import { styled } from '../../theme';

export const StyledTileContainer = styled('div', {
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1)', // todo write util to fade colors
    margin: '0 1px 1px 0',
    borderRadius: '$1',
    cursor: 'pointer',

    maxWidth: '207px',
    width: '134px',
    overflow: 'hidden',
    variants: {
        size: {
            mobile: { width: '134px' },
            default: { width: '207px' },
        },
    },
});
