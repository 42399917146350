import { styled } from '@exaring/ui/components-styled/theme';

export const Badge = styled('div', {
    margin: '0 3px',
    color: '$white',
    height: '18px',
    border: 'solid 1.5px $white',
    borderRadius: '2px',
    padding: '1.5px 3px',
    fontSize: '10px',
    fontWeight: '$3',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});
