/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';
import { getBaseUrl } from '@exaring/utils';

const base = getBaseUrl(window.location.href);

export const Links = {
    IMPRINT_URL: `https://www.${base}/impressum`,
    TERMS_AND_CONDITIONS_URL: `https://www.${base}/agb?tenant=o2`,
    DATA_PRIVACY_STATEMENT_URL: `https://wwww.${base}/dse?tenant=o2`,
    ABOUT_URL: 'https://www.o2.de/tv',
    HELP_DESK_URL: 'https://www.o2.de/tv-faq',
};

const Footer = () => {
    return (
        <footer className="footer">
            <div className="extended-footer">
                <div className="extended-footer__container">
                    <section className="footer-section">
                        <a
                            href={Links.ABOUT_URL}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="footer-section__link"
                        >
                            Mehr über O₂ TV
                        </a>
                    </section>

                    <section className="footer-section">
                        <a
                            href={Links.HELP_DESK_URL}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="footer-section__link"
                        >
                            Häufige Fragen
                        </a>
                    </section>
                </div>
            </div>

            <section className="base-footer">
                <ul className="base-footer__legal-link-list">
                    <li className="base-footer__legal-link-item">
                        <a href={Links.IMPRINT_URL} className="base-footer__legal-link">
                            Impressum
                        </a>
                    </li>
                    <li className="base-footer__legal-link-item">
                        <a
                            href={Links.TERMS_AND_CONDITIONS_URL}
                            className="base-footer__legal-link"
                        >
                            AGB
                        </a>
                    </li>
                    <li className="base-footer__legal-link-item">
                        <a
                            href={Links.DATA_PRIVACY_STATEMENT_URL}
                            className="base-footer__legal-link"
                        >
                            Datenschutz
                        </a>
                    </li>
                </ul>
            </section>

            <section className="base-footer base-footer--powered">
                <span>powered by</span>
                <span className="base-footer--powered__icon icon icon--waipu">waipu</span>
            </section>
        </footer>
    );
};

export { Footer };
export default Footer;
