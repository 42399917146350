/*
 *  Copyright (C) Exaring AG - All Rights Reserved
 */

import { Globals, VariantProps, styled } from '@exaring/ui/components-styled/theme';
import { GridContainer } from '@exaring/ui/components-styled/Grid';
import { h } from 'preact';

const StyledModal = styled(GridContainer, {
    flexFlow: 'column',
    background: '$nearBlack',
    padding: '$$gutterWidth',

    width: '100vw',
    maxHeight: '100vh',
    overflow: 'hidden',

    '@md': {
        width: '928px',
    },
});

type ModalType = typeof StyledModal;

// This wrapper is added so that clicks inside the modal don't fire the onClick in the ModalBackdrop component
// In order to achieve this, we need to add the event.stopPropagation() at some point, before the event
// bubbles up to the ModalBackdrop component
//
// Typchecking disabled as this acts as a proxy
//
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const Modal: ModalType = (props: any) => {
    const { children, ...otherProps } = props;

    return (
        <div onClick={(e) => e.stopPropagation()}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <StyledModal {...otherProps}>{children}</StyledModal>
        </div>
    );
};

export const ModalBackdrop = styled(GridContainer, {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundRgba: { color: 'nearBlack', opacity: 0.7 },
    backdropFilter: 'blur(4px)',

    variants: {
        modal: {
            true: {
                zIndex: '$modal',
            },
        },
        fullscreen: {
            true: {
                zIndex: '$fullscreen',
            },
        },
        toast: {
            true: {
                top: Globals.headerHeight,
                background: 'inherit',
                zIndex: '$modal',
                [`${Modal}`]: {
                    maxHeight: `calc(100vh - ${Globals.headerHeight})`,
                },
            },
        },
    },
});

export type StyledBackdropVariants = VariantProps<typeof ModalBackdrop>;
