/* eslint-disable no-restricted-syntax */

import { h } from 'preact';
import { connect } from 'unistore/preact';
import { useRef, useState, useEffect, useCallback, useMemo } from 'preact/hooks';
import ageVerificationService from '@exaring/networking/services/age-verification';
import AgeVerificationModuleInput from './AgeVerificationModuleInput';
import actions from '../../../actions/player';
import constants from '../../../constants';
import { WebClientGAEvent } from '../../../web-client-ga';
import store from '../../../store';
import { trackWithUserContext, trackWithPlayerContext } from '../../../tracking';

const AgeVerificationModule = ({ onVerificationSuccess }) => {
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const refs = useMemo(() => [ref1, ref2, ref3, ref4], []);

    const [inputValidationError, setInputValidationError] = useState();
    const [pinVerificationError, setPinVerificationError] = useState();
    const [errors, setErrors] = useState([false, false, false, false]);

    const updateInput = useCallback(
        (inputKey, value, hasError) => {
            refs[inputKey].current.value = value;
            const newErrors = [...errors];
            newErrors[inputKey] = hasError;
            setErrors(newErrors);
        },
        [errors, refs],
    );

    const handlePinSubmit = useCallback(async () => {
        trackWithUserContext(WebClientGAEvent.ParentalControlPinVerification);
        try {
            const pin = refs.map((ref) => ref.current.value).join('');
            await ageVerificationService.verifyPin(pin);
            onVerificationSuccess();
            trackWithPlayerContext(
                WebClientGAEvent.ChildProtection,
                store.getState(),
                WebClientGAEvent.PinVerificationSuccessful,
                'child_protection_blocker',
            );
        } catch (e) {
            setPinVerificationError(
                'Jugendschutz-PIN nicht korrekt. Bitte geben Sie Ihre PIN erneut ein:',
            );

            refs.forEach((ref, index) => updateInput(index, '', false));
            refs[0].current.focus();
            trackWithPlayerContext(
                WebClientGAEvent.ChildProtection,
                store.getState(),
                WebClientGAEvent.PinVerificationUnsuccessful,
                'child_protection_blocker',
            );
        }
    }, [refs, onVerificationSuccess, updateInput]);

    const handleFocus = (inputKey) => {
        updateInput(inputKey, null, false);
    };

    const handleChange = useCallback(
        async (inputKey) => {
            const { value } = refs[inputKey].current;
            setPinVerificationError(undefined);

            if (!value.match('[0-9]')) {
                updateInput(inputKey, '', true);
                return;
            }

            updateInput(inputKey, value, false);

            if (inputKey === 3) {
                handlePinSubmit();
                return;
            }
            const nextInput = refs[inputKey + 1].current;
            nextInput.focus();
        },
        [refs, updateInput, handlePinSubmit],
    );

    useEffect(() => {
        const hasInputError = errors.find((hasError) => hasError);
        if (hasInputError) {
            setInputValidationError('Bitte überprüfen Sie Ihre Eingabe');
        }
        if (!hasInputError && !!inputValidationError) {
            setInputValidationError(undefined);
        }
    }, [errors, inputValidationError]);

    useEffect(() => {
        refs[0].current.focus();
    }, []); // eslint-disable-line

    return (
        <div className="media-player-verification-module">
            {inputValidationError || pinVerificationError ? (
                <div className="media-player-input-error">
                    {pinVerificationError || inputValidationError}
                </div>
            ) : (
                <div className="media-player-input-headline">
                    Bitte geben Sie Ihre Jugendschutz-PIN ein:
                </div>
            )}

            <div className="media-player-input-group">
                {refs.map((ref, key) => (
                    <AgeVerificationModuleInput
                        inputKey={key}
                        inputRef={ref}
                        value={ref.current?.value}
                        handleFocus={handleFocus}
                        handleChange={handleChange}
                        hasError={errors[key] || !!pinVerificationError}
                    />
                ))}
            </div>
            <div
                className="media-player-input-password-forget"
                onClick={() => {
                    trackWithUserContext(WebClientGAEvent.ParentalControlPinForgotton);
                    window.location.href = constants.CSC_AGE_VERIFICATION_URL;
                }}
            >
                PIN VERGESSEN
            </div>
        </div>
    );
};

export default connect(undefined, actions)(AgeVerificationModule);
