import { Recording, isRecording } from '@exaring/networking/types/Recording/Recording';
import { useMemo } from 'preact/hooks';
import { useRecordingInit } from './useRecordingInit';
import { useMemoData } from '../../../hooks/useMemoData';
import { isPlayableRecording, sortRecordingStartTime } from './filter';
import { RemoteDataState } from '../../../state/utils/RemoteData';

/**
 * This hook is used to filter and sort recordings. It returns the filtered and unfiltered recordings.
 * @param filter
 * @param sorting
 * @returns [filteredRecordings: Recording[], unfilteredRecordings: Recording[], state: RemoteDataState]
 */
export const useRecordings = (
    filter: (recording: Recording) => boolean = isPlayableRecording,
    sorting: (left: Recording, right: Recording) => number = sortRecordingStartTime,
): [Recording[], Recording[], RemoteDataState] => {
    const [state, recordings] = useRecordingInit();

    const memoizedRecordings = useMemo(() => recordings.filter(isRecording), [recordings]); // ignore placeholder

    return [useMemoData(memoizedRecordings, filter, sorting), memoizedRecordings, state];
};
