/* eslint-disable import/no-unused-modules */
/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */
import { h, FunctionComponent, Fragment, VNode } from 'preact';
import Arrow from '@exaring/assets/icons/arrow-big.svg';
import { RecordingDetails } from '@exaring/networking/types/Recording/RecordingDetails';
import { GA } from '@exaring/utils';
import { PageGrid, PageGridItem } from '../PageGrid';
import { LoadingSpinner } from '../LoadingSpinner';
import { MiniFooter } from '../MiniFooter';
import { RecommendationContents } from '../types/Recommendation';
import { RecommendationDataType } from '../EpgDetails/Recommendations';

import {
    ProgramImage,
    ProgramDescription,
    ProgramDetails,
    ProgramTeaser,
    Recommendations,
} from '../EpgDetails';

import { styled } from '../theme';
import { EpgContentNotFound } from './Exceptions';
import { NetworkingException } from '../Modal';
import { LayoutType, LinkType, Program as ProgramType } from './types';
import { CloseAndBackButtons } from './CloseAndBackButtons';
import { ChannelSection } from './ChannelSection';
import { NavigationButtons, getProgramDetailsScreenName } from './NavigationButtons';

const Root = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '$nearBlack',
    color: '$white',
    height: '100%',
    justifyContent: 'center',
    paddingBottom: '20px',
    variants: {
        layout: {
            modal: {
                paddingRight: '$$gutterWidth',
                paddingLeft: '$$gutterWidth',
                overflowY: 'auto',
            },
            player: {
                backgroundColor: 'transparent',
                overflowY: 'auto',
            },
            epg: {},
            mediathek: {},
            microSite: {
                paddingBottom: '48px',
                paddingRight: '61px',
                paddingLeft: '61px',
            },
        },
    },
});

const ProgramDetailsContainer = styled('div', {
    display: 'flex',
    justifyContent: 'center',
});

const Loading = styled('div', {
    minHeight: 100,
    display: 'flex',
    alignItems: 'center',
});

const PlaylistNavigationItem = styled('div', {
    display: 'none', // @todo enable when feature is figured out completly
    position: 'absolute',
    top: '25vh',
    '@md': {
        top: '50vh',
    },
});

const PlaylistPreviousItem = styled(PlaylistNavigationItem, {
    left: '0px',
});

const PlaylistNextItem = styled(PlaylistNavigationItem, {
    right: '0px',
});

const ArrowRight = styled(Arrow, { $$size: '44px', width: '$$size', height: '$$size' });
const ArrowLeft = styled(ArrowRight, { transform: 'rotateY(180deg)' });

const ModalImageComponent = styled('img', {
    width: '100%',
    borderRadius: '$2',
    contain: 'layout',
    variants: {
        layout: {
            modal: { marginTop: '0px' },
        },
    },
});

const StyledRoot = styled(Root, { justifyContent: 'start' });

export const StyledPageGrid = styled(PageGrid, {
    rowGap: '20px',
    margin: '0px 0px',
});

export const TeaserAndImageGrid = styled(StyledPageGrid, {
    variants: {
        layout: {
            mediathek: { paddingTop: '72px' },
            epg: { paddingTop: '95px' },
            modal: {},
            microSite: {},
            player: {},
        },
    },
});

const LayoutWrapper = styled('div', {
    position: 'relative',
    '-webkit-overflow-scrolling': 'touch',
    maxWidth: '1342px',
    variants: {
        layout: {
            mediathek: {
                marginTop: '0',
                marginBottom: '0',
                maxWidth: '1482px',
                paddingRight: '70px',
                paddingLeft: '70px',
            },
            epg: {
                maxWidth: '1482px',
                paddingRight: '70px',
                paddingLeft: '70px',
            },
            modal: {},
            microSite: {},
            player: {
                width: '100%',
            },
        },
    },
});

const getLayoutForDetails = (layout: LayoutType) => {
    switch (layout) {
        case 'player':
            return {
                xs: 4,
                sm: 4,
                md: 4,
                lg: 6,
                xl: 8,
            };
        default:
            return {
                xs: 2,
                sm: 2,
                md: 2,
                lg: 3,
                xl: 4,
            };
    }
};

type RecommendationPayload = {
    channelId: string;
    programId: string;
    recommendation: RecommendationContents;
    contentId?: string;
};

export const ProgramDetailPage: FunctionComponent<{
    program: ProgramType | undefined;
    layout: LayoutType;
    goTo?: (program: ProgramType | undefined, linkType: LinkType) => void;
    renderRecommendations?: () => JSX.Element | undefined;
    isPlaying?: boolean;
    isLoading?: boolean;
    errorCode?: number;
    hideMediathekButton?: boolean;
    hidePlayButton?: boolean;
    renderRecordingButton?: (program: ProgramType) => any;
    onRecommendationClick?: (args: RecommendationPayload) => void;
    stationDisplay?: string;
    handleMediathekButtonClick?: () => void;
    headerButtons?: VNode<any>;
    channelWithLogo?: boolean;
    channelNumber?: number;
    isEpisodeView?: boolean;
    recording?: RecordingDetails;
    isTimeShifted?: boolean;
}> = ({
    layout,
    program,
    recording,
    renderRecommendations,
    goTo,
    isPlaying = false,
    isLoading,
    errorCode,
    hideMediathekButton = false,
    hidePlayButton = false,
    renderRecordingButton,
    onRecommendationClick,
    children,
    stationDisplay,
    handleMediathekButtonClick,
    headerButtons,
    channelWithLogo,
    channelNumber,
    isEpisodeView,
    isTimeShifted,
}) => {
    const onErrorScreenHistoryBack = () => {
        goTo?.(program, 'close');
    };

    const path = window.location.pathname;
    // get contentId out of path
    const contentId = path.split('content:')[1];

    if (errorCode) {
        return (
            <Root>
                {errorCode === 500 ? (
                    <NetworkingException onClick={onErrorScreenHistoryBack} />
                ) : (
                    <EpgContentNotFound onClick={onErrorScreenHistoryBack} />
                )}
            </Root>
        );
    }
    if (isLoading) {
        return (
            <Root>
                <Loading>
                    <LoadingSpinner secondary />
                </Loading>
            </Root>
        );
    }
    if (!program) {
        return <></>;
    }

    const getSmLayoutForTeaser = () => {
        switch (layout) {
            case 'player':
                return 3;
            case 'microSite':
                return 2;
            default:
                return 1;
        }
    };

    const layoutForDetails = getLayoutForDetails(layout);

    const getRecommendationsDataType = (): RecommendationDataType | undefined => {
        if (layout === 'mediathek') {
            return RecommendationDataType.Vod;
        }
        if (layout === 'epg') {
            return RecommendationDataType.Program;
        }
        if ((layout === 'modal' || layout === 'player') && recording) {
            return RecommendationDataType.Recording;
        }
        if ((layout === 'modal' || layout === 'player') && program) {
            return RecommendationDataType.Program;
        }

        return undefined;
    };

    const handleRecommendationClick = (
        data: RecommendationPayload,
        rowIdx: number,
        tileIdx: number,
        rowId: string,
    ) => {
        onRecommendationClick?.(data);
        GA.track({
            event: 'epg',
            event_description: 'select_highlight_video',
            screen_name: getProgramDetailsScreenName(program),
            channel_name: program.channelDisplay,
            program_id: program.id,
            program_title: program.title,
            player_type: 'livetv',
            section_id: rowId,
            section_position: `${rowIdx}_${tileIdx}`,
        });
    };

    return (
        <StyledRoot layout={layout}>
            <ProgramDetailsContainer>
                <LayoutWrapper layout={layout}>
                    {layout !== 'microSite' && (
                        <CloseAndBackButtons
                            layout={layout}
                            isEpisodeView={isEpisodeView}
                            onClose={() => goTo?.(program, 'close')}
                            onClickBack={() => goTo?.(program, 'backToSeries')}
                        />
                    )}
                    <ChannelSection
                        layout={layout}
                        program={program}
                        stationDisplay={stationDisplay}
                        buttons={headerButtons}
                        channelWithLogo={channelWithLogo}
                        channelNumber={channelNumber}
                    />

                    <TeaserAndImageGrid layout={layout}>
                        <PageGridItem
                            xs={3}
                            sm={getSmLayoutForTeaser()}
                            md={layout === 'player' ? 4 : 2}
                            lg={layout === 'player' ? 6 : 3}
                            xl={layout === 'player' ? 6 : 4}
                        >
                            <ProgramTeaser program={program} layout={layout} />

                            <NavigationButtons
                                layout={layout}
                                program={program}
                                recording={recording}
                                hideMediathekButton={hideMediathekButton}
                                hidePlayButton={hidePlayButton}
                                hideRestartButton={layout === 'modal' && !!program && !recording}
                                renderRecordingButton={renderRecordingButton}
                                isPlaying={isPlaying}
                                handleMediathekButtonClick={handleMediathekButtonClick}
                                isPlayoutTimeShifted={isTimeShifted}
                                goTo={goTo}
                            />

                            {!program.actors?.length && program.description && (
                                <PageGridItem
                                    xs="hidden"
                                    sm="hidden"
                                    md={layoutForDetails.md}
                                    lg={8}
                                    xl={8}
                                >
                                    <ProgramDescription layout={layout}>
                                        {program.description}
                                    </ProgramDescription>
                                </PageGridItem>
                            )}
                        </PageGridItem>

                        {layout !== 'player' && (
                            <PageGridItem xs={3} sm={2} md={2} lg={3} xl={4}>
                                <ProgramImage
                                    layout={layout}
                                    program={program}
                                    imageComponent={
                                        layout === 'modal' ? ModalImageComponent : undefined
                                    }
                                />
                            </PageGridItem>
                        )}
                    </TeaserAndImageGrid>
                    <StyledPageGrid>
                        {!!program.description && !!program.actors?.length && (
                            <PageGridItem
                                xs={layoutForDetails.xs}
                                sm={layoutForDetails.sm}
                                md={layoutForDetails.md}
                                lg={layoutForDetails.lg}
                                xl={layoutForDetails.xl}
                            >
                                <ProgramDescription layout={layout}>
                                    {program.description}
                                </ProgramDescription>
                            </PageGridItem>
                        )}
                        {!program.actors?.length && !!program.description && (
                            <PageGridItem xs={3} sm={3} md="hidden">
                                <ProgramDescription layout={layout}>
                                    {program.description}
                                </ProgramDescription>
                            </PageGridItem>
                        )}
                        {!!program.actors?.length && (
                            <PageGridItem
                                xs={layoutForDetails.xs}
                                sm={layoutForDetails.sm}
                                md={layoutForDetails.md}
                                lg={layoutForDetails.lg}
                                xl={layoutForDetails.xl}
                            >
                                <ProgramDetails layout={layout} program={program} />
                            </PageGridItem>
                        )}
                    </StyledPageGrid>

                    <StyledPageGrid>
                        <PageGridItem xs={2} sm={3} md={4} lg={6} xl={4}>
                            {children}
                        </PageGridItem>
                    </StyledPageGrid>

                    {layout !== 'microSite' && (
                        <>
                            {(program.id || recording?.id) && (
                                <StyledPageGrid>
                                    <PageGridItem xs={2} sm={3} md={4} lg={6} xl={8}>
                                        {renderRecommendations ? (
                                            renderRecommendations()
                                        ) : (
                                            <Recommendations
                                                layout={layout}
                                                dataType={getRecommendationsDataType()}
                                                programId={program.id}
                                                recordingId={recording?.id}
                                                vodId={contentId}
                                                handleRecommendationClick={
                                                    handleRecommendationClick
                                                }
                                            />
                                        )}
                                    </PageGridItem>
                                </StyledPageGrid>
                            )}
                            <StyledPageGrid>
                                <PageGridItem xs={2} sm={3} md={4} lg={6} xl={8}>
                                    <MiniFooter css={{ flexDirection: 'column' }} />
                                </PageGridItem>
                            </StyledPageGrid>
                        </>
                    )}
                </LayoutWrapper>
            </ProgramDetailsContainer>

            <PlaylistPreviousItem>
                <ArrowLeft />
            </PlaylistPreviousItem>
            <PlaylistNextItem>
                <ArrowRight />
            </PlaylistNextItem>
        </StyledRoot>
    );
};
