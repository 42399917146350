export const SupNewTVResponseV1 = {
    streamUrl: {
        type: 'string',
        default: '',
        required: true,
    },
    processOutcomeField: { type: 'boolean', default: false },
    muxInstrumentation: { type: 'boolean', default: false },
    cacheable: { type: 'boolean', default: false },
    pauseAllowed: { type: 'boolean', default: false },
    seekTime: { type: 'number', default: 0 },
    refreshPeriod: { type: 'number', default: 0 },
};

export const SupRegularResponseV1 = {
    streamUrl: {
        type: 'string',
        default: '',
    },
    advertising: {
        type: 'object',
        default: {
            googleDai: {
                type: 'object',
                default: {
                    assetKey: { type: 'string', default: '' },
                    streamToken: { type: 'string', default: '' },
                    protocol: { type: 'string', default: 'dash' },
                },
            },
        },
    },
};

// vnd.streamurlprovider.stream-url-request-v1+json
export const SupRequestV1 = {
    stream: {
        type: 'object',
        required: true,
        default: {
            station: {
                type: 'string',
                default: '',
                required: true,
            },
            protocol: {
                type: 'enum',
                default: 'dash',
                enum: ['hls', 'dash'],
                required: true,
            },
            requestMuxInstrumentation: { type: 'boolean', default: false },
            processOutcomeField: { type: 'boolean', default: false },
            advertising: {
                type: 'object',
                default: {
                    id: { type: 'string', default: '' },
                    googlePal: { type: 'string', default: '' },
                },
            },
            // Stream start time (vbegin) as unix timestamp (in seconds). This property must only be set if the client requires access to the stream at an earlier point in time (e.g. pause, instant restart).
            startTime: { type: 'number', default: undefined },
            // Stream start time reason (vbegin_reason), which can be set to specify the reason why startTime was set to an earlier point in time. Attention: property can only be used in conjunction with startTime!
            startTimeReason: { type: 'string', default: undefined },
            // Flag indicating, that the client wants to use medium-latency-mode for the requested stream.
            requestMediumLatency: { type: 'boolean', default: false },

            // Indicates what purpose the stream-url is used for (e.g. casting or preview)
            purpose: { type: 'string', default: undefined },
        },
    },
    advertising: {
        type: 'object',
        required: false,
        default: {
            gdprConsent: {
                type: 'string',
                default: '',
                required: false,
            },
        },
    },
};
