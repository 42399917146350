/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';
import { classnames, strj } from '@exaring/utils';

export const NavigationItem = ({
    name,
    onAction,
    className,
    icon,
    label,
    disabled,
    enableChildActionBindOnly,
    dataTestId = '',
}) => {
    const wrapperCls = strj(name, 'navigation-item', '__');

    const itemCls = classnames(
        wrapperCls,
        className,
        disabled && strj(wrapperCls, 'disabled', '--'),
    );

    const iconCls = classnames(strj(wrapperCls, 'icon', '__'), strj('icon', icon, '--'));

    const labelCls = classnames(strj(wrapperCls, 'label', '__'));

    const _onAction = disabled ? () => {} : onAction;
    const itemAction = enableChildActionBindOnly ? undefined : _onAction;
    const distinctBindAction = enableChildActionBindOnly ? _onAction : undefined;

    return (
        <div
            {...(dataTestId ? { 'data-testid': dataTestId } : {})}
            className={itemCls}
            onClick={itemAction}
        >
            {icon && <span className={iconCls} onClick={distinctBindAction} />}
            {label && (
                <span className={labelCls} onClick={distinctBindAction}>
                    {label}
                </span>
            )}
        </div>
    );
};

export const Navigation = ({ children, name, className }) => {
    const _name = strj(name, 'navigation', '-');
    const _children = (children && children.map && children) || [children]; // array compliance

    const mutatedChildren =
        _children &&
        _children.map((vnode) => {
            return vnode
                ? {
                      ...vnode,
                      props: { name: _name, ...vnode.props }, // pass name prop to children
                  }
                : null;
        });

    const navCls = classnames('navigation', strj(name, 'navigation', '-'), className);

    return <nav className={navCls}>{mutatedChildren}</nav>;
};

export default Navigation;
