import { h, FunctionComponent, Fragment } from 'preact';
import { Dropdown } from '@exaring/ui/components-styled/DropDown';
import { StyledFilterIcon, StyledSortIcon } from '@exaring/ui/components-styled/DropDown/DropDown';
import { CSS } from '@stitches/react';
import { SortSelectOption, SortingOption, sortingSelectOptions } from './sorting';
import { FilterOption, filterSelectOptions } from './filter';
import { HeaderMenu } from '../PageHeadline/PageHeader';
import { FilterSelect } from './FilterSelect';

const DropdownStyles = {
    width: '161px',
    '@md': { width: '207px' },
};

export const RecordingFilterSortingMenu: FunctionComponent<{
    setActiveSorting: (value: SortingOption) => void;
    setActiveFilter: (value: FilterOption) => void;
    activeFilter: FilterOption;
    activeSorting: SortingOption;
    disableSorting?: boolean;
    disableFiltering?: boolean;
    css?: CSS;
    isSeriesView?: boolean;
    isFilterDisabledMap?: Record<FilterOption, boolean>;
    disableSortingByEpisode?: boolean;
}> = ({
    setActiveSorting,
    setActiveFilter,
    activeFilter,
    activeSorting,
    css,
    disableSorting = false,
    disableFiltering = false,
    isSeriesView = false,
    isFilterDisabledMap,
    disableSortingByEpisode,
}) => {
    const filterOptions = isFilterDisabledMap
        ? filterSelectOptions.map((option) => ({
              ...option,
              disabled: isFilterDisabledMap?.[option.value],
          }))
        : filterSelectOptions;

    const channelViewOptions = sortingSelectOptions.filter(
        ({ value }) => value !== SortingOption.EpisodeAsc && value !== SortingOption.EpisodeDesc,
    );

    const filterSelectOptionsForSeriesView = ({ value }: { value: SortingOption }) =>
        value !== SortingOption.ChannelAsc && value !== SortingOption.ChannelDesc;

    const setDisabledIfNoEpisode = (item: SortSelectOption) => {
        let disabled = false;
        if (
            disableSortingByEpisode &&
            (item.value === SortingOption.EpisodeAsc || item.value === SortingOption.EpisodeDesc)
        ) {
            disabled = true;
        }

        return {
            ...item,
            disabled,
        };
    };

    const seriesViewOptions = sortingSelectOptions
        .filter(filterSelectOptionsForSeriesView)
        .map(setDisabledIfNoEpisode);

    const sortingOptions = isSeriesView ? seriesViewOptions : channelViewOptions;

    return (
        <>
            <HeaderMenu css={css}>
                <Dropdown<SortingOption>
                    css={DropdownStyles}
                    onChange={(value) => setActiveSorting(value as SortingOption)}
                    options={sortingOptions}
                    selected={activeSorting}
                    icon={<StyledSortIcon />}
                    disabled={disableSorting}
                    label="Sortieren nach"
                />
                <Dropdown<FilterOption>
                    css={
                        !isSeriesView
                            ? {
                                  ...DropdownStyles,
                                  '@lg': { display: 'none' },
                              }
                            : DropdownStyles
                    }
                    onChange={(value) => setActiveFilter(value as FilterOption)}
                    options={filterOptions}
                    disabled={disableFiltering}
                    selected={activeFilter}
                    icon={<StyledFilterIcon />}
                    label="Filtern nach"
                />
                {!isSeriesView && (
                    <FilterSelect
                        css={{
                            flexDirection: 'row-reverse',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                        activeFilter={activeFilter}
                        onChangeFilter={setActiveFilter}
                        isFilterDisabledMap={isFilterDisabledMap}
                    />
                )}
            </HeaderMenu>
        </>
    );
};
