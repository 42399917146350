import { getBaseUrl } from '@exaring/utils';

/* global __ENVIRONMENT__ */

const base = getBaseUrl(window.location.href);

const constants = {
    TOKEN_TIMECHECK_THRESHOLD: 60,
    RECORDING_READY_TIME: 60, // deprecated
    WAIPU_BASE_URL: `https://www.${base}`,
    AUTH_BASE_URL: `https://auth.${base}`,
    EPG_BASE_URL: `https://epg-cache.${base}`,
    EPG_CHANNEL_BASE_URL: `https://epg.${base}`,
    EPG_CHANNEL_CONFIG_BASE_URL: `https://epg-channels.${base}`,
    IMAGES_BASE_URL: `https://images${__ENVIRONMENT__ === 'prod' ? '' : '-dev'}.wpstr.tv`,
    PLAYOUT_URL_PROVIDER_BASE_URL: `https://playout-url-provider.${base}`, // @deprecated legacy playout provider
    DEVICE_CAPABILITES_BASE_URL: `https://device-capabilities.${base}/api`,
    STREAM_URL_PROVIDER_BASE_URL: `https://stream-url-provider.${base}/api`,
    ACTIVATION_BASE_URL: `https://activation.${base}/api`,
    WAIPUTHEK_BASE_URL: `https://waiputhek.${base}/api`,
    PERMISSION_MANAGEMENT: `https://permission-management.${base}/api`,
    TUNER_BASE_URL: `https://tuner.${__ENVIRONMENT__ === 'prod' ? '' : 'dev.'}wpstr.tv`,

    AGE_VERIFICATION_BASE_URL: `https://age-verification.${base}/api/verification`,
    RECORDINGS_BASE_URL: `https://recording.${base}/api/recordings`,
    STREAM_POSITION_BASE_URL: `https://stream-position.${base}/api`,
    SERIALS_RECORDING_BASE_URL: `https://recording-scheduler.${base}/api`,
    REGISTRATION_BASE_URL: `https://customer-registration.${base}`,
    CUSTOMER_FUNNEL_BASE_URL: `https://client.${base}/customer-funnel`,
    REGISTRATION_SERVICE_BASE_URL: `https://customer-registration.${base}/api`,
    EXTERNAL_REGISTRATION_SERVICE_BASE_URL: `https://external-customer-reg.${base}/api`,
    RECOMMENDATIONS_SERVICE_BASE_URL: `https://recommendations.${base}/api`,
    CANCELLATION_SERVICE_BASE_URL: `https://cancellation.${base}/api`,
    CUSTOMER_SELF_SERVICE_BASE_URL: `https://customer-self-service.${base}/api`,
    CUSTOMER_SELF_CARE_BASE_URL: `https://customer-self-care.${base}/api`,
    CHANNEL_LOGO_LIST_URL: `https://www.${base}/assets/channel-logos-v2.json`,

    EPAY_VOUCHER_VALIDATION_URL: `https://epay.${base}/api/coupons`,
    O2_API: `https://o2api.${base}/api/o2`,
    BOOKING_BASE_URL: `https://booking.${base}/api`,

    // Auth flow
    AUTH_REDIRECT_URL: `https://auth.${base}/ui/oauth/auth`,
    LOGOUT_URL: `https://auth.${base}/ui/logout`,
};

export default constants;
