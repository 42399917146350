import { VariantProps } from '@stitches/react';
import { FunctionComponent, h } from 'preact';
import { styled, keyframes } from '../theme';

const SpinnerRotateAnimation = keyframes({
    to: {
        transform: 'rotate(1turn)',
    },
});

export const LoadingSpinner = styled('div', {
    $$size: '22px',
    margin: '1px auto',
    textIndent: '-9999em',
    width: '$$size',
    height: '$$size',
    borderRadius: '50%',
    background: '$white',
    position: 'relative',
    animation: `${SpinnerRotateAnimation} 1.4s infinite linear`,
    padding: 3,
    // create one mask which fades out background color clockwise with conic
    // gradient and then create another mask which fades out the whole content.
    // This gives you a "border" with the value of the padding above.
    $$mask: `
        conic-gradient(transparent 170deg, #000 250deg, #000 360deg),
        linear-gradient(#000 0 0) content-box;
    `,
    mask: '$$mask',
    '-webkit-mask': '$$mask',
    maskComposite: 'subtract',
    '-webkit-mask-composite': 'source-out',

    variants: {
        secondary: {
            true: {
                background: '$blue',
            },
        },
    },
});
type LoadingSpinnerVariants = VariantProps<typeof LoadingSpinner>;

const Container = styled('div', {
    display: 'flex',
    alignItems: 'center',
});
export const LoadingSpinnerIcon: FunctionComponent<
    LoadingSpinnerVariants & {
        width: number;
        height: number;
    }
> = ({ width, height, ...otherProps }) => {
    // compute padding to make it match the other circle icons like play and record
    const size = Math.max(Math.min(width, height) - 8, 0);
    return (
        <Container css={{ width, height }}>
            <LoadingSpinner
                css={{ width: size, height: size }}
                {...otherProps} // eslint-disable-line react/jsx-props-no-spreading
            />
        </Container>
    );
};
