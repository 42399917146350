import constants from '../constants';
import { get, post } from '../request';

export const verification = () => get(constants.AGE_VERIFICATION_BASE_URL);

export const verifyPin = (pin) =>
    post(
        `${constants.AGE_VERIFICATION_BASE_URL}/pin/verify`,
        {
            Accept: 'application/vnd.waipu.age-verification-pin-verification-request-v1+json',
            'Content-Type':
                'application/vnd.waipu.age-verification-pin-verification-request-v1+json',
        },
        { pin },
    );
