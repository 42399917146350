import { h } from 'preact';
import Heading from '../../typography/heading';
// type Props = {
//     className?: string,
//     title?: string,
//     children?: array
//     content?: array
// };

export default ({ className, children, content, footer, title, inset = true }) => {
    const _header =
        (title && title !== '' && (
            <Heading content={title} className="section__header" legacy={false} />
        )) ||
        '';
    const _footer = (footer && <div className="section__footer">{footer}</div>) || '';
    return (
        <section className={`section ${className || ''}`}>
            {!inset && _header}
            <div className="section__content">
                {inset && _header}
                {content || children}
                {inset && _footer}
            </div>
            {!inset && _footer}
        </section>
    );
};
