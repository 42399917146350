import { h, FunctionComponent } from 'preact';
import { StyledComponentBaseProps } from '../../theme';
import { Exception } from './Exception';

type NetworkingException = StyledComponentBaseProps & {
    onClick: () => void;
};

export const NetworkingException: FunctionComponent<NetworkingException> = ({ onClick }) => {
    return (
        <Exception
            title="Entschuldigung, es ist ein Netzwerk-Fehler aufgetreten."
            onClick={onClick}
        />
    );
};
