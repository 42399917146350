import { h } from 'preact';
import { conditionalCb } from '@exaring/utils';
import FavoriteActiveIcon from '@exaring/assets/icons/favorite-active.svg';
import FavoriteInactiveIcon from '@exaring/assets/icons/favorite-inactive.svg';

const FavoriteButton = ({ isActive = false, onClick }) => {
    const FavoriteIcon = isActive ? FavoriteActiveIcon : FavoriteInactiveIcon;
    return (
        <FavoriteIcon
            onClick={conditionalCb(onClick, onClick)}
            className={isActive ? 'icon--favorite' : 'icon--favorite-inactive'}
        />
    );
};

export default FavoriteButton;
