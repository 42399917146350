import { isObject, isString, validateProperties } from '@exaring/utils';

export const TV_FUSE_LINK = 'tvfuse:link';
export const TV_FUSE_VIDEO = 'tvfuse:video';

export type MediathekItem = MediathekLinkItem | MediathekVideoItem;

type MediathekItemType = MediathekItem['type'];

interface MediathekLinkItemJson {
    type: typeof TV_FUSE_LINK;
    data: string;
}

export interface MediathekLinkItem {
    type: typeof TV_FUSE_LINK;
    data: MediathekLinkData;
}

interface MediathekLinkData {
    description: string;
    descriptionHTML: string;
    img: string;
    microsite: string;
    parentalGuidance: string;
    route: string;
    source: string;
    title: string;
}

export interface MediathekVideoItem {
    type: typeof TV_FUSE_VIDEO;
    publicationWindow?: {
        publishFrom?: string;
        publishedUntil?: string;
    };
    video: {
        airtime?: string;
        description?: string;
        duration?: number;
        episode?: number;
        genre?: string;
        id?: string;
        img?: string;
        parentalGuidance?: string;
        pinRequired?: boolean;
        programID: string;
        season?: number;
        source?: string;
        title?: string;
    };
}

const isLinkData = (value: unknown): value is MediathekLinkData =>
    validateProperties(value, {
        description: isString,
        descriptionHTML: isString,
        img: isString,
        microsite: isString,
        parentalGuidance: isString,
        route: isString,
        source: isString,
        title: isString,
    });

const isJsonLink = (value: unknown): value is MediathekLinkItemJson =>
    validateProperties(value, {
        type: (val) => val === TV_FUSE_LINK,
        data: isString,
    });

export const isLink = (value: unknown): value is MediathekLinkItem =>
    validateProperties(value, {
        type: (val) => val === TV_FUSE_LINK,
    });

export const isVideo = (value: unknown): value is MediathekVideoItem =>
    validateProperties(value, {
        type: (val) => val === TV_FUSE_VIDEO,
    });

export const matcher = <R>(type: MediathekItemType, options: Record<MediathekItemType, () => R>) =>
    options[type]();

/** Decodes given json string data to js data without throwing an exception. */
const decodeJson = (data: string): unknown => {
    try {
        return JSON.parse(data);
    } catch (e: unknown) {
        return undefined;
    }
};

const decodeLink = ({ type, data }: MediathekLinkItemJson): MediathekLinkItem | undefined => {
    const parsedData = decodeJson(data);
    return isLinkData(parsedData) ? { type, data: parsedData } : undefined;
};

const isValidJson = (value: unknown): value is MediathekItem =>
    isObject(value) && (isJsonLink(value) || (isVideo(value) && isString(value.video?.programID)));

export const decode = (value: unknown): MediathekItem | undefined => {
    if (isValidJson(value)) {
        return isJsonLink(value) ? decodeLink(value) : value;
    }
    return undefined;
};
