import { useEffect, useState } from 'preact/hooks';
import { unix } from '@exaring/utils';
import { RemoteData, failed, notAsked, remoteDataStateResolver } from '../state/utils/RemoteData';

const MAX_RETRIES = 3;
export const COOLDOWN_IN_SEC_DEFAULT = 60;

export const RetriesExceededError = 'useRemoteData: Retries exceeded';

type RemoteDataRetry = {
    timestamp: number;
    tries: number;
};

export interface DeferredRemoteData<T> {
    data: RemoteData<T>;
    retry?: () => void;
}
const hasNonNullableProperties = <T>(
    obj: T,
): obj is {
    [K in keyof T]: NonNullable<T[K]>;
} => {
    if (!obj) {
        return true; // allow undefined
    }

    for (const key in obj) {
        // eslint-disable-next-line no-restricted-syntax
        if (obj[key] === null || obj[key] === undefined) {
            return false;
        }
    }
    return true;
};

type NonNullableObject<T> = {
    [K in keyof T]: NonNullable<T[K]>;
};

/**
 * Encapsulate fetching remote data with retry logic and cool down phase after 3 retries.
 *
 * TL;DR
 * Only add dependencies that are absolutely required for the resource callback. The resource won't be fetched
 * until all optional dependencies are NonNullable.
 *
 * To avoid dependency/resolver threshing and other unnecessary resolver callback conditional handling in case
 * dependencies do not meet the nonnullable condition, this util allows pass in dependencies that prevent the
 * resource to be fetched unless all defined dependencies are NonNullable.
 *
 * @param resource
 * @param validator
 * @param dependencies WARNING these dependencies work different than useEffect dependencies. refer description
 * @param cooldownTimeInSec
 * @returns
 */
export const useRemoteData = <T, D>(
    resource: (params: NonNullableObject<D>) => Promise<{ data: any }>,
    validator: (value: unknown) => value is T,
    dependencies?: D,
    cooldownTimeInSec: number = COOLDOWN_IN_SEC_DEFAULT,
): DeferredRemoteData<T> => {
    const [data, setState] = useState<RemoteData<T>>(notAsked());
    const [tries, setTries] = useState<RemoteDataRetry>({ timestamp: unix(), tries: 0 });

    const callback = () => {
        // make sure data resolver is only called when dependencies are met or dependency object is empty
        if (hasNonNullableProperties(dependencies)) {
            remoteDataStateResolver(
                () => {
                    return resource(dependencies as unknown as NonNullableObject<D>); // ignore the empty dependency case. Don't know any better way to tell TS to eat this without breaking the generic type resolving :/
                },
                validator,
                (deferredData) => {
                    setState(deferredData);
                },
            );
        }

        // we do not need to cleanup on the final
    };

    const retry = () => {
        // lock retry after, whatever is defined in MAX_RETRIES, tries for defined cool down time
        if (tries.tries === MAX_RETRIES && tries.timestamp + cooldownTimeInSec > unix()) {
            setState(failed(Error(RetriesExceededError)));
        } else {
            setState(notAsked()); // release error state
            setTries({
                timestamp: unix(),
                tries: tries.tries === MAX_RETRIES ? 1 : tries.tries + 1, // reset if retry lock was released otherwise count up
            });
            callback();
        }
    };

    useEffect(callback, dependencies ? Object.values(dependencies) : []);

    return { data, retry };
};
