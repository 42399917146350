/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, FunctionComponent } from 'preact';
import tileFallback from '@exaring/assets/images/tileFallback.png';
import { CSS } from '../../theme';
import { Typography } from '../../Typography/Typography';
import { StyledTileContainer } from '../parts/StyledTileContainer';
import { StyledTileImage } from '../parts/StyledTileImage';
import { StyledMetaSpan } from '../parts/StyledMetaSpan';
import { headlineStyle } from '../styles';
import { StyledTileContent } from '../parts/StyledTileContent';
import { VideoTileImageContainer } from '../parts/VideoTileImageContainer';

export const SmallTile: FunctionComponent<{
    css?: CSS;
    className?: string;
    title?: string;
    channel?: string;
    imageSrc?: string;
    locked?: boolean;
    onMediaPlayout?: () => void;
    onOpenDetails?: () => void;
    hidePlayOverlay?: boolean;
}> = ({
    css,
    className,
    title,
    channel,
    imageSrc,
    locked,
    hidePlayOverlay = false,
    onMediaPlayout,
    onOpenDetails,
}) => {
    return (
        <StyledTileContainer
            size={{
                '@initial': 'mobile',
                '@sm': 'default',
            }}
            css={css}
            className={className}
        >
            <VideoTileImageContainer
                locked={locked}
                onClick={onMediaPlayout}
                hidePlayOverlay={hidePlayOverlay}
                css={{
                    '& > span': {
                        // Fix for safari, the height is set to 101% to avoid the pixel flash
                        // https://exaring.atlassian.net/browse/TVFUS-52083
                        height: '101%',
                    },
                }}
            >
                <StyledTileImage src={imageSrc} srcFallback={tileFallback} />
            </VideoTileImageContainer>

            <StyledTileContent onClick={onOpenDetails}>
                <Typography css={headlineStyle} variant="h2">
                    {title}
                </Typography>
                <StyledMetaSpan>{channel}</StyledMetaSpan>
            </StyledTileContent>
        </StyledTileContainer>
    );
};
