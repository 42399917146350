/* eslint-disable max-classes-per-file */
/* eslint-disable no-restricted-syntax */

/* extracted from https://github.com/google/shaka-player/blob/master/lib/abr/
   changes to the original algorithm are marked with "waipu" */

/*! @license
 * Shaka Player
 * Copyright 2016 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

import shaka from 'shaka-player/dist/shaka-player.compiled';

const inRange = (x: number, min: number, max: number): boolean => {
    return x >= min && x <= max;
};

/**
 * @param variant
 * @param restrictions
 *   Configured restrictions from the user.
 * @param maxHwRes
 *   The maximum resolution the hardware can handle.
 *   This is applied separately from user restrictions because the setting
 *   should not be easily replaced by the user's configuration.
 */
const meetsRestrictions = (
    variant: shaka.extern.Variant,
    restrictions: shaka.extern.Restrictions,
    maxHwRes: { width: number; height: number },
): boolean => {
    const { video, bandwidth } = variant || {};

    // |video.width| and |video.height| can be undefined, which breaks
    // the math, so make sure they are there first.
    if (video && video.width && video.height) {
        if (
            !inRange(
                video.width,
                restrictions.minWidth,
                Math.min(restrictions.maxWidth, maxHwRes.width),
            )
        ) {
            return false;
        }

        if (
            !inRange(
                video.height,
                restrictions.minHeight,
                Math.min(restrictions.maxHeight, maxHwRes.height),
            )
        ) {
            return false;
        }

        if (!inRange(video.width * video.height, restrictions.minPixels, restrictions.maxPixels)) {
            return false;
        }
    }

    // |video.frameRate| can be undefined, which breaks
    // the math, so make sure they are there first.
    if (video && video.frameRate) {
        if (!inRange(video.frameRate, restrictions.minFrameRate, restrictions.maxFrameRate)) {
            return false;
        }
    }

    if (!inRange(bandwidth, restrictions.minBandwidth, restrictions.maxBandwidth)) {
        return false;
    }

    return true;
};

/**
 * @param restrictions
 * @param variants
 * @return variants filtered according to
 *   |restrictions| and sorted in ascending order of bandwidth.
 */
const filterAndSortVariants = (
    restrictions: shaka.extern.Restrictions | undefined | null,
    variants: shaka.extern.Variant[],
): shaka.extern.Variant[] => {
    let newVariants = variants;
    if (restrictions) {
        newVariants = variants.filter((variant) =>
            meetsRestrictions(
                variant,
                restrictions,
                /* maxHwRes= */ { width: Infinity, height: Infinity },
            ),
        );
    }
    return newVariants.sort((v1, v2) => v1.bandwidth - v2.bandwidth);
};

/**
 * @param halfLife The quantity of prior samples (by weight) used
 *   when creating a new estimate.  Those prior samples make up half of the
 *   new estimate.
 */
class Ewma {
    alpha_: number;

    estimate_: number;

    totalWeight_: number;

    constructor(halfLife: number) {
        /**
         * Larger values of alpha expire historical data more slowly.
         * @private {number}
         */
        this.alpha_ = Math.exp(Math.log(0.5) / halfLife);

        /** @private {number} */
        this.estimate_ = 0;

        /** @private {number} */
        this.totalWeight_ = 0;
    }

    /**
     * Takes a sample.
     *
     * @param {number} weight
     * @param {number} value
     */
    sample(weight: number, value: number) {
        const adjAlpha = this.alpha_ ** weight;
        const newEstimate = value * (1 - adjAlpha) + adjAlpha * this.estimate_;

        if (!Number.isNaN(newEstimate)) {
            this.estimate_ = newEstimate;
            this.totalWeight_ += weight;
        }
    }

    /**
     * @return {number}
     */
    getEstimate() {
        const zeroFactor = 1 - this.alpha_ ** this.totalWeight_;
        return this.estimate_ / zeroFactor;
    }
}

class EwmaBandwidthEstimator {
    fast_: Ewma;

    slow_: Ewma;

    bytesSampled_: number;

    minTotalBytes_: number;

    minBytes_: number;

    constructor() {
        /**
         * A fast-moving average.
         * Half of the estimate is based on the last 2 seconds of sample history.
         */
        this.fast_ = new Ewma(2);

        /**
         * A slow-moving average.
         * Half of the estimate is based on the last 5 seconds of sample history.
         */
        this.slow_ = new Ewma(5);

        /**
         * Number of bytes sampled.
         */
        this.bytesSampled_ = 0;

        /**
         * Minimum number of bytes sampled before we trust the estimate.  If we have
         * not sampled much data, our estimate may not be accurate enough to trust.
         * If bytesSampled_ is less than minTotalBytes_, we use defaultEstimate_.
         * This specific value is based on experimentation.
         */
        // waipu: move up from 128k to 1280k to avoid switching up from judging first audio segment
        this.minTotalBytes_ = 128e4; // 1280kB

        /**
         * Minimum number of bytes, under which samples are discarded.  Our models
         * do not include latency information, so connection startup time (time to
         * first byte) is considered part of the download time.  Because of this, we
         * should ignore very small downloads which would cause our estimate to be
         * too low.
         * This specific value is based on experimentation.
         */
        this.minBytes_ = 16e3; // 16kB
    }

    /**
     * Takes a bandwidth sample.
     *
     * @param durationMs The amount of time, in milliseconds, for a
     *   particular request.
     * @param numBytes The total number of bytes transferred in that
     *   request.
     */
    sample(durationMs: number, numBytes: number) {
        if (numBytes < this.minBytes_) {
            return;
        }

        const bandwidth = (8000 * numBytes) / durationMs;
        const weight = durationMs / 1000;

        this.bytesSampled_ += numBytes;
        this.fast_.sample(weight, bandwidth);
        this.slow_.sample(weight, bandwidth);
    }

    /**
     * Gets the current bandwidth estimate.
     *
     * @param defaultEstimate
     * @return The bandwidth estimate in bits per second.
     */
    getBandwidthEstimate(defaultEstimate: number): number {
        if (this.bytesSampled_ < this.minTotalBytes_) {
            return defaultEstimate;
        }

        // Take the minimum of these two estimates.  This should have the effect
        // of adapting down quickly, but up more slowly.
        const ret = Math.min(this.fast_.getEstimate(), this.slow_.getEstimate());
        return ret;
    }

    // waipu: added this function to reduce bandwidth estimation after zapping
    resetForZapping() {
        const oldVal = this.fast_.estimate_;
        const newVal = oldVal / 5;
        this.fast_.estimate_ = newVal;
        // console.debug('[shaka-abr] resetForZapping() old=%s new=%s', oldVal, newVal);
    }

    /**
     * @return True if there is enough data to produce a meaningful
     *   estimate.
     */
    hasGoodEstimate(): boolean {
        return this.bytesSampled_ >= this.minTotalBytes_;
    }
}

export class WaipuAbrManager {
    switch_: shaka.extern.AbrManager.SwitchCallback | null;

    enabled_: boolean;

    bandwidthEstimator_: EwmaBandwidthEstimator;

    variants_: shaka.extern.Variant[];

    playbackRate_: number;

    startupComplete_: boolean;

    lastTimeChosenMs_: number | null;

    config_: shaka.extern.AbrConfiguration | null;

    mediaElement_: HTMLMediaElement | null;

    constructor() {
        this.switch_ = null;
        this.enabled_ = false;
        this.bandwidthEstimator_ = new EwmaBandwidthEstimator();
        // TODO: Consider using NetworkInformation's change event to throw out an
        // old estimate based on changing network types, such as wifi => 3g.
        /**
         * A filtered list of Variants to choose from.
         */
        this.variants_ = [];
        this.playbackRate_ = 1;
        this.startupComplete_ = false;
        /**
         * The last wall-clock time, in milliseconds, when streams were chosen.
         */
        this.lastTimeChosenMs_ = null;
        this.config_ = null;
        this.mediaElement_ = null;
    }

    stop() {
        this.switch_ = null;
        this.enabled_ = false;
        this.variants_ = [];
        this.playbackRate_ = 1;
        this.lastTimeChosenMs_ = null;
        this.mediaElement_ = null;
        // Don't reset |startupComplete_|: if we've left the startup interval, we
        // can start using bandwidth estimates right away after init() is called.
    }

    init(switchCallback: shaka.extern.AbrManager.SwitchCallback) {
        this.switch_ = switchCallback;
        // waipu: added this call to blank bandwidth estimation after zapping
        this.bandwidthEstimator_.resetForZapping();
    }

    chooseVariant() {
        if (!this.config_) {
            return undefined;
        }

        // Get sorted Variants.
        let sortedVariants = filterAndSortVariants(this.config_.restrictions, this.variants_);
        const currentBandwidth = this.bandwidthEstimator_.getBandwidthEstimate(
            this.config_.defaultBandwidthEstimate,
        );
        if (this.variants_.length && !sortedVariants.length) {
            // If we couldn't meet the ABR restrictions, we should still play
            // something.
            // These restrictions are not "hard" restrictions in the way that
            // top-level or DRM-based restrictions are.  Sort the variants without
            // restrictions and keep just the first (lowest-bandwidth) one.
            // console.log(
            //     '[shaka-abr] No variants met the ABR restrictions. Choosing a variant by lowest bandwidth.',
            // );
            sortedVariants = filterAndSortVariants(/* restrictions= */ null, this.variants_);

            const first = sortedVariants[0];
            if (first) {
                sortedVariants = [first];
            }
        }
        // Start by assuming that we will use the first Stream.
        let chosen = sortedVariants[0] || undefined;
        for (let i = 0; i < sortedVariants.length; i += 1) {
            const item = sortedVariants[i];

            if (item) {
                const next = sortedVariants[i + 1];
                const playbackRate = !Number.isNaN(this.playbackRate_)
                    ? Math.abs(this.playbackRate_)
                    : 1;
                const itemBandwidth = playbackRate * item.bandwidth;
                const minBandwidth = itemBandwidth / this.config_.bandwidthDowngradeTarget;
                const nextBandwidth = playbackRate * (next || { bandwidth: Infinity }).bandwidth;
                const maxBandwidth = nextBandwidth / this.config_.bandwidthUpgradeTarget;
                if (currentBandwidth >= minBandwidth && currentBandwidth <= maxBandwidth) {
                    chosen = item;
                }
            }
        }
        this.lastTimeChosenMs_ = Date.now();
        return chosen;
    }

    enable() {
        this.enabled_ = true;
    }

    disable() {
        this.enabled_ = false;
    }

    segmentDownloaded(deltaTimeMs: number, numBytes: number) {
        this.bandwidthEstimator_.sample(deltaTimeMs, numBytes);
        if (this.lastTimeChosenMs_ !== null && this.enabled_) {
            this.suggestStreams_();
        }
    }

    getBandwidthEstimate() {
        return (
            this.config_ &&
            this.bandwidthEstimator_.getBandwidthEstimate(this.config_.defaultBandwidthEstimate)
        );
    }

    setVariants(variants: shaka.extern.Variant[]) {
        this.variants_ = variants;
    }

    playbackRateChanged(rate: number) {
        console.error('[shaka-abr] playbackRateChanged() rate=%s', rate);
        this.playbackRate_ = rate;
    }

    setMediaElement(mediaElement: HTMLMediaElement) {
        this.mediaElement_ = mediaElement;
    }

    configure(config: shaka.extern.AbrConfiguration) {
        this.config_ = config;
    }

    /**
     * Calls switch_() with the variant chosen by chooseVariant().
     */
    suggestStreams_() {
        if (!this.startupComplete_) {
            // Check if we've got enough data yet.
            if (!this.bandwidthEstimator_.hasGoodEstimate()) {
                // console.debug('[shaka-abr] Still waiting for a good estimate...');
                return;
            }
            this.startupComplete_ = true;
        } else {
            // Check if we've left the switch interval.
            const now = Date.now();
            const delta = now - (this.lastTimeChosenMs_ || 0);
            if (this.config_ && delta < this.config_.switchInterval * 1000) {
                // console.debug('[shaka-abr] Still within switch interval...');
                return;
            }
        }
        const chosenVariant = this.chooseVariant();
        // var bandwidthEstimate = this.bandwidthEstimator_.getBandwidthEstimate(
        //    this.config_.defaultBandwidthEstimate);
        // var currentBandwidthKbps = Math.round(bandwidthEstimate / 1000.0);
        if (chosenVariant) {
            // If any of these chosen streams are already chosen, Player will filter
            // them out before passing the choices on to StreamingEngine.
            this.switch_?.(chosenVariant);
        }
    }
}
