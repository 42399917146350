import { getBaseUrl } from '@exaring/utils';
import { post } from '../../request';

const base = getBaseUrl(window.location.href);
const SCHEDULER_BASE_URL = `https://recording-scheduler.${base}/api`;

/**
 * Create serial recording
 * WARNING it's not possible to omit the "optionals" title and channel.
 * NOTE
 * if you want to record a live recording and assign it to a group recording you have
 * to schedule the serial recording first and retrieve the groupId from this request
 * to hand it over to the live recording request.
 * SEE recordings.schedule to record a single recording
 */
export const scheduleSerial = (title?: string, channel?: string, seriesId?: string) =>
    post(
        `${SCHEDULER_BASE_URL}/serials`,
        {
            Accept: 'application/vnd.waipu.recording-scheduler-serial-recording-v1+json',
            'Content-Type': 'application/vnd.waipu.recording-scheduler-serials-v1+json',
        },
        {
            ...(title ? { title } : {}),
            ...(channel ? { channel } : {}),
            ...(seriesId ? { seriesId } : {}),
        },
    );

/**
 * Delete serial recording
 * NOTE
 * Aka: "Group Recordings" unfortunately BS is using different expressions for the same thing
 */
export const unscheduleSerials = (
    serialRecordingList: Array<number>,
    deleteOptions = {
        deleteFutureRecordings: false,
        deleteFinishedRecordings: false,
        deleteRunningRecordings: false,
    },
) =>
    post(
        `${SCHEDULER_BASE_URL}/delete-requests`,
        {
            Accept: 'application/vnd.waipu.recording-scheduler-serial-recordings-v1+json',
            'Content-Type':
                'application/vnd.waipu.recording-scheduler-delete-serial-recordings-v1+json',
        },
        {
            serialRecordings: serialRecordingList.map((id) => ({
                id,
                ...deleteOptions,
            })),
        },
    );
