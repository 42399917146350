/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';
import { classnames } from '@exaring/utils';

const NAVIGATION_BASE_CLASS = 'header--logged-in';

const decorateNameProp = (children, name) => {
    /* eslint-disable no-param-reassign */
    if (children) {
        return children.map((child) => {
            if (child?.props) {
                child.props.name = name;
            }
            return child;
        });
    }

    return children;
    /* eslint-enable no-param-reassign */
};

export const HorizontalSeparator = () => <div className="header--logged-in__bottom-separator" />;

export const Header = ({ children, className }) => {
    return <header className={classnames('header--logged-in', className)}>{children}</header>;
};

/**
 * generic navigation to cover all basic functionallity of a navigation item
 */
export const Navigation = ({ children, name = 'main' }) => {
    return (
        <nav className={`${NAVIGATION_BASE_CLASS}__${name}-nav`}>
            <ul>{decorateNameProp(children, `${name}`)}</ul>
        </nav>
    );
};

export const MainNavigation = ({ id, onBrandClick, children }) => {
    return (
        <div data-testid={id} className="header--logged-in__main-menu">
            <div className="header--logged-in__logo header--logged-in__logo--small">
                <a onClick={onBrandClick}>
                    <span className="icon icon--logo-small" />
                </a>
            </div>
            <Navigation>{children}</Navigation>
        </div>
    );
};

export const SubNavigation = ({ children }) => {
    return (
        <div className="header--logged-in__sub-menu">
            <Navigation name="sub">{children}</Navigation>
        </div>
    );
};
