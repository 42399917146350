/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { constants } from '../constants';
import { now } from '../../date';

/**
 * indicates if user has a free account
 * @param {object} token
 * @returns {boolean}
 */
export const isFreeUser = (token) => token?.userAssets?.account?.free || false;

/**
 * is IR allowed check
 * @param {object} token
 * @returns {boolean}
 */
export const isInstantRestartAllowed = (token) => token.userAssets.instantRestart;

/**
 * check if flag is set without concrete helper implementation
 * @param {JWT} token
 * @param {JwtTokenFlags} flag
 * @returns {boolean}
 */
export const isFlagSet = (token, flag) => token?.f?.includes(flag) || false;

/**
 * indicates if we are allowed to track user
 * @param {object} token
 * @returns {boolean}
 */
export const isTrackingForbidden = (token) => isFlagSet(token, 'dnt');

/**
 * indicates if user is in dunning level 2
 * @param {object} token
 * @returns {boolean}
 */
export const isDunningLevel2 = (token) => isFlagSet(token, 'dl2');

export const isRequiredFieldsMissing = (jwt) => isFlagSet(jwt, 'mrf');

/**
 * compares token expire time with reference time in case reference time
 * is smaller given token expiring time functions returns `false` otherwise `true`
 * @param {object} token
 * @param {number} refTime given reference time as unix-timestamp
 * @returns {boolean}
 */
export const isTTLValid = (token, refTime = now() + constants.TOKEN_TIMECHECK_THRESHOLD) =>
    token.exp > refTime;

/**
 * indicates if user has a confirmed account
 * @param {object} token
 * @returns {boolean}
 */
export const isUserConfirmed = (token) => token.doiStatus.includes('CONFIRMED') || false;

export const isNotExpired = (jwt) => typeof jwt?.exp === 'number' && jwt.exp * 1000 > Date.now();
