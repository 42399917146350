import {
    DNDPlugin,
    NodeEventData,
    ParentConfig,
    handleDragoverNode,
    handleDragstart,
    handleEnd,
    handleTouchOverNode,
    handleTouchstart,
    performSort,
    updateConfig,
} from '@formkit/drag-and-drop';
import { useDragAndDrop } from '@formkit/drag-and-drop/react';
import { MutableRef, useCallback, useRef } from 'preact/hooks';
import { enableImprovedScroll } from './scroll.util';

export type MoveDirection = 'before' | 'after';

type CreateConfig = (p: {
    setItemsRef: MutableRef<(items: any[]) => void>;
    dragStartIndexRef: MutableRef<number | undefined>;
    dragOverIndexRef: MutableRef<number | undefined>;
    onDragStart: (index: number) => void;
    onDragOver: (index: number, moveDirection: MoveDirection) => void;
    onDragEnd: () => void;
    onReorder: (items: any[], draggedItem?: any) => void;
}) => Partial<ParentConfig<unknown>>;

// Provides a special config that uses existing methods to override the default behaviour
const createConfig: CreateConfig = ({
    setItemsRef,
    dragStartIndexRef,
    dragOverIndexRef,
    onDragStart,
    onDragOver,
    onDragEnd,
    onReorder,
}) => {
    // By Default, the sorting happens after item is moved over another one.
    // In our case, we need to sort items only after they are dropped.
    // Therefore disabling the `performSort` logic by providing empty function.
    const customPerformSort: typeof performSort = () => {};

    // Remember which item (it's index) the user starts dragging
    const dragStart = (data: NodeEventData<any>) => {
        dragStartIndexRef.current = data.targetData.node.data.index;
        onDragStart(dragStartIndexRef.current);
        enableImprovedScroll(true);
    };

    const customHandleDragstart: typeof handleDragstart = (data) => {
        handleDragstart(data);
        dragStart(data);
    };

    const customHandleTouchStart: typeof handleTouchstart = (data) => {
        handleTouchstart(data);
        dragStart(data);
    };

    // Remember over which item (it's index) user draggs the item
    const dragOver = (data: NodeEventData<any>) => {
        const { index } = data.targetData.node.data;

        if (index !== dragOverIndexRef.current && dragStartIndexRef.current !== undefined) {
            dragOverIndexRef.current = index;

            const moveDirection: MoveDirection =
                dragStartIndexRef.current > dragOverIndexRef.current ? 'before' : 'after';

            onDragOver(dragOverIndexRef.current, moveDirection);
        }
    };

    const customHandleDragoverNode: typeof handleDragoverNode = (data) => {
        handleDragoverNode(data);
        dragOver(data);
    };

    const customHandleTouchOverNode: typeof handleTouchOverNode = (data) => {
        handleTouchOverNode(data);
        dragOver(data.detail);
    };

    // Knowing the indexes of dragged item and the one underneath
    // we can now sort the whole array
    const customHandleEnd: typeof handleEnd = (e) => {
        handleEnd(e);
        enableImprovedScroll(false);

        if (dragOverIndexRef.current !== undefined && dragStartIndexRef.current !== undefined) {
            const { parent } = e.targetData;
            const [...items] = parent.data.getValues(parent.el);

            const draggedValue = items[dragStartIndexRef.current] as (typeof items)[0];
            items.splice(dragStartIndexRef.current, 1);

            items.splice(dragOverIndexRef.current, 0, draggedValue);

            setItemsRef.current(items);

            onDragEnd();

            dragStartIndexRef.current = undefined;
            dragOverIndexRef.current = undefined;

            onReorder(items, draggedValue);
        } else {
            onDragEnd();
        }
    };

    return {
        performSort: customPerformSort,
        handleDragstart: customHandleDragstart,
        handleDragoverNode: customHandleDragoverNode,
        handleTouchstart: customHandleTouchStart,
        handleTouchOverNode: customHandleTouchOverNode,
        handleEnd: customHandleEnd,
        // Disable scrolling when dragging to use the improved scrolling util
        scrollBehavior: {
            x: 1.0,
            y: 1.0,
            scrollOutside: false,
        },
    };
};

type Result<T> = [
    parent: React.RefObject<HTMLElement>,
    itmes: T[],
    setItems: React.Dispatch<React.SetStateAction<T[]>>,
    updateCfg: (config: Partial<ParentConfig<T>>) => void,
];

type Handlers<T> = {
    onDragStart: (index: number) => void;
    onDragOver: (index: number, moveDirection: MoveDirection) => void;
    onDragEnd: () => void;
    onReorder: (items: T[], draggedItem?: any) => void;
};

export const useWaipuDragAndDrop = <T>(list: T[], handlers: Handlers<T>): Result<T> => {
    const setItemsRef = useRef<(items: any[]) => void>(() => {});
    const dragStartIndexRef = useRef<number | undefined>(undefined);
    const dragOverIndexRef = useRef<number | undefined>(undefined);

    const waipuGridPlugin = useCallback<DNDPlugin>((parent) => {
        const config = createConfig({
            setItemsRef,
            dragOverIndexRef,
            dragStartIndexRef,
            onDragStart: handlers.onDragStart,
            onDragOver: handlers.onDragOver,
            onDragEnd: handlers.onDragEnd,
            onReorder: handlers.onReorder,
        });

        // expected API of plugin
        // https://drag-and-drop.formkit.com/#custom-plugin
        return {
            setup() {
                updateConfig(parent, config);
            },
            teardown() {},
            setupNode() {},
            tearDownNode() {},
            setupNodeRemap() {},
            tearDownNodeRemap() {},
        };
    }, []);

    const [parent, items, setItems, updateCfg] = useDragAndDrop(list, {
        plugins: [waipuGridPlugin],
    });

    setItemsRef.current = setItems;

    const result = [parent, items, setItems, updateCfg];

    return result as Result<T>;
};
