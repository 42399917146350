/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';

// type Props = {
//     name: string,
//     label: string,
//     checked: boolean
// };

export default ({ checked, name, label }) => {
    return (
        <label className="form-item form-item--checkbox form-item--fineprint-checkbox">
            <input type="checkbox" className="form-item__element" checked={checked} name={name} />
            <span className="form-item__label">{label}</span>
        </label>
    );
};
