/*
 *  Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, FunctionComponent } from 'preact';
import { Colors, styled, CSS } from '@exaring/ui/components-styled/theme';
import { GridContainer, GridItem } from '@exaring/ui/components-styled/Grid';
import { VideoTileImageContainer } from '@exaring/ui/components-styled/Tile/parts/VideoTileImageContainer';
import { StyledImageOverlay } from '@exaring/ui/components-styled/Tile/parts/StyledImageOverlay';
import ArrowBackIcon from '@exaring/assets/icons/arrow-back.svg';
import { useHover } from '@exaring/ui/hooks/useHover';
import { StyledMetaSpan } from '@exaring/ui/components-styled/Tile/parts/StyledMetaSpan';
import {
    diffInSeconds,
    formatSecondsDuration,
    diffInDays,
    now,
    formatDateTime,
} from '@exaring/utils/date';
import { Badge } from '@exaring/ui/components-styled/Badge/Badge';
import { CircleIcon, TrashcanIcon } from '@exaring/ui/components-styled/Tile/parts/StyledMenu';
import { RecordingStatus as RecordingStatusOverview } from '@exaring/networking/types/Recording/Recording';
import {
    RecordingStatus,
    RecordingEpgData,
    RecordingDetails,
} from '@exaring/networking/types/Recording/RecordingDetails';
import { assetUrl, epgInfoSeason } from '../../../helper';
import {
    GridContentContainer,
    HeroContainer,
    HeroContent,
    HeroImage,
    TextWrapper,
    Title,
} from './TileHeroShared';
import * as RemoteData from '../../../state/utils/RemoteData';

const iconStyles = {
    position: 'absolute',
    left: 'calc(50% - calc(32px / 2))',
    bottom: '26px',
};

const StyledTrashcanIcon = styled(TrashcanIcon, iconStyles);
const StyledCircleIcon = styled(CircleIcon, iconStyles);

const HeroMetaSpan = styled(StyledMetaSpan, {
    marginTop: '10px',
});

const StyledVideoTileImageContainer = styled(VideoTileImageContainer, {
    height: '100%',
    width: '100%',

    [`&:hover ${StyledImageOverlay}`]: {
        cursor: 'pointer',
        backgroundColor: 'inherit',
    },
});

const defaultSize = {
    width: '44px',
    height: '44px',
};

export const MoreArrow = styled(ArrowBackIcon, {
    position: 'absolute',
    right: '10px',
    bottom: '15px',
    ...defaultSize,
    transform: 'rotate(180deg)',
    variants: {
        size: {
            default: defaultSize,
            large: { width: '64px', height: '64px' },
        },
    },
});

const NewBadge = styled(Badge, {
    background: '$green',
    position: 'absolute',
    top: 5,
    left: 5,
    color: '$black',
});

const RecordingBadge = styled(Badge, {
    display: 'flex',
    background: '$white',
    position: 'absolute',
    top: 5,
    left: 5,
    color: '$debianRed',
});

const DaysRemainingBadge = styled('div', {
    display: 'flex',
    borderRadius: '$4',
    fontSize: '14px',
    font: 'OpenSans-Light, OpenSans',
    padding: '3px 10px',
    textTransform: 'uppercase',
    background: '$melancholicStone',
    color: '$white',
});

const BulletGlyph = styled('span', {
    flex: '1',
    marginTop: '-1px',
    letterSpacing: '1.5px',
    '&::before': {
        content: '●',
    },
});

export const RecordingStatusBadge: FunctionComponent<{
    recording: {
        isNew?: boolean | undefined | null;
        status: RecordingStatus | RecordingStatusOverview;
    };
}> = ({ recording }) => {
    switch (recording.status) {
        case 'RECORDING':
            return (
                <RecordingBadge>
                    <BulletGlyph /> Rec
                </RecordingBadge>
            );
        case 'FINISHED':
            return recording.isNew ? <NewBadge>Neu</NewBadge> : null; // eslint-disable-line no-restricted-syntax
        default:
            return null; // eslint-disable-line no-restricted-syntax
    }
};

const formatRemainingDays = (remainingDays: number) => {
    const pluralizedUnit = remainingDays === 1 ? 'Tag' : 'Tage';

    return `Noch ${remainingDays} ${pluralizedUnit}`;
};

const epgTitle = (epgData: RecordingEpgData) => {
    return epgData.series?.episodeTitle || epgData.textContent.title;
};

export const TileHero: FunctionComponent<{
    data: RemoteData.RemoteData<RecordingDetails>;
    seasonList?: string[];
    stationDisplay?: string;
    duration?: number; // fallback for scheduled recordings
    onPlayContent?: () => void;
    onOpenDetails?: () => void;
    onDelete?: any;
    onToggleSelection?: any;
    isSelected?: boolean;
    selectable?: boolean;
    layout?: 'default' | 'modal';
    hover?: boolean;
    css?: CSS;
}> = ({
    data,
    seasonList,
    css,
    selectable,
    hover,
    layout = 'default',
    onToggleSelection,
    onDelete,
    isSelected,
    stationDisplay = '',
    duration,
    onPlayContent,
    onOpenDetails,
}) => {
    const isLoading = RemoteData.isLoading(data);
    const isLoaded = RemoteData.didSucceed(data);
    const isNotLoaded = RemoteData.didNotAsk(data);
    const recording = isLoaded ? data.value : undefined;

    const [hoverRef, isHovered] = useHover<HTMLDivElement>();
    const [hoverContentRef, isContentHovered] = useHover<HTMLDivElement>();

    let durationInSec = 0;
    let imgSrc = '';

    try {
        const url = recording?.programDetails.imageUrls?.[0];

        if (url) {
            const imgUrl = new URL(url);
            imgUrl.searchParams.set('res', '1024x576'); // setting or overwriting existing res url param
            imgSrc = imgUrl.href;
        }
    } catch (_) {
        // if url is malformed we do not track an error...
    }

    const isLocked = recording?.locked ?? false;
    const isSerialRecording = !!recording?.recordingGroup; // if recording is part of a recordingGroup it's a "serial recording"
    const expiresAt =
        recording?.expiresAt && recording?.expiresAt > now().toString()
            ? Math.ceil(diffInDays(recording?.expiresAt, now()))
            : 0;

    if (recording) {
        if (recording.status === 'RECORDING') {
            // this calculation is actually the same BS is doing for the recording overview data `durationSeconds`,
            // but i decided not to drill down the props of overview to details and mix them.
            // This value is the approximation of the recording length when the recording does not get stopped manually
            durationInSec = diffInSeconds(recording?.startTime, recording?.stopTime);
        } else if (recording.status === 'SCHEDULED') {
            durationInSec = duration ?? 0;
        } else {
            durationInSec = recording.recordedNetTimeSeconds ?? 0;
        }
    }

    return (
        <HeroContainer css={css}>
            <GridContainer>
                <HeroContent xs={6}>
                    <HeroImage
                        src={imgSrc}
                        srcFallback={assetUrl('background-waipu-gradient.png')}
                    />
                </HeroContent>
            </GridContainer>
            <GridContentContainer
                isLoading={isLoading}
                isNotLoaded={isNotLoaded}
                isMediaHovered={layout === 'default' && !isSerialRecording && isHovered}
                detailsDisabled={!onOpenDetails}
            >
                <HeroContent ref={hoverRef} xs={6}>
                    {layout === 'default' && isLoaded && (
                        <StyledVideoTileImageContainer
                            locked={isLocked}
                            iconsSize={64}
                            hideAllOverlays={isSerialRecording}
                            onClick={isSerialRecording ? onOpenDetails : onPlayContent}
                        />
                    )}
                    {layout === 'default' && recording && (
                        <RecordingStatusBadge recording={recording} />
                    )}
                </HeroContent>
                <HeroContent ref={hoverContentRef} xs={6} onClick={onOpenDetails}>
                    <GridContainer
                        css={{
                            height: '100%',
                            padding: '30px 20px',
                            '@md': {
                                alignContent: 'center',
                            },
                            position: 'relative',
                        }}
                    >
                        {recording && (
                            <TextWrapper>
                                <Title>
                                    {recording.programDetails && layout === 'default'
                                        ? epgTitle(recording.programDetails)
                                        : recording.programDetails.textContent.title}
                                </Title>
                                {expiresAt > 0 && (
                                    <GridItem css={{ display: 'flex', marginTop: '10px' }}>
                                        <DaysRemainingBadge>
                                            {formatRemainingDays(expiresAt)}
                                        </DaysRemainingBadge>
                                    </GridItem>
                                )}
                                {layout === 'default' && (
                                    <HeroMetaSpan css={{ color: '$lightBlue' }}>
                                        {formatDateTime(
                                            recording.programDetails.startTime,
                                            !!durationInSec && !!recording.programDetails.startTime,
                                        )}
                                        {formatSecondsDuration(durationInSec)}
                                    </HeroMetaSpan>
                                )}
                                <HeroMetaSpan css={{ color: '$mediumGray' }}>
                                    {recording?.programDetails &&
                                        epgInfoSeason(
                                            recording.programDetails.contentMeta?.mainGenre,
                                            seasonList?.length ?? 0,
                                            stationDisplay,
                                        )}
                                </HeroMetaSpan>
                                {layout === 'default' && !selectable && isContentHovered && (
                                    <StyledTrashcanIcon hover={hover} onClick={onDelete} />
                                )}
                                {layout === 'default' && selectable && (
                                    <StyledCircleIcon
                                        onClick={onToggleSelection}
                                        checked={isSelected}
                                    />
                                )}
                                {layout === 'default' && isSerialRecording && (
                                    <MoreArrow
                                        fill={Colors.white}
                                        width="100%"
                                        height="100%"
                                        size={{
                                            '@initial': 'default',
                                            '@md': 'large',
                                        }}
                                    />
                                )}
                            </TextWrapper>
                        )}
                    </GridContainer>
                </HeroContent>
            </GridContentContainer>
        </HeroContainer>
    );
};
