/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */
/* eslint-disable no-restricted-syntax */
/* eslint no-console: ["error", { allow: ["warn", "error"] }] */

import {
    logError,
    logErrorWithDescription,
    addBreadcrumbMessage,
    initStreamLogging,
    parseMetaData,
    logStreamEvent,
    unix,
    loadFileAsync,
} from '@exaring/utils';
import { getCurrentProgram, getStreamUrl, getVideoStreamAndContentType } from './helper';
import constants from '../constants';
import { PLAYOUT_LIVE_RECORDING } from './constants';
import { epgStore, userStore } from '../state/Store';
import initPlayer, { createPlayer } from '../player/shaka';
import { drmConfig } from '../player/drm';
import { parseDuration } from '../player/shaka-xmlutil';
import { enableDaiTracking } from './helper/dai';

const dai = {
    eventCues: [],
    eventTrack: undefined,
    contactedTrackingUrls: {},
};

const resetDai = () => {
    dai.eventCues = [];
    dai.eventTrack.removeEventListener('cuechange', cuechange);
    dai.eventTrack = undefined;
    dai.contactedTrackingUrls = {};
};

const cuechange = () => {
    try {
        dai.eventTrack?.activeCues?.forEach((cue) => {
            /* at this point eventTrack.activeCues[i].text contains an URL
            to which we send an HTTP GET request for tracking */
            const url = cue.text;
            if (!dai.contactedTrackingUrls[url]) {
                httpFireAndForget(url);
                dai.contactedTrackingUrls[url] = true;
            }
        });
    } catch (err2) {
        console.error('[Player] cuechange', err2);
        throw err2;
    }
};

/* for ad tracking events all the required information is in the URL
   and we do not care if the request fails */
const httpFireAndForget = (url) => {
    fetch(url);
};

const isTextTrackSupported = () => !!window.VTTCue;

const pushTrackingEvent = (url, timeStamp) => {
    /* we add the events as subtitles with a duration of one
      second. Shorter cues may not trigger the cuechange event.
      Longer cues could cause overlap of the subtitles
      and thus we would fire the same URLs twice */
    if (!isTextTrackSupported()) {
        return;
    }
    if (dai.eventTrack) {
        dai.eventTrack.addCue(new VTTCue(timeStamp, timeStamp + 1.0, url));
    } else {
        dai.eventCues.push(new VTTCue(timeStamp, timeStamp + 1.0, url));
    }
};

const loadMuxSdk = async () => {
    try {
        if (!window[constants.SHAKA_MUX_FUNC_NAME]) {
            await loadFileAsync(constants.SHAKA_MUX_SRC);
        }
    } catch (e) {
        addBreadcrumbMessage('error while loading MUX-SDK', 'player', 'warning');
    }
};

export const initialState = {
    playerId: 'player-parent',
    isLoaded: false, // player loaded playout ready to play
    isMounted: false,
    isBusy: false,
    isPlaying: false,
    isUnloading: false,
    isMuted: true, // @see player config
    isFinished: false,
    volume: -1, // unkown
    timestamp: 0,
    errorType: null,
    errorCode: null,
    errorMessage: null,
    // cache states for playout promise queue
    dashUrl: '',
    hlsUrl: '',
    startAt: 0,
    audioTracks: {
        languages: [],
        selectedLanguage: undefined,
    },
    subtitleTracks: {
        languages: [],
        selectedLanguage: undefined,
    },
    prevState: null, // rollback state
};

let player;
let playerInstance;
let progressInterval;

const deinitProgressInterval = (interval) => {
    clearInterval(interval);
};

const initProgressInterval = (cb) => {
    return setInterval(cb, constants.OSD_PLAYER_PROGRESS_UPDATE_INTERVAL);
};

const muxLogging = async (
    { playout: playoutStore, player: playerStore },
    _player,
    event,
    additionalData,
) => {
    if (playoutStore.enableStreamLogging && !!getStreamUrl(playerStore)) {
        const epgData = await getCurrentProgram(playoutStore, epgStore());
        logStreamEvent(_player, event, {
            ...additionalData,
            ...parseMetaData(
                epgData,
                getVideoStreamAndContentType(playoutStore),
                getStreamUrl(playerStore),
            ),
        });
    }
};

export const playerInfo = () =>
    player
        ? `${constants.PLAYER_NAME} ${player?.playerVersion() || ''}`.trim()
        : 'Player not initialized';
export const playerTechInfo = async () => player?.playerTechnicalInfo();

const actions = (store) => {
    const getPlayerState = (prop) => {
        return store.getState().player[prop];
    };

    const setPlayerState = (nextState) => {
        const { player: playerState } = store.getState();

        store.setState({
            player: { ...playerState, ...nextState },
        });

        return store.getState();
    };

    const setPlayerIsBusy = (flag) => {
        return setPlayerState({ isBusy: !!flag });
    };

    const isReady = () => {
        return getPlayerState('isMounted') && getPlayerState('isLoaded');
    };

    const progressUpdate = async () => {
        // workaround because live recording never receive finish event
        const { recording, playoutType } = store.getState().playout;
        const currentTime = player.getCurrentTime();

        if (playoutType === PLAYOUT_LIVE_RECORDING && currentTime >= unix(recording.stopTime)) {
            setPlayerState({ isFinished: true });
            await player.unload();
        }

        setPlayerState({ timestamp: currentTime });
    };

    const __actions = {
        setAudioLanguage: ({ player: playerState } = store.getState(), lang) => {
            player.setAudioLanguage(lang);

            setPlayerState({
                audioTracks: {
                    ...playerState.audioTracks,
                    selectedLanguage: lang,
                },
            });
        },
        setTextLanguage: ({ player: playerState } = store.getState(), lang) => {
            player.setTextLanguage(lang);

            setPlayerState({
                subtitleTracks: {
                    ...playerState.subtitleTracks,
                    selectedLanguage: lang,
                },
            });
        },
        playerMount: async (
            { player: playerState } = store.getState(),
            flag = false,
            rollbackState = false,
        ) => {
            await loadMuxSdk();
            let { prevState } = playerState;

            if (rollbackState && prevState) {
                setPlayerState(prevState);
            }

            if (flag) {
                const finished = () => {
                    setPlayerState({
                        isFinished: true,
                    });
                };

                const unloaded = () => {
                    resetDai();
                    if (!getPlayerState('isUnloading')) {
                        return;
                    }
                    setPlayerState({
                        // reset player state
                        isPlaying: initialState.isPlaying,
                        isLoaded: initialState.isLoaded,
                        isFinished: initialState.isFinished,
                        timestamp: initialState.timestamp,
                        errorCode: initialState.errorCode,
                        errorType: initialState.errorType,
                        errorMessage: initialState.errorMessage,
                    });

                    deinitProgressInterval(progressInterval);
                };

                const loaded = () => {
                    setPlayerState({
                        errorCode: initialState.errorCode, // always wipe previous errors from state when new content is loaded (unloaded will not be called on zapping for example)
                        errorType: initialState.errorType,
                        errorMessage: initialState.errorMessage,
                        isPlaying: false,
                        isLoaded: true,
                    });
                };

                const playing = () => {
                    const audioTracks = player.getAudioInfo();
                    const subtitleTracks = player.getTextInfo();

                    setPlayerState({
                        isPlaying: true,
                        audioTracks,
                        subtitleTracks,
                    });

                    deinitProgressInterval(progressInterval);
                    progressInterval = initProgressInterval(progressUpdate);
                };

                const seek = () => {
                    progressUpdate(); // immediately fire progress update
                };

                const seeked = () => {
                    setPlayerState({
                        startAt: 0,
                        isBusy: false,
                    });

                    // update recording position after 2s to make sure, that the seeked timestamp is available
                    window.setTimeout(() => {
                        if (!isReady()) {
                            return;
                        }

                        store.api.playout.updateBackendRecordingPosition();
                    }, 2000);
                };

                const timeshift = () => {
                    setPlayerState({
                        timestamp: getPlayerState('startAt'), // immediately set timestamp to timeshift position
                    });
                };

                const timeshifted = () => {
                    setPlayerState({
                        startAt: 0,
                        isBusy: false,
                    });
                };

                const paused = () => {
                    setPlayerState({
                        isPlaying: false,
                    });

                    deinitProgressInterval(progressInterval);
                };

                const error = async (errorType, errorCode, message) => {
                    await player.unload();

                    setPlayerState({
                        errorType,
                        errorCode,
                        errorMessage: message,
                        isBusy: false,
                    });

                    const state = store.getState();

                    muxLogging(state, playerInstance, 'error', {
                        player_error_code: errorCode,
                        player_error_message: message,
                    });
                };

                /* needed for DAI tracking - we can add a text track only after 'loadedmetadata' but we receive
                all events from a static manifest beforehand, so we store them in
                the dai.eventCues array in between */
                const loadedmetadata = () => {
                    try {
                        if (!isTextTrackSupported()) {
                            return;
                        }

                        dai.eventTrack = playerInstance
                            .getMediaElement()
                            .addTextTrack('subtitles', 'trackingEvents', 'en');
                        dai.eventCues.forEach((cue) => dai.eventTrack.addCue(cue));
                        dai.eventTrack.addEventListener('cuechange', cuechange);
                    } catch (err) {
                        console.error('[Player] loadedmetadata', err);
                        throw err;
                    }
                };

                /* needed for DAI tracking - The idea is that event tracking JSON is received
                in the player's 'timelineregionadded' event, the individual tracking
                events are then converted into subtitle cues and passed to the HTML5
                video element. This way the video element takes care about the timing
                and we just have to contact the tracking URLs whenever the 'cuechange'
                event informs us about active subtitle cues. */
                const timelineregionadded = (ev) => {
                    try {
                        if (
                            ev.detail.eventElement.parentNode.getAttribute('schemeIdUri') !==
                            'http://dashif.org/identifiers/vast30'
                        ) {
                            return;
                        }
                        /* the DASH XML hierarchy is Period/EventStream/Event so we can get
                        the period duration from the event's grandparent */
                        const periodXml = ev.detail.eventElement.parentNode.parentNode;
                        const durationXml = periodXml.getAttribute('duration');
                        const data = JSON.parse(ev.detail.eventElement.textContent);
                        const duration = parseDuration(durationXml) || data.durationMillis;

                        const eventTypes = [
                            { id: 'start', fraction: 0.0 },
                            { id: 'firstQuartile', fraction: 0.25 },
                            { id: 'midpoint', fraction: 0.5 },
                            { id: 'thirdQuartile', fraction: 0.75 },
                            { id: 'complete', fraction: 1.0 },
                        ];

                        /* push impression URLs */
                        data?.impressionUrlTemplates?.forEach((impression) =>
                            pushTrackingEvent(impression, ev.detail.startTime),
                        );

                        /* push progress tracking URLs */
                        eventTypes.forEach((et) => {
                            if (data?.trackingEvents?.[et.id]) {
                                const timeStamp =
                                    ev.detail.startTime + et.fraction * (duration / 1000.0);
                                data.trackingEvents[et.id].forEach((url) =>
                                    pushTrackingEvent(url, timeStamp),
                                );
                            }
                        });
                    } catch (err) {
                        console.error('[Player] timelineregionadded', err);
                        throw err;
                    }
                };

                playerInstance = createPlayer(drmConfig(userStore().userHandle));

                player = initPlayer({
                    onUnloaded: unloaded,
                    onLoaded: loaded,
                    onPlayed: playing,
                    onPaused: paused,
                    onSeeked: seeked,
                    onTimeShift: timeshift,
                    onTimeShifted: timeshifted,
                    onError: error,
                    onSeek: seek,
                    onFinished: finished,
                    ...(enableDaiTracking() && {
                        onLoadedmetadata: loadedmetadata,
                        onTimelineregionadded: timelineregionadded,
                    }),
                });

                const nextState = setPlayerState({ isMounted: !!flag });

                const { dashUrl, hlsUrl, startAt } = nextState.player;

                if (dashUrl || hlsUrl) {
                    return __actions.playout(nextState, dashUrl, hlsUrl, startAt);
                }

                return {
                    // do not write to global state scope as this is not side effect safe
                    player: {
                        ...nextState.player,
                    },
                };
            }

            deinitProgressInterval(progressInterval);

            try {
                player && (await player.destroy());
            } catch (e) {
                logError(e);
            }

            const { dashUrl, hlsUrl, startAt } = store.getState().player;
            prevState = { dashUrl, hlsUrl, startAt };

            return {
                player: {
                    ...initialState,
                    prevState,
                },
            };
        },

        playout: async (
            state,
            dash,
            hls,
            startAt = 0,
            autoPause = false,
            isLiveTv = false,
            isTimeshift = false,
        ) => {
            const { userHandle } = userStore();
            const setPlayoutState = () => {
                setPlayerState({
                    dashUrl: dash,
                    hlsUrl: hls,
                    startAt,
                });
            };

            if (!getPlayerState('isMounted')) {
                console.error('Player not mounted yet, postpone playout');
                return setPlayoutState();
            }

            const _processPlayout = async (dashUrl) => {
                // verifies resolved playout is equal current active channelId
                const _dirtyCheck = (_dashUrl) => {
                    const expectedDashUrl = getPlayerState('dashUrl');

                    // check if playoutId changed during playout
                    if (_dashUrl !== expectedDashUrl) {
                        setPlayerIsBusy(true);
                        setPlayoutState();
                        return _processPlayout(expectedDashUrl);
                    }

                    return undefined;
                };

                const { playout: playoutStore, player: playerStore } = store.getState();
                if (playoutStore.enableStreamLogging && !playerInstance.mux) {
                    try {
                        initStreamLogging(
                            playerInstance,
                            {
                                environmentKey: constants.STREAM_LOGGING_ENV_KEY,
                                playerName: 'Web-Client',
                                playerVersion: constants.RELEASE,
                                viewerUserId: userHandle,
                                debug: false,
                                metadata: parseMetaData(
                                    await getCurrentProgram(playoutStore, epgStore()),
                                    getVideoStreamAndContentType(playoutStore),
                                    getStreamUrl(playerStore),
                                ),
                            },
                            constants.SHAKA_MUX_FUNC_NAME,
                            player.playerBase,
                        );
                    } catch (e) {
                        logError(e);
                    }
                }

                try {
                    await player.unload();
                } catch (e) {
                    logErrorWithDescription(e, 'while unloading player');
                    setPlayerIsBusy(false);
                    return undefined;
                }

                try {
                    await player.load(
                        {
                            dash: getPlayerState('dashUrl'),
                            hls: getPlayerState('hlsUrl'),
                        },
                        isLiveTv,
                        isTimeshift,
                        startAt,
                    );

                    muxLogging(store.getState(), playerInstance, 'videochange');

                    if (!autoPause) {
                        player.play();
                    }

                    setPlayerIsBusy(false);

                    return _dirtyCheck(dashUrl);
                } catch (e) {
                    // player might not be fully loaded, so our custom error handler is not attached yet
                    const error =
                        !(e instanceof Error) && e?.data[1] instanceof Error
                            ? e.data[1] // workaround to handle shaka errors during mount
                            : e;

                    logErrorWithDescription(error, 'while loading player and starting new playout');
                    setPlayerIsBusy(false);
                    return undefined;
                }
            };

            if (!getPlayerState('isBusy')) {
                // this prevents dom exceptions https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
                setPlayerIsBusy(true);
                setPlayoutState();
                return _processPlayout(dash);
            }

            return setPlayoutState();
        },

        unload: async (state) => {
            if (isReady()) {
                setPlayerState({ isUnloading: true });
                await player.unload();
                muxLogging(state, playerInstance, 'viewend');
            }
        },

        seek: (state, postion) => {
            if (isReady()) {
                setPlayerIsBusy(true);
                player.seek(postion);
            }
        },

        timeShift: (state, postion, calledAfterLoad = false) => {
            if (isReady()) {
                setPlayerIsBusy(true);
                player.timeShift(postion, calledAfterLoad);
            }
        },

        toggleMute: (state, flag) => {
            if (isReady()) {
                player.toggleMute(flag);
                return setPlayerState({ isMuted: flag });
            }

            return undefined;
        },

        setVolume: (state, vol) => {
            if (isReady()) {
                const _vol = Math.max(0, Math.min(parseInt(vol, 10) || 0, 100));

                player.setVolume(_vol);
                return setPlayerState({ volume: _vol });
            }

            return undefined;
        },

        pause: () => {
            if (isReady()) {
                player.pause();
            }
        },

        play: async () => {
            if (isReady()) {
                await player.play();
            }
        },

        resetError: () => setPlayerState({ errorCode: initialState.errorCode }),
    };

    return __actions;
};

export default actions;
