import { h } from 'preact';
import { classnames } from '@exaring/utils';

import Button from '../../elements/button';

// type Props = {
//     buttons: array([
//                    className?: string,
//                    disabled?: boolean,
//                    label: string,
//                    title: string,
//                    action: function
//                  ]
//            ),
// };

export default ({ buttons, children, className }) => {
    const _className = classnames('button-group', className);
    return (
        <div className={_className}>
            {children ||
                buttons.map((button) => {
                    // eslint-disable-next-line no-shadow
                    const { className, action, disabled, label, title } = button;
                    return (
                        <Button
                            className={className}
                            action={action}
                            disabled={disabled}
                            label={label}
                            title={title}
                        />
                    );
                })}
        </div>
    );
};
