import { h, Fragment, FunctionComponent } from 'preact';

import { CSS } from '@stitches/react';
import { styled } from '@exaring/ui/components-styled/theme';
import { GridContainer, GridItem } from '@exaring/ui/components-styled/Grid';
import { GridItemProps } from '@exaring/ui/components-styled/Grid/Grid';
import { Headline } from './Headline';

export const PAGE_HEADLINE_HEIGHT = 84;

const StyledHeaderBackdrop = styled(GridContainer, {
    position: 'fixed',
    flexWrap: 'nowrap',
    gap: '20px',
    alignItems: 'center',
    width: '100%',
    padding: '0 61px',
    zIndex: 3,
    display: 'flex',
    height: `${PAGE_HEADLINE_HEIGHT}px`,
    backgroundRgba: { color: 'nearBlack', opacity: 0.9 },
    backdropFilter: 'blur(4px)',
});

const StyledHeadlineStub = styled('div', {
    width: '100%',
    height: '100px',
});

export const HeaderMenu: FunctionComponent<GridItemProps> = ({
    children,
    css,
    xs = 6,
    ...props
}) => (
    <GridItem
        css={{
            display: 'flex',
            flexDirection: 'row-reverse',
            columnGap: '$$gutterWidth',
            height: '36px',
            ...css,
        }}
        xs={xs}
        {...props}
    >
        {children}
    </GridItem>
);

export const PageHeader: FunctionComponent<{
    title?: string;
    subtitle?: string;
    className?: string;
    slim?: boolean;
    css?: CSS;
    headlineStubCss?: CSS;
}> = ({ title, subtitle, css, headlineStubCss, className, children, slim = false }) => {
    return (
        <>
            <StyledHeaderBackdrop
                direction="row"
                justifyContent="center"
                css={css}
                className={className}
            >
                {(title || subtitle) && (
                    <GridItem xs={6}>
                        <Headline subtitle={subtitle} css={{ marginLeft: '0' }}>
                            {title}
                        </Headline>
                    </GridItem>
                )}
                {children}
            </StyledHeaderBackdrop>
            {!slim && <StyledHeadlineStub css={headlineStubCss} />}
        </>
    );
};
