import { h, FunctionComponent, ComponentChildren } from 'preact';
import { Typography } from '../../Typography';
import { Modal, ModalProps } from '../Modal';

type UpsellingModal = ModalProps & {
    title: string;
    subtitle: string;
    buttonLabel?: string;
    buttons?: ComponentChildren;
    onClick?: (e: Event) => void;
};

export const UpsellingModal: FunctionComponent<UpsellingModal> = ({
    title,
    subtitle,
    buttonLabel,
    buttons,
    ...modalProps
}) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Modal {...modalProps}>
            <Typography variant="h1" css={{ lineHeight: '1.19em' }}>
                {title}
            </Typography>
            <Typography variant="body2" css={{ marginBottom: '30px' }}>
                {subtitle}
            </Typography>
            {buttons}
        </Modal>
    );
};
