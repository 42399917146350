/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';
import Markup from 'preact-markup';
import ButtonGroup from '../button-group';
import Link from '../../typography/link';

const presetIconStyles = {
    spinner: 'notification__icon spinner',
};

const NotificationScreen = ({
    type,
    buttons,
    closeAction,
    className,
    content,
    title,
    helpText,
    opaque = true,
}) => {
    const iconClass = presetIconStyles[type] || `notification__icon icon--${type}`;
    const helpTextParagraph = `<p className="text">${helpText}</p>`;
    return (
        <div className={`notification__screen ${className || ''}`}>
            {opaque && <div className="notification__screen-opacity" />}
            {closeAction && (
                <div className="notification__close icon icon--close-white" onClick={closeAction} />
            )}
            <div className="notification__body">
                {type && <span className={iconClass} />}
                <div className="notification__title">{title}</div>
                {content && <div className="notification__content">{content}</div>}
                {buttons && buttons.length > 0 && (
                    <div className="notification__footer">
                        <ButtonGroup buttons={buttons} />
                    </div>
                )}
                {helpText && (
                    <div className="notification__help-text">
                        <Markup markup={helpTextParagraph} components={{ Link }} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default NotificationScreen;
