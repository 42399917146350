/* eslint-disable react/jsx-props-no-spreading */

/*
 *  Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, FunctionComponent } from 'preact';
import { Epg as PlanbyEpg, Layout } from '@nessprim/planby';
import { useContext, useEffect, useRef } from 'preact/hooks';
import { Program } from './Program';
import { ChannelItem } from './ChannelItem';
import { Timeline } from './Timeline';
import { useEpgData } from './hooks/useEpgData';
import { EmptyFavourites } from './EmptyFavourites';
import { lastActiveChannel } from '../../actions/helper';
import { ScrollContext } from './context/ScrollContext';
import { ShadowScroll } from './ShadowScroll/ShadowScroll';

let refreshIntervalId: ReturnType<typeof setInterval>;

export const Epg: FunctionComponent = () => {
    const { getEpgProps, ref, itemHeight, layoutProps, showEmptyFavorites, hasLoadingChannels } =
        useEpgData();
    const { scrollToChannelRef } = useContext(ScrollContext);

    const lastScrollLeft = useRef<number | undefined>();

    useEffect(() => {
        refreshIntervalId = setInterval(() => {
            if (lastScrollLeft.current === ref.current?.scrollLeft) {
                scrollToChannelRef?.current?.(lastActiveChannel());
                clearInterval(refreshIntervalId);
            }
            lastScrollLeft.current = ref.current?.scrollLeft;
        }, 100);

        return () => {
            clearInterval(refreshIntervalId);
            lastScrollLeft.current = undefined;
        };
    }, [scrollToChannelRef, hasLoadingChannels, ref]);

    return (
        <PlanbyEpg loader={<EmptyFavourites />} isLoading={showEmptyFavorites} {...getEpgProps()}>
            <Layout
                ref={ref}
                itemHeight={itemHeight}
                {...layoutProps}
                renderTimeline={(props) => <Timeline {...props} />}
                renderProgram={({ program, ...rest }) => {
                    return <Program key={program.data.id} program={program} {...rest} />;
                }}
                renderChannel={({ channel }) => (
                    <ChannelItem key={channel.uuid} channel={channel} />
                )}
            />
            <ShadowScroll scrollBoxRef={ref} />
        </PlanbyEpg>
    );
};
