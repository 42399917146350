/* eslint-disable react/jsx-props-no-spreading */
import { h, FunctionComponent } from 'preact';
import {
    ComposedIconButtonWithLabelProps,
    IconButton,
} from '@exaring/ui/components-styled/IconButton';
import AllChannelIcon from '@exaring/assets/icons/all-channels-toggle-off.svg';
import FavoriteInactiveIcon from '@exaring/assets/icons/favorite-inactive.svg';
import FavoriteActiveIcon from '@exaring/assets/icons/favorite-active.svg';
import ChannelSortIcon from '@exaring/assets/icons/channel-sort-toggle.svg';
import { Colors, styled } from '@exaring/ui/components-styled/theme';

const EpgIconButton = styled(IconButton, {
    paddingLeft: '8px',
    paddingRight: '8px',

    '@md': {
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    '@lg': {
        paddingLeft: '18px',
        paddingRight: '18px',
    },
    '> p': {
        fontSize: '$2',
    },
    '&:hover > svg': {
        fill: '$aquamarine',
    },

    variants: {
        disabled: {
            false: {},
            true: {
                cursor: 'default',
                opacity: '0.4',
                '&:hover': {
                    backgroundColor: '$dark',
                },
                '&:hover > svg': {
                    fill: '$white',
                },
            },
        },
    },
});

interface OptionalFillColor {
    fill?: string;
}

const optionalFillColor = (
    isActive: boolean,
    color: string = Colors.aquamarine,
): OptionalFillColor => {
    return isActive ? { fill: color } : {};
};

export const EpgFilterAllChannelsButton: FunctionComponent<
    ComposedIconButtonWithLabelProps & {
        isActive?: boolean;
    }
> = ({ title = 'Alle Sender', isActive = false, ...props }) => {
    return (
        <EpgIconButton
            icon={<AllChannelIcon {...optionalFillColor(isActive)} />}
            title={title}
            spacing="minimal"
            {...props}
        />
    );
};

const EpgFavoritesIcon: FunctionComponent<{ isActive?: boolean }> = ({ isActive = false }) => {
    const IconComponent = isActive ? FavoriteActiveIcon : FavoriteInactiveIcon;
    return <IconComponent {...optionalFillColor(isActive)} />;
};

export const EpgFilterFavoritesButton: FunctionComponent<
    ComposedIconButtonWithLabelProps & {
        isActive?: boolean;
    }
> = ({ title = 'Nur Favoriten', isActive = false, ...props }) => {
    return (
        <EpgIconButton
            icon={<EpgFavoritesIcon isActive={isActive} />}
            title={title}
            spacing="minimal"
            {...props}
        />
    );
};

export const EpgToggleFavoriteButton: FunctionComponent<
    Omit<ComposedIconButtonWithLabelProps, 'title'> & {
        isActive?: boolean;
        labelRemoveFavorit?: string;
        labelAddFavorit?: string;
    }
> = ({
    labelRemoveFavorit = 'Favorit entfernen',
    labelAddFavorit = 'Favorit hinzufügen',
    isActive = false,
    disabled = false,
    ...props
}) => {
    const label = isActive ? labelRemoveFavorit : labelAddFavorit;
    return (
        <EpgIconButton
            icon={<EpgFavoritesIcon isActive={isActive} />}
            title={label}
            spacing="minimal"
            disabled={disabled}
            css={{
                padding: 0,
                width: '24px',
                height: '24px',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            }}
            {...props}
        />
    );
};

export const EpgChannelSortButton: FunctionComponent<
    ComposedIconButtonWithLabelProps & {
        isActive?: boolean;
        showTitle?: boolean;
    }
> = ({ title = 'Sendersortierung', showTitle = true, isActive = false, ...props }) => {
    return (
        <EpgIconButton
            icon={<ChannelSortIcon {...optionalFillColor(isActive)} />}
            title={title}
            spacing="minimal"
            {...props}
        >
            {showTitle && title}
        </EpgIconButton>
    );
};
