import { h } from 'preact';
import { NotificationScreen } from '@exaring/ui';

const RecordingBlockerScreen = () => (
    <NotificationScreen
        className="header-spacer"
        content={
            <p>
                Diese Aufnahme steht Ihnen nach Anpassung <br />
                Ihres Speicherplatzes zur Verfügung.
            </p>
        }
        type="locked"
    />
);

export default RecordingBlockerScreen;
