import { useEffect } from 'preact/hooks';
import { Recording, RecordingPlaceholder } from '@exaring/networking/types/Recording/Recording';
import { useRecordingStore } from '../../../state/Store';
import { RemoteDataState, didNotAsk, didSucceed } from '../../../state/utils/RemoteData';

export const useRecordingInit = (): [RemoteDataState, Array<Recording | RecordingPlaceholder>] => {
    const { recordings, fetchRecordings } = useRecordingStore();

    useEffect(() => {
        if (didNotAsk(recordings)) {
            fetchRecordings();
        }
    }, [fetchRecordings, recordings.status]);

    return [recordings.status, didSucceed(recordings) ? recordings.value : []];
};
