/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, FunctionComponent } from 'preact';
import tileFallback from '@exaring/assets/images/tileFallback-m.png';
import { CSS, styled } from '../../theme';
import { Typography } from '../../Typography/Typography';
import { StyledTileContainer } from '../parts/StyledTileContainer';
import { StyledTileImage } from '../parts/StyledTileImage';
import { StyledMetaSpan } from '../parts/StyledMetaSpan';
import { headlineStyle } from '../styles';
import { StyledTileContent } from '../parts/StyledTileContent';
import { VideoTileImageContainer } from '../parts/VideoTileImageContainer';

export const RecordingDiscoveryMetaInfo = StyledMetaSpan;

const RecordingDiscoveryTileContent = styled(StyledTileContent, {
    background: 'none',
    '&:hover': {
        background: 'none',
    },
});

const RecordingDiscoveryTileContainerStyled = styled(StyledTileContainer, {
    boxShadow: 'none',
});

export const RecordingDiscoveryTileEnumeration = styled('div', { display: 'flex', width: '100%' });

export const RecordingDiscoveryTile: FunctionComponent<{
    css?: CSS;
    className?: string;
    title?: string;
    airtime?: string;
    duration?: string;
    genre?: string;
    imageSrc?: string;
    locked?: boolean;
}> = ({ css, className, title, imageSrc, locked, children }) => (
    <RecordingDiscoveryTileContainerStyled
        size={{
            '@initial': 'mobile',
            '@sm': 'default',
        }}
        css={css}
        className={className}
    >
        <VideoTileImageContainer hidePlayOverlay locked={locked}>
            <StyledTileImage
                css={{ borderRadius: '10px' }}
                src={imageSrc}
                srcFallback={tileFallback}
            />
        </VideoTileImageContainer>

        <RecordingDiscoveryTileContent>
            <Typography css={headlineStyle} variant="h2">
                {title}
            </Typography>
            {children}
        </RecordingDiscoveryTileContent>
    </RecordingDiscoveryTileContainerStyled>
);
