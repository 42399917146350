import { FunctionComponent, h, Fragment } from 'preact';
import { useEffect } from 'preact/hooks';

import { ProgramProgressBar } from '@exaring/ui/components-styled/ProgramProgressBar/ProgramProgressBar';
import { styled } from '@exaring/ui/components-styled/theme';
import HdIcon from '@exaring/assets/icons/hd.svg';
import LockedIcon from '@exaring/assets/icons/content-locked.svg';
import FavoriteIcon from '@exaring/assets/icons/favorite.svg';
import {
    ParentalGuidance,
    ProgramDetails,
    ProgramSeries,
} from '@exaring/networking/types/ProgramDetails';
import { epgStore, useEpgStore } from '../../../state/Store';
import { prefixEnumerationWithZero } from '../ChannelItem';
import { assetUrl } from '../../../helper';
import { Genre } from './Genre';
import { Time } from './Time';
import { Badge } from './Badge';
import {
    PreviewImg,
    StyledPreviewContainer,
    TextWrapper,
    Description,
    BadgeWrapper,
    PreviewImgContainer,
} from './SharedComponents';
import { lastActiveChannel } from '../../../actions/helper';
import { getProgramProgress } from './utils';

const NameWrapper = styled('div', {
    height: '24px',
    margin: '4px 10px 10px 0',
    lineHeight: 2,
    fontSize: '$1',
    display: 'flex',
    alignItems: 'center',

    '@md-width-and-md-height': {
        fontSize: '$2.5',
        lineHeight: 1.5,
    },
});

const Title = styled('div', {
    maxWidth: '870px',
    margin: '6px 0 10px',
    fontWeight: '$3',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '30px',
    fontSize: '20px',
    lineHeight: 1.5,

    '@sm': {
        width: 'calc(100vw - 370px)',
    },
    '@md-width-and-md-height': {
        width: 'calc(100vw - 600px)',
        height: '50px',
        fontSize: '$5',
    },
});

const fetchProgramDetailsForIdOrLastChannel = async (programId?: string) => {
    const { getLiveProgram, fetchProgramDetails } = epgStore();

    if (programId) {
        fetchProgramDetails(programId);
    } else {
        const lastChannelId = lastActiveChannel()?.toLowerCase();
        const liveProgram = lastChannelId ? await getLiveProgram(lastChannelId!) : undefined;
        if (liveProgram?.id) {
            fetchProgramDetails(liveProgram.id);
        }
    }
};

const getSeriesText = (series: ProgramSeries) => {
    const { seasonNumber, episodeNumber, episodeTitle, episodeTitleOriginal } = series;
    const seasonAndEpisode =
        seasonNumber && episodeNumber && `S${seasonNumber} F${episodeNumber} ⋅`;
    return (
        seasonAndEpisode &&
        (episodeTitle || episodeTitleOriginal) &&
        `${seasonAndEpisode} ${episodeTitle || episodeTitleOriginal}`
    );
};

const getDescription = (program: ProgramDetails) => {
    const {
        textContent: { titleSideline: description },
        series,
    } = program;
    return (series && getSeriesText(series)) || description || '';
};

export const ProgramPreview: FunctionComponent<{ programId?: string }> = ({ programId }) => {
    const { programDetails, getStationById } = useEpgStore();

    useEffect(() => {
        fetchProgramDetailsForIdOrLastChannel(programId);
    }, [programId]);

    if (programDetails.state !== 'Success' || programDetails.value === undefined) {
        return <></>;
    }

    const {
        imageUrls,
        textContent: { title },
        startTime,
        stopTime,
        stationId,
        contentMeta: { mainGenre: genre },
        production: { year },
        ageRating,
    } = programDetails.value;

    const channel = getStationById(stationId);
    const isHd = channel?.availableStreamQuality === 'hd';
    const channelName = channel?.displayName;
    const enumeration = channel?.enumeration
        ? prefixEnumerationWithZero(channel?.enumeration)
        : undefined;
    const locked = channel?.locked;
    const favorite = channel?.favorite;
    // eslint-disable-next-line no-template-curly-in-string
    const imgUrl = imageUrls?.[0]?.replace('${resolution}', 'm');
    const description = getDescription(programDetails.value);

    const { progress } = getProgramProgress({
        type: channel?.newTv ? 'NewTv' : 'Live',
        startTime,
        stopTime,
    });

    return (
        <StyledPreviewContainer>
            <PreviewImgContainer>
                <PreviewImg src={imgUrl} srcFallback={assetUrl('background-waipu-gradient.png')} />
                <ProgramProgressBar progress={progress} />
            </PreviewImgContainer>
            <TextWrapper>
                <NameWrapper>
                    <div>{channelName}</div>
                    <BadgeWrapper>
                        {enumeration && <Badge>{enumeration}</Badge>}
                        {isHd && <HdIcon />}
                        {favorite && <FavoriteIcon />}
                        {locked && <LockedIcon />}
                    </BadgeWrapper>
                </NameWrapper>
                <Title>{title}</Title>
                <Description>{description}</Description>
                <Time startTime={startTime} stopTime={stopTime} />
                <Genre
                    genre={genre}
                    year={year ?? undefined}
                    parentalGuidance={
                        (ageRating?.parentalGuidance ?? undefined) as ParentalGuidance
                    }
                />
            </TextWrapper>
        </StyledPreviewContainer>
    );
};
