import { styled } from '../../theme';
import { clipTextOverflowStyle } from '../styles';

export const StyledMetaSpan = styled('span', {
    ...clipTextOverflowStyle,
    fontFamily: 'inherit',
    fontSize: '$1',
    fontWeight: '$1',
    letterSpacing: 'normal',
    height: '18px',
});
