import { programDetailsSchema } from '../../types/ProgramDetails';
import { z } from 'zod';

const recordingDetailsStatus = z.enum([
    'SCHEDULED',
    'RECORDING',
    'FINISHED',
    'FAILED',
    'EXPIRED',
    'DELETING',
    'DELETED',
    'LOCKED',
    'PVR_LIMIT_EXCEEDED',
]);

const recordingDetailsSchema = z.object({
    id: z.string(), // required
    recordingGroup: z.number().nullish(),
    startTime: z.string(), // required
    stopTime: z.string(), // required
    position: z.number().nullish(),
    isNew: z.boolean().nullish(),
    status: recordingDetailsStatus, // required
    programDetails: programDetailsSchema, // required
    locked: z.boolean().nullish(),
    recordingSeekingForbidden: z.boolean().nullish(),
    recordedNetTimeSeconds: z.number().nullish(),
    recordedNetTimeBeforeInSeconds: z.number().nullish(),
    expiresAt: z.string().nullish(),
});

// http://business-systems.doc.exaring.net/swagger-doc/swagger-dist/index.html?url=https%3a%2f%2frecording.waipu-dev.net%2fapi%2fdocs%2frecording-V4#/Recordings/getRecordingByIdV4
export type RecordingDetails = z.infer<typeof recordingDetailsSchema>;
export type RecordingEpgData = z.infer<typeof programDetailsSchema>;
export type RecordingStatus = z.infer<typeof recordingDetailsStatus>;

export const isValidRecordingDetailsJson = (value: unknown): value is RecordingDetails => {
    return recordingDetailsSchema.safeParse(value).success;
};

export const decodeRecordingDetails = (value: unknown): RecordingDetails | undefined => {
    return isValidRecordingDetailsJson(value) ? value : undefined;
};
