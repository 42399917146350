/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { ComponentChildren, h } from 'preact';
import { forwardRef } from 'preact/compat';
import { CSS } from '@stitches/react';
import { styled } from '@exaring/ui/components-styled/theme';
import { Headline } from './Headline';
import { PAGE_HEADLINE_HEIGHT } from './PageHeadline';

const StyledSectionContainer = styled('div', {
    paddingBottom: '30px',
});

export const scrollToSection = (element?: HTMLDivElement) => {
    if (element) {
        window.scrollTo(0, element.offsetTop - PAGE_HEADLINE_HEIGHT);
    }
};

export const Section = forwardRef<
    HTMLDivElement,
    {
        id?: string;
        title?: string;
        subtitle?: string;
        css?: CSS;
        headlineCss?: CSS;
        children?: ComponentChildren;
    }
>(({ id, title, subtitle, css, headlineCss, children }, ref) => {
    return (
        <StyledSectionContainer css={css} ref={ref}>
            <Headline id={id} css={headlineCss} subtitle={subtitle}>
                {title}
            </Headline>
            {children}
        </StyledSectionContainer>
    );
});
