/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

/* eslint-disable import/prefer-default-export */
export const searchRenderCondition = (
    { searchQuery, isSearchPending },
    { searchQuery: searchQueryNext, isSearchPending: isSearchPendingNext },
) => {
    const searchTermNotChanged = searchQuery === searchQueryNext;
    const pendingStateNotChanged = isSearchPending === isSearchPendingNext;

    return searchTermNotChanged && pendingStateNotChanged; // true means do NOT rerender
};
