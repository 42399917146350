/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import useNativeEvents from './events/native';
import { useTouchEvents, SwipeDirection } from './events/touch';
import useAsyncAction from './useAsyncAction';
import useForceUpdate from './force-update';

export {
    // native dom event hooks
    useNativeEvents,
    // touch event hooks
    useTouchEvents,
    SwipeDirection,
    // async hooks
    useAsyncAction,
    useForceUpdate,
};
