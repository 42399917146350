import RecordingServiceApi from '@exaring/networking/services/recordings-v4';
import {
    isValidRecordingDetailsJson,
    RecordingDetails,
} from '@exaring/networking/types/Recording/RecordingDetails';
import { DeferredRemoteData, useRemoteData } from '../../../hooks/useRemoteData';
import { notAsked } from '../../../state/utils/RemoteData';

export const useRecordingDetails = (recordingId?: string): DeferredRemoteData<RecordingDetails> => {
    const result = useRemoteData(
        ({ recordingId: recId }) => {
            return RecordingServiceApi.recordingDetails(recId);
        },
        isValidRecordingDetailsJson,
        { recordingId },
    );

    return recordingId ? result : { data: notAsked() };
};
