/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, Fragment } from 'preact';
import { NotificationScreen, ButtonGroup } from '@exaring/ui';
import userService from '@exaring/networking/services/user';
import { localStorageItem } from '@exaring/utils';
import { useEffect, useState } from 'preact/hooks';
import { trackWithUserContext } from '../../tracking';
import { WebClientGAEvent } from '../../web-client-ga';
import constants from '../../constants';

const setUserConfirmationBlockerFlag = (flag = false) =>
    localStorageItem('userConfirmationBlocker', flag);

const getUserConfirmationBlockerFlag = () => localStorageItem('userConfirmationBlocker');

const DOIBlocker = ({ emailAddress }) => {
    const [doiEmailSuccess, setDoiEmailSuccess] = useState();

    useEffect(() => {
        trackWithUserContext(WebClientGAEvent.DOIBlockerScreen);
    }, []);

    const lastError = getUserConfirmationBlockerFlag();
    setUserConfirmationBlockerFlag(false);

    return (
        <NotificationScreen
            className="header-spacer"
            title="Fast geschafft."
            content={
                <Fragment>
                    <p>Wir haben Ihnen eine E-Mail an</p>
                    <p>
                        <strong>{emailAddress}</strong> geschickt.
                    </p>
                    <p>Link in der E-Mail bestätigen und los geht&apos;s.</p>
                    {lastError && (
                        <p className="alert--error alert--doi strong">
                            Bitte bestätigen Sie erst Ihre E-Mail-Adresse.
                        </p>
                    )}
                    {doiEmailSuccess === true && (
                        <p className="alert--success alert--doi strong">E-Mail gesendet.</p>
                    )}
                    {doiEmailSuccess === false && (
                        <p className="alert--error alert--doi strong">
                            E-Mail konnte nicht gesendet werden.
                        </p>
                    )}
                    <ButtonGroup
                        buttons={[
                            {
                                className: 'button--doi',
                                action: () => {
                                    setUserConfirmationBlockerFlag(true);
                                    window.location.reload();
                                },
                                label: 'Jetzt fernsehen',
                            },
                            {
                                className: 'button--light',
                                action: async () => {
                                    let success = false;
                                    trackWithUserContext(WebClientGAEvent.DOIResendEmail);

                                    try {
                                        const { status } = await userService.confirmationEmail();

                                        success = status === 201;
                                    } catch (e) {
                                        success = false;
                                    }

                                    setDoiEmailSuccess(success);
                                },
                                label: 'Email erneut zusenden',
                            },
                        ]}
                    />
                    <div className="link--doi">
                        <a
                            onClick={() => trackWithUserContext(WebClientGAEvent.DOIChangeEmail)}
                            href={`${constants.CHANGE_EMAIL_URL}?backUrl=${constants.WEB_CLIENT_BASE_URL}`}
                        >
                            E-Mail-Adresse ändern
                        </a>
                    </div>
                </Fragment>
            }
        />
    );
};

export default DOIBlocker;
