import { h } from 'preact';
import RecordingStartIcon from '@exaring/assets/icons/recording-start.svg';
import RecordingStopIcon from '@exaring/assets/icons/recording-stop.svg';
import RecordingDeleteIcon from '@exaring/assets/icons/recording-delete.svg';

import { LoadingSpinnerIcon } from '../LoadingSpinner';
import { Colors } from '../theme';

export const RECORD_ICON_MAP = {
    start: <RecordingStartIcon />,
    stop: <RecordingStopIcon color={Colors.red} />,
    delete: <RecordingDeleteIcon />,
    spinner: <LoadingSpinnerIcon width={0} height={0} secondary />,
} as const;
