import {
    token,
    refreshToken,
    mergeAccounts,
    loginWithAmazon,
    loginWithAppleId,
} from '../requests/auth';
import { requestHookChain } from '../networking.v2';

const api = {
    token: requestHookChain(token),
    refreshToken: requestHookChain(refreshToken),
    mergeAccounts: requestHookChain(mergeAccounts),
    loginWithAmazon: requestHookChain(loginWithAmazon),
    loginWithAppleId: requestHookChain(loginWithAppleId),
};

export default api;
