import { h, Fragment } from 'preact';
import { memo } from 'preact/compat';
import { connect } from 'unistore/preact';
import { BaseUi, Slider } from '@exaring/ui';
import { isOnAir, adjustImageRes } from '@exaring/utils/data/program';
import InfoIcon from '@exaring/assets/icons/info.svg';

import {
    events,
    classnames,
    localStorageItem,
    conditionalCb,
    progress as progressCalc,
    isTimestamp,
    epgLiveDuration,
    playbackInfo,
    now,
    isTunerServiceId,
} from '@exaring/utils';
import { getMicroSiteIdFromStation } from '@exaring/utils/data/channel';
import MobileDetect from 'mobile-detect';
import { ProgramDetailPage } from '@exaring/ui/components-styled/ProgramDetailPage';
import { logError } from '@exaring/utils/error-logging';
import { styled } from '@exaring/ui/components-styled/theme';
import { MediathekButton } from '@exaring/ui/components-styled/IconButton';
import { epg2ProgramDetailsToProgramDetailPage } from '@exaring/ui/components-styled/ProgramDetailPage/helper';
import {
    routeToLive,
    routeToEpg,
    routeToWaiputhekDetails,
    Routes,
    routeTo,
    routeToVoD,
    routeToWaiputhekMediathek,
} from '../../../routes';
import RecordingButton from '../../RecordingButton';
import OSDButton from './OSDButton';
import OSDButtonGroup from './OSDButtonGroup';
import RecordingControls from './RecordingControls';
import ChannelControls from './ChannelControls';
import MediaPlayerErrorScreen from '../MediaPlayerErrorScreen';
import Header from '../../Header';
import ProgressBar from '../../ProgressBar';
import { trackWithPlayerContext } from '../../../tracking';
import {
    playoutDataGenerator,
    isPinEntryTTLValid,
    isSafari,
    getCurrentScreen,
} from '../../../helper';
import * as channelSortStore from '../../../actions/channel-sort';

import constants from '../../../constants';
import { WebClientGA, WebClientGAEvent } from '../../../web-client-ga';

import {
    PLAYOUT_LIVE_RECORDING,
    PLAYOUT_RECORDING,
    PLAYOUT_LIVE,
    PLAYOUT_VOD,
    OSD_PANEL_CLOSED,
    OSD_PANEL_EPG,
    OSD_PANEL_CHANNELS,
    OSD_ACTIVE_EDGE_LEFT,
} from '../../../actions/constants';

import { CLEARANCE_LEVEL } from '../../../actions/helper/parental-guidance';
import playoutActions from '../../../actions/playout';

import store from '../../../store';

import ChannelList from '../../ChannelList';
import AgeVerificationBlocker from '../AgeVerificationBlocker';
import {
    epgStore,
    uiStore,
    useEpgStore,
    useUserStore,
    notificationsStore,
} from '../../../state/Store';
import SubtitleButton from './SubtitleButton';
import { safariSubtitlesFix } from '../../../player/safariSubtitlesFix';

/* eslint-disable no-bitwise */

const StyledInfoIcon = styled(InfoIcon, {
    width: '20px',
    height: '20px',
    marginLeft: '5px',
    cursor: 'pointer',
});

class OSD extends BaseUi {
    streamPositionTimeout;

    constructor(props) {
        super(props);

        const md = new MobileDetect(window.navigator.userAgent);
        this.isiPad = md.tablet() === 'iPad';

        if (!this.hasSeenChannelList) {
            setTimeout(() => {
                this.onSetChannelListVisibility(true);
                setTimeout(() => {
                    this.onSetChannelListVisibility(false);
                }, constants.CHANNELLIST_AUTOSHOW_DISPLAYTIME);
            }, constants.CHANNELLIST_AUTOSHOW_DELAY);
        }

        this.state = {
            memoChannels: [],
            channels: [],
            isFavoriteMode: false,
        };
    }

    componentDidMount() {
        const { epgStations, epgInfo, fetchEpgStations, fetchEpgInfo, fetchProgramDetails } =
            epgStore();

        const programId = this.props.program?.id;

        // only fetch details for live program
        if (!this.props.isRecording) {
            programId && fetchProgramDetails(programId);
        }

        if (epgInfo.state === 'NotAsked') {
            fetchEpgInfo();
        }

        if (epgStations.state === 'NotAsked') {
            fetchEpgStations();
        }

        this.streamPositionTimeout = setInterval(() => {
            this.props.updateBackendRecordingPosition();
        }, 60000);
    }

    componentWillReceiveProps(nextProps) {
        const {
            program: nextProgram,
            activeChannelId: nextChannel,
            parentalGuidance,
            pinRequired,
            volume: nextVolume,
        } = nextProps;
        const { program: currentProgram, activeChannelId: currentChannel, volume } = this.props;
        const { fetchProgramDetails } = epgStore();

        if (nextProgram?.id && currentProgram?.id !== nextProgram.id) {
            fetchProgramDetails(nextProgram.id);
        }

        if (nextVolume !== volume) {
            localStorageItem('playerVolume', nextVolume);
        }

        // if program changes for active playout we change clearance to level 1
        if (
            !!currentProgram?.id &&
            parentalGuidance &&
            currentChannel === nextChannel &&
            currentProgram.id !== nextProgram.id &&
            !pinRequired
        ) {
            this.props.parentalGuidanceClearance(CLEARANCE_LEVEL.PARENTAL_ADVISORY);
        }
    }

    componentWillUnmount() {
        if (this.streamPositionTimeout) {
            clearInterval(this.streamPositionTimeout);
        }
    }

    render(props) {
        const {
            show,
            playoutData,
            playerTimestamp,
            program,
            isLiveRecording,
            isRecording,
            isVoD,
            previewImage,
            disabled,
            isReady,
            playerError,
            activeCooldown,
            isTimeShifted,
            unmuteInteractionRequired,
            panelState,
            showPanels,
            isFullscreen,
            isSeekingForbidden,
            isPlaying,
            isFavoriteMode,
            onNavigateLeft,
            onNavigateRight,
            channels,
            activeChannel,
            microSiteId,
            liveProgramIndex,
            fetchThumbnail,
            parentalGuidance,
            parentalGuidanceClearance,
            parentalGuidanceLevel,
            pinRequired,
            programDetails,
            updateStation,
        } = props;

        const showParentalGuidance =
            parentalGuidance && parentalGuidanceLevel < CLEARANCE_LEVEL.CLEARED;

        const {
            uuid: channelId,
            displayName,
            logo: imageSrc,
            favorite: isFavourite,
        } = activeChannel || {};
        const isDisabledOrNotReady = disabled || !isReady || showParentalGuidance;
        const { duration, startTime, stopTime } = playoutData || {};

        // TODO: move to playoutDataGenerator during refactoring :)
        const position = isTimestamp(playerTimestamp)
            ? Math.floor(playerTimestamp)
            : startTime?.add(playerTimestamp, 's').unix();
        const progress =
            progressCalc(
                startTime,
                stopTime,
                isTimeShifted || isRecording || isVoD ? position : now(),
            ) || 0;
        const liveProgress =
            isTimeShifted || isLiveRecording ? progressCalc(startTime, stopTime, now()) : 0;

        const osdClasses = classnames(
            'osd',
            !show && !showParentalGuidance && 'is-hidden',
            showParentalGuidance && 'is-disabled',
        );

        const overlayClasses = classnames(
            'epg-detail__live-overlay',
            panelState & OSD_PANEL_EPG && showPanels && 'epg-detail__live-overlay--fade-in',
        );

        // epg short details
        const details = (
            <div
                className={classnames(
                    'osd__details',
                    (isRecording || isVoD) && 'osd__details--recording',
                )}
            >
                <div className="osd__description">
                    <h1 className="osd__title">
                        {isRecording ? program?.programDetails.textContent.title : program?.title}
                    </h1>
                </div>

                <div className="osd__time">
                    <span className="osd__duration-info">
                        {isRecording || isVoD
                            ? playbackInfo(startTime, stopTime, position)
                            : (program?.startTime &&
                                  epgLiveDuration(program?.startTime, program?.stopTime)) ||
                              ''}
                    </span>
                    <StyledInfoIcon onClick={this.onDetailsToggleClick} />
                </div>
            </div>
        );

        const { getStationById } = epgStore();
        const { id: programId, assetId } = this.props.program || {};
        const isVOD = isTunerServiceId(programId) || assetId !== undefined;
        let convertedProgram;
        let errorCode;
        let isProgramDetailsLoading = false;
        if (isVOD) {
            convertedProgram = this.props.program;
        } else if (isRecording) {
            convertedProgram = epg2ProgramDetailsToProgramDetailPage(
                this.props.program.programDetails,
            );
        } else {
            const { value, state, error } = programDetails || {};
            const station = value?.stationId ? getStationById(value?.stationId) : undefined;
            errorCode = state === 'Error' ? error?.response?.status || 500 : undefined;
            isProgramDetailsLoading = state === 'Loading';
            convertedProgram = value
                ? epg2ProgramDetailsToProgramDetailPage(value, station?.displayName)
                : undefined;
        }

        if (convertedProgram) {
            convertedProgram.channelDisplay = displayName;
        }

        /* eslint-disable react/jsx-props-no-spreading */
        return (
            <Fragment>
                <div id="media-player-osd" className={osdClasses}>
                    {!playerError && parentalGuidance && (
                        <AgeVerificationBlocker
                            key={program?.id}
                            rating={parentalGuidance}
                            handleAgeVerification={parentalGuidanceClearance}
                            parentalGuidanceLevel={parentalGuidanceLevel}
                            pinRequired={pinRequired}
                        />
                    )}

                    {playerError && (
                        <MediaPlayerErrorScreen
                            {...playerError}
                            program={program}
                            hideButtons={activeCooldown}
                        />
                    )}
                    <div
                        key="background-click--helper"
                        className="osd__background-click--helper"
                        onClick={conditionalCb(
                            this.onOsdBackgroundClick,
                            this.onOsdBackgroundClick,
                        )}
                    />
                    {!isFullscreen && <Header id="osd-page" key="header" />}
                    {!playerError && unmuteInteractionRequired && !disabled && (
                        <div
                            key="speaker-icon"
                            className={classnames(
                                'osd__player-init',
                                parentalGuidance && 'init--hidden',
                            )}
                            onClick={this.onFirstUserInteraction}
                        >
                            <div className="osd__icon--speaker-off icon--speaker-toggle-off" />
                        </div>
                    )}
                    {showPanels && (
                        <div key="controls" className="osd__controls">
                            <ProgressBar
                                loaded={liveProgress}
                                progress={progress}
                                duration={duration}
                                previewImage={previewImage}
                                playoutData={playoutData}
                                fetchThumbnail={fetchThumbnail}
                                seekingForbidden={isSeekingForbidden}
                                isDisabled={isSeekingForbidden}
                                onProgressClick={this.onProgressClick}
                            />

                            <div className="osd__control-wrapper">
                                {isRecording || isVoD ? (
                                    details
                                ) : (
                                    <ChannelControls
                                        toggleChannel={async () => {
                                            updateStation(channelId, !isFavourite, true);
                                        }}
                                        isFavourite={isFavourite}
                                        isDisabled={disabled}
                                        channelImageSrc={adjustImageRes(
                                            imageSrc,
                                            216,
                                            162,
                                            'small',
                                        )}
                                        channelTitle={displayName}
                                        onChannelLogoClick={this.onChannelLogoClick}
                                        onNavigatePrevious={onNavigateLeft}
                                        onNavigateNext={onNavigateRight}
                                    >
                                        {details}
                                    </ChannelControls>
                                )}

                                {this.renderRightControlGroup({
                                    ...props,
                                    isDisabledOrNotReady,
                                    convertedProgram,
                                })}
                            </div>
                        </div>
                    )}

                    <ChannelList
                        onToggleFavorite={this.toggleFavorite}
                        show={panelState & OSD_PANEL_CHANNELS && showPanels}
                        channels={channels}
                        activeChannel={activeChannel}
                        isFavoriteMode={isFavoriteMode}
                        liveProgram={liveProgramIndex}
                        onChannelItemAction={this.onChannelItemClick}
                        isDisabled={isRecording || isVoD}
                        onMouseEnter={() => this.onActiveEdgeLeft(true)}
                        onMouseLeave={() => this.onActiveEdgeLeft(false)}
                    />

                    <div key="details" className={overlayClasses}>
                        {(panelState & OSD_PANEL_EPG && (
                            <ProgramDetailPage
                                layout="player"
                                program={convertedProgram}
                                errorCode={errorCode}
                                isLoading={isProgramDetailsLoading}
                                isPlaying={isPlaying}
                                isTimeShifted={isTimeShifted}
                                onRecommendationClick={this.handleRecommendationClick}
                                renderRecordingButton={(_program) => (
                                    <RecordingButton
                                        key={_program.id}
                                        program={{
                                            stationId: _program.channel,
                                            textContent: { title: _program.title },
                                            ..._program,
                                        }}
                                        label="Aufnehmen"
                                        size="big"
                                        showLabel
                                    />
                                )}
                                hideMediathekButton={!microSiteId}
                                goTo={(prog, linkType) => {
                                    this.routingHandler(prog, linkType);
                                }}
                            />
                        )) ||
                            ''}
                    </div>
                </div>
            </Fragment>
        );
        /* eslint-enable react/jsx-props-no-spreading */
    }

    toggleFavorite = (stationId, favorite) => {
        const evtDescription = favorite
            ? WebClientGAEvent.UnmarkFavourite
            : WebClientGAEvent.MarkFavourite;
        WebClientGA().trackEvent({
            eventName: WebClientGAEvent.QuickZapper,
            eventDescription: evtDescription,
            channelName: this.props.activeChannel?.displayName,
            screenName: getCurrentScreen(),
            programId: this.props.program?.id,
            programTitle: this.props.program?.title,
        });
        this.props.updateStation(stationId, !favorite, true);
    };

    handleRecommendationClick = ({ channelId, programId, recommendation, contentId }) => {
        if (contentId) {
            routeToWaiputhekDetails(contentId);
            return;
        }
        if (isOnAir(recommendation) && !recommendation.locked) {
            this.onPanelStateChange(!this.props.panelState && OSD_PANEL_EPG);
            routeToLive(channelId);
            return;
        }
        routeToEpg(channelId, programId);
    };

    routingHandler(program, linkType) {
        switch (linkType) {
            case 'close':
                this.onPanelStateChange(OSD_PANEL_CLOSED);
                break;
            case 'togglePlay':
                this.togglePlay();
                break;
            case 'previous':
                routeToVoD(program.channel, program.prevId || undefined);
                break;
            case 'next':
                routeToVoD(program.channel, program.nextId || undefined);
                break;
            case 'mediathek':
                this.routeToWaiputhekMediathek(this.props.microSiteId);
                break;
            default:
                logError(new Error(`Routing: Link type unknown: "${linkType}"`));
                break;
        }
    }

    routeToWaiputhekMediathek = (microSiteId) =>
        microSiteId &&
        routeToWaiputhekMediathek(
            microSiteId,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            false,
        );

    renderRightControlGroup = (props) => {
        const {
            isDisabledOrNotReady,
            volumeSliderActive,
            program,
            isCompletedRecording,
            isSeekingForbidden,
            isPlaying,
            isRecording,
            isLiveRecording,
            isVoD,
            convertedProgram,
            microSiteId,
        } = props;
        const instantRestartDisabledCondition =
            isSafari() || (!isVoD && !this.isInstantRestartAllowed()) || props.isTimeShifted;
        const pauseDisabledCondition =
            isSafari() || (!isVoD && props.isPauseForbidden()) || props.isFreeUser;

        const fullscreenToggleClasses = classnames(
            'osd__icon',
            'icon--fullscreen-mode',
            !props.isFullscreen ? 'icon--fullscreen' : 'icon--fullscreen-exit',
            !props.isFullscreenAvailable() || (isDisabledOrNotReady && 'icon--disabled'),
        );
        const speakerToggleClasses = classnames(
            'osd__icon',
            props.muted ? 'icon--speaker-toggle-off' : 'icon--speaker-toggle-on',
            !props.showVolume && 'icon--speaker-toggle-hide-Slider',
            isDisabledOrNotReady && 'icon--disabled',
        );

        const liveToggleClasses = classnames(
            'osd__icon',
            'icon--live',
            !props.isTimeShifted && 'icon--disabled',
        );

        const volume = [{ name: 'volume', value: props.volume }];

        const volumeSliderBaseClass = 'osd__controls__volume';
        const volumeSliderClasses = classnames(
            volumeSliderBaseClass,
            volumeSliderActive && `${volumeSliderBaseClass}--active`,
        );

        safariSubtitlesFix();

        const handleMediathekButtonClick = () => {
            trackWithPlayerContext(
                WebClientGAEvent.PlayerControls,
                store.getState(),
                WebClientGAEvent.OpenMediaLibrary,
                getCurrentScreen(),
            );
            this.routeToWaiputhekMediathek(microSiteId);
        };

        return (
            <OSDButtonGroup className="osd__controls__icon-group--right">
                {microSiteId ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: '10px',
                        }}
                    >
                        <MediathekButton
                            onClick={handleMediathekButtonClick}
                            showLabel
                            iconSize={32}
                            text="tiny"
                            css={{ padding: 6, width: '186px', height: '36px' }}
                        />
                    </div>
                ) : undefined}
                <div
                    onClick={conditionalCb(!isDisabledOrNotReady, this.onVolumeButtonClick)}
                    className={speakerToggleClasses}
                >
                    <div className={volumeSliderClasses}>
                        <Slider
                            ranges={volume}
                            handle="volume"
                            onStatusChange={conditionalCb(
                                !isDisabledOrNotReady,
                                this.onVolumeChange,
                            )}
                            additionalClassName="volume"
                            vertical
                        />
                    </div>
                </div>
                <SubtitleButton
                    isPlaying={this.props.isPlaying}
                    program={program}
                    playout={this.props.playout}
                />
                {program &&
                    !(isRecording ? program.programDetails.restrictions : program)
                        .recordingForbidden &&
                    !isCompletedRecording && (
                        <RecordingButton
                            css={{
                                marginRight: '$7',
                                padding: 0,
                            }}
                            key={program.id}
                            program={
                                isRecording
                                    ? {
                                          stationId: convertedProgram.channel,
                                          textContent: { title: convertedProgram.title },
                                          ...convertedProgram,
                                      }
                                    : {
                                          stationId: program.channel,
                                          textContent: { title: program.title },
                                          ...program,
                                      }
                            }
                        />
                    )}

                {isRecording || isVoD ? (
                    <Fragment>
                        <RecordingControls
                            isDisabled={isDisabledOrNotReady}
                            isPlaying={isPlaying}
                            isSeekingForbidden={isSeekingForbidden}
                            isPauseForbidden={isDisabledOrNotReady || pauseDisabledCondition}
                            isInstantRestartForbidden={instantRestartDisabledCondition}
                            onPlay={this.onPlay}
                            onJumpToStart={this.onJumpToStart}
                            onSeekForward={this.onSeekForward}
                            onSeekBackward={this.onSeekBackward}
                        />
                        {isLiveRecording && (
                            <div
                                onClick={this.onInvokeLive}
                                className="osd__icon icon--live"
                                title="Zum Livesignal"
                            />
                        )}
                    </Fragment>
                ) : (
                    <Fragment>
                        <OSDButton
                            // ToDo: Refactor disabled handling
                            isDisabled={isDisabledOrNotReady || instantRestartDisabledCondition}
                            className="icon--restart"
                            title={this.instantRestartIconTitle(props)}
                            onAction={this.onInvokeInstantRestart}
                        />
                        <OSDButton
                            isDisabled={isDisabledOrNotReady || pauseDisabledCondition}
                            className={props.isPlaying ? 'icon--pause' : 'icon--play'}
                            title={this.pauseIconTitle(props)}
                            onAction={this.onPlay}
                        />
                        <OSDButton
                            isDisabled={isDisabledOrNotReady || !props.isTimeShifted}
                            className={liveToggleClasses}
                            title={props.isTimeShifted ? 'Zurück zum Live-Zeitpunkt.' : ''}
                            onAction={this.onInvokeLive}
                        />
                    </Fragment>
                )}

                <div
                    onClick={conditionalCb(!isDisabledOrNotReady, props.onFullscreenButtonClick)}
                    className={fullscreenToggleClasses}
                    title={props.isFullscreen ? 'Vollbild verlassen (f)' : 'Vollbild (f)'}
                />
            </OSDButtonGroup>
        );
    };

    instantRestartIconTitle = ({ isTimeShifted, isFreeUser }) => {
        let title = 'Sendung von vorne starten.';

        if (!this.isInstantRestartAllowed()) {
            title = 'Neustart der Sendung nicht möglich.';
            if (isFreeUser) {
                title =
                    'Ein Neustart der Sendung ist in dem von Ihnen gebuchten Paket nicht möglich.';
            }
        } else if (isTimeShifted) {
            title =
                'Neustart der Sendung nicht möglich. Sie befinden sich bereits im zeitversetzten Modus.';
        } else if (isSafari()) {
            title =
                'Die Instant-Restart-Funktion ist im Safari Browser leider noch nicht verfügbar. Wir arbeiten daran.';
        }

        return title;
    };

    pauseIconTitle = ({ isPlaying, isFreeUser, isPauseForbidden }) => {
        let title = 'Play';

        if (isPauseForbidden()) {
            title = 'Pausieren nicht möglich.';
            if (isFreeUser) {
                title = 'Pausieren ist in dem von Ihnen gebuchten Paket nicht möglich.';
            }
        } else if (isPlaying) {
            title = isSafari()
                ? 'Die Pause-Funktion ist im Safari Browser leider noch nicht verfügbar. Wir arbeiten daran.'
                : 'Pause';
        }

        return title;
    };

    isInstantRestartAllowed = () => {
        const { program, isInstantRestartAllowed } = this.props;
        return isInstantRestartAllowed && !program?.instantRestartForbidden;
    };

    // eslint-disable-next-line class-methods-use-this
    get hasSeenChannelList() {
        return localStorageItem('hasSeenChannelList');
    }

    // eslint-disable-next-line class-methods-use-this
    set hasSeenChannelList(value) {
        localStorageItem('hasSeenChannelList', value);
    }

    onInvokeLive = () => {
        const { isRecording, program } = this.props;
        const title = isRecording ? program?.programDetails.textContent.title : program?.title;
        this.props.createInstantReplayHint(title, {
            onConfirm: this.props.seekToLiveEdge,
        });
        trackWithPlayerContext(
            WebClientGAEvent.PlayerControls,
            store.getState(),
            WebClientGAEvent.JumpToLive,
            getCurrentScreen(),
        );
    };

    onJumpToStart = () => {
        trackWithPlayerContext(
            WebClientGAEvent.PlayerControls,
            store.getState(),
            WebClientGAEvent.Restart,
            getCurrentScreen(),
        );
        this.onInteraction(this.props.lockOSDToggle);
        this.props.seekTo(0);
    };

    togglePlay = () => {
        const { isPlaying, muted } = this.props;
        this.props.togglePlay(!isPlaying);

        if (isPlaying && muted) {
            this.props.setIsMuted(true);
        }
    };

    onPlay = (e) => {
        if (this.props.unmuteInteractionRequired) {
            this.onFirstUserInteraction(e);
        }

        this.togglePlay();
    };

    onInvokeInstantRestart = () => {
        trackWithPlayerContext(
            WebClientGAEvent.PlayerControls,
            store.getState(),
            WebClientGAEvent.Restart,
            getCurrentScreen(),
        );
        this.props.restartProgram(this.props.program, undefined, 'restart');
    };

    onSetChannelListVisibility = (show = false) => {
        this.onPanelStateChange(show ? OSD_PANEL_CHANNELS : OSD_PANEL_CLOSED);
    };

    onSeekForward = () => {
        this.onInteraction(this.props.lockOSDToggle);
        this.props.seekToRelativeOffset(30);
    };

    onSeekBackward = () => {
        this.onInteraction(this.props.lockOSDToggle);
        this.props.seekToRelativeOffset(-30);
    };

    onProgressClick = (newPosition) => {
        this.onInteraction(this.props.lockOSDToggle);

        if (this.props.isSeekingForbidden || this.props.disabled) {
            if (this.props.isSeekingForbidden) {
                notificationsStore().seekingNotAllowed();
            }
            return;
        }

        if (this.props.isLive) {
            this.props.restartProgram(this.props.program, newPosition, 'seek');
        } else {
            this.props.seekToPercentage(newPosition);
        }
    };

    onOsdBackgroundClick = (e) => {
        if (this.props.disabled) {
            return;
        }

        events.pauseEvent(e);

        if (this.props.unmuteInteractionRequired) {
            this.onFirstUserInteraction(e);
        } else if (this.props.showVolume) {
            this.props.toggleShowVolume(false);
        } else if (this.props.panelState) {
            this.onPanelStateChange(OSD_PANEL_CLOSED);
        } else {
            this.props.onBackgroundClick(e);
        }
    };

    onFirstUserInteraction = (e) => {
        events.pauseEvent(e);

        this.onInteraction();
        this.props.initUnmuteIfNeeded();
    };

    onDetailsToggleClick = (e) => {
        events.pauseEvent(e);

        this.onPanelStateChange(~this.props.panelState & OSD_PANEL_EPG);
    };

    onChannelLogoClick = (e) => {
        events.pauseEvent(e);

        this.onPanelStateChange(~this.props.panelState & OSD_PANEL_CHANNELS);
    };

    onChannelItemClick = (e) => {
        const newChannel = e.currentTarget.getAttribute('rel');
        WebClientGA().trackEvent({
            eventName: WebClientGAEvent.QuickZapper,
            eventDescription: WebClientGAEvent.SelectProgram,
            channelName: newChannel,
            screenName: getCurrentScreen(),
            programId: this.props.program?.id,
            programTitle: this.props.program?.title,
        });

        routeTo(Routes.LIVE_TV_PAGE + newChannel);
    };

    onVolumeButtonClick = (e) => {
        if (!e.target.classList.contains('osd__icon')) {
            return;
        }

        if (this.props.disabled) {
            return;
        }

        events.pauseEvent(e);

        this.onInteraction(this.props.lockOSDToggle);

        if (e.type === 'click' || this.isiPad) {
            this.props.setIsMuted(!this.props.muted);
        } else {
            this.props.toggleShowVolume();
        }
    };

    onPanelStateChange = (nextState) => {
        this.props.updateOsdPanel(nextState);

        if (!this.props.muted) {
            if (this.props.isVolumeReduced && nextState === OSD_PANEL_CLOSED) {
                this.props.setReducedVolumeFlag(false);
            } else if (!this.props.isVolumeReduced && nextState !== OSD_PANEL_CLOSED) {
                this.props.setReducedVolumeFlag(true);
            }
        }

        if (this.props.showVolume) {
            this.props.toggleShowVolume(false);
        }

        if (this.props.panelState === OSD_PANEL_CHANNELS) {
            this.hasSeenChannelList = true;
        }

        this.onInteraction(nextState);
    };

    onVolumeChange = (volume) => {
        this.onInteraction(this.props.lockOSDToggle);
        this.props.changeVolume(volume);
    };

    onInteraction = (lock = false) => {
        this.props.toggleOSD(/* show */ true, /* lock */ !!lock);
    };

    onActiveEdgeLeft = (flag = true) => {
        const { panelState } = this.props;
        const leftEdgeActive = !!(OSD_ACTIVE_EDGE_LEFT & panelState);
        const channelsPanelActive = !!(OSD_PANEL_CHANNELS & panelState);

        if (!channelsPanelActive || leftEdgeActive) {
            if (flag) {
                this.onPanelStateChange(OSD_PANEL_CHANNELS | OSD_ACTIVE_EDGE_LEFT);
            } else if (leftEdgeActive) {
                this.onPanelStateChange(OSD_PANEL_CLOSED);
            }
        }
    };
}

const mapStateToProps = (state) => {
    const { playout, player } = state;
    const { getStationById } = epgStore();
    const { isSearchActive } = uiStore();

    // We don't want to restrict the OSD to the favorite list in favMode as it produces edge cases when switching modes
    const { activeProgram, recording, playoutType, activeChannelId, streamingDetails } = playout;

    const { lastPinEntry } = playout;
    // TODO Those definitions should be the same throughout our app
    const isLiveRecording = playoutType === PLAYOUT_LIVE_RECORDING;
    const isCompletedRecording = playoutType === PLAYOUT_RECORDING;
    const isRecording = isCompletedRecording || isLiveRecording;
    const isVoD = playoutType === PLAYOUT_VOD;
    const isLive = playoutType === PLAYOUT_LIVE;

    const channelId = isRecording ? recording.programDetails.stationId : activeProgram?.channel;

    const activeChannel = channelId && getStationById(channelId); // DO NOT change to activeChannelId as its not recording safe

    const microSiteId = activeChannel
        ? // if channel exists in our channels list we check if there is a microSiteId in the channel object.
          // if there is no microSiteId we know that there is no matching mediathek for this channel
          getMicroSiteIdFromStation(activeChannel)
        : // if the channel does not exist in our channels list we assume the activeChannelId is the microSiteId (mediathek id)
          activeChannelId;

    let seekingForbidden = true;

    if (isVoD) {
        seekingForbidden = false;
    } else if (isRecording) {
        seekingForbidden = recording.recordingSeekingForbidden;
    } else if (isLive) {
        seekingForbidden =
            activeProgram?.seekingForbidden || activeChannel?.restrictions?.seekingForbidden;
    }

    return {
        activeChannelId,
        // player
        playerTimestamp: player.timestamp,
        isPlaying: playout.onHold ? false : player.isPlaying,
        isReady: player.isLoaded && !player.isBusy,
        playerError: playout.playerError,
        activeCooldown: playout.activeCooldown,

        // timeshift
        showTimeShiftExitNotification: playout.showTimeShiftExit,
        timeShiftProgramTitle: playout.timeShiftProgramTitle,
        isTimeShifted: playout.isTimeShifted,
        lastTimeshift: playout.lastTimeshift,

        parentalGuidanceLevel: playout.parentalGuidanceLevel,
        playout,

        // program
        playoutId: playout.playoutId,
        playoutData: activeProgram
            ? playoutDataGenerator(activeProgram, playout.recording, streamingDetails)
            : undefined,
        activeChannel,
        program: isRecording ? recording : activeProgram,
        isSeekingForbidden: seekingForbidden,
        microSiteId,
        isLive,
        isLiveRecording,
        isCompletedRecording,
        isRecording,
        isVoD,

        previewImage: playout.previewImage,

        // volume handling
        showVolume: playout.showVolume,
        volume: playout.onHold || player.isMuted ? 0 : player.volume,
        muted: player.isMuted,
        unmuteInteractionRequired: playout.unmuteInteractionRequired,
        isVolumeReduced: playout.isVolumeReduced || isSearchActive,

        // OSD states
        lockOSDToggle: playout.onHold || playout.lockOSDToggle,
        panelState: playout.panelState,
        fetchThumbnail: playout.fetchThumbnail,

        // PG
        pinRequired: !isPinEntryTTLValid(lastPinEntry),
        isFavourite: activeChannel?.isFavourite,
    };
};

const combinedActions = () => {
    return {
        ...channelSortStore.actions(store),
        ...playoutActions(store),
    };
};
const ConnectedOSD = connect(mapStateToProps, combinedActions)(OSD);
const connectZustand = () => {
    return (props) => {
        const {
            updateStation,
            showOnlyFavorites: isFavoriteMode,
            programDetails,
            epgStations: { value: stations, favorites },
        } = useEpgStore();

        const { isFreeUser, isInstantRestartAllowed } = useUserStore();
        const { createInstantReplayHint } = notificationsStore();

        return (
            <ConnectedOSD
                isFreeUser={isFreeUser}
                isInstantRestartAllowed={isInstantRestartAllowed}
                updateStation={updateStation}
                programDetails={programDetails}
                isFavoriteMode={isFavoriteMode}
                channels={isFavoriteMode ? favorites : stations}
                createInstantReplayHint={createInstantReplayHint}
                {...props} // eslint-disable-line
            />
        );
    };
};

export default memo(connectZustand());
