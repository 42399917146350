/* eslint-disable react/jsx-props-no-spreading */

/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, FunctionComponent, ComponentChildren } from 'preact';
import { CSS, VariantProps } from '@stitches/react';
import { styled } from '../theme';
import { Typography } from '../Typography';

const Text = styled(Typography, {
    variants: {
        variant: {
            small: {
                color: '$white',
                fontSize: '$2',
                fontWeight: '$1',
                margin: 0,
                lineHeight: 1.7,
            },
            // extend here
        },
    },
});

const Root = styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    variants: {
        layout: {
            default: {
                backgroundColor: '$melancholicStone',
                borderRadius: 5,
                width: 'fit-content',
                padding: '0 $7',
            },
            // extend here
        },
    },
});
type RootVariants = VariantProps<typeof Root>;

type PillProps = {
    icon?: JSX.Element;
    onClick?: (e: any) => void;
    layout?: RootVariants['layout'];
    css?: CSS;
    className?: string;
    children?: ComponentChildren;
};

export const Pill: FunctionComponent<PillProps> = ({
    children,
    layout = 'default',
    ...otherProps
}) => {
    return (
        <Root layout={layout} {...otherProps}>
            {children}
        </Root>
    );
};

type TextVariants = VariantProps<typeof Text>;

type PillTextProps = {
    variant?: TextVariants['variant'];
};

export const PillText: FunctionComponent<PillProps & PillTextProps> = ({
    children,
    variant = 'small',
    ...otherProps
}) => {
    return (
        <Pill {...otherProps}>
            <Text variant={variant}>{children}</Text>
        </Pill>
    );
};
