/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';

import SelectableChannelListItem from './selectable-channel-list-item';

const labelName = (value) => {
    const val = value && value.length > 0 && value.charAt(0).match(/^[A-z]+$/);

    return val ? val.pop().toUpperCase() : '#';
};

const imageWidth = 68;
const imageHeight = 54;

export default ({ items, onAddAction }) => {
    let previousLabel = null;
    const list = [];

    items.forEach((item) => {
        let label = labelName(item.displayName);
        if (previousLabel === label) {
            label = '';
        } else {
            previousLabel = label;
        }

        list.push(
            <SelectableChannelListItem
                key={`select_${item.id}`}
                id={item.id}
                title={item.displayName}
                iconUrl={item.getScaledIcon(imageWidth, imageHeight)}
                sourceSet={item.getSourceSet(imageWidth, imageHeight)}
                hidden={item.hidden}
                label={label}
                onAddAction={onAddAction}
            />,
        );
    });

    return <div className="channel-list anim--highlight">{list}</div>;
};

/*
SelectableChannelList.propTypes = {
    items: React.PropTypes.array,
    onAddAction: React.PropTypes.func
} */
