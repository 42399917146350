import { h, FunctionComponent } from 'preact';

import WarningIcon from '@exaring/assets/icons/warn.svg';
import InfoIcon from '@exaring/assets/icons/info.svg';
import { CSS, styled, VariantProps } from '../../theme';
import { Modal, ModalProps } from '../Modal';
import { ModalLayout } from '../Modal/Layouts';

const Root = styled(Modal, {
    background: '$nearBlack',
    color: '$white',
    variants: {
        icon: {
            info: {},
            warning: {},
        },
    },
});

const ContainerLayer = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
    background: 'inherit',
});

export const ExceptionLayout: FunctionComponent = ({ children }) => {
    return (
        <ContainerLayer>
            <ModalLayout>{children}</ModalLayout>
        </ContainerLayer>
    );
};

export type ExceptionModalVariants = VariantProps<typeof Root>;
export type IconVariants = ExceptionModalVariants['icon'];
export type ExceptionModal = ModalProps & { icon: IconVariants; iconCss?: CSS };

export const ExceptionModal: FunctionComponent<ExceptionModal> = ({
    children,
    icon,
    iconCss,
    css,
    ...rootProps
}) => {
    const IconComponent = styled(icon === 'warning' ? WarningIcon : InfoIcon, iconCss || {});

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Root {...rootProps} layout={ExceptionLayout}>
            <IconComponent width="48" height="48" />
            {children}
        </Root>
    );
};
