/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { ComponentChildren, FunctionComponent, h } from 'preact';
import { styled } from '../../theme';
import { Typography } from '../../Typography';
import { LayoutType } from '../../ProgramDetailPage/types';

const Root = styled(Typography, {
    flex: '1 auto',
    margin: '44px 0 0',
    '@md': {
        flexBasis: '50%',
    },
    whiteSpace: 'break-spaces',
    overflowWrap: 'break-word',
    variants: {
        layout: {
            modal: {
                margin: '20px 0 0',
            },
            epg: {},
            mediathek: {},
            microSite: {},
            player: {},
        },
    },
});

const Heading = styled(Typography, {
    margin: 0,
    flex: '1 0 50%',
    fontWeight: 600,
});

export type ProgramDescriptionProps = {
    children: ComponentChildren;
    layout?: LayoutType;
};

export const ProgramDescription: FunctionComponent<ProgramDescriptionProps> = ({
    children,
    layout,
}) => {
    return (
        <Root variant="body2" tagName="section" layout={layout}>
            <Heading variant="body2" tagName="h3">
                Beschreibung
            </Heading>
            {children}
        </Root>
    );
};
