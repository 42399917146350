import { CSS } from '../theme';
import {
    PrimaryStyles,
    PrimaryDisabledStyles,
    SecondaryStyles,
    SecondaryDisabledStyles,
    DarkStyles,
    DarkDisabledStyles,
    EpgDisabledStyles,
    EpgActiveStyles,
} from './types';

const buttonInteractionStylesPrimary = {
    borderColor: '#darkBlue',
    backgroundColor: '#darkBlue',
    outline: 'none',
};

export const primaryStyles: PrimaryStyles = {
    display: 'inline-block',
    margin: '0 0 $2 0',
    padding: '6px 12px',
    width: '240px',
    border: '1px solid $blue',
    borderRadius: '1.62em',
    backgroundColor: '$blue',
    cursor: 'pointer',

    fontFamily: '$primary',
    fontWeight: '$1',
    color: '$white',
    lineHeight: '1.5em',
    textAlign: 'center',
    whiteSpace: 'nowrap',

    '&:focus': buttonInteractionStylesPrimary,
    '&:hover': buttonInteractionStylesPrimary,
    '&:active': {
        ...buttonInteractionStylesPrimary,
        boxShadow: 'none',
    },
};

const buttonInactiveStylesPrimary: CSS = {
    cursor: 'default',
    color: '$white',
    borderColor: '$babyBlue',
    backgroundColor: '$babyBlue',
};

export const primaryDisabledStyles: PrimaryDisabledStyles = {
    ...buttonInactiveStylesPrimary,
    '&:hover': buttonInactiveStylesPrimary,
    '&:focus': buttonInactiveStylesPrimary,
    '&:active': buttonInactiveStylesPrimary,
};

export const epgStyles: PrimaryDisabledStyles = {
    backgroundColor: '$dark',
    borderRadius: 4,
    width: 'auto',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    '&:hover': {
        backgroundColor: '$charcoalGrey',
    },
};

const buttonInteractionStylesSecondary = {
    backgroundColor: '$white',
    borderColor: '$darkBlue',
    color: '$darkBlue',
    outline: 'none',
};

export const secondaryStyles: SecondaryStyles = {
    backgroundColor: '$white',
    borderColor: '$blue',
    color: '$blue',
    '&:focus': buttonInteractionStylesSecondary,
    '&:hover': buttonInteractionStylesSecondary,
    '&:active': {
        ...buttonInteractionStylesSecondary,
        boxShadow: 'none',
    },
};

export const buttonInactiveStylesSecondary = {
    cursor: 'default',
    backgroundColor: '$white',
    borderColor: '$babyBlue',
    color: '$babyBlue',
    outline: 'none',
};

export const secondaryDisabledStyles: SecondaryDisabledStyles = {
    ...buttonInactiveStylesSecondary,
    '&:hover': buttonInactiveStylesSecondary,
    '&:focus': buttonInactiveStylesSecondary,
    '&:active': buttonInactiveStylesSecondary,
};

// NOTICE: wild guess, design missing
const buttonInteractionStylesDark: CSS = {
    backgroundColor: '$white',
    color: '$black',
};

const buttonInteractionStylesEpg: CSS = {
    backgroundColor: '$dark',
    opacity: '.2', // 🗿
    pointerEvents: 'none',
};

export const darkStyles: DarkStyles = {
    color: '$white',
    borderColor: '$white',
    backgroundColor: 'transparent',
    boxShadow: 'none',

    '&:focus': buttonInteractionStylesDark,
    '&:hover': buttonInteractionStylesDark,
    '&:active': buttonInteractionStylesDark,
};

export const darkDisabledStyles: DarkDisabledStyles = {
    ...buttonInteractionStylesDark,
    '&:hover': buttonInteractionStylesDark,
    '&:focus': buttonInteractionStylesDark,
    '&:active': buttonInteractionStylesDark,
};

export const epgDisabledStyles: EpgDisabledStyles = {
    ...buttonInteractionStylesEpg,
    '&:hover': buttonInteractionStylesEpg,
    '&:focus': buttonInteractionStylesEpg,
    '&:active': buttonInteractionStylesEpg,
};

export const epgActiveStyles: EpgActiveStyles = {
    color: '$aquamarine',
};
