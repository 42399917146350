/*
 *  Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, FunctionComponent } from 'preact';
import { useEffect } from 'preact/hooks';
import { isOnAir, isTVFuse } from '@exaring/utils/data/program';
import { logError } from '@exaring/utils/error-logging';
import { RecommendationContents } from '@exaring/ui/components-styled/types/Recommendation';
import { LinkType, Program } from '@exaring/ui/components-styled/ProgramDetailPage/types';
import { Globals, styled } from '@exaring/ui/components-styled/theme';
import { ProgramDetailPage } from '@exaring/ui/components-styled/ProgramDetailPage';
import { epg2ProgramDetailsToProgramDetailPage } from '@exaring/ui/components-styled/ProgramDetailPage/helper';
import { getMicroSiteIdFromStation } from '@exaring/utils/data/channel';
import {
    routeToWaiputhekDetails,
    routeToEpg,
    routeToLive,
    routeToVoD,
    routeToWaiputhekMediathek,
} from '../routes';
import Header from '../components/Header';
import { useEpgStore, useUserStore, notificationsStore } from '../state/Store';
import RecordingButton, {
    programToProgramData,
} from '../components/RecordingButton/RecordingButton';

const StyledPage = styled('div', {
    position: 'relative',
    top: Globals.headerHeight,
    width: '100%',
    paddingTop: '20px',
});

const handleRecommendationClick = (args: {
    channelId: string;
    programId: string;
    recommendation: RecommendationContents;
    contentId?: string;
}) => {
    const { channelId, programId, recommendation, contentId } = args;
    if (contentId) {
        routeToWaiputhekDetails(contentId);
        return;
    }

    if (isOnAir(recommendation) && !recommendation.locked) {
        routeToLive(channelId);
        return;
    }
    routeToEpg(channelId, programId);
};

const routingHandler = (
    program: Program | undefined,
    linkType: LinkType,
    goBack: () => void,
    microSiteId?: string,
) => {
    switch (linkType) {
        case 'close':
            goBack();
            break;
        case 'play':
            if (program && (isTVFuse(program) || program.newTvContentId)) {
                routeToVoD(
                    program.channel.toUpperCase(),
                    program.newTvContentId || program.id,
                    false,
                );
            } else {
                routeToLive(program!.channel.toUpperCase());
            }
            break;
        case 'mediathek':
            if (microSiteId) {
                routeToWaiputhekMediathek(
                    microSiteId,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    false,
                );
            } else if (program?.channel) {
                routeToWaiputhekMediathek(
                    program.channel,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    false,
                );
            }
            break;
        default:
            logError(new Error(`Routing: Link type unknown: "${linkType}"`));
            break;
    }
};

interface Epg2DetailsProps {
    programId: string;
    noHeader?: boolean;
    autoRecord?: boolean;
}

export const Epg2DetailsPage: FunctionComponent<Epg2DetailsProps> = ({
    programId,
    noHeader,
    autoRecord,
}) => {
    const { getStationById, fetchProgramDetails, programDetails, fetchEpgStations, epgStations } =
        useEpgStore();
    const { isFreeUser } = useUserStore();
    const notifications = notificationsStore();

    useEffect(() => {
        fetchEpgStations();
    }, [fetchEpgStations]);

    useEffect(() => {
        if (programId) {
            fetchProgramDetails(programId);
        }
    }, [fetchProgramDetails, programId]);

    const { value: program, state, error } = programDetails || {};
    const station = program?.stationId ? getStationById(program.stationId) : undefined;
    const microSiteId = station ? getMicroSiteIdFromStation(station) : undefined;
    const errorCode = state === 'Error' ? error?.response?.status || 500 : undefined;
    const convertedProgram = program
        ? epg2ProgramDetailsToProgramDetailPage(program, station?.displayName)
        : undefined;

    const goTo = (programDetail: Program | undefined, linkType: LinkType) => {
        routingHandler(programDetail, linkType, routeToEpg, microSiteId);
    };

    const isProgramInLockedChannel = () => {
        const channel = epgStations.value.find((item) => item.id === program?.stationId);
        return channel?.locked;
    };

    const handleMediathekButtonClick = () => {
        if (isFreeUser && isProgramInLockedChannel()) {
            notifications.createUpsellingToast();
        } else {
            goTo?.(convertedProgram, 'mediathek');
        }
    };

    return (
        <StyledPage>
            {!noHeader && <Header />}
            {program && (
                <ProgramDetailPage
                    layout="epg"
                    program={convertedProgram}
                    isLoading={state === 'Loading'}
                    errorCode={errorCode}
                    hideMediathekButton={!microSiteId}
                    goTo={goTo}
                    onRecommendationClick={handleRecommendationClick}
                    handleMediathekButtonClick={handleMediathekButtonClick}
                    renderRecordingButton={(_program) => (
                        <RecordingButton
                            triggerAutoRecord={autoRecord}
                            key={_program.id}
                            program={programToProgramData(_program, isProgramInLockedChannel())}
                            size="big"
                            showLabel
                            isProgramDetails
                        />
                    )}
                />
            )}
        </StyledPage>
    );
};
