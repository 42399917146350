import { z } from 'zod';
import { isObject, isArrayOf } from '@exaring/utils';

const RecordingStatusSchema = z.enum([
    'RECORDABLE',
    'SCHEDULED',
    'RECORDING',
    'FINISHED',
    'UNKNOWN',
]);

const RecordingOverviewSchema = z.object({
    id: z.string(),
    programId: z.string(),
    stationId: z.string(),
    recordingGroup: z.number().optional(),
    seriesId: z.string().optional(),
    recordingStartTime: z.string(),
    title: z.string(),
    epgStartTime: z.string(),
    genreDisplayName: z.string(),
    previewImage: z.string().optional(),
    stationDisplay: z.string(),
    episodeTitle: z.string().optional(),
    episode: z.string().optional(),
    season: z.string().optional(),
    positionPercentage: z.number(),
    durationSeconds: z.number(),
    totalEpisodeCount: z.number().optional(),
    newEpisodeCount: z.number().optional(),
    isNew: z.boolean(),
    locked: z.boolean(),
    status: RecordingStatusSchema,
    expiresAt: z.string().optional(),
    fullyWatched: z.boolean().optional(),
});

const RecordingPlacerHolderSchema = z.object({
    id: z.string(),
    title: z.string().nullish(),
    stationId: z.string().nullish(),
    seriesId: z.string().nullish(),
    programId: z.string(),
    recordingGroup: z.number().nullish(),
    status: RecordingStatusSchema,
    placeHolder: z.boolean(),
});

const RecordingStatusResponseSchema = z.object({
    id: z.string(),
    recordingGroup: z.number(),
    seriesId: z.string(),
    status: RecordingStatusSchema,
});

export type Recording = z.infer<typeof RecordingOverviewSchema>;
export type RecordingPlaceholder = z.infer<typeof RecordingPlacerHolderSchema>;
export type RecordingStatus = z.infer<typeof RecordingStatusSchema>;
export type RecordingStatusResponse = z.infer<typeof RecordingStatusResponseSchema>;
export type RecordingSelection = 'AVAILABLE' | 'PLANNED';

export const RecordingStatusEnum = RecordingStatusSchema.enum;

export const isPlaceHolder = (value: unknown): value is RecordingPlaceholder => {
    return RecordingPlacerHolderSchema.safeParse(value).success;
};

export const isRecording = (value: unknown): value is Recording => {
    return RecordingOverviewSchema.safeParse(value).success;
};

export const isValidRecordingListJson = (value: unknown): value is Recording[] => {
    return isArrayOf(isObject, value);
};
