/*
 *  Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, Fragment, FunctionComponent } from 'preact';
import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import { Globals, styled } from '@exaring/ui/components-styled/theme';
import { now } from '@exaring/utils';
import { useHover } from '@exaring/ui/hooks/useHover';
import { Epg } from '../components/Epg2/Epg';
import { SettingsBar } from '../components/Epg2/SettingsBar/SettingsBar';
import Header from '../components/Header';
import { Preview } from '../components/Epg2/Preview/Preview';
import { epgStore } from '../state/Store';
import { ScrollContext } from '../components/Epg2/context/ScrollContext';
import { Epg2DetailsPage } from './Epg2DetailsPage';
import { RouteParams } from '../@types/router';
import { EpgPaging } from '../components/Epg2/Paging/Paging';

const containerLeftIndent = 62;

const StyledEpgPage = styled('div', {
    $$titleHeight: '72px',
    $$settingsBarHeight: '36px',
    $$programPreviewMarginBottom: '24px',
    $$programPreviewHeightLg: '244px',
    $$programPreviewHeightMd: '239px',
    $$programPreviewHeightSm: '119px',
    $$programPreviewHeightXs: '118px',
    $$marginLeft: `${containerLeftIndent}px`,
    position: 'relative',
    marginTop: Globals.headerHeight,
    marginLeft: '$$marginLeft',
    height: `calc(100vh - ${Globals.headerHeight})`,
    width: `calc(100% - $$marginLeft)`,
});

const Title = styled('div', {
    height: '$$titleHeight',
    padding: '21px 0',
    opacity: '0.9',
    fontSize: '20px',
    fontWeight: '$3',
});

const NavigationContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    marginRight: '60px',
    height: 'calc($$programPreviewHeightXs + ($$settingsBarHeight + $$programPreviewMarginBottom))',

    '@sm': {
        height: 'calc($$programPreviewHeightSm + ($$settingsBarHeight + $$programPreviewMarginBottom))',
    },
    '@md-width-and-md-height': {
        height: 'calc($$programPreviewHeightMd + ($$settingsBarHeight + $$programPreviewMarginBottom))',
    },
    '@lg-width-and-md-height': {
        height: 'calc($$programPreviewHeightLg + ($$settingsBarHeight + $$programPreviewMarginBottom))',
    },
});

const EpgContainer = styled('div', {
    position: 'relative',
    display: 'flex',
    height: 'calc(100% - ($$programPreviewHeightXs + ($$settingsBarHeight + ($$programPreviewMarginBottom + $$titleHeight))))',

    '@sm': {
        height: 'calc(100% - ($$programPreviewHeightSm + ($$settingsBarHeight + ($$programPreviewMarginBottom + $$titleHeight))))',
    },
    '@md-width-and-md-height': {
        height: 'calc(100% - ($$programPreviewHeightMd + ($$settingsBarHeight + ($$programPreviewMarginBottom + $$titleHeight))))',
    },
    '@lg-width-and-md-height': {
        height: 'calc(100% - ($$programPreviewHeightLg + ($$settingsBarHeight + ($$programPreviewMarginBottom + $$titleHeight))))',
    },
});

interface EpgPageProps {
    matches: RouteParams;
    noHeader?: boolean;
    autorecord?: boolean;
}

const StyledFullscreenWrapper = styled('div', {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
    zIndex: 100,
    backgroundColor: '$nearBlack',

    variants: {
        isHidden: {
            true: {
                display: 'none',
            },
        },
    },
});

const currentTime = now();

export const EpgPage: FunctionComponent<EpgPageProps> = ({
    matches,
    noHeader = false,
    autorecord: autoRecord,
}) => {
    const { programId, channelId } = matches;
    const { setPreview } = epgStore();
    const showDetails = programId && channelId;
    const renderPreview = !programId && !channelId;

    useEffect(() => {
        return () => {
            setPreview(undefined);
        };
    }, [setPreview]);

    const [scrollState, setScrollState] = useState({
        dayUnix: currentTime.unix(),
        hour: currentTime.get('hour'),
    });
    const setDayUnix = useCallback(
        (dayUnix: number) => setScrollState((prev) => ({ ...prev, dayUnix })),
        [],
    );
    const setHour = useCallback(
        (hour: number) => setScrollState((prev) => ({ ...prev, hour })),
        [],
    );

    const scrollToTimeRef =
        useRef<(dayUnix: number | undefined, hour: number | undefined) => void>();
    const scrollToChannelRef = useRef<(uuid?: string) => void>();
    const setScrollToTimeHandler = useCallback(
        (handler: (dayUnix: number | undefined, hour: number | undefined) => void) => {
            scrollToTimeRef.current = handler;
        },
        [],
    );
    const setScrollToChannelHandler = useCallback((handler: (uuid?: string) => void) => {
        scrollToChannelRef.current = handler;
    }, []);

    const [containerRef, isHovered] = useHover<HTMLDivElement>();
    return (
        <>
            <Header id="epg-page" />
            <StyledEpgPage>
                <Title>Programmübersicht</Title>
                <ScrollContext.Provider
                    value={{
                        dayUnix: scrollState.dayUnix,
                        hour: scrollState.hour,
                        setDayUnix,
                        setHour,
                        setScrollToChannelHandler,
                        setScrollToTimeHandler,
                        scrollToTimeRef,
                        scrollToChannelRef,
                    }}
                >
                    <NavigationContainer>
                        {renderPreview && <Preview />}
                        <SettingsBar />
                    </NavigationContainer>
                    <EpgContainer ref={containerRef}>
                        <Epg />
                        <EpgPaging
                            show={isHovered}
                            indentLeft={-containerLeftIndent}
                            indentRight={10}
                        />
                    </EpgContainer>
                </ScrollContext.Provider>
            </StyledEpgPage>
            <StyledFullscreenWrapper isHidden={!showDetails}>
                {showDetails && (
                    <Epg2DetailsPage
                        programId={programId}
                        noHeader={noHeader}
                        autoRecord={autoRecord}
                    />
                )}
            </StyledFullscreenWrapper>
        </>
    );
};
