import { h, FunctionComponent } from 'preact';
import { styled } from '../theme';
import { RectangleSkeleton } from './RectangleSkeleton';

const Shape = styled('div', {
    backgroundColor: '$nearBlack',
    display: 'inline-block',
});

const RectangleHole = styled(Shape, {
    marginLeft: '10px',
    height: '24px',
    width: '32px',
    borderRadius: '4px',
});

const CircleHole = styled(Shape, {
    marginLeft: '24px',
    borderRadius: '50%',
    width: '40px',
    height: '40px',

    '@md-width-and-md-height': {
        marginLeft: '19px',
        width: '48px',
        height: '48px',
    },
});

export const ChannelSkeleton: FunctionComponent<{ animated?: boolean }> = ({ animated }) => (
    <RectangleSkeleton
        animated={animated}
        css={{
            height: 'calc(100% - 2px)',
            boxShadow: '3px 0px 3px -1px black',
            display: 'flex',
            alignItems: 'center',
        }}
    >
        <RectangleHole />
        <CircleHole />
    </RectangleSkeleton>
);
