import { getWaipuDeviceId } from '@exaring/utils';
import constants from '../constants';
import { post } from '../request';

const getURLSearchParamsWithOptionalDeviceId = () => {
    const payload = new URLSearchParams();
    const deviceId = getWaipuDeviceId();

    if (deviceId) {
        payload.append('waipu_device_id', deviceId);
    }

    return payload;
};

/**
 * @param {String} code
 * @param {String} codeVerifier
 * @param {String} redirectUri @deprecated as this parameter was an spec error
 * @param {String} clientId
 * @return {Object}
 */
export const token = (code, codeVerifier, redirectUri, clientId) => {
    const payload = getURLSearchParamsWithOptionalDeviceId();
    payload.append('grant_type', 'authorization_code');
    payload.append('client_id', clientId);
    payload.append('code_verifier', codeVerifier);
    payload.append('code', code);

    const req = post(
        `${constants.AUTH_BASE_URL}/oauth/token`,
        { 'Content-Type': 'application/x-www-form-urlencoded' },
        payload,
    );
    req.authorize = false;
    req.credentials = 'include';
    return req;
};

/**
 * @param {String} refreshToken
 * @param {String} clientId
 * @return {Object}
 */
export const refreshToken = (_refreshToken, clientId) => {
    const payload = getURLSearchParamsWithOptionalDeviceId();
    payload.append('grant_type', 'refresh_token');
    payload.append('client_id', clientId);
    payload.append('refresh_token', _refreshToken);

    const req = post(
        `${constants.AUTH_BASE_URL}/oauth/token`,
        { 'Content-Type': 'application/x-www-form-urlencoded' },
        payload,
    );
    req.authorize = false;
    req.credentials = 'include';
    return req;
};

/**
 * @param {string} username
 * @param {string} password
 * @return {Object}
 */
export const mergeAccounts = (username, password) => {
    const payload = getURLSearchParamsWithOptionalDeviceId();
    payload.append('grant_type', 'password');
    payload.append('username', username);
    payload.append('password', password);

    const req = post(
        `${constants.AUTH_BASE_URL}/oauth/merge`,
        {
            Accept: 'application/vnd.waipu.auth-oauth-token-account-merge-v1+json',
        },
        payload,
    );
    req.authorize = false;
    req.credentials = 'include';
    return req;
};

/**
 * @param {string} amazonToken
 * @param {string} deviceId
 */
export const loginWithAmazon = (amazonToken) => {
    const waipuDeviceId = getWaipuDeviceId();
    const req = post(
        `${constants.AUTH_BASE_URL}/api/amazon-token`,
        {
            'Content-Type': 'application/vnd.waipu.users-amazon-token-v1+json',
        },
        { token: amazonToken, waipuDeviceId },
    );
    req.authorize = false;
    return req;
};

/**
 * @param {Record<'identityToken' | 'authorizationCode' | 'waipuDeviceId', string>} config
 *
 * Responses
 *
 *  200     Ok
 */
export const loginWithAppleId = ({ idToken, authCode }) => {
    const waipuDeviceId = getWaipuDeviceId();
    const req = post(
        `${constants.AUTH_BASE_URL}/api/apple-token`,
        {
            'Content-Type': 'application/vnd.waipu.users-appleid-token-v1+json',
        },
        { identityToken: idToken, authorizationCode: authCode, waipuDeviceId },
    );
    req.authorize = false;
    return req;
};
