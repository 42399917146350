import { h, FunctionComponent } from 'preact';
import { CSS } from '@stitches/react';
import { styled } from '../theme';

const Overlay = styled('div', {
    $$barHeight: '3px',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    borderRadius: '$1',
    overflow: 'hidden',
    variants: {
        progress: {
            '-1': {
                display: 'none',
            },
        },
        noBorderRadius: {
            true: {
                borderRadius: '0',
            },
        },
    },
});

const ProgressBar = styled('div', {
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%',
    height: '$$barHeight',
    borderBottom: '$$barHeight solid white',
});

const Progress = styled('div', {
    position: 'absolute',
    bottom: '0',
    left: '0',
    height: '$$barHeight',
    width: '0',
    backgroundColor: '$blue',
});

export const ProgramProgressBar: FunctionComponent<{
    progress: number;
    live?: number;
    noBorderRadius?: boolean;
    css?: CSS;
}> = ({ progress, live, noBorderRadius, css }) => (
    <Overlay
        data-testid="progressBarOverlay"
        data-progress={progress}
        progress={progress}
        noBorderRadius={noBorderRadius}
        css={css}
    >
        <ProgressBar data-testid="progressBarBackground" />
        <Progress data-testid="progressBarCurrent" css={{ width: `${progress}%` }} />
        {live !== undefined && (
            <Progress
                data-testid="progressBarLive"
                css={{ width: `${live}%`, backgroundColor: '$bluegray' }}
            />
        )}
    </Overlay>
);
