import { programs, programsQuery, programCurrent, programDetails } from '../requests/epg';
import { requestHookChain } from '../networking.v2';

const api = {
    programs: requestHookChain(programs),
    programsQuery: requestHookChain(programsQuery),
    programCurrent: requestHookChain(programCurrent),
    programDetails: requestHookChain(programDetails),
};

export default api;
