/* eslint-disable react/jsx-props-no-spreading */

import { h, FunctionComponent } from 'preact';

import { Program } from '../../ProgramDetailPage/types';
import { styled } from '../../theme';
import { Typography } from '../../Typography';

export const ChannelBox = styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const ChannelTitle = styled(Typography, {
    fontSize: '16px',
    marginRight: '10px',
    lineHeight: 1.7,
    color: 'white',
    flex: 1,
});

export const ProgramChannel: FunctionComponent<{
    program: Program;
    stationDisplay?: string;
}> = ({ program, stationDisplay }) => {
    const { channelDisplay } = program;

    return (
        <ChannelBox>
            <ChannelTitle nogutter>{stationDisplay || channelDisplay}</ChannelTitle>
        </ChannelBox>
    );
};
