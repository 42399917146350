import { z } from 'zod';

const displayType = z.enum([
    'clips',
    'highlights',
    'hollywood',
    'mediaLibraries',
    'movies',
    'playlists',
    'teaser',
    'stations',
    'videoDetails',
    'large16x9',
    'small16x9',
    'small1x1',
    'small2x3',
]);

const recordingState = z
    .enum(['scheduled', 'recording', 'finished', 'recording-possible', 'recording-not-possible'])
    .nullable();

const contentsApiSchema = z.object({
    url: z.string(),
    title: z.string(),
    autoplay: z.boolean(),
    series: z.boolean(),
    locked: z.boolean(),
    // optionals
    subtitle: z.string().nullish(),
    genre: z.string().nullish(),
    img: z.string().nullish(),
    episodeTitle: z.string().nullish(),
    channelImg: z.string().nullish(),
    channelDisplay: z.string().nullish(),
    positionPercentage: z.number().nullable(),
    duration: z.number().nullable(),
    season: z.number().nullable(),
    episode: z.number().nullable(),
    cardType: z.enum(['end']).nullable(),
    recordingState,
    startTime: z.string().nullish(),
    stopTime: z.string().nullish(),
});

const recordingDiscoveryApiSchema = z.object({
    id: z.string(),
    title: z.string(),
    anchor: z.string().nullable(), // actually nullable
    displayType,
    contents: contentsApiSchema.array(),
});

const contentsSchema = contentsApiSchema.extend({
    stationId: z.string().optional(),
    programId: z.string().optional(),
});

const recordingDiscoverySchema = recordingDiscoveryApiSchema.extend({
    contents: contentsSchema.array(),
});

export type RecordingDiscoveryContentApi = z.infer<typeof contentsApiSchema>;
export type RecordingDiscoveryApi = z.infer<typeof recordingDiscoveryApiSchema>;
export type RecordingDiscovery = z.infer<typeof recordingDiscoverySchema>;
export type RecordingDiscoveryContent = z.infer<typeof contentsSchema>;
type RecordingContentDiscoveryResponse = RecordingDiscoveryApi;

export const isRecordingDiscoveryEntity = (value: unknown): value is RecordingDiscoveryApi => {
    return recordingDiscoveryApiSchema.safeParse(value).success;
};

export const isRecordingContentDiscovery = (
    value: unknown,
): value is RecordingContentDiscoveryResponse => {
    return isRecordingDiscoveryEntity(value);
};
