import { h, FunctionComponent } from 'preact';

import { PulseLoadingIndicatorAnimation, styled } from '@exaring/ui/components-styled/theme';
import { Typography } from '@exaring/ui/components-styled/Typography';
import { ImageWithFallback } from '@exaring/ui/components-styled/Tile/parts/ImageWithFallback';
import { GridContainer, GridItem } from '@exaring/ui/components-styled/Grid';

import { assetUrl } from '../../../helper';

/**
 * this container is an overlay which combining
 * three features, it provides the gradient overlay which
 * is partly covering the preview image on the left
 * it provides the the loading animation overlay
 * of the whole container and is also rendering the
 * recording information text. Another nice aspect of
 * this solution is, it has a complete covering click
 * event target.
 */
export const GridContentContainer = styled(GridContainer, {
    position: 'absolute',

    top: '0px',
    left: '0px',
    right: '0px',
    height: '100%',
    flexWrap: 'nowrap',
    gap: 'calc($$gutterWidth * 2)',
    alignItems: 'center',

    linearGradient: '90deg, rgba(24,22,35,0) 38%, rgba(24,22,35,1) 50%',
    '&:hover': {
        linearGradient: '90deg, rgba(52,50,62,0) 38%, rgba(52,50,62,1) 50%',
        cursor: 'pointer',
    },

    variants: {
        isLoading: {
            true: {
                background: '$dark',
                animation: `${PulseLoadingIndicatorAnimation} 1s infinite linear`,
                '&:hover': {
                    // do not show any hover states on loading
                    background: 'inherit',
                    cursor: 'inherit',
                },
            },
        },

        isNotLoaded: {
            true: {
                background: '$dark',
                '&:hover': {
                    // do not show any hover states on loading
                    background: '$dark',
                    cursor: 'inherit',
                },
            },
        },

        isMediaHovered: {
            true: {
                background: 'rgba(0,0,0,0.7)', // adds the hover effect for the left side panel
            },
        },

        detailsDisabled: {
            true: {
                '&:hover': {
                    linearGradient: '90deg, rgba(24,22,35,0) 38%, rgba(24,22,35,1) 50%',
                    cursor: 'inherit',
                },
            },
        },

        noGradientBackground: {
            true: {
                backgroundImage: 'none',

                '&:hover': {
                    backgroundImage: 'none',
                },
            },
        },

        solidBackground: {
            true: {
                linearGradient: '90deg, rgba(0,0,0,0) 0%, rgba(24,22,35,1) 25%',

                '&:hover': {
                    linearGradient: '90deg, rgba(0,0,0,0) 0%, rgba(24,22,35,1) 25%',
                    cursor: 'inherit',
                },
            },
        },
    },
});

export const Title = styled(Typography, {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '20px',
    fontWeight: '200',
    marginBottom: '$$gutterWidth',
    '@md': {
        marginBottom: '$$gutterWidthSmall',
    },
    '@lg': {
        marginBottom: '$$gutterWidth',
    },
});

export const HeroContainer = styled('div', {
    position: 'relative',
    width: '100%',
    minHeight: '244px',
    maxHeight: '500px',
    borderRadius: '$1',
    overflow: 'hidden',
});

export const HeroContent = styled(GridItem, {
    height: '100%',
});

export const TextWrapper = styled('div', {
    overflow: 'hidden',
});

export const HeroImage = styled(ImageWithFallback, {
    width: '100%',
    minHeight: '244px',
    maxHeight: '364px',
    '@md': {
        minHeight: '500px',
        maxHeight: '720px',
    },
    objectFit: 'cover',
    aspectRatio: 16 / 9,
});

const StyledLogo = styled('div', {
    display: 'flex',
    width: 'min(100%, 224px)',
    height: 'min(100%, 208px)',

    alignItems: 'center',
    justifyContent: 'space-around',

    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
});

const Pill = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    marginTop: '70px',
    padding: '4px 8px',
    width: 'min(100%, 136px)',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: '$2',

    background: 'rgba(0,0,0,.7)',
    borderRadius: '$3',

    '@sm': {
        width: 'min(100%, 166px)',
        marginTop: '80px',
    },
    '@md': {
        padding: '2px',
        marginTop: '60px',
        width: 'min(100%, 136px)',
    },
    '@lg': {
        width: 'min(100%, 146px)',
        marginTop: '70px',
    },
});

export const WaipuPackageLogo: FunctionComponent<{
    filename: string;
    label?: string;
    css?: any;
}> = ({ filename, label, css }) => {
    return (
        <StyledLogo
            css={{
                backgroundImage: `url(${assetUrl(`logos/${filename}.png`)})`,
                marginBottom: '-70px',
                marginLeft: '-20px',
                width: '40%',
                height: '40%',
                '@xs': {
                    width: '35%',
                    height: '35%',
                    marginBottom: '50px',
                },
                '@sm': {
                    backgroundImage: `url(${assetUrl(`logos/${filename}@2x.png`)})`,
                    width: '55%',
                    height: '55%',
                    marginBottom: '-100px',
                    marginLeft: '20px',
                },
                '@md': {
                    width: '45%',
                    height: '45%',
                    marginBottom: '-20px',
                    marginLeft: '30px',
                },

                '@lg': {
                    backgroundImage: `url(${assetUrl(`logos/${filename}@3x.png`)})`,
                },
                ...css,
            }}
        >
            {label ? <Pill>{label}</Pill> : undefined}
        </StyledLogo>
    );
};
