/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

const isBrowser = () => typeof window !== 'undefined';

if (isBrowser()) {
    window.dataLayer = window.dataLayer || [];

    /* eslint-disable no-inner-declarations, prefer-rest-params */
    function gtag() {
        window.dataLayer.push(arguments);
    }
    /* eslint-enable no-inner-declarations, prefer-rest-params */

    // Google Consent Mode for Sourcepoint
    gtag('consent', 'default', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
        ad_personalization: 'denied',
        personalization_storage: 'denied',
        ad_user_data: 'denied',
        functionality_storage: 'denied',
        security_storage: 'denied',
        wait_for_update: 500,
    });
}

let initialized = false;
let enabled = true;
let el;

// allows mixin dynamic base data for every track event
let baseLoadCb;

export const init = (trackingId) => {
    if (!isBrowser()) {
        return;
    }
    /* eslint-disable */
    // prettier-ignore
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
        let f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;j.src='//t.waipu.tv/gtm.js?id=' + i + dl;el =j;
        f ? f.parentNode.insertBefore(j, f) : d.body.insertBefore(j, d.body.firstChild); // fallback for empty body
    })(window, document, 'script', 'dataLayer', trackingId);
    /* eslint-enable */
    initialized = true;
};

export const deinit = () => {
    if (!isBrowser()) {
        return;
    }
    initialized = false;
    if (el) {
        el.remove();
    }
    window.dataLayer = [];
};

export const disable = () => {
    if (!isBrowser()) {
        return undefined;
    }
    enabled = false;
    return enabled;
};

export const enable = () => {
    if (!isBrowser()) {
        return undefined;
    }
    enabled = true;
    return enabled;
};

export const isInitialized = () => {
    if (!isBrowser()) {
        return undefined;
    }
    return initialized;
};

export const setBaseLoadCb = (cb) => {
    if (!isBrowser()) {
        return;
    }
    baseLoadCb = cb;
};

export const track = (trackingLoad) => {
    if (!isBrowser()) {
        return;
    }
    const baseData = (baseLoadCb && baseLoadCb()) || {};
    if (enabled) {
        if (!isInitialized()) {
            console.error('WARNING: Calling track before tracker has been inititialized');
        }
        window.dataLayer.push({ ...baseData, ...trackingLoad });
    }
};

export default track;
