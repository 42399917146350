import { styled } from '../theme';

export const StyledContainer = styled('div', {
    textAlign: 'center',
    fontFamily: '$primary',
    fontSize: '$1',
    fontWeight: '$1',
    color: '$mediumGray',
    margin: '40px 0',

    '@sm': {
        textAlign: 'end',
    },
    '@md': {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '61px 25px',
    },
});
