import { isObject, isString, mapWithoutNil } from '@exaring/utils/index';
import { HighlightItem, decode as decodeHighlightItem } from './HighlightItem';

export const SECTION_TEASER = 'teaser';
export const SECTION_MEDIA_LIBRARIES = 'mediaLibraries';
export const SECTION_MOVIES = 'movies';
export const SECTION_PLAYLISTS = 'playlists';
export const SECTION_HOLLYWOOD = 'hollywood';
export const SECTION_CLIPS = 'clips';

export type HighlightSection =
    | HighlightTeaserSection
    | HighlightMediaLibrariesSection
    | HighlightMoviesSection
    | HighlightPlaylistsSection
    | HighlightHollywoodSection
    | HighlightClipsSection;

export type DisplayType = HighlightSection['displayType'];

interface HighlightTeaserSection extends HighlightBaseSection {
    displayType: typeof SECTION_TEASER;
}

interface HighlightMediaLibrariesSection extends HighlightBaseSection {
    displayType: typeof SECTION_MEDIA_LIBRARIES;
}

interface HighlightMoviesSection extends HighlightBaseSection {
    displayType: typeof SECTION_MOVIES;
}

interface HighlightPlaylistsSection extends HighlightBaseSection {
    displayType: typeof SECTION_PLAYLISTS;
}

interface HighlightHollywoodSection extends HighlightBaseSection {
    displayType: typeof SECTION_HOLLYWOOD;
}

interface HighlightClipsSection extends HighlightBaseSection {
    displayType: typeof SECTION_CLIPS;
}

interface HighlightBaseSection {
    contents: Array<HighlightItem>;
    id: string;
    locked?: boolean;
    title?: string;
    upselling?: boolean;
}

export const matcher = <R>(displayType: DisplayType, options: Record<DisplayType, () => R>) =>
    options[displayType]();

const isValidJson = (value: any): value is HighlightSection => {
    if (isObject(value)) {
        const { id, contents, displayType } = value;
        return (
            isString(id) &&
            Array.isArray(contents) &&
            (displayType === SECTION_TEASER ||
                displayType === SECTION_MEDIA_LIBRARIES ||
                displayType === SECTION_MOVIES ||
                displayType === SECTION_PLAYLISTS ||
                displayType === SECTION_HOLLYWOOD ||
                displayType === SECTION_CLIPS)
        );
    }

    return false;
};

export const decode = (value: unknown): HighlightSection | undefined => {
    return isValidJson(value)
        ? {
              ...value,
              contents: mapWithoutNil(value.contents, decodeHighlightItem),
          }
        : undefined;
};
