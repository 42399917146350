/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

/**
 * returns a new promise with exposed _resolve & _reject function
 * @return {Promise}
 */
export function controlledPromise() {
    let _resolve;
    let _reject;
    let _catch;

    const temp = new Promise((resolve, reject) => {
        _resolve = resolve;
        _reject = reject;
    }).catch((e) => {
        // use initial catch handler to initialize the proxy error handler
        // for the controlled promise. Which allows dynamically reassign
        // the used error handler
        _catch?.(e);
    });

    temp.catch = (callback) => {
        // override initial catch handler to allow custom dynamically override catch block
        _catch = callback;
    };

    temp.resolve = _resolve;
    temp.reject = _reject;
    temp.done = false;

    if (temp.finally) {
        temp.finally(() => {
            temp.done = true;

            const blocker = () => {
                throw new Error('promise is already fulfilled');
            };

            temp.resolve = blocker;
            temp.reject = blocker;
        });
    }

    return temp;
}

/**
 * returns a new promise that rejects on given timeout
 * @return {Promise}
 */
export const timeoutPromise = (timeout, promise) => {
    let _timeout;

    return new Promise((resolve, reject) => {
        _timeout = setTimeout(reject.bind(undefined, 'promise timeout'), timeout);

        promise.then(() => clearTimeout(_timeout));
        promise.then(resolve, reject);
    });
};

export const promisify = (fn) => (value) => new Promise((resolve) => fn(value, resolve));
