/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { isEmptyString, isString, strj } from '../string';
import { isNil } from '../validators';

/**
 * Return the url parameters or an object representation of the given string
 * of the url param format: 'var=val&param=val2'
 * @return {Record<string, string>}
 */
export const getUrlParams = (urlParamString = window.location.search) => {
    const params = new URLSearchParams(urlParamString);
    const paramsAsEntries = params.entries();
    return Object.fromEntries(paramsAsEntries);
};

/**
 * Return only the required url parameters or an object representation of the given string
 * of the url param format: 'var=val&param=val2'
 * @return {Record<string, string>}
 */
export const getUrlParamsByKeys = (keys, urlParamString) =>
    Object.entries(getUrlParams(urlParamString))
        .filter(([key, value]) => keys.includes(key) && isString(value) && !isEmptyString(value))
        .reduce((cur, [key, value]) => {
            return Object.assign(cur, { [key]: value });
        }, {});

/**
 * expects a string in flavor of window.location.pathname (`/` | `/foo` | `/bar/test/foo`)
 * @param path
 */
export function rootPath(path) {
    const _rootPath = path.split('/');
    return (_rootPath && _rootPath.length && _rootPath[1] && `/${_rootPath[1]}`) || '/';
}

/**
 * Converts a key-value based string commonly found in URLs (searchparams and fragment) into an object
 * @param {string} string to convert
 * @param {string} pairDelimiter
 * @param {string} valueDelimiter
 * @returns {object}
 */
export function urlStringToObject(string, pairDelimiter = '&', valueDelimiter = '=') {
    if (!string) {
        return {};
    }

    let _string = string;

    // if string starts with '?' or '#' then remove those characters
    if (_string.charAt(0) === '?' || _string.charAt(0) === '#') {
        _string = _string.substring(1);
    }

    // spread out the key-value pairs separated by a delimiter
    const keyValuePairs = _string.split(pairDelimiter);
    const object = {};

    // return it as an object
    for (let i = 0; i < keyValuePairs.length; i += 1) {
        const array = keyValuePairs[i].split(valueDelimiter);
        // eslint-disable-next-line prefer-destructuring
        object[array[0]] = array[1];
    }

    return object;
}

/**
 * Converts an object to a key-value based string commonly found in URLs
 * @param {object} object to stringify
 * @param {string} pairDelimiter -> delimiter between key-value-pairs
 * @param {string} valueDelimiter -> delimiter inside key-value-pairs
 * @returns {string}
 */
export function toUrlString(object, pairDelimiter = '&', valueDelimiter = '=') {
    if (!object) {
        return '';
    }

    const keys = Object.keys(object);
    const stringParts = [];

    for (let i = 0; i < keys.length; i += 1) {
        stringParts.push(`${keys[i]}${valueDelimiter}${object[keys[i]]}`);
    }

    return stringParts.join(pairDelimiter);
}

/**
 * Returns an object of keys and values from the hash area of a string, URL-string or current location
 * @param {string} baseUrl URL to search
 * @returns {object} object containing keys and values of data contained in URL fragment
 */
export function getUrlHashParams(baseUrl = window.location.hash) {
    // split the string by '#'
    const stringParts = baseUrl.split('#');

    // initialize the search string as the first part in case a string which might be
    // the actual fragment identifier has been handed over to the function, otherwise ''
    let hashString = stringParts[0].includes('?') ? '' : stringParts[0];

    // the part of the URL containing at least one '=' and no '?' is the one we are looking for
    // (starting at 1 because it can possibly only be the first part if there are no other parts)
    const partsLength = stringParts.length;
    for (let i = 1; i < partsLength; i += 1) {
        if (stringParts[i].includes('=') && !stringParts[i].includes('?')) {
            hashString = stringParts[i];
        }
    }

    return urlStringToObject(hashString);
}

/**
 * Looks for a 'key' supposedly in a URL fragment string and returns its value, if found
 * @param {string} name 'Key' to look for
 * @param {string} baseUrl URL to search
 * @returns {string} value of found key
 */
export function getFragmentIdentifier(name, baseUrl = window.location.href) {
    const fragmentObject = getUrlHashParams(baseUrl);
    return fragmentObject[name] || '';
}

/**
 * Looks for a 'key' in an URL string and returns its value, if found
 * @param {string} name 'Key' to look for
 * @param {string} baseUrl URL to search
 * @returns {string} value of found key or null
 */
export function getParameterByName(name, baseUrl = window.location.href) {
    return new URL(baseUrl).searchParams.get(name);
}

/**
 * Looks for a 'key' in a URL string then replaces its value if the parameter already exists or set it if it doesn't
 * @param {string} name 'Key' to look for
 * @param {string} value 'Value' to set
 * @param {string} baseUrl URL to search
 * @returns {string} URL
 */
export function setParameterIntoUrl(name, value, baseUrl = window.location.href) {
    const url = new URL(baseUrl);

    if (url.searchParams.has(name)) {
        url.searchParams.set(name, value);
    } else {
        url.searchParams.append(name, value);
    }

    return url.href;
}

/**
 * Generates an url string with a given baseUrl. Also encodes param values.
 * @param {string} baseUrl https://foo.bar
 * @param {object} params {param: 'world', elf: 'dr%C3%B6lvzehn'}
 * @param {boolean} ignoreNullish
 * @returns {string} url https://foo.bar/?param=world&elf=dr%C3%B6lvzehn
 */
export function urlWithParams(baseUrl, params, ignoreNullish = false) {
    const url = new URL(baseUrl);

    Object.keys(params).forEach((param) => {
        const isSet = !isNil(params[param]);

        if ((ignoreNullish && isSet) || !ignoreNullish) {
            url.searchParams.set(param, params[param]);
        }
    });

    return url.href;
}

/**
 * url template renderer to pass optional params into an url string. Also encodes
 * param values.
 * @param {string} str template string 'hello ${param}'
 * @param {object} params {param: 'world', lol: 'isIgnored'}
 * @returns {string} url string with replaced place holders 'hello world'
 */
export function urlTplParams(str, params) {
    let url = str;

    Object.keys(params).forEach((param) => {
        url = url.replace(`$\{${param}}`, encodeURIComponent(params[param]));
    });

    return url;
}

/**
 * Returns the second-level-domain and top-level-domain of a location
 * @param location
 * @returns {string}
 */
export const getBaseUrl = (location = window.location.href) => {
    const url = new URL(location);
    const domain = url.host.split('.');

    let ipDomain = false;

    if (domain.length > 2) {
        if (domain.length === 4 && parseInt(domain[0], 10) > 0) {
            ipDomain = true;
        } else {
            domain.shift();
        }
    }

    let base = domain.join('.');

    if (base.includes('localhost:') || ipDomain) {
        base = 'waipu-dev.net';
    }

    return base;
};

/**
 * Clean url from given hash parameters and return the cleaned string
 * @param {string} baseUrl -> an URL string
 * @param params -> hash params which should be deleted, deletes all if left blank
 * then used to determine whether the history state should be replaced. It defaults to true.
 */
export function removeUrlHashParams(baseUrl, ...params) {
    const url = new URL(baseUrl);
    const paramsLength = params && params.length;

    let returnUrl = `${url.protocol}//${url.hostname}${url.port ? `:${url.port}` : ''}${
        url.pathname
    }${url.search}`;

    // if no params are given, remove the whole URL fragment
    if (paramsLength === 0) {
        return returnUrl;
    }

    const hashValues = getUrlHashParams(url.hash);

    for (let i = 0; i < paramsLength; i += 1) {
        delete hashValues[params[i]];
    }

    const hashString = toUrlString(hashValues);

    returnUrl += `#${hashString}`;

    return returnUrl;
}

/**
 * Replaces window.history.state with a given URL
 * @deprecated this should be moved to another module
 * @param {string} url -> an URL string
 */
export function replaceHistoryState(url) {
    window.history.replaceState({ path: url }, undefined, url);
}

/**
 * Clean url from given search params and all hash parameters replace the respective browser history entry
 * @param {string} baseUrl -> an URL string
 * @param {string[]} params -> the parameters which are to be deleted from the URL
 * @returns {string} URL
 */
export function removeUrlParams(baseUrl, ...params) {
    const url = new URL(baseUrl);
    const paramsLength = params && params.length;

    // in case no parameters are supplied, clean the URL of all parameters
    if (paramsLength === 0) {
        return `${url.protocol}//${url.host}${url.pathname}${url.hash}`;
    }

    for (let i = 0; i < paramsLength; i += 1) {
        url.searchParams.delete(params[i]);
    }

    return url.href;
}

/**
 * Initialize URLSearchParams with an object of params
 * @params {object}
 * @returns {URLSearchParams} search params
 */
export const initSearchParams = (params) => {
    const searchParams = new URLSearchParams();

    Object.keys(params).forEach((prop) => {
        searchParams.set(prop, params[prop]);
    });

    return searchParams;
};

export const getAGBUrl = (tenant = 'waipu', version) => {
    return strj(
        `https://${tenant === 'waipu' ? 'www' : `${tenant}tv`}.${getBaseUrl()}/agb`,
        version,
        '/',
    );
};

export const getDSEUrl = (tenant = 'waipu', version) => {
    return strj(
        `https://${tenant === 'waipu' ? 'www' : `${tenant}tv`}.${getBaseUrl()}/dse`,
        version,
        '/',
    );
};

/**
 * Set a new search parameter in the URL
 * @param key
 * @param value
 */

export const setUrlSearchParameter = (key, value) => {
    const params = new URLSearchParams(window.location.search);
    params.set(key, value);
    window.history.pushState({}, '', `${window.location.pathname}?${params.toString()}`);
};

/**
 *
 * @param {*} key
 */
export const removeUrlSearchParameter = (key) => {
    const params = new URLSearchParams(window.location.search);
    params.delete(key);

    const newQueryString = params.toString();
    const newUrl = newQueryString
        ? `${window.location.pathname}?${newQueryString}`
        : window.location.pathname;

    window.history.pushState({}, '', newUrl);
};
