/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { date, isNil, strj, rootPath } from '@exaring/utils';
import { isRecording } from '@exaring/utils/data/recording';
import { isTVFuse } from '@exaring/utils/data/program';
import { sourceSet, scaledIcon } from '@exaring/utils/data/channel';
import { isTTLValid } from '@exaring/utils/date';
import {
    PLAYOUT_LIVE_RECORDING,
    PLAYOUT_RECORDING,
    PLAYOUT_LIVE,
    PLAYOUT_VOD,
} from './actions/constants';
import { Routes } from './routes';

export const epgInfoSeason = (genre = '', seasonCount = 0, station = '') => {
    const detailSeparator = ': ';
    const topicSeparator = ' · ';
    let shortInfo = genre;

    const seasonSection = seasonCount ? strj('Staffeln', seasonCount, detailSeparator) : '';

    shortInfo = strj(shortInfo, seasonSection, topicSeparator);
    shortInfo = strj(shortInfo, station, topicSeparator);

    return shortInfo;
};

export const epgInfoShort = (genre = '', season = '', episode = '', station = '') => {
    const detailSeparator = ': ';
    const topicSeparator = ' · ';
    let shortInfo = genre;

    const seasonSection = season ? strj('Staffel', season, detailSeparator) : '';
    const episodeSection = episode ? strj('Folge', episode, detailSeparator) : '';

    shortInfo = strj(shortInfo, strj(seasonSection, episodeSection, ' '), topicSeparator);
    shortInfo = strj(shortInfo, station, topicSeparator);

    return shortInfo;
};

// todo add doc block add unit test
export const playoutDataGenerator = (program, recording, streamingDetails) => {
    let type;
    let startTime;
    let sharpStartTime;
    let stopTime;
    let duration;

    if (recording) {
        type = isRecording(recording.status) ? PLAYOUT_LIVE_RECORDING : PLAYOUT_RECORDING; // todo: move to store
        startTime = date(recording.startTime);
        stopTime = date(recording.stopTime);
        duration = startTime.diff(stopTime);
    } else if (program) {
        type = isTVFuse(program) ? PLAYOUT_VOD : PLAYOUT_LIVE;
        startTime = date(program.startTime);
        stopTime = date(program.stopTime);
        duration = startTime.diff(stopTime);
    }

    if (streamingDetails) {
        sharpStartTime = date(streamingDetails.sharpStartTime);
    }

    return {
        startTime,
        sharpStartTime,
        stopTime,
        duration,
        type,
    };
};

/**
 * Verify a unix-timestamp to be in range between now and -60 minutes
 * @param {number} lastPinEntry
 * @returns {boolean}
 */
export const isPinEntryTTLValid = (lastPinEntry) =>
    isTTLValid(lastPinEntry ?? 0, undefined, -60 * 60);

/**
 * @returns {boolean}
 */
export const isSafari = () => {
    const ua = window.navigator.userAgent;
    return ua.includes('Safari') && !ua.includes('Chrome');
};

/**
 * @returns {boolean}
 */
export const isEdgeBrowser = () => window.navigator.userAgent.indexOf('Edge') > -1;

export const streamProtocolBrowserDetection = () => (isSafari() ? 'hls' : 'dash');

/**
 * detects the Browser according to the given navigator(useragent)
 * @params {object}  navigator-Object from Browser
 *
 * @returns {Object}  name: Name of Browser version: BrowserVersion
 */

// @TODO: make more detailed to detect also minor versions
export function detectBrowser(_navigator) {
    const ua = _navigator.userAgent;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    let temp;

    if (/trident/i.test(M[1])) {
        temp = /\brv[ :]+(\d+)/g.exec(ua) || [];

        return {
            name: 'IE',
            version: temp[1] || '',
        };
    }
    if (M[1] === 'Chrome') {
        temp = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (!isNil(temp)) {
            return {
                name: temp[1].replace('OPR', 'Opera'),
                version: temp[2],
            };
        }
    }
    M = M[2] ? [M[1], M[2]] : [_navigator.appName, _navigator.appVersion, '-?'];

    temp = ua.match(/version\/(\d+)/i);
    if (!isNil(temp)) {
        M.splice(1, 1, temp[1]);
    }
    return {
        name: M[0],
        version: M[1],
    };
}

/**
 * detects the OS according to the given useragent and platform
 * @returns {String}  name: Name of Browser version: BrowserVersion
 */

export function detectOS(userAgent, platform) {
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    if (macosPlatforms.indexOf(platform) !== -1) {
        return 'Mac OS';
    }
    if (iosPlatforms.indexOf(platform) !== -1) {
        return 'iOS';
    }
    if (windowsPlatforms.indexOf(platform) !== -1) {
        return 'Windows';
    }
    if (/Android/.test(userAgent)) {
        return 'Android';
    }
    if (/Linux/.test(platform)) {
        return 'Linux';
    }

    return 'Unknown';
}

export const channelImageSrc = (channel) => {
    const width = 128;
    const height = 96;

    return {
        src: (channel && scaledIcon(channel, width, height)) || '',
        srcSet: (channel && sourceSet(channel, width * 2, height * 2)) || '',
    };
};

const isCloudinaryRessource = (url) => url.indexOf('cloudinary') >= 0;

const cloudinaryImageResourceWithDegradation = (url, width, height, autoQuality = 'good') => {
    return url.replace(
        /\/c_limit,q_auto:(.+?),w_(.+?),h_(.+?),f_(.+?)\//,
        `/c_limit,q_auto:${autoQuality},w_${width},h_${height},f_jpg/`,
    );
};

const waipuImageResourceWithParams = (url, width, height) => {
    return strj(url, `width=${width}&height=${height}`, '?');
};

export const imageResource = (url, width, height) =>
    isCloudinaryRessource(url)
        ? cloudinaryImageResourceWithDegradation(url, width, height)
        : waipuImageResourceWithParams(url, width, height);

export const assetUrl = (path) => `ui/${path}`;

export const epgV2ProgramDetailsToV1 = (programV2) => {
    const {
        id,
        stationId,
        startTime,
        stopTime,
        restrictions,
        ageRating,
        series,
        textContent: { title, descLong: description },
    } = programV2;

    return {
        id,
        title,
        description,
        channel: stationId,
        duration: 0,
        startTime,
        stopTime,
        series,
        ...restrictions, // v1 compat: recordingForbidden, pauseForbidden NOTE new added by v2: introduce instantRestartForbidden, seekingForbidden
        ...ageRating, // NOTE new added by v2 and actually needed for features
    };
};

const isPathActive = (path) => path === rootPath(window.location.pathname);

export const getCurrentScreen = () => {
    if (isPathActive(Routes.EPG_PAGE)) {
        return 'epg';
    }
    if (isPathActive(Routes.WAIPUTHEK_PAGE)) {
        return 'waiputhek';
    }
    if (isPathActive(Routes.RECORDING_PAGE)) {
        return 'recordings';
    }
    if (isPathActive('/aufnahme')) {
        return 'player_recordings';
    }
    if (isPathActive('/vod')) {
        return 'player_media_library';
    }
    return 'player_livetv';
};

export const trackingFilterValueConverter = (val) => {
    switch (val) {
        case 'all':
            return 'available';
        case 'scheduled':
            return 'planned';
        case 'new':
            return 'not_watched';
        case 'continue':
            return 'partially_watched';
        case 'seen':
            return 'watched';
        default:
            return undefined;
    }
};

export const trackingSortValueConverter = (val) => {
    switch (val) {
        case 'dateDesc':
            return 'most_recent';
        case 'dateAsc':
            return 'least_recent';
        case 'titleAsc':
            return 'title_asc';
        case 'titleDesc':
            return 'title_desc';
        case 'channelAsc':
            return 'channel_asc';
        case 'channelDesc':
            return 'channel_desc';
        case 'episodeAsc':
            return 'episode_asc';
        case 'episodeDesc':
            return 'episode_desc';
        default:
            return undefined;
    }
};
