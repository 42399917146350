/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

const strings = {
    pageTitle: 'waipu.tv - Fernsehen wie noch nie.',
    brandName: 'waipu.tv',
    euUpgradeNotAllowedText:
        'Mit Ihrem aktuellen Paket ist eine Nutzung im EU-Ausland nicht möglich.',
    upsellingTitleRecordings:
        'Mit unserem Comfort- oder Perfect Plus-Paket ganz einfach Sendungen aufnehmen.',
    upsellingContentRecordings: 'Buchen Sie jetzt und verpassen Sie nichts mehr!',
    upsellingTitle: 'Jetzt weitere exklusive Inhalte freischalten!',
    upsellingContent:
        'Im waipu.tv Perfect Plus Paket stehen Ihnen jederzeit alle Filme und Serien der waiputhek zur Verfügung.',
    upsellingButtonLabel: '1 Monat kostenlos testen',
    waiputhekLabel: 'waiputhek',
} as const;

export default strings;
