import { h } from 'preact';
import {
    TimelineWrapper,
    TimelineBox,
    Timeline as PlanbyTimeline,
    TimelineDivider,
    TimelineDividers,
    useTimeline,
} from '@nessprim/planby';
import { timeStr, now } from '@exaring/utils/date';
import { styled } from '@exaring/ui/components-styled/theme';

const StyledTimelineDividers = styled(TimelineDividers, {
    paddingBottom: '2px !important',
    '& div:nth-child(even)': {
        opacity: 0,
    },
});

const TimelineTime = styled('div', {
    position: 'absolute',
    top: '18px',
    left: '-18px',
});

const StyledTimelineWrapper = styled(TimelineWrapper, {
    zIndex: '11 !important',
});

const formatTime = (hours: number) =>
    now()
        .startOf('day')
        .add(hours * 60, 'm')
        .format((t: any) => timeStr(t.hour, t.minute, -1, ''));

const renderDividers = (dividers: any, key: number, hourWidth: number) =>
    dividers.map((_: any, i: number) => {
        const elementKey = `divider-${key}-${i}`;

        return (
            <TimelineDivider
                left={0}
                key={elementKey}
                width={hourWidth}
                style={{
                    width: '2px',
                    borderRadius: '1px',
                }}
            />
        );
    });

export const Timeline = (props: PlanbyTimeline) => {
    const {
        isToday,
        isCurrentTime,
        timelineHeight,
        hourWidth,
        offsetStartHoursRange,
        isTimelineVisible,
    } = props;
    const { time, getTimelineProps, dividers } = useTimeline(props);

    const renderTime = (_: string | number, index: number) => {
        const position = { left: hourWidth * index, width: hourWidth };
        const isVisible = isTimelineVisible(position);

        if (!isVisible) {
            return undefined;
        }

        return (
            <TimelineBox
                key={index}
                isToday={isToday}
                isCurrentTime={isCurrentTime}
                timelineHeight={timelineHeight}
                {...position}
                style={{
                    opacity: '0.3',
                    fontWeight: 600,
                }}
            >
                <TimelineTime>{formatTime(index + offsetStartHoursRange)}</TimelineTime>
                <TimelineTime style={{ left: `${hourWidth / 2 - 14}px` }}>
                    {formatTime(index + offsetStartHoursRange + 0.5)}
                </TimelineTime>
                <StyledTimelineDividers>
                    {renderDividers(dividers, index + offsetStartHoursRange, hourWidth)}
                </StyledTimelineDividers>
            </TimelineBox>
        );
    };

    return (
        <StyledTimelineWrapper {...getTimelineProps()}>
            {time.map((timeslot, index) => renderTime(timeslot, index))}
        </StyledTimelineWrapper>
    );
};
