/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

const GET = 'GET';
const POST = 'POST';
const PATCH = 'PATCH';
const PUT = 'PUT';
const DELETE = 'DELETE';

/**
 * @typedef {GET | POST | PATCH | PUT | DELETE} HttpMethod
 * @type {HttpMethod[]}
 * */
export const httpMethods = [GET, POST, PUT, DELETE, PATCH];

/**
 * Create mutable request, as native Requests not mutable after initialization.
 *
 * @typedef {RequestInit & { url: string }} RequestConfig
 * @param  {HttpMethod} requestMethod
 * @param  {string} url
 * @param  {HeadersInit} headers
 * @param  {any} body
 * @return {RequestConfig}
 */
const request = (requestMethod, url, headers, body) => {
    let method = /** @type {HttpMethod} */ (`${requestMethod}`.toUpperCase());
    method = httpMethods.includes(method) ? method : GET;
    return { url, method, headers: new Headers(headers), body };
};

/**
 * @param {RequestConfig} req
 * @return {Request}
 */
export const requestToNative = (req) => {
    const { url } = req;
    delete req.url;
    return new Request(url, req);
};

/**
 * @param  {string} url
 * @param  {HeadersInit} [headers]
 * @param  {any} [body]
 * @return {RequestConfig}
 */
export const get = (url, headers, body) => {
    return request(GET, url, headers, body);
};

/**
 * @param  {string} url
 * @param  {HeadersInit} [headers]
 * @param  {any} [body]
 * @return {RequestConfig}
 */
export const post = (url, headers, body) => {
    return request(POST, url, headers, body);
};

/**
 * @param  {string} url
 * @param  {HeadersInit} [headers]
 * @param  {any} [body]
 * @return {RequestConfig}
 */
export const put = (url, headers, body) => {
    return request(PUT, url, headers, body);
};

/**
 * @param  {string} url
 * @param  {HeadersInit} [headers]
 * @param  {any} [body]
 * @return {RequestConfig}
 */
export const patch = (url, headers, body) => {
    return request(PATCH, url, headers, body);
};

/**
 * @param  {string} url
 * @param  {HeadersInit} [headers]
 * @param  {any} [body]
 * @return {RequestConfig}
 */
export const remove = (url, headers, body) => {
    return request(DELETE, url, headers, body);
};

export default request;
