import { getBaseUrl } from '@exaring/utils';
import { SortItemProps } from './SortItem.types';

export const getLogo = (channel: { id: string }) => {
    return `https://images.${getBaseUrl()}/api/channels/${channel.id.toUpperCase()}/logosd.png?width=192&height=144`;
};

type DropLine = 'left' | 'right' | 'top' | 'bottom' | undefined;

export const getDropLine = (
    view: SortItemProps['view'],
    dropLine: SortItemProps['showDropLine'],
): DropLine => {
    if (dropLine === undefined) {
        return undefined;
    }

    if (view === 'grid') {
        return dropLine === 'before' ? 'left' : 'right';
    }

    return dropLine === 'before' ? 'top' : 'bottom';
};
