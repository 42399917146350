/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';
import { indexInArray, findPos } from '@exaring/utils';
import { BaseUi } from '../base-ui';

const validiateBoundaries = (max, offset, target) => {
    return Math.max(0, Math.min(target - offset, max + offset * 2));
};

class Slider extends BaseUi {
    constructor(props) {
        super(props);

        this.onStatusChange = this.onStatusChange.bind(this);
        this.renderRange = this.renderRange.bind(this);
        this.composePositionStyle = this.composePositionStyle.bind(this);
        this.determinePositionValue = this.determinePositionValue.bind(this);
        this.changeValue = this.changeValue.bind(this);

        this.finalStatusTimeout = null;
        this.hoverPos = { x: 0, y: 0 };
        this.state = {
            scrubbing: false,
        };
    }

    render() {
        const sliderClasses = `slider slider--${this.props.additionalClassName} slider--${
            this.props.vertical ? 'vertical' : 'horizontal'
        }`;
        const handleIndex = indexInArray(this.props.ranges, 'name', this.props.handle);

        let handleValue = 0;

        if (this.state.scrubbing) {
            handleValue = this.determinePositionValue(this.hoverPos);
        } else if (handleIndex >= 0) {
            handleValue = this.props.ranges[handleIndex].value;
        }

        return (
            <div className={sliderClasses}>
                <div className="slider__ranges">
                    {this.props.handle && (
                        <div
                            className="slider__handle"
                            style={this.composePositionStyle(handleValue, true)}
                        />
                    )}
                    {this.props.ranges.map((range) => {
                        return this.renderRange(range);
                    })}
                </div>
            </div>
        );
    }

    renderRange(range) {
        const rangeClasses = `slider__range slider__range--${range.name}`;
        return (
            <div className={rangeClasses} style={this.composePositionStyle(range.value, false)} />
        );
    }

    get touchEventSelectorMap() {
        return [
            this.touchEventSelector(
                `.slider--${this.props.additionalClassName}`,
                `.slider--${this.props.additionalClassName}`,
                this.touchEventMap,
            ),
        ];
    }

    get touchEventMap() {
        return {
            pan: this.onStatusChange,
            tap: this.onStatusChange,
        };
    }

    onStatusChange(_e) {
        const e = _e.detail.events[0];
        let changeDelay = 0;

        this.hoverPos = { x: e.pageX, y: e.pageY };

        if (e.type === 'move') {
            changeDelay = 100;
            clearTimeout(this.finalStatusTimeout);
            this.setState({ scrubbing: true });
        }

        const volPercentage = this.determinePositionValue(this.hoverPos);

        this.finalStatusTimeout = setTimeout(() => {
            this.changeValue(volPercentage);
        }, changeDelay);
    }

    composePositionStyle(val, isHandle) {
        let direction;

        if (isHandle === true) {
            direction = this.props.vertical ? 'bottom' : 'left';
        } else {
            direction = this.props.vertical ? 'height' : 'width';
        }

        return `${direction}: ${val}%`;
    }

    determinePositionValue(position) {
        const containerEl = document.querySelector(`.slider--${this.props.additionalClassName}`);
        const el = document.querySelector(
            `.slider--${this.props.additionalClassName} .slider__ranges`,
        );

        const containerPosition = findPos(containerEl);

        const maxRange = this.props.vertical ? el.offsetHeight : el.offsetWidth;
        const targetPx = this.props.vertical
            ? maxRange - (position.y - containerPosition.top)
            : position.x - containerPosition.left;

        const offset = validiateBoundaries(maxRange, 0, targetPx);

        return (100 / maxRange) * offset;
    }

    changeValue(percentage) {
        this.props.onStatusChange(percentage);
        this.setState({ scrubbing: false });
    }
}

// Slider.propTypes = {
//     ranges: React.PropTypes.array,
//     handle?: React.PropTypes.string,
//     additionalClassName: React.PropTypes.string,
//     onStatusChange: React.PropTypes.func,
//     vertical: React.PropTypes.bool
// };

export default Slider;
