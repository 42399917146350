/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { FunctionComponent, h } from 'preact';
import { Exception } from '../../Modal';
import { StyledComponentBaseProps } from '../../theme';

type EpgContentNotFound = StyledComponentBaseProps & {
    onClick: () => void;
};

export const EpgContentNotFound: FunctionComponent<EpgContentNotFound> = ({ onClick }) => {
    return (
        <Exception
            title="Der Inhalt konnte nicht geladen werden."
            subtitle="Zu dieser Sendung liegen uns leider keine Details vor."
            onClick={onClick}
        />
    );
};

type WaiputhekEpgContentNotFound = Omit<EpgContentNotFound, 'title' | 'subtitle'>;

export const WaiputhekEpgContentNotFound: FunctionComponent<WaiputhekEpgContentNotFound> = ({
    onClick,
}) => {
    return (
        <Exception
            title="Die Inhalte der waiputhek konnten nicht geladen werden."
            subtitle="Zu dieser Sendung liegen uns leider keine Details vor. Bitte gehen Sie zurück zur Mediathek."
            onClick={onClick}
        />
    );
};
