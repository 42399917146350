import { SupNewTVResponseV1, SupRegularResponseV1 } from './sup';
import { DeviceTokenV1 } from './vnd.dc.device-token-v1+json';
import { RecordingV2, RecordingStartV2, RecordingSummaryV2 } from './recording';
import { ProgramV1, ProgramV2 } from './program';

const baseName = 'application/vnd';
const waipu = 'waipu';
const streamUrlProvider = 'streamurlprovider';

const types = {
    [`${baseName}.${streamUrlProvider}.newtv-stream-url-v1+json`]: SupNewTVResponseV1,
    [`${baseName}.${streamUrlProvider}.traditional-stream-url-v1+json`]: SupRegularResponseV1,
    [`${baseName}.dc.device-token-v1+json`]: DeviceTokenV1,
    [`${baseName}.${waipu}.recordings-v2+json`]: RecordingV2,
    [`${baseName}.${waipu}.epg-program-v1+json`]: ProgramV1,
    [`${baseName}.${waipu}.epg-program-v2+json`]: ProgramV2,
    [`${baseName}.${waipu}.start-recording-v2+json`]: RecordingStartV2,
    [`${baseName}.${waipu}.recording-summary-v2+json`]: RecordingSummaryV2,
};

export default types;
