import { isEmptyString } from '@exaring/utils';
import { useUiStore } from '../state/Store';
import { routeTo } from '../routes';

export const useGoBack = () => {
    const { previousRoute } = useUiStore();
    return () => {
        if (previousRoute && !isEmptyString(previousRoute)) {
            routeTo(previousRoute);
        } else {
            routeTo('/', false);
        }
    };
};
