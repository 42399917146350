/* eslint-disable import/no-unused-modules */

/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

// type Props = {
//     // from parent
//     ranges: RecordingModel, // TODO double check if using RecordingModel as a type really works here
//     handle: string,
//     previewImage: object,
//     onStatusChange: Function,
//     additionalClassName: string,
//     vertical: bool
// }

import { h, createRef } from 'preact';
import { BaseUi, Slider } from '@exaring/ui';
import {
    classnames,
    events,
    findPos,
    rAF,
    conditionalCb,
    date,
    logErrorWithDescription,
    CTimeDate,
    diffInSeconds,
    unix,
} from '@exaring/utils';
import constants from '../../constants';

/**
 * Returns the index of a thumbnail at a specific position
 * @param thumbnailInterval in seconds
 * @param thumbnailStartTime given as a ctime convertible date format (BS sends ISO8610 time strings)
 * @param recordingStartTime given as a ctime convertible date format
 * @param recordingSharpStartTime given as a ctime convertible date format
 * @param position in seconds
 */
export const getThumbnailIndex = (
    thumbnailInterval: number,
    thumbnailStartTime: CTimeDate,
    recordingStartTime?: CTimeDate,
    recordingSharpStartTime?: CTimeDate,
    position = 0,
) => {
    const thumbnailDateTime = date(thumbnailStartTime);
    const startDateTime = date(recordingSharpStartTime || recordingStartTime);

    if (unix(startDateTime) < unix(thumbnailDateTime)) {
        return 0;
    }

    const secondsBeforeStart = diffInSeconds(startDateTime, thumbnailDateTime);
    const thumbnailIndexesToSkip = Math.floor(secondsBeforeStart / thumbnailInterval) + 1;
    return Math.floor(position / thumbnailInterval) + thumbnailIndexesToSkip;
};

const formatter = new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
});

const formatTime = (seconds: number) =>
    `${Math.floor(seconds / 60)
        .toString()
        .padStart(2, '0')}:${Math.floor(seconds % 60)
        .toString()
        .padStart(2, '0')}`;

const formatDateTime = (startTime: string, seconds: number) => {
    const d = new Date(startTime);
    d.setSeconds(d.getSeconds() + seconds);
    return formatter.format(d);
};

type Props = {
    progress?: number;
    duration?: number;
    loaded?: number;
    seekingForbidden?: boolean;
    isDisabled?: boolean;
    fetchThumbnail?: boolean;
    showTooltip?: boolean;
    previewImage?: {
        numberOfFiles: number;
        width: number;
        height: number;
        intervalInMilliseconds: number;
        recordingStartTime: string;
        recordingStopTime: string;
        blob: Blob;
        thumbnails: HTMLImageElement;
    };
    playoutData?: {
        startTime: CTimeDate;
        sharpStartTime: CTimeDate;
        stopTime: CTimeDate;
        duration: number;
        type: number;
    };
    onProgressClick?: (progress: number) => void;
};

type State = {
    showTooltip: boolean;
    tooltipPosition: number;
    progressBarWidth: number;
    isTouchDevice: boolean;
};

class ProgressBar extends BaseUi<Props, State> {
    canvas = createRef();

    constructor(props: Props) {
        super(props);

        this.state = {
            showTooltip: false,
            tooltipPosition: 0,
            progressBarWidth: 0,
            isTouchDevice: Modernizr.touchevents,
        };
    }

    override componentDidMount() {
        this.registerResizeControls();
        this.onResize();
    }

    registerResizeControls() {
        events.addEventsTo(window, this.resizeEventMap);
    }

    removeResizeControls() {
        events.removeEventsFrom(window, this.resizeEventMap);
    }

    get resizeEventMap() {
        return {
            resize: this.onResize,
        };
    }

    // Handling when window is Resized
    onResize = () => {
        rAF(() => {
            const containerEl = document.querySelector('.osd__progressBar') as HTMLDivElement;
            this.setState({
                progressBarWidth: (containerEl && containerEl.offsetWidth) || 0,
            });
        });
    };

    onUpdateCanvas = (
        thumb: any,
        x: number,
        y: number,
        cropW: number,
        cropH: number,
        width: number,
        height: number,
    ) => {
        if (!this.canvas.current) {
            return;
        }
        const context = this.canvas.current.getContext('2d');
        context.imageSmoothingEnabled = false;
        context.drawImage(thumb, x, y, cropW, cropH, 0, 0, width, height);
    };

    onMouseEndAction = (e: TouchEvent) => {
        this.updatePosition(e);
        this.setTooltipVisibility(e);
    };

    updatePosition = (e: TouchEvent) => {
        e.preventDefault();
        let pageX: number;
        let updateStream = false;
        if (e.type === 'touchmove') {
            pageX = e.targetTouches[0]?.pageX ?? 0;
        } else if (e.type === 'touchend') {
            pageX = e.changedTouches[0]?.pageX ?? 0;
            updateStream = true;
        } else {
            pageX = (e as unknown as MouseEvent).pageX;
        }

        if (this.state.showTooltip) {
            this.setTooltipPosition(pageX);

            if (updateStream) {
                const newValue = this.calculatePosition(pageX);
                if (this.props.onProgressClick) {
                    this.props.onProgressClick(newValue);
                }
            }
        }
    };

    calculateTime = () => {
        try {
            const { duration = 0, playoutData } = this.props;
            const seconds = Math.round((this.state.tooltipPosition * duration) / 100);
            return playoutData?.type === 1
                ? formatDateTime(playoutData.startTime as string, seconds) // is live, then return clock time
                : formatTime(seconds); // else return minutes and seconds
        } catch (e) {
            logErrorWithDescription(e as Error, 'while trying to calculate time in ProgressBar');

            // return empty string if hoverPositionTime can not be calculated
            return '';
        }
    };

    calculatePosition = (_mousePosX = 0) => {
        const containerEl = document.querySelector('.osd__progressBar');
        const positionContainer = (containerEl && findPos(containerEl)) || { left: 0, top: 0 };
        const hoverPosition = _mousePosX - positionContainer.left;

        return (100 / this.state.progressBarWidth) * hoverPosition;
    };

    getTooltipPosition = (height: number) => {
        const { progressBarWidth, tooltipPosition } = this.state;

        const toolTip = document.querySelector('.osd__progressBar__tooltip') as HTMLDivElement;
        const toolTipWidth = toolTip?.offsetWidth || 0;
        let toolTipPosition = (progressBarWidth / 100) * tooltipPosition;

        const lowerThreshold = toolTipWidth / 2;
        const upperThreshold = progressBarWidth - toolTipWidth / 2;

        if (toolTipPosition > upperThreshold) {
            toolTipPosition = upperThreshold;
        } else if (toolTipPosition < lowerThreshold) {
            toolTipPosition = lowerThreshold;
        }

        const tooltipPositionTop = height + constants.PREVIEW_THUMBNAIL_PADDING;

        return `left: ${toolTipPosition}px; top: -${tooltipPositionTop}px`;
    };

    setTooltipPosition = (positionX: number) => {
        this.setState({
            tooltipPosition: this.calculatePosition(positionX),
        });
    };

    setTooltipVisibility = (e: TouchEvent) => {
        e.preventDefault();
        this.setState({
            showTooltip:
                (e.type === 'mouseenter' || e.type === 'touchstart') &&
                !this.props.seekingForbidden,
        });
    };

    renderTooltip = () => {
        const { THUMBNAIL_WIDTH, THUMBNAIL_HEIGHT, PREVIEW_THUMBNAIL_SCALE_FACTOR } = constants;
        const { showTooltip } = this.state;
        const { previewImage, fetchThumbnail } = this.props;
        const thumbnails = previewImage?.thumbnails;
        const width = THUMBNAIL_WIDTH * PREVIEW_THUMBNAIL_SCALE_FACTOR;
        const height = THUMBNAIL_HEIGHT * PREVIEW_THUMBNAIL_SCALE_FACTOR;

        const validThumbnail = fetchThumbnail || !!thumbnails;

        const previewThumbnail = validThumbnail && this.renderPreviewThumbnail(width, height);
        const toolTipClassName = classnames(
            'osd__progressBar__tooltip',
            previewThumbnail && 'osd__progressBar__tooltip--withThumbnail',
            !showTooltip && 'osd__progressBar__tooltip--hidden',
        );

        const tooltipPositionStyle = this.getTooltipPosition(validThumbnail ? height : -6);
        const thumbnailSpinner = classnames('spinner', fetchThumbnail && 'spinner--active');

        return (
            <div className={toolTipClassName} style={tooltipPositionStyle}>
                {previewThumbnail}
                <span className={thumbnailSpinner} />
                <span className="osd__progressBar__tooltip__time">{this.calculateTime()}</span>
            </div>
        );
    };

    renderPreviewThumbnail = (width: number, height: number) => {
        let destWidth = width;
        let destHeight = height;

        if (this.props.previewImage?.thumbnails) {
            const { previewImage, duration = 1, playoutData } = this.props;
            const { tooltipPosition } = this.state;
            const positionInSeconds = (tooltipPosition / 100) * duration;

            try {
                const {
                    intervalInMilliseconds,
                    recordingStartTime: thumbnailStartTime,
                    height: thumbnailHeight,
                    width: thumbnailWidth,
                    thumbnails,
                } = previewImage;

                const thumbnailIndex = getThumbnailIndex(
                    intervalInMilliseconds / 1000,
                    date(thumbnailStartTime),
                    playoutData?.startTime,
                    playoutData?.sharpStartTime,
                    positionInSeconds,
                );

                // get thumbnail position in preview image (x, y)
                const columns = thumbnails.width / thumbnailWidth;
                const imageRow = Math.floor(thumbnailIndex / columns) || 0;
                const imageColumn = thumbnailIndex % columns || 0;
                const x = imageColumn * thumbnailWidth;
                const y = imageRow * thumbnailHeight;

                destWidth = thumbnailWidth * constants.PREVIEW_THUMBNAIL_SCALE_FACTOR;
                destHeight = thumbnailHeight * constants.PREVIEW_THUMBNAIL_SCALE_FACTOR;

                this.onUpdateCanvas(
                    thumbnails,
                    x,
                    y,
                    thumbnailWidth,
                    thumbnailHeight,
                    destWidth,
                    destHeight,
                );

                // use this for debugging purposes of image rendering and
                // manipulating to see whole image fetched from backend:
                // this.onUpdateCanvas(thumbnails, 0, 0, 65280, 1080, destWidth, destHeight);
            } catch (e) {
                // Fetching of thumbnail index failed, don't render thumbnails.
                // console.error(e);
            }
        }

        return (
            <canvas
                ref={this.canvas}
                width={destWidth}
                height={destHeight}
                className="osd__progressBar__tooltip__thumbnail"
            />
        );
    };

    render() {
        const {
            loaded,
            progress,
            seekingForbidden,
            isDisabled,
            onProgressClick = () => {},
        } = this.props;
        const { showTooltip, tooltipPosition, isTouchDevice } = this.state;
        const ranges = [
            { name: 'loaded', value: loaded || 0 },
            { name: 'hover', value: showTooltip ? tooltipPosition : 0 },
            { name: 'played', value: progress },
        ];

        const progressBarClasses = classnames(
            'osd__progressBar',
            showTooltip && 'osd__progressBar--hover',
            isTouchDevice && !seekingForbidden && 'osd__progressBar--touch',
        );

        const setTooltipVisibility = conditionalCb(!isDisabled, this.setTooltipVisibility);
        const updatePosition = conditionalCb(!isDisabled, this.updatePosition);
        const mouseEnd = conditionalCb(!isDisabled, this.onMouseEndAction);

        return (
            <div
                className={progressBarClasses}
                onMouseEnter={setTooltipVisibility}
                onMouseLeave={setTooltipVisibility}
                onTouchStart={setTooltipVisibility}
                onTouchEnd={mouseEnd}
                onTouchMove={updatePosition}
                onMouseMove={updatePosition}
            >
                {showTooltip && this.renderTooltip()}
                <Slider
                    ranges={ranges}
                    handle={!showTooltip ? 'played' : 'hover'}
                    onStatusChange={onProgressClick}
                    additionalClassName="progress"
                    vertical={false}
                />
            </div>
        );
    }
}

export default ProgressBar;
