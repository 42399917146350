/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

/* global Slip */

import { h, Component } from 'preact';
import 'slipjs';

import { classnames } from '@exaring/utils';
import DraggableChannelListItem from './draggable-channel-list-item';

const imageWidth = 68;
const imageHeight = 54;

export default class DraggableChannelList extends Component {
    onScrolling = false;

    componentDidMount() {
        this.slip = new Slip(this.listDomEl);
        this.mountSortingListener();
    }

    componentWillUnmount() {
        this.unmountSortingListener();
        this.slip.detach();
        this.slip = null;
    }

    touchMoveHandler = () => {
        this.onScrolling = true;
    };

    touchEndHandler = () => {
        if (this.scrollRollbackTimeout) {
            clearTimeout(this.scrollRollbackTimeout);
        }

        this.scrollRollbackTimeout = setTimeout(() => {
            this.onScrolling = false;
        }, 550); // wait 550ms to finish webview rollback animation after scrolling
    };

    touchCancelHandler = () => {
        this.onScrolling = false;
    };

    slipBeforeSwipeHandler = (event) => {
        event.preventDefault();
    };

    slipBeforeReorderHandler = (event) => {
        if (this.onScrolling) {
            event.preventDefault();
        }
    };

    slipBeforeWaitHandler = (event) => {
        if (event.target.classList.contains('icon--channel-drag')) {
            event.preventDefault();
        }
    };

    slipReorderHandler = (event) => {
        const { onSendToIndexAction } = this.props;
        event.target.parentNode.insertBefore(event.target, event.detail.insertBefore);

        onSendToIndexAction(event.detail.originalIndex, event.detail.spliceIndex);
    };

    mountSortingListener() {
        this.listDomEl.addEventListener('slip:beforereorder', this.slipBeforeReorderHandler);
        this.listDomEl.addEventListener('slip:beforeswipe', this.slipBeforeSwipeHandler);
        this.listDomEl.addEventListener('slip:beforewait', this.slipBeforeWaitHandler);
        this.listDomEl.addEventListener('slip:reorder', this.slipReorderHandler);

        this.listDomEl.addEventListener('touchmove', this.touchMoveHandler);
        this.listDomEl.addEventListener('touchend', this.touchEndHandler);
        this.listDomEl.addEventListener('touchcancel', this.touchCancelHandler);
    }

    unmountSortingListener() {
        this.listDomEl.removeEventListener('slip:beforereorder', this.slipBeforeReorderHandler);
        this.listDomEl.removeEventListener('slip:beforeswipe', this.slipBeforeSwipeHandler);
        this.listDomEl.removeEventListener('slip:beforewait', this.slipBeforeWaitHandler);
        this.listDomEl.removeEventListener('slip:reorder', this.slipReorderHandler);

        this.listDomEl.removeEventListener('touchmove', this.touchMoveHandler);
        this.listDomEl.removeEventListener('touchend', this.touchEndHandler);
        this.listDomEl.removeEventListener('touchcancel', this.touchCancelHandler);
    }

    render({ channelsAll, onToggleFavoriteAction, onRemoveAction }) {
        const list = [];

        channelsAll.forEach(
            (
                item, // immutable compat
            ) =>
                list.push(
                    <DraggableChannelListItem
                        key={`drag_${item.id}`}
                        id={item.id}
                        title={item.displayName}
                        faved={item.faved}
                        iconUrl={item.getScaledIcon(imageWidth, imageHeight)}
                        sourceSet={item.getSourceSet(imageWidth, imageHeight)}
                        hidden={item.hidden}
                        onToggleFavoriteAction={onToggleFavoriteAction}
                        onRemoveAction={onRemoveAction}
                    />,
                ),
        );

        return (
            <div
                className={classnames('channel-list', 'anim--highlight')}
                ref={(_list) => {
                    this.listDomEl = _list;
                }}
            >
                {list}
            </div>
        );
    }
}

/*
DraggableChannelList.propTypes = {
    channelsAll: React.PropTypes.array,
    channelsVisible: React.PropTypes.array,
    onToggleFavoriteAction: React.PropTypes.func,
    onSendToIndexAction: React.PropTypes.func,
    onRemoveAction: React.PropTypes.func
} */
