import { h, FunctionComponent } from 'preact';
import { styled } from '../../theme';

import { Gradient } from './GradientStyles';

export const ModalLayout = styled('div', {
    textAlign: 'center',
    maxWidth: '660px',
    padding: `$2`,
    margin: 'auto',
});

export const GradientLayer = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',

    ...Gradient,
});

export const CenteredLayout: FunctionComponent = ({ children }) => {
    return (
        <GradientLayer>
            <ModalLayout>{children}</ModalLayout>
        </GradientLayer>
    );
};
