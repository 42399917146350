import { styled } from '@exaring/ui/components-styled/theme';
import { ImageWithFallback } from '@exaring/ui/components-styled/Tile/parts/ImageWithFallback';

export const StyledPreviewContainer = styled('div', {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: '24px',
    columnGap: '$2',
});

export const PreviewImgContainer = styled('div', {
    position: 'relative',
    borderRadius: '$1',
});

export const PreviewImg = styled(ImageWithFallback, {
    width: '203px',
    objectFit: 'contain',
    borderRadius: '$1',
    aspectRatio: 16 / 9,
    backgroundColor: '$anthracite',

    '@sm': {
        width: '211px',
    },
    '@md-width-and-md-height': {
        width: '425px',
    },
    '@lg-width-and-md-height': {
        width: '434px',
    },
});

export const BadgeWrapper = styled('div', {
    display: 'flex',
    alignItems: 'center',
    opacity: 0.3,
    marginLeft: '10px',

    variants: {
        big: {
            true: {
                transform: 'scale(1.3) translateY(1px)',
            },
        },
    },
});

export const Description = styled('div', {
    display: 'none',
    maxWidth: 'calc(100vw - 360px)',
    height: '24px',
    margin: '10px 0 20px',
    fontSize: '20px',
    fontWeight: '$1',
    lineHeight: 1.2,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '@sm': {
        width: 'calc(100vw - 370px)',
    },
    '@md-width-and-md-height': {
        width: 'calc(100vw - 600px)',
        display: 'block',
    },
});

export const TextWrapper = styled('div', {
    flex: 1,
});
