/* eslint-disable react/jsx-props-no-spreading */

/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { cloneElement, ComponentChildren, FunctionComponent, h } from 'preact';
import { forwardRef } from 'preact/compat';
import { CSS, VariantProps } from '@stitches/react';

import PauseIcon from '@exaring/assets/icons/pause.svg';
import PlayIcon from '@exaring/assets/icons/play.svg';
import CloseIcon from '@exaring/assets/icons/close.svg';
import ArrowBackIcon from '@exaring/assets/icons/arrow-back.svg';
import HighlightsIcon from '@exaring/assets/icons/highlights.svg';
import HighlightsO2Icon from '@exaring/assets/icons/highlights-o2.svg';
import { Constants } from '../../constants-env';
import { styled } from '../theme';
import { Typography } from '../Typography';
import { RECORD_ICON_MAP } from './recordIconMap';

const Text = styled(Typography, {
    fontSize: 16,
    fontWeight: '$2',
    lineHeight: 1.5,
    color: '$white',
});

const Root = styled('button', {
    cursor: 'pointer',
    display: 'flex',
    columnGap: '6px',
    alignItems: 'center',
    justifyContent: 'center',
    variants: {
        layout: {
            default: {
                backgroundColor: '$dark',
                borderRadius: 5,
                '&:hover': {
                    backgroundColor: '$charcoalGrey',
                },
            },
            minimal: {},
        },
        spacing: {
            default: {
                height: 60,
                padding: '8px',
            },
            tiny: {
                height: 32,
                padding: 0,
                backgroundColor: 'initial',
            },
            minimal: {},
        },
        size: {
            auto: {},
            parent: {
                width: '100%',
            },
            big: {
                width: 236, // design wrong, biggest text ist "Aufnahme verwerfen"
            },
            responsive: {
                width: '100%',
                '@sm': {
                    width: 236,
                },
            },
        },
        text: {
            default: {},
            tiny: {
                [`& ${Text}`]: {
                    fontSize: '$2',
                    fontWeight: '$1',
                },
            },
            onlyOnDesktop: {
                [`& ${Text}`]: {
                    display: 'none',

                    '@sm': {
                        display: 'inherit',
                    },
                },
            },
        },
        disabled: {
            false: {},
            true: {
                cursor: 'default',
                opacity: '0.4',
                '&:hover': {
                    backgroundColor: '$dark',
                },
            },
        },
    },
});
type RootVariants = VariantProps<typeof Root>;

type IconButtonProps = {
    icon?: JSX.Element;
    title?: string;
    onClick?: (e: any) => void;
    iconSize?: number;
    layout?: RootVariants['layout'];
    spacing?: RootVariants['spacing'];
    size?: RootVariants['size'];
    text?: RootVariants['text'];
    disabled?: RootVariants['disabled'];
    css?: CSS;
    id?: string;
    className?: string;
    children?: ComponentChildren;
};

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>( // why to use forwardRef https://github.com/preactjs/preact/issues/1521#issuecomment-1139131605
    (
        {
            title = '',
            icon: iconOrig,
            layout = 'default',
            spacing = 'default',
            text = 'default',
            size = 'auto',
            iconSize: iconWidth,
            disabled = false,
            children,
            id,
            ...otherProps
        },
        ref,
    ) => {
        const iconSize = iconWidth ?? (layout === 'default' && spacing === 'default' ? 44 : 32);
        const icon =
            iconOrig !== undefined
                ? cloneElement(iconOrig, { height: iconSize, width: iconSize })
                : undefined;

        return (
            <Root
                title={title}
                layout={layout}
                spacing={spacing}
                size={size}
                text={text}
                disabled={disabled}
                ref={ref}
                key={id}
                {...otherProps}
            >
                {icon}
                {layout === 'default' && children && (
                    <Text nowrap nogutter>
                        {children}
                    </Text>
                )}
            </Root>
        );
    },
);

export type ComposedIconBottonProps = Omit<IconButtonProps, 'icon'>;
export type ComposedIconButtonWithLabelProps = Omit<IconButtonProps, 'children'>;

export const PlayButton: FunctionComponent<
    ComposedIconButtonWithLabelProps & {
        isPlaying?: boolean;
        playTitle?: string;
        pauseTitle?: string;
    }
> = ({ title = 'Jetzt abspielen', isPlaying = false, playTitle, pauseTitle, ...props }) => {
    const icon = isPlaying ? <PauseIcon /> : <PlayIcon />;
    const playPauseTitle = isPlaying ? pauseTitle : playTitle;
    return (
        <IconButton icon={icon} title={playPauseTitle ?? title} {...props} iconSize={44}>
            {title}
        </IconButton>
    );
};

export const MediathekButton: FunctionComponent<
    ComposedIconBottonProps & {
        showLabel?: boolean;
        text?: 'tiny' | 'onlyOnDesktop';
        iconSize?: number;
    }
> = ({ showLabel, ...props }) => (
    <IconButton
        icon={Constants.TENANT === 'o2' ? <HighlightsO2Icon /> : <HighlightsIcon />}
        {...props}
    >
        {showLabel ? 'Mediathek' : undefined}
    </IconButton>
);

type RecordingButtonProps = ComposedIconButtonWithLabelProps & {
    label?: string;
    iconName?: keyof typeof RECORD_ICON_MAP;
};

export const RecordingButton: FunctionComponent<RecordingButtonProps> = ({
    title,
    label = '',
    iconName = 'start',
    ...otherProps
}) => (
    <IconButton icon={RECORD_ICON_MAP[iconName]} title={label ? '' : title} {...otherProps}>
        {label}
    </IconButton>
);

export const CloseButton: FunctionComponent<
    Pick<IconButtonProps, 'onClick' | 'css' | 'className'>
> = (props) => <IconButton icon={<CloseIcon />} layout="minimal" spacing="minimal" {...props} />;

export const ArrowBackButton: FunctionComponent<
    Pick<IconButtonProps, 'onClick' | 'css' | 'className' | 'iconSize'>
> = (props) => (
    <IconButton icon={<ArrowBackIcon />} layout="minimal" spacing="minimal" {...props} />
);

// TODO add Bell button
