/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';
import constants from '../constants';

const MobileBlocker = ({ brandName }) => {
    return (
        <div id="mobile-blocker">
            <div className="background-curtain">
                <div className="content">
                    <h2 className="content__header">Hallo!</h2>
                    <p className="content__subheader">{`Der Web-Player von ${brandName} ist für den Browser auf Ihrem Desktop-PC, Laptop oder Tablet optimiert!`}</p>
                </div>
            </div>
            <div className="section section--pull-up">
                <div className="section__content">
                    <div className="section__content__inner">
                        <p className="text">
                            <strong>{`Um ${brandName} auf Ihrem Smartphone zu nutzen, jetzt die App fürs Smartphone installieren, anmelden und loslegen.`}</strong>
                        </p>
                        <div className="app-store-links">
                            <a
                                href={constants.ANDROID_APP_URL}
                                className="app-store-links__link app-store-links__link--android"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {' '}
                                Jetzt bei Google Play
                            </a>
                            <a
                                href={constants.APPLE_APP_URL}
                                className="app-store-links__link app-store-links__link--ios"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {' '}
                                Laden im AppStore
                            </a>
                        </div>
                        <span className="separator">
                            <span className="separator__title">oder</span>
                            <span className="separator__line" />
                        </span>
                        <p className="text">
                            {`Falls Sie die ${brandName}-App bereits auf Ihrem Smartphone installiert haben, wählen Sie:`}
                        </p>
                        <div className="center--small-only">
                            <a className="button" href={constants.APP_UNIVERSAL_URL}>
                                App jetzt öffnen
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileBlocker;
