import { z } from 'zod';

export const jwtSchema = z.object({
    firstName: z.string().default('').catch(''),
    lastName: z.string().default('').catch(''),
    userHandle: z.string().default('').catch(''),
    sub: z.string().email().default('').catch(''),
    gender: z.string().default('').catch(''),
    f: z.array(z.string()).default([]).catch([]),
    roles: z.array(z.string()).default([]).catch([]),
    iss: z.string().url(),
    exp: z.number().default(0).catch(0),
    iat: z.number().default(0).catch(0),
    age: z.number().default(0).catch(0),
    doiStatus: z.string().default('').catch(''),
    userAssets: z.object({
        channels: z
            .object({
                SD: z.array(z.string()).default([]),
                HD: z.array(z.string()).default([]),
            })
            .default({
                SD: [],
                HD: [],
            }),
        hoursRecording: z.number().default(0),
        account: z
            .object({
                free: z.boolean().default(false),
                subscription: z.string().default(''),
            })
            .default({
                free: false,
                subscription: '',
            }),
        mobileUsage: z.boolean().default(false),
        instantRestart: z.boolean().default(false),
    }),
});

export type JWT = z.infer<typeof jwtSchema>;
