import { isObject, mapWithoutNil } from '@exaring/utils/index';
import { HighlightSection, decode as decodeHighlightSection } from './HighlightSection';

interface Highlights {
    modules: Array<HighlightSection>;
}

const isValidJson = (value: any): value is Highlights =>
    isObject(value) && Array.isArray(value.modules);

export const decode = (value: unknown): Highlights | undefined => {
    return isValidJson(value)
        ? {
              modules: mapWithoutNil(value.modules, decodeHighlightSection),
          }
        : undefined;
};
