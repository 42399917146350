import { h } from 'preact';
import { classnames } from '@exaring/utils';

// type Props = {
//     type?: number, *
//     content: string, *
//     className: string, *
//     children: any,
//     id?: string, *
//     title?: string,
//     legacy?: boolean,
// };

export default ({ content, children, className, id, title, type, legacy = true }) => {
    const HeadingLevel = `h${type || 1}`;
    const _classNames = classnames(
        className,
        legacy && type === 1 && 'content__header',
        legacy && type === 2 && 'section__header',
    );

    return (
        <HeadingLevel id={id} title={title} className={_classNames}>
            {content || children}
        </HeadingLevel>
    );
};
