import { inRange } from '@exaring/utils';

export class NetworkingError extends Error {
    customCause;

    statusCode;

    response;

    constructor(statusCode, cause, message, response) {
        super(message);
        this.name = `Networking Error(${cause})`;
        this.customCause = cause;
        this.statusCode = statusCode;
        this.response = response;
    }
}

export const createNetworkingError = (statusCode, cause, message, response) => {
    return new NetworkingError(statusCode, cause, message, response);
};

export const throwIfInErrorRange = (statusCode, message, response) => {
    if (inRange(statusCode, 400, 599, true)) {
        throw createNetworkingError(
            statusCode,
            statusCode < 500 ? 'ClientError' : 'ServerError',
            message,
            response,
        );
    }
};
