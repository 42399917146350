import { getBaseUrl } from '@exaring/utils';

/* global __CONFIG__ */

const base = getBaseUrl(window.location.href);

const constants = Object.freeze({
    WEB_CLIENT_BASE_URL: __CONFIG__.WEB_CLIENT_BASE_URL || `https://play.${base}/`,
    LOGOUT_URL: __CONFIG__.LOGOUT_URL || `https://auth.${base}/ui/logout`,
    HELP_URL: __CONFIG__.HELP_URL || 'https://hilfe.waipu.de/hc/de',
    CSC_HOME_URL: __CONFIG__.CSC_HOME_URL || `https://customer-self-care.${base}/ui/home`,
    CSC_CHECKOUT_URL:
        __CONFIG__.CSC_CHECKOUT_URL || `https://customer-self-care.${base}/ui/checkout`,
    CSC_PAYMENT_URL:
        __CONFIG__.CSC_PAYMENT_URL ||
        `https://customer-self-care.${base}/ui/account_payment_data#zahlungsdaten`,
    OFFERS_URL: __CONFIG__.OFFERS_URL || `https://www.${base}/angebote`,
    DEFAULT_AUTH_FRAME_ERROR_TIMEOUT: 15e3,
    DEFAULT_AUTH_FRAME_MAX_RETRY: 1,
});

// https://w3c.github.io/uievents/#determine-keypress-keyCode
export const keyboardKeys = Object.freeze({
    enter: 13,
    escape: 27,
    space: 32,
    left: 37,
    up: 38,
    right: 39,
    down: 40,
    s: 83,
});

export const keyboardCodes = Object.freeze({
    enter: 'Enter',
    escape: 'Escape',
    space: 'Space',
    left: 'ArrowLeft',
    up: 'ArrowUp',
    right: 'ArrowRight',
    down: 'ArrowDown',
    s: 'KeyS',
    i: 'KeyI',
    f: 'KeyF',
});

export default constants;
