import { useEffect } from 'preact/hooks';
import { Recording } from '@exaring/networking/types/Recording/Recording';
import { signal } from '@preact/signals';
import { WebClientGA, WebClientGAEvent } from '../../../web-client-ga';

type State = {
    recording: Recording;
    wholeGroup: boolean;
}[];

const selectedRecordings = signal<State>([]);

export const useRecordingSelection = (initialRecordings?: Recording[]) => {
    const initialState = (initialRecordings ?? []).map((recording) => ({
        recording,
        wholeGroup: false,
    }));

    useEffect(() => {
        selectedRecordings.value = initialState;

        return () => {
            selectedRecordings.value = [];
        };
    }, []);

    const selectRecording = (
        newRecordings: Recording | Recording[],
        selectGroup: boolean = false,
    ) => {
        let result: State = [];
        const recordingsToAdd = Array.isArray(newRecordings) ? newRecordings : [newRecordings];

        const prevSelectedRecordings = selectedRecordings.value;

        const existingIds = new Set(
            prevSelectedRecordings.map((selection) => selection.recording.id),
        );
        const uniqueRecordings: State = recordingsToAdd
            .filter((recording) => !existingIds.has(recording.id))
            .map((recording) => ({
                recording,
                wholeGroup: selectGroup,
            }));

        result = [...prevSelectedRecordings, ...uniqueRecordings];

        selectedRecordings.value = result;

        return result.map((selection) => selection.recording);
    };

    const toggleRecordingSelection = (recordingToToggle: Recording, selectGroup: boolean) => {
        const programData = {
            programId: recordingToToggle.programId,
            programTitle: recordingToToggle.title,
            channelName: recordingToToggle.stationDisplay,
        };

        const prevSelectedRecordings = selectedRecordings.value;
        if (
            prevSelectedRecordings.some(
                (selection) => selection.recording.id === recordingToToggle.id,
            )
        ) {
            WebClientGA().trackEvent({
                eventName: WebClientGAEvent.Recordings,
                eventDescription: WebClientGAEvent.UnmarkRecording,
                screenName: 'recordings',
                ...programData,
            });

            selectedRecordings.value = prevSelectedRecordings.filter(
                (selection) => selection.recording.id !== recordingToToggle.id,
            );

            return;
        }

        WebClientGA().trackEvent({
            eventName: WebClientGAEvent.Recordings,
            eventDescription: WebClientGAEvent.MarkRecording,
            screenName: 'recordings',
            ...programData,
        });

        selectedRecordings.value = [
            ...prevSelectedRecordings,
            { recording: recordingToToggle, wholeGroup: selectGroup },
        ];
    };

    const clearRecordingSelection = () => {
        selectedRecordings.value = [];
    };

    const isGroupSelected = (recordingId: string) => {
        return selectedRecordings.value.some(
            (selection) => selection.recording.id === recordingId && selection.wholeGroup,
        );
    };

    const isRecordingSelected = (recordingId: string) => {
        return selectedRecordings.value.some((selection) => selection.recording.id === recordingId);
    };

    const getSelectedRecordings = () => {
        return selectedRecordings.value.map((selection) => selection.recording);
    };

    const getSelectionState = () => {
        return selectedRecordings.value;
    };

    return {
        selectRecording,
        getSelectionState,
        getSelectedRecordings,
        toggleRecordingSelection,
        clearRecordingSelection,
        isGroupSelected,
        isRecordingSelected,
    };
};
