import { h, FunctionComponent } from 'preact';
import { createStitches } from '@stitches/react';
import { ReactNode, forwardRef } from 'preact/compat';
import { MediaQuery } from '../theme';

type SpanSize = number | 'hidden' | 'full';

const createGridTemplateColumns = (breakpoint: string, columns: number) => {
    return {
        [breakpoint]: {
            gridTemplateColumns: `repeat(${columns}, 1fr)`,
        },
    };
};

const createGridColumnEnd = (breakpoint: string, span: SpanSize | undefined) => {
    if (span === undefined) {
        return {};
    }

    if (span === 'hidden') {
        return {
            [breakpoint]: {
                display: 'none',
                gridColumnEnd: `initial`,
            },
        };
    }

    if (span === 'full') {
        return {
            [breakpoint]: {
                display: 'block',
                gridColumnStart: 1,
                gridColumnEnd: -1,
            },
        };
    }

    return {
        [breakpoint]: {
            display: 'block',
            gridColumnEnd: `span ${span}`,
        },
    };
};

const { styled: customStyled } = createStitches({
    utils: {
        columnsDefault: (value: number) => ({
            gridTemplateColumns: `repeat(${value}, 1fr)`,
        }),
        columnsXs: (value: number) => createGridTemplateColumns('@xs', value),
        columnsSm: (value: number) => createGridTemplateColumns('@sm', value),
        columnsMd: (value: number) => createGridTemplateColumns('@md', value),
        columnsLg: (value: number) => createGridTemplateColumns('@lg', value),
        columnsXl: (value: number) => createGridTemplateColumns('@xl', value),
        spanXs: (value: SpanSize) => createGridColumnEnd('@xs', value),
        spanSm: (value: SpanSize) => createGridColumnEnd('@sm', value),
        spanMd: (value: SpanSize) => createGridColumnEnd('@md', value),
        spanLg: (value: SpanSize) => createGridColumnEnd('@lg', value),
        spanXl: (value: SpanSize) => createGridColumnEnd('@xl', value),
    },
    media: {
        xs: `(min-width: ${MediaQuery.xs}px)`,
        sm: `(min-width: ${MediaQuery.sm}px)`,
        md: `(min-width: ${MediaQuery.md}px)`,
        lg: `(min-width: ${MediaQuery.lg}px)`,
        xl: `(min-width: ${MediaQuery.xl}px)`,
    },
});

const PageGridItemStyled = customStyled('div');

type PageGridItemProps = {
    xs?: SpanSize;
    sm?: SpanSize;
    md?: SpanSize;
    lg?: SpanSize;
    xl?: SpanSize;
    className?: string;
};

export const PageGridItem: FunctionComponent<PageGridItemProps> = ({
    xs = 1,
    sm,
    md,
    lg,
    xl,
    className,
    children,
}) => {
    const css = {
        spanXs: xs,
        spanSm: sm,
        spanMd: md,
        spanLg: lg,
        spanXl: xl,
    };

    return (
        <PageGridItemStyled className={className} css={css}>
            {children}
        </PageGridItemStyled>
    );
};

const PageGridStyled = customStyled('div', {
    display: 'grid',
    columnGap: '20px',
    margin: '0 62px',

    variants: {
        debug: {
            true: {
                rowGap: '5px',

                '& > div': {
                    border: 'solid 2px rgba(255, 0, 0, 0.2)',
                },
            },
        },
    },
});

type PageGridProps = {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    children: ReactNode;
    className?: string;
    debug?: boolean;
};

export const PageGrid: FunctionComponent<PageGridProps> = forwardRef<HTMLDivElement, PageGridProps>(
    ({ xs = 2, sm = 3, md = 4, lg = 6, xl = 8, children, className, debug = false }, ref) => {
        const css = {
            columnsDefault: xs,
            columnsSm: sm,
            columnsMd: md,
            columnsLg: lg,
            columnsXl: xl,
        };

        return (
            <PageGridStyled ref={ref} className={className} css={css} debug={debug}>
                {children}
            </PageGridStyled>
        );
    },
);
