import { h } from 'preact';

import {
    RecordingDiscoveryTile,
    RecordingDiscoveryMetaInfo,
} from '@exaring/ui/components-styled/Tile';
import { RecordingDiscoveryTileEnumeration } from '@exaring/ui/components-styled/Tile/RecordingDiscoveryTile/RecordingDiscoveryTile';
import { RecordingDiscoveryNumber } from '@exaring/ui/components-styled/Tile/RecordingDiscoveryTile';
import { RecordingDiscoveryContent } from '@exaring/networking/types/Recording/RecordingDiscovery';
import { formatDateTime } from '@exaring/utils/date';
import { Routes, routeTo } from '../../../routes';
import { recordingStore } from '../../../state/Store';
import { epgInfoShort } from '../../../helper';
import RecordingButton from '../../RecordingButton';
import { TimeInfo } from './TimeInfo';
import { ProgramData } from '../../RecordingButton/RecordingButton';
import { WebClientGA, WebClientGAEvent } from '../../../web-client-ga';

export const contentDiscoveryTileMapper = (
    discoveryContent: RecordingDiscoveryContent,
    idx?: number,
): JSX.Element | undefined => {
    let stationId;
    let programId = '';

    try {
        const path = new URL(discoveryContent.url).pathname.split('/');
        programId = path[Math.max(path.length - 1, 0)] || '';
        stationId = path[Math.max(path.length - 2, 0)];
    } catch (e) {
        // no data is invalid error tracking
    }

    const epgProgram = {
        id: programId,
        stationId,
        ...discoveryContent,
    } as ProgramData;
    const isRecordable = discoveryContent.recordingState !== 'recording-not-possible'; // the only state that is not handled by the recording button

    const handleDiscoveryTileClick = () => {
        WebClientGA().trackEvent({
            eventName: WebClientGAEvent.Recordings,
            eventDescription: WebClientGAEvent.SelectRecording,
            screenName: 'recordings',
            programId,
            programTitle: discoveryContent.title,
            channelName: discoveryContent.channelDisplay as string,
        });
        if (discoveryContent.locked) {
            recordingStore().setShowUpsellingModal(true);
        } else if (programId) {
            routeTo(`${Routes.RECORDING_PAGE_PROGRAM}/${programId}`);
        }
    };

    return (
        <RecordingDiscoveryTileEnumeration
            onClick={handleDiscoveryTileClick}
            css={{ cursor: 'pointer' }}
            id={`discovery-tile-${idx}`}
        >
            <RecordingDiscoveryNumber variant="h1">
                {idx !== undefined && idx >= 0 ? idx + 1 : undefined}
            </RecordingDiscoveryNumber>
            <RecordingDiscoveryTile
                title={discoveryContent.title}
                locked={discoveryContent.locked}
                genre={discoveryContent.channelDisplay ?? ''}
                imageSrc={discoveryContent.img ?? ''}
            >
                <TimeInfo>
                    {discoveryContent.startTime
                        ? formatDateTime(
                              discoveryContent.startTime,
                              !!discoveryContent.startTime && !!discoveryContent.duration,
                          )
                        : ''}
                    {discoveryContent.duration ? `${discoveryContent.duration} Min.` : ''}
                </TimeInfo>
                <RecordingDiscoveryMetaInfo>
                    {epgInfoShort(
                        discoveryContent.genre ?? undefined,
                        discoveryContent.season ? `${discoveryContent.season}` : undefined,
                        discoveryContent.episode ? `${discoveryContent.episode}` : undefined,
                    )}
                </RecordingDiscoveryMetaInfo>
                <RecordingDiscoveryMetaInfo>
                    {discoveryContent.channelDisplay}
                </RecordingDiscoveryMetaInfo>
                {isRecordable && (
                    <RecordingButton
                        css={{ marginTop: '15px', width: '100%', backgroundColor: '$dark' }}
                        showLabel
                        program={epgProgram}
                        iconSize={32}
                        text="tiny"
                        spacing="tiny"
                        shouldOpenUpPopup
                    />
                )}
            </RecordingDiscoveryTile>
        </RecordingDiscoveryTileEnumeration>
    );
};
