/**
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { useEffect, useState } from 'preact/hooks';

export const useWindowSize = (): { width: number; height: number } => {
    const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });

    useEffect(() => {
        const onResize = () => setSize({ width: window.innerWidth, height: window.innerHeight });

        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, []);

    return size;
};
