import { FunctionComponent, h } from 'preact';
import { styled } from '@exaring/ui/components-styled/theme';
import {
    date,
    diffInMinutes,
    now,
    epgOverviewDateFormat,
    dateStrNumric,
    CTime,
} from '@exaring/utils';
import { ParentalGuidance } from '@exaring/networking/types/ProgramDetails';

const StyledTime = styled('div', {
    height: '15px',
    fontSize: '$1',
    lineHeight: 1.25,
    fontWeight: '$1',
    color: '$blue',
    margin: '10px 0 10px 0',

    '@md-width-and-md-height': {
        height: '27px',
        fontSize: '$2.5',
        lineHeight: 1.69,
        margin: '20px 0 10px 0',
    },
});

const getDateString = (startDate: CTime) =>
    `${dateStrNumric(-1, startDate.get('month'), startDate.get('day'))}. ⋅ `;

export const Time: FunctionComponent<{
    startTime: string | number | Date;
    stopTime: string | number | Date;
    parentalGuidance?: ParentalGuidance;
}> = ({ startTime, stopTime }) => {
    const startDate = date(startTime);
    const stopDate = date(stopTime);
    const unixStartTime = date(startTime).unix();
    const unixStopTime = date(stopTime).unix();
    const unixCurrentTime = now().unix();
    const duration = diffInMinutes(stopDate, startDate);
    const remaining = diffInMinutes(unixStopTime, unixCurrentTime);
    const isLive = unixCurrentTime > unixStartTime && unixCurrentTime < unixStopTime;
    const isUpcoming = unixCurrentTime < unixStartTime;
    const isPast = unixCurrentTime > unixStopTime;
    const isToday = startDate.get('day') !== now().get('day');

    if (isLive) {
        return (
            <StyledTime>
                Seit {epgOverviewDateFormat(startTime, duration)}
                {remaining && ` ⋅ noch ${remaining} Min.`}
            </StyledTime>
        );
    }
    if (isUpcoming || isPast) {
        return (
            <StyledTime>
                {isToday && getDateString(startDate)}
                {epgOverviewDateFormat(startTime, duration)}
            </StyledTime>
        );
    }

    return <StyledTime />;
};
