/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, Fragment } from 'preact';
import { getBaseUrl } from '@exaring/utils';
import { MorphIconTypes, MorphIcon } from '../../elements/morph-icon';

// ToDo: Consider making this component dynamic in case we want to show different footers in different environments,
// so far it is possible to decide between the complete Footer and the Base-Footer without navigation

const base = getBaseUrl(window.location.href);

const Links = {
    FACEBOOK_URL: 'https://www.facebook.com/waipu.tv/',
    INSTAGRAM_URL: 'https://www.instagram.com/waipu.tv',
    YOUTUBE_URL: 'https://www.youtube.com/channel/UCM8k2fsf7zhm2uhEeRuv6nA',
    IMPRINT_URL: `https://www.${base}/impressum`,
    TERMS_AND_CONDITIONS_URL: `https://www.${base}/agb`,
    DATA_PRIVACY_STATEMENT_URL: `https://www.${base}/dse`,
    WEBSITE_OFFERS_URL: `https://www.${base}/angebote/`,
    WEBSITE_OPTIONS_URL: `https://www.${base}/optionen/`,
    WEBSITE_PRESS_URL: `https://www.${base}/presse/`,
    EXARING_URL: 'http://www.exaring.de/',
    EXARING_CAREER_URL: 'http://www.exaring.de/#karriere',
    HELP_DESK_URL: 'https://hilfe.waipu.de/hc/de',
    HELP_DESK_FAQ_URL: 'https://hilfe.waipu.de/hc/de/categories/200901179-FAQ',
    HELP_DESK_FIRST_STEPS_URL: 'https://hilfe.waipu.de/hc/de/categories/200901189-Neu-bei-waipu-tv',
    HELP_DESK_SETUP_URL: 'https://hilfe.waipu.de/hc/de/categories/200901199-waipu-tv-einrichten',
    HELP_DESK_USAGE_URL: 'https://hilfe.waipu.de/hc/de/categories/200927235-waipu-tv-nutzen',
    HELP_DESK_ACCOUNT_URL: 'https://hilfe.waipu.de/hc/de/categories/200901209-Konto-und-Profil',
};

/*
type Props = {
    id: string,
    label: string,
    checked?: boolean.
    children: array
}
*/

const FooterToggle = ({ id, checked, label, children }) => {
    return (
        <Fragment>
            <input
                id={id}
                type="checkbox"
                className="footer-section__toggle"
                checked={checked || false}
            />
            <label htmlFor={id} className="footer-section__header">
                <MorphIcon
                    type={MorphIconTypes.plusMinus}
                    className="footer-section__header-icon"
                />
                {label}
            </label>
            {children}
        </Fragment>
    );
};

const BaseFooterContent = () => {
    return (
        <section className="base-footer">
            <ul className="base-footer__social-link-list">
                <li className="base-footer__social-link-item">
                    <a
                        target="_blank"
                        href={Links.FACEBOOK_URL}
                        rel="noopener noreferrer"
                        className="base-footer__social-link icon icon--facebook"
                    >
                        Facebook
                    </a>
                </li>
                <li className="base-footer__social-link-item">
                    <a
                        target="_blank"
                        href={Links.INSTAGRAM_URL}
                        rel="noopener noreferrer"
                        className="base-footer__social-link icon icon--instagram"
                    >
                        Instagram
                    </a>
                </li>
                <li className="base-footer__social-link-item">
                    <a
                        target="_blank"
                        href={Links.YOUTUBE_URL}
                        rel="noopener noreferrer"
                        className="base-footer__social-link icon icon--youtube"
                    >
                        Youtube
                    </a>
                </li>
            </ul>
            <ul className="base-footer__legal-link-list">
                <li className="base-footer__legal-link-item">
                    <a href={Links.IMPRINT_URL} className="base-footer__legal-link">
                        Impressum
                    </a>
                </li>
                <li className="base-footer__legal-link-item">
                    <a href={Links.TERMS_AND_CONDITIONS_URL} className="base-footer__legal-link">
                        AGB
                    </a>
                </li>
                <li className="base-footer__legal-link-item">
                    <a href={Links.DATA_PRIVACY_STATEMENT_URL} className="base-footer__legal-link">
                        Datenschutzbestimmung
                    </a>
                </li>
            </ul>
            <span className="base-footer__copyright">&copy; EXARING AG, München</span>
        </section>
    );
};

const BaseFooter = () => {
    return (
        <footer className="footer">
            <BaseFooterContent />
        </footer>
    );
};

const Footer = () => {
    return (
        <footer className="footer">
            <div className="extended-footer">
                <div className="extended-footer__container">
                    <section className="footer-section">
                        <FooterToggle id="payment-toggle" label="Sicher bezahlen" checked>
                            <ul className="footer-section__link-list">
                                <li className="footer-section__link-item">
                                    <div className="footer-section__flex-container">
                                        <span className="icon--paypal-black" />
                                        <span className="icon--visa-black" />
                                        <span className="icon--mastercard-black" />
                                        <span className="icon--sepa-black" />
                                    </div>
                                </li>
                            </ul>
                        </FooterToggle>
                    </section>

                    <section className="footer-section">
                        <FooterToggle id="waipu-toggle" label="Mehr über waipu.tv">
                            <ul className="footer-section__link-list">
                                <li className="footer-section__link-item">
                                    <a
                                        href={Links.WEBSITE_OFFERS_URL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="footer-section__link"
                                    >
                                        Angebote
                                    </a>
                                </li>
                                <li className="footer-section__link-item">
                                    <a
                                        href={Links.WEBSITE_OPTIONS_URL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="footer-section__link"
                                    >
                                        Optionen
                                    </a>
                                </li>
                            </ul>
                        </FooterToggle>
                    </section>

                    <section className="footer-section">
                        <FooterToggle id="service-toggle" label="Hilfe">
                            <ul className="footer-section__link-list">
                                <li className="footer-section__link-item">
                                    <a
                                        href={Links.HELP_DESK_URL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="footer-section__link"
                                    >
                                        Hilfebereich
                                    </a>
                                </li>
                                <li className="footer-section__link-item">
                                    <a
                                        href={Links.HELP_DESK_FAQ_URL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="footer-section__link"
                                    >
                                        Häufige Fragen
                                    </a>
                                </li>
                                <li className="footer-section__link-item">
                                    <a
                                        href={Links.HELP_DESK_FIRST_STEPS_URL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="footer-section__link"
                                    >
                                        Erste Schritte
                                    </a>
                                </li>
                                <li className="footer-section__link-item">
                                    <a
                                        href={Links.HELP_DESK_SETUP_URL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="footer-section__link"
                                    >
                                        waipu.tv einrichten
                                    </a>
                                </li>
                                <li className="footer-section__link-item">
                                    <a
                                        href={Links.HELP_DESK_USAGE_URL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="footer-section__link"
                                    >
                                        waipu.tv nutzen
                                    </a>
                                </li>
                                <li className="footer-section__link-item">
                                    <a
                                        href={Links.HELP_DESK_ACCOUNT_URL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="footer-section__link"
                                    >
                                        Mein Konto
                                    </a>
                                </li>
                            </ul>
                        </FooterToggle>
                    </section>

                    <section className="footer-section">
                        <FooterToggle id="company-toggle" label="Unternehmen">
                            <ul className="footer-section__link-list">
                                <li className="footer-section__link-item">
                                    <a
                                        href={Links.EXARING_URL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="footer-section__link"
                                    >
                                        Über uns
                                    </a>
                                </li>
                                <li className="footer-section__link-item">
                                    <a
                                        href={Links.EXARING_CAREER_URL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="footer-section__link"
                                    >
                                        Jobs
                                    </a>
                                </li>
                                <li className="footer-section__link-item">
                                    <a
                                        href={Links.WEBSITE_PRESS_URL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="footer-section__link"
                                    >
                                        Presse
                                    </a>
                                </li>
                            </ul>
                        </FooterToggle>
                    </section>
                </div>
            </div>
            <BaseFooterContent />
        </footer>
    );
};

export { Footer, BaseFooter };
export default Footer;
