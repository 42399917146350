/* eslint-disable react/jsx-props-no-spreading */

import { h, Fragment, FunctionComponent } from 'preact';
import { ChannelSkeleton } from '@exaring/ui/components-styled/Skeleton/ChannelSkeleton';
import { styled } from '@exaring/ui/components-styled/theme';
import LockedIcon from '@exaring/assets/icons/locked.svg';
import { Channel } from '@nessprim/planby/dist/Epg/helpers/interfaces';
import { useCallback } from 'preact/hooks';
import { adjustImageRes } from '@exaring/utils/data/program';
import { WebClientGAEvent, WebClientGA } from '../../web-client-ga';
import { EpgToggleFavoriteButton } from './SettingsBar/Buttons';
import { epgStore, notificationsStore } from '../../state/Store';
import {
    isLoadingChannel,
    isStationChannel,
    StationChannel,
} from '../../types/api/epg/StationConfig';
import { useDelayedHover } from './hooks/useDelayedHover';

const ChannelBox = styled('div', {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'transparent',
    paddingRight: '1px',
});

const ChannelBoxContainer = styled('div', {
    background: '$dark',
    width: '100%',
    height: 'calc(100% - 2px)',
    borderRadius: '4px',
    boxShadow: '3px 0px 3px -1px black',
    display: 'flex',
    columnGap: '10px',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
        border: 'solid 1px $white',
    },

    variants: {
        locked: {
            true: {
                background: '$narwhalGrey',

                '&:hover': {
                    border: 'solid 1px $tuna',
                },

                '& > *': {
                    opacity: 0.25,
                    pointerEvents: 'none',
                },
            },
        },
    },
});

const EdgeCover = styled('div', {
    position: 'absolute',
    left: '0',
    width: 'calc(100% - 4px)', // offset to not clip radius background
    height: '100%',
    background: '$nearBlack',
    zIndex: -1,
});

const IconWrapper = styled('div', {
    width: '37.5px',
    display: 'flex',
    justifyContent: 'center',
});

const ChannelLogo = styled('img', {
    width: '64px',
    maxHeight: '52px',
    position: 'relative',
});

const StyledEnumeration = styled('div', {
    borderRadius: '4px',
    padding: '0px 5px',
    background: '$black',
    fontWeight: '$3',
});

const Enumeration: FunctionComponent<{ locked?: boolean; enumeration?: number }> = ({
    locked,
    enumeration,
}) => {
    if (locked) {
        return (
            <IconWrapper>
                <LockedIcon width="24px" height="24px" />
            </IconWrapper>
        );
    }

    if (enumeration) {
        return <StyledEnumeration>{prefixEnumerationWithZero(enumeration)}</StyledEnumeration>;
    }

    return <></>;
};

// 00000

export const prefixEnumerationWithZero = (value: number): string => {
    const str = `00${value}`;
    return value < 1000 ? str.substring(str.length - 3) : `${value}`;
};

const StationChannelItem: FunctionComponent<{
    channel: StationChannel;
}> = ({ channel }) => {
    const { enumeration, logo, displayName, uuid, favorite, locked } = channel;
    const { setPreview } = epgStore();
    const notifications = notificationsStore();
    const { onMouseEnter, onMouseLeave } = useDelayedHover(
        () => setPreview({ type: 'Channel', id: uuid }),
        50,
    );

    const onClick = useCallback(() => {
        if (locked) {
            notifications.createUpsellingToast();
        }
    }, [locked, notifications]);

    const toggleFavoriteAction = () => {
        epgStore().updateStation(uuid, !favorite);
        notifications.toggleFavorite(!!favorite);
        const evtDescription = favorite
            ? WebClientGAEvent.UnmarkFavourite
            : WebClientGAEvent.MarkFavourite;
        WebClientGA().trackEvent({
            eventName: WebClientGAEvent.ChannelSort,
            eventDescription: evtDescription,
            channelName: displayName,
            screenName: 'channel_sort',
        });
    };

    return (
        <>
            <ChannelBoxContainer
                locked={locked}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onClick={onClick}
            >
                <Enumeration locked={locked} enumeration={enumeration} />
                <ChannelLogo
                    src={adjustImageRes(logo, 216, 162, 'small')}
                    alt={displayName}
                    title={displayName}
                    loading="lazy"
                />
                <EpgToggleFavoriteButton isActive={favorite} onClick={toggleFavoriteAction} />
            </ChannelBoxContainer>
            <EdgeCover />
        </>
    );
};

export const ChannelItem: FunctionComponent<{
    channel: Channel;
}> = ({ channel }) => {
    const { position } = channel;

    return (
        <ChannelBox
            css={{
                top: position.top,
                height: position.height,
            }}
        >
            {isStationChannel(channel) ? (
                <StationChannelItem channel={channel} />
            ) : (
                <ChannelSkeleton animated={isLoadingChannel(channel) ? channel.animated : false} />
            )}
        </ChannelBox>
    );
};
