import { styled } from '../theme';

export const StyledLink = styled('a', {
    fontWeight: '$3',
    textDecoration: 'none',
    '&:hover, &:focus': {
        textDecoration: 'underline',
    },
    paddingLeft: '$1',
});
