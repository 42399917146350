import { useNotificationsStore } from '../state/Store';

export type Notification =
    | 'reorder-success'
    | 'reorder-error'
    | 'reset-success'
    | 'reset-error'
    | 'show-all-success'
    | 'show-all-error'
    | undefined;

export const useChannelConfigNotifications = () => {
    const notificationStore = useNotificationsStore();

    const showNotification = (notification: Notification, data?: number) => {
        // eslint-disable-next-line default-case
        switch (notification) {
            case 'reorder-success': {
                notificationStore.createSnack(
                    {
                        type: 'success',
                        message: 'Sendernummer wurde geändert',
                    },
                    'EmptyAction',
                );
                break;
            }
            case 'reorder-error': {
                notificationStore.createSnack(
                    {
                        type: 'error',
                        message: `Sendernummer muss zwischen 1 und ${data} liegen`,
                    },
                    'EmptyAction',
                );
                break;
            }
            case 'reset-success': {
                notificationStore.createSnack(
                    {
                        type: 'success',
                        message: 'Ihre Sendersortierung wurde zurückgesetzt',
                    },
                    'EmptyAction',
                );
                break;
            }
            case 'reset-error': {
                notificationStore.createSnack(
                    {
                        type: 'error',
                        message: 'Ihre Sendersortierung konnte nicht zurückgesetzt werden',
                    },
                    'EmptyAction',
                );
                break;
            }
            case 'show-all-success': {
                notificationStore.createSnack(
                    {
                        type: 'success',
                        message: `${data} Sender wurden zur Senderliste hinzugefügt`,
                    },
                    'EmptyAction',
                );
                break;
            }
            case 'show-all-error': {
                notificationStore.createSnack(
                    {
                        type: 'error',
                        message: 'Sender konnten nicht zur Senderliste hinzugefügt werden',
                    },
                    'EmptyAction',
                );
                break;
            }
        }
    };

    const showResetVisibilityModal = (onConfirm: () => void) => {
        notificationStore.createToast(
            {
                title: 'Alle Sender wieder einblenden?',
                message: () =>
                    'Alle ausgeblendeten Sender werden wieder in der Sender-Liste angezeigt und können sortiert werden.',
                icon: 'channelsort',
                confirmLabel: 'Bestätigen',
                cancelLabel: 'Abbrechen',
                onConfirm,
            },
            'EmptyAction',
        );
    };

    const showResetModal = (onConfirm: () => void, onCancel: () => void) => {
        notificationStore.createToast(
            {
                title: 'Jetzt Sendersortierung zurücksetzen?',
                message: () =>
                    'Durch das Zurücksetzen werden Ihre Favoriten entfernt und die Sendersortierung auf Standard zurückgesetzt',
                icon: 'channelsort',
                confirmLabel: 'Zurücksetzen',
                cancelLabel: 'Abbrechen',
                onConfirm,
                onCancel,
            },
            'EmptyAction',
        );
    };

    return {
        showNotification,
        showResetModal,
        showResetVisibilityModal,
    };
};
