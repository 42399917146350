/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, Fragment } from 'preact';
import screenfull from 'screenfull';
import { connect } from 'unistore/preact';

import { events, localStorageItem, logError, isEmptyString } from '@exaring/utils';
import { Exception } from '@exaring/ui/components-styled/Modal';
import { BaseUi, keyboardCodes } from '@exaring/ui';

import playoutActions from '../../actions/playout';
import playerActions from '../../actions/player';

import store from '../../store';
import MediaPlayerError from './MediaPlayerError';

import { PLAYOUT_RECORDING, PLAYOUT_LIVE_RECORDING } from '../../actions/constants';

import constants from '../../constants';
import OSD from './OSD';
import RecordingBlockerScreen from '../RecordingBlockerScreen';
import Player from '../Player';
import { routeTo, Routes } from '../../routes';
import ErrorTypes from '../../errorTypes';
import { epgStore, recordingStore, uiStore } from '../../state/Store';
import { trackWithPlayerContext } from '../../tracking';
import { WebClientGAEvent } from '../../web-client-ga';
import { RemoteDataStates } from '../../state/utils/RemoteData';
import { getCurrentScreen } from '../../helper';

const mapStateToProps = (state) => {
    const { playout, player } = state;
    const { getStationById } = epgStore();
    const { isSearchActive, previousRoute } = uiStore();
    const { streamingDetailsStatus, resetStreamingDetailsStatus } = recordingStore();

    return {
        // OSD states
        showOSD: playout.showOSD || isSearchActive,
        hideOSDPanels: isSearchActive,
        lockOSDToggle: playout.lockOSDToggle,
        isFullscreenMode: playout.isFullscreenMode,
        unmuteInteractionRequired: playout.unmuteInteractionRequired,
        playerError: playout.playerError,
        activeCooldown: playout.activeCooldown,
        parentalGuidanceRating: playout.parentalGuidanceRating,

        // playout
        playoutType: playout.playoutType,
        recording: playout.recording,
        onHold: playout.onHold,
        showVolume: playout.showVolume,

        // player
        timestamp: player.timestamp,
        errorType: player.errorType,
        errorCode: player.errorCode,
        errorMessage: player.errorMessage,
        isPlaying: player.isPlaying,
        isFinished: player.isFinished,
        muted: player.isMuted,

        // Program data
        activeChannelId: playout.activeChannelId,
        activeProgram: playout.activeProgram,
        activeChannel: getStationById(playout.activeChannelId),
        streamingDetailsStatus,
        previousRoute,
        resetStreamingDetailsStatus,
    };
};

let showVolumeTimeout;

class MediaPlayer extends BaseUi {
    constructor(props) {
        super(props);
        this.clickCounter = 0;
    }

    componentDidUpdate(previousProps) {
        const { errorCode, errorType, errorMessage } = this.props;
        const { errorCode: prevErrorCode } = previousProps;

        if (errorCode && prevErrorCode !== errorCode) {
            this.onError(errorCode, errorType, errorMessage);
        }

        const { playoutType } = this.props;

        if (this.props.isFinished) {
            if (playoutType === PLAYOUT_RECORDING) {
                routeTo(Routes.RECORDING_PAGE);
            }

            if (playoutType === PLAYOUT_LIVE_RECORDING) {
                routeTo(`${Routes.RECORDING_SCHEDULED_PAGE}`);
            }
        }

        if (
            this.props.playerError &&
            !this.props.activeCooldown && // do not reset while in active Cooldown Phase
            (previousProps.activeChannelId !== this.props.activeChannelId ||
                previousProps.playoutType !== playoutType)
        ) {
            // reset player error, when changing channels
            this.props.resetErrorOverlay();
            this.props.resetError();
        }

        if (
            this.props.isPlaying !== previousProps.isPlaying &&
            this.props.isPlaying &&
            previousProps.muted
        ) {
            this.props.setIsMuted(true);
        }
    }

    componentDidMount() {
        super.componentDidMount();
        // ToDo: Move OSD Timeout handling to store
        this.osdClickTimeout = undefined;

        if (this.isFullscreenAvailable()) {
            screenfull.on('change', this.setFullscreen);
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        clearTimeout(this.osdClickTimeout);

        this.isFullscreenAvailable() && screenfull.off('change', this.setFullscreen);
        events.removeEventsFrom(window, this.windowEventMap);
    }

    componentWillReceiveProps(nextProps) {
        if (
            (!this.props.onHold && nextProps.onHold) ||
            (!this.props.hideOSDPanels && nextProps.hideOSDPanels)
        ) {
            // player gets hidden in the back
            this.unbindEventMap(this.mouseEventMap);
            this.unbindEventMap(this.keyboardEventMap);
        } else if (
            (this.props.onHold && !nextProps.onHold) ||
            (this.props.hideOSDPanels && !nextProps.hideOSDPanels)
        ) {
            // player gets visible again
            this.bindEventMap(this.mouseEventMap);
            this.bindEventMap(this.keyboardEventMap);
        }

        if (!nextProps.isPlaying && this.props.isPlaying && this.props.muted && !nextProps.muted) {
            nextProps.setIsMuted(true);
        }
    }

    render({
        playerError,
        showOSD,
        lockOSDToggle,
        unmuteInteractionRequired,
        recording,
        onHold,
        hideOSDPanels,
        isFullscreenMode,
        parentalGuidanceRating,
        showVolume,
        streamingDetailsStatus,
    }) {
        const wrapperCls = 'player-wrapper__player-osd';
        const isLockedRecording = recording?.locked;
        const _showOsd =
            (showOSD ||
                lockOSDToggle ||
                isLockedRecording ||
                unmuteInteractionRequired ||
                playerError) &&
            localStorageItem('hasSeenWelcomeScreen');

        return (
            <>
                <div className={onHold ? 'media-player media-player--onhold' : 'media-player'}>
                    <div className="player-wrapper">
                        <div className={_showOsd ? wrapperCls : `${wrapperCls} osd--hidden`}>
                            <Player />

                            {!onHold && (
                                <OSD
                                    parentalGuidance={parentalGuidanceRating}
                                    show={_showOsd}
                                    showPanels={!hideOSDPanels}
                                    disabled={isLockedRecording}
                                    isFullscreen={isFullscreenMode}
                                    onNavigateLeft={this.onNavigateLeft}
                                    onNavigateRight={this.onNavigateRight}
                                    onBackgroundClick={this.onMouseUpListener}
                                    isPauseForbidden={this.isPauseForbidden}
                                    isFullscreenAvailable={this.isFullscreenAvailable}
                                    onFullscreenButtonClick={this.toggleFullscreen}
                                    volumeSliderActive={showVolume}
                                />
                            )}
                            {isLockedRecording && <RecordingBlockerScreen />}
                        </div>
                    </div>
                </div>
                {streamingDetailsStatus === RemoteDataStates.Failed && (
                    <Exception
                        title="Ihr Stream konnte nicht geladen werden."
                        subtitle="Leider konnte Ihr Stream nicht geladen werden. Bitte laden Sie die Seite noch einmal oder versuchen Sie es später noch einmal."
                        onClick={this.goBack}
                        withReload
                    />
                )}
            </>
        );
    }

    get mouseEventMap() {
        return {
            mousemove: this.onMouseMove,
        };
    }

    get keyboardEventMap() {
        return {
            keyup: this.onKeyUpListener,
            keydown: this.onKeyDownListener,
        };
    }

    get windowEventMap() {
        return {
            beforeunload: this.onUnloadWindow,
        };
    }

    onKeyUpListener = (e) => {
        const { code } = e;

        switch (code) {
            case keyboardCodes.escape:
                // reuse the handling of the background click
                this.onMouseUpListener(e);
                break;
            default:
                return;
        }
        events.pauseEvent(e);
    };

    onKeyDownListener = (e) => {
        const { code, ctrlKey, metaKey } = e;

        if (ctrlKey || metaKey) {
            // ignore any keycode combos with cmd/meta key (linux, win and macos compatibility)
            return;
        }

        switch (code) {
            case keyboardCodes.escape:
                events.pauseEvent(e);
                break;
            case keyboardCodes.space:
                if (this.props.playoutType === PLAYOUT_RECORDING || !this.isPauseForbidden()) {
                    this.togglePlay(e);
                }
                break;
            case keyboardCodes.f:
                if (!this.props.hideOSDPanels) {
                    this.toggleFullscreen();
                }
                break;
            case keyboardCodes.left:
                this.onNavigateLeft(e);
                break;
            case keyboardCodes.right:
                this.onNavigateRight(e);
                break;
            case keyboardCodes.up:
                this.onArrowUp(e);
                break;
            case keyboardCodes.down:
                this.onArrowDown(e);
                break;
            default:
                break;
        }
    };

    onMouseMove = () => {
        if (!this.props.lockOSDToggle) {
            this.props.toggleOSD(/* show */ true);
        }
    };

    isPauseForbidden = () => {
        const { activeProgram, activeChannel } = this.props;

        // use channel.pauseForbidden as a fallback, if program or program.pauseForbidden is not set
        if (!activeProgram || activeProgram?.pauseForbidden === undefined) {
            return !!activeChannel?.restrictions.pauseForbidden;
        }

        return !!activeProgram?.pauseForbidden;
    };

    togglePlay = (e) => {
        events.pauseEvent(e);
        this.onPlay(!this.props.isPlaying);
    };

    setFullscreen = () => {
        this.onToggleFullscreen(screenfull.isFullscreen);
    };

    isFullscreenAvailable = () => screenfull.isEnabled;

    toggleFullscreen = () => {
        if (this.props.isFullscreenMode) {
            this.exitFullscreen();
        } else {
            this.launchFullscreen();
        }
        trackWithPlayerContext(
            WebClientGAEvent.PlayerControls,
            store.getState(),
            WebClientGAEvent.ToggleFullScreen,
            getCurrentScreen(),
        );
    };

    launchFullscreen = async () => {
        // cross browser fullscreen support (including mobile devices)
        if (this.isFullscreenAvailable()) {
            try {
                await screenfull.request();
            } catch (e) {
                // in older firefox this could throw an permission error https://exaring.atlassian.net/browse/TVFUS-44874
            }
            this.onToggleFullscreen(true);
        } else {
            // TODO add toast in case fullscreen is disabled
        }
    };

    exitFullscreen = async () => {
        if (this.isFullscreenAvailable()) {
            try {
                await screenfull.exit();
            } catch (e) {
                // eslint-disable-line
            }
            this.onToggleFullscreen(false);
        }
    };

    onMouseUpListener = (e) => {
        events.pauseEvent(e);

        this.props.initUnmuteIfNeeded();

        this.clickCounter += 1;

        clearTimeout(this.osdClickTimeout);

        this.osdClickTimeout = setTimeout(() => {
            // just one click toggles OSD
            if (this.clickCounter === 1) {
                this.props.toggleOSD(
                    /* show */ this.props.unmuteInteractionRequired || !this.props.showOSD,
                );
            }

            if (this.clickCounter >= 2) {
                this.toggleFullscreen();
            }

            this.clickCounter = 0;
        }, constants.OSD_CLICK_ACTION_TIMEOUT);
    };

    onError = (errorCode, errorType, errorMessage) => {
        const error = new MediaPlayerError({ errorCode, errorType, errorMessage });
        // if error results from too many active streams set a cooldown-phase
        if (errorType === ErrorTypes.StreamLimitation) {
            this.props.setPlayoutCooldown();
        }

        this.props.showErrorOverlay({ errorCode, errorType });

        logError(error);
    };

    handleVolumeSlideInteraction = () => {
        const { toggleShowVolume } = this.props;
        toggleShowVolume(true);
        clearTimeout(showVolumeTimeout);
        showVolumeTimeout = setTimeout(() => {
            toggleShowVolume(false);
        }, constants.OSD_VOLUME_AUTO_HIDE_TIMEOUT);
    };

    onArrowUp = () => {
        const { increaseVolume } = this.props;
        this.handleVolumeSlideInteraction();
        increaseVolume();
    };

    onArrowDown = () => {
        const { decreaseVolume } = this.props;
        this.handleVolumeSlideInteraction();
        decreaseVolume();
    };

    onNavigateRight = (e) => {
        e.stopPropagation();

        if (!this.props.hideOSDPanels) {
            this.props.playoutByChannelShift(1);
        }
    };

    onNavigateLeft = (e) => {
        e.stopPropagation();
        if (!this.props.hideOSDPanels) {
            this.props.playoutByChannelShift(-1);
        }
    };

    onPlay = (active) => {
        this.props.togglePlay(active);
    };

    onToggleFullscreen = (active) => {
        this.props.setFullscreenMode(active);
    };

    onUnloadWindow = () => {
        this.props.updateBackendRecordingPosition(this.props.isFinished);
    };

    goBack = () => {
        const { previousRoute, resetStreamingDetailsStatus } = this.props;
        resetStreamingDetailsStatus();
        if (previousRoute && !isEmptyString(previousRoute)) {
            routeTo(previousRoute);
        } else {
            routeTo('/', false);
        }
    };
}

const combinedActions = () => {
    return {
        ...playoutActions(store),
        ...playerActions(store),
    };
};

export default connect(mapStateToProps, combinedActions)(MediaPlayer);
