import { SentryEvent, SentryHint } from '@exaring/utils/error-logging/types';
import constants from '../../constants';
import MediaPlayerError from './MediaPlayerError';
import ErrorTypes from '../../errorTypes';

export const setMediaPlayerErrorGroup = (event: SentryEvent, hint: SentryHint) => {
    const exception = hint.originalException;

    if (exception instanceof MediaPlayerError) {
        if (exception.errorCode) {
            if (
                exception.errorCode === 1002 ||
                exception.errorCode === 1003 ||
                (exception.errorCode === 1001 && exception.errorType === ErrorTypes.Network)
            ) {
                return { ...event, fingerprint: ['MediaPlayerErrorIgnored'] };
            }
        }

        return {
            ...event,
            fingerprint: ['MediaPlayerError', constants.PLAYER_NAME, exception.errorType],
        };
    }

    return event;
};
