import { playoutUrl, programDetails, mediaLibrary } from '../requests/tuner';
import { requestHookChain } from '../networking.v2';

const api = {
    playoutUrl: requestHookChain(playoutUrl),
    programDetails: requestHookChain(programDetails),
    mediaLibrary: requestHookChain(mediaLibrary),
};

export default api;
