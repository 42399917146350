/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { FunctionComponent, h, Fragment } from 'preact';
import contentDiscoveryService from '@exaring/networking/services/content-discovery';
import { isNil, logError } from '@exaring/utils';
import { NetworkingError } from '@exaring/networking';
import { TileScroller } from './TileScroller';
import { Recommendation, RecommendationContents } from '../../types/Recommendation';
import { useAsyncAction } from '../../../hooks';
import { Typography } from '../../Typography';
import { styled } from '../../theme';
import { contentDiscoveryTileMapper } from './contentDiscoveryTileMapper';
import { LayoutType } from '../../ProgramDetailPage/types';

export enum RecommendationDataType {
    Recording = 'recording',
    Program = 'program',
    Vod = 'vod',
}

const Separator = styled('div', {
    width: '100%',
    height: '2px',
    margin: '50px 0 50px 0',
    borderRadius: '1px',
    backgroundImage: 'linear-gradient(to left, $purple 0%, $bluish 100%)',
});

const Heading = styled(Typography, {
    marginTop: '50px',
    marginBottom: '24px',
    fontSize: '$3',
    fontWeight: 600,
    variants: {
        layout: {
            modal: {
                marginBottom: '0px',
            },
            player: {},
            epg: {},
            mediathek: {},
            microSite: {},
        },
    },
});

const StyledWrapper = styled('div', {
    maxWidth: '788px',
    '@sm': {
        maxWidth: '888px',
    },
    variants: {
        layout: {
            modal: {},
            player: {
                maxWidth: '458px',
                '@sm': {
                    maxWidth: '448px',
                },
            },
            epg: {
                maxWidth: 'unset',
            },
            mediathek: {
                maxWidth: 'unset',
            },
            microSite: {},
        },
    },
});

const StyledLengthContainer = styled('span', {
    fontFamily: '$primary',
    fontSize: '$1',
    color: '$mediumGray',
    marginLeft: '10px',
    fontWeight: '$1',
});

const fetchRecommendations = async (
    dataType: RecommendationDataType,
    programId: string,
    recordingId: string,
    vodId: string,
): Promise<
    | {
          result: Recommendation[];
      }
    | undefined
> => {
    if (!dataType) {
        return undefined;
    }
    try {
        let action;
        switch (dataType) {
            case RecommendationDataType.Recording:
                action = () => contentDiscoveryService.recordings(recordingId);
                break;
            case RecommendationDataType.Program:
                action = () => contentDiscoveryService.programs(programId);
                break;
            case RecommendationDataType.Vod:
                action = () => contentDiscoveryService.vod(vodId);
                break;
            default:
                break;
        }
        const {
            data: { modules },
        }: any = action && (await action());
        return modules;
    } catch (e: unknown) {
        if (e instanceof NetworkingError) {
            if (e.response?.status !== 404) {
                // limit error logging to unexpected networking errors
                logError(e);
            }
        } else if (e instanceof Error) {
            // log all other errors
            logError(e);
        }
    }
    return undefined;
};

type RecommendationsProps = {
    programId: string;
    dataType?: RecommendationDataType;
    recordingId?: string;
    vodId?: string;
    layout?: LayoutType;
    handleRecommendationClick?: (
        args: {
            channelId: string;
            programId: string;
            recommendation: RecommendationContents;
            contentId?: string;
        },
        rowIdx: number,
        tileIdx: number,
        rowId: string,
    ) => void;
};

export const Recommendations: FunctionComponent<RecommendationsProps> = ({
    programId,
    recordingId,
    handleRecommendationClick,
    dataType,
    vodId,
    layout,
}) => {
    const [recommendationsData] = useAsyncAction(fetchRecommendations, [
        dataType,
        programId,
        recordingId,
        vodId,
    ]);

    const {
        result: recommendations,
        isLoading,
    }: { result: Recommendation[] | undefined | null; isLoading: boolean } =
        recommendationsData as { result: Recommendation[] | undefined | null; isLoading: boolean };

    if (isLoading || !Array.isArray(recommendations) || recommendations.length === 0) {
        return <></>;
    }

    return (
        <StyledWrapper layout={layout}>
            <Separator />
            {recommendations.map((recommendationData: Recommendation, rowIdx: number) => (
                <>
                    <Heading variant="body2" tagName="h3" layout={layout}>
                        {recommendationData.title}
                        {recommendationData.contents && (
                            <StyledLengthContainer>{`${recommendationData.contents.length} ${
                                recommendationData.contents.length > 1 ? 'Videos' : 'Video'
                            }`}</StyledLengthContainer>
                        )}
                    </Heading>
                    <TileScroller
                        layout={layout}
                        data={
                            recommendationData.contents.map((item) => ({
                                ...item,
                                dataId: recommendationData.id,
                            })) ?? []
                        }
                        onClick={({ tileIdx, ...args }) =>
                            !isNil(recommendationData.id) &&
                            handleRecommendationClick?.(
                                args,
                                rowIdx + 1,
                                tileIdx + 1,
                                recommendationData.id,
                            )
                        }
                        mapTile={contentDiscoveryTileMapper}
                    />
                </>
            ))}
        </StyledWrapper>
    );
};
