import { h, FunctionComponent } from 'preact';
import { CSS } from '@stitches/react';
import LockedIcon from '@exaring/assets/icons/locked.svg';
import tileFallback from '@exaring/assets/images/tileFallback.png';
import { Colors, styled } from '../../theme';
import { StyledTileImageContainer } from '../parts/StyledTileImageContainer';
import { StyledImageOverlay } from '../parts/StyledImageOverlay';
import { ImageWithFallback } from '../parts/ImageWithFallback';

const StyledImage = styled(ImageWithFallback, {
    borderRadius: '$1',
    cursor: 'pointer',

    objectFit: 'cover',
    backgroundSize: 'cover',

    maxWidth: '434px',
    width: '287px',

    variants: {
        size: {
            mobile: { width: '287px' },
            default: { width: '434px' },
        },
    },
});

const ImageContainer = styled(StyledTileImageContainer, {
    cursor: 'pointer',
});

const LockContainer: FunctionComponent<{
    css?: CSS;
    className?: string;
    locked?: boolean;
    onClick?: () => void;
}> = ({ locked = false, children, onClick, css, className }) => {
    return (
        <ImageContainer onClick={onClick} css={css} className={className}>
            {locked && (
                <StyledImageOverlay>
                    <LockedIcon width={45} height={45} color={Colors.white} />
                </StyledImageOverlay>
            )}
            {children}
        </ImageContainer>
    );
};

//
// Component
//

export const CollectionTile: FunctionComponent<{
    imageSrc: string;
    alt: string;
    css?: CSS;
    className?: string;
    locked?: boolean;
    onClick?: () => void;
}> = ({ imageSrc, css, className, locked, onClick, alt }) => (
    <LockContainer className={className} css={css} locked={locked} onClick={onClick}>
        <StyledImage
            size={{
                '@initial': 'mobile',
                '@sm': 'default',
            }}
            src={imageSrc}
            alt={alt}
            srcFallback={tileFallback}
        />
    </LockContainer>
);
