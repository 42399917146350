/*
 *  Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, FunctionComponent } from 'preact';
import { isEmptyString } from '@exaring/utils';
import { isOnAir } from '@exaring/utils/data/program';
import { RouteParams } from '../@types/router';
import {
    waiputhekContextByCrumbs,
    waiputhekBackPathByCrumbs,
    routeTo,
    routeToWaiputhekDetails,
    routeToWaiputhekMediathek,
    splitWaiputhekPath,
    WaiputhekCrumbKey,
    waiputhekRouteByPath,
    routeToWaiputhekByPath,
    replaceHistoryState,
    crumbsOfWaiputhekDetails,
    routeToVoD,
    routeToLive,
} from '../routes';
import { WaiputhekDetailsPage } from './WaiputhekDetailsPage';
import { WaiputhekPage } from './WaiputhekPage';
import { MediathekPage } from './MediathekPage';
import { isChannel, isContent } from '../types/WaiputhekContext';
import { RecommendationContents } from '../../../../packages/ui/components-styled/types/Recommendation';

export const WaiputhekRouter: FunctionComponent<{
    matches: RouteParams;
}> = ({ matches }) => {
    const crumbs = splitWaiputhekPath(matches.breadcrumb);
    const context = waiputhekContextByCrumbs(crumbs);
    const backPath = waiputhekBackPathByCrumbs(crumbs);

    const handleGoBack = () => {
        if (matches.back && !isEmptyString(matches.back)) {
            routeTo(matches.back);
        } else {
            routeToWaiputhekByPath(backPath);
        }
    };

    const handleGoToVod = (
        contentId: string,
        channelId?: string,
        moduleId?: string,
        categoryId?: string,
    ) => {
        replaceHistoryState(
            crumbsOfWaiputhekDetails(
                contentId,
                moduleId,
                categoryId,
                moduleId ? undefined : crumbs,
            ),
        );
        routeToVoD(channelId, contentId, false);
    };

    if (isChannel(context)) {
        return (
            <MediathekPage
                id={context.channelId}
                categoryId={context.focus?.sectionId}
                contentId={context.focus?.contentId}
                goBack={handleGoBack}
                goToChannel={(id) => routeToWaiputhekMediathek(id, undefined, undefined, crumbs)}
                goToContent={(id, categoryId) => {
                    routeToWaiputhekDetails(id, undefined, categoryId, crumbs);
                }}
                goToVod={(contentId, channelId, categoryId) =>
                    handleGoToVod(contentId, channelId, undefined, categoryId)
                }
            />
        );
    }

    if (isContent(context)) {
        const handleRecommendationClick = (args: {
            channelId: string;
            programId: string;
            recommendation: RecommendationContents;
            contentId?: string;
        }) => {
            const { channelId, programId, recommendation, contentId } = args;
            if (contentId) {
                routeToWaiputhekDetails(contentId);
                return;
            }

            if (isOnAir(recommendation) && !recommendation.locked) {
                routeToLive(channelId);
                return;
            }

            if (channelId) {
                routeToWaiputhekDetails(
                    programId,
                    undefined,
                    undefined,
                    [{ key: WaiputhekCrumbKey.CHANNEL, id: channelId }],
                    matches.back && !isEmptyString(matches.back)
                        ? matches.back
                        : waiputhekRouteByPath(backPath),
                );
            }
        };

        return (
            <WaiputhekDetailsPage
                id={context.contentId}
                channelId={context.channelId}
                goBack={handleGoBack}
                onRecommendationClick={handleRecommendationClick}
            />
        );
    }

    return (
        <WaiputhekPage
            moduleId={context.focus?.sectionId}
            contentId={context.focus?.contentId}
            channelId={context.focus?.channelId}
            categoryId={context.focus?.categoryId}
            goToChannel={routeToWaiputhekMediathek}
            goToContent={routeToWaiputhekDetails}
            goToVod={handleGoToVod}
        />
    );
};
