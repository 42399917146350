import { h, Component } from 'preact';
import { classnames } from '@exaring/utils';

const IconState = {
    loading: 0,
    loaded: 1,
    error: 2,
};

export default class ChannelLogo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadState: IconState.loading,
        };
    }

    handleImageLoad = () => this.setState({ loadState: IconState.loaded });

    handleImageLoadError = () => this.setState({ loadState: IconState.error });

    render({ src, sourceSet }) {
        const { loadState } = this.state;

        return (
            <img
                alt=""
                className={classnames(
                    'channel-logo',
                    loadState === IconState.loading && 'loading',
                    loadState === IconState.loaded && 'loaded',
                    loadState === IconState.error && 'error',
                )}
                src={src}
                srcSet={sourceSet}
                onLoad={this.handleImageLoad}
                onError={this.handleImageLoadError}
            />
        );
    }
}

/*
ChannelLogo.propTypes = {
    src: React.PropTypes.string,
    sourceSet: React.PropTypes.string
} */
