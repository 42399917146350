
import { getBaseUrl } from '@exaring/utils';
import { get } from '../../request'; 

const base = getBaseUrl(window.location.href);
const CONTENT_DISCOVERY_URL = `https://content-discovery.${base}/api`;

export const mostPopularRecordings = () => {
    return get(`${CONTENT_DISCOVERY_URL}/recording-recommendations/most-popular`);
};

export const recordings = (recordingId: string) =>
    get(`${CONTENT_DISCOVERY_URL}/recommendations/recordings/${recordingId}`, {
        Accept: 'application/vnd.waipu.content-discovery-recommendations-v1+json',
});

export const programs = (programId: string) =>
    get(`${CONTENT_DISCOVERY_URL}/recommendations/programs/${programId}`, {
        Accept: 'application/vnd.waipu.content-discovery-recommendations-v1+json',
});

export const vod = (vodId: string) =>
    get(`${CONTENT_DISCOVERY_URL}/recommendations/vod/${vodId}`, {
        Accept: 'application/vnd.waipu.content-discovery-recommendations-v1+json',
});
