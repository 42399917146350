import constants from '../constants';
import { post, put } from '../request';

/**
 * Trigger DOI confirmation email
 * 201 Created — Email sent
 * @returns {Object}
 */
export const confirmationEmail = () =>
    post(`${constants.CUSTOMER_SELF_CARE_BASE_URL}/doi-confirmation-email`);

export const update = (data) =>
    put(
        `${constants.CUSTOMER_SELF_CARE_BASE_URL}/master-data`,
        {
            'Content-Type': 'application/vnd.waipu.customer-update-v1+json',
        },
        data,
    );

export const setSepaPaymentData = (data, recaptchaResponse, recaptchaAction) =>
    post(
        `${constants.CUSTOMER_SELF_CARE_BASE_URL}/payment-data`,
        {
            'Content-Type': 'application/vnd.waipu.customer-self-care-sepa-data-v1+json',
        },
        {
            ...data,
            recaptcha_response: recaptchaResponse,
            recaptcha_action: recaptchaAction,
        },
    );
