/*
 * @deprecated
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';

export const MorphIconTypes = {
    /* eslint-disable no-bitwise */
    plusMinus: 1 << 0,
    burgerMenu: 1 << 1,
    cscNavigationDropdown: 1 << 2,
    /* eslint-enable no-bitwise */
};

// type Props = {
//     type: string,
//     className?: string
// };

export const MorphIcon = ({ className, type }) => {
    let path = '';
    let morphId = '';

    switch (type) {
        case MorphIconTypes.plusMinus:
            path = <path id="m2" d="M 3,11 l 9,0 l 0,2 l -9,0 a 1,1 0 0 1 0,-2" />;
            morphId = 'm2';
            break;

        case MorphIconTypes.burgerMenu:
            path = <path id="m1" d="M 3,11 l 9,0 l 0,2 l -9,0 a 1,1 0 0 1 0,-2" />;
            morphId = 'm1';
            break;

        case MorphIconTypes.cscNavigationDropdown:
            path = <path id="a" d="M 3,11 l 9,0 l 0,2 l -9,0 a 1,1 0 0 1 0,-2" />;
            morphId = 'a';
            break;
        default:
            return '';
    }

    return (
        <svg
            className={`morph-icon ${className || ''}`}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <dev>{path}</dev>
            <use className="top-left" xlinkHref={`#${morphId}`} />
            <use className="top-right" xlinkHref={`#${morphId}`} />
            <use className="middle-left" xlinkHref={`#${morphId}`} />
            <use className="middle-right" xlinkHref={`#${morphId}`} />
            <use className="bottom-left" xlinkHref={`#${morphId}`} />
            <use className="bottom-right" xlinkHref={`#${morphId}`} />
        </svg>
    );
};

export default MorphIcon;
