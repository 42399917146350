/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, FunctionComponent } from 'preact';
import PlayIcon from '@exaring/assets/icons/play.svg';
import LockedIcon from '@exaring/assets/icons/locked.svg';
import { Colors, CSS } from '../../theme';
import { StyledImageOverlay } from './StyledImageOverlay';
import { StyledTileImageContainer } from './StyledTileImageContainer';

export const VideoTileImageContainer: FunctionComponent<{
    css?: CSS;
    className?: string;
    locked?: boolean;
    onClick?: (event: MouseEvent) => void;
    iconsSize?: number;
    hidePlayOverlay?: boolean;
    hideAllOverlays?: boolean;
}> = ({
    locked = false,
    children,
    onClick,
    css,
    className,
    hidePlayOverlay = false,
    hideAllOverlays = false,
    iconsSize = 44,
}) => {
    return (
        <StyledTileImageContainer onClick={onClick as any} css={css} className={className}>
            {!hideAllOverlays &&
                (locked ? (
                    <StyledImageOverlay>
                        <LockedIcon width={iconsSize} height={iconsSize} color={Colors.white} />
                    </StyledImageOverlay>
                ) : (
                    !hidePlayOverlay && (
                        <StyledImageOverlay>
                            <PlayIcon width={iconsSize} height={iconsSize} color={Colors.white} />
                        </StyledImageOverlay>
                    )
                ))}
            {children}
        </StyledTileImageContainer>
    );
};
