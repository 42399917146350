import { FunctionComponent, h } from 'preact';
import { CSS } from '@stitches/react';
import { styled } from '../theme';
import { StyledCheckedIcon, StyledUncheckedIcon } from '../Button/Button';

const StyledToggleButton = styled('div', {
    display: 'flex',
    alignItems: 'center',

    variants: {
        selected: {
            true: {
                color: '$aquamarine',
            },
        },
    },
});

export const ToggleButton: FunctionComponent<{
    css: CSS;
    selected?: boolean;
    onClick?: (event: any) => void;
}> = ({ children, css, selected = false, onClick }) => {
    const CircleIcon = selected ? StyledCheckedIcon : StyledUncheckedIcon;
    return (
        <StyledToggleButton css={css} onClick={onClick} selected={selected}>
            {children}
            <CircleIcon />
        </StyledToggleButton>
    );
};
