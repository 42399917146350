import { FunctionComponent, h } from 'preact';
import { styled } from '@exaring/ui/components-styled/theme';
import { ParentalGuidance } from '@exaring/networking/types/ProgramDetails';
import { parentalRatingType } from '../../../actions/helper/parental-guidance';

const StyledGenre = styled('div', {
    height: '15px',
    fontSize: '$1',
    lineHeight: 1.25,
    marginTop: '10px',
    fontWeight: '$1',
    color: '$mediumGray',

    '@md-width-and-md-height': {
        height: '27px',
        fontSize: '$2.5',
        lineHeight: '1.69',
    },
});

export const Genre: FunctionComponent<{
    genre?: string;
    year?: string;
    parentalGuidance?: ParentalGuidance;
}> = ({ genre, year, parentalGuidance }) => {
    const fsk =
        parentalGuidance && parentalRatingType[parentalGuidance]
            ? `FSK ${parentalRatingType[parentalGuidance]}`
            : undefined;
    const text = [genre, year, fsk].filter((it) => it !== undefined).join(' ⋅ ');
    return <StyledGenre>{text}</StyledGenre>;
};
