/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

/* eslint-disable import/first, global-require */
if (process.env.NODE_ENV === 'development') {
    require('preact/debug');
}

// Elements
import Button from './elements/button';
import Icon from './elements/icon';
import {
    MorphIconTypes, // FIXME: heavy type definition
    MorphIcon,
} from './elements/morph-icon';

// typography
import Heading from './typography/heading';
import Text from './typography/text';
import Link from './typography/link';

// form
import Checkbox from './form/checkbox'; // FIXME: depreacted?

// Components
import Section from './components/section';

import ButtonGroup from './components/button-group';

import Header from './components/header';
import { Header as HeaderLoggedIn } from './components/header--logged-in';
import { Footer, BaseFooter } from './components/footer';
import { Footer as FooterO2 } from './components/footer-o2';

import { keyboardKeys, keyboardCodes } from './constants';
import { BaseUi, SwipeDirection } from './components/base-ui';
import { appBootstrap } from './components/api-app';
import NotificationScreen from './components/notification-screen';
import Spinner from './components/spinner';
import Slider from './components/slider';
import SelectInput from './components/select-input';
import Dropdown from './components/dropdown';

import ModalDialogue from './components/dialog'; // FIXME: depreacted?
import PopupMenu from './components/popup-menu';

import NotificationToast from './components/notification-toast';
import Select from './components/select';
import DraggableChannelList from './components/channel-list/draggable-channel-list';
import SelectableChannelList from './components/channel-list/selectable-channel-list';
import { Navigation, NavigationItem } from './components/navigation';
import UpcomingProgram from './components/epg-details/upcoming-program';
import ErrorPage from './pages/error-page';
/* eslint-enable */

export {
    // Input
    keyboardKeys,
    keyboardCodes,
    // TYPOGRAPHY
    Text,
    Link,
    Heading,
    // ELEMENTS
    Button,
    Icon,
    MorphIconTypes, // FIXME: heavy type definition
    MorphIcon,
    // COMPONENTS
    BaseUi,
    SwipeDirection,
    Section,
    ButtonGroup,
    Slider,
    SelectInput,
    Select,
    Checkbox,
    Dropdown,
    PopupMenu,
    Spinner,
    NotificationScreen,
    ModalDialogue,
    NotificationToast,
    Navigation,
    NavigationItem,
    // channel sort components
    DraggableChannelList,
    SelectableChannelList,
    // epg details components
    UpcomingProgram,
    // ErrorPage components
    ErrorPage,
    // bootstrap and specialized components
    appBootstrap,
    Header,
    Footer,
    BaseFooter,
    FooterO2,
    HeaderLoggedIn,
};
