import { h, FunctionComponent, Fragment } from 'preact';
import Edit from '@exaring/assets/icons/edit.svg';
import Delete from '@exaring/assets/icons/recording-delete.svg';
import Checked from '@exaring/assets/icons/circle-checked.svg';
import Unchecked from '@exaring/assets/icons/circle-unchecked.svg';
import { styled } from '../theme';
import {
    primaryStyles,
    primaryDisabledStyles,
    secondaryStyles,
    secondaryDisabledStyles,
    darkStyles,
    darkDisabledStyles,
    epgStyles,
    epgDisabledStyles,
    epgActiveStyles,
} from './tenantStyles';
import { LoadingSpinner } from '../LoadingSpinner';
import { ButtonProps } from './types';

const iconStyles = {
    width: '32px',
    height: '32px',
    marginRight: '$5',
    pointerEvents: 'none',
};
export const StyledEditIcon = styled(Edit, iconStyles);

export const StyledDeleteIcon = styled(Delete, iconStyles);

const circleIconStyles = {
    width: '32px',
    height: '32px',
    marginLeft: '$5',
    pointerEvents: 'none',
};
export const StyledCheckedIcon = styled(Checked, circleIconStyles);

export const StyledUncheckedIcon = styled(Unchecked, circleIconStyles);

const ButtonContent = styled('div', {});

const StyledButton = styled('button', {
    ...primaryStyles,
    variants: {
        secondary: {
            true: {
                ...secondaryStyles,
            },
        },
        dark: {
            true: {
                ...darkStyles,
            },
        },
        // TODO the name `disabled` overwrites the HTML prop with the same name
        // and therefore it is not set. This means that native browser
        // functionality (screenreader?!!) don't work as well as
        // react-testing-libraries toBeDisabled! For the moment you can use
        // expect(screen.getByRole('button', { name: /my button name/i }).className).toContain('disabled-true');
        disabled: {
            true: {
                ...primaryDisabledStyles,
            },
        },
        active: {
            true: {
                ...epgActiveStyles,
            },
        },
        epg: {
            true: {
                ...epgStyles,
            },
        },
        iconOnlyOnMobile: {
            true: {
                [`${ButtonContent}`]: {
                    display: 'none',
                    '@md': {
                        display: 'block',
                    },
                },
            },
        },
    },
    compoundVariants: [
        {
            secondary: true,
            disabled: true,
            css: {
                ...secondaryDisabledStyles,
            },
        },
        {
            dark: true,
            disabled: true,
            css: {
                ...darkDisabledStyles,
            },
        },

        {
            epg: true,
            disabled: true,
            css: {
                ...epgDisabledStyles,
            },
        },
    ],
});

// https://app.zeplin.io/styleguide/5db1b7aeb864471285b0ec2c/components?seid=61a0dc0ebd939e891289c460
export const Button: FunctionComponent<ButtonProps> = ({
    id,
    children,
    css: cssProp,
    className,
    title,
    onClick,
    disabled = false,
    secondary = false,
    dark = false,
    epg = false,
    active = false,
    type = 'button',
    loading = false,
    icon,
    selected,
    iconOnlyOnMobile,
    dataClickClass,
    dataClickId,
}) => {
    const CircleIcon = selected ? StyledCheckedIcon : StyledUncheckedIcon;

    const trackingProps = dataClickClass &&
        dataClickId && {
            'data-click-class': dataClickClass,
            'data-click-id': dataClickId,
        };

    return (
        <StyledButton
            id={id}
            type={type}
            css={cssProp}
            className={className}
            onClick={onClick}
            disabled={disabled}
            secondary={secondary}
            dark={dark}
            epg={epg}
            active={active || selected}
            title={title}
            iconOnlyOnMobile={iconOnlyOnMobile}
        >
            {loading ? (
                <LoadingSpinner secondary={secondary || dark} />
            ) : (
                <>
                    {icon}
                    <ButtonContent {...trackingProps}>
                        {children}
                        {selected !== undefined ? <CircleIcon /> : undefined}
                    </ButtonContent>
                </>
            )}
        </StyledButton>
    );
};
