import { RefObject } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';

type UseHover<T> = [RefObject<T>, boolean];

export const useHover = <T extends HTMLElement>(): UseHover<T> => {
    const [value, setValue] = useState<boolean>(false);
    const ref = useRef<T>(null);

    const handleMouseOver = () => {
        setValue(true);
    };
    const handleMouseOut = () => {
        setValue(false);
    };

    useEffect(() => {
        const node = ref.current;

        if (node) {
            node.addEventListener('mouseenter', handleMouseOver);
            node.addEventListener('mouseleave', handleMouseOut);

            return () => {
                setValue(false);
                node.removeEventListener('mouseenter', handleMouseOver);
                node.removeEventListener('mouseleave', handleMouseOut);
            };
        }

        return () => {
            setValue(false);
        };
    }, [ref]);

    return [ref, value];
};
