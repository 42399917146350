/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import ctime from 'ctimejs';
import { timeoutPromise } from '../promise';
import { inRange } from '../math';

/** @type {['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']} */
const LO_MONTH = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
];

/** @type {['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']} */
const LO_DAYS = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];

/**
 * Test if the given string is a ISO8601 date string.
 * @See https://stackoverflow.com/questions/3143070/javascript-regex-iso-datetime
 *
 * @param {string} dateString
 * @returns boolean
 */
export const isIso8601 = (dateString) =>
    typeof dateString === 'string'
        ? /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)/.test(
              dateString,
          )
        : false;

/**
 * @param {number} val
 * @return {string | number}
 */
export const leadingZero = (val) => {
    return val > 9 ? val : `0${val}`;
};

/**
 * @param {number} duration
 * @param suffix
 * @returns
 */
export const formatSecondsDuration = (duration, suffix = 'Min.') =>
    `${Math.round(duration / 60)} ${suffix}`;

/**
 * @param {0 | 1 | 2 | 3 | 4 | 5 | 6} idx
 * @param {boolean} [short]
 * @return {(typeof LO_DAYS)[number] | string}
 */
export const weekday = (idx, short = false) => {
    return short ? `${LO_DAYS[idx].substring(0, 2)}.` : LO_DAYS[idx];
};

/**
 * Unix timestamp of given time reference
 *
 * @param {string|number|Date|CTime} [t] Optional time reference
 * @returns {number}
 */
export const unix = (t) => ctime(t).unix();

/**
 * Now time as ctime reference
 *
 * @returns {CTime}
 */
export const now = () => ctime();

/**
 * Ctime instance
 *
 * @returns {CTime}
 */
export const date = (t) => ctime(t);

/**
 * Verify if ctime date is in range
 *
 * @param {string|Date|CTime} t
 * @param {string|Date|CTime} tmin
 * @param {string|Date|CTime} tmax
 * @returns {boolean}
 */
export const isDateInRange = (t, tmin, tmax) => {
    const value = isIso8601(t) || t instanceof Date ? date(t) : t.unix();
    const minValue = isIso8601(tmin) || t instanceof Date ? date(tmin) : tmin.unix();
    const maxValue = isIso8601(tmax) || t instanceof Date ? date(tmax) : tmax.unix();
    return inRange(value, minValue, maxValue);
};

/**
 * Determines if a date range overlaps with live edge
 *
 * @param {string|number|date} start time
 * @param {string|number|date} end time
 * @param {string|number|date} [nowTime] optional now time
 * @returns {boolean} program on air state
 */
export const isOnAir = (start, end, nowTime = undefined) => {
    const _now = (nowTime ? ctime(nowTime) : ctime()).time();

    const st = ctime(start).time();
    const et = ctime(end).time();
    return et > _now && st < _now;
};

/**
 * Calculates progress out of timestamps
 *
 * @param {string|number|date} start
 * @param {string|number|date} end
 * @param {string|number|date} [currentTs] Optional now time
 * @returns {number} progress range percent
 */
export const progress = (start, end, currentTs = undefined) => {
    const _currentTs = currentTs ? ctime(currentTs) : now();
    const _start = ctime(start);
    const _end = ctime(end);

    if (_currentTs.unix() < _start.unix() || !start || !end) {
        return 0;
    }

    const secondsPlayed = _start.diff(ctime(currentTs));
    const _duration = _start.diff(ctime(_end));

    return Math.min((100 / _duration) * secondsPlayed, 100);
};

export const timeStr = (h = 0, m = 0, s = 0, suffix = 'Uhr') => {
    const time = [];

    if (h >= 0) {
        time.push(leadingZero(h));
    }
    if (m >= 0) {
        time.push(leadingZero(m));
    }
    if (s >= 0) {
        time.push(leadingZero(s));
    }

    return `${time.join(':')}${suffix && ` ${suffix}`}`;
};

/**
 * Returns a time string in format:
 * <HH:mm:ss> or <mm:ss>
 *
 * @param {any} t
 * @param {boolean} short toggle short representation
 * @returns {string} <HH:mm:ss|mm:ss>
 */
export const timeStrFormat = (t, short = false) => {
    return timeStr(!short ? t.hour : -1, t.minute, t.second, '');
};

/**
 * Returns a date string in format:
 * <06. Apr>
 *
 * @param {0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11} month
 * @param {0 | 1 | 2 | 3 | 4 | 5 | 6} day Day of week number
 * @returns {string} <DD. mm>
 */
export const dateStr = (month, day) => {
    return `${leadingZero(day)}. ${LO_MONTH[month - 1].substring(0, 3)}`;
};

export const dateStrNumric = (year = -1, month = -1, day = -1) => {
    const dateArr = [];

    if (day >= 0) {
        dateArr.push(leadingZero(day));
    }
    if (month >= 0) {
        dateArr.push(leadingZero(month));
    }
    if (year >= 0) {
        dateArr.push(year);
    }

    return `${dateArr.join('.')}`;
};

/**
 * Returns a custom ISO8610 format specified by service.
 *
 * Example output:
 * <YYYY-MM-DDTHH:mm:ss>
 *
 * @param {any} t
 * @returns {string} <HH:mm:ss|mm:ss>
 */
export const iso8610Custom = (t) => {
    return `${leadingZero(t.year)}-${leadingZero(t.month)}-${leadingZero(t.day)}T${timeStrFormat(
        t,
    )}`;
};

/**
 * fractional diff days
 * @param {string|number|date} t1
 * @param {string|number|date} t2
 * @returns {number} difference in days
 */
export const diffInDays = (t1, t2) => {
    return diffInMinutes(t1, t2) / 60 / 24;
};

/**
 * Diffs two timestamps and returns the positive difference
 * between them in minutes. (Rounds up to a full minute).
 *
 * @param {string|number|date} t1
 * @param {string|number|date} t2
 * @returns {number} difference in minutes
 */
export const diffInMinutes = (t1, t2) => {
    const _t1 = ctime(t1);
    const _t2 = ctime(t2);

    return Math.ceil(_t1.diff(_t2) / 60); // always round up to one minute
};

/**
 * Diffs two timestamps and returns the difference between them in seconds.
 *
 * @param {string|number|date} t1
 * @param {string|number|date} [t2] (default: now())
 * @returns {number} difference in seconds
 */
export const diffInSeconds = (t1, t2 = now()) => Math.ceil(ctime(t1).diff(ctime(t2)));

/**
 * This function detects the timedifference between startTime and
 * stopTime decides how to return the difference in minutes or seconds
 * according to the boolean `min`.
 *
 * @param {string|number|date} start time
 * @param {string|number|date} end time
 * @param {boolean} min format duration to minutes before return
 * @returns {number} duration in minutes or seconds
 */
export const dateDuration = (start, end, min = false) => {
    const _start = unix(start);
    const _end = unix(end);

    return min ? diffInMinutes(_start, _end) : diffInSeconds(_start, _end);
};

/**
 * This formatter function detects whenever a date is "onAir" and
 * decides how to render the EPG Information accordingly.
 *
 * @param {string|number|date} start start time
 * @param {string|number|date} end end time
 * @param {boolean} [short] display short EPG information if program is not "onAir" (true: 16:44 Uhr ⋅ 50 Min. | false: 16:46 Uhr 01.Dez ⋅ 50 Min.)
 * @param {string|number|date} [nowTime] optional now time
 * @returns {string} onAir String: Seit 16:44 Uhr ⋅ noch 50 Min.
 */
export const programPlayoutInfo = (start, end, short = false, nowTime = undefined) => {
    const _s = ctime(start);
    const _e = ctime(end);
    const _st = _s.unix();
    const _et = _e.unix();
    const _now = nowTime ? ctime(nowTime) : ctime();
    const _n = _now.unix();
    const onAir = isOnAir(_st, _et, nowTime);

    return _s.format((t) => {
        const time = timeStr(t.hour, t.minute, -1);
        return onAir
            ? `Seit ${time} ⋅ noch ${diffInMinutes(_n, _et)} Min.`
            : `${time}${short ? '' : ` ${dateStr(t.month, t.day)}`} ⋅ ${_s.diff(_e) / 60} Min.`;
    });
};

/**
 * Example Output:
 * <20:15 Uhr ⋅ 120 Min.>
 *
 * @param {string|number|date} start date time string, unix-timestamp, native date object
 * @param {number} duration in min
 * @returns {string} <{start}HH:mm [Uhr] ⋅ {duration}mm [Min.]>
 */
export const epgOverviewDateFormat = (start, duration) => {
    return `${ctime(start).format((t) => timeStr(t.hour, t.minute, -1))} ⋅ ${duration} Min.`;
};

/**
 * Example Output:
 * <Freitag, 17.05.2019 ⋅ 20:15 Uhr ⋅ 120 Min.>
 *
 * @param {string|number|date} start date time string, unix-timestamp, native date object
 * @param {number} duration in min.
 * @returns {string} <dddd, DD.MM.YYYY ⋅ HH:mm [Uhr] ⋅ {duration}mm [Min.]>
 */
export const epgDetailsDateFormat = (start, duration) => {
    return ctime(start).format((t) => {
        const dayname = weekday(t.wday);
        const datePart = dateStrNumric(t.year, t.month, t.day);
        const time = epgOverviewDateFormat(start, duration);

        return `${dayname}, ${datePart} ⋅ ${time}`;
    });
};

/**
 * Example Output:
 * <23.02.20 ⋅ 17:45>
 *
 * @param {string|number|date} time date time string, unix-timestamp, native date object
 * @returns {string} <DD.MM.YY ⋅ HH:mm>
 */
export const epgDateTime = (time) => {
    const start = ctime(time);
    return `${start.format(
        (t) =>
            `${dateStrNumric(`${t.year}`.substr(2, 2), t.month, t.day)} ⋅ ${timeStr(
                t.hour,
                t.minute,
                -1,
                '',
            )}`,
    )}`;
};

/**
 * Example Output:
 * <13 Std. 7 Min.>
 *
 * @param {number} maximumStorageInSeconds
 * @param {number} usedStorageInSeconds
 * @returns {string | undefined} <HH Std. mm Min.>
 */
export const remainingStorageInHoursAndMinutes = (
    maximumStorageInSeconds,
    usedStorageInSeconds,
) => {
    if (!Number.isInteger(maximumStorageInSeconds) || !Number.isInteger(usedStorageInSeconds)) {
        return undefined;
    }

    if (maximumStorageInSeconds - usedStorageInSeconds < 0) {
        return '0 Std.';
    }

    const hourInSeconds = 3600;

    const maxStorage = Math.max(maximumStorageInSeconds, 0);
    const usedStorage = Math.max(usedStorageInSeconds, 0);

    const remainingHours = Math.floor((maxStorage - usedStorage) / hourInSeconds);
    const remainingMinutes = Math.round(((maxStorage - usedStorage) % hourInSeconds) / 60);
    const showMinutes = inRange(remainingMinutes, 0, 60);

    return `${remainingHours} Std.${showMinutes ? ` ${remainingMinutes} Min.` : ''}`;
};

/**
 * Example Output:
 * <13 Std.>
 *
 * @param {number} maximumStorageInSeconds
 * @param {number} usedStorageInSeconds
 * @returns {number | undefined}
 */
export const remainingStorageInHours = (maximumStorageInSeconds, usedStorageInSeconds) => {
    if (!Number.isInteger(maximumStorageInSeconds) || !Number.isInteger(usedStorageInSeconds)) {
        return undefined;
    }

    if (maximumStorageInSeconds - usedStorageInSeconds < 0) {
        return 0;
    }

    const hourInSeconds = 3600;

    const maxStorage = Math.max(maximumStorageInSeconds, 0);
    const usedStorage = Math.max(usedStorageInSeconds, 0);

    return Math.floor((maxStorage - usedStorage) / hourInSeconds);
};

/**
 * Example Output:
 * <55 Min.>
 *
 * @param {string|number|date} startTime date time string, unix-timestamp, native date object
 * @param {string|number|date} stopTime date time string, unix-timestamp, native date object
 * @returns {string} <mm Min.>
 */
export const epgDuration = (startTime, stopTime) => {
    const start = ctime(startTime);
    const stop = ctime(stopTime);
    const duration = diffInMinutes(start, stop);
    return `${duration} Min.`;
};

/**
 * Example Output:
 * <Seit 12:30 Uhr ⋅ noch 60 Min.>
 *
 * @param {string|number|date} start date time string, unix-timestamp, native date object
 * @param {string|number|date} end date time string, unix-timestamp, native date object
 * @param {any} _timestamp
 * @returns {string} <[Seit] HH:mm [Uhr] ⋅ noch mm [Min.]>
 */
export const epgDetailsLiveDateFormat = (start, end, _timestamp) => {
    const time = ctime(start).format((t) => timeStr(t.hour, t.minute, -1));
    const _now = (_timestamp && _timestamp.unix()) || ctime().unix();
    const _end = ctime(end).unix();
    const remainder = diffInMinutes(_now, _end) * (_now > _end ? -1 : 1);

    return `Seit ${time} ⋅ noch ${remainder >= 0 ? remainder : 0} Min.`;
};

/**
 * Example Output:
 * <Freitag, 17.05.> or <Fr., 17.05.> or <17.05.>
 *
 * @param {any} t
 * @param {boolean} [_weekday]
 * @param {boolean} [shortWeekday]
 * @returns {string} <dddd, DD.MM.|DD.MM.>
 */
export const weekdayAndDateFormat = (t, _weekday = true, shortWeekday = false) => {
    return `${_weekday ? `${weekday(t.wday, shortWeekday)}, ` : ''}${dateStrNumric(
        -1,
        t.month,
        t.day,
    )}.`;
};

/**
 * Example Output:
 * <15:00 — 16:00 Uhr>
 *
 * @param {string|number|date} start date time string, unix-timestamp, native date object
 * @param {string|number|date} end date time string, unix-timestamp, native date object
 * @returns {string} <{start}HH:mm - {end}HH:mm [Uhr]>
 */
export const epgLiveDuration = (start, end) => {
    return `${ctime(start).format((t) => timeStr(t.hour, t.minute, -1, ''))} - ${ctime(end).format(
        (t) => timeStr(t.hour, t.minute, -1),
    )}`;
};

/**
 * Example Output:
 * <Fr. 17.05 ⋅ 01:20 Uhr>
 *
 * @param {any} t
 * @returns {string} <dd. DD.MM. ⋅ HH:mm [Uhr]>
 */
export const searchResultFormat = (t) => {
    return `${weekday(t.wday, true)} ${weekdayAndDateFormat(t, false)} ⋅ ${timeStr(
        t.hour,
        t.minute,
        -1,
        'Uhr',
    )}`;
};

/**
 * Example Output:
 * <05:20 ⋅ 20 Min.> | <00:05:12 ⋅ 60 Min.>
 *
 * @param {string|number|date} start date time string, unix-timestamp, native date object
 * @param {string|number|date} end date time string, unix-timestamp, native date object
 * @param {string|number|date} _timestamp date time string, unix-timestamp, native date object
 * @returns {string} <HH:mm ⋅ {duration}mm Min.> | <HH:mm:ss ⋅ {duration}mm Min.>
 */
export const playbackInfo = (start, end, _timestamp) => {
    const _start = ctime(start);
    const _end = ctime(end);
    const duration = diffInMinutes(_start, _end);
    const played = ctime().startOf('day').set(ctime(_timestamp).diff(_start), 's');

    return `${played.format((t) =>
        timeStrFormat(t, /* short */ !(duration >= 60)),
    )} ⋅ ${duration} Min.`;
};

/**
 * Example Output:
 * <von Mi. 12.10.>
 *
 * @param {any} t
 * @returns {string} <von dd. DD.MM.>
 */
export const formatAirTime = (t) =>
    `von ${weekday(t.wday, true)} ${weekdayAndDateFormat(t, false)}`;

/**
 * Distinguish a static timestamp from a dynamic timestamp.
 * Reference boundary for a static timestamp is 1970/01/01 and 1970/01/30.
 *
 * @param {number} ts
 * @returns {boolean}
 */
export const isTimestamp = (ts) => ts > 30 * 24 * 60 * 60 || ts < 0;

/**
 * ttl check against now reference in consideration of threshold value
 * @param  {string|number|date} ttl
 * @param  {string|number|date} [nowRef] 60 sec. threshold
 * @param  {number} [threshold]
 * @return {boolean}
 */
export const isTTLValid = (ttl, nowRef = now(), threshold = 60) => {
    return unix(ttl) - threshold > unix(nowRef);
};

/**
 * Detect local GMT offsets accuracy should be close to one second
 *
 * @param {number} [timeout]
 * @param {string} [timeserver] Url of the timeserver (default: time.akamai.com)
 * @param {(res: Response) => Promise<number>} [responseHandler]
 * @returns {Promise<number>} timestamp negative ahead of GMT / positive behind GMT
 */
export const localGMTOffset = async (
    timeout = 1500,
    timeserver = 'https://time.akamai.com',
    responseHandler = (res) => res.text().then((val) => parseInt(val, 10)),
) => {
    const timestamp = await timeoutPromise(
        timeout,
        fetch(timeserver).catch(() => {}), // silence any request errors and catch and handle any error case in timeout promise
    )
        .then(responseHandler)
        .catch(() => 0);

    const localNow = ctime().unix();
    const behind = timestamp > localNow;

    const diff = ctime(timestamp).diff(ctime(localNow)); // cast legacy timestamp (seconds) to modern (seconds+ms)
    return behind ? diff * -1 : diff;
};

/**
 * Adds or subtracts given seconds to or from date
 *
 * @param {date} _date date
 * @param {number} [offsetValueInSeconds] seconds
 * @returns {date} date
 */
export const dateWithOffset = (_date, offsetValueInSeconds = 0) =>
    date(_date).add(offsetValueInSeconds, 's');

/**
 * Example Output:
 * <22.02.2024 ⋅ 05:20 Uhr ⋅ >
 *
 * @param {string|number|date} dateStr date time string, unix-timestamp, native date object
 * @param {boolean} withSeparator date time string, unix-timestamp, native date object
 * @returns {string} <DD.MM.YYYY ⋅ HH:mm Uhr ⋅ >
 */
export const formatDateTime = (dateString, withSeparator) => {
    if (!dateString) {
        return '';
    }

    const cTimeDate = ctime(dateString);
    const formattedDate = cTimeDate.format(
        (t) => `${dateStrNumric(t.year, t.month, t.day)} ⋅ ${timeStr(t.hour, t.minute, -1)}`,
    );

    return `${formattedDate}${withSeparator ? ' ⋅ ' : ''}`;
};

/**
 * Example Output:
 * <22.02.2024>
 *
 * @param {string|number|date} dateStr date time string, unix-timestamp, native date object
 * @returns {string} <DD.MM.YYYY>
 */
export const formatDate = (dateString) => {
    if (!dateString) {
        return '';
    }

    const cTimeDate = ctime(dateString);
    const formattedDate = cTimeDate.format((t) => dateStrNumric(t.year, t.month, t.day));

    return formattedDate;
};
