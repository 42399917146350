/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { firstCharToLower, isString } from '../string';

/**
 * Check if the given value is an object, in the sense of being a Record,
 * instead of null or an array.
 */
export function isObject(value) {
    return (
        // eslint-disable-next-line no-restricted-syntax
        value !== undefined && value !== null && typeof value === 'object' && !Array.isArray(value)
    );
}

/**
 * Deep merge two objects.
 * @param {Record<PropertyKey, any>} target
 * @param {Array<Record<PropertyKey, any>>} sources
 */
export function merge(target, ...sources) {
    if (!sources.length) {
        return target;
    }

    const objectMapper = (key, source) => {
        if (isObject(source[key])) {
            if (!target[key]) {
                Object.assign(target, { [key]: {} });
            }

            merge(target[key], source[key]);
        } else if (Array.isArray(source[key])) {
            Object.assign(target, { [key]: [].concat(source[key]) });
        } else {
            Object.assign(target, { [key]: source[key] });
        }
    };

    const source = sources.shift();
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach((key) => {
            objectMapper(key, source);
        });
    }

    return merge(target, ...sources);
}

/**
 * JSON-Stringifies string values inside an object, also in deeper layers
 * (needed for the webpack-define-plugin to work with the resulting object)
 *
 * @param {Record<PropertyKey, any>} object
 */
export const stringifyValues = (object) => {
    const _object = { ...object };
    const properties = Object.keys(_object);

    for (let i = 0; i < properties.length; i += 1) {
        const currentValue = _object[properties[i]];

        if (typeof currentValue === 'string') {
            _object[properties[i]] = JSON.stringify(currentValue);
        } else if (isObject(object[properties[i]])) {
            _object[properties[i]] = stringifyValues(currentValue);
        }
    }
    return _object;
};

/**
 * Filter object
 */
export const filterObject = (source, filter) =>
    Object.fromEntries(Object.entries(source).filter(filter));

/**
 * Remove null/undefined fields from given object
 *
 * @param {Record<PropertyKey, any>} source
 * @returns {Record<PropertyKey, NonNullable<any>>}
 */
export const removeEmpty = (source) =>
    // eslint-disable-next-line no-restricted-syntax
    filterObject(source, ([, v]) => v !== null && v !== undefined);

/**
 * Transformes object attributes to transformer return value
 *
 * @param  {Record<PropertyKey, any>} obj
 * @returns object
 */
export const attributesTransform = (obj, transformer = firstCharToLower) =>
    Object.keys(obj).reduce((acc, curr) => {
        acc[transformer?.(curr) || curr] = obj[curr];
        return acc;
    }, {});

export const isEmptyObject = (obj) =>
    isObject(obj) &&
    Object.getPrototypeOf(obj) === Object.prototype &&
    Object.keys(obj).length === 0;

export const hasProperty = (value, prop) => isObject(value) && Object.hasOwn(value, prop);

export const hasProperties = (value, props) =>
    isObject(value) && props.every((prop) => Object.hasOwn(value, prop));

export const isObjEqual = (a, b) =>
    isObject(a) &&
    isObject(b) &&
    Object.keys(a).length === Object.keys(b).length &&
    Object.entries(a).reduce((last, [key, valueA]) => {
        if (last === false) {
            // One element failed -> so everything failes.
            return false;
        }
        const valueB = b[key];
        return isObject(valueA) && isObject(valueB)
            ? isObjEqual(valueA, valueB)
            : valueA === valueB;
    }, true);

/**
 * Support function for {@link getDisplayableListRepresentation}
 *
 * @param {string} name
 * @param {*} value
 * @returns {{ name: string; value: string }}
 */
const getDisplayableListEntry = (name, value) =>
    isString(value) ? { name, value } : { name, value: JSON.stringify(value) };

/**
 * Transforms an object into displayable list of object properties including first layer of nesting.
 * Deeper levels of nesting will be stringified using JSON.stringify.
 * Example: {a: 'A', b: { c: 'C' }} transforms to [{name: 'a', value: 'A'}, {name: 'b - c', value: 'C'}]
 *
 * @param {Object.<string, *>} data
 * @param {string[]} [blacklist]
 * @returns {{ name: string; value: string }[]}
 */
export const getDisplayableListRepresentation = (data, blacklist) =>
    isObject(data)
        ? Object.keys(data).reduce((reduced, key) => {
              if (blacklist && blacklist.includes(key)) {
                  return reduced;
              }

              const value = data[key];

              if (isObject(value)) {
                  const valueKeys = Object.keys(value);
                  return [
                      ...reduced,
                      ...(blacklist
                          ? valueKeys.filter((k) => !blacklist.includes(k))
                          : valueKeys
                      ).map((nestedKey) =>
                          getDisplayableListEntry(`${key} - ${nestedKey}`, value[nestedKey]),
                      ),
                  ];
              }

              return [...reduced, getDisplayableListEntry(key, value)];
          }, [])
        : [];
