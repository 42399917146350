/*
 *  Copyright (C) Exaring AG - All Rights Reserved
 */

import { localStorageItem } from '@exaring/utils';
import { StoreSlice } from '../utils/StoreSlice';

export type State = {
    previousRoute: string;
    showBuildInfo: boolean;
    isSearchActive: boolean;
    useRecordingsV1: boolean;

    setPreviousRoute: (val: string) => void;
    setShowBuildInfo: (val: boolean) => void;
    toggleSearchActive: (val?: boolean) => void;
};

export const initialState = {
    previousRoute: '',
    showBuildInfo: false,
    isSearchActive: false,
    useRecordingsV1: !!localStorageItem('useRecordingsV1'),
};

export const State: StoreSlice<State> = (set) => ({
    ...initialState,

    setPreviousRoute(val) {
        set((state) => {
            state.previousRoute = val;
        }, 'UI/setPreviousRoute');
    },

    setShowBuildInfo(val) {
        set((state) => {
            state.showBuildInfo = val;
        }, 'UI/setShowBuildInfo');
    },

    toggleSearchActive(val) {
        set((state) => {
            state.isSearchActive = val === undefined ? !state.isSearchActive : val;
        }, 'UI/toggleSearchActive');
    },
});
