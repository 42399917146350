import { useEffect } from 'preact/hooks';
import { useEpgStore } from '../../../state/Store';

export const useEpgInit = () => {
    const { epgStations, epgInfo, fetchEpgStations, fetchEpgInfo } = useEpgStore();
    useEffect(() => {
        // init epg grid info update
        if (epgInfo.state === 'NotAsked') {
            fetchEpgInfo();
        }

        if (epgStations.state === 'NotAsked') {
            fetchEpgStations();
        }
        // NOTE retry logic recommendation: https://exaring.atlassian.net/wiki/spaces/TM/pages/3041460401/EPG+2.0+Client+Integration#HTTP-Vorgaben-f%C3%BCr-Clients
    }, [fetchEpgStations, fetchEpgInfo, epgInfo.state, epgStations.state]);
};
