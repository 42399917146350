/* eslint-disable no-restricted-syntax */

export const ProgramV1 = {
    // default
    id: { type: 'string', default: '' },
    channel: { type: 'string', default: '' },
    title: { type: 'string', default: '' },
    episodeTitle: { type: 'string', default: '' },
    stopTime: { type: 'date', default: null },
    startTime: { type: 'date', default: null },
    genre: { type: 'string', default: null },
    genreDisplayName: { type: 'string', default: null },
    previewImages: { type: 'array', default: [] },
    // additional Info
    episode: { type: 'number', default: null },
    season: { type: 'number', default: null },
    duration: { type: 'number', default: 0 },
    description: { type: 'string', default: '' },
    year: { type: 'number', default: null },
    country: { type: 'string', default: '' },
    actors: { type: 'array', default: [] },
    links: { type: 'array', default: [] },
    channelDisplay: { type: 'string', default: '' },
    // TVFuse
    streamUrlProvider: { type: 'url', default: null },
    // serialRecording available
    series: { type: 'number', default: null },
    // permissions recording
    recordingForbidden: { type: 'boolean', default: false },
    recordingSeekingForbidden: { type: 'boolean', default: false },
    // permissions instant restart & pause
    instantRestartForbidden: { type: 'boolean', default: false },
    pauseForbidden: { type: 'boolean', default: false },
    // Sharpstart
    refinedStartTime: { type: 'date', default: '' },
    refinedStopTime: { type: 'date', default: '' },
    // BS Junk
    pdcDescriptor: { type: 'string', default: null },
    // showOrder
    prevId: { type: 'string', default: '' },
    assetId: { type: 'string', default: '' },
    rerunId: { type: 'string', default: '' },
    nextId: { type: 'string', default: '' },
    episodeId: { type: 'string', default: '' },
    parentalGuidance: { type: 'string', default: '' },
};

export const ProgramV2 = {
    id: { type: 'string', default: '' },
    channel: { type: 'string', default: '' },
    title: { type: 'string', default: '' },
    episodeTitle: { type: 'string', default: '' },
    stopTime: { type: 'date', default: null },
    startTime: { type: 'date', default: null },
    genre: { type: 'string', default: null },
    genreDisplayName: { type: 'string', default: null },
    previewImages: { type: 'array', default: [] },
    // permissions recording
    recordingForbidden: { type: 'boolean', default: false },
    recordingSeekingForbidden: { type: 'boolean', default: false },
    // permissions instant restart & pause
    instantRestartForbidden: { type: 'boolean', default: false },
    pauseForbidden: { type: 'boolean', default: false },
    // TVFuse
    streamUrlProvider: { type: 'url', default: null },
    ageRestriction: { type: 'number', default: 0 },
    pinRequired: { type: 'boolean', default: false },
};
