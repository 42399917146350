/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */
import { hashCode } from '../helper';

export function mapActions(actions, _store) {
    const newActions = typeof actions === 'function' ? actions(_store) : actions;
    const mapped = {};

    Object.keys(newActions).forEach((i) => {
        mapped[i] = _store.action(newActions[i]);
    });

    return mapped;
}

const hashFn = (fn) => {
    return hashCode(`${fn}`);
};

export const hasAction = (store, fn) => store.allActions.includes(hashFn(fn));

export const safeAsync = async (store, state, callback, ...args) => {
    // if the state object contains only one key that means that a synchronous action called this
    // asynchronous one. Only in this case we need to persist state!
    if (state && Object.keys(state).length === 1) {
        store.setState(state); // persist synchronous state
    }

    // if: callback function is an action
    if (hasAction(store, callback)) {
        // apply state as first argument and return updated state
        return [await callback(state, ...args)];
    }

    // else: callback is either a unistore bound action or any other (async) function
    // return updated state and callback return value
    const result = await callback(...args);
    return [store.getState(), result];
};

/* eslint-disable no-param-reassign */
const setup = (store, scopes = {}) => {
    if (!store) {
        return;
    }

    // add safeAsync helper to store
    store.safeAsync = (...args) => safeAsync(store, ...args);

    store.api = {};
    store.allActions = [];

    Object.keys(scopes).forEach((scope) => {
        const actions = scopes[scope];
        const actionFunctions = typeof actions === 'function' ? actions(store) : actions;
        const boundActions = mapActions(actionFunctions, store);

        // make actions available through `store.api.$scope.$actionName()`
        store.api[scope] = boundActions;

        // create allActions arrays, which is needed for hasAction helper
        store.allActions = [...store.allActions, ...Object.values(actionFunctions).map(hashFn)];
    });
};

export const unistoreSetup = setup;
/* eslint-enable no-param-reassign */
