import { h, FunctionComponent } from 'preact';

import { formatSecondsDuration } from '@exaring/utils';

import {
    CircleIcon,
    MoreArrow,
    StyledMenu,
    TrashcanIcon,
} from '@exaring/ui/components-styled/Tile/parts/StyledMenu';
import { Colors, styled } from '@exaring/ui/components-styled/theme';
import { MediumTile } from '@exaring/ui/components-styled/Tile';
import { Recording } from '@exaring/networking/types/Recording/Recording';
import { StyledTileContent } from '@exaring/ui/components-styled/Tile/parts/StyledTileContent';
import { RectangleSkeleton } from '@exaring/ui/components-styled/Skeleton/RectangleSkeleton';
import { RecordingStatusBadge } from '../Tile/TileHero/TileHero';
import { epgInfoShort } from '../../helper';

const RecordingGridStyled = styled('div', {
    display: 'grid',
    'grid-template-columns': 'repeat(auto-fill, minmax(204px, .5fr))',

    marginTop: '$$sectionGutter',
    columnGap: '$$gutterWidth',
    rowGap: '$$gutterWidth',

    '&:first-child': {
        marginTop: 0,
    },
});
const RecordingTileContextMenu = styled(StyledMenu, {
    visibility: 'hidden',
    variants: {
        mode: {
            default: {
                visibility: 'hidden',
            },
            editMode: {
                visibility: 'visible',
            },
        },
    },
});

const RecordingTile = styled(MediumTile, {
    position: 'relative',
    transition: 'transform 0.2s',

    '&:hover': {
        transform: 'scale(1.05)',

        [`${StyledTileContent}`]: {
            background: '$charcoalGrey',
        },
    },
});

export type ItemContextCallback = (
    recordingId: string,
    recordingGroup?: number,
    locked?: boolean,
    isGridRecording?: boolean,
) => (event: MouseEvent | TouchEvent) => void;

const contentTileMapper = (
    recording: Recording,
    isContentSelected: (id: string) => boolean,
    onSelectContent: ItemContextCallback,
    onPlayContent?: ItemContextCallback,
    onDeleteContent?: ItemContextCallback,
    onOpenDetails?: ItemContextCallback,
    editMode?: boolean,
): JSX.Element | undefined => {
    const isSerialRecording = recording.recordingGroup !== undefined;
    // eslint-disable-next-line no-template-curly-in-string
    const imageSrc = recording.previewImage?.replace('${resolution}', 'm');

    const isPlayable = recording.status === 'FINISHED' || recording.status === 'RECORDING';

    return (
        <RecordingTile
            css={{
                margin: 0, // this style overrides must be defined here otherwise they do not override all responsive styles :/
                width: '100%',
                maxWidth: 'inherit',
            }}
            contentStyles={{
                [`&:hover ${RecordingTileContextMenu}`]: {
                    visibility: 'visible',
                },
            }}
            title={recording.title}
            startTime={recording.epgStartTime}
            duration={formatSecondsDuration(recording.durationSeconds)}
            noMediaOverlay={isSerialRecording || !isPlayable}
            onMediaPlayout={
                isSerialRecording || !isPlayable
                    ? onOpenDetails?.(recording.id, recording.recordingGroup, recording.locked)
                    : onPlayContent?.(recording.id, recording.recordingGroup)
            }
            onOpenDetails={onOpenDetails?.(
                recording.id,
                recording.recordingGroup,
                recording.locked,
            )}
            locked={recording.locked}
            channel={recording.stationDisplay}
            genre={epgInfoShort(
                recording.genreDisplayName ?? undefined,
                recording.season ? recording.season.toString() : undefined,
                recording.episode ? recording.episode.toString() : undefined,
            )}
            imageSrc={imageSrc}
            progressbarPosition={
                recording?.recordingGroup === undefined ? recording.positionPercentage : undefined
            }
        >
            <RecordingStatusBadge
                recording={{ status: recording?.status, isNew: recording?.isNew }}
            />

            <RecordingTileContextMenu mode={editMode ? 'editMode' : 'default'}>
                {editMode ? (
                    <CircleIcon
                        onClick={() => {
                            onSelectContent?.(recording.id, recording.recordingGroup);
                        }}
                        checked={isContentSelected(recording.id)}
                    />
                ) : (
                    <TrashcanIcon
                        onClick={onDeleteContent?.(
                            recording.id,
                            recording.recordingGroup,
                            recording.locked,
                            recording.recordingGroup !== undefined,
                        )}
                        hover
                    />
                )}
            </RecordingTileContextMenu>

            {recording?.recordingGroup && (
                <MoreArrow
                    css={{ position: 'absolute', right: '10px', bottom: '15px' }}
                    width="100%"
                    height="100%"
                    size="medium"
                    color={Colors.white}
                />
            )}
        </RecordingTile>
    );
};

export const RecordingGrid: FunctionComponent<{
    recordings: Recording[];
    isContentSelected: (id: string) => boolean;
    onSelectContent: ItemContextCallback;
    onPlayContent?: ItemContextCallback;
    onDeleteContent?: ItemContextCallback;
    onOpenDetails?: ItemContextCallback;
    editMode?: boolean;
    loading?: boolean;
}> = ({
    recordings,
    isContentSelected,
    onSelectContent,
    onPlayContent,
    onDeleteContent,
    onOpenDetails,
    editMode,
    loading,
}) => {
    return (
        <RecordingGridStyled>
            {loading
                ? [...Array(10).keys()].map((it) => (
                      <RectangleSkeleton
                          key={it}
                          css={{
                              borderRadius: '$1',
                              height: '295px',
                          }}
                      />
                  ))
                : recordings.map((item) =>
                      contentTileMapper(
                          item,
                          isContentSelected,
                          onSelectContent,
                          onPlayContent,
                          onDeleteContent,
                          onOpenDetails,
                          editMode,
                      ),
                  )}
        </RecordingGridStyled>
    );
};
