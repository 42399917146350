/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';
import {
    epgDetailsLiveDateFormat,
    searchResultFormat,
    date,
    classnames,
    strj,
} from '@exaring/utils';
import { isOnAir } from '@exaring/utils/data/program';
import { styled } from '@exaring/ui/components-styled/theme';
import { useEpgStore } from '../../state/Store';

const SearchResultListStyled = styled('ul', {
    $$headerHeightTotal: '66px',
    position: 'fixed',
    width: '100%',
    height: 'calc(100% - $$headerHeightTotal)',
    top: '$$headerHeightTotal',
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    zIndex: '$searchResult',
    overflowY: 'scroll',
    textAlign: 'left',
});

const labelWithPlayoutTime = (startTime, stopTime, _isOnAir = false) => {
    if (_isOnAir) {
        return epgDetailsLiveDateFormat(date(startTime), date(stopTime));
    }

    return date(startTime).format(searchResultFormat);
};

const matchTpl = (match) => {
    return <span className="marked">{match}</span>;
};

// Tags which do not appear in this object will be ignored in the results
const matchTypes = {
    actors: 'Schauspieler',
    genre: 'Genre',
};

const SearchResultItem = ({ metaData, title, subtitle, onItemClick, selected }) => {
    const matchedMetaData = metaData && metaData.matches;
    const tags = [];

    if (matchedMetaData) {
        matchedMetaData.forEach((match) => {
            if (matchTypes[match.field]) {
                tags.push(<span>{matchTypes[match.field]}: </span>);
                match.values.forEach((tag) => {
                    tags.push(<span className="search-result__item__matches__tag">{tag}</span>);
                });
            }
        });
    }

    return (
        <li
            className={classnames('search-result__item', selected && 'selected')}
            onClick={onItemClick}
        >
            <h2 className="search-result__item__title">{title}</h2>
            {(tags.length && <div className="search-result__item__matches">{tags}</div>) || ''}
            <span className="search-result__item__subtitle">{subtitle}</span>
        </li>
    );
};

export const renderMatch = (title, query = '', templateCb) => {
    const re = new RegExp(/[(\w)\u00C0-\u00ff.,]+/gi); // explicit white list of words
    const escapedQuery =
        query
            .match(re)
            ?.join('|')
            .replace(/[-[\]{}()*+?.,\\^$]/gi, '\\$&') || '';

    const reQuery = new RegExp(escapedQuery, 'ig');
    const matches = title.match(reQuery) || [];
    const imatches = title.split(reQuery) || [];
    const result = [];
    let i = 0;
    const len = imatches.length;

    const render = typeof templateCb === 'function';

    while (i < len) {
        imatches[i] && result.push(imatches[i]);
        matches[i] && result.push((render && templateCb(matches[i])) || matches[i]);
        i += 1;
    }

    return result;
};

const SearchResultList = ({ resultsRef, items, query, onItemClick, selectedResult }) => {
    const { getStationById } = useEpgStore();
    return (
        <SearchResultListStyled ref={resultsRef} className="search-result">
            {items.map((result, index) => {
                let subtitle;
                const { program, metaData } = result;
                const { startTime, stopTime, title, isChannel, stationId } = program;
                const _isOnAir = startTime && stopTime ? isOnAir(program) : false;
                const renderedMatch = renderMatch(title, query, matchTpl);
                const station = getStationById(stationId);

                if (!isChannel) {
                    subtitle = strj(
                        labelWithPlayoutTime(startTime, stopTime, _isOnAir),
                        station?.displayName, // omit channel name if channel could not be mapped
                        ' - ',
                    );
                }

                return (
                    <SearchResultItem
                        onItemClick={(e) => {
                            e.item = {
                                ...program,
                                isOnAir: _isOnAir,
                            };
                            onItemClick(e);
                        }}
                        title={renderedMatch}
                        subtitle={subtitle}
                        metaData={metaData}
                        selected={selectedResult === index}
                    />
                );
            })}
        </SearchResultListStyled>
    );
};

export default SearchResultList;
