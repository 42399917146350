/* eslint-disable no-template-curly-in-string */
import { isObject, isString, isBoolean } from '@exaring/utils';
import { Channel as PlanByChannel } from '@nessprim/planby/dist/Epg/helpers/interfaces';
import { EpgStation } from './Stations';

type StreamQuality = 'hd' | 'sd';
type TemplateString = string; // @todo make more concrete type
type MediaCenter = {
    micrositeUrl: string;
    apiUrl: string;
};
type ParentalGuidance = 'fsk-12' | 'fsk-16' | 'fsk-18';
type AgeRating = {
    pinRequired: boolean;
    parentalGuidance: ParentalGuidance;
};

type Restrictions = {
    pauseForbidden: boolean;
    recordingForbidden: boolean;
    recordingSeekingForbidden: boolean;
    instantRestartForbidden: boolean;
    seekingForbidden: boolean;
    mobileOptionRequired: boolean;
    mobileLiveTVForbidden: boolean;
    mobileRecordingPlaybackOptionRequired: boolean;
    mobileRecordingPlaybackForbidden: boolean;
};

interface Station {
    id: string;
    displayName: string;
    streamQualities: Array<StreamQuality>;
    logoTemplateUrl: TemplateString;
    mediaCenter: MediaCenter;
    ageRating: AgeRating;
    restrictions: Restrictions;
}

interface StationConfig {
    stations: Array<Station>;
}

export type Channel = LoadingChannel | StationChannel;

interface LoadingChannel extends PlanByChannel {
    type: 'loading';
    animated?: boolean;
}

export interface StationChannel extends PlanByChannel, Station {
    type: 'station';
    enumeration?: number;
    favorite?: boolean;
    visible?: boolean;
    locked?: boolean;
    omitted?: boolean;
    availableStreamQuality: StreamQuality;
}

export const isLoadingChannel = (value: unknown): value is LoadingChannel =>
    isObject(value) && value.type === 'loading';

export const isStationChannel = (value: unknown): value is StationChannel =>
    isObject(value) && value.type === 'station';

const isValidJson = (value: unknown): value is StationConfig => {
    if (isObject(value) && Array.isArray(value.stations)) {
        const firstEntry = value.stations[0];
        const { id, logoTemplateUrl, restrictions } = firstEntry as Station;
        return isString(id) && isString(logoTemplateUrl) && isBoolean(restrictions?.pauseForbidden);
    }

    return false;
};

const getLogo = (templateUrl: string, streamQuality: StreamQuality) => {
    let url = templateUrl.replaceAll('${streamQuality}', streamQuality);
    url = url.replaceAll('${shape}', 'standard');
    url = url.replaceAll('${resolution}', '320x180');

    return url;
};

const mapToPlanby = (
    station: Station,
    idx: number,
    streamQuality: StreamQuality = 'sd',
): StationChannel => {
    return {
        type: 'station',
        enumeration: idx + 1,
        isNestedChild: false,
        uuid: station.id,
        logo: getLogo(station.logoTemplateUrl, streamQuality),
        availableStreamQuality: streamQuality,
        ...station,
    };
};

export const decode = (value: unknown, epgStations?: EpgStation[]): Channel[] | undefined => {
    const streamQualityMap =
        epgStations?.reduce<Record<string, StreamQuality>>((reduced, epgStation) => {
            reduced[epgStation.stationId] = epgStation.streamQuality === 'hd' ? 'hd' : 'sd';
            return reduced;
        }, {}) ?? {};

    return isValidJson(value)
        ? value.stations.map((station, idx) =>
              mapToPlanby(station, idx, streamQualityMap[station.id]),
          )
        : undefined;
};
