/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';
import { classnames, conditionalCb } from '@exaring/utils';

const OSDButton = ({ onAction, isDisabled, className, title }) => {
    const action = conditionalCb(!(isDisabled || !onAction), onAction);
    const buttonCls = classnames('osd__icon', className, isDisabled && 'icon--disabled');

    return <div onClick={action} className={buttonCls} title={title} />;
};

export default OSDButton;
