/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { styled } from '../theme';

export const Badge = styled('span', {
    borderRadius: '$4',
    borderColor: 'transparent',
    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 0 4px 0 rgba(0, 0, 0, 0.12)',
    color: '$white',
    display: 'block',
    fontFamily: 'Poppins',
    fontSize: 9,
    fontWeight: 600,
    letterSpacing: '.5px',
    lineHeight: '12px',
    padding: '2px 4px',
    textTransform: 'uppercase',
});
