import { h } from 'preact';
import { useCallback } from 'preact/hooks';
import { date, now } from '@exaring/utils';
import { truncateString } from '@exaring/utils/string';
import { RectangleSkeleton } from '@exaring/ui/components-styled/Skeleton/RectangleSkeleton';
import { styled } from '@exaring/ui/components-styled/theme';
import { ProgramItem, ProgramFlex, useProgram } from '@nessprim/planby';
import { epgStore, notificationsStore } from '../../state/Store';
import { WebClientGA, WebClientGAEvent } from '../../web-client-ga';
import { lastActiveChannel } from '../../actions/helper';
import { Routes, routeTo, routeToEpg } from '../../routes';
import { useDelayedHover } from './hooks/useDelayedHover';

const ProgramBox = styled('div', {
    position: 'absolute',
    overflow: 'inherit',

    variants: {
        disableInteraction: {
            true: {
                pointerEvents: 'none',
            },
        },
    },
});

const ProgramStack = styled('div', {
    overflow: 'hidden',
    alignSelf: 'center',
});

const EpgProgramTitle = styled('p', {
    margin: 0,
    fontSize: '16px',
    fontWeight: '600',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const ProgramContent = styled('div', {
    position: 'relative',
    display: 'flex',
    fontSize: '11px',
    height: '100%',
    paddingTop: '10px ',
    paddingBottom: '10px ',
    overflow: 'clip',
    cursor: 'pointer',
    zIndex: 1,

    boxSizing: 'border-box',
    border: 'solid 1px $nearBlack',
    borderRadius: '4px',
    background: '$ebony',
    color: '#fff',

    '&:hover': {
        borderColor: '$white',
        transition: 'none',
    },

    [`${EpgProgramTitle}`]: {
        color: '$white',
    },

    variants: {
        isLive: {
            true: {
                background: '$anthracite',
            },
        },
        previous: {
            true: {
                background: '$steelGrey',
                [`${EpgProgramTitle}`]: {
                    opacity: 0.3,
                },
            },
        },
        upcoming: {
            true: {
                background: '$cinder',
            },
        },
        active: {
            true: {}, // we need this for compoundVariants. Do not delete
        },
        locked: {
            true: {
                '&:hover': {
                    borderColor: '$tuna',
                    [`${EpgProgramTitle}`]: {
                        color: '$white',
                    },
                },
            },
        },
    },

    compoundVariants: [
        {
            isLive: true,
            active: true,
            css: {
                background: '$beautifulDarkness',
            },
        },
        {
            locked: true,
            isLive: true,
            css: {
                background: '$inkBlack',
                [`${EpgProgramTitle}`]: {
                    opacity: 0.25,
                },
            },
        },
        {
            locked: true,
            previous: true,
            css: {
                background: '$narwhalGrey',
                [`${EpgProgramTitle}`]: {
                    opacity: 0.08,
                },

                '&:hover': {
                    [`${EpgProgramTitle}`]: {
                        opacity: 0.25,
                    },
                },
            },
        },
        {
            locked: true,
            upcoming: true,
            css: {
                background: '$dreamlessSleep',
                [`${EpgProgramTitle}`]: {
                    opacity: 0.25,
                },
            },
        },
        {
            locked: true,
            isLive: true,
            active: true,
            css: {
                background: '$narwhalGrey',
                [`${EpgProgramTitle}`]: {
                    opacity: 0.25,
                },
            },
        },
    ],
});

export const Program = ({ program, ...rest }: ProgramItem) => {
    const { getStationById, setPreview } = epgStore();
    const notifications = notificationsStore();
    const { styles, isLive } = useProgram({
        program,
        ...rest,
    });

    const { data } = program;
    const { loading, title, till, since, channelUuid, id, animated } = data;

    const lastChannelId = lastActiveChannel();
    const channel = getStationById(channelUuid);
    const isPast = date(till).unix() - now().unix() < 0;
    const isUpcoming = date(since).unix() - now().unix() > 0;

    const onClickHandler = useCallback(() => {
        if (channel?.locked) {
            notifications.createUpsellingToast();
        } else if (isLive) {
            routeTo(Routes.LIVE_TV_PAGE + channel?.id);
        } else if (isPast || isUpcoming) {
            routeToEpg(channel?.id, id);
        }
        WebClientGA().trackEvent({
            eventName: WebClientGAEvent.EPG,
            eventDescription: WebClientGAEvent.SelectProgram,
            channelName: channel?.displayName,
            screenName: 'epg',
            programId: id,
            programTitle: title,
        });
    }, [isLive, channel?.locked, channel?.id, isPast, isUpcoming, id, notifications]);

    const { onMouseEnter, onMouseLeave } = useDelayedHover(
        () => setPreview({ type: 'Program', id }),
        50,
    );

    const width = Math.max(styles.width - 2 * 20, 0); // shrink box width by padding space
    const paddingLeftRight = `20px`;
    const processedTitle = width < 30 ? '' : truncateString(title, width);

    return (
        <ProgramBox
            css={{ ...styles.position }}
            onClick={onClickHandler}
            onMouseEnter={!loading ? onMouseEnter : undefined}
            onMouseLeave={!loading ? onMouseLeave : undefined}
            disableInteraction={loading}
            title={title}
        >
            {loading ? (
                <RectangleSkeleton
                    animated={animated}
                    css={{
                        margin: '1px',
                        width: 'calc(100% - 2px)',
                        height: 'calc(100% - 2px)',
                    }}
                />
            ) : (
                <ProgramContent
                    isLive={isLive}
                    previous={isPast}
                    upcoming={isUpcoming}
                    locked={channel?.locked}
                    active={lastChannelId?.toLowerCase() === data.channelUuid} // TODO: remove toLowerCase after migration of EPG 2.0 is completed
                    css={{
                        paddingLeft: paddingLeftRight,
                        paddingRight: paddingLeftRight,
                    }}
                >
                    <ProgramFlex style={{ position: 'sticky', width: 'inherit', left: '175px' }}>
                        <ProgramStack>
                            <EpgProgramTitle>{processedTitle}</EpgProgramTitle>
                        </ProgramStack>
                    </ProgramFlex>
                </ProgramContent>
            )}
        </ProgramBox>
    );
};
