import { Program } from '@nessprim/planby';
import { useMemo } from 'preact/hooks';
import { FetchState } from '../../../state/EpgStore';
import { Channel } from '../../../types/api/epg/StationConfig';

export const useProgramWithLoadingSkeleton = (
    epgState: Record<string, FetchState>,
    channels: Channel[],
    programs: Program[],
    startUtc: number,
    endUtc: number,
    animated = true,
) =>
    useMemo(() => {
        const extendedPrograms = [...programs];

        // add one 'loading' program item for each channel whose state is undefined (initial state) or 'Loading'
        channels.forEach(({ uuid: channelUuid }, index) => {
            const uuid = channelUuid || '';
            if (!epgState[uuid] || epgState[uuid] === 'Loading') {
                extendedPrograms.push({
                    loading: true,
                    animated,
                    id: `${uuid}_loader`,
                    channelUuid: uuid,
                    since: startUtc,
                    till: endUtc,
                    title: '',
                    description: '',
                    image: '',
                    index,
                    channelIndex: index,
                    // Undocumented prop that seems to be only used for drag-n-drop, which is not used in our case
                    // Passing dummy object to satisfy TS
                    // Introduced in  https://github.com/Nessprim/planby/commit/9ed61fa570dbc9104791307fab909320597cb174
                    channelPosition: {
                        top: 0,
                        height: 0,
                    },
                });
            }
        });

        return extendedPrograms;
    }, [epgState, channels, programs, startUtc, endUtc, animated]);
