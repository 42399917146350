import { MutableRef, useCallback, useEffect, useMemo } from 'preact/hooks';

import { useDebounce } from '@exaring/ui/hooks/useDebounce';
import { useWindowSize } from '@exaring/ui/hooks/useWindowSize';
import { MediaQuery } from '@exaring/ui/components-styled/theme';

import { useEpg } from '@nessprim/planby';

import { epgTheme } from '../Theme';

import { useEpgStore } from '../../../state/Store';
import { useEpgInit } from './useEpgInit';
import { VisibleChannelTimeslot, getEpgViewport } from '../Viewport';
import {
    CHANNEL_LIST_WIDTH_IN_PX,
    DAY_RANGE_IN_PX,
    LOADING_DEBOUNCE_TIME_IN_MS,
    PROGRAM_XS_HEIGHT_IN_PX,
    PROGRAM_MD_HEIGHT_IN_PX,
} from '../constants';
import { isLoadingChannel } from '../../../types/api/epg/StationConfig';
import { useProgramWithLoadingSkeleton } from './useProgramWithLoadingSkeleton';
import { getLoadingChannels } from '../../../state/EpgStore';
import { useEpgTimeScroll } from './useEpgTimeScroll';
import { useEpgChannelScroll } from './useEpgChannelScroll';
import { useEpgDateRange } from './useEpgDateRange';

export const useEpgData = () => {
    useEpgInit();
    const [startDate, endDate, startUtc, endUtc] = useEpgDateRange();

    const {
        epgStations,
        epgData: { value: epgData, state: epgState },
        fetchEpgData,
        showOnlyFavorites,
    } = useEpgStore();

    const channelsState = useMemo(() => {
        if (showOnlyFavorites) {
            const filtered = epgStations.value.filter(
                (channel) => isLoadingChannel(channel) || (channel.visible && channel.favorite),
            );

            const emptyChannels = filtered.length < 1;
            return {
                channels: emptyChannels ? getLoadingChannels(false) : filtered,
                showEmptyFavorites: emptyChannels,
                hasLoadingChannels: emptyChannels,
            };
        }

        return {
            channels: epgStations.value.filter(
                (channel) => isLoadingChannel(channel) || channel.visible,
            ),
            hasLoadingChannels: isLoadingChannel(epgStations.value[0]),
        };
    }, [showOnlyFavorites, epgStations]);

    const epg = useProgramWithLoadingSkeleton(
        epgState,
        channelsState.channels.map((ch) => ({ ...ch })),
        epgData,
        startUtc,
        endUtc,
        !channelsState.showEmptyFavorites,
    );
    const windowSize = useWindowSize();
    const channelHeight =
        windowSize.width < MediaQuery.md || windowSize.height < MediaQuery.md
            ? PROGRAM_XS_HEIGHT_IN_PX
            : PROGRAM_MD_HEIGHT_IN_PX;

    const { getEpgProps, getLayoutProps, scrollX, scrollY, onScrollRight, onScrollTop } = useEpg({
        // Since 3.8.0 the channels and programs (epg) have to be mutable, therefore passing a mutable version
        // Workaround for https://github.com/Nessprim/planby/issues/31
        channels: channelsState.channels.map((ch) => ({ ...ch })),
        epg: epg.map((pr) => ({ ...pr })),
        dayWidth: DAY_RANGE_IN_PX,
        sidebarWidth: CHANNEL_LIST_WIDTH_IN_PX,
        itemHeight: channelHeight,
        isSidebar: true,
        isTimeline: true,
        isLine: !channelsState.showEmptyFavorites,
        startDate: startDate.format((t: any) => t.utc),
        endDate: endDate.format((t: any) => t.utc),
        isBaseTimeFormat: false,
        theme: epgTheme,
        globalStyles: `
            .planby {
                padding: 0 !important;
                font-family: inherit;
            }
        `,
    });

    const { itemHeight, ref, ...layoutProps } = getLayoutProps();

    useEpgTimeScroll(
        startDate,
        endDate,
        layoutProps.dayWidth,
        scrollX,
        // set left offset to 1/4 of timeline width
        ref.current?.offsetWidth ? ref.current?.offsetWidth / 4 - layoutProps.sidebarWidth : 0,
        onScrollRight,
    );
    useEpgChannelScroll(channelsState.channels, channelHeight, scrollY, onScrollTop);

    const visibleGridItems = useMemo(
        () =>
            getEpgViewport(
                ref as MutableRef<HTMLDivElement>,
                startDate,
                endDate,
                scrollX,
                scrollY,
                channelsState.channels,
                itemHeight,
            ),
        [ref, scrollX, scrollY, channelsState.channels, itemHeight, startDate, endDate],
    );

    // because eslint can not reason about the useDebounce closure
    const debouncedUpdateData = useCallback(
        useDebounce((items: VisibleChannelTimeslot[]) => {
            items.forEach(({ channelId, timeslot }: VisibleChannelTimeslot) => {
                fetchEpgData(channelId, timeslot);
            });
        }, LOADING_DEBOUNCE_TIME_IN_MS),
        [fetchEpgData],
    ); // debounce to prevent fetch threshing

    useEffect(() => {
        debouncedUpdateData(visibleGridItems);
    }, [debouncedUpdateData, visibleGridItems]);

    return {
        getEpgProps,
        ref,
        itemHeight,
        layoutProps,
        showEmptyFavorites: channelsState.showEmptyFavorites,
        hasLoadingChannels: channelsState.hasLoadingChannels,
    };
};
