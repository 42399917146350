import { createContext } from 'preact';
import { MutableRef } from 'preact/hooks';

export const ScrollContext = createContext<{
    // current scroll position
    dayUnix: number;
    hour: number;

    // refs of scrollTo-functions, used to trigger scrolling
    scrollToTimeRef?: MutableRef<
        ((dayUnix: number | undefined, hour: number | undefined) => void) | undefined
    >;
    scrollToChannelRef?: MutableRef<((uuid?: string) => void) | undefined>;

    // called from useEpgTimeScroll hook
    setDayUnix: (dayUnix: number) => void;
    setHour: (hour: number) => void;
    setScrollToTimeHandler: (
        handler: (dayUnix: number | undefined, hour: number | undefined) => void,
    ) => void;

    // called from useEpgChannelScroll hook
    setScrollToChannelHandler: (handler: (uuid?: string) => void) => void;
}>({
    dayUnix: 0,
    hour: 0,
    setDayUnix: () => {},
    setHour: () => {},
    setScrollToTimeHandler: () => {},
    setScrollToChannelHandler: () => {},
});
