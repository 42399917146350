/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, Component } from 'preact';

export default class NotificationToast extends Component {
    componentDidMount() {
        const { onNotificationHidden } = this.props;
        if (onNotificationHidden) {
            setTimeout(onNotificationHidden, 1500);
        }
    }

    render() {
        const { text } = this.props;
        return (
            <div className="notification-toast anim--fadeInOut">
                <p className="notification-toast__label">{text}</p>
            </div>
        );
    }
}

/*
NotificationToast.propTypes = {
    text: React.PropTypes.string,
    onNotificationHidden: React.PropTypes.func
} */
