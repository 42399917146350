/* eslint-disable no-bitwise */
// TODO: What about timeshifted playout?
export const PLAYOUT_NONE = 0;
export const PLAYOUT_LIVE = 1 << 0;
export const PLAYOUT_NEW_TV = 1 << 1;
export const PLAYOUT_RECORDING = 1 << 2;
export const PLAYOUT_VOD = 1 << 3;
export const PLAYOUT_LIVE_RECORDING = PLAYOUT_LIVE | PLAYOUT_RECORDING;

export const OSD_PANEL_CLOSED = 0;
export const OSD_PANEL_EPG = 1 << 1;
export const OSD_PANEL_CHANNELS = 1 << 2;
export const OSD_ACTIVE_EDGE_LEFT = 1 << 3;

/* eslint-enable no-bitwise */
