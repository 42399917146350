/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */
import { date } from '@exaring/utils';
import { WebClientGA, WebClientGAEvent } from './web-client-ga'; // eslint-disable-line import/no-cycle
import { PLAYOUT_LIVE } from './actions/constants.ts';
import { epgStore } from './state/Store.ts';

export const trackWithPlayerContext = (
    eventName,
    state,
    eventDescription,
    screenName,
    additionals = {},
) => {
    const { player, playout } = state;
    let type;
    let stationDetails;

    const { getStationById } = epgStore();

    if (playout?.activeProgram?.id) {
        stationDetails = getStationById(
            playout?.activeProgram.channel ?? playout?.activeProgram.stationId,
        );
    }

    if (playout.isNewTV) {
        type = 'vod';
    } else if (playout.playoutType === PLAYOUT_LIVE) {
        type =
            playout.isTimeShifted || eventName === WebClientGAEvent.TimeshiftInstantRestart
                ? 'time_shift'
                : 'livetv';
    } else {
        type = 'recording';
    }

    const timeShiftTime =
        playout?.activeProgram && player.timestamp > 0
            ? date(playout?.activeProgram.startTime).diff(date(Math.floor(player.timestamp))) * 1000 // casting to modern TS as diff returns seconds
            : 0;

    const load =
        (playout?.activeProgram && {
            channelName: stationDetails?.displayName,
            programId: playout?.activeProgram.id,
            programTitle:
                playout?.activeProgram?.title ?? playout?.activeProgram?.textContent?.title,
            playerType: type,
        }) ||
        {};

    WebClientGA().trackEvent(
        {
            eventName,
            eventDescription,
            screenName,
            ...load,
            ...additionals,
        },
        { timeShiftTime },
    );
};

export const trackWithUserContext = (eventName, eventDescription) => {
    WebClientGA().trackEvent({
        eventName, // user context is evolved through runtime hook in web-client-ga
        eventDescription,
    });
};
