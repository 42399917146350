/* eslint-disable no-restricted-syntax */
/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */
import {
    logErrorWithDescription,
    unix,
    now,
    isTimestamp,
    date,
    clamp,
    isTunerServiceId,
    localStorageItem,
} from '@exaring/utils';
import { isRecording, isLocked, isFinished } from '@exaring/utils/data/recording';
import { playoutUrls } from '@exaring/utils/data/playout';

// data services
import sup from '@exaring/networking/services/stream-url-provider';
import epgService from '@exaring/networking/services/epg';
import tunerService from '@exaring/networking/services/tuner';
import streamPositionService from '@exaring/networking/services/stream-position';
import imageScalerService from '@exaring/networking/services/image-scaler';
import RecordingServiceApi from '@exaring/networking/services/recordings-v4';

import create from '@exaring/utils/data/schema/create';
import { arrayBufferToImage } from '@exaring/utils/data/transformer';
import { SupRequestV1 } from '@exaring/utils/data/schema/sup';
import constants from '../constants';
import {
    CLEARANCE_LEVEL,
    verifyParentalGuidanceLive,
    verifyParentalGuidanceVod,
    verifyParentalGuidanceRecordingV4,
} from './helper/parental-guidance';

import {
    PLAYOUT_NONE,
    PLAYOUT_LIVE_RECORDING,
    PLAYOUT_RECORDING,
    PLAYOUT_LIVE,
    PLAYOUT_NEW_TV,
    PLAYOUT_VOD,
    OSD_PANEL_CLOSED,
    OSD_PANEL_CHANNELS,
} from './constants';
import { WebClientGAEvent } from '../web-client-ga'; // eslint-disable-line import/no-cycle
import { trackWithPlayerContext } from '../tracking'; // eslint-disable-line import/no-cycle

import { getCurrentProgram, setLastActiveChannel, lastActiveChannel } from './helper';
import { getCurrentScreen, playoutDataGenerator, streamProtocolBrowserDetection } from '../helper';
import MediaPlayerError from '../components/MediaPlayer/MediaPlayerError';

import ErrorTypes, { PlayoutErrorTypes } from '../errorTypes';
import { notificationsStore, recordingStore, userStore } from '../state/Store';
import { loadLanguageSettings } from '../player/shaka';
import { getGdprConsent } from './helper/dai';

const TIMESHIFT_START_OFFSET = 60; // 1 minute

let epgStore;

export const setEpg2Store = (_epgStore) => {
    epgStore = _epgStore;
};

const createImage = (blob) =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.onload = () => {
            resolve(image);
        };
        image.onerror = (err) => {
            reject(err);
        };
        image.src = URL.createObjectURL(blob);
    });

/**
 * Returns a segmented seek position in milliseconds based on
 *   - the given timestamp to which we intended to seek
 *   - and the current time.
 *
 * @param {number} startTimeMillis The start time of the current program in milliseconds
 * @param {number} seekingProgressMillis The amount in milliseconds we intend to seek
 * @param {number} nowMillis The current time in milliseconds
 * @returns {number} The timeshift in milliseconds
 */
export const getSeekTime = (startTimeMillis, seekingProgressMillis, nowMillis = Date.now()) => {
    const absoluteProgressMillis = startTimeMillis + seekingProgressMillis;
    const maxTimeshiftMillis = constants.TIMESHIFT_MAX_LIVE_OFFSET * 1000;

    // ensure that user cannot seek more than the set TIMESHIFT_MAX_LIVE_OFFSET
    const seekPosMillis = Math.max(absoluteProgressMillis, nowMillis - maxTimeshiftMillis);

    // in order to limit the number of different streams infra-wise we only allow the seeking to
    // positions that fall into a given seek time frame
    const moduloDifference = (nowMillis - seekPosMillis) % constants.SEEK_TIME_FRAME;
    const timeshiftMillis = seekPosMillis + moduloDifference;
    return timeshiftMillis;
};

export const initialState = {
    // OSD
    onHold: true, // playout possible but player is paused immediately
    showOSD: false, // osd is visible
    lockOSDToggle: false, // disable toggling
    showVolume: false, // volume slider visible
    unmuteInteractionRequired: true, // an interaction is required to unmute the playout
    isFullscreenMode: false, // player is in fullscreen
    panelState: OSD_PANEL_CLOSED, // channel-list or EPG-info panel or none
    showTimeShiftExit: false, // if timeshift exit was revoked show notification

    // reduced volume handling when EPG details are opened in live tv
    isVolumeReduced: false, // if player should reduce volume when panel is open (kinda redundant as we got panelState at anytime)
    lastVolume: constants.OSD_DEFAULT_VOLUME, // should init as default volume

    // timeshift states
    lastTimeshift: null, // holds rollback data for timeshift states (activeProgram, timeShiftTimestamp, activeChannelId)
    isTimeShifted: false,
    timeShiftTimestamp: 0,

    parentalGuidanceRating: '',
    parentalGuidanceLevel: CLEARANCE_LEVEL.CLEARED,

    // new player states
    playoutTimestamp: null, // track playout time to determine stream end
    heartbeatInterval: null,
    activeChannelId: null, // current active channel id (only live tv)
    playoutType: PLAYOUT_NONE, // current active playout type (recording, live recording, ...)
    playoutId: null, // current active playout id (generated for live playouts, recording-ID for recordings)
    isNewTV: false, // @deprecated -> move to playoutTypes
    activeProgram: {}, // (@todo init should be null) current active program (only filled for recordings and timeshift this should be filled all the time)
    recording: null,
    previewImage: null,
    playerError: false,
    activeCooldown: false,
    fetchThumbnail: false,
    programId: null,
    lastPinEntry: null,
    enableStreamLogging: false,
};

let newTvRefreshTimeout = null;
let channelLoaderIsBusy = false;
let timeshiftLoaderInProgress = false;
let channelToLoadNext;

let timeshiftExitTimeout;
let osdTimeout;

export const playoutErrorHandler = ({ response }) => {
    try {
        const error = response.data;

        if (Object.values(PlayoutErrorTypes).includes(error.type)) {
            return { errorType: error.type };
        }
    } catch {
        // do nothing
    }

    return { errorType: PlayoutErrorTypes.GENERAL_ERROR }; // network error
};
const fetchPlayoutUrl = (
    channelId,
    newTV,
    vbegin,
    deviceToken,
    propagateLoadingState,
    startTimeReason,
) => {
    const gdprConsent = getGdprConsent();

    return sup.playoutUrl(
        create(
            {
                stream: {
                    station: channelId,
                    startTime: vbegin,
                    ...(startTimeReason ? { startTimeReason } : {}),
                    protocol: streamProtocolBrowserDetection(),
                    requestMuxInstrumentation: true,
                    processOutcomeField: true,
                },
                ...(gdprConsent && {
                    advertising: {
                        gdprConsent,
                    },
                }),
            },
            SupRequestV1,
        ),
        newTV, // newTV
        deviceToken || '',
        propagateLoadingState,
    );
};

const actions = (store) => {
    let playoutCooldownTimeout;

    const isEpgInitialized = async () => {
        const {
            epgStations: { state },
            fetchEpgStations,
        } = epgStore();
        const isInitialized = state === 'Success';

        if (!isInitialized) {
            await fetchEpgStations();
        }
    };

    const fetchPlayoutForChannel = async (
        channel,
        vbegin = null,
        propagateLoadingState = true,
        deviceToken,
        newTv = true,
    ) => {
        let playout;

        channelLoaderIsBusy = true;
        try {
            const { data } = await fetchPlayoutUrl(
                channel,
                newTv,
                vbegin,
                deviceToken,
                propagateLoadingState,
            );
            playout = data;
        } catch (e) {
            channelLoaderIsBusy = false;
            logErrorWithDescription(e, 'while requesting playoutUrl');
            throw e; // will be handled in `initLivePlayoutWithChannel()`
        }
        return playout;
    };

    const revokePreviewImage = () => {
        // NOTICE: we revoke URLs when user leaves the recording stream to give
        // the big image resource free
        const { playout } = store.getState();
        const url = playout?.previewImage?.thumbnails?.src;
        if (!url) {
            return;
        }

        URL.revokeObjectURL(url);
        store.setState({
            playout: {
                ...playout,
                previewImage: undefined,
            },
        });
    };

    const __actions = {
        initLivePlayoutWithTimeshift: async (
            _state,
            channelId,
            onHold = false,
            propagateLoadingState = true,
            vbegin = 0,
            newTv = true,
            startTimeReason = '',
        ) => {
            const protocol = streamProtocolBrowserDetection();
            const { deviceToken } = userStore();
            timeshiftLoaderInProgress = true;

            const timeShiftPlay = async (playout) => {
                const { playout: playoutState } = store.getState();

                store.setState({
                    playout: {
                        ...playoutState,
                        onHold,
                        isTimeShifted: vbegin > 0,
                        timeShiftTimestamp: vbegin,
                        playoutId: channelId + now(),
                        playoutTimestamp: unix(),
                    },
                });

                timeshiftLoaderInProgress = false;
                const dash = protocol === 'dash' && playout.streamUrl;
                const hls = protocol === 'hls' && playout.streamUrl;

                return store.api.player.playout(
                    dash,
                    hls,
                    /* startAt */ vbegin,
                    /* autoPause */ undefined,
                    /* isLiveTv */ true,
                    /* isTimeshift */ true,
                );
            };

            let playoutData;

            try {
                const { data } = await fetchPlayoutUrl(
                    channelId,
                    newTv,
                    Math.max(0, vbegin - TIMESHIFT_START_OFFSET),
                    deviceToken,
                    propagateLoadingState,
                    startTimeReason,
                );

                playoutData = data;
            } catch (e) {
                timeshiftLoaderInProgress = false;
                logErrorWithDescription(e, 'while requesting playoutUrl');
            }

            return timeShiftPlay(playoutData);
        },

        updateActiveProgram: ({ playout }, activeProgram) => {
            store.setState({
                playout: {
                    ...playout,
                    activeProgram,
                },
            });
        },

        /**
         * @param {!Object} state
         * @param {!string} channelId playout channel
         * @param {boolean} onHold immediately pause player after playout
         * @param {boolean} propagateLoadingState show loading indicator
         * @param {boolean} silentTimeshiftTeardown release timeshift without revoke notification
         */
        initLivePlayoutWithChannel: async (
            state,
            channelId,
            onHold = false,
            propagateLoadingState = true,
            silentTimeshiftTeardown = false,
        ) => {
            const { activeChannelId, reinitTimeshift, lastTimeshift } = state.playout;

            if (reinitTimeshift) {
                store.setState({
                    playout: {
                        ...state.playout,
                        showTimeShiftExit: false,
                        isTimeShifted: true,
                        lastTimeshift: null,
                        playerError: false,
                        reinitTimeshift: false, // reset reinit
                        ...state.playout.lastTimeshift,
                    },
                });

                return __actions.initLivePlayoutWithTimeshift(
                    store.getState(),
                    channelId,
                    /* onHold */ false,
                    /* propagateLoadingState */ true,
                    lastTimeshift?.timeShiftTimestamp,
                );
            }

            __actions.deinitTimeShiftMode(state, !silentTimeshiftTeardown); // teardown any exisiting timeshift states
            __actions.resetErrorOverlay();

            // updateBackendRecordingPosition needed, in case user is switching from recording directly into live-tv
            await __actions.updateBackendRecordingPosition(store.getState());
            revokePreviewImage();

            const startPlayout = async (_playout) => {
                const { playout } = store.getState();

                const protocol = streamProtocolBrowserDetection();

                let dash;
                let hls;
                const seekTime = _playout.seekTime || _playout.seekMilliseconds || 0;
                const activeProgram = await getCurrentProgram(playout, epgStore());

                const { parentalGuidanceRating, parentalGuidanceLevel, autoPause } =
                    await verifyParentalGuidanceLive(
                        channelId,
                        activeChannelId,
                        playout.parentalGuidanceLevel,
                    );

                const isLiveTv = _playout.seekTime === undefined;
                const isNewTV = !isLiveTv;

                loadLanguageSettings();

                store.setState({
                    playout: {
                        ...playout,
                        activeProgram,
                        previewImage: initialState.previewImage,
                        recording: initialState.recording,
                        playoutType: PLAYOUT_LIVE,
                        isNewTV,
                        onHold,
                        playoutId: _playout.channel + now(),
                        playoutTimestamp: unix(),
                        parentalGuidanceRating,
                        parentalGuidanceLevel,
                        enableStreamLogging: !!_playout.muxInstrumentation,
                    },
                });

                if (_playout.streamUrl) {
                    // new pup compat layer
                    dash = protocol === 'dash' && _playout.streamUrl;
                    hls = protocol === 'hls' && _playout.streamUrl;
                } else {
                    const pUrls = playoutUrls(_playout);
                    dash = pUrls['mpeg-dash'];
                    hls = pUrls.hls;
                }

                window.history.replaceState(window.history.state, '', `/${channelId}`);
                trackWithPlayerContext(
                    WebClientGAEvent.ScreenView,
                    store.getState(),
                    null,
                    getCurrentScreen(),
                    { pause: onHold },
                );
                return store.api.player.playout(
                    dash,
                    hls,
                    seekTime > 0 ? seekTime / 1000 : undefined,
                    /* autoPause */ channelId === activeChannelId ? false : autoPause,
                    isLiveTv,
                );
            };

            // store the active channel up front, so the ui can update independently from the player being ready
            store.setState({
                playout: {
                    ...store.getState().playout,
                    activeChannelId: channelId,
                },
            });

            // make sure there is no running newTvRefreshTimeout
            if (newTvRefreshTimeout) {
                clearTimeout(newTvRefreshTimeout);
                newTvRefreshTimeout = null;
            }

            if (channelLoaderIsBusy) {
                // "queue" the channel request
                channelToLoadNext = {
                    channelId,
                    onHold,
                    propagateLoadingState,
                };

                return undefined;
            } // else

            const { deviceToken } = userStore();
            let playout;
            try {
                playout = await fetchPlayoutForChannel(
                    channelId,
                    null,
                    propagateLoadingState,
                    deviceToken,
                );
                if (channelLoaderIsBusy && channelToLoadNext) {
                    // trigger the "queued" channel
                    const temp = channelToLoadNext;
                    channelLoaderIsBusy = false;
                    channelToLoadNext = undefined;

                    return __actions.initLivePlayoutWithChannel(
                        store.getState(),
                        temp.channelId,
                        temp.onHold,
                        temp.propagateLoadingState,
                    );
                }

                store.setState({
                    playout: {
                        ...store.getState().playout,
                        activeChannelId: channelId,
                    },
                });
                setLastActiveChannel(channelId);

                await startPlayout(playout);

                channelLoaderIsBusy = false;

                const nextState = store.getState();
                const { playout: nextPlayoutState } = nextState;
                const currentUiChannel = nextPlayoutState.activeChannelId;

                if (currentUiChannel && currentUiChannel !== channelId) {
                    return __actions.initLivePlayoutWithChannel(
                        state,
                        currentUiChannel,
                        nextPlayoutState.onHold,
                    );
                }

                return {
                    playout: {
                        ...store.getState().playout,
                        activeChannelId: channelId,
                    },
                };
            } catch (error) {
                const { errorType, errorCode } = playoutErrorHandler(error);

                let nextState = store.getState();
                nextState.playout = {
                    ...nextState.playout,
                    ...initialState,
                    onHold,
                    unmuteInteractionRequired: nextState.playout.unmuteInteractionRequired,
                    activeChannelId: channelId,
                };

                store.setState(nextState);

                await store.api.player.unload();

                nextState = store.getState();
                nextState.unmuteInteractionRequired = true;
                return __actions.showErrorOverlay(
                    nextState,
                    new MediaPlayerError({ errorCode, errorType }),
                );
            }
        },

        interruptPlayout: async ({ playout: { playerError, isTimeShifted } }) => {
            if (playerError) {
                // ignore if player is already in error state
                return store.getState();
            }

            if (isTimeShifted) {
                __actions.timeshiftSnapshot();
            }

            await store.api.player.unload();
            const nextState = store.getState();

            return __actions.showErrorOverlay(
                nextState,
                new MediaPlayerError({
                    errorCode: undefined,
                    errorType: ErrorTypes.StillWatching,
                }),
            );
        },

        playoutRetry: async (state = store.getState()) => {
            const {
                playoutType,
                activeProgram,
                activeChannelId,
                recording,
                playoutId,
                isTimeShifted,
                playerError,
            } = state.playout;

            if (playoutType === PLAYOUT_LIVE || playoutType === PLAYOUT_NEW_TV) {
                const isValidTimeshiftRollback =
                    playerError && playerError.timestamp + 60 * 5 - unix() > 0; // rollback timeshift for 5 minutes.
                if (isTimeShifted && isValidTimeshiftRollback) {
                    // auto timeshift rollback on error retry (including still watching error screen)
                    return __actions.revokeTimeShiftExit();
                }

                return __actions.initLivePlayoutWithChannel(
                    state,
                    activeChannelId || lastActiveChannel(),
                    false,
                    true,
                    true, // silent timeshift release after error
                );
            }

            if (playoutType === PLAYOUT_RECORDING || playoutType === PLAYOUT_LIVE_RECORDING) {
                return __actions.initPlayoutWithRecording(state, recording.id || playoutId);
            }

            if (playoutType === PLAYOUT_VOD) {
                return __actions.initVODPlayout(state, activeProgram.id, activeProgram.channel);
            }

            return state;
        },

        playoutByChannelShift: async (state = store.getState(), offset = 1) => {
            const { playout } = state;
            const { playoutType, playerError } = playout;
            const { getNextAndPreviousChannel } = epgStore();
            const currentChannel = state.playout.activeChannelId;

            if (!playerError && (playoutType !== PLAYOUT_LIVE || timeshiftLoaderInProgress)) {
                return;
            }

            const [previousChannel, nextChannel] = getNextAndPreviousChannel(
                state.playout.activeChannelId,
            );

            const targetChannel = offset === 1 ? nextChannel : previousChannel;

            if (!targetChannel) {
                return;
            }

            if (currentChannel !== targetChannel.uuid) {
                // channel changed from last to first, or vice versa. Show Quickzapper now.
                __actions.showQuickzapper(store.getState());
            }

            __actions.initLivePlayoutWithChannel(store.getState(), targetChannel.uuid);
        },

        updateBackendRecordingPosition: async (state, finished = false) => {
            const {
                recording: _recording,
                playoutType,
                programId,
                parentalGuidanceLevel,
                parentalGuidanceRating,
            } = state.playout;

            if (
                parentalGuidanceRating !== null &&
                parentalGuidanceLevel !== CLEARANCE_LEVEL.CLEARED
            ) {
                return;
            }

            if (playoutType === PLAYOUT_VOD) {
                await streamPositionService.updatePosition(
                    programId,
                    Number.parseInt(store.getState().player.timestamp.toFixed(0), 10) || 0,
                );
                return;
            }

            if (!_recording) {
                return;
            }

            const { timestamp } = state.player;
            let _timestamp = Math.floor(timestamp);
            const position = date(_timestamp);

            // dynamic means timestamp includes epg start date time information
            const isDynamicTimestamp = position.format((t) => t.year) !== 1970;

            if (isDynamicTimestamp) {
                _timestamp = position.diff(date(_recording.startTime));
            }

            _timestamp = finished ? 0 : _timestamp;

            const recording = {
                ..._recording,
                position: _timestamp,
            };

            store.setState({
                playout: {
                    ...store.getState().playout,
                    recording,
                },
            });

            try {
                const { id } = recording;
                await streamPositionService.updatePosition(id, _timestamp);
            } catch (e) {
                logErrorWithDescription(e, 'while updating recording position');
            }
        },

        initPlayoutWithRecording: async (state = store.getState(), recordingId, onHold = false) => {
            __actions.deinitTimeShiftMode(state, /* initTimeShiftExitRevoke */ false); // teardown any existing timeshift states
            __actions.resetErrorOverlay();

            // make sure there is no running newTvRefreshTimeout
            if (newTvRefreshTimeout) {
                clearTimeout(newTvRefreshTimeout);
                newTvRefreshTimeout = null;
            }

            const { playout, player } = store.getState();

            if (playout.playoutId === recordingId && player.isLoaded) {
                // do not playout already loaded recording
                store.setState({
                    playout: {
                        ...store.getState().playout,
                        onHold,
                    },
                });
                return store.api.player.play();
            }

            let _recording;

            // step 1) fetch new recordingItem with Id
            try {
                const { data: recordingDetails } = await RecordingServiceApi.recordingDetails(
                    recordingId,
                );

                if (recordingDetails) {
                    _recording = recordingDetails;
                } else {
                    return undefined;
                }
            } catch (e) {
                logErrorWithDescription(e, 'while requesting recording details');
                // TODO: we should probably show an error screen here
                return undefined;
            }

            const streamUrls = {};
            const recordingType = isRecording(_recording.status)
                ? PLAYOUT_LIVE_RECORDING
                : PLAYOUT_RECORDING;
            const program = _recording.programDetails;
            const recording = { ..._recording };

            const streamingDetails = await recordingStore().fetchStreamingDetails(
                recordingId,
                getGdprConsent(),
            );

            if (!streamingDetails) {
                return undefined;
            }

            streamingDetails.streams.forEach((link) => {
                streamUrls[link.protocol] = link.href;
            });

            const { parentalGuidanceRating, parentalGuidanceLevel, autoPause } =
                verifyParentalGuidanceRecordingV4(
                    program,
                    player.activeChannelId,
                    playout.parentalGuidanceLevel,
                );

            // step 2) set new recordingItem as playerItem as soon as possible
            store.setState({
                playout: {
                    ...store.getState().playout,
                    isNewTV: false,
                    activeProgram: program,
                    activeChannelId: initialState.activeChannelId,
                    playoutType: recordingType,
                    parentalGuidanceRating,
                    parentalGuidanceLevel,
                    recording,
                    onHold,
                    playoutId: recording.id,
                    playoutTimestamp: unix(),
                    streamingDetails,
                },
            });
            trackWithPlayerContext(
                WebClientGAEvent.PlayerControls,
                store.getState(),
                WebClientGAEvent.Play,
                'recordings',
            );
            if (isLocked(recording)) {
                await store.api.player.unload();
            } else {
                await store.api.player.playout(
                    streamUrls.MPEG_DASH,
                    streamUrls.HLS,
                    recording.position,
                    autoPause,
                );

                // step 3) fetch the preview image asynchronously, as we don't want to wait for it
                // to be loaded in order to start playout
                if (isFinished(recording?.status)) {
                    __actions.initPreviewImage(
                        state,
                        program.stationId.toUpperCase(),
                        program.dmbMeta.id,
                    );
                }
            }

            return undefined;
        },

        // this action needs to use setState because it should not block the stream
        initPreviewImage: async (_state, channelId, programId) => {
            let fetchThumbnail = true;
            const { playout: playoutDeferred } = store.getState();

            revokePreviewImage();
            store.setState({
                playout: {
                    ...playoutDeferred,
                    fetchThumbnail,
                },
            });

            fetchThumbnail = false;

            try {
                const { data } = await imageScalerService.thumbnails(channelId, programId);
                const previewImageData = arrayBufferToImage(data);
                const thumbnails = await createImage(previewImageData.blob);

                const { playout } = store.getState();
                const { recording } = playout;

                if (
                    recording?.programDetails.stationId === channelId.toLowerCase() &&
                    recording.programDetails.dmbMeta.id === programId
                ) {
                    store.setState({
                        playout: {
                            ...playout,
                            previewImage: { ...previewImageData, thumbnails },
                            fetchThumbnail,
                        },
                    });
                }
            } catch (e) {
                store.setState({
                    playout: {
                        ...playoutDeferred,
                        fetchThumbnail,
                    },
                });
            }
        },

        initVODPlayout: async (
            { playout, player = store.getState().player },
            programId,
            channelId,
            idfa = '',
            onHold = false,
        ) => {
            __actions.resetErrorOverlay(); // always reset error overlay on playout new content

            if (playout.playoutId === programId && player.isLoaded) {
                // do not playout already loaded recording
                store.api.player.play();
                return {
                    playout: {
                        ...playout,
                        playoutType: PLAYOUT_VOD,
                        onHold,
                    },
                };
            }

            let program;
            let hls;
            let dash;
            let vodProgramId;
            let position;
            let parentalGuidanceRating;
            let parentalGuidanceLevel;
            let autoPause;

            // step 1) fetch new programItem with Id
            try {
                const useTunerService = isTunerServiceId(programId);
                const { data } = await (useTunerService
                    ? tunerService.programDetails(programId)
                    : epgService.programDetails(channelId, programId));
                program = data;

                program = {
                    ...program,
                    startTime: program.startTime ?? now().unix(),
                    stopTime: program.stopTime ?? now().add(program.duration, 'm').unix(),
                };

                await isEpgInitialized();
                const {
                    parentalGuidanceRating: _parentalGuidanceRating,
                    parentalGuidanceLevel: _parentalGuidanceLevel,
                    autoPause: _autoPause,
                } = verifyParentalGuidanceVod(
                    program,
                    player.activeChannelId,
                    playout.parentalGuidanceLevel,
                );

                parentalGuidanceRating = _parentalGuidanceRating;
                parentalGuidanceLevel = _parentalGuidanceLevel;
                autoPause = _autoPause;

                const { data: playoutData } = await sup.playoutUrlTvfuse(
                    program.streamUrlProvider,
                    idfa,
                    getGdprConsent(),
                );
                vodProgramId = playoutData.programID;

                try {
                    const { duration: programDurationInMinutes } = program;

                    const { data: positionData } = await streamPositionService.position(
                        vodProgramId,
                    );

                    position = positionData?.position;
                    if (position > programDurationInMinutes * constants.RECORDING_NET_MIN + 30) {
                        position = 0;
                    }
                } catch (e) {
                    // most likely a 404 happened, so position is set to 0 as default
                    position = 0;
                }

                const streamUrls = playoutData.player;

                hls = streamUrls.hls;
                dash = streamUrls.mpd;
            } catch (e) {
                logErrorWithDescription(e, 'while starting vod playout');
                throw e;
            }

            await store.api.player.playout(dash, hls, position, autoPause);

            const nextState = {
                playout: {
                    ...playout,
                    isNewTV: true,
                    activeProgram: program,
                    activeChannelId: program.channel,
                    playoutType: PLAYOUT_VOD,
                    recording: initialState.recording,
                    previewImage: initialState.previewImage,
                    onHold,
                    playoutId: program.id,
                    programId: vodProgramId,
                    parentalGuidanceRating,
                    parentalGuidanceLevel,
                    playoutTimestamp: unix(),
                },
            };

            store.setState(nextState);
            return nextState;
        },

        togglePlay: async (_state, playing = true) => {
            let state = store.getState();
            const { playoutType, onHold, activeChannelId, timeShiftTimestamp } = state.playout;

            if (playoutType === PLAYOUT_LIVE) {
                if (!playing) {
                    __actions.initTimeShiftMode();
                } else {
                    __actions.deinitHeartbeat();
                    await __actions.initLivePlayoutWithTimeshift(
                        store.getState(),
                        activeChannelId,
                        /* onHold */ false,
                        /* propagateLoadingState */ false,
                        /* vbegin */ timeShiftTimestamp,
                    );
                }
            }

            state = store.getState();
            await __actions.updateBackendRecordingPosition(state);

            if (!onHold && playing) {
                await store.api.player.play();
                trackWithPlayerContext(
                    WebClientGAEvent.PlayerControls,
                    state,
                    WebClientGAEvent.Play,
                    getCurrentScreen(),
                );
            } else {
                store.api.player.pause();
                trackWithPlayerContext(
                    WebClientGAEvent.PlayerControls,
                    state,
                    WebClientGAEvent.Pause,
                    getCurrentScreen(),
                );
                trackWithPlayerContext(
                    WebClientGAEvent.ScreenView,
                    state,
                    null,
                    getCurrentScreen(),
                    {
                        pause: true,
                    },
                );
            }
        },

        /*
         * TIMESHIFT
         */
        restartProgram: async (_state, program, percentage, restartReason) => {
            const state = store.getState();

            const activeProgram = await getCurrentProgram(state.playout, epgStore());
            const playoutData = playoutDataGenerator(activeProgram);

            let startTimestamp;

            if (percentage !== undefined) {
                const seekInMilliseconds = (percentage / 100) * playoutData.duration * 1000;
                const seek = getSeekTime(new Date(program.startTime).getTime(), seekInMilliseconds);
                startTimestamp = seek / 1000;
            } else {
                startTimestamp = unix(program.startTime);
            }

            const maxRestartTime = constants.TIMESHIFT_MAX_LIVE_OFFSET - 60; // TIMESHIFT_MAX_LIVE_OFFSET - 60 = 149min
            const maxTimestamp = now().subtract(maxRestartTime, 's').unix();

            // check that vbegin doesn't exceed the limit of 149min
            const vbegin = Math.max(startTimestamp, maxTimestamp);

            store.setState({
                playout: {
                    ...state.playout,
                    activeProgram,
                },
            });

            trackWithPlayerContext(
                WebClientGAEvent.ScreenView,
                store.getState(),
                null,
                getCurrentScreen(),
                { pause: state.playout.onHold },
            );

            return __actions.initLivePlayoutWithTimeshift(
                _state,
                program.channel,
                /* onHold */ false,
                /* propagateLoadingState */ true,
                /* vbegin */ vbegin,
                /* newTv */ true,
                /* startTimeReason */ restartReason,
            );
        },

        // ToDo: Use async/await for asynchronicity contained
        revokeTimeShiftExit: () => {
            const { playout } = store.getState();
            const { lastTimeshift } = playout;
            trackWithPlayerContext(WebClientGAEvent.TimeshiftExitRevoke, store.getState());
            setLastActiveChannel(lastTimeshift?.activeChannelId);

            store.setState({
                playout: {
                    ...playout,
                    reinitTimeshift: true,
                },
            });

            __actions.initLivePlayoutWithChannel(store.getState(), lastTimeshift?.activeChannelId);
        },

        initTimeShiftExitNotification: ({ playout } = store.getState()) => {
            store.setState({
                playout: {
                    ...playout,
                    showTimeShiftExit: true,
                },
            });

            const hintId = notificationsStore().createInstantReplayHint(
                playout.activeProgram.title,
                {
                    onConfirm: () => {
                        clearTimeout(timeshiftExitTimeout);
                        __actions.revokeTimeShiftExit();
                    },
                },
            );
            clearTimeout(timeshiftExitTimeout);

            timeshiftExitTimeout = setTimeout(
                __actions.deinitTimeShiftExitNotification(hintId),
                constants.TIMESHIFT_EXIT_OVERLAY_DISPLAY_TIME,
            );
        },

        deinitTimeShiftExitNotification:
            (hintId) =>
            ({ playout } = store.getState()) => {
                store.setState({
                    playout: {
                        ...playout,
                        showTimeShiftExit: false,
                        lastTimeshift: null,
                    },
                });
                notificationsStore().deleteNotification(
                    hintId,
                    'Notifications/createInstantReplayHint',
                );
            },

        // TODO: starting point for general heartbeat interval for player including shouldPauseBeReleased, isCurrentProgramCorrect, ...
        initTimeShiftMode: async (state = store.getState()) => {
            const { playout, player } = state;
            const currentProgram = await getCurrentProgram(playout, epgStore());

            store.setState({
                playout: {
                    ...playout,
                    // ToDo: it would actually be better to use playout.getCurrentTime() here, as our timestamp might be out-of-date
                    timeShiftTimestamp: Math.round(player.timestamp),
                    activeProgram: playout.isTimeShifted ? playout.activeProgram : currentProgram, // on timeshift init set activeProgram to live program
                    isTimeShifted: true,
                },
            });

            __actions.initHeartbeat();
        },

        timeshiftSnapshot: (state = store.getState()) => {
            const { playout, player } = state;
            let lastTimeshift = null;

            if (playout.isTimeShifted) {
                lastTimeshift = {
                    activeProgram: playout.activeProgram,
                    timeShiftTimestamp: Math.round(player.timestamp),
                    activeChannelId: playout.activeChannelId,
                    parentalGuidanceLevel: playout.parentalGuidanceLevel,
                    parentalGuidanceRating: playout.parentalGuidanceRating,
                };
            }

            store.setState({
                playout: {
                    ...playout,
                    lastTimeshift,
                },
            });
        },

        deinitTimeShiftMode: (state = store.getState(), initTimeShiftExitRevoke = true) => {
            const { playout, player } = state;
            let lastTimeshift = null;

            if (playout.isTimeShifted) {
                if (initTimeShiftExitRevoke) {
                    lastTimeshift = {
                        activeProgram: playout.activeProgram,
                        timeShiftTimestamp: Math.round(player.timestamp),
                        activeChannelId: playout.activeChannelId,
                        parentalGuidanceLevel: playout.parentalGuidanceLevel,
                        parentalGuidanceRating: playout.parentalGuidanceRating,
                    };
                }

                store.setState({
                    playout: {
                        ...playout,
                        timeShiftTimestamp: 0,
                        isTimeShifted: false,
                        lastTimeshift,
                    },
                });

                if (initTimeShiftExitRevoke) {
                    // Needs setting of lastTimeshift before
                    __actions.initTimeShiftExitNotification();
                }

                __actions.deinitHeartbeat();
            }
        },

        // ToDo: Is it really useful for this to be run once per second? Wouldn't an interval of 5 seconds be enough?
        // ToDo: Use async/await for asynchronicity contained
        heartbeatCallback: () => {
            const { player } = store.getState();
            const liveEdgeGap = Math.round(date(player.timestamp).diff(now()));

            if (liveEdgeGap >= constants.TIMESHIFT_MAX_LIVE_OFFSET) {
                // 150 mins in sec.
                __actions.seekToLiveEdge();
            }
        },

        initHeartbeat: () => {
            const { playout } = store.getState();

            store.setState({
                playout: {
                    ...playout,
                    heartbeatInterval: setInterval(
                        __actions.heartbeatCallback,
                        constants.PLAYER_HEARTBEAT_INTERVAL,
                    ),
                },
            });
        },

        deinitHeartbeat: () => {
            const { playout } = store.getState();
            clearInterval(playout.heartbeatInterval);

            store.setState({
                playout: {
                    ...playout,
                    heartbeatInterval: null,
                },
            });
        },

        /*
         * SEEKING
         */

        seekToRelativeOffset: (state = store.getState(), offset) => {
            const { timestamp } = state.player;
            const { playoutType, recording, activeProgram } = state.playout;

            let seekTo = timestamp + offset;

            if (isTimestamp(timestamp) && playoutType === PLAYOUT_RECORDING) {
                const playoutData = playoutDataGenerator(activeProgram, recording);

                seekTo -= unix(playoutData.startTime);
            }

            store.api.playout.seekTo(seekTo);
        },

        seekToPercentage: (state = store.getState(), percentage) => {
            const { recording, activeProgram } = state.playout;
            const playoutData = playoutDataGenerator(activeProgram, recording);
            const seekTo = (playoutData.duration / 100) * percentage;
            store.api.playout.seekTo(seekTo);
        },

        seekTo: (state = store.getState(), value) => {
            const { playoutType, recording, activeProgram, onHold } = state.playout;
            const playoutData = playoutDataGenerator(activeProgram, recording);
            let minSeek;
            let maxSeek;

            if (playoutType === PLAYOUT_RECORDING || playoutType === PLAYOUT_VOD) {
                minSeek = 0;
                maxSeek = playoutData.duration;
            } else if (playoutType === PLAYOUT_LIVE_RECORDING) {
                const currentTime = now();
                const stopTime = date(playoutData.stopTime);
                const calculatedStopTime = currentTime < stopTime ? currentTime : stopTime;
                minSeek = 0;
                maxSeek =
                    calculatedStopTime.subtract(30, 's' /* live edge gap */).unix() -
                    unix(playoutData.startTime);
            }

            const seekTo =
                minSeek !== undefined && maxSeek !== undefined
                    ? Math.floor(Math.max(minSeek, Math.min(maxSeek, value)))
                    : value;

            playoutType === PLAYOUT_LIVE_RECORDING
                ? store.api.player.timeShift(seekTo)
                : store.api.player.seek(seekTo);

            trackWithPlayerContext(
                WebClientGAEvent.ScreenView,
                store.getState(),
                null,
                getCurrentScreen(),
                { pause: onHold },
            );
        },

        seekToLiveEdge: async () => {
            const { playout } = store.getState();
            const { playoutType, activeChannelId } = playout;

            if (playoutType === PLAYOUT_LIVE_RECORDING) {
                return store.api.playout.seekToPercentage(100);
            }

            // if player is in timeshift mode, start new live playout
            // deinit timeshift mode is handled in initLivePlayoutWithChannel
            if (playout.isTimeShifted) {
                await __actions.initLivePlayoutWithChannel(store.getState(), activeChannelId);
                trackWithPlayerContext(WebClientGAEvent.JumpToLiveEdge, store.getState());
            }

            return undefined;
        },

        unload: async (state) => {
            const { unmuteInteractionRequired } = state.playout;
            const { isFinished: playerIsFinished } = state.player;

            __actions.updateBackendRecordingPosition(state, playerIsFinished);
            await store.api.player.unload();
            revokePreviewImage();

            return {
                playout: {
                    ...state.playout,
                    ...initialState,
                    lastPinEntry: state.playout.lastPinEntry,
                    unmuteInteractionRequired,
                },
            };
        },

        /*
         * VOLUME HANDLING
         */

        initUnmuteIfNeeded: ({ playout }) => {
            const { unmuteInteractionRequired } = playout;

            if (unmuteInteractionRequired) {
                return __actions.setIsMuted({ playout }, false);
            }

            return { playout };
        },

        changeVolume: ({ playout, player = store.getState().player }, nextVolume) => {
            const { isVolumeReduced } = playout;
            const { volume } = player;
            const currentVolume = volume === -1 ? constants.OSD_DEFAULT_VOLUME : volume;

            const _nextVolume = Math.round(nextVolume);
            const nextMute = _nextVolume < 2;

            if (!nextMute) {
                store.api.player.setVolume(_nextVolume);
            }

            const nextState = {
                playout: {
                    ...playout,
                    isVolumeReduced: false,
                    lastVolume: isVolumeReduced ? _nextVolume : currentVolume,
                },
            };

            return __actions.setIsMuted(nextState, nextMute);
        },

        shiftVolume: ({ player }, direction = 1) => {
            const { volume } = player;

            return __actions.changeVolume(
                store.getState(),
                clamp(volume + constants.OSD_VOLUME_SHIFT_STEP * direction, 0, 100),
            );
        },

        increaseVolume: (state) => __actions.shiftVolume(state),

        decreaseVolume: (state) => __actions.shiftVolume(state, -1),

        // it is unsafe to use this action's state parameter as it might get mutated in initUnmuteIfNeeded
        setReducedVolumeFlag: (state, nextReduced = false) => {
            const { playout, player = store.getState().player } =
                __actions.initUnmuteIfNeeded(state);
            const { isVolumeReduced, lastVolume } = playout;
            const { volume, isMuted } = player;

            let vol = volume;

            if (nextReduced && volume > 25 && !isMuted) {
                vol = 25;
            } else if (isVolumeReduced) {
                vol = lastVolume;
            }

            store.api.player.setVolume(vol);

            return {
                playout: {
                    ...playout,
                    isVolumeReduced: nextReduced,
                    lastVolume: nextReduced ? volume : vol,
                },
            };
        },

        setIsMuted: ({ playout, player = store.getState().player }, nextMute = false) => {
            const { unmuteInteractionRequired } = playout;
            const { volume, isMuted } = player;

            const savedVolume = localStorageItem('playerVolume');
            const nextVolume = volume === -1 ? savedVolume || constants.OSD_DEFAULT_VOLUME : volume;

            if (nextMute === isMuted) {
                return { playout };
            }

            store.api.player.setVolume(nextVolume);
            store.api.player.toggleMute(nextMute);

            // ToDo: tracking actions need full store access,
            //  doesn't need to be an argument then, could be imported directly in tracking component
            trackWithPlayerContext(
                WebClientGAEvent.PlayerControls,
                store.getState(),
                WebClientGAEvent.ToggleMuted,
                getCurrentScreen(),
            );

            if (unmuteInteractionRequired) {
                return {
                    playout: {
                        ...playout,
                        unmuteInteractionRequired: false,
                    },
                };
            }

            return { playout };
        },

        /*
         * OSD
         */

        setFullscreenMode: ({ playout }, isFullscreenMode) => {
            const event = isFullscreenMode
                ? WebClientGAEvent.EnterFullscreen
                : WebClientGAEvent.EnterScreen;

            trackWithPlayerContext(event, store.getState());

            return {
                playout: {
                    ...playout,
                    isFullscreenMode,
                },
            };
        },

        updateOsdPanel: ({ playout }, nextVal) => {
            return {
                playout: {
                    ...playout,
                    panelState: nextVal,
                },
            };
        },

        hideOSD: ({ playout } = store.getState()) => {
            osdTimeout = null;
            const nextState = {
                playout: {
                    ...playout,
                    showOSD: false,
                    lockOSDToggle: false,
                },
            };

            !playout.onHold && store.setState(nextState);
        },

        toggleOSD: ({ playout }, show = true, lock = false) => {
            clearTimeout(osdTimeout);

            if (show && !lock) {
                osdTimeout = setTimeout(__actions.hideOSD, constants.OSD_HIDE_TIMEOUT);
            }

            if (show !== playout.showOSD || lock !== playout.lockOSDToggle) {
                return {
                    playout: {
                        ...playout,
                        showOSD: show,
                        lockOSDToggle: lock,
                    },
                };
            }

            return { playout };
        },

        showQuickzapper: ({ playout }) => {
            const { lockOSDToggle } = playout;
            clearTimeout(osdTimeout);

            if (!lockOSDToggle) {
                osdTimeout = setTimeout(__actions.hideOSD, constants.QUICKZAPPER_HIDE_TIMEOUT);
            }

            return __actions.updateOsdPanel({ playout }, OSD_PANEL_CHANNELS);
        },

        toggleShowVolume: (state, show) => {
            let { playout } = __actions.initUnmuteIfNeeded(state);

            const { lockOSDToggle, panelState, showVolume } = playout;
            const nextShow = show || !showVolume;

            if (!nextShow) {
                playout = __actions.toggleOSD(
                    { playout },
                    true,
                    panelState === 0 ? false : lockOSDToggle, // avoid interference with panel state
                ).playout;
            } else {
                playout = __actions.toggleOSD({ playout }, true, true).playout;
            }

            return {
                playout: {
                    ...playout,
                    showVolume: nextShow,
                },
            };
        },

        showErrorOverlay: ({ playout }, error, action = store.api.playout.playoutRetry) => {
            const { errorType, errorCode } = error;
            return {
                playout: {
                    ...playout,
                    playerError: {
                        errorCode,
                        errorType,
                        timestamp: unix(),
                        action: (...args) => {
                            store.api.playout.resetErrorOverlay();
                            action(...args);
                        },
                    },
                },
            };
        },

        resetErrorOverlay: ({ playout } = store.getState()) => {
            store.setState({
                playout: {
                    ...playout,
                    playerError: initialState.playerError,
                },
            });
        },

        setPlayoutCooldown: ({ playout } = store.getState()) => {
            store.setState({
                playout: {
                    ...playout,
                    activeCooldown: true,
                },
            });

            clearTimeout(playoutCooldownTimeout);

            playoutCooldownTimeout = setTimeout(
                __actions.clearPlayoutCooldown,
                constants.PLAYER_COOLDOWN_TIMEOUT,
            );
        },

        clearPlayoutCooldown: ({ playout } = store.getState()) => {
            store.setState({
                playout: {
                    ...playout,
                    activeCooldown: false,
                },
            });
        },

        parentalGuidanceClearance: (
            { playout } = store.getState(),
            clearance = CLEARANCE_LEVEL.CLEARED,
        ) => {
            return {
                playout: {
                    ...playout,
                    lastPinEntry: clearance === CLEARANCE_LEVEL.CLEARED ? unix() : null,
                    parentalGuidanceLevel: clearance,
                },
            };
        },
    };

    return __actions;
};

export default actions;
