import { styled } from '../../theme';

export const StyledTileContent = styled('figcaption', {
    display: 'flex',
    rowGap: '$5',
    flexDirection: 'column',
    color: '$mediumGray',
    padding: '$1 $7',

    backgroundColor: '$dark',
});
