import { getBaseUrl, urlWithParams } from '@exaring/utils';
import { get } from '../../request';

const base = getBaseUrl(window.location.href);
const EPG_BASE_URL = `https://epg-cache.${base}`;
const EPG_QUERY_BASE_URL = `https://epg-query.${base}`;

export const gridInfo = () => {
    return get(`${EPG_BASE_URL}/api/grid/info`);
};

export const grid = (channelId: string, dateTimeString: string) => {
    if (!dateTimeString.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/)) {
        throw Error('Expects ISO date in UTC-0 TZ (ex. 2022-12-29T04:30:00Z)');
    }

    return get(`${EPG_BASE_URL}/api/grid/${channelId.toLowerCase()}/${dateTimeString}`);
};

export const details = (programId: string) => {
    return get(`${EPG_BASE_URL}/api/programs/${programId}`);
};

export const query = (query: string, limit: number = 50) => {
    const req = get(urlWithParams(`${EPG_QUERY_BASE_URL}/api/programs/search`, { query, limit }));
    req.authorize = false;
    req.credentials = 'include';
    return req;
};
