/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { Fragment, FunctionComponent, h } from 'preact';
import { LayoutType, Program } from '../../ProgramDetailPage/types';
import { styled } from '../../theme';
import { Typography } from '../../Typography';
import { InfoItem } from './InfoItem';

const Root = styled('section', {
    flex: '1 100%',
    margin: '44px 0 0',
    '@md': {
        flexBasis: '50%',
    },
    variants: {
        layout: {
            modal: {
                margin: '20px 0 0',
            },
            epg: {},
            mediathek: {},
            microSite: {},
            player: {},
        },
    },
});

const ActorHeader = styled('div', {
    margin: '0',
    display: 'flex',
});

const Heading = styled(Typography, {
    margin: 0,
    flex: '1 0 50%',
    fontWeight: 600,
});

export const ProgramDetails: FunctionComponent<{
    program: Program;
    layout?: LayoutType;
}> = ({ program, layout }) => {
    const actorInfoItems = (program.actors || []).map((actor) => {
        return <InfoItem label={actor.name} value={actor.part} />;
    });

    return (
        <Root layout={layout}>
            {actorInfoItems.length > 0 && (
                <Fragment>
                    <ActorHeader>
                        <Heading variant="body2" tagName="h3">
                            Darsteller
                        </Heading>
                        <Heading variant="body2" tagName="h3">
                            Rolle
                        </Heading>
                    </ActorHeader>
                    {actorInfoItems}
                </Fragment>
            )}
        </Root>
    );
};
