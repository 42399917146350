import { alphabeticOnlyString, characterArrayToString, isString } from '../string';

type NumberKeys = 'numberOfFiles' | 'width' | 'height' | 'intervalInMilliseconds';
type StringKeys = 'recordingStartTime' | 'recordingStopTime';

type ExifCommentData = {
    numberOfFiles: number;
    width: number;
    height: number;
    intervalInMilliseconds: number;
    recordingStartTime: string;
    recordingStopTime: string;
};

const numberKeys: Array<string> = ['numberOfFiles', 'width', 'height', 'intervalInMilliseconds'];
const stringKeys: Array<string> = ['recordingStartTime', 'recordingStopTime'];

const isKnownKeyTypeOfString = (key: unknown): key is StringKeys =>
    isString(key) && stringKeys.includes(key);
const isKnownKeyTypeOfNumber = (key: unknown): key is NumberKeys =>
    isString(key) && numberKeys.includes(key);

export const parseExifCommentData = (charArray: Array<number>): ExifCommentData => {
    const defaultTags = characterArrayToString(charArray).replace('ASCII', '').split(';');

    const exifCommentData: ExifCommentData = {
        numberOfFiles: 0,
        width: 0,
        height: 0,
        intervalInMilliseconds: 0,
        recordingStartTime: '',
        recordingStopTime: '',
    };

    defaultTags.forEach((tag) => {
        const [key, value] = tag.split('=');

        if (!isString(key) || !isString(value)) {
            throw Error('comment data malformed');
        }

        const safeKey = alphabeticOnlyString(key); // cleanup ctrl characters etc.

        if (isKnownKeyTypeOfString(safeKey)) {
            exifCommentData[safeKey] = value;
        }
        if (isKnownKeyTypeOfNumber(safeKey)) {
            exifCommentData[safeKey] = parseInt(value, 10);
        }
    });

    return exifCommentData;
};
