import constants from '../constants';
import { post } from '../request';
import { playoutUrl as playoutUrlTvfuse } from './tuner';

const deviceCapabilities = (deviceInfo) =>
    post(
        `${constants.DEVICE_CAPABILITES_BASE_URL}/device-capabilities`,
        { 'Content-Type': `application/vnd.dc.device-info-v1+json` },
        deviceInfo,
    );

const playoutUrl = (body, newTv = false, deviceToken = '') =>
    post(
        `${constants.STREAM_URL_PROVIDER_BASE_URL}/stream-url`,
        {
            'Content-Type': 'application/vnd.streamurlprovider.stream-url-request-v1+json',
            'X-Device-Token': deviceToken,
            Accept: newTv
                ? 'application/vnd.streamurlprovider.newtv-stream-url-v1+json'
                : 'application/vnd.streamurlprovider.traditional-stream-url-v1+json',
        },
        body,
    );

export { deviceCapabilities, playoutUrl, playoutUrlTvfuse };
