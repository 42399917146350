import { h } from 'preact';
import constants from '../../../constants';
import { WebClientGAEvent } from '../../../web-client-ga';
import { trackWithUserContext } from '../../../tracking';

const AgeVerificationHint = () => {
    return (
        <div className="media-player-verification-hint">
            <div className="info--small">
                Bitte verifizieren Sie zum Abspielen dieses Inhaltes Ihr Alter im Kundenkonto.
            </div>
            <button
                type="button"
                className="button "
                onClick={() => {
                    trackWithUserContext(WebClientGAEvent.ParentalControlPinCreate);
                    window.location.href = constants.CSC_AGE_VERIFICATION_URL;
                }}
            >
                Jetzt verifizieren
            </button>
        </div>
    );
};

export default AgeVerificationHint;
