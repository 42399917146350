/**
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { useEffect } from 'preact/hooks';

export const useTimeout = (fn: () => void, timeoutInMs: number, deps: any[]) => {
    useEffect(() => {
        const id = setTimeout(fn, timeoutInMs);
        return () => clearTimeout(id);
    }, deps);
};
