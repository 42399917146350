import { CSS } from '../../theme';

export const Gradient: CSS = {
    background: `linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    $black
),
linear-gradient(
    to left,
    rgba(39, 137, 252, 30%),
    rgba(160, 80, 148, 30%)
)`,
};
