/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, FunctionComponent } from 'preact';
import { memo } from 'preact/compat';
import { useState, useEffect, useCallback } from 'preact/hooks';
import { CSS, styled } from '@exaring/ui/components-styled/theme';
import { events, dateDuration } from '@exaring/utils';
import { isRecording, isScheduled, isRecordable, isUnknown } from '@exaring/utils/data/recording';
import { serialRecordingAvailable } from '@exaring/utils/data/program';
import { RecordingState, RecordingStateValues } from '@exaring/utils/data/constants';

import { RecordingButton as WebUiRecordingButton } from '@exaring/ui/components-styled/EpgDetails';

import { isOnAir } from '@exaring/utils/date';
import {
    Recording,
    RecordingPlaceholder,
    isPlaceHolder,
} from '@exaring/networking/types/Recording/Recording';
import { Program } from '@exaring/ui/components-styled/ProgramDetailPage/types';
import { WebClientGA, WebClientGAEvent, WebClientGAEventValues } from '../../web-client-ga';
import { routeToEpg } from '../../routes';

import RecordingButtonPopup from './RecordingButtonPopup';
import { getAvailableRecordingSeconds } from '../../actions/helper';
import { useRecordingInit } from '../Recording2/hooks/useRecordingInit';
import { useRecordingStore, notificationsStore, useUserStore } from '../../state/Store';
import { RemoteDataStates, didSucceed, didNotAsk } from '../../state/utils/RemoteData';
import { getCurrentScreen } from '../../helper';
import { getProgramDetailsScreenName } from '../../../../../packages/ui/components-styled/ProgramDetailPage/NavigationButtons';

const RecordingButtonWrapper = styled('div', { position: 'relative' });

export type ProgramData = {
    id: string;
    stationId: string;
    startTime: string;
    stopTime: string;
    series?: any;

    title?: string;
    isProgramInLockedChannel?: boolean;
};

export const programToProgramData = (
    program: Program,
    isProgramInLockedChannel?: boolean,
): ProgramData => ({
    id: program.id,
    stationId: program.channel,
    startTime: program.startTime,
    stopTime: program.stopTime,
    series: program.series,
    title: program.title,
    isProgramInLockedChannel,
});

const filterExistingGroupRecording =
    (program: ProgramData) => (rec: Recording | RecordingPlaceholder) => {
        return rec.title === program.title && rec.stationId === program.stationId;
    };

const RecordingButton: FunctionComponent<{
    program: ProgramData;
    showLabel: boolean;
    className?: string;
    triggerAutoRecord?: boolean;
    size?: 'auto' | 'parent' | 'big' | 'responsive';
    spacing?: 'default' | 'tiny' | 'minimal';
    text?: 'default' | 'tiny' | 'onlyOnDesktop';
    iconSize?: number;
    css?: CSS;
    availableRecordingSeconds?: number;
    shouldOpenUpPopup?: boolean;
    isProgramDetails?: boolean;
}> = ({
    program,
    showLabel,
    className,
    triggerAutoRecord,
    size,
    spacing,
    text,
    iconSize,
    css,
    shouldOpenUpPopup = false,
    isProgramDetails = false,
}) => {
    useRecordingInit();

    const {
        schedule,
        unschedule,
        unscheduleSerials,
        initRecordingsSummary,
        scheduleSerial,
        stop,
        getRecordingGroup,
        recordingsSummary,
        recordings: recordingRemoteData,
        recordingGroups,
    } = useRecordingStore();

    const notifications = notificationsStore();
    const currentScreen = getCurrentScreen();

    const recordings = didSucceed(recordingRemoteData) ? recordingRemoteData.value : [];

    const recordingsState = recordingRemoteData.status;

    const [showPopupMenu, togglePopupMenu] = useState(false);
    const [recordingId, setRecordingId] = useState<string>('');
    const [recordingGroupId, setRecordingGroupId] = useState<number>(0);

    const [recordingState, setRecordingState] = useState<RecordingStateValues>(
        RecordingState.RECORDABLE,
    );

    const { isFreeUser } = useUserStore();

    if (didNotAsk(recordingsSummary)) {
        initRecordingsSummary();
    }

    useEffect(() => {
        // get all recordings for programId
        let foundRecordings;
        // second guess if program is part of recording group
        if (program.series?.id) {
            // unfortunately we have to look up all known recordings to find the aggregated "group recording"
            // we only can match this group recording by title and station id

            // first we filter for all recordings which are related to that group
            foundRecordings = recordings.filter(filterExistingGroupRecording(program));
            const foundGroupRecording = foundRecordings[0];
            // if there is a matched aggregated group recording we have to lazy load all members
            // to match potential recordings of that group after loading them

            if (
                foundGroupRecording &&
                !isPlaceHolder(foundGroupRecording) &&
                foundGroupRecording.recordingGroup
            ) {
                const recordingGroup = recordingGroups[foundGroupRecording.recordingGroup];

                if (recordingGroup) {
                    if (didSucceed(recordingGroup)) {
                        foundRecordings = recordingGroup.value; // set recordings with group recordings as it contains all recordings that are relevant for epg data
                    }
                } else {
                    getRecordingGroup(
                        foundGroupRecording.recordingGroup,
                        foundGroupRecording.seriesId,
                    );
                }
            } else {
                // case where only the group recording is scheduled but not the single recording
                foundRecordings = recordings.filter((rec) => rec.programId === program.id); // look for single recordings
            }
        } else {
            foundRecordings = recordings.filter((rec) => rec.programId === program.id); // look for single recordings
        }

        // active recordings
        const activeRecording = foundRecordings.find((rec) => rec.status === 'RECORDING');
        const scheduledRecording = foundRecordings.find((rec) => rec.status === 'SCHEDULED');
        const finishedRecording = foundRecordings.find((rec) => rec.status === 'FINISHED'); // this is needed to show a recording state for finished recording group member (yay)
        const unknownRecording = foundRecordings.find((rec) => rec.status === 'UNKNOWN');

        const recordingItem =
            activeRecording || scheduledRecording || finishedRecording || unknownRecording;

        if (recordingItem) {
            setRecordingState(recordingItem.status);
            setRecordingId(recordingItem.id);
            setRecordingGroupId(recordingItem?.recordingGroup || 0);
        } else {
            setRecordingState(
                recordingsState === RemoteDataStates.Success || // as we do not have any error state, error or success state should always show recordable
                    recordingsState === RemoteDataStates.Failed
                    ? RecordingState.RECORDABLE
                    : RecordingState.UNKNOWN,
            );
            setRecordingId('');
            setRecordingGroupId(0);
        }
    }, [recordings, program, recordingState, recordingsState, getRecordingGroup, recordingGroups]);

    const handleTracking = useCallback(
        (
            eventName: WebClientGAEventValues,
            eventDescription: WebClientGAEventValues,
            screenName?: string,
        ) => {
            WebClientGA().trackEvent({
                eventName,
                eventDescription,
                screenName,
                channelName: program.stationId,
                programId: program.id,
                programTitle: program?.title,
            });
        },
        [program.stationId, program.id, program.title],
    );

    const handleSingle = useCallback(
        async (e?: any) => {
            if (isScheduled(recordingState)) {
                unschedule([recordingId]);
                if (recordingGroupId) {
                    handleTracking(
                        WebClientGAEvent.Recordings,
                        WebClientGAEvent.StopSerialRecording,
                        isProgramDetails
                            ? getProgramDetailsScreenName(program as any)
                            : currentScreen,
                    );
                } else {
                    handleTracking(
                        WebClientGAEvent.Recordings,
                        WebClientGAEvent.StopRecording,
                        isProgramDetails
                            ? getProgramDetailsScreenName(program as any)
                            : currentScreen,
                    );
                }
            } else if (isRecording(recordingState)) {
                stop(recordingId);
                if (recordingGroupId) {
                    handleTracking(
                        WebClientGAEvent.Recordings,
                        WebClientGAEvent.StopSerialRecording,
                        isProgramDetails
                            ? getProgramDetailsScreenName(program as any)
                            : currentScreen,
                    );
                } else {
                    handleTracking(
                        WebClientGAEvent.Recordings,
                        WebClientGAEvent.StopRecording,
                        isProgramDetails
                            ? getProgramDetailsScreenName(program as any)
                            : currentScreen,
                    );
                }
            } else {
                schedule(program.id, undefined, isOnAir(program.startTime, program.stopTime));
                handleTracking(
                    WebClientGAEvent.Recordings,
                    WebClientGAEvent.ScheduleRecording,
                    isProgramDetails
                        ? getProgramDetailsScreenName(program as any)
                        : getCurrentScreen(),
                );
            }
            if (e) {
                events.pauseEvent(e);
            }
            togglePopupMenu(false);
        },
        [handleTracking, program, recordingId, recordingState, schedule, stop, unschedule],
    );

    let availableRecordingSeconds: number | undefined;

    if (didSucceed(recordingsSummary)) {
        const { maxStorage, usedStorage } = recordingsSummary.value;
        availableRecordingSeconds = getAvailableRecordingSeconds(maxStorage, usedStorage);
    }

    const handleClick = useCallback(
        (e?: any) => {
            if (e) {
                events.pauseEvent(e);
            }

            if (isFreeUser && program.isProgramInLockedChannel) {
                notifications.createUpsellingToast();
                return;
            }

            if (
                isRecordable(recordingState) &&
                !isFreeUser &&
                availableRecordingSeconds !== undefined &&
                availableRecordingSeconds - dateDuration(program.startTime, program.stopTime) < 0
            ) {
                notifications.createRecordingStorageUpsellingToast();
                return;
            }
            if (!isUnknown(recordingState)) {
                if (isFreeUser) {
                    notifications.createUpsellingDialog();
                    return;
                }

                if (serialRecordingAvailable(program) || recordingGroupId) {
                    if (
                        (isScheduled(recordingState) || isRecording(recordingState)) &&
                        !recordingGroupId
                    ) {
                        // special handling for serials were the user scheduled a single item but not the whole serial
                        // then we don't show the option to delete the whole serial
                        handleSingle();
                    } else {
                        togglePopupMenu(true);
                    }
                } else {
                    handleSingle();
                }
            }
        },
        [
            recordingState,
            program,
            recordingGroupId,
            isFreeUser,
            handleSingle,
            availableRecordingSeconds,
        ],
    );

    useEffect(() => {
        if (
            recordingsState === RemoteDataStates.Success &&
            isRecordable(recordingState) &&
            triggerAutoRecord
        ) {
            handleClick();
            routeToEpg(program.stationId, program.id, false, true); // remove auto record flag after calling recording logic
        }
    }, [
        recordingState,
        recordingsState,
        triggerAutoRecord,
        handleClick,
        program.stationId,
        program.id,
    ]);

    const handleSerials = useCallback(
        (e?: any) => {
            if (isScheduled(recordingState) || isRecording(recordingState)) {
                handleTracking(
                    WebClientGAEvent.Recordings,
                    WebClientGAEvent.StopSerialRecording,
                    getCurrentScreen(),
                );
                if (recordingGroupId) {
                    unscheduleSerials(
                        [recordingGroupId],
                        /* stopRunningRecording */ isRecording(recordingState),
                    );
                } else {
                    console.error(new Error('try to unschedule a non scheduled serial'));
                }
            } else {
                const getScreen = () => {
                    if (isProgramDetails) {
                        return getProgramDetailsScreenName(program as any);
                    }
                    if (getCurrentScreen() === 'player_livetv') {
                        return 'player_livetv';
                    }
                    return 'recordings';
                };
                scheduleSerial(
                    program.id,
                    program.stationId,
                    program.title,
                    program.series.id,
                    isOnAir(program.startTime, program.stopTime),
                );
                handleTracking(
                    WebClientGAEvent.Recordings,
                    WebClientGAEvent.ScheduleSerialRecording,
                    getScreen(),
                );
            }
            if (e) {
                events.pauseEvent(e);
            }
            togglePopupMenu(false);
        },
        [
            unscheduleSerials,
            scheduleSerial,
            handleTracking,
            program,
            recordingGroupId,
            recordingState,
        ],
    );

    const getButtonCfg = (rs: string) => {
        let label = '';
        let iconName: 'spinner' | 'stop' | 'delete' | 'start';
        let title;

        switch (rs) {
            case RecordingState.UNKNOWN:
                // no label/text, only icon
                iconName = 'spinner';
                title = 'Bitte warten';
                break;
            case RecordingState.RECORDING:
                label = 'Stoppen';
                iconName = 'stop';
                title = 'Aufnahme stoppen';
                break;
            case RecordingState.SCHEDULED:
                label = 'Aufnahme verwerfen';
                iconName = 'stop';
                title = 'Aufnahme verwerfen';
                break;
            case RecordingState.FINISHED:
            case RecordingState.RECORDABLE:
            default:
                label = 'Aufnehmen';
                iconName = 'start';
                title = 'Aufnehmen';
        }

        return {
            label,
            iconName,
            title,
        };
    };

    const { label, iconName, title } = getButtonCfg(recordingState);

    return (
        <RecordingButtonWrapper key={recordingId}>
            <WebUiRecordingButton
                iconSize={iconSize}
                iconName={iconName}
                className={className}
                title={title}
                onClick={handleClick}
                layout={showLabel ? 'default' : 'minimal'}
                label={showLabel ? label : undefined}
                size={size}
                spacing={spacing}
                text={text}
                css={{
                    ...css,
                    ...(showPopupMenu ? { opacity: 0.5 } : {}),
                    ...(isUnknown(recordingState) ? { cursor: 'default' } : {}),
                }}
            />
            {showPopupMenu && (
                <RecordingButtonPopup
                    recordingState={recordingState}
                    showLabel={showLabel}
                    handleSingle={handleSingle}
                    handleSerials={handleSerials}
                    shouldOpenUp={shouldOpenUpPopup}
                    closePopupMenu={(e?: any) => {
                        if (e) {
                            events.pauseEvent(e);
                        }
                        togglePopupMenu(false);
                    }}
                />
            )}
        </RecordingButtonWrapper>
    );
};

export default memo(RecordingButton);
