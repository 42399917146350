/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { useEffect } from 'preact/hooks';
import ZingTouch from 'zingtouch';

export const SwipeDirection = {
    Up: 1,
    Right: 2,
    Down: 3,
    Left: 4,
};

export const swipeDirection = (deg) => {
    const swipeUpAngle = deg >= 45 && deg <= 135;
    const swipeDownAngle = deg >= 225 && deg <= 315;

    const isLeftSwipe = deg > 135 && deg < 225;

    if (swipeUpAngle || swipeDownAngle) {
        return swipeUpAngle ? SwipeDirection.Up : SwipeDirection.Down;
    }

    return isLeftSwipe ? SwipeDirection.Left : SwipeDirection.Right;
};

export const useTouchEvents = ({ region, selector, eventMap }) => {
    useEffect(() => {
        const zt = new ZingTouch.Region(
            document.querySelector(region),
            /* capture:false */ false,
            /* preventDefault:true */ false,
        );

        const eventIndex = Object.keys(eventMap);

        eventIndex.forEach((eventName) => {
            zt.bind(document.querySelector(selector), eventName, eventMap[eventName]);
        });

        return () => {
            eventIndex.forEach((_eventName) => {
                zt.unregister(_eventName);
            });
        };
    }, [region, selector, eventMap]);
};
