/* eslint-disable no-restricted-syntax */
/* extracted from https://github.com/google/shaka-player/blob/master/lib/util/xml_utils.js */

/*! @license
 * Shaka Player
 * Copyright 2016 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * Parses an XML duration string.
 * Negative values are not supported. Years and months are treated as exactly
 * 365 and 30 days respectively.
 * @param {string} durationString The duration string, e.g., "PT1H3M43.2S",
 *   which means 1 hour, 3 minutes, and 43.2 seconds.
 * @return {?number} The parsed duration in milliseconds on success; otherwise,
 *   return undefined.
 * @see {@link http://www.datypic.com/sc/xsd/t-xsd_duration.html}
 */
export const parseDuration = (durationString?: string): number | undefined => {
    if (!durationString) {
        return undefined;
    }

    const re =
        '^P(?:([0-9]*)Y)?(?:([0-9]*)M)?(?:([0-9]*)D)?' +
        '(?:T(?:([0-9]*)H)?(?:([0-9]*)M)?(?:([0-9.]*)S)?)?$';
    const matches = new RegExp(re).exec(durationString);

    if (!matches) {
        return undefined;
    }

    // Note: Number(null) == 0 but Number(undefined) == NaN.
    const years = Number(matches[1] || null);
    const months = Number(matches[2] || null);
    const days = Number(matches[3] || null);
    const hours = Number(matches[4] || null);
    const minutes = Number(matches[5] || null);
    const seconds = Number(matches[6] || null);

    // Assume a year always has 365 days and a month always has 30 days.
    const d =
        60 * 60 * 24 * 365 * years +
        60 * 60 * 24 * 30 * months +
        60 * 60 * 24 * days +
        60 * 60 * hours +
        60 * minutes +
        seconds;
    return Number.isFinite(d) ? Math.ceil(d * 1000.0) : undefined;
};
