/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';

import { classnames } from '@exaring/utils';
import { Navigation, Button } from '@exaring/ui';
import { routeTo, Routes } from '../../routes';
import { WebClientGA, WebClientGAEvent } from '../../web-client-ga';
import { useEpgStore } from '../../state/Store';
import { getCurrentScreen } from '../../helper';

const EpgChannelNavigation = ({ name }) => {
    const { showOnlyFavorites: isFavoriteFilterOn, setFavorites: setFavoriteMode } = useEpgStore();
    const context = name || 'epg';
    const buttonBaseClasses = 'button--navigation button--icon button--medium';
    const buttonActiveClass = 'is-active';
    const favButtonClasses = classnames(buttonBaseClasses, isFavoriteFilterOn && buttonActiveClass);
    const channelButtonClasses = classnames(
        buttonBaseClasses,
        !isFavoriteFilterOn && buttonActiveClass,
    );

    return (
        <Navigation name="epg-channel">
            <Navigation name="epg-channel-navigation__favorite" className="navigation--group">
                <Button
                    className={favButtonClasses}
                    title="Favoriten"
                    label={
                        <span
                            className={`icon icon--sm icon--favorite-toggle-${
                                isFavoriteFilterOn ? 'on' : 'off'
                            }`}
                        />
                    }
                    action={() => {
                        WebClientGA().trackEvent(
                            {
                                eventName: WebClientGAEvent.QuickZapper,
                                eventDescription: WebClientGAEvent.DisplayFavourites,
                                screenName: getCurrentScreen(),
                            },
                            { context },
                        );
                        setFavoriteMode(true);
                    }}
                />

                <Button
                    className={channelButtonClasses}
                    title="Alle Sender"
                    label={
                        <span
                            className={`icon icon--sm icon--all-channels-toggle-${
                                !isFavoriteFilterOn ? 'on' : 'off'
                            }`}
                        />
                    }
                    action={() => {
                        WebClientGA().trackEvent(
                            {
                                eventName: WebClientGAEvent.QuickZapper,
                                eventDescription: WebClientGAEvent.DisplayAllChannels,
                                screenName: getCurrentScreen(),
                            },
                            { context },
                        );
                        setFavoriteMode(false);
                    }}
                />
            </Navigation>
            <Button
                className="button--navigation button--icon button--big"
                title="Sendersortierung"
                label={<span className="icon icon--sm icon--channel-sort" />}
                action={() => {
                    WebClientGA().trackEvent(
                        {
                            eventName: WebClientGAEvent.QuickZapper,
                            eventDescription: WebClientGAEvent.OpenChannelSort,
                            screenName: getCurrentScreen(),
                        },
                        { context },
                    );
                    routeTo(Routes.CHANNELCONFIG_PATH);
                }}
            />
        </Navigation>
    );
};

export default EpgChannelNavigation;
