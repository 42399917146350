/*
 *  Copyright (C) Exaring AG - All Rights Reserved
 */

import epg2Service from '@exaring/networking/services/epg2';
import { SearchResultResponse, isSearchResult } from '@exaring/networking/types/Epg/SearchResult';
import { StoreSlice } from '../utils/StoreSlice';
import constants from '../../constants';
import { RemoteData, remoteDataStateResolver, notAsked } from '../utils/RemoteData';

type SearchData = SearchResultResponse;

export type State = {
    search: RemoteData<SearchData>;
    query: string;

    getResultData: () => SearchData;
    resetState: () => void;
    searchEpg: (query: string) => void;
    updateQuery: (query: string) => void;
    resetResultData: () => void;
};

export const initialState = {
    search: notAsked(),
    query: '',
};

export const State: StoreSlice<State> = (set, get) => ({
    ...initialState,

    getResultData: () => {
        const { search } = get();

        return search.status === 'success' ? search.value : [];
    },

    resetState: () => {
        const { resetResultData, updateQuery } = get();
        updateQuery('');
        resetResultData();
    },

    updateQuery(query) {
        set((state) => {
            state.query = query;
        }, 'Search/updateQuery');
    },

    resetResultData() {
        set((state) => {
            state.search = notAsked();
        }, 'Search/resetResultData');
    },

    searchEpg: async (query) =>
        remoteDataStateResolver(
            () => epg2Service.query(query, constants.SEARCH_RESULT_LIMIT), // resource
            isSearchResult, // type guard for response data
            (resolvedState) => {
                set((state) => {
                    state.search = resolvedState;
                }, 'Search/searchEpg');
            },
        ),
});
