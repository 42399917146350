/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { Component } from 'preact';
import { events as foo } from '@exaring/utils';
import ZingTouch from 'zingtouch';

/* eslint-disable no-bitwise */
export const SwipeDirection = {
    Up: 1 << 0,
    Right: 1 << 1,
    Down: 1 << 2,
    Left: 1 << 3,
};
/* eslint-enable no-bitwise */

export class BaseUi extends Component {
    constructor(props) {
        super(props);

        this._touchEventRegions = [];
    }

    componentDidMount() {
        const { mouseEventMap, keyboardEventMap, touchEventSelectorMap } = this;

        if (touchEventSelectorMap) {
            const selMap = Array.isArray(touchEventSelectorMap)
                ? touchEventSelectorMap
                : [touchEventSelectorMap];

            selMap.forEach((evtCfg) => {
                const zt = new ZingTouch.Region(
                    document.querySelector(evtCfg.reg),
                    /* capture:false */ false,
                    /* preventDefault:true */ false,
                );

                Object.keys(evtCfg.evtMap).forEach((evtName) => {
                    zt.bind(document.querySelector(evtCfg.sel), evtName, evtCfg.evtMap[evtName]);
                });

                this._touchEventRegions.push({ zt, evtMap: evtCfg.evtMap });
            });
        }

        if (mouseEventMap) {
            this.bindEventMap(mouseEventMap);
        }

        if (keyboardEventMap) {
            this.bindEventMap(keyboardEventMap);
        }
    }

    componentWillUnmount() {
        const { mouseEventMap } = this;
        const { keyboardEventMap } = this;
        const { touchEventSelectorMap } = this;

        if (touchEventSelectorMap) {
            this._touchEventRegions.forEach((evtReg) => {
                Object.keys(evtReg.evtMap).forEach((evtName) => {
                    evtReg.zt.unregister(evtName);
                });
            });
        }

        if (mouseEventMap) {
            this.unbindEventMap(mouseEventMap);
        }

        if (keyboardEventMap) {
            this.unbindEventMap(keyboardEventMap);
        }
    }

    // eslint-disable-next-line class-methods-use-this
    touchEventSelector(selector, region, eventMap) {
        return {
            sel: selector,
            reg: region,
            evtMap: eventMap,
        };
    }

    // eslint-disable-next-line class-methods-use-this
    swipeDirection(deg) {
        const swipeUpAngle = deg >= 45 && deg <= 135;
        const swipeDownAngle = deg >= 225 && deg <= 315;

        const isLeftSwipe = deg > 135 && deg < 225;

        if (swipeUpAngle || swipeDownAngle) {
            return swipeUpAngle ? SwipeDirection.Up : SwipeDirection.Down;
        }

        return isLeftSwipe ? SwipeDirection.Left : SwipeDirection.Right;
    }

    // eslint-disable-next-line class-methods-use-this
    bindEventMap(eventMap) {
        foo.addEventsTo(document, eventMap);
    }

    // eslint-disable-next-line class-methods-use-this
    unbindEventMap(eventMap) {
        foo.removeEventsFrom(document, eventMap);
    }
}
