/* eslint-disable import/no-unused-modules */
import { h } from 'preact';
import { CSS } from '@stitches/react';
import { localStorageItem } from '@exaring/utils';
import { ModalIcon } from '@exaring/ui/components-styled/BigToast/BigToastIcon';
import constants from '../constants';
// eslint-disable-next-line import/no-cycle
import { routeTo, Routes } from '../routes';
import { WebClientGA, WebClientGAEvent, WebClientGAEventValues } from '../web-client-ga';
import { StoreSlice } from './utils/StoreSlice';

const generateId = () => `${Math.random().toString(36).slice(2)}${Date.now().toString(36)}`;

const trackEvent = (
    eventName: WebClientGAEventValues,
    eventDescription?: WebClientGAEventValues,
    screenName?: string,
) => WebClientGA().trackEvent({ eventName, eventDescription, screenName });

type Toast = { id: string; type: 'toast'; props: ToastProps };
type Snack = { id: string; type: 'snack'; props: SnackProps };
type Hint = { id: string; type: 'hint'; props: HintProps };

type TNotification = Toast | Snack | Hint;

type ToastProps = {
    title: string;
    message: () => h.JSX.Element | string;
    icon: ModalIcon;
    confirmLabel: string;
    cancelLabel: string;
    onConfirm?: () => Promise<void> | void;
    onCancel?: () => Promise<void> | void;
};

type SnackProps = {
    type: 'success' | 'info' | 'error';
    message: string;
    onClose?: () => void;
    css?: CSS;
};

type HintProps = {
    title: string;
    titleEmphasize?: string | number;
    message?: string;
    confirmLabel?: string;
    cancelLabel?: string;
    onConfirm?: () => Promise<void> | void;
    onCancel?: () => Promise<void> | void;
    onClose?: () => Promise<void> | void;
};

export type State = {
    notifications: Array<TNotification>;

    reset: () => void;

    createToast: (props: ToastProps, actionName: string) => string;
    createSnack: (props: SnackProps, actionName: string) => string;
    createHint: (props: HintProps, actionName: string) => string;
    deleteNotification: (id: string, actionName: string) => void;

    confirmDeleteRecordings: (numberOfRecordings?: number, props?: Partial<ToastProps>) => string;
    doneDeleteRecordings: (recordingsCount: number, props?: Partial<SnackProps>) => string;
    errorDeleteRecordings: (recordingsCount: number, props?: Partial<SnackProps>) => string;

    toggleFavorite: (isFavorite: boolean, props?: Partial<SnackProps>) => string;

    createUpsellingToast: (props?: Partial<ToastProps>) => string;

    createUpsellingDialog: (props?: Partial<ToastProps>) => string;

    createRecordingStorageUpsellingToast: (props?: Partial<ToastProps>) => string;
    createRecordingStorageHint: (
        remainingStorageInHoursAndMinutes: string,
        props?: Partial<HintProps>,
    ) => string;
    createInstantReplayHint: (programTitle: string, props?: Partial<HintProps>) => string;
};

// Type guards
export const isHint = (notification: any): notification is Hint => {
    return notification.type === 'hint';
};

export const isToast = (notification: any): notification is Toast => {
    return notification.type === 'toast';
};

export const isSnack = (notification: any): notification is Snack => {
    return notification.type === 'snack';
};

export const State: StoreSlice<State> = (set) => {
    const deleteNotification = (id: string, actionName: string) => {
        set((state: State) => {
            state.notifications = state.notifications.filter((n) => n.id !== id);
        }, actionName);
    };

    const reset = () => {
        set((state: State) => {
            state.notifications = [];
        }, 'Notifications/reset');
    };

    const fireAndForget =
        (id: string, actionName: string, fn: () => Promise<void> | void = () => {}) =>
        async () => {
            if (fn) {
                await fn();
            }
            deleteNotification(id, actionName);
        };

    const createToast = (props: ToastProps, actionName: string) => {
        const id = generateId();

        set((state: State) => {
            state.notifications.push({
                id,
                type: 'toast',
                props: {
                    ...props,
                    onConfirm: fireAndForget(id, 'Notifications/clearToast', props.onConfirm),
                    onCancel: fireAndForget(id, 'Notifications/clearToast', props.onCancel),
                },
            });
        }, actionName);

        return id;
    };

    const createSnack = (props: SnackProps, actionName: string) => {
        const id = generateId();

        set((state: State) => {
            state.notifications.push({
                id,
                type: 'snack',
                props: {
                    ...props,
                    onClose: fireAndForget(id, 'Notifications/clearSnack', props.onClose),
                },
            });
        }, actionName);

        return id;
    };

    const createHint = (props: HintProps, actionName: string) => {
        const id = generateId();

        set((state: State) => {
            state.notifications.push({
                id,
                type: 'hint',
                props: {
                    ...props,
                    onConfirm: fireAndForget(id, 'Notifications/clearHint', props.onConfirm),
                    onCancel: fireAndForget(id, 'Notifications/clearHint', props.onCancel),
                    onClose: fireAndForget(id, 'Notifications/clearHint', props.onClose),
                },
            });
        }, actionName);

        return id;
    };

    return {
        notifications: [],

        reset,

        createToast,
        createSnack,
        createHint,
        deleteNotification,

        confirmDeleteRecordings: (numberOfRecordings?: number, props?: Partial<ToastProps>) =>
            createToast(
                {
                    title: 'Ausgewählte Inhalte löschen',
                    message: () =>
                        'Sind Sie sicher, dass sie diese Inhalte unwiderruflich löschen wollen?',
                    icon: 'trashcan',
                    confirmLabel:
                        numberOfRecordings !== undefined
                            ? `Löschen (${numberOfRecordings})`
                            : 'Löschen',
                    cancelLabel: 'Abbrechen',
                    ...props,
                },
                'Notifications/confirmDeleteRecordings',
            ),

        doneDeleteRecordings: (recordingsCount: number, props?: Partial<SnackProps>) =>
            createSnack(
                {
                    type: 'success',
                    message: `${
                        recordingsCount > 1
                            ? `${recordingsCount} Aufnahmen wurden`
                            : 'Eine Aufnahme wurde'
                    } gelöscht`,
                    onClose: () => {},
                    ...props,
                },
                'Notifications/doneDeleteRecordings',
            ),

        errorDeleteRecordings: (recordingsCount: number, props?: Partial<SnackProps>) =>
            createSnack(
                {
                    type: 'error',
                    message: `Aufnahme${recordingsCount > 1 ? 'n' : ''} konnte${
                        recordingsCount > 1 ? 'n' : ''
                    } nicht gelöscht werden`,
                    onClose: () => {},
                    ...props,
                },
                'Notifications/doneDeleteRecordings',
            ),

        toggleFavorite: (isFavorite: boolean, props?: Partial<SnackProps>) =>
            createSnack(
                {
                    type: 'success',
                    message: isFavorite ? 'Aus Favoriten entfernt' : 'Zu Favoriten hinzugefügt',
                    ...props,
                },
                'Notifications/toggleFavorite',
            ),

        createUpsellingToast: (props?: Partial<ToastProps>) =>
            createToast(
                {
                    title: 'Jetzt upgraden & über 200 TV-Sender genießen!',
                    message: () => (
                        <p>
                            Dieser Sender ist nicht Teil Ihres TV-Pakets. Sie können unter „
                            <a
                                href={constants.CSC_HOME_URL}
                                style={{ textDecoration: 'underline' }}
                            >
                                Mein Konto
                            </a>
                            “ Ihre Buchung anpassen oder unter{' '}
                            <a href={constants.OFFERS_URL} style={{ textDecoration: 'underline' }}>
                                waipu.tv/angebote
                            </a>{' '}
                            unsere TV-Pakete vergleichen.
                        </p>
                    ),
                    icon: 'lock',
                    confirmLabel: 'Jetzt buchen',
                    cancelLabel: 'Schließen',
                    onConfirm: () => {
                        trackEvent(
                            WebClientGAEvent.Funnel,
                            WebClientGAEvent.StartUpgrade,
                            'upsell_blocker',
                        );
                        window.location.href = constants.CSC_HOME_URL;
                    },
                    ...props,
                },
                'Notifications/createRecordingStorageUpselling',
            ),

        createUpsellingDialog: (props?: Partial<ToastProps>) =>
            createToast(
                {
                    title: 'Jetzt upgraden & nicht’s mehr verpassen!',
                    message: () => (
                        <p>
                            Die Aufnahmefunktion ist nicht Teil Ihres TV-Pakets. Einfach unter “
                            <a
                                href={constants.CSC_HOME_URL}
                                target="_blank"
                                style={{ textDecoration: 'underline' }}
                                rel="noreferrer"
                            >
                                Mein Konto
                            </a>
                            “ Ihre Buchung anpassen oder unsere{' '}
                            <a
                                target="_blank"
                                href={constants.OFFERS_URL}
                                style={{ textDecoration: 'underline' }}
                                rel="noreferrer"
                            >
                                TV-Pakete
                            </a>{' '}
                            vergleichen.
                        </p>
                    ),
                    icon: 'lock',
                    confirmLabel: '1 Monat kostenlos testen',
                    cancelLabel: 'Schließen',
                    onConfirm: () => {
                        trackEvent(
                            WebClientGAEvent.Funnel,
                            WebClientGAEvent.StartUpgrade,
                            'upsell_blocker',
                        );
                        window.open(constants.FUNNEL_URL, '_blank');
                    },
                    ...props,
                },
                'Notifications/createUpsellingDialog',
            ),

        createRecordingStorageUpsellingToast: (props?: Partial<ToastProps>) =>
            createToast(
                {
                    title: 'Mehr Speicher benötigt',
                    message: () =>
                        'Buchen Sie jetzt neuen Aufnahmespeicher oder löschen Sie einige Aufnahmen, um weitere Inhalte aufzunehmen oder gesperrte Aufnahmen anzusehen.',
                    icon: 'lock',
                    confirmLabel: 'Jetzt buchen',
                    cancelLabel: 'Schließen',
                    onConfirm: () => {
                        trackEvent(
                            WebClientGAEvent.Funnel,
                            WebClientGAEvent.StartUpgrade,
                            'upsell_blocker',
                        );
                        window.location.href = `${constants.CSC_CHECKOUT_URL}/option/RECORDING`;
                    },
                    onCancel: props?.onCancel,
                    ...props,
                },
                'Notifications/createRecordingStorageUpselling',
            ),

        createRecordingStorageHint: (
            remainingStorageInHoursAndMinutes: string,
            props?: Partial<HintProps>,
        ) => {
            let hideTimeout: NodeJS.Timeout;
            trackEvent(WebClientGAEvent.PlayerStorageHintDisplay);

            const id = createHint(
                {
                    title: 'Verfügbarer Aufnahmespeicher:',
                    titleEmphasize: remainingStorageInHoursAndMinutes,
                    message: 'Löschen Sie Aufnahmen, um keine geplante Sendung zu verpassen',
                    confirmLabel: 'Zu den Aufnahmen',
                    cancelLabel: 'Verstanden',
                    onConfirm: () => {
                        routeTo(Routes.RECORDING_PAGE);
                        trackEvent(WebClientGAEvent.PlayerStorageHintGoToRecordings);
                    },
                    onCancel: () => {
                        localStorageItem('user_storage_optout', true);
                        clearTimeout(hideTimeout);
                        trackEvent(WebClientGAEvent.PlayerStorageHintOptOut);
                    },
                    onClose: () =>
                        trackEvent(
                            WebClientGAEvent.Recordings,
                            WebClientGAEvent.HideStorageHint,
                            'recordings',
                        ),
                    ...props,
                },
                'Notifications/createRecordingStorageHint',
            );

            hideTimeout = setTimeout(() => {
                trackEvent(WebClientGAEvent.PlayerStorageHintAutoHide);
                deleteNotification(id, 'Notifications/createRecordingStorageHintAutoHide');
            }, constants.NOTIFICATIONS_AUTO_DISSMISS_TIME);

            return id;
        },

        createInstantReplayHint: (programTitle: string, props?: Partial<HintProps>) => {
            return createHint(
                {
                    title: `Die zeitversetzte Wiedergabe der Sendung "${programTitle}" wurde verlassen. Wollen Sie diese fortsetzen?`,
                    confirmLabel: 'Ok',
                    ...props,
                },
                'Notifications/createInstantReplayHint',
            );
        },

        seekingNotAllowed: (props?: Partial<SnackProps>) =>
            createSnack(
                {
                    type: 'info',
                    message: 'Der Sender erlaubt das Spulen in dieser Sendung nicht',
                    onClose: () => {},
                    ...props,
                    css: { ...(props?.css || {}), marginBottom: '70px' },
                },
                'Notifications/seekingNotAllowed',
            ),

        seekingFutureNotAllowed: (props?: Partial<SnackProps>) =>
            createSnack(
                {
                    type: 'info',
                    message: 'Dieser Sender erlaubt kein Spulen in die Zukunft',
                    onClose: () => {},
                    ...props,
                    css: { ...(props?.css || {}), marginBottom: '70px' },
                },
                'Notifications/seekingFutureNotAllowed',
            ),
    };
};
