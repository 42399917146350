import { ColorsType, FontType } from './types';

export const Fonts: FontType = {
    primary: 'Open Sans',
    secondary: 'Poppins',
} as const;

/**
 * All colors we use at exaring. In styled components use stitches color
 * syntax. This object exists as a fallback, so we can use the colors in any
 * part of our apps.
 *
 * https://app.zeplin.io/styleguide/5db1b7aeb864471285b0ec2c/colors
 */

export const Colors: ColorsType = {
    black: '#000000',
    nearBlack: '#090714', // is now called richBlack
    greyPurple: '#171521',
    charade: '#282632',
    blackPurple: '#0b0a0f',
    dark: '#181623',
    white: '#ffffff',
    // white20: fade(white, 20%)
    verylightgray: '#f8f8f8',
    lightgray: '#ececec',
    alto: '#dddddd',
    gray: '#aaaaaa',
    dustyGrey: '#9b9b9b',
    // spanish-gray, waiputhekModulGenreChannelEtc
    mediumGray: '#96959c',
    // granite-grey
    darkgray: '#666666',
    greyishBrown: '#444444',
    // 'greyishBrown50':    fade(greyish-brown, 50%),
    charcoalGrey: '#34323e',
    bluegray: '#cad7de',
    babyBlue: '#a9d0fe',
    // brilliantAzureBlue
    bluish: '#3293fc',
    // primaryLightBlue
    blue: '#2789fc',
    // https://app.zeplin.io/project/5a27efb37131e85d78e59452/screen/618bea30f75927b455cafbee
    lightBlue: '#4894f5',
    // 'blue'-50:             fade(blue, 50%),
    logoBlue: '#2468b2',
    // absolute-zero
    darkBlue: '#0053b3',
    lightNavy: '#113d71',
    darkOrange: '#ff8800',
    gold: '#f3d200',
    // razzmicBerry
    purple: '#a05094',
    burgundy: '#713969',
    // signalRed
    red: '#ff0000',
    pink: '#f65c7c',
    red20: '#ffcccc', // notification (band) error color
    green: '#2fc091',
    // primaryTurquoiseGreen
    aquamarine: '#36dea7',
    islamicGreen: '#33bd00',

    spaceCadet: '#19224f',
    brightNavyBlue: '#1786e1',
    spanishGreen: '#159655',
    fluorescentBlue: '#2bfffd',
    netflixRed: '#e50914',
    debianRed: '#e00340',
    melancholicStone: '#586370',

    // EPG 2.0
    anthracite: '#282631',
    steelGrey: '#181722',
    cinder: '#181622',
    narwhalGrey: '#0d0b16',
    inkBlack: '#22202a',
    dreamlessSleep: '#110f1a',
    beautifulDarkness: '#6c6b72',
    ebony: '#35333c',
    tuna: '#46454d',

    // https://app.zeplin.io/project/5a27efb37131e85d78e59452/screen/60dc6be358d5d0079f02e73d (see toasts)
    toastInfoBackground: '#0f2c4b',
    toastInfoText: '#fff',
    toastSuccessBackground: '#36dea7',
    toastSuccessText: '#000',
    toastErrorBackground: '#e00340',
    toastErrorText: '#fff',

    // NOTICE please add a Zeplin link, if you need additional colors which are
    // not in the styleguide yet, so we know where they come from.
} as const;
