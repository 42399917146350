import { getBaseUrl } from '@exaring/utils';
import { removeEmpty } from '@exaring/utils/object';
import { get, patch, post } from '../../request';

const base = getBaseUrl(window.location.href);

const STATION_BASE_URL = `https://user-stations.${base}`;
const WEB_PROXY_BASE_URL = `https://web-proxy.${base}`;

export const settings = (userHandle) => {
    return get(`${STATION_BASE_URL}/api/station-settings/${userHandle}`);
};

export const stations = () => {
    return get(`${STATION_BASE_URL}/api/stations?omitted=false`); // hide channels not included in package with `omitted` get param
};

export const reset = () => {
    return post(`${STATION_BASE_URL}/api/stations/user-settings/reset`);
};

export const reorder = (stationsArray) => {
    return post(
        `${STATION_BASE_URL}/api/stations/user-settings/reorder`,
        {
            'Content-Type': 'application/vnd.waipu.user-stations-stations-position-update.v1+json',
        },
        stationsArray,
    );
};

export const update = (stationId, favorite, visible) => {
    const req = patch(
        `${STATION_BASE_URL}/api/stations/user-settings/${stationId}`,
        { 'Content-Type': 'application/vnd.waipu.user-stations-station-patch.v1+json' },
        removeEmpty({
            favorite,
            visible,
        }),
    );
    return req;
};

export const updateAll = (channels) => {
    const req = patch(
        `${STATION_BASE_URL}/api/stations/user-settings`,
        { 'Content-Type': 'application/vnd.waipu.user-stations-stations-patch.v1+json ' },
        channels,
    );
    return req;
};

export const config = () => {
    const req = get(`${WEB_PROXY_BASE_URL}/station-config`);
    req.authorize = false;

    return req;
};
