import { h, FunctionComponent } from 'preact';
import { CSS } from '@stitches/react';
import { styled } from '@exaring/ui/components-styled/theme';
import { MiniFooter } from '@exaring/ui/components-styled/MiniFooter';

const StyledContainer = styled('div', {
    margin: '0 65px 50px',
    fontFamily: '$primary',
    fontSize: '$1',
    fontWeight: '$1',
    color: '$mediumGray',

    '@md': {
        display: 'flex',
        justifyContent: 'space-between',
        columnGap: '$2',
    },
});

const StyledChannelFooter = styled('div', {
    marginBottom: '60px',
});

export const PageFooter: FunctionComponent<{
    css?: CSS;
    className?: string;
    htmlContent?: string;
}> = ({ css, className, htmlContent }) => {
    return (
        <StyledContainer css={css} className={className}>
            {htmlContent && (
                <StyledChannelFooter dangerouslySetInnerHTML={{ __html: htmlContent }} />
            )}

            <MiniFooter css={{ '@md': { flexGrow: 1, marginTop: 0 } }} />
        </StyledContainer>
    );
};
