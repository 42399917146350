/* eslint-disable no-restricted-syntax */
import { RecordingState, schemaBase } from '../constants';

export const RecordingV2Schema = `${schemaBase}.recording-v2+json`;
export const RecordingsV2Schema = `${schemaBase}.recordings-v2+json`;
export const RecordingStartV2Schema = `${schemaBase}.start-recording-v2+json`;
export const RecordingSummaryV2Schema = `${schemaBase}.recording-summary-v2+json`;

export const RecordingV2 = {
    id: { type: 'string', default: '' },
    userId: { type: 'string', default: '' },
    programId: { type: 'string', default: '' },
    channelId: { type: 'string', default: '' },
    channelPeriodId: { type: 'string', default: '' },
    recordingGroup: { type: 'number', default: null },
    stopTime: { type: 'date', default: null },
    startTime: { type: 'date', default: null },
    position: { type: 'number', default: 0 },
    positionSetAt: { type: 'date', default: null },
    watched: { type: 'boolean', default: false },
    status: { type: 'recordingState', default: RecordingState.UNKNOWN },
    streamingDetails: { type: 'streamingDetails', default: [] },
    epgData: { type: 'application/vnd.waipu.epg-program-v1+json', default: [] }, // actually its just a subset but validation is fine with the complete superset
    links: { type: 'array', default: [] },
    locked: { type: 'boolean', default: false },
    recordedNetTimeSeconds: { type: 'number', default: 0 },
    recordedNetTimeBefore: { type: 'number', default: 0 },
    seasonAsInteger: { type: 'number', default: 0 },
    episodeAsInteger: { type: 'number', default: 0 },
};

export const RecordingStartV2 = {
    channelId: { type: 'string', default: '', required: true },
    programId: { type: 'string', default: '', required: true },
    stopTime: { type: 'number', default: null },
    startTime: { type: 'number', default: null },
    qualities: { type: 'array', default: [] },
    recordingGroup: { type: 'number', default: 0 },
};

export const RecordingSummaryV1 = {
    availableRecordingHours: { type: 'number', default: null },
    finishedRecordingsSeconds: { type: 'number', default: null },
    numberOfFinishedRecordings: { type: 'number', default: null },
};

export const RecordingSummaryV2 = RecordingSummaryV1;

export const VODPositionV1 = {
    // there is no content type defined this is just for completness reasons
    position: { type: 'number', default: 0 },
};
