import {
    settings,
    config,
    stations,
    update,
    reset,
    reorder,
    updateAll,
} from '../requests/station/station';
import { requestHookChain } from '../networking.v2';

const api = {
    settings: requestHookChain(settings),
    stations: requestHookChain(stations),
    reset: requestHookChain(reset),
    reorder: requestHookChain(reorder),
    update: requestHookChain(update),
    updateAll: requestHookChain(updateAll),
    config: requestHookChain(config),
};

export default api;
