/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

/*
 * use oauth2 module for oauth2+pcre.
 * For not authorized clients there is no need
 * for using this module at all.
 * @deprecated
 */

import { h, render } from 'preact';
import { initErrorLogging } from '@exaring/utils/error-logging';

export const appBootstrap = async ({
    restClient: nwc,
    preInitCb,
    AppContainer,
    mountElement,
    renderInitCb = null,
    options = {},
}) => {
    const { errorLogging } = options;
    preInitCb?.(); // eslint-disable-line no-unused-expressions

    errorLogging && initErrorLogging(errorLogging);

    renderInitCb && (await renderInitCb(nwc, /* login */ false));

    render(<AppContainer restClient={nwc} />, mountElement);
};

export default { appBootstrap };
