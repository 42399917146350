import { confirmationEmail, update, setSepaPaymentData } from '../requests/users';
import { requestHookChain } from '../networking.v2';

const api = {
    confirmationEmail: requestHookChain(confirmationEmail),
    update: requestHookChain(update),
    setSepaPaymentData: requestHookChain(setSepaPaymentData),
};

export default api;
