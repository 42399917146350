/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { ProgramV1 } from '@exaring/utils/data/schema/program';
import { validate } from '@exaring/utils/data/schema/validator';
import constants from '../constants';

export default class Channel {
    constructor(data, programs, isHD = false, channelLogo = {}) {
        this.id = data.id;
        this.stationId = data.stationId;
        this.displayName = data.displayName || 'Unbekannt';
        this._iconUrl = '';
        this._isHD = isHD;
        this._channelLogo = channelLogo;
        this.faved = data.faved;
        this.hidden = data.hidden;
        this.locked = data.locked;

        this.programs = (programs || []).map((program) => {
            return validate(program, ProgramV1);
        });

        this.properties = data.properties || [];
        this.links = data.links || [];
    }

    linkByRelType(type) {
        const link = this.links.find((_link) => {
            return _link.rel === type ? _link : null; // eslint-disable-line no-restricted-syntax
        });

        return link && link.href ? link.href : null; // eslint-disable-line no-restricted-syntax
    }

    static get tvfuseIdentifier() {
        return 'tvfuse';
    }

    static get pauseForbiddenIdentifier() {
        return 'pauseForbidden';
    }

    static get recordingForbiddenIdentifier() {
        return 'recordingForbidden';
    }

    static get seekingForbiddenIdentifier() {
        return 'recordingSeekingForbidden';
    }

    static get linkRelLiveImageIdentifier() {
        return 'liveImage';
    }

    static get linkRelIconIdentifier() {
        return 'icon';
    }

    static get linkRelIconHDIdentifier() {
        return 'iconhd';
    }

    static get linkRelIconSDIdentifier() {
        return 'iconsd';
    }

    static get linkRelIconLargeHDIdentifier() {
        return 'iconlargehd';
    }

    static get linkRelIconLargeSDIdentifier() {
        return 'iconlargesd';
    }

    set channelLogos(logo) {
        this._channelLogo = logo || {};
    }

    // Channel Logo external resource
    get channelLogoHd() {
        return (this._channelLogo.hd && this._channelLogo.hd.svg) || '';
    }

    get channelLogoSd() {
        return (this._channelLogo.sd && this._channelLogo.sd.svg) || '';
    }

    // methods for Properties
    get isTVFuse() {
        return this.properties && this.properties.indexOf(Channel.tvfuseIdentifier) > -1;
    }

    get recordingForbidden() {
        return (
            this.properties && this.properties.indexOf(Channel.recordingForbiddenIdentifier) > -1
        );
    }

    get pauseForbidden() {
        return this.properties && this.properties.indexOf(Channel.pauseForbiddenIdentifier) > -1;
    }

    get seekingForbidden() {
        return this.properties && this.properties.indexOf(Channel.seekingForbiddenIdentifier) > -1;
    }

    channelLogo() {
        if (this.channelLogoHd) {
            return this.channelLogoHd && constants.WAIPU_BASE_URL + this.channelLogoHd;
        }

        return this.channelLogoSd && constants.WAIPU_BASE_URL + this.channelLogoSd;
    }

    // Links
    get iconLargeHd() {
        return this.linkByRelType(Channel.linkRelIconLargeHDIdentifier);
    }

    get iconLargeSD() {
        return this.linkByRelType(Channel.linkRelIconLargeSDIdentifier);
    }

    get iconHD() {
        return this.linkByRelType(Channel.linkRelIconHDIdentifier);
    }

    get iconSD() {
        return this.linkByRelType(Channel.linkRelIconSDIdentifier);
    }

    get icon() {
        return this.linkByRelType(Channel.linkRelIconIdentifier);
    }

    get liveImage() {
        return this.linkByRelType(Channel.linkRelLiveImageIdentifier);
    }

    /**
     * @deprecated Since version 5.10.0. Will be deleted soon. Use `getScaledIcon(x,y)` instead.
     */
    get iconUrl() {
        const size = this._iconSize || 96;
        return `${this._isHD ? this.iconHD : this.iconSD}?width=${size}&height=${size}`;
    }

    set iconUrl(value) {
        this._iconUrl = value;
    }

    getSourceSet = (width, height) =>
        `${this.getScaledIcon(width, height)}, ` +
        `${this.getScaledIcon(width * 2, height * 2)} 2x, ` +
        `${this.getScaledIcon(width * 3, height * 3)} 3x`;

    getScaledIcon(x, y) {
        return `${this._isHD ? this.iconHD : this.iconSD}?width=${x}&height=${y}`;
    }

    toJSON() {
        return {
            id: this.id,
            hidden: this.hidden,
            faved: this.faved,
        };
    }

    get isHD() {
        return this._isHD;
    }
}
