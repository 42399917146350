import { runtimeHookCfg } from './runtime';
import { requestToNative } from './request';

const ERROR_MODULE_NAME = 'Networking: ';

export const ERROR_REQUEST_ABORTED = `${ERROR_MODULE_NAME} request was aborted`;
export const ERROR_FETCH_FAILED = `${ERROR_MODULE_NAME} Fetch failed`;

export { NetworkingError } from './hooks/NetworkError/NetworkError';

/**
 * Reduce all hooks to one result object. Used to process given
 * `Request` or `Response` object with all given hooks.
 */
export const iterateHooks = async (hooks, ctxObj, onErrorText = '') => {
    const ctxDiffObj = ctxObj;
    // just functional hooks (drop all falsy resolved conditionals)
    const _hooks = hooks.filter((hook) => typeof hook === 'function');
    return _hooks.reduce(async (_ctx, hook) => {
        const __ctx = await _ctx;
        const ctxRes = await hook(__ctx);

        if (ctxDiffObj !== ctxRes) {
            throw Error(onErrorText, hook);
        }
        return ctxRes;
    }, ctxObj);
};

const hookChainCaller = async (req, { abortController } = {}, ...params) => {
    let request;
    let response;

    request = req(...params); // initialize request

    const runtimeCfg = runtimeHookCfg();
    if (!runtimeCfg) {
        throw new Error(`${ERROR_MODULE_NAME} Runtime config was not found`);
    }

    request = await iterateHooks(
        runtimeCfg.request,
        request,
        `${ERROR_MODULE_NAME} Request hook must not replace request`,
    );

    try {
        response = await fetch(
            requestToNative({
                ...request,
                ...(abortController ? { signal: abortController.signal } : {}),
            }),
        );
    } catch (e) {
        if (abortController?.signal?.aborted) {
            throw Error(ERROR_REQUEST_ABORTED);
        }

        throw Error(ERROR_FETCH_FAILED);
    }

    response = await iterateHooks(
        runtimeCfg.response,
        response,
        `${ERROR_MODULE_NAME} Response hook must not replace response`,
    );
    return response;
};

/**
 * Wrapper for request to wrap hook chain around given `Request`.
 */
export const requestHookChain = (req) => {
    const reqType = typeof req;
    if (reqType !== 'function') {
        throw new Error(`Expected requests as function, but received type ${reqType}`);
    }

    return (...params) => hookChainCaller(req, undefined, ...params);
};

export const requestHookChainV2 = (req) => {
    const reqType = typeof req;
    if (reqType !== 'function') {
        throw new Error(`Expected requests as function, but received type ${reqType}`);
    }

    return (...params) => {
        const abortController = new AbortController();
        return {
            request: hookChainCaller(req, { abortController }, ...params),
            abortController,
        };
    };
};
