import { mapWithoutNil, isObject, isString } from '@exaring/utils/index';
import { MediathekItem, decode as decodeMediathekItem } from './MediathekItem';

export interface MediathekCategory {
    id: string;
    title?: string;
    description?: string;
    img?: string;
    waiputhekImg?: string;
    contents: Array<MediathekItem>;
}

const isValidJson = (value: any): value is MediathekCategory => {
    if (isObject(value)) {
        const { id, contents } = value;
        return isString(id) && Array.isArray(contents) && contents.length > 0;
    }

    return false;
};

export const decode = (value: unknown): MediathekCategory | undefined => {
    return isValidJson(value)
        ? {
              ...value,
              contents: mapWithoutNil(value.contents, decodeMediathekItem),
          }
        : undefined;
};
