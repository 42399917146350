import { FunctionComponent, h, Fragment } from 'preact';
import { useEffect } from 'preact/hooks';
import { Program } from '@nessprim/planby';
import { ProgramProgressBar } from '@exaring/ui/components-styled/ProgramProgressBar/ProgramProgressBar';
import { styled } from '@exaring/ui/components-styled/theme';
import HdIcon from '@exaring/assets/icons/hd.svg';
import FavoriteIcon from '@exaring/assets/icons/favorite.svg';
import LockedIcon from '@exaring/assets/icons/content-locked.svg';
import { adjustImageRes } from '@exaring/utils/data/program';
import { assetUrl } from '../../../helper';
import { Time } from './Time';
import { Badge } from './Badge';
import { getProgramProgress } from './utils';
import {
    BadgeWrapper,
    PreviewImg,
    StyledPreviewContainer,
    TextWrapper,
    Description,
    PreviewImgContainer,
} from './SharedComponents';
import { useEpgStore } from '../../../state/Store';
import { prefixEnumerationWithZero } from '../ChannelItem';

const NameWrapper = styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '13px',
});

const Title = styled('div', {
    fontWeight: '$3',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '20px',
    lineHeight: 1.5,

    '@md-width-and-md-height': {
        fontSize: '$5',
    },
});

export const ChannelPreview: FunctionComponent<{ channelId: string }> = ({ channelId }) => {
    const { getStationById, epgData, getLiveProgram } = useEpgStore();
    const channel = getStationById(channelId);

    useEffect(() => {
        getLiveProgram(channelId);
    }, [channelId, getLiveProgram]);

    if (channel === undefined) {
        return <></>;
    }

    const { logo, displayName, uuid, favorite, locked, availableStreamQuality, newTv } = channel;

    const isHd = availableStreamQuality === 'hd';
    const enumeration = channel?.enumeration
        ? prefixEnumerationWithZero(channel?.enumeration)
        : undefined;
    const program = epgData.live[uuid || ''] || ({} as Program);
    const { title: programTitle, since, till } = program;

    const { progress } = getProgramProgress({
        type: newTv ? 'NewTv' : 'Live',
        startTime: since,
        stopTime: till,
    });

    return (
        <StyledPreviewContainer>
            <PreviewImgContainer>
                <PreviewImg
                    src={adjustImageRes(logo || '', 640, 360)}
                    srcFallback={assetUrl('background-waipu-gradient.png')}
                />
                <ProgramProgressBar progress={progress} />
            </PreviewImgContainer>
            <TextWrapper>
                <NameWrapper>
                    <Title>{displayName}</Title>
                    <BadgeWrapper big>
                        {enumeration && <Badge>{enumeration}</Badge>}
                        {isHd && <HdIcon />}
                        {favorite && <FavoriteIcon />}
                        {locked && <LockedIcon />}
                    </BadgeWrapper>
                </NameWrapper>
                <Description>{programTitle}</Description>
                <Time startTime={since} stopTime={till} />
            </TextWrapper>
        </StyledPreviewContainer>
    );
};
