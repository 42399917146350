import { z } from 'zod';
import { logError } from '@exaring/utils';

const matchSchema = z.object({ field: z.string(), values: z.string().array() });

const searchResultSchema = z.object({
    program: z.object({
        id: z.string(),
        startTime: z.string(),
        stopTime: z.string(),
        title: z.string(),
        stationId: z.string(),
        episodeTitle: z.string().optional(),
        genres: z.string().array().optional(),
        castMembers: z.string().array().optional(),
        previewImage: z.string().optional(),
    }),
    metaData: z.object({
        score: z.number(),
        fieldsMatched: z.string().array(),
        matches: matchSchema.array(),
    }),
});

export type SearchResultResponseSchema = z.infer<typeof searchResultSchema>;
export type SearchResultResponse = SearchResultResponseSchema[];

export const isSearchResult = (value: unknown): value is SearchResultResponse => {
    try {
        if (Array.isArray(value)) {
            return searchResultSchema.safeParse(value[0]).success;
        }

        return false;
    } catch (e: any) {
        logError(e);
        return false;
    }
};
