import { FunctionComponent, h, Fragment } from 'preact';
import Lock from '@exaring/assets/icons/lock.svg';
import Trashcan from '@exaring/assets/icons/recording-delete.svg';
import ChannelSort from '@exaring/assets/icons/channel-sort-toggle.svg';
import Oval from '@exaring/assets/images/oval.svg';
import { styled } from '@stitches/react';

const OvalBackground = styled(Oval, {
    position: 'absolute',
    top: '-32px',
    left: 'calc(50% - 62px)',
    zIndex: '1',
    width: '124px',
    height: '32px',
    transform: 'translateY(0.5px)',
});

const iconStyles = {
    position: 'absolute',
    top: '-16px',
    left: 'calc(50% - 16px)',
    zIndex: '2',
    width: '32px',
    height: '32px',
};

const LockIcon = styled(Lock, iconStyles);
const TrashcanIcon = styled(Trashcan, iconStyles);
const ChannelSortIcon = styled(ChannelSort, iconStyles);

export type ModalIcon = 'lock' | 'trashcan' | 'channelsort';

interface ToastIconProps {
    icon?: ModalIcon;
}

// eslint-disable-next-line consistent-return
const getIcon = (icon: ModalIcon) => {
    // eslint-disable-next-line default-case
    switch (icon) {
        case 'lock': {
            return LockIcon;
        }
        case 'trashcan': {
            return TrashcanIcon;
        }
        case 'channelsort': {
            return ChannelSortIcon;
        }
    }
};

export const BigToastIcon: FunctionComponent<ToastIconProps> = ({ icon = 'lock' }) => {
    const Icon = getIcon(icon);

    return (
        <>
            <OvalBackground viewBoxWidth="124" viewBoxHeight="32" fill="#0f2c4b" />
            <Icon />
        </>
    );
};
