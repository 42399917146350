import { h, FunctionComponent } from 'preact';
import { CSS } from '@stitches/react';
import { styled } from '@exaring/ui/components-styled/theme';
import { Typography } from '@exaring/ui/components-styled/Typography/Typography';

const Subtitle = styled('span', {
    display: 'inline',
    paddingLeft: '10px',
    color: '$white',
    fontSize: '$2',
    opacity: 0.25,
});

export const Headline: FunctionComponent<{ css?: CSS; className?: string; subtitle?: string }> = ({
    css,
    className,
    subtitle,
    children,
}) => {
    return (
        <Typography
            variant="h2"
            css={{ marginLeft: '65px', marginBottom: 0, fontSize: '20px', ...css }}
            className={className}
        >
            {children}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Typography>
    );
};
