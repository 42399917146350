import { FunctionComponent, h, Fragment } from 'preact';
import EyeIcon from '@exaring/assets/icons/eye-on.svg';
import { useEffect, useState } from 'preact/hooks';
import { HeaderMenu } from '../PageHeadline/PageHeader';
import { SortItem, SortItemChannel } from '../SortItem';
import {
    ContentWrapper,
    Explanation,
    ResetButton,
    StyledCloseIcon,
    HeaderWrapper,
    StyledModalBackdrop,
    StyledModal,
} from './HiddenChannelsModal.styles';

type HiddenChannelsModalProps = {
    show: boolean;
    onClose: () => void;
    channels: SortItemChannel[];
    onToggleVisible: (id: string, show: boolean) => void;
    showHiddenChannels: () => void;
};

const findSortNumbers = (channels: SortItemChannel[]) => {
    const visibleChannelIds = channels.filter((chan) => chan.visible).map((chan) => chan.id);

    return (channel: SortItemChannel) => {
        const number = visibleChannelIds.indexOf(channel.id) + 1;
        return number > 0 ? number : undefined;
    };
};

export const HiddenChannelsModal: FunctionComponent<HiddenChannelsModalProps> = ({
    show,
    onClose,
    channels,
    onToggleVisible,
    showHiddenChannels,
}) => {
    const [oppenedChannelIds, setOppenedChannelIds] = useState<string[]>([]);

    // Remember the hidden channels upon opening of the modal
    // So that unhiding them does not remove from this temp list
    useEffect(() => {
        if (show === true) {
            setOppenedChannelIds(channels.filter((ch) => !ch.visible).map((ch) => ch.id));
        } else {
            // Clear to avoid flashing older channels on re-oppening of the modal
            setOppenedChannelIds([]);
        }
    }, [show]);

    if (show === false) {
        return <></>;
    }

    const getSortNumber = findSortNumbers(channels);

    const hiddenChannels = channels
        .filter((channel) => oppenedChannelIds.includes(channel.id))
        .map((channel) => (
            <SortItem
                view="list"
                channel={channel}
                sortNumber={getSortNumber(channel)}
                onToggleVisible={onToggleVisible}
                disableEditing
            />
        ));

    return (
        <StyledModalBackdrop
            modal
            fullscreen
            alignItems="flex-start"
            justifyContent="center"
            onClick={onClose}
        >
            <StyledModal>
                <div>
                    <HeaderMenu xs={12}>
                        <StyledCloseIcon onClick={onClose} />
                    </HeaderMenu>

                    <HeaderWrapper>
                        <Explanation>
                            Ausgeblendete TV-Sender wieder zur Sender-Liste hinzufügen.
                        </Explanation>

                        <ResetButton
                            epg
                            icon={<EyeIcon />}
                            onClick={() => {
                                showHiddenChannels();
                            }}
                        >
                            Alle einblenden
                        </ResetButton>
                    </HeaderWrapper>
                </div>

                <ContentWrapper>{hiddenChannels}</ContentWrapper>
            </StyledModal>
        </StyledModalBackdrop>
    );
};
