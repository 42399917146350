/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

/**
 * get url with given rel type
 * @param {object} channel
 * @param {string} rel link identifier
 * @returns {string} url for rel type
 */
export const linkByRelType = (channel, rel) =>
    // eslint-disable-next-line no-restricted-syntax
    channel.links.find((c) => c.rel === rel)?.href || null;

/**
 * get microSiteId from channel object if it exists
 * @deprecated only use this function for legacy channel objects. for epg2.0 use getMicroSiteIdFromStation
 * @param {object} channel
 * @returns {string|undefined} microSiteId
 */
export const getMicroSiteIdFromChannel = (channel) => {
    const microSiteLink = linkByRelType(channel, 'micrositeSourceUrl');
    return microSiteLink?.match(/microsites\/(.*)\/contents/)?.[1];
};

/**
 * get microSiteId from station object if it exists
 * @param {object} channel
 * @returns {string|undefined} microSiteId
 */
export const getMicroSiteIdFromStation = (station) => {
    const microSiteLink = station?.mediaCenter?.apiUrl;
    return microSiteLink?.match(/microsites\/(.*)\/contents/)?.[1];
};

/**
 * check if flag is set in properties array of channel
 * @param {object} channel
 * @param {string} flag
 * @returns {boolean}
 */
export const isPropFlagSet = (channel, flag) => channel.properties.includes(flag);

/**
 * @param {object} channel
 * @returns {string} url
 */
export const iconHD = (channel) => {
    return linkByRelType(channel, 'iconhd');
};

/**
 * @param {object} channel
 * @returns {string} url
 */
export const iconSD = (channel) => {
    return linkByRelType(channel, 'iconsd');
};

/**
 * @param {object} channel
 * @returns {boolean}
 */
export const isRecordingForbidden = (channel) => isPropFlagSet(channel, 'recordingForbidden');

/**
 * @param {object} channel
 * @returns {boolean}
 */
export const isTvFuse = (channel) => isPropFlagSet(channel, 'tvfuse');

/**
 * this flag is not delivered by BS but mixed-in by our logic to avoid
 * tremendous addional lookup nonsense.
 * @param {object} channel
 * @returns {boolean}
 */
export const isHD = (channel) => isPropFlagSet(channel, '_hd');

/**
 * @param {object} channel
 * @returns {boolean}
 */
export const isPauseForbidden = (channel) => isPropFlagSet(channel, 'pauseForbidden');

/**
 * @param {object} channel
 * @returns {boolean}
 */
export const seekingForbidden = (channel) => isPropFlagSet(channel, 'recordingSeekingForbidden');

/**
 * @param {object} channel
 * @returns {string} url
 */
export const scaledIcon = (channel, width = 0, height = 0) =>
    `${isHD(channel) ? iconHD(channel) : iconSD(channel)}?width=${width}&height=${height}`;

/**
 * @param {number} width
 * @param {number} height
 * @returns {string}
 */
export const sourceSet = (channel, width, height) =>
    `${scaledIcon(channel, width, height, isHD(channel))}, ` +
    `${scaledIcon(channel, width * 2, height * 2, isHD(channel))} 2x, ` +
    `${scaledIcon(channel, width * 3, height * 3, isHD(channel))} 3x`;
