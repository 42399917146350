import { h } from 'preact';

// type Props = {
//     className?: string,
//     content: string,
//     id?: string,
//     href: string,
//     title?: string,
//     newTab: boolean
// };

export default ({ content, children, className, href, id, title, newTab }) => {
    const rel = newTab && 'noopener noreferrer';
    const target = newTab && '_blank';

    return (
        <a className={className} id={id} href={href} title={title} rel={rel} target={target}>
            {content || children}
        </a>
    );
};
