/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */
import { FunctionComponent, h } from 'preact';
import { remainingStorageInHours } from '@exaring/utils';
import { styled } from '../theme';

const StyledCloudStorageInfo = styled('div', {
    fontSize: '14px',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
});

const Progressbar = styled('div', {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '207px',
    height: '2px',
    background: 'linear-gradient(to right, $bluish, $purple 62%, $debianRed)',
    borderRadius: '2px',

    variants: {
        disabled: {
            true: {
                background: '#3f3f3f',
            },
        },
    },
});

const RemainingSpace = styled('div', {
    background: '#3f3f3f',
    height: '100%',
    borderLeft: '2px solid $nearBlack',
    borderRadius: '2px',
});

interface CloudStorageInfoProps {
    maxStorage: number;
    usedStorage: number;
}

export const CloudStorageInfo: FunctionComponent<CloudStorageInfoProps> = ({
    maxStorage,
    usedStorage,
}) => {
    const usedStorageInPercent = (usedStorage / maxStorage) * 100;
    const leftStorageInPercent = 100 - usedStorageInPercent;
    const maxStorageInHours = maxStorage / 3600;
    const remainingStorage = remainingStorageInHours(maxStorage, usedStorage);
    const remainingStorageText = `${remainingStorage} ${
        maxStorageInHours > 0 ? `von ${maxStorageInHours}` : ''
    } Std. verfügbar`;
    return (
        <StyledCloudStorageInfo>
            {remainingStorageText}
            <br />
            <Progressbar disabled={maxStorageInHours === 0}>
                <RemainingSpace style={{ width: `${leftStorageInPercent}%` }} />
            </Progressbar>
        </StyledCloudStorageInfo>
    );
};
