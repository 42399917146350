/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';
import { classnames } from '@exaring/utils';

const OSDButtonGroup = ({ className, isDisabled, children }) => {
    const buttonGroupClasses = classnames(
        className,
        'osd__controls__icon-group',
        isDisabled && 'osd__controls__icon-group--disabled',
    );

    return <div className={buttonGroupClasses}>{children}</div>;
};

export default OSDButtonGroup;
