/*
 *  Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, Fragment, FunctionComponent, Ref } from 'preact';
import { useEffect, useRef } from 'preact/hooks';

import { date, formatAirTime, isNil } from '@exaring/utils';
import { MediumTile, LinkTile } from '@exaring/ui/components-styled/Tile';
import { Exception } from '@exaring/ui/components-styled/Modal';
import Header from '../components/Header';
import { useWaiputhekStore } from '../state/Store';
import {
    isLink,
    isVideo,
    matcher,
    MediathekItem,
    MediathekLinkItem,
    MediathekVideoItem,
    TV_FUSE_LINK,
    TV_FUSE_VIDEO,
} from '../types/api/MediathekItem';
import { MediathekCategory } from '../types/api/MediathekCategory';
import { StyledWaiputhekPage } from '../components/waiputhek/StyledWaiputhekPage';
import { scrollToSection, Section } from '../components/waiputhek/Section';
import { PageHeadline } from '../components/waiputhek/PageHeadline';
import { StyledLoadingSpinner } from '../components/StyledLoadingSpinner';
import { TileScroller } from '../components/waiputhek/TileScroller';
import { PageFooter } from '../components/waiputhek/PageFooter';
import { imageResource } from '../helper';
import { PlayerType } from '../types/tracking';
import { WebClientGA, WebClientGAEvent } from '../web-client-ga';

const formatDuration = (durationMs: number) => `${Math.round(durationMs / (1000 * 60))} Min`;

const trackClick = (
    content: MediathekItem,
    category: MediathekCategory,
    sectionIdx: number,
    tileIdx?: number,
) => {
    const playerType: PlayerType = 'vod';
    matcher(content.type, {
        [TV_FUSE_LINK]: () => {
            return WebClientGA().trackEvent(
                {
                    eventName: WebClientGAEvent.Waiputhek,
                    eventDescription: WebClientGAEvent.WaiputhekSelectHighlightCollection,
                    channelName: category.title,
                    screenName: 'media_library_group',
                    programTitle: (content as MediathekLinkItem).data.title,
                    playerType,
                },
                {
                    section_id: category.id,
                    section_position: `${sectionIdx}_${tileIdx}`,
                },
            );
        },
        [TV_FUSE_VIDEO]: () => {
            return WebClientGA().trackEvent(
                {
                    eventName: WebClientGAEvent.Waiputhek,
                    eventDescription: WebClientGAEvent.WaiputhekSelectHighlightVideo,
                    channelName: category.title,
                    screenName: 'media_library',
                    programTitle: (content as MediathekVideoItem).video.title,
                    programId: (content as MediathekVideoItem).video.programID,
                    playerType,
                },
                {
                    section_id: category.id,
                    section_position: `${sectionIdx}_${tileIdx}`,
                },
            );
        },
    });
};

const contentTileMapper =
    (
        channelId: string,
        category: MediathekCategory,
        goToChannel: (id: string) => void,
        goToContent: (id: string, categoryId?: string) => void,
        goToVod: (contentId: string, channelId?: string, categoryId?: string) => void,
        categoryIdx: number,
    ) =>
    (content: MediathekItem, idx?: number): JSX.Element | undefined => {
        const { id: categoryId, img: categoryImg, waiputhekImg: categoryWaiputhekImg } = category;

        const handleClickAndTrack = (clickHandler: () => void) => {
            trackClick(content, category, categoryIdx + 1, !isNil(idx) ? idx + 1 : undefined);
            clickHandler();
        };

        if (isLink(content)) {
            const {
                data: { img },
            } = content;
            return (
                <LinkTile
                    imageSrc={img || categoryWaiputhekImg || categoryImg}
                    onClick={() => handleClickAndTrack(() => goToChannel(categoryId))}
                    imageFullWidth={!!img || !categoryWaiputhekImg}
                />
            );
        }

        if (isVideo(content)) {
            const {
                video: { img, title, airtime, duration, genre, programID },
            } = content;
            return (
                <MediumTile
                    imageSrc={img ? imageResource(img, 320, 180) : undefined}
                    title={title}
                    airtime={airtime && date(airtime).format(formatAirTime)}
                    duration={duration ? formatDuration(duration) : undefined}
                    genre={genre}
                    onMediaPlayout={() =>
                        handleClickAndTrack(() => goToVod(programID, channelId, categoryId))
                    }
                    onOpenDetails={() =>
                        handleClickAndTrack(() => goToContent(programID, categoryId))
                    }
                />
            );
        }

        return undefined;
    };

export const MediathekPage: FunctionComponent<{
    id: string;
    categoryId?: string;
    contentId?: string;
    goBack: () => void;
    goToChannel: (id: string) => void;
    goToContent: (id: string, categoryId?: string) => void;
    goToVod: (contentId: string, channelId?: string, categoryId?: string) => void;
}> = ({ id, goBack, goToChannel, goToContent, goToVod, categoryId, contentId }) => {
    const { mediathekData, fetchMediathek } = useWaiputhekStore();
    const scrollRef = useRef<HTMLDivElement>();

    const { value, state } = mediathekData;

    useEffect(() => {
        fetchMediathek(id);
    }, [fetchMediathek, id]);

    useEffect(() => {
        if (state === 'Success') {
            scrollToSection(scrollRef.current);
        }
    }, [state]);

    return (
        <>
            <Header />
            <StyledWaiputhekPage>
                {state === 'Loading' && <StyledLoadingSpinner />}
                {state === 'Success' && value && (
                    <>
                        <PageHeadline
                            title={value.title}
                            imgUrl={value.waiputhekImg}
                            onBack={goBack}
                        />
                        {value.categories.map((category, categoryIdx) => {
                            const focus = category.id === categoryId;
                            const defaultIndex =
                                focus && contentId
                                    ? category.contents.findIndex(
                                          (c) => isVideo(c) && c.video.programID === contentId,
                                      )
                                    : undefined;
                            return (
                                <Section
                                    ref={focus ? (scrollRef as Ref<HTMLDivElement>) : undefined}
                                    title={category.title}
                                >
                                    <TileScroller<MediathekItem>
                                        data={category.contents}
                                        dataIndex="sourceUrl"
                                        mapTile={contentTileMapper(
                                            value.channel,
                                            category,
                                            goToChannel,
                                            goToContent,
                                            goToVod,
                                            categoryIdx,
                                        )}
                                        defaultIndex={defaultIndex}
                                    />
                                </Section>
                            );
                        })}
                        <PageFooter htmlContent={value.imprint} />
                    </>
                )}
                {state === 'Error' && (
                    <Exception
                        title="Der Inhalt konnte nicht geladen werden."
                        subtitle="Zu dieser Mediathek liegen uns leider keine Inhalte vor."
                        onClick={goBack}
                        withReload
                    />
                )}
            </StyledWaiputhekPage>
        </>
    );
};
