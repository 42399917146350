/*
 * Events
 */

export const events = {
    getMousePosition(event) {
        return {
            x: event.pageX,
            y: event.pageY,
        };
    },

    getTouchPosition(event) {
        return {
            x: event.touches[0].pageX,
            y: event.touches[0].pageY,
        };
    },

    pauseEvent(event) {
        event.stopPropagation();
        event.preventDefault();
        /* eslint-disable no-param-reassign */
        event.returnValue = false;
        event.cancelBubble = true;
        /* eslint-enable no-param-reassign */
    },

    addEventsTo(domEl, eventMap) {
        Object.keys(eventMap).forEach((key) => {
            domEl.addEventListener(key, eventMap[key], false);
        });
    },

    removeEventsFrom(domEl, eventMap) {
        Object.keys(eventMap).forEach((key) => {
            domEl.removeEventListener(key, eventMap[key], false);
        });
    },

    targetIsDescendant(event, parent) {
        let node = event.target;

        // eslint-disable-next-line no-restricted-syntax
        while (node !== null) {
            if (node === parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    },
};

export default events;
