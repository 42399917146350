import { h, FunctionComponent } from 'preact';

import { BackIcon, ReloadIcon } from './ButtonIcons';
import { styled, Colors } from '../../theme';
import { Typography } from '../../Typography';
import { Button } from '../../Button';
import { ExceptionModal, IconVariants } from '.';

type Exception = Omit<ExceptionModal, 'icon'> & {
    icon?: IconVariants;
    title?: string;
    subtitle?: string;
    buttonLabel?: string;
    withReload?: boolean;
    onClick?: (e: Event) => void;
};

const StyledButton = styled(Button, {
    width: '207px',
    height: '60px',
    borderRadius: '5px',
    border: 'transparent',
    boxShadow: 'unset',
    background: Colors.dark,
});

const LabelWrapper = styled('span', {
    fontSize: '$8',
    marginLeft: '10px',
});

export const Exception: FunctionComponent<Exception> = ({
    className,
    title,
    subtitle,
    buttonLabel = 'Zurück',
    icon = 'warning',
    withReload = false,
    children,
    onClick,
    ...rootProps
}) => {
    const handleReloadPageClick = () => {
        window.location.reload();
    };

    return (
        <ExceptionModal
            icon={icon}
            iconCss={{ marginBottom: '$2', fill: Colors.white }}
            variant="overlay"
            className={className}
            {...rootProps} // eslint-disable-line react/jsx-props-no-spreading
        >
            <Typography variant="h1" css={{ lineHeight: '33px' }}>
                {title}
            </Typography>
            {subtitle && (
                <Typography variant="body2" css={{ marginBottom: '30px', lineHeight: '24px' }}>
                    {subtitle}
                </Typography>
            )}
            {onClick && (
                <StyledButton onClick={onClick}>
                    <BackIcon />
                    <LabelWrapper>{buttonLabel}</LabelWrapper>
                </StyledButton>
            )}
            {withReload && (
                <StyledButton css={{ marginLeft: '20px' }} onClick={handleReloadPageClick}>
                    <ReloadIcon />
                    <LabelWrapper>Neu laden</LabelWrapper>
                </StyledButton>
            )}
        </ExceptionModal>
    );
};
