import { h, FunctionComponent, ComponentChildren } from 'preact';
import { useState } from 'preact/hooks';
import { CSS } from '@stitches/react';

import { styled } from '../theme';

const StyledTooltip = styled('div', {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const StyledText = styled('div', {
    position: 'absolute',
    width: '204px',
    padding: '4px',
    borderRadius: '4px 8px',
    boxShadow: '0 0 10px 0 rgba(9, 7, 20, 0.9)',
    backgroundColor: '$dark',
    fontSize: '10px',
    fontWeight: 'normal',
    lineHeight: 'normal',
    zIndex: 20,
    left: 'calc(100% + 5px)',
    top: '5px',
});

export const Tooltip: FunctionComponent<{
    children: ComponentChildren;
    text: string;
    css?: CSS;
}> = ({ children, text, css }) => {
    const [isVisible, setIsVisible] = useState(false);
    return (
        <StyledTooltip
            css={css}
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
        >
            {children}
            {isVisible && <StyledText>{text}</StyledText>}
        </StyledTooltip>
    );
};
