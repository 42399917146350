/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';
import { classnames } from '@exaring/utils';
import Link from '../../typography/link';

export default function ErrorPage({
    className,
    errorCode,
    errorHeadline,
    errorText,
    children,
    errorLinks = [],
}) {
    const defaultCode = 500;
    const defaultHeadline = 'Entschuldigung,';
    const defaultText =
        'es ist ein unerwarteter Fehler aufgetreten. Wir werden uns schnellstmöglich darum kümmern. ' +
        'Bitte probieren Sie es später noch einmal. Wenden Sie sich an den Kundenservice, wenn das Problem weiterhin besteht.';

    const errorPageClasses = classnames(`error-page--${errorCode || defaultCode}`, className);

    return (
        <div className={errorPageClasses}>
            <div className="main">
                <span className="error-page__image" />
                <div className="error-page__description">
                    <h1 className="content__header">{errorHeadline || defaultHeadline}</h1>
                    <p className="text">{errorText || defaultText}</p>
                    {children}
                    {errorLinks.length > 0 && (
                        <nav className="error-page__links">
                            {errorLinks.map(({ url, newTab, title, text }) => (
                                <Link
                                    className="text-link"
                                    href={url}
                                    newTab={newTab}
                                    title={title}
                                >
                                    {text}
                                </Link>
                            ))}
                        </nav>
                    )}
                </div>
            </div>
        </div>
    );
}
