import {
    summary,
    recordings,
    details,
    schedule,
    unschedule,
    stop,
    deleteFailed,
    scheduleSerial,
    scheduledSerials,
    unscheduleSerials,
} from '../requests/recordings';
import { requestHookChain } from '../networking.v2';

const api = {
    summary: requestHookChain(summary),
    recordings: requestHookChain(recordings),
    details: requestHookChain(details),
    schedule: requestHookChain(schedule),
    unschedule: requestHookChain(unschedule),
    stop: requestHookChain(stop),
    deleteFailed: requestHookChain(deleteFailed),
    scheduleSerial: requestHookChain(scheduleSerial),
    scheduledSerials: requestHookChain(scheduledSerials),
    unscheduleSerials: requestHookChain(unscheduleSerials),
};

export default api;
