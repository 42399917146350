import contentDiscoveryService from '@exaring/networking/services/content-discovery';
import {
    RecordingDiscoveryApi,
    isRecordingContentDiscovery,
} from '@exaring/networking/types/Recording/RecordingDiscovery';
import { DeferredRemoteData, useRemoteData } from '../../../hooks/useRemoteData';

export const useRecordingContentDiscovery = (): DeferredRemoteData<RecordingDiscoveryApi> => {
    return useRemoteData(
        () => contentDiscoveryService.mostPopularRecordings(),
        isRecordingContentDiscovery,
    );
};
