import { h, Fragment, FunctionComponent } from 'preact';

import { UpsellingModal } from '@exaring/ui/components-styled/Modal/Upselling';
import { Button as StyledButton } from '@exaring/ui/components-styled/Button';
import { CSS } from '@exaring/ui/components-styled/theme';
import constants from '../../constants';
import strings from '../../strings'; // @todo replace with type safe string file

export const DarkGradient: CSS = {
    background: `linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    $black
),
linear-gradient(
    to left,
    rgba(39, 137, 252),
    rgba(160, 80, 148)
)`,
};

export const RecordingUpsellingModal: FunctionComponent<{
    toggleUpsellingModal: () => void;
}> = ({ toggleUpsellingModal }) => {
    return (
        <UpsellingModal
            data-testid="upselling-modal"
            title={strings.upsellingTitleRecordings}
            subtitle={strings.upsellingContentRecordings}
            buttonLabel={strings.upsellingButtonLabel}
            css={DarkGradient}
            buttons={
                <>
                    {constants.FREE_USER_UPSELLING_URL && (
                        <>
                            <StyledButton
                                onClick={() => {
                                    window.open(
                                        constants.FREE_USER_UPSELLING_URL,
                                        '_blank',
                                        'noopener',
                                    );
                                    toggleUpsellingModal();
                                }}
                            >
                                {strings.upsellingButtonLabel}
                            </StyledButton>
                            <br />
                        </>
                    )}
                    <StyledButton secondary onClick={toggleUpsellingModal}>
                        Schließen
                    </StyledButton>
                </>
            }
        />
    );
};
