import { urlWithParams } from '@exaring/utils';
import constants from '../constants';
import { get } from '../request';

export const playoutUrl = (url, idfa, gdprConsent) =>
    get(urlWithParams(url, { idfa, gdprConsent }, true));

export const programDetails = (tvfuseProgramId) => {
    if (!tvfuseProgramId) {
        throw new Error('missing parameter tvfuseProgramId');
    }

    return get(`${constants.TUNER_BASE_URL}/contents/${tvfuseProgramId}`);
};

export const mediaLibrary = (channelId) => {
    if (!channelId) {
        throw new Error('missing parameter channelId');
    }

    const req = get(`${constants.TUNER_BASE_URL}/microsites/${channelId}/contents`);
    req.authorize = true;

    return req;
};
