import { h, FunctionComponent } from 'preact';

import CloseSvg from '@exaring/assets/icons/close.svg';
import InfoSvg from '@exaring/assets/icons/info.svg';
import { CSS } from '@stitches/react';
import { useTimeout } from '../../hooks/useTimeout';
import { styled, keyframes } from '../theme';

//
//  Constants
//

const VISIBILITY_TIME = 3.0; // Seconds

//
//  Css Animations
//

const fadeInOut = keyframes({
    '0%': { opacity: 0, bottom: -40 },
    '10%': { opacity: 1.0, bottom: 0 },
    '90%': { opacity: 1.0, bottom: 0 },
    '100%': { opacity: 0, bottom: -40 },
});

//
//  Components
//

export const Container = styled('div', {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    zIndex: '1000',
    position: 'fixed',
    pointerEvents: 'none',

    bottom: '-40px',
    opacity: 0,
    animation: `${fadeInOut} ${VISIBILITY_TIME}s`,
});

export const Toast = styled('div', {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: '6px',
    whiteSpace: 'nowrap',
    backgroundColor: '$aquamarine',
    borderRadius: '8px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
    padding: '6px 3px 6px 10px',
    margin: '10px',
    maxWidth: '500px',
    pointerEvents: 'all',

    variants: {
        type: {
            info: {
                color: '$toastInfoText',
                backgroundColor: '$toastInfoBackground',
            },
            error: {
                color: '$toastErrorText',
                backgroundColor: '$toastErrorBackground',
            },
            success: {
                color: '$toastSuccessText',
                backgroundColor: '$toastSuccessBackground',
            },
        },
    },
});

const ToastContent = styled('div', {
    flex: 1,
    fontSize: '14px',
});

const InfoIcon = styled(InfoSvg, {
    height: '30px',
});

const CloseIcon = styled(CloseSvg, {
    height: '30px',
    opacity: '0.35',
});

const CloseButton = styled('button', {
    border: 0,
    padding: 0,
    margin: 0,
    backgroundColor: 'inherit',
});

export const CloseToastButton: FunctionComponent<{ onClick?: () => void }> = ({ onClick }) => (
    <CloseButton onClick={onClick}>
        <CloseIcon />
    </CloseButton>
);

//
//  Main
//

export type SnackbarType = 'success' | 'info' | 'error'; // we should add more types like 'warning';

type SnackbarProps = {
    onClose?: () => void;
    type?: SnackbarType;
    css?: CSS;
};

export const Snackbar: FunctionComponent<SnackbarProps> = ({ css, onClose, type, children }) => {
    useTimeout(() => onClose?.(), VISIBILITY_TIME * 1000, [onClose]);
    return (
        <Container css={css}>
            <Toast type={type}>
                {type === 'info' && <InfoIcon />}
                <ToastContent>{children}</ToastContent>
                {onClose && <CloseToastButton onClick={onClose} />}
            </Toast>
        </Container>
    );
};
