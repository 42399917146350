import { h, FunctionComponent, VNode } from 'preact';
import { now, unix, GA } from '@exaring/utils';
import { isOnAir, isTVFuse } from '@exaring/utils/data/program';
import RestartIcon from '@exaring/assets/icons/restart.svg';
import LiveTVIcon from '@exaring/assets/icons/television.svg';
import { RecordingDetails } from '@exaring/networking/types/Recording/RecordingDetails';
import { IconButton, MediathekButton } from '../IconButton';

import { LayoutType, Program, LinkType } from './types';
import { styled } from '../theme';
import { PlayButton } from '../EpgDetails';

const Navigation = styled('div', {
    display: 'flex',
    columnGap: '20px',
    marginTop: '40px',
    variants: {
        layout: {
            modal: {
                marginTop: '20px',
            },
            epg: {},
            mediathek: {},
            microSite: {},
            player: {},
        },
    },
});

const StyledIconButton = styled(IconButton, {
    width: '60px',
});

type NavigationButtonsProps = {
    program: Program;
    navigationButtons?: VNode<any>[];
    hideMediathekButton?: boolean;
    hideRestartButton?: boolean;
    hidePlayButton?: boolean;
    isPlaying?: boolean;
    renderRecordingButton?: (program: Program) => any;
    handleMediathekButtonClick?: () => void;
    goTo?: (program: Program | undefined, linkType: LinkType) => void;
    layout?: LayoutType;
    isPlayoutTimeShifted?: boolean;
    recording?: RecordingDetails;
};

const unixCurrentTime = now().unix();
const unixStartTime = (p: Program) => unix(p.startTime);
const unixStopTime = (p: Program) => unix(p.stopTime);

const isProgramLive = (p: Program) =>
    p && unixCurrentTime > unixStartTime(p) && unixCurrentTime < unixStopTime(p);
const isProgramInFuture = (p: Program) => p && unixCurrentTime < unixStartTime(p);
const isProgramInPast = (p: Program) => p && unixCurrentTime > unixStopTime(p);

export const getProgramDetailsScreenName = (p: Program) => {
    if (isProgramLive(p)) {
        return 'program_details';
    }
    if (isProgramInFuture(p)) {
        return 'program_details_future';
    }
    if (isProgramInPast(p)) {
        return 'program_details_past';
    }

    return undefined;
};

export const NavigationButtons: FunctionComponent<NavigationButtonsProps> = ({
    layout,
    program,
    hideMediathekButton = false,
    hideRestartButton = false,
    hidePlayButton = false,
    renderRecordingButton,
    isPlaying = false,
    handleMediathekButtonClick,
    goTo,
    navigationButtons,
    isPlayoutTimeShifted,
    recording,
}) => {
    const navigationItems: JSX.Element[] = [];
    const programIsTvFuse = isTVFuse(program);
    const isPlayable = (p: Program) => p && (isOnAir(p) || isTVFuse(p) || p.newTvContentId);
    const programIsUpcoming = program && unix(program.startTime) > now().unix();
    const programIsFinished = program && unix(program.stopTime) < now().unix();
    const vodOrLive = isPlayable(program);
    const isRecordable = !program.recordingForbidden && !programIsFinished;
    const isPlayableRecording = recording?.status === 'FINISHED';

    const getPlayerType = () => {
        switch (layout) {
            case 'player':
                return isPlayoutTimeShifted ? 'time_shift' : 'livetv';
            case 'modal':
                return 'recording';
            case 'mediathek':
                return 'vod';
            case 'epg':
                return 'livetv';
            default:
                return undefined;
        }
    };

    if (layout === 'player') {
        if (programIsTvFuse) {
            navigationItems.push(
                <PlayButton
                    title=""
                    isPlaying={isPlaying}
                    onClick={() => goTo?.(program, 'togglePlay')}
                />,
            );

            // todo add next button when logic is specified
            // if (program.nextId) {
            //     navigationItems.push(<IconButton onClick={() => goTo?.(program, 'next')}>Nächste Folge</IconButton>);
            // }
        } else if (isRecordable && renderRecordingButton) {
            navigationItems.push(renderRecordingButton(program));
        }
    } else {
        const recordingButton =
            isRecordable && renderRecordingButton ? renderRecordingButton(program) : undefined;

        const primaryButton = () => {
            if (!vodOrLive && !isPlayableRecording) {
                return recordingButton;
            }
            if ((vodOrLive || isPlayableRecording) && !hidePlayButton) {
                return (
                    <PlayButton
                        onClick={() => {
                            GA.track({
                                event: 'player_controls',
                                event_description: 'play',
                                channel_name: program.channelDisplay,
                                program_id: program.id,
                                program_title: program.title,
                                player_type: getPlayerType(),
                                screen_name: getProgramDetailsScreenName(program),
                            });
                            goTo?.(program, 'play');
                        }}
                        text="onlyOnDesktop"
                    />
                );
            }

            return undefined;
        };

        if ((!programIsFinished || vodOrLive || isPlayableRecording) && primaryButton()) {
            navigationItems.push(primaryButton());
        }
    }
    if (navigationButtons) {
        navigationItems.push(...navigationButtons);
    }

    if (layout === 'modal') {
        if (!hideRestartButton && !programIsUpcoming) {
            navigationItems.push(
                <StyledIconButton
                    icon={<RestartIcon width="44" height="44" />}
                    onClick={() => {
                        GA.track({
                            event: 'player_controls',
                            event_description: 'restart',
                            channel_name: program.channelDisplay,
                            program_id: program.id,
                            program_title: program.title,
                            player_type: 'recordings',
                            screen_name: getProgramDetailsScreenName(program),
                        });
                        goTo?.(program, 'restart');
                    }}
                />,
            );
        }
        navigationItems.push(
            <StyledIconButton
                icon={<LiveTVIcon width="44" height="44" />}
                onClick={() => {
                    GA.track({
                        event: 'player_controls',
                        event_description: 'go_to_channel',
                        channel_name: program.channelDisplay,
                        program_id: program.id,
                        program_title: program.title,
                        screen_name: getProgramDetailsScreenName(program),
                    });
                    goTo?.(program, 'togglePlay');
                }}
            />,
        );
    }

    if (!hideMediathekButton) {
        navigationItems.push(
            <MediathekButton
                css={{ width: '60px' }}
                iconSize={44}
                onClick={() => {
                    GA.track({
                        event: layout === 'modal' ? 'player_controls' : 'epg',
                        event_description: 'open_media_library',
                        channel_name: program.channelDisplay,
                        program_id: program.id,
                        program_title: program.title,
                        player_type: getPlayerType(),
                        screen_name: getProgramDetailsScreenName(program),
                    });
                    if (handleMediathekButtonClick) {
                        handleMediathekButtonClick();
                    } else {
                        goTo?.(program, 'mediathek');
                    }
                }}
            />,
        );
    }

    return navigationItems.length > 0 ? (
        <Navigation layout={layout}>{navigationItems}</Navigation>
    ) : null;
};
