/*
 *  Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, Fragment, FunctionComponent, Ref } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import { isNil } from '@exaring/utils';
import { BigTile, SmallTile, ChannelTile } from '@exaring/ui/components-styled/Tile';
import { CollectionTile } from '@exaring/ui/components-styled/Tile/CollectionTile';
import { isEmptyString } from '@exaring/utils/string';
import Header from '../components/Header';
import { useWaiputhekStore, notificationsStore } from '../state/Store';
import {
    HighlightItem,
    HighlightVideoItem,
    HIGHLIGHT_CATEGORY,
    HIGHLIGHT_COLLECTION,
    HIGHLIGHT_VIDEO,
    isVideo,
    isCategory,
    matcher as highlightItemMatcher,
    HighlightCategoryItem,
} from '../types/api/HighlightItem';
import { PlayerType } from '../types/tracking';
import {
    DisplayType,
    matcher as highlightSectionMatcher,
    SECTION_CLIPS,
    SECTION_HOLLYWOOD,
    SECTION_MEDIA_LIBRARIES,
    SECTION_MOVIES,
    SECTION_PLAYLISTS,
    SECTION_TEASER,
} from '../types/api/HighlightSection';
import { StyledWaiputhekPage } from '../components/waiputhek/StyledWaiputhekPage';
import { scrollToSection, Section } from '../components/waiputhek/Section';
import { PageHeadline } from '../components/waiputhek/PageHeadline';
import { StyledLoadingSpinner } from '../components/StyledLoadingSpinner';
import { TileScroller } from '../components/waiputhek/TileScroller';
import { PageFooter } from '../components/waiputhek/PageFooter';
import strings from '../strings';
import { WebClientGA, WebClientGAEvent } from '../web-client-ga';
import { imageResource } from '../helper';

const getDurationLabel = (duration?: number): string | undefined =>
    duration ? `${duration} Min.` : undefined;

const getMovieOrHollywoodTile = (
    content: HighlightItem,
    imageSrc: string,
    onMediaPlayout: () => void,
    onOpenDetails: () => void,
) => {
    if (isCategory(content)) {
        return (
            <BigTile
                key={content.sourceUrl}
                imageSrc={imageSrc}
                title={content.title}
                genre={content.genreDisplayName}
                channel={content.channelDisplay}
                locked={content.locked}
                onMediaPlayout={onMediaPlayout}
                onOpenDetails={onOpenDetails}
                hidePlayOverlay
                tall
            />
        );
    }
    if (isVideo(content)) {
        return (
            <BigTile
                key={content.sourceUrl}
                imageSrc={imageSrc}
                title={content.title}
                time={getDurationLabel(content.duration)}
                genre={content.genreDisplayName}
                channel={content.channelDisplay}
                locked={content.locked}
                onMediaPlayout={onMediaPlayout}
                onOpenDetails={onOpenDetails}
                tall
            />
        );
    }

    return undefined;
};

const createImageLinkWithAspectRatios = (
    rel: string | undefined,
    href: string | undefined,
    width: number,
) => {
    if (href === undefined || isEmptyString(href)) {
        return '';
    }

    let height;
    switch (rel) {
        case 'image-16:9':
            height = (width / 16) * 9;
            break;
        case 'image-1:1':
            height = width;
            break;
        case 'image-2:3':
            height = (width / 2) * 3;
            break;
        case 'image-4:3':
            height = (width / 4) * 3;
            break;
        default:
            console.error(`${rel} was ignored. Using 16:9 as fallback`);
            height = (width / 16) * 9;
            break;
    }

    return imageResource(href, width, height);
};

const trackClick = (
    content: HighlightItem,
    moduleId: string,
    sectionIdx?: number,
    tileIndex?: string,
) => {
    const playerType: PlayerType = 'vod';
    highlightItemMatcher(content.type, {
        [HIGHLIGHT_CATEGORY]: () => {
            WebClientGA().trackEvent(
                {
                    eventName: WebClientGAEvent.Waiputhek,
                    eventDescription: WebClientGAEvent.WaiputhekSelectHighlightCollection,
                    channelName: content.channel,
                    screenName: 'waiputhek',
                    programTitle: content.title,
                    playerType,
                },
                {
                    section_id: moduleId,
                    section_position: `${sectionIdx}_${tileIndex}`,
                    moduleId,
                    locked: content.locked,
                },
            );
        },
        [HIGHLIGHT_COLLECTION]: () => {
            WebClientGA().trackEvent(
                {
                    eventName: WebClientGAEvent.Waiputhek,
                    eventDescription: WebClientGAEvent.WaiputhekSelectHighlightCollection,
                    channelName: content.channel,
                    screenName: 'waiputhek',
                    programTitle: content.title,
                    playerType,
                },
                {
                    section_id: moduleId,
                    section_position: `${sectionIdx}_${tileIndex}`,
                    locked: content.locked,
                },
            );
        },
        [HIGHLIGHT_VIDEO]: () => {
            const { contentId } = content as HighlightVideoItem;
            if (contentId !== undefined) {
                WebClientGA().trackEvent(
                    {
                        eventName: WebClientGAEvent.Waiputhek,
                        eventDescription: WebClientGAEvent.WaiputhekSelectHighlightVideo,
                        screenName: 'waiputhek',
                        channelName: content.channel,
                        programTitle: content.title,
                        programId: contentId,
                        playerType,
                    },
                    {
                        locked: content.locked,
                        section_id: moduleId,
                        section_position: `${sectionIdx}_${tileIndex}`,
                    },
                );
            }
        },
    });
};

const contentTileMapper =
    (
        displayType: DisplayType,
        moduleId: string,
        sectionIdx: number,
        goToChannel: (id: string, categoryId?: string, moduleId?: string) => void,
        goToContent: (id: string, moduleId?: string) => void,
        goToVod: (contentId: string, channelId?: string, moduleId?: string) => void,
        onLockedClick: () => void,
    ) =>
    (content: HighlightItem, idx?: number): JSX.Element | undefined => {
        const imageAspectRatio = content.links?.[0]?.rel;
        const imageSrc = content.links?.[0]?.href;
        const imageResourceWithSize = createImageLinkWithAspectRatios(
            imageAspectRatio,
            imageSrc,
            displayType === SECTION_TEASER ? 640 : 512,
        );

        const tileDetailsClickHandler = () => {
            trackClick(
                content,
                moduleId,
                sectionIdx,
                !isNil(idx) ? (idx + 1).toString() : undefined,
            );
            if (content.locked) {
                onLockedClick();
            } else {
                highlightItemMatcher(content.type, {
                    [HIGHLIGHT_CATEGORY]: () =>
                        goToChannel(
                            content.channel,
                            (content as HighlightCategoryItem).categoryId,
                            moduleId,
                        ),
                    [HIGHLIGHT_COLLECTION]: () => goToChannel(content.channel, undefined, moduleId),
                    [HIGHLIGHT_VIDEO]: () => {
                        const id = (content as HighlightVideoItem).contentId;
                        if (id) {
                            goToContent(id, moduleId);
                        }
                    },
                });
            }
        };

        const tilePlayoutClickHandler = () => {
            if (!content.locked && isVideo(content) && content.contentId) {
                trackClick(
                    content,
                    moduleId,
                    sectionIdx,
                    !isNil(idx) ? (idx + 1).toString() : undefined,
                );
                WebClientGA().trackEvent({
                    eventName: WebClientGAEvent.PlayerControls,
                    eventDescription: WebClientGAEvent.Play,
                    channelName: content.channel,
                    programTitle: content.title,
                    screenName: 'player_media_library',
                    programId: content.contentId,
                    playerType: 'vod',
                });
                goToVod(content.contentId, content.channel, moduleId);
            } else {
                tileDetailsClickHandler();
            }
        };

        return highlightSectionMatcher<JSX.Element | undefined>(displayType, {
            [SECTION_TEASER]: () => (
                <CollectionTile
                    key={content.sourceUrl}
                    imageSrc={imageResourceWithSize}
                    locked={content.locked}
                    alt={content.title || ''}
                    onClick={tileDetailsClickHandler}
                />
            ),
            [SECTION_MEDIA_LIBRARIES]: () => (
                <ChannelTile
                    key={content.sourceUrl}
                    imageSrc={imageResourceWithSize}
                    clickable={!content.locked}
                    title={content.title}
                    onClick={tileDetailsClickHandler}
                />
            ),
            [SECTION_HOLLYWOOD]: () =>
                getMovieOrHollywoodTile(
                    content,
                    imageResourceWithSize,
                    tilePlayoutClickHandler,
                    tileDetailsClickHandler,
                ),
            [SECTION_MOVIES]: () =>
                getMovieOrHollywoodTile(
                    content,
                    imageResourceWithSize,
                    tilePlayoutClickHandler,
                    tileDetailsClickHandler,
                ),
            [SECTION_CLIPS]: () =>
                isVideo(content) ? (
                    <BigTile
                        key={content.sourceUrl}
                        imageSrc={imageResourceWithSize}
                        title={content.title}
                        time={getDurationLabel(content.duration)}
                        genre={content.genreDisplayName}
                        channel={content.channelDisplay}
                        locked={content.locked}
                        onMediaPlayout={tilePlayoutClickHandler}
                        onOpenDetails={tileDetailsClickHandler}
                    />
                ) : undefined,
            [SECTION_PLAYLISTS]: () => {
                return (
                    <SmallTile
                        key={content.sourceUrl}
                        imageSrc={imageResourceWithSize}
                        title={content.title}
                        channel={content.channelDisplay}
                        locked={content.locked}
                        onMediaPlayout={tilePlayoutClickHandler}
                        onOpenDetails={tileDetailsClickHandler}
                        hidePlayOverlay={!isVideo(content)}
                    />
                );
            },
        });
    };

const subtitle = {
    teaser: ['Mediathek & Kategorie', 'Mediatheken & Kategorien'],
    playlists: ['Video', 'Videos'],
    movies: ['Film', 'Filme'],
    clips: ['Genre & Kategorie', 'Genres & Kategorien'],
    mediaLibraries: ['Sender', 'Sender'],
    hollywood: ['Video', 'Videos'],
};

const getTileIndex = (
    contents: HighlightItem[],
    contentId?: string,
    channelId?: string,
    categoryId?: string,
) => {
    if (contentId) {
        return contents.findIndex((c) => isVideo(c) && c.contentId === contentId);
    }

    if (channelId) {
        const index = categoryId
            ? contents.findIndex(
                  (c) => isCategory(c) && c.channel === channelId && c.categoryId === categoryId,
              )
            : -1;

        return index > -1 ? index : contents.findIndex((c) => c.channel === channelId);
    }

    return 0;
};

export const WaiputhekPage: FunctionComponent<{
    moduleId?: string;
    contentId?: string;
    channelId?: string;
    categoryId?: string;
    goToChannel: (id: string, categoryId?: string, moduleId?: string) => void;
    goToContent: (id: string, moduleId?: string) => void;
    goToVod: (contentId: string, channelId?: string, moduleId?: string) => void;
}> = ({ goToChannel, goToContent, goToVod, moduleId, categoryId, channelId, contentId }) => {
    const { highlightsData, fetchHighlights } = useWaiputhekStore();
    const notifications = notificationsStore();
    const scrollRef = useRef<HTMLDivElement>();

    const { value, state } = highlightsData;

    useEffect(() => {
        // @todo add error handling / retry, design needed
        if (state !== 'Success') {
            fetchHighlights();
        }
    }, [fetchHighlights]);

    const maxPercentage = useRef(0);

    useEffect(() => {
        const handleScroll = () => {
            const lastPercentage = Math.min(
                1,
                (window.innerHeight + window.pageYOffset) / document.body.offsetHeight,
            );

            if (lastPercentage > maxPercentage.current) {
                maxPercentage.current = lastPercentage;
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const handleScrollEnd = () => {
            const normalizedPercentage = parseFloat(maxPercentage.current.toFixed(2)) * 100;
            if (normalizedPercentage === 100) {
                WebClientGA().trackEvent({
                    eventName: WebClientGAEvent.ScrollDepth,
                    eventDescription: '100%' as any,
                    screenName: 'Waiputhek',
                });
            } else if (normalizedPercentage >= 75) {
                WebClientGA().trackEvent({
                    eventName: WebClientGAEvent.ScrollDepth,
                    eventDescription: '75%' as any,
                    screenName: 'Waiputhek',
                });
            } else if (normalizedPercentage >= 50) {
                WebClientGA().trackEvent({
                    eventName: WebClientGAEvent.ScrollDepth,
                    eventDescription: '50%' as any,
                    screenName: 'Waiputhek',
                });
            } else if (normalizedPercentage >= 25) {
                WebClientGA().trackEvent({
                    eventName: WebClientGAEvent.ScrollDepth,
                    eventDescription: '25%' as any,
                    screenName: 'Waiputhek',
                });
            }
        };

        window.addEventListener('scrollend', handleScrollEnd);

        return () => {
            window.removeEventListener('scrollend', handleScrollEnd);
        };
    }, [maxPercentage]);

    useEffect(() => {
        if (state === 'Success') {
            scrollToSection(scrollRef.current);
        }
    }, [state]);

    const handleLockedClick = () => {
        notifications.createUpsellingToast();
    };

    return (
        <>
            <Header id="waiputhek-page" />
            <StyledWaiputhekPage>
                {state === 'Loading' && <StyledLoadingSpinner />}
                {state === 'Success' && (
                    <>
                        <PageHeadline title={strings.waiputhekLabel} />
                        {value.map((section, sectionIdx) => {
                            const title =
                                subtitle[section.displayType as keyof typeof subtitle] ||
                                subtitle.clips;
                            const sectionContentLength = section.contents.length;
                            const focus = section.id === moduleId;
                            const defaultIndex = focus
                                ? getTileIndex(section.contents, contentId, channelId, categoryId)
                                : undefined;

                            return (
                                section.contents &&
                                section.contents.length > 0 && (
                                    <Section
                                        ref={focus ? (scrollRef as Ref<HTMLDivElement>) : undefined}
                                        title={section.title}
                                        subtitle={`${sectionContentLength} ${
                                            title[sectionContentLength > 1 ? 1 : 0]
                                        }`}
                                        key={section.id}
                                    >
                                        <TileScroller<HighlightItem>
                                            translateArrow={
                                                section.displayType === SECTION_MEDIA_LIBRARIES
                                                    ? '-50px'
                                                    : undefined
                                            }
                                            data={section.contents}
                                            dataIndex="sourceUrl"
                                            mapTile={contentTileMapper(
                                                section.displayType,
                                                section.id,
                                                sectionIdx + 1,
                                                goToChannel,
                                                goToContent,
                                                goToVod,
                                                handleLockedClick,
                                            )}
                                            defaultIndex={defaultIndex}
                                        />
                                    </Section>
                                )
                            );
                        })}
                        <PageFooter />
                    </>
                )}
            </StyledWaiputhekPage>
        </>
    );
};
