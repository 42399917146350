/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { FunctionComponent, h, ComponentType } from 'preact';
import { styled, CSS, Globals, StyledComponentBaseProps } from '../../theme';
import { CenteredLayout } from './Layouts';

/**
 * viewport: complete available viewport starting over navigation
 * overlay: over content but starting below navigation
 */
export type DisplayVariants = 'viewport' | 'overlay';

const boundingRect: CSS = { top: 0, right: 0, bottom: 0, left: 0 };

const ModalBody = styled('div', {
    display: 'flex',

    position: 'fixed',
    overflowY: 'auto',
    overscrollBehavior: 'contain', // prevent contextual scroll position to change when modal is scrollable (this is only having effect if modal content boundings exceed the viewport and cause the modal scrollbar to appear)
    zIndex: 10,

    variants: {
        display: {
            viewport: { ...boundingRect },
            overlay: {
                ...boundingRect,
                top: Globals.headerHeight,
                bottom: -Globals.headerHeight,
            },
        },
    },
});

export type ModalProps = StyledComponentBaseProps & {
    variant?: DisplayVariants;
    layout?: ComponentType;
};

export const Modal: FunctionComponent<ModalProps> = ({
    variant = 'overlay',
    className,
    css,
    layout: Layout,
    children,
}) => {
    const LayoutComponent = Layout || CenteredLayout;
    return (
        <ModalBody display={variant} className={className} css={css}>
            <LayoutComponent>{children}</LayoutComponent>
        </ModalBody>
    );
};
