import { isObject, isString, isArrayOf, isBoolean } from '@exaring/utils';

export interface EpgStation {
    stationId: string;
    displayName: boolean;
    userSettings: EpgStationUserSettings;
    locked: boolean;
    omitted: boolean;
    streamQuality: string;
}

interface EpgStationUserSettings {
    favorite: boolean;
    visible: boolean;
}

const isUserSettings = (value: unknown): value is EpgStationUserSettings => {
    if (isObject(value)) {
        const { favorite, visible } = value;
        return isBoolean(favorite) && isBoolean(visible);
    }
    return false;
};

const isValidJson = (value: unknown): value is EpgStation[] => {
    if (isArrayOf(isObject, value) && value[0]) {
        const { stationId, displayName, locked, omitted, streamQuality, userSettings } = value[0];
        return (
            isString(stationId) &&
            isString(displayName) &&
            isBoolean(locked) &&
            isBoolean(omitted) &&
            isString(streamQuality) &&
            isUserSettings(userSettings)
        );
    }

    return false;
};

export const decode = (value: unknown): EpgStation[] | undefined => {
    return isValidJson(value) ? value : undefined;
};
