/* eslint-disable @typescript-eslint/ban-ts-comment */
import { h, Fragment, FunctionComponent } from 'preact';
import { useEffect, useState } from 'preact/hooks';

import EyeIcon from '@exaring/assets/icons/eye-off.svg';
import ResetIcon from '@exaring/assets/icons/channel-reset.svg';
import ListIcon from '@exaring/assets/icons/listview.svg';
import GridIcon from '@exaring/assets/icons/gridview.svg';
import ArrowBack from '@exaring/assets/icons/arrow-back.svg';
import { connect } from 'unistore/preact';
import Header from '../components/Header';
import { PageGrid, PageGridItem } from '../../../../packages/ui/components-styled/PageGrid';
import { ChannelSortGridList } from '../components/ChannelSortGridList';
import store from '../store';

// @ts-ignore
import * as channelSortStore from '../actions/channel-sort';
import { SortItemChannel } from '../components/SortItem';
import { routeToEpg } from '../routes';
import {
    BackButton,
    ChannelSortContent,
    Description,
    HeaderText,
    ResetButton,
    StickyBottomGradient,
    StickySubheader,
    ViewButton,
} from './ChannelSortPage.styles';
import { HiddenChannelsModal } from '../components/HiddenChannelsModal';
import { useChannelConfigNotifications } from '../hooks/useChannelConfigNotifications';
import { epgStore } from '../state/Store';

type View = 'grid' | 'list';

type ChannelSortPageProps = {
    onToggleFavorite: (id: string) => void;
    onToggleVisible: (id: string, show: boolean) => void;
    onReorder: (ids: string[], draggedChannel?: string) => void;
    channels: SortItemChannel[];
    onResetChannels: () => void;
    onCancelResetChannels: () => void;
    /**
     * @deprecated after rewrite use the onReorder callback
     */
    shiftVisibleChannel: (fromIndex: number, toIndex: number) => void;
    /**
     * @deprecated after rewrite use different approach
     */
    showHiddenChannels: () => void;
    /**
     * @deprecated after rewrite use different approach
     */
    resetState: 'success' | 'error' | undefined;
    /**
     * @deprecated after rewrite use different approach
     */
    showAllChannelsState: number | 'error' | undefined;
};

const ChannelSortPage: FunctionComponent<ChannelSortPageProps> = ({
    onToggleFavorite,
    onToggleVisible,
    onReorder,
    onResetChannels,
    onCancelResetChannels,
    channels,
    shiftVisibleChannel,
    showHiddenChannels,
    resetState,
    showAllChannelsState,
}) => {
    const [view, setView] = useState<View>('grid');
    const [showHiddenChannelsModal, setShowHiddenChannelsModal] = useState(false);

    const { showResetModal, showResetVisibilityModal, showNotification } =
        useChannelConfigNotifications();

    const hasHiddenChannels = channels.some((chan) => !chan.visible);
    const visibleChannelsCount = channels.filter((chan) => chan.visible).length;

    // This is same implementation as it was done in the old code.
    // Gonna be thrown away in the new-world
    // Reach for the skies!
    useEffect(() => {
        return () => {
            const { fetchEpgStations } = epgStore();
            fetchEpgStations();
        };
    }, []);

    // show notification only when resetState changes to a different, non-undefined value
    useEffect(() => {
        if (resetState === 'success') {
            showNotification('reset-success');
        }
        if (resetState === 'error') {
            showNotification('reset-error');
        }
    }, [resetState]);

    useEffect(() => {
        if (typeof showAllChannelsState === 'number') {
            showNotification('show-all-success', showAllChannelsState);
            setShowHiddenChannelsModal(false);
        }
        if (showAllChannelsState === 'error') {
            showNotification('show-all-error');
        }
    }, [showAllChannelsState]);

    const subheader = (
        <StickySubheader>
            <BackButton onClick={() => routeToEpg()}>
                <ArrowBack />
            </BackButton>
            <PageGrid sm={6}>
                <PageGridItem xs="hidden" sm={1} lg={2} xl={3} />
                <PageGridItem sm={2} md={1}>
                    <ViewButton
                        selected={view === 'grid'}
                        epg
                        icon={<GridIcon />}
                        onClick={() => setView('grid')}
                    >
                        Rasteransicht
                    </ViewButton>
                </PageGridItem>
                <PageGridItem sm={2} md={1}>
                    <ViewButton
                        selected={view === 'list'}
                        epg
                        icon={<ListIcon />}
                        onClick={() => setView('list')}
                    >
                        Listenansicht
                    </ViewButton>
                </PageGridItem>
                <PageGridItem sm="hidden" lg={1} xl={2} />
                <PageGridItem>
                    <ResetButton
                        epg
                        icon={<ResetIcon />}
                        onClick={() => showResetModal(onResetChannels, onCancelResetChannels)}
                    >
                        <span>Zurücksetzen</span>
                    </ResetButton>
                </PageGridItem>
            </PageGrid>
        </StickySubheader>
    );

    const details = (
        <PageGrid>
            <PageGridItem xs="full">
                <HeaderText>Sendersortierung aller TV-Sender</HeaderText>
            </PageGridItem>
            <PageGridItem sm={2} md={3} lg={5} xl={7}>
                <Description>
                    Sender per Drag and Drop sortieren, ein- oder ausblenden sowie zu ihren
                    Favoriten hinzufügen.
                </Description>
            </PageGridItem>
            <PageGridItem>
                <ViewButton
                    epg
                    icon={<EyeIcon />}
                    onClick={() => setShowHiddenChannelsModal(true)}
                    disabled={!hasHiddenChannels}
                >
                    Ausgeblendete Sender
                </ViewButton>
            </PageGridItem>
        </PageGrid>
    );

    const sortableChannels =
        channels.length === 0 ? (
            <></>
        ) : (
            <ChannelSortGridList
                view={view}
                channels={channels}
                onToggleFavorite={onToggleFavorite}
                onToggleVisible={onToggleVisible}
                onReorder={(ids, draggedChannel) => {
                    showNotification('reorder-success');
                    onReorder(ids, draggedChannel);
                }}
                onReorderError={() => showNotification('reorder-error', visibleChannelsCount)}
                onChannelShift={(from, to) => {
                    showNotification('reorder-success');
                    shiftVisibleChannel(from, to);
                }}
            />
        );

    const hiddenChannelsModal =
        channels.length === 0 ? (
            <></>
        ) : (
            <HiddenChannelsModal
                channels={channels}
                show={showHiddenChannelsModal}
                onToggleVisible={onToggleVisible}
                onClose={() => {
                    setShowHiddenChannelsModal(false);
                }}
                showHiddenChannels={() => showResetVisibilityModal(showHiddenChannels)}
            />
        );

    return (
        <>
            {hiddenChannelsModal}
            <Header />
            {subheader}
            <ChannelSortContent>
                {details}
                {sortableChannels}
            </ChannelSortContent>
            <StickyBottomGradient />
        </>
    );
};

// *****************************************************************
// Everything below will be removed for the TanStack/Signals rewrite
// Let's don't care that much about the style/quiality
// *****************************************************************

type MappedProps = {
    channels: SortItemChannel[];
    resetState: 'success' | 'error' | undefined;
    // In case of success, number describes how many channels were affected
    showAllChannelsState: number | 'error' | undefined;
    error: number;
};

// @ts-ignore
const mapStateToProps = ({ channelSort }): MappedProps => {
    return {
        channels: Array.from(channelSort.get('channelsAll')).map((i: any) => ({
            favorite: i.faved,
            visible: !i.hidden,
            id: i.id,
            displayName: i.displayName,
            locked: i.locked,
        })),

        showAllChannelsState: channelSort.get('showAllChannelsState'),
        resetState: channelSort.get('resetState'),
        error: channelSort.get('error'),
    };
};

const combinedActions = () => {
    return {
        ...channelSortStore.actions(store),
    };
};

type ChannelSortPageWrapperProps = {
    init: () => void;
    toggleFavorite: (id: string) => void;
    toggleChannel: (id: string, show: boolean) => void;
    sortChannels: (ids: string[], draggedChannel?: string) => void;
    resetChannels: () => void;
    cancelResetChannels: () => void;
    resetState: MappedProps['resetState'];
    showAllChannelsState: MappedProps['showAllChannelsState'];
    channels: SortItemChannel[];
    error: number;
    showHiddenChannels: () => void;
    shiftVisibleChannel: (fromIndex: number, toIndex: number) => void;
};

const ChannelSortPageWrapper: FunctionComponent<ChannelSortPageWrapperProps> = ({
    init,
    channels,
    toggleChannel,
    toggleFavorite,
    sortChannels,
    resetChannels,
    cancelResetChannels,
    resetState,
    showAllChannelsState,
    showHiddenChannels,
    shiftVisibleChannel,
}) => {
    useEffect(() => {
        init();
    }, []);

    return (
        <ChannelSortPage
            channels={channels}
            onToggleFavorite={(id) => toggleFavorite(id.toUpperCase())}
            onToggleVisible={(id, show) => toggleChannel(id.toUpperCase(), show)}
            onReorder={(ids, draggedChannel) => sortChannels(ids, draggedChannel)}
            onResetChannels={() => resetChannels()}
            onCancelResetChannels={() => cancelResetChannels()}
            resetState={resetState}
            showAllChannelsState={showAllChannelsState}
            shiftVisibleChannel={(from, to) => shiftVisibleChannel(from, to)}
            showHiddenChannels={showHiddenChannels}
        />
    );
};

export const ConnectedChannelSortPage = connect(
    mapStateToProps,
    combinedActions,
    // @ts-ignore
)(ChannelSortPageWrapper);
