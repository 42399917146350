import { mapWithoutNil, isObject, isString } from '@exaring/utils/index';
import { MediathekCategory, decode as decodeMediathekCategory } from './MediathekCategory';

export interface Mediathek {
    channel: string;
    title?: string;
    genre?: string;
    img?: string;
    imprint?: string;
    waiputhekImg?: string;
    categories: Array<MediathekCategory>;
}

const isValidJson = (value: any): value is Mediathek => {
    if (isObject(value)) {
        const { channel, categories } = value;
        return isString(channel) && Array.isArray(categories);
    }

    return false;
};

export const decode = (value: unknown): Mediathek | undefined => {
    return isValidJson(value)
        ? {
              ...value,
              categories: mapWithoutNil(value.categories, decodeMediathekCategory),
          }
        : undefined;
};
