import { CTime, now } from '@exaring/utils';
import { useMemo } from 'preact/hooks';
import { EPG_DAYS } from '../constants';

type UnixTimestamp = number;

export const useEpgDateRange = (): [CTime, CTime, UnixTimestamp, UnixTimestamp] => {
    const timeRef = now().startOf('hour').toString();

    return useMemo(() => {
        const startDate = now().subtract(4, 'h').startOf('hour'); // always round to full hour otherwise planby gets fucked up
        const endDate = now().add(EPG_DAYS, 'days').startOf('day');

        const startUtc = startDate.time();
        const endUtc = endDate.time();

        return [startDate, endDate, startUtc, endUtc];
        // disable rule as eslint can not reason about ctime internals
    }, [timeRef]);
};
