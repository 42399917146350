/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { isNil } from '../validators';

/**
 * Find value at key in 2DArray.
 * @param {any[]} array
 * @param {string} key
 * @param {any} value
 * @returns {number} index
 */
export function indexInArray(array, key, value) {
    if (!array) {
        return -1;
    }

    for (let i = 0; i < array.length; i += 1) {
        if (array[i][key] === value) {
            return i;
        }
    }
    return -1;
}

/**
 * Join truthy Items of a 2DArray to a string (removes given falsy elements).
 * @param {string[]} array
 * @param {string} del delimiter string
 * @returns {string} joined string
 */
export function arrj(array, del = ' ') {
    if (!array) {
        return '';
    }
    return array
        .filter(Boolean)
        .map((item) => item.trim())
        .join(del);
}

/**
 * Convert an array of objects to an object containing all array entries idientified by given object key.
 * @param {any[]} array array of objects, e.g. [{ name: 'a', cnt: 5 }, { name: 'b', cnt: 10 }]
 * @param {string} key object key, e.g. 'name'. Array entries without given key will be ignored.
 * @returns {any} reduced array as object, e.g. { 'a': { name: 'a', cnt: 5 }, 'b': { name: 'b', cnt: 10 } }
 */
export function arrayToObject(array, key) {
    if (!array || !key) {
        return undefined;
    }

    return (
        array?.reduce((prev, curr) => {
            if (!curr[key] || (typeof curr[key] !== 'number' && typeof curr[key] !== 'string')) {
                return prev;
            }

            return { ...prev, [curr[key]]: curr };
        }, {}) || {}
    );
}

/**
 * Map all items of given array and only return items that are not null or undefined after mapping.
 * @param {any[]} array
 * @param {(item) => any} mapper
 * @returns {any[]} mapped array
 */
export function mapWithoutNil(array, mapper) {
    if (!array || !mapper) {
        return [];
    }

    return array.reduce((reduced, content, currentIndex) => {
        const mapped = mapper(content, currentIndex);
        return isNil(mapped) ? reduced : [...reduced, mapped];
    }, []);
}

/**
 * Check if every element of given array passes given check.
 *
 * @param {(v: unknown) => boolean} checkFn Use a type guard to benefit from type inference.
 * @param {unknown[]} value Value / Array to check it's elements against.
 * @return boolean
 */
export const isArrayOf = (checkFn, value) => Array.isArray(value) && value.every(checkFn);
