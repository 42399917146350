/* eslint-disable import/prefer-default-export */
import { auth, jsonEncoder, dataValidator as requestDataValidator } from './request';
import { statusCode, jsonDecoder, binaryDecoder, dataValidator } from './response';

/**
 * Mixin params to hook
 * @param  {Function} hook
 * @param  {Record<PropertyKey, any>} options
 * @return {Function} wrapped hook
 */
const mixinParams = (hook, options) => (req) => hook(req, options);

/**
 * Default config for common usage of request hooks
 *
 * REQUEST:
 *   -> auth
 *   -> jsonEncoder
 *   -> requestDataValidator
 * RESPONSE:
 *   -> statusCode
 *   -> jsonDecoder
 *   -> dataValidator
 *   -> binaryDecoder
 * @param  {Record<PropertyKey, any>} options
 * @param  {Record<PropertyKey, any>} hooks
 * @return {Record<PropertyKey, any>}
 */
const defaultHooksConfig = (options = {}, hooks = {}) => {
    const { request, response } = hooks;

    return {
        request: [
            mixinParams(auth, options),
            mixinParams(requestDataValidator, options),
            mixinParams(jsonEncoder, options),
            ...(request || []).map((fn) => mixinParams(fn, options)),
        ],
        response: [
            mixinParams(jsonDecoder, options),
            mixinParams(dataValidator, options), // must be defined after jsonDecoder
            mixinParams(binaryDecoder, options),
            ...(response || []).map((fn) => mixinParams(fn, options)),
            mixinParams(statusCode, options),
        ],
    };
};

export { defaultHooksConfig, mixinParams };
