import { h } from 'preact';
import { memo } from 'preact/compat';
import { classnames, epgDetailsLiveDateFormat, conditionalCb } from '@exaring/utils';
import { Button } from '@exaring/ui';
import { useCallback, useEffect, useMemo } from 'preact/hooks';
import { useThrottle } from '@exaring/ui/hooks/throttle';
import { useScrollViewport } from '@exaring/ui/hooks/useScrollViewport';
import EpgChannelNavigation from './epg/EpgChannelNavigation';
import { routeTo, Routes } from '../routes';
import { WebClientGA, WebClientGAEvent } from '../web-client-ga';

import FavoriteButton from './FavoriteButton';
import { notificationsStore, useEpgStore } from '../state/Store';
import { getLiveUTCTimeSlot } from '../state/EpgStore';
import { epgV2ProgramToV1 } from '../actions/helper';

const CHANNEL_ITEM_HEIGHT = 76;

const scrollToActiveChannel = () => {
    const list = document.querySelector('.channel-list');
    const activeChannel = list && list.querySelector('.is-active');

    if (list && activeChannel) {
        list.scrollTop = activeChannel && activeChannel.offsetTop - list.offsetTop;
    }
};

const ChannelList = ({
    activeChannel,
    isDisabled,
    show,
    channels: _channels,
    isFavoriteMode,
    onMouseEnter,
    onMouseLeave,
    onChannelItemAction,
    onToggleFavorite,
}) => {
    const channels = useMemo(
        () => _channels?.filter((channel) => !channel.locked && channel.visible),
        [_channels],
    );

    useEffect(() => {
        show && scrollToActiveChannel();
    }, [show, activeChannel]);

    const {
        epgData: { live: liveProgram },
        isTimeslotOnError,
        getLiveProgram,
    } = useEpgStore();

    const notifications = notificationsStore();

    const [channelListRef, scrollX, scrollY, width, height] = useScrollViewport(); // eslint-disable-line no-unused-vars

    const [start, end] = useMemo(() => {
        const startIndex = Math.floor(scrollY / CHANNEL_ITEM_HEIGHT);
        const endIndex =
            startIndex + Math.ceil((height + CHANNEL_ITEM_HEIGHT) / CHANNEL_ITEM_HEIGHT); // load always one channel ahead to avoid visual glitches
        return [startIndex, endIndex];
    }, [scrollY, height]);

    const throttledToggleFavorite = useThrottle(onToggleFavorite, 1000);

    const updateLiveProgram = useCallback(() => {
        channels.slice(start, end).forEach((channel) => {
            getLiveProgram(channel.uuid);
        });
    }, [channels, start, end, getLiveProgram]);

    useEffect(() => {
        updateLiveProgram();
    }, [updateLiveProgram]);

    const renderListElement = useCallback(
        (channel) => {
            const imageSrc = channel.logo;
            const isActive = activeChannel && activeChannel.uuid === channel.uuid;

            const p = liveProgram?.[channel.id] ? epgV2ProgramToV1(liveProgram?.[channel.id]) : {};
            const isOnError = isTimeslotOnError(channel.id, getLiveUTCTimeSlot());
            const isLoading = !isOnError && !p?.title;

            return (
                <li
                    className={classnames('channel-list__item', isActive && 'is-active')}
                    rel={channel.uuid}
                    onClick={onChannelItemAction}
                    title={p.title}
                >
                    <span
                        className="channel-list__item__favorite"
                        onClick={(e) => {
                            e.stopPropagation();
                            throttledToggleFavorite(channel.uuid, channel.favorite);
                            notifications.toggleFavorite(channel.favorite);
                        }}
                    >
                        <FavoriteButton isActive={channel.favorite} />
                    </span>
                    <span className="channel-list__item__channel-logo">
                        <img
                            loading="lazy"
                            src={imageSrc}
                            alt={channel.displayName}
                            title={channel.displayName}
                        />
                    </span>

                    <div
                        className={classnames(
                            'channel-list__item__current-program',
                            isLoading && 'loading',
                        )}
                    >
                        <h3>{p.title}</h3>
                        <span>
                            {isOnError ? (
                                <a
                                    className="text-link"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        updateLiveProgram();
                                    }}
                                >
                                    Erneut versuchen
                                </a>
                            ) : (
                                p.startTime && epgDetailsLiveDateFormat(p.startTime, p.stopTime)
                            )}
                        </span>
                    </div>
                </li>
            );
        },
        [
            activeChannel,
            liveProgram,
            isTimeslotOnError,
            onChannelItemAction,
            throttledToggleFavorite,
            updateLiveProgram,
            notifications,
        ],
    );

    if (isDisabled) {
        return undefined;
    }

    const overlayClasses = classnames(
        'channel-list__overlay',
        show && 'channel-list__overlay--fade-in',
    );

    return (
        <div
            key="channelList"
            className={overlayClasses}
            onMouseEnter={conditionalCb(onMouseEnter, onMouseEnter)}
            onMouseLeave={conditionalCb(onMouseLeave, onMouseLeave)}
        >
            <div className="channel-list__wrapper">
                <EpgChannelNavigation name="quickZapper" />
                <nav className="channel-list" ref={channelListRef}>
                    <ul>
                        {channels && channels.map(renderListElement)}
                        {isFavoriteMode && (
                            <li
                                className="channel-list__item channel-list__empty-state"
                                onClick={() => {
                                    WebClientGA().trackEvent({
                                        eventName: WebClientGAEvent.QuickZapperEmptyFavoriteAdd,
                                    });
                                    routeTo(Routes.CHANNELCONFIG_PATH);
                                }}
                            >
                                <Button className="button--link button--icon button--medium">
                                    <span className="icon icon--small icon--channel-add-blue" />
                                    <span className="button__label">Favoriten hinzufügen</span>
                                </Button>
                            </li>
                        )}
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default memo(ChannelList, (prevProps, nextProps) => {
    const { show, isDisabled, channels, activeChannel } = prevProps;

    return (
        show === nextProps.show &&
        isDisabled === nextProps.isDisabled &&
        channels === nextProps.channels &&
        activeChannel === nextProps.activeChannel
    );
});
