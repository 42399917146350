import { useCallback, useState } from 'preact/hooks';

export const useThrottle = (cb, ms) => {
    const [throttled, throttle] = useState(false);
    return useCallback(
        (...args) => {
            if (throttled) {
                return;
            }

            throttle(true);
            cb(...args);
            setTimeout(() => {
                throttle(false);
            }, ms);
        },
        [cb, ms, throttled],
    );
};

export default useThrottle;

// TODO: add unit test, first we need to solve hook import problems
