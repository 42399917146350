/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';

const WelcomeScreen = ({ fullName, brandName, onClickAction }) => {
    return (
        <div id="welcome-screen" className="welcome__overlay">
            <div className="welcome__overlay-opacity" />
            <div className="welcome__content">
                <div className="welcome__header">
                    <span className="welcome__header__title">Hallo {fullName},</span> <br />
                    <span className="welcome__header__subtitle">willkommen bei {brandName}!</span>
                </div>
                <div className="welcome__text">
                    Mit {brandName} können Sie auf allen Ihren Geräten Fernsehen wie noch nie
                    genießen.
                </div>
                <button className="button" onClick={onClickAction} type="button">
                    Jetzt fernsehen
                </button>
            </div>
        </div>
    );
};

// WelcomeScreen.propTypes = {
//     fullName: React.PropTypes.string,
//     brandName: React.PropTypes.string,
//     onClickAction: React.PropTypes.func
// }

export default WelcomeScreen;
