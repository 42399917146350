import constants from '../constants';
import { get } from '../request';

/**
 * @param {String} channelId
 * @param {String} programId
 * @return {Object}
 */
// eslint-disable-next-line import/prefer-default-export
export const thumbnails = (channelId, programId) =>
    get(`${constants.IMAGES_BASE_URL}/api/thumbnails/${channelId}/${programId}.jpg`, {
        'X-THUMBNAIL-VERSION': '2',
    });
