/*
 *  Copyright (C) Exaring AG - All Rights Reserved
 *
 *  How the Immer store works:
 *  @See https://github.com/pmndrs/zustand
 *
 *  How splitting the store in slices work:
 *  @See https://github.com/pmndrs/zustand/wiki/Splitting-the-store-into-separate-slices
 *  @See ./Slice.ts: First comment.
 *
 *  @todo Typescript is fucked up in some cases. Fix State Typing!
 *  @See https://github.com/pmndrs/zustand/issues/508
 *  @See https://dev.to/svehla/typescript-object-fromentries-389c
 */

import { create, StateCreator, StoreApi } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { SetSlice, SetState } from './utils/StoreSlice';
import { State as UserSlice } from './UserStore';
import { State as WaiputhekSlice } from './WaiputhekStore';
import { State as EpgSlice } from './EpgStore';
// eslint-disable-next-line import/no-cycle
import { State as NotificationsSlice } from './NotificationsStore';
import { State as RecordingSlice } from './RecordingStore';
import { State as SearchSlice } from './Search/SearchStore';
import { State as UiSlice } from './Ui/UiStore';

//
//  Store Configuration -- If you want to add another slice to the store, just
//                         add it to the Store type and the StoreConfig. From
//                         there, we're able to construct everything else.
//

export type Store = {
    user: UserSlice;
    waiputhek: WaiputhekSlice;
    epg: EpgSlice;
    notifications: NotificationsSlice;
    recording: RecordingSlice;
    search: SearchSlice;
    ui: UiSlice;
};

const StoreConfig: StateCreator<Store> = (set, get /* , api */) => ({
    user: UserSlice(setSlice('user', set), getSlice('user', get)),
    waiputhek: WaiputhekSlice(setSlice('waiputhek', set), getSlice('waiputhek', get)),
    epg: EpgSlice(setSlice('epg', set), getSlice('epg', get)),
    notifications: NotificationsSlice(
        setSlice('notifications', set),
        getSlice('notifications', get),
    ),
    recording: RecordingSlice(setSlice('recording', set), get),
    search: SearchSlice(setSlice('search', set), getSlice('search', get)),
    ui: UiSlice(setSlice('ui', set), getSlice('ui', get)),
});

type SliceNames = keyof Store;

//
//  Helpers
//

/** Set slice state to root store */
const setSlice =
    <T extends SliceNames>(sliceName: T, set: SetState<Store>) =>
    (setSliceState: SetSlice<Store[T]>, actionName: string, replace?: boolean | undefined) =>
        set(
            (state) => {
                if (typeof setSliceState === 'function') {
                    setSliceState(state[sliceName]);
                }
                return state;
            },
            replace,
            actionName,
        );

/** Get slice state from root store */
const getSlice =
    <T extends SliceNames>(sliceName: T, get: StoreApi<Store>['getState']) =>
    (): Store[T] =>
        get()[sliceName];

//
//  Middlewares
//  @See https://github.com/pmndrs/zustand#middleware
//

// Middleware to enable Redux DevTools extension under development mode
const devtoolsMiddleware = (store: StateCreator<Store>) =>
    process.env.NODE_ENV === 'development' ? devtools(store) : store;

//
//  Main -- Construct the Root Store
//

/** The global store as hook, for use in components. */
// We have to ignore TS checking for immer here to get around the
// `Type instantiation is excessively deep and possibly infinite` error.
// See https://github.com/immerjs/immer/issues/839
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const useStore = create<Store>()(immer(devtoolsMiddleware(StoreConfig)));

/** The global store, to be used outside of components. */
export const Store = useStore.getState;

/** The Waiputhek store as hook. */
export const useUserStore = () => useStore((s) => s.user);
export const useWaiputhekStore = () => useStore((s) => s.waiputhek);
export const useEpgStore = () => useStore((s) => s.epg);
export const useRecordingStore = () => useStore((s) => s.recording);
export const useSearchStore = () => useStore((s) => s.search);
export const useUiStore = () => useStore((s) => s.ui);
export const useNotificationsStore = () => useStore((s) => s.notifications);
export const epgStore = () => Store().epg;
export const notificationsStore = () => Store().notifications;
export const userStore = (): UserSlice => Store().user;
export const recordingStore = (): RecordingSlice => Store().recording;
export const searchStore = (): SearchSlice => Store().search;
export const uiStore = (): UiSlice => Store().ui;
