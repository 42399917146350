import { h, Component } from 'preact';

// Dropdown.propTypes = {
//     className: React.PropTypes.string,
//     options: React.PropTypes.object,
//     onChange: React.PropTypes.function,
//     selected: React.PropTypes.number
// };

export default class Dropdown extends Component {
    shouldComponentUpdate(nextProps) {
        const { selected, options } = this.props;
        const isSelected = selected !== nextProps.selected;
        let isOptionChanged = false;

        // make a cheaper test first, whether length changed or the actual prop switched to being undefined
        if (
            (options && !nextProps.options) ||
            (!options && nextProps.options) ||
            (options && nextProps.options && options.length !== nextProps.options.length)
        ) {
            return true;
        }

        if (options && nextProps.options) {
            // TODO check if the usage of immutable could prevent deep attribute comparison
            for (let i = 0; i < nextProps.options.length; i += 1) {
                if (nextProps.options[i].value !== options[i].value) {
                    isOptionChanged = true;
                    break;
                }
            }
        }

        return isOptionChanged || isSelected;
    }

    handleChange = (e) => {
        const { onChange } = this.props;
        onChange(e.target.value, e.target.selectedIndex, e);
    };

    render({ className, selected, options }) {
        return (
            <div className={className}>
                <div className="icon--arrow">&nbsp;</div>
                <select onChange={this.handleChange} value={selected}>
                    {options.map((item) => {
                        return (
                            <option key={item.value} value={item.value}>
                                {item.label}
                            </option>
                        );
                    })}
                </select>
            </div>
        );
    }
}
