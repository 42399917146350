/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { FunctionComponent, FunctionalComponent, h } from 'preact';

import { previewImages, previewImagesEpg2 } from '@exaring/utils/data/program';
import { LayoutType, Program } from '../../ProgramDetailPage/types';
import { styled } from '../../theme';
import { Badge } from '../../Badge/Badge';

const Img = styled('img', {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '372px',
    borderRadius: '$2',
    marginTop: '$4',
    '@sm': {
        marginTop: '0',
    },
});

const LiveBadge = styled(Badge, {
    background: '$debianRed',
    position: 'absolute',
    top: 5,
    left: 5,
});

const StyledSection = styled('section', {
    position: 'relative',
    marginTop: '$$gutterWidth',
    variants: {
        layout: {
            modal: { marginTop: 'unset' },
            epg: {},
            mediathek: {},
            microSite: {},
            player: {},
        },
    },
});

export const ProgramImage: FunctionComponent<{
    program: Program;
    imageComponent?: FunctionalComponent;
    layout?: LayoutType;
}> = ({ program, imageComponent, layout }) => {
    // eslint-disable-next-line no-template-curly-in-string
    const images = program.previewImages?.[0]?.includes('${resolution}')
        ? // eslint-disable-next-line no-template-curly-in-string
          previewImagesEpg2(program.previewImages)
        : previewImages(program) || [];
    const ImageComp = imageComponent || Img;
    const firstImage = images[0] ? <ImageComp alt="" src={images[0]} /> : '';
    const isLive = program.liveShow ? <LiveBadge>Live</LiveBadge> : '';

    return (
        <StyledSection layout={layout}>
            {isLive}
            {firstImage}
        </StyledSection>
    );
};
