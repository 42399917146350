/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */
const MAX_ALLOWED_THROWS = 3;

export const loop = () => {
    let loopId;
    let halt = false;
    let _hooks = [];
    let _onError;
    const _loopInterval = 1000; // ms

    const hooks = () => {
        return [..._hooks];
    };

    const length = () => {
        return _hooks.length;
    };

    const clear = () => {
        _hooks = [];
        return _hooks;
    };

    const stop = () => {
        halt = true;
        return halt;
    };

    const start = () => {
        halt = false;
        return halt;
    };

    const runHooks = (time) => {
        /* eslint max-depth: ["error", 4] */
        _hooks.forEach(async (hook, idx) => {
            try {
                const { cb, ms: hookInterval, hasThrown } = hook;
                const hookDelta = time - hook.lastCall;

                if (hookDelta > hookInterval) {
                    // if interval is over
                    hook.lastCall = time; // eslint-disable-line no-param-reassign
                    try {
                        await cb?.(time); // eslint-disable-line no-unused-expressions
                    } catch (e) {
                        if (hasThrown + 1 === MAX_ALLOWED_THROWS) {
                            _hooks = remove(cb); //	hara-kiri
                            throw new Error(
                                `Recurring hook error detected: To avoid amok scenarios app loop removed hook. origin error: (${e.message})`,
                            );
                        } else {
                            _hooks[idx].hasThrown += 1; // track errors caused by hook
                            throw e;
                        }
                    }
                }
            } catch (e) {
                _onError && _onError(e);
            }
        });
    };

    const hookLoop = () => {
        const time = new Date().getTime();
        if (halt) {
            // halt before loop is kicked off
            return;
        }
        runHooks(time);
    };

    const wrapHook = (hook, ms) => {
        return { cb: hook, ms, lastCall: new Date().getTime(), hasThrown: 0 };
    };

    const add = (hook, ms = _loopInterval) => {
        if (typeof hook === 'function') {
            _hooks.push(wrapHook(hook, ms >= _loopInterval ? ms : _loopInterval));
            return [..._hooks];
        }

        throw new Error('hook must be a function');
    };

    const remove = (hook) => {
        _hooks = _hooks.filter((_hook) => _hook.cb !== hook);

        return [..._hooks];
    };

    const init = (onError) => {
        _onError = onError;

        if (loopId) {
            throw new Error('loop already initialized');
        }

        loopId = setInterval(hookLoop, _loopInterval);
    };

    const deinit = () => {
        clearInterval(loopId);
        stop();
        clear();
    };

    const isRunning = () => {
        return !halt;
    };

    const isInLoop = (hook) => _hooks.some(({ cb }) => cb === hook);

    return {
        init,
        deinit,
        add,
        remove,
        isRunning,
        start,
        stop,
        isInLoop,
        hooks,
        length,
        clear,
    };
};

export default loop;
