import { h } from 'preact';

// type Props = {
//     className?: string,
//     content: string
// };

export default ({ className, content, children }) => {
    return <p className={`text ${className || ''}`}>{content || children}</p>;
};
