import { signal } from '@preact/signals';

// How far from edge of the screen scroll should be triggered
const scrollThreshold = 150;
const maxScrollStep = 30;
const intervalTime = 10;

const scrollEnabled = signal(false);
const scrollIncrement = signal(0);
const scrollIntervalId = signal<NodeJS.Timeout | undefined>(undefined);

const startIntervalScrolling = (direction: 'up' | 'down', distanceFromEdge: number) => {
    const scrollSign = direction === 'up' ? -1 : 1;
    // Calculate scroll speed based on distance from edge
    const scrollSpeed = ((scrollThreshold - distanceFromEdge) / scrollThreshold) * maxScrollStep;
    scrollIncrement.value = scrollSign * scrollSpeed;

    if (scrollIntervalId.value === undefined) {
        scrollIntervalId.value = setInterval(() => {
            window.scrollBy(0, scrollIncrement.value);
        }, intervalTime);
    }
};

const stopIntervalScrolling = () => {
    clearInterval(scrollIntervalId.value);
    scrollIntervalId.value = undefined;
    scrollIncrement.value = 0;
};

const onDragOver = (event: DragEvent) => {
    const mouseY = event.clientY;

    if (mouseY < scrollThreshold) {
        startIntervalScrolling('up', mouseY);
    } else if (mouseY > window.innerHeight - scrollThreshold) {
        startIntervalScrolling('down', window.innerHeight - mouseY);
    } else {
        stopIntervalScrolling();
    }
};

const onDragEnd = () => {
    stopIntervalScrolling();
};

scrollEnabled.subscribe((state) => {
    if (state) {
        document.addEventListener('dragover', onDragOver);
        document.addEventListener('dragend', onDragEnd);
        document.addEventListener('drop', onDragEnd);
    } else {
        document.removeEventListener('dragover', onDragOver);
        document.removeEventListener('dragend', onDragEnd);
        document.removeEventListener('drop', onDragEnd);
        stopIntervalScrolling();
    }
});

export const enableImprovedScroll = (value: boolean) => {
    scrollEnabled.value = value;
};
