import { isObject } from '@exaring/utils';

export const HIGHLIGHT_CATEGORY = 'category';
export const HIGHLIGHT_COLLECTION = 'collection';
export const HIGHLIGHT_VIDEO = 'video';

export type HighlightItem = HighlightCategoryItem | HighlightCollectionItem | HighlightVideoItem;

type HighlightItemType = HighlightItem['type'];

export interface HighlightCategoryItem extends HighlightBaseItem {
    type: typeof HIGHLIGHT_CATEGORY;
    categoryId?: string;
}

interface HighlightCollectionItem extends HighlightBaseItem {
    type: typeof HIGHLIGHT_COLLECTION;
}

export interface HighlightVideoItem extends HighlightBaseItem {
    type: typeof HIGHLIGHT_VIDEO;
    categoryId?: string;
    contentId?: string;
    duration?: number;
}

interface LinkRessource {
    href: string;
    rel: string;
    type: string;
}

interface HighlightBaseItem {
    ageRestriction?: number;
    channel: string;
    channelDisplay?: string;
    clickUrl?: string;
    description?: string;
    genre?: string;
    genreDisplayName?: string;
    highlight?: boolean;
    highlightFrom?: string;
    highlightUntil?: string;
    imprint?: string;
    links?: Array<LinkRessource>;
    locked?: boolean;
    nativeClickUrl?: string;
    parentalGuidance?: string;
    pinRequired?: boolean;
    sourceUrl?: string;
    title?: string;
}

export const isCategory = (value: HighlightItem): value is HighlightCategoryItem =>
    value.type === HIGHLIGHT_CATEGORY;

export const isVideo = (value: HighlightItem): value is HighlightVideoItem =>
    value.type === HIGHLIGHT_VIDEO;

export const matcher = <R>(type: HighlightItemType, options: Record<HighlightItemType, () => R>) =>
    options[type]();

const isValidJson = (value: any): value is HighlightItem => {
    if (isObject(value)) {
        const { type } = value;
        return (
            type === HIGHLIGHT_CATEGORY || type === HIGHLIGHT_COLLECTION || type === HIGHLIGHT_VIDEO
        );
    }

    return false;
};

export const decode = (value: unknown): HighlightItem | undefined =>
    isValidJson(value) ? value : undefined;
