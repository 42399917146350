/*
 *  Copyright (C) Exaring AG - All Rights Reserved
 */
import { FunctionComponent, h } from 'preact';
import { Button } from '@exaring/ui/components-styled/Button';
import { GridContainer } from '@exaring/ui/components-styled/Grid';
import { CSS, styled } from '@exaring/ui/components-styled/theme';
import { FilterOption } from './filter';

const StyledFilterSelect = styled(GridContainer, {
    columnGap: '$$gutterWidth',
    display: 'none',
    flexWrap: 'nowrap',
    '@lg': { display: 'flex' },
});

const FilterButton = styled(Button, {
    fontSize: '$2',
});

export const FilterSelect: FunctionComponent<{
    activeFilter: FilterOption;
    onChangeFilter: (value: FilterOption) => void;
    css?: CSS;
    isFilterDisabledMap?: Record<FilterOption, boolean>;
}> = ({ activeFilter, onChangeFilter, css, isFilterDisabledMap }) => {
    return (
        <StyledFilterSelect css={css}>
            <FilterButton
                epg
                onClick={() => onChangeFilter(FilterOption.Scheduled)}
                active={activeFilter === FilterOption.Scheduled}
                disabled={isFilterDisabledMap?.[FilterOption.Scheduled]}
            >
                Geplant
            </FilterButton>

            <FilterButton
                epg
                onClick={() => onChangeFilter(FilterOption.Seen)}
                active={activeFilter === FilterOption.Seen}
                disabled={isFilterDisabledMap?.[FilterOption.Seen]}
            >
                Bereits gesehen
            </FilterButton>

            <FilterButton
                epg
                onClick={() => onChangeFilter(FilterOption.New)}
                active={activeFilter === FilterOption.New}
                disabled={isFilterDisabledMap?.[FilterOption.New]}
            >
                Nicht gesehen
            </FilterButton>

            <FilterButton
                epg
                onClick={() => onChangeFilter(FilterOption.Continue)}
                active={activeFilter === FilterOption.Continue}
                disabled={isFilterDisabledMap?.[FilterOption.Continue]}
            >
                Weiterschauen
            </FilterButton>

            <FilterButton
                epg
                onClick={() => onChangeFilter(FilterOption.All)}
                active={activeFilter === FilterOption.All}
                disabled={isFilterDisabledMap?.[FilterOption.All]}
            >
                Verfügbar
            </FilterButton>
        </StyledFilterSelect>
    );
};
