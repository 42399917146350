/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, Fragment } from 'preact';
import { events, classnames, GA } from '@exaring/utils';
import WaiputhekIcon from '@exaring/assets/icons/highlights.svg';
import WaiputhekO2Icon from '@exaring/assets/icons/highlights-o2.svg';
import AccountIcon from '@exaring/assets/icons/account-logged-in.svg';
import { styled } from '../../components-styled/theme';
import { Constants } from '../../constants-env';
import constants from '../../constants';

const NAVIGATION_BASE_CLASS = 'header--logged-in';
const NOP = () => {};

export const NavigationItem = ({
    action = NOP,
    className,
    id,
    key,
    tooltip,
    children,
    name = 'main',
    active,
    small,
    hidden,
    showOnMobile,
}) => {
    return (
        <li
            data-testid={id}
            className={classnames(
                name && `${NAVIGATION_BASE_CLASS}__${name}-item`,
                small && `${NAVIGATION_BASE_CLASS}__${name}-item--small`,
                hidden && 'is-hidden',
                showOnMobile && 'on-mobile',
                active && 'is-active',
                className,
            )}
            key={key}
            onClick={action}
            title={tooltip}
        >
            {children}
        </li>
    );
};

export const Label = ({ children }) => {
    return <span className={`${NAVIGATION_BASE_CLASS}__label`}>{children}</span>;
};

export const Icon = ({ name, active, className }) => {
    const _name = `icon--${name}`;
    return <span className={classnames(`icon ${active ? `${_name}-active` : _name}`, className)} />;
};

export const NavigationItemIconLabel = ({
    id,
    icon,
    name,
    className,
    tooltip,
    action,
    label,
    active,
    small,
    hidden,
    showOnMobile,
    children,
}) => {
    // as we want to move to the modern way of rendering icons as components this condition was added
    const Inner = ({ isActive }) => {
        const iconToRender =
            typeof icon === 'string' ? <Icon name={icon} active={isActive} /> : icon;
        return (
            <>
                {icon && iconToRender}
                {label && <Label>{label}</Label>}
            </>
        );
    };

    let child;

    if (children) {
        if (typeof children === 'function') {
            child = children({ children: Inner });
        } else {
            child = Inner({ isActive: active, children });
        }
    } else {
        child = <Inner isActive={active} />;
    }

    return (
        <NavigationItem
            action={action}
            className={className}
            small={small}
            showOnMobile={showOnMobile}
            hidden={hidden}
            name={name}
            key={id}
            tooltip={tooltip}
            active={active}
            id={id}
        >
            {child}
        </NavigationItem>
    );
};

/* eslint-disable react/jsx-props-no-spreading */

export const Television = ({
    id = 'television',
    icon = 'television',
    active = false,
    tooltip = 'Live schauen',
    label = 'Fernsehen',
    showOnMobile = true,
    action = () => {
        window.location.href = constants.WEB_CLIENT_BASE_URL;
    },
    ...rest
}) => {
    return (
        <NavigationItemIconLabel
            {...{ id, icon, action, active, tooltip, label, showOnMobile, ...rest }}
        />
    );
};

export const Epg = ({
    id = 'epg',
    icon = 'epg',
    active = false,
    tooltip = 'Programm',
    label = 'Programm',
    showOnMobile = false,
    action = NOP,
    ...rest
}) => {
    return (
        <NavigationItemIconLabel
            {...{ id, icon, action, active, tooltip, label, showOnMobile, ...rest }}
        />
    );
};

export const Waiputhek = ({
    id = 'waiputhek',
    icon = Constants.TENANT === 'o2' ? (
        <WaiputhekO2Icon />
    ) : (
        <WaiputhekIcon width="32" height="32" />
    ),
    active = false,
    tooltip = 'waiputhek',
    label = 'waiputhek',
    showOnMobile = false,
    action = NOP,
    ...rest
}) => {
    return (
        <NavigationItemIconLabel
            {...{ id, icon, action, active, tooltip, label, showOnMobile, ...rest }}
        />
    );
};

export const Recordings = ({
    id = 'recordings',
    icon = 'recordings',
    active = false,
    tooltip = 'Aufnahmen',
    label = 'Aufnahmen',
    showOnMobile = false,
    action = NOP,
    ...rest
}) => {
    return (
        <NavigationItemIconLabel
            {...{ id, icon, action, active, tooltip, label, showOnMobile, ...rest }}
        />
    );
};

export const Search = ({
    id = 'search',
    icon = 'search',
    active = false,
    tooltip = 'Sender oder Sendungen suchen (Drücke S auf deiner Tastatur)',
    showOnMobile = false,
    small = true,
    action = NOP,
    ...rest
}) => {
    return (
        <NavigationItemIconLabel
            {...{ id, icon, action, active, tooltip, showOnMobile, small, ...rest }}
        />
    );
};

export const Close = ({
    id = 'search',
    icon = 'close-white-small',
    active = false,
    tooltip = 'Schließen',
    showOnMobile = false,
    small = true,
    action = NOP,
    ...rest
}) => {
    return (
        <NavigationItemIconLabel
            {...{ id, icon, action, active, tooltip, showOnMobile, small, ...rest }}
        />
    );
};

export const Help = ({
    id = 'help',
    icon = 'help',
    active = false,
    tooltip = 'Hilfe',
    showOnMobile = true,
    small = true,
    action = (e) => {
        GA.track({ event: 'help', event_description: 'open_help', screen_name: 'help' });
        events.pauseEvent(e);
        window.open(constants.HELP_URL, '_blank', 'noopener');
    },
    ...rest
}) => {
    return (
        <NavigationItemIconLabel
            {...{ id, icon, action, active, tooltip, showOnMobile, small, ...rest }}
        />
    );
};

const StyledAccountIcon = styled(AccountIcon, {
    '&:active': {
        fill: '#36DEA7',
    },
});

export const Account = ({
    id = 'account',
    icon = <StyledAccountIcon active={window.location.href === constants.CSC_HOME_URL} />,
    active = false,
    tooltip = 'Mein Konto',
    small = true,
    showOnMobile = true,
    action = (e) => {
        GA.track({ event: 'menu', event_description: 'account' });
        events.pauseEvent(e);
        window.location.href = constants.CSC_HOME_URL;
    },
    ...rest
}) => {
    return (
        <NavigationItemIconLabel
            {...{ id, icon, action, active, tooltip, small, showOnMobile, ...rest }}
        />
    );
};

export const Logout = ({
    id = 'logout',
    icon = 'logout',
    active = false,
    tooltip = 'Logout',
    small = true,
    showOnMobile = true,
    action = NOP,
    ...rest
}) => {
    return (
        <NavigationItemIconLabel
            {...{ id, icon, action, active, tooltip, small, showOnMobile, ...rest }}
        />
    );
};

export const SearchInput = ({
    id = 'search',
    icon = 'search',
    active = true,
    tooltip = 'Sender oder Sendungen suchen',
    small = false,
    showOnMobile = false,
    action = NOP,
    searchQuery = '',
    onInputUpdate = NOP,
    inputRef,
    iconClassName,
    ...rest
}) => {
    return (
        <NavigationItem
            {...{ id, icon, iconClassName, action, active, tooltip, small, showOnMobile, ...rest }}
        >
            {icon && <Icon name={icon} className={iconClassName} active={active} />}
            <input
                ref={inputRef}
                type="text"
                placeholder={tooltip}
                className="header--logged-in__main-item__label__value"
                value={searchQuery}
                onInput={onInputUpdate}
            />
        </NavigationItem>
    );
};
/** eslint-enable react/jsx-props-no-spreading */
