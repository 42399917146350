/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import * as Sentry from '@sentry/browser';
import { now, diffInSeconds } from '../date';

const SECONDS_TO_DISCARD_DUPLICATE_ERRORS = 60;

let history = [];

export const getHistory = () => history;

export const historyFind = (exceptionDescriptor) =>
    history.findIndex(
        ({ type, value }) =>
            exceptionDescriptor.type === type && exceptionDescriptor.value === value,
    ) >= 0;

export const historyAdd = (exceptionDescriptor) => {
    if (historyFind(exceptionDescriptor)) {
        return false;
    }

    const { type, value } = exceptionDescriptor;
    return !!history.push({ ts: now(), type, value });
};

export const removeOldHistoryEntries = () => {
    history = history.filter(({ ts }) => diffInSeconds(ts) < SECONDS_TO_DISCARD_DUPLICATE_ERRORS);
};

const addBreadcrumb = ({ message, category, level, data }) => {
    Sentry.addBreadcrumb({ message, category, level, data });
};
export const logAddBreadcrumb = addBreadcrumb;

export const addBreadcrumbMessage = (message, category, level, data) => {
    addBreadcrumb({ message, category, level, data });
};

export const addLoopBreadcrumb = ({ type, value }) => {
    addBreadcrumb({
        message: 'exception loop protection',
        category: 'logging',
        level: 'warning',
        data: {
            duplicateError: `${type} (${value})`,
        },
    });
};

const loopProtection = (event = {}) => {
    if (event.exception && event.exception.values && event.exception.values.length) {
        removeOldHistoryEntries(history);

        const exceptionDescriptor = event.exception.values[0];

        if (!historyAdd(exceptionDescriptor)) {
            // already found in history, let's add breadcrumb and discard error
            addLoopBreadcrumb(exceptionDescriptor);
            return undefined;
        }
    }

    return event;
};

export const logError = (e, ...args) => {
    if (process.env.NODE_ENV === 'development') {
        console.error(e, args);
    }

    return Sentry.captureException(e, ...args);
};

export const logErrorWithTag = (tag, e, ...args) => {
    Sentry.withScope((scope) => {
        scope.setTag(tag.name, JSON.stringify(tag.value));
        logError(e, ...args);
    });
};

export const logMessage = (m, ...args) => Sentry.captureMessage(m, ...args);

export const logErrorWithDescription = (error, description) => {
    try {
        if (description && error.message) {
            error.message = `${error.message} (${description})`;
        }

        return logError(error);
    } catch {
        const errorMessage = `${error ? error.message || error : 'unknown'} (${description})`;

        const newError =
            error && error.constructor
                ? new error.constructor(errorMessage)
                : new Error(errorMessage);

        if (error?.stack) {
            newError.stack = error.stack;
        }

        return logError(newError);
    }
};

const setUser = (userHandle) => {
    if (!userHandle) {
        return;
    }

    Sentry.configureScope((scope) => {
        scope.setUser({ id: userHandle });
    });
};
export const logSetUser = setUser;

export const initErrorLogging = ({
    url,
    env,
    release,
    userHandle,
    onBeforeSend = (value) => value,
    enableLoopProtection = true,
    tenant,
}) => {
    if (!url) {
        return;
    }

    let environment = env || process.env.NODE_ENV || 'undefined';

    if (process.env.NODE_ENV === 'development') {
        environment = 'local';
    }

    try {
        Sentry.init({
            dsn: url,
            environment,
            release,
            beforeSend: (eventOrig, hint) => {
                const event = enableLoopProtection ? loopProtection(eventOrig) : eventOrig;

                if (!event) {
                    return undefined;
                }

                return onBeforeSend(event, hint);
            },
            ignoreErrors: [
                // happens when zapping quickly through channels, can be ignored safely. More Info: https://goo.gl/LdLk22
                'AbortError: The play() request was interrupted', // Chrome, Edge, Opera, ...
                'AbortError: The fetching process for the media resource was aborted', // Firefox
                'AbortError: The operation was aborted.', // Safari

                // Prevent Non-Errors from showing up in Sentry
                'Non-Error exception captured',
            ],
        });

        if (tenant) {
            Sentry.setTag('tenant', tenant);
        }

        setUser(userHandle);
    } catch (e) {
        console.error('Sentry could not be initialized');
    }
};
