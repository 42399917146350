/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';

import FavoriteActiveIcon from '@exaring/assets/icons/favorite-active.svg';
import FavoriteInactiveIcon from '@exaring/assets/icons/favorite-inactive.svg';
import ChannelLogo from '../channel-logo';
import Icon from '../../elements/icon';

export default ({
    id,
    iconUrl,
    sourceSet,
    title,
    faved,
    hidden,
    onToggleFavoriteAction,
    onRemoveAction,
}) => {
    if (hidden) {
        return null;
    }

    const FavoriteIcon = faved ? FavoriteActiveIcon : FavoriteInactiveIcon;

    return (
        <div className="channel-list__item">
            {onToggleFavoriteAction && (
                <div className="channel-list__item__action-bar-left">
                    <FavoriteIcon
                        data-testid={`${faved ? 'unfavorite' : 'favorite'}-${id}`}
                        className={`icon-svg icon--favorite${faved ? '' : '-inactive'}`}
                        onClick={() => onToggleFavoriteAction(id)}
                    />
                </div>
            )}
            <div className="channel-list__item__content">
                <div className="channel-list__item__content__image">
                    <ChannelLogo src={iconUrl} sourceSet={sourceSet} />
                </div>
                <h2 className="channel-list__item__content__title">{title}</h2>
            </div>
            <div className="channel-list__item__action-bar-right">
                <Icon
                    dataTestId={`hide-${id}`}
                    className="icon--channel-remove"
                    onClick={() => onRemoveAction(id)}
                />
                <Icon dataTestId={`drag-${id}`} className="icon--channel-drag" />
            </div>
        </div>
    );
};

/*
DraggableChannelListItem.propTypes = {
    id: React.PropTypes.string,
    title: React.PropTypes.string,
    iconUrl: React.PropTypes.string,
    hidden: React.PropTypes.bool,
    onToggleFavoriteAction: React.PropTypes.func,
    onRemoveAction: React.PropTypes.func
} */
