import { useEffect } from 'preact/hooks';

export const useClickAway = (ref: any, onClickAwayHandler: () => void) => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent | TouchEvent) => {
            if (ref.current && !ref.current?.contains(event.target)) {
                onClickAwayHandler();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, onClickAwayHandler]);
};
