import { clamp, CTime } from '@exaring/utils';
import { MutableRef } from 'preact/hooks';
import { CHANNEL_LIST_WIDTH_IN_PX, DAY_RANGE_IN_PX, TIME_SLOT_SIZE } from './constants';
import { Channel } from '../../types/api/epg/StationConfig';

export interface VisibleChannelTimeslot {
    channelId: string;
    timeslot: string;
}

export const getTimeslotsForViewport = (
    scrollX: number,
    width: number,
    startTime: CTime,
    stopTime: CTime,
    slotSize: number,
    dayRangeInPx: number,
): string[] => {
    const date = new Date(startTime.time()); // reference time
    const clampedWidth = clamp(width, 0, dayRangeInPx); // overall epg width
    const diffInSeconds = startTime.diff(stopTime); // difference of time range in seconds
    const diffInHours = Math.floor(diffInSeconds / 60 / 60);
    const hourInPx = dayRangeInPx / diffInHours;
    const minHour = date.getUTCHours(); // defines the actual start time in utc time
    const scrollXOffset = clamp(
        // this is not the real scroll offset but the corrected scroll position -> time transformation
        scrollX + minHour * hourInPx,
        0,
        Math.max(dayRangeInPx - width, clampedWidth), // underflow protection (ex. 1024 - 1024 = 0 = 1024)
    );

    const startHour = Math.floor(scrollXOffset / hourInPx); // lower bounding correction
    const endHour = Math.ceil((scrollXOffset + clampedWidth) / hourInPx); // upper bounding correction
    // start and end timeslots are NOT real hour values, its just a hour time scale value (0, 1, 2, 3, ..., 24, 25, 26, ...)
    const startTimeslot = startHour - (startHour % slotSize); // clamp to timeslot size
    const endTimeslot = endHour - (endHour % slotSize) + slotSize; // clamp to timeslot size
    const timeslotLength = Math.ceil((endTimeslot - startTimeslot) / slotSize); // timeslots sum

    // creating timeslots by setting the actual UTC hour relative to the start time
    return Array.from({ length: timeslotLength }, (_, i) => {
        const timeslotDate = new Date(startTime.time()); // be aware js dates are mutable
        timeslotDate.setUTCHours(startTimeslot + i * slotSize, 0, 0, 0); // utc magic timeslot shifting skrrr (setUTCHours allows setting overflowing numbers and is not limited by 24 hour values)
        return timeslotDate.toISOString();
    });
};

const getVisibleChannelTimeslots = (
    timeslots: string[],
    channels: string[],
): VisibleChannelTimeslot[] => {
    const gridItems: VisibleChannelTimeslot[] = [];
    timeslots.forEach((timeslot: string) => {
        channels.forEach((channel) => {
            gridItems.push({ channelId: channel, timeslot });
        });
    });

    return gridItems;
};

export const getEpgViewport = (
    ref: MutableRef<HTMLDivElement>,
    startDate: CTime,
    endDate: CTime,
    scrollX: number,
    scrollY: number,
    channels: Channel[],
    itemHeight: number,
): VisibleChannelTimeslot[] => {
    const epgViewHeight = ref?.current?.clientHeight || 0;
    const epgViewWidth = ref?.current?.clientWidth || 0;
    const startIndex = Math.floor(scrollY / itemHeight);
    const endIndex = Math.ceil(epgViewHeight / itemHeight);

    const channelCount = channels.length;
    const channelList = channels
        .slice(clamp(startIndex, 0, channelCount), clamp(startIndex + endIndex, 0, channelCount))
        .map((channel) => channel.id);

    const timeslots = getTimeslotsForViewport(
        scrollX,
        epgViewWidth,
        startDate,
        endDate,
        TIME_SLOT_SIZE,
        DAY_RANGE_IN_PX - CHANNEL_LIST_WIDTH_IN_PX,
    );

    const visibleChannelTimeslots = getVisibleChannelTimeslots(timeslots, channelList);

    return visibleChannelTimeslots;
};
