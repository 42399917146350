const digestMessage = async (message) => {
    // inspired by: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
    const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
    const base64 = btoa(String.fromCharCode.apply(undefined, new Uint8Array(hashBuffer))); // convert buffer to base64 string
    return base64;
};

// eslint-disable-next-line import/prefer-default-export
export const codeChallenge = async (codeVerifier) => {
    const base64 = await digestMessage(codeVerifier);

    return base64.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
};

/* Insecure fast hash just for simple things like an object lookup or
 * the key param in react components. This way you can use any object or
 * any data to create a hash from it and use it as uniq key in an react
 * component.
 *
 * From:
 * https://gist.github.com/iperelivskiy/4110988#gistcomment-2697447
 */
export const insecureHash = (value = '' /* :any */) /* :string */ => {
    const s = JSON.stringify(value);
    const magic = 2654435761; // knuth's multiplicative prime hash number
    let h = 0xdeadbeef;
    for (let i = 0; i < s.length; i += 1) {
        // eslint-disable-next-line no-bitwise
        h = Math.imul(h ^ s.charCodeAt(i), magic);
    }
    // eslint-disable-next-line no-bitwise
    return String((h ^ (h >>> 16)) >>> 0);
};
