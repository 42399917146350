import { deviceCapabilities, playoutUrl, playoutUrlTvfuse } from '../requests/stream-url-provider';
import { requestHookChain, requestHookChainV2 } from '../networking.v2';

const api = {
    deviceCapabilities: requestHookChain(deviceCapabilities),
    playoutUrl: requestHookChain(playoutUrl),
    playoutUrlV2: requestHookChainV2(playoutUrl),
    playoutUrlTvfuse: requestHookChain(playoutUrlTvfuse),
};

export default api;
