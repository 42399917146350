import { clamp } from '@exaring/utils';

type PlayoutType = 'Live' | 'NewTv' | 'Recording' | 'Vod' | 'TvFuse';

export const getProgramProgress = ({
    type,
    startTime,
    stopTime,
}: {
    type: PlayoutType;
    startTime: string | number | Date;
    stopTime: string | number | Date;
    offsetInSec?: number;
}): {
    progress: number;
    live?: number;
} => {
    const startDate = new Date(startTime);
    const endDate = new Date(stopTime);
    const now = new Date();

    const totalDuration = (endDate as any) - (startDate as any);
    const timeElapsed = (now as any) - (startDate as any);

    // if the program is running we want to return a meaningful progress value
    if (now >= startDate && now <= endDate) {
        // for live tv we return a percentage progress
        if (type === 'Live') {
            return { progress: clamp(Math.floor((timeElapsed / totalDuration) * 100), 0, 100) };
        }

        // for all other cases we always start at zero progress
        return { progress: 0 };
    }

    // -1 is important here as it is an indicator for components to possibly hide themselves
    return { progress: -1 };
};
