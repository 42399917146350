import { h, Fragment, FunctionComponent, cloneElement, VNode } from 'preact';
import { useRef, useState } from 'preact/hooks';
import FavoriteActiveIcon from '@exaring/assets/icons/info.svg';

import { useClickAway } from '../../hooks/useClickAway';
import { Tooltip } from '../Tooltip';
import { styled } from '../theme';

const StyledMultiLayerMenu = styled('div', {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const StyledContainer = styled('div', {
    position: 'absolute',
    bottom: '50px',

    borderRadius: '4px',
    padding: '0 2px',
    boxShadow: '0 0 10px 0 rgba(9, 7, 20, 0.9)',
    border: 'solid 1px $charcoalGrey',
    backgroundColor: '$nearBlack',
    zIndex: 20,
});

const StyledMenuItem = styled('div', {
    position: 'relative',
    width: '240px',
    padding: '8px 34px 8px 10px',
    fontSize: '14px',
    borderRadius: '3px',
    lineHeight: '20px',
    margin: '2px 0',

    '&:hover': {
        background: '$charcoalGrey',
        cursor: 'pointer',
    },

    '&:last-child': {
        marginBottom: '8px',
    },

    variants: {
        selected: {
            true: {
                '&:after': {
                    // packages/assets/icons/check.svg
                    content: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath d='M21.896 10.504a1.153 1.153 0 0 1 1.25 1.043c.028.293-.055.596-.258.844l-7.5 9a1.157 1.157 0 0 1-1.706.077l-4.5-4.5a1.153 1.153 0 0 1 0-1.636 1.153 1.153 0 0 1 1.636 0l3.604 3.605 6.692-8.03c.205-.242.49-.378.782-.403z' fill='%232789fc'%3E%3C/path%3E%3C/svg%3E%0A")`,
                    width: '32px',
                    height: '32px',
                    position: 'absolute',
                    top: '50%',
                    right: '2px',
                    transform: 'translate(0, -51%)',
                },
            },
        },
        layout: {
            submenu: {
                '&:after': {
                    // packages/assets/icons/right.svg
                    content: `url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.998 16.069a1.184 1.184 0 0 1-.24.65l-.095.11-5.699 5.828a1.13 1.13 0 0 1-1.517.096l-.108-.098-.097-.112a1.192 1.192 0 0 1 0-1.44l.095-.11L17.22 16l-4.884-4.993a1.191 1.191 0 0 1-.095-1.55l.097-.112c.45-.46 1.176-.46 1.625-.002l5.7 5.827a1.181 1.181 0 0 1 .238.356l.048.131c.04.134.056.273.048.412z' fill-rule='nonzero' fill='%23fff'%3E%3C/path%3E%3C/svg%3E%0A")`,
                    width: '32px',
                    height: '32px',
                    position: 'absolute',
                    top: '50%',
                    right: '34px',
                    transform: 'translate(100%, -50%)',
                },
            },
        },
    },
});

const StyledInfoItem = styled('div', {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    columnGap: '6px',
    lineHeight: 1.4,
    height: '40px',
    width: '240px',
    padding: '8px',
    fontSize: '10px',
    fontWeight: 600,
    borderTop: 'solid 1px $charcoalGrey',
    marginTop: '8px',
});

const StyledMenuTitle = styled(StyledMenuItem, {
    borderTop: 'solid 1px $charcoalGrey',
    borderBottom: 'solid 1px $charcoalGrey',
    borderRadius: 0,
    fontSize: '14px',
    fontWeight: 600,
    margin: '8px 0',
    padding: '10px 10px',
    lineHeight: '19px',

    '&:first-child': {
        borderTopWidth: '0px',
        marginTop: '0px',
    },

    '&:hover': {
        background: 'unset',
        cursor: 'auto',
    },

    variants: {
        showBack: {
            true: {
                paddingLeft: '32px',

                '&:hover': {
                    cursor: 'pointer',
                },

                '&:before': {
                    // packages/assets/icons/left.svg
                    content: `url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.002 16.069c.013.231.093.459.24.65l.095.11 5.699 5.828a1.13 1.13 0 0 0 1.517.096l.108-.098.097-.112a1.192 1.192 0 0 0 0-1.44l-.095-.11L14.78 16l4.884-4.993a1.191 1.191 0 0 0 .095-1.55l-.097-.112a1.131 1.131 0 0 0-1.625-.002l-5.7 5.827a1.181 1.181 0 0 0-.238.356l-.048.131c-.04.134-.056.273-.048.412z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E%0A")`,
                    width: '32px',
                    height: '32px',
                    position: 'absolute',
                    top: '50%',
                    left: '0',
                    border: 'none',
                    transform: 'translate(0px, -50%)',
                },
            },
        },
    },
});

const StyledDisabled = styled('div', {
    opacity: 0.3,
});

export interface MenuItem {
    label: string;
    onClick?: () => void;
    selected?: boolean;
    subMenu?: Menu[];
}

export interface InfoItem {
    label: string;
    infoText: string;
}

export interface Menu {
    title: string;
    items: MenuItem[];
}

const menuIsNotEmpty = (menu: Menu[]) =>
    !menu?.reduce((acc, subMenu) => acc || subMenu.items.length > 0, false);

export const MultiLayerMenu: FunctionComponent<{
    button: VNode;
    menu: Menu[];
    info: InfoItem;
}> = ({ button, menu, info }) => {
    const [visible, setVisible] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState<Menu[][]>([menu]);
    const ref = useRef<HTMLDivElement>(null);
    useClickAway(ref, () => setVisible(false));

    const btn = cloneElement(button, {
        onClick: () => {
            setSelectedMenu([menu]);
            setVisible((state) => !state);
        },
    });

    const menuTitleClickHandler = () =>
        selectedMenu.length > 1 && setSelectedMenu((oldState) => oldState.slice(0, -1));

    const subMenuClickHandler =
        ({ subMenu }: MenuItem) =>
        () =>
            subMenu && setSelectedMenu((oldState) => [...oldState, subMenu]);

    const menuItemClickHandler = (onClick: any) => (e: any) => {
        onClick(e);
        setVisible(false);
    };

    const renderItem = (item: MenuItem) => {
        if (item.subMenu && menuIsNotEmpty(item.subMenu)) {
            return <></>;
        }

        const onClickHandler = item.subMenu
            ? subMenuClickHandler(item)
            : menuItemClickHandler(item.onClick);

        return (
            <StyledMenuItem
                layout={item.subMenu ? 'submenu' : undefined}
                onClick={onClickHandler}
                selected={!!item.selected}
            >
                {item.label}
            </StyledMenuItem>
        );
    };

    return (
        <StyledMultiLayerMenu ref={ref}>
            {btn}
            {visible && (
                <StyledContainer>
                    {selectedMenu[selectedMenu.length - 1]?.map((it) => (
                        <>
                            <StyledMenuTitle
                                showBack={selectedMenu.length > 1}
                                onClick={menuTitleClickHandler}
                            >
                                {it.title}
                            </StyledMenuTitle>
                            {it.items.map(renderItem)}
                        </>
                    ))}
                    {info && (
                        <StyledInfoItem>
                            <Tooltip text={info.infoText}>
                                <StyledDisabled>
                                    <FavoriteActiveIcon width={22} height={22} />
                                </StyledDisabled>
                            </Tooltip>
                            <StyledDisabled>{info.label}</StyledDisabled>
                        </StyledInfoItem>
                    )}
                </StyledContainer>
            )}
        </StyledMultiLayerMenu>
    );
};
