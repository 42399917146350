/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';
import { classnames, epgOverviewDateFormat } from '@exaring/utils';
import { previewImages } from '@exaring/utils/data/program';

const programRecommendation = (program, recordingButtonElement, onRecoItemClick) => {
    const images = previewImages(program) || [];
    const firstImage = images[0] ? <img alt="" src={images[0]} /> : '';

    const onClick = (e) => {
        if (onRecoItemClick) {
            if (!e.defaultPrevented) {
                onRecoItemClick(program.channel, program.id);
            }
        }
    };

    return (
        <div className="epg-detail__program-recommendations__list">
            <div className="epg-detail__program-recommendations__list__item" onClick={onClick}>
                <div className="epg-detail__program-recommendations__list__item__thumb">
                    <div
                        className={classnames(
                            'epg-detail__program-recommendations__list__item__thumb__image',
                            !firstImage
                                ? 'epg-detail__program-recommendations__list__item__thumb__image--empty'
                                : '',
                        )}
                    >
                        {firstImage}
                    </div>
                    {program && !program.recordingForbidden && recordingButtonElement}
                </div>
                <div className="epg-detail__program-recommendations__list__item__content">
                    <span
                        className="epg-detail__program-recommendations__list__item__content__header"
                        title={program.title}
                    >
                        {program.title}
                    </span>
                    <span className="epg-detail__program-recommendations__list__item__content__time">
                        {epgOverviewDateFormat(program.startTime, program.duration)}
                    </span>
                    <span className="epg-detail__program-recommendations__list__item__content__more">
                        {program.genreDisplayName} ⋅ {program.channelDisplay}
                    </span>
                </div>
            </div>
        </div>
    );
};

// TODO remove recordingButtonElement from props, there is only one usage of this component in `EpgDetail` in web-client
export default ({ program, recordingButtonElement, onRecoItemClick }) => {
    return (
        <section className="epg-detail__program-recommendations">
            <h3 className="epg-detail__program-recommendations__header">Im Anschluss:</h3>
            {programRecommendation(program, recordingButtonElement, onRecoItemClick)}
        </section>
    );
};

/*
UpcomingProgram.propTypes = {
    program: React.PropTypes.object,
    recordingButtonElement: React.PropTypes.element,
    onRecoItemClick: React.PropTypes.func
} */
