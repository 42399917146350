/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

/**
 * Helper to persist a value in the sessionStoage. Automatically converts value to JSON.
 *
 * @param {string} key storage key
 * @param {any} value value to store
 */
export const sessionStorageSet = (key, value) => sessionStorage.setItem(key, JSON.stringify(value));

/**
 * Helper to fetch a value from the sessionStoage. Automatically converts JSON back to value.
 *
 * @param {string} key storage key
 * @returns {any}
 */
export const sessionStorageGet = (key) => JSON.parse(sessionStorage.getItem(key));

/**
 * Helper to remove a value from the sessionStoage.
 *
 * @param {string} key storage key
 */
export const sessionStorageRemove = (key) => sessionStorage.removeItem(key);
