/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { FunctionComponent, h } from 'preact';
import { styled } from '../../theme';
import { Typography } from '../../Typography';

const Root = styled(Typography, {
    display: 'flex',
    lineHeight: 1.57,
    color: '$bluegray',
});

const Label = styled('span', {
    $$space: '7px',
    flex: '1 0 50%',
    variants: {
        align: {
            left: {
                paddingRight: '$$space',
                textAlign: 'left',
            },
            right: {
                paddingLeft: '$$space',
                textAlign: 'right',
            },
        },
    },
});

export const InfoItem: FunctionComponent<{
    label: string;
    value?: string | null;
    unit?: string;
}> = ({ label, value, unit }) => {
    return (
        <Root variant="body2" tagName="div" nogutter>
            <Label align="left">{label}</Label>
            <Label align="left">
                {value}
                {unit ? ` ${unit}` : ''}
            </Label>
        </Root>
    );
};
