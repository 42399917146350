import { date, diffInMinutes } from '@exaring/utils';
import {
    CastMember,
    ProgramDetails as ProgramDetailsType,
} from '@exaring/networking/types/ProgramDetails';
import { Program } from './types';

export const epg2CastMembersToActors = (crewMembers: CastMember[]) =>
    crewMembers.map((member) => ({
        name: member.name,
        part: member.role,
    }));

export const epg2ProgramDetailsToProgramDetailPage = (
    epg2ProgramDetails: ProgramDetailsType,
    channelDisplay?: string,
): Program => {
    const {
        contentMeta: { mainGenre, liveShow },
        textContent: { background, title, descShort, descLong },
        restrictions: { recordingForbidden, pauseForbidden },
        production: { year, castMembers, countries = [] },
        series,
        newTvMeta,
        id,
        imageUrls,
        startTime,
        stopTime,
        stationId,
    } = epg2ProgramDetails;
    let parentalGuidance = '';

    if (epg2ProgramDetails.ageRating) {
        parentalGuidance = epg2ProgramDetails.ageRating.parentalGuidance ?? '';
    }

    const { episodeTitleOriginal, episodeTitle, seasonNumber, episodeNumber } = series || {};
    const { contentId } = newTvMeta || {};
    return {
        genre: mainGenre,
        genreDisplayName: mainGenre,
        description: descLong || descShort || background,
        recordingForbidden: recordingForbidden ?? false,
        stopTime,
        startTime,
        series: series ?? undefined,
        channel: stationId,
        duration: `${diffInMinutes(date(stopTime), date(startTime))}`,
        title,
        year,
        season: seasonNumber,
        pauseForbidden: pauseForbidden ?? false,
        actors: castMembers ? epg2CastMembersToActors(castMembers as CastMember[]) : undefined, // equal tying but different types :/
        episodeTitle: episodeTitle || episodeTitleOriginal,
        id,
        episode: episodeNumber,
        liveShow,
        channelDisplay: channelDisplay || stationId,
        country: countries.join(', '),
        // eslint-disable-next-line no-template-curly-in-string
        previewImages: imageUrls,
        parentalGuidance,
        newTvContentId: contentId,
    };
};
