import CloseIcon from '@exaring/assets/icons/close.svg';
import { styled } from '@exaring/ui/components-styled/theme';
import { Button } from '@exaring/ui/components-styled/Button';
import { ModalBackdrop, Modal } from '../Modal/Modal';

export const StyledCloseIcon = styled(CloseIcon, {
    color: '$white',
    cursor: 'pointer',
});

export const ContentWrapper = styled('div', {
    display: 'grid',
    columnGap: '20px',
    gridTemplateColumns: 'repeat(4, 1fr)',
    marginTop: '26px',
    overflowY: 'auto',

    // Calculate max size of container (adds scroll to content)
    // (screen height) - 2x(spacing from screen edges) - 2x(padding of modal)
    // - (height of content above)
    maxHeight: 'calc(100vh - 26px - 40px - 98px)',
});

export const HeaderWrapper = styled('div', {
    display: 'grid',
    columnGap: '20px',
    gridTemplateColumns: 'repeat(4, 1fr)',
    marginTop: '26px',
});

export const Explanation = styled('div', {
    gridColumnEnd: 'span 3',
});

export const ResetButton = styled(Button, {
    width: '100% !important',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: '$2',

    '& > svg': {
        width: '32px',
        height: '32px',
        marginTop: '-4px',
        marginBottom: '-4px',
        marginRight: '8px',
    },
});

export const StyledModalBackdrop = styled(ModalBackdrop, {
    margin: '13px',
    height: '100vh',
});

export const StyledModal = styled(Modal, {
    viewTransitionName: 'detail-modal',
    overflowY: 'hidden',
    padding: '20px',
    maxHeight: 'calc(100vh - 26px)',
});
