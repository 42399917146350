/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, FunctionComponent } from 'preact';
import { CSS } from '@stitches/react';
import tileFallback from '@exaring/assets/images/tileFallback.png';
import { styled } from '../../theme';
import { Typography } from '../../Typography/Typography';
import { StyledTileContainer } from '../parts/StyledTileContainer';
import { StyledMetaSpan } from '../parts/StyledMetaSpan';
import { StyledTileImage } from '../parts/StyledTileImage';
import { headlineStyle } from '../styles';
import { VideoTileImageContainer } from '../parts/VideoTileImageContainer';
import { StyledTileContent } from '../parts/StyledTileContent';

const StyledImageExtended = styled(StyledTileImage, {
    maxHeight: '311px',

    variants: {
        tall: {
            true: {
                height: '311px',
            },
        },
    },
});

export const BigTile: FunctionComponent<{
    css?: CSS;
    className?: string;
    title?: string;
    time?: string;
    genre?: string;
    channel?: string;
    imageSrc?: string;
    locked?: boolean;
    onMediaPlayout?: () => void;
    onOpenDetails?: () => void;
    hidePlayOverlay?: boolean;
    tall?: boolean;
}> = ({
    css,
    className,
    title,
    time,
    genre,
    channel,
    imageSrc,
    locked,
    onMediaPlayout,
    onOpenDetails,
    hidePlayOverlay = false,
    tall = false,
}) => (
    <StyledTileContainer
        size={{
            '@initial': 'mobile',
            '@sm': 'default',
        }}
        css={css}
        className={className}
    >
        <VideoTileImageContainer
            locked={locked}
            onClick={onMediaPlayout}
            hidePlayOverlay={hidePlayOverlay}
        >
            <StyledImageExtended src={imageSrc} srcFallback={tileFallback} tall={tall} />
        </VideoTileImageContainer>

        <StyledTileContent onClick={onOpenDetails}>
            <Typography css={headlineStyle} variant="h2">
                {title}
            </Typography>
            <StyledMetaSpan css={{ color: '$lightBlue' }}>{time}</StyledMetaSpan>
            <StyledMetaSpan>{genre}</StyledMetaSpan>
            <StyledMetaSpan>{channel}</StyledMetaSpan>
        </StyledTileContent>
    </StyledTileContainer>
);
