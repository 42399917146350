import DragIcon from '@exaring/assets/icons/drag-handle.svg';
import { PageGridItem } from '../../../../../packages/ui/components-styled/PageGrid';
import { styled } from '../../../../../packages/ui/components-styled/theme';

export const ChannelLogo = styled('img', {
    width: '64px',
    height: '48px',
    objectFit: 'contain',
});

export const ChannelName = styled('div', {
    flexGrow: '1',
    textOverflow: 'ellipsis',
    lineHeight: '24px',
    height: '24px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
});

export const StyledSortItem = styled(PageGridItem, {
    background: '$dark',
    height: '64px',
    borderRadius: '4px',
    cursor: 'grab',
    border: 'solid 2px transparent',

    '&:hover': {
        border: 'solid 2px $white',
    },

    variants: {
        border: {
            true: {
                border: 'solid 2px $white',
            },
        },
        dropLine: {
            left: {
                '&:before': {
                    display: 'block',
                    content: ' ',
                    height: '84px',
                    borderLeft: 'solid 2px $blue',
                    marginRight: '-2px',
                    position: 'relative',
                    top: '-10px',
                    float: 'left',
                    left: '-11px',
                },
            },
            right: {
                '&:before': {
                    display: 'block',
                    content: ' ',
                    height: '84px',
                    borderLeft: 'solid 2px $blue',
                    marginLeft: '-2px',
                    position: 'relative',
                    top: '-10px',
                    float: 'right',
                    left: '11px',
                },
            },
            top: {
                '&:before': {
                    display: 'block',
                    content: ' ',
                    height: '0px',
                    width: 'calc(100% + 20px)',
                    borderTop: 'solid 2px $blue',
                    marginTop: '-2px',
                    position: 'relative',
                    left: '-10px',
                },
            },
            bottom: {
                '&:after': {
                    display: 'block',
                    content: ' ',
                    height: '0px',
                    width: 'calc(100% + 20px)',
                    borderTop: 'solid 2px $blue',
                    marginTop: '-2px',
                    position: 'relative',
                    left: '-10px',
                },
            },
        },
        hidden: {
            true: {
                background: 'rgba($dark, 0.25)',

                [`${ChannelName}`]: { opacity: 0.25 },
                [`${ChannelLogo}`]: { opacity: 0.25 },
            },
        },
    },
});

export const ChannelIconWrapper = styled('div', {
    position: 'relative',
});

export const LockIconWrapper = styled('div', {
    position: 'absolute',
    top: '12px',
    left: '20px',

    '& > svg': {
        width: '24px',
        height: '24px',
    },

    variants: {
        visible: {
            false: {
                display: 'none',
            },
        },
    },
});

export const SortItemContent = styled('div', {
    display: 'flex',
    height: '60px',
    alignItems: 'center',
    margin: '0 8px',
    columnGap: '10px',
});

export const DragIconStyled = styled(DragIcon, {
    width: '24px',
    height: '24px',
    flexShrink: 0,
});

export const IconButon = styled('button', {
    cursor: 'pointer',

    '& > svg': {
        width: '24px',
        height: '24px',
    },

    variants: {
        active: {
            true: {
                color: '$aquamarine',
            },
        },
    },
});
