import { getBaseUrl, urlWithParams } from '@exaring/utils';
import { get, post, remove } from '../../request';

type RecordingSelection = 'AVAILABLE' | 'PLANNED';

const base = getBaseUrl(window.location.href);
const RECORDINGS_BASE_URL = `https://recording.${base}`;
const RECORDING_ACCEPT = 'application/vnd.waipu.recording-v4+json';
const RECORDING_STREAMING_DETAIL_ACCEPT =
    'application/vnd.waipu.recording-streaming-detail-v4+json';
const RECORDINGS_ACCEPT = 'application/vnd.waipu.recordings-v4+json';
const RECORDING_DELETE_CONTENT_TYPE = 'application/vnd.waipu.recording-ids-v4+json';

export const recordingDetails = (recordingId: string) =>
    get(`${RECORDINGS_BASE_URL}/api/recordings/${recordingId}`, {
        Accept: RECORDING_ACCEPT,
    });

export const streamingDetails = (recordingId: string, gdprConsent?: string) =>
    get(
        urlWithParams(
            `${RECORDINGS_BASE_URL}/api/recordings/${recordingId}/streamingdetails`,
            {
                gdprConsent: gdprConsent || null,
            },
            true,
        ),
        {
            Accept: RECORDING_STREAMING_DETAIL_ACCEPT,
        },
    );

export const recordings = () =>
    get(`${RECORDINGS_BASE_URL}/api/recordings`, {
        Accept: RECORDINGS_ACCEPT,
    });

export const recordingGroup = (recordingGroup?: number, seriesId?: string) =>
    get(
        urlWithParams(
            `${RECORDINGS_BASE_URL}/api/recordings`,
            {
                recordingGroup: recordingGroup || null,
                seriesId: seriesId || null,
            },
            true,
        ),
        {
            Accept: RECORDINGS_ACCEPT,
        },
    );

export const schedule = (programId: string, recordingGroup?: number) =>
    post(
        `${RECORDINGS_BASE_URL}/api/recordings`,
        {
            Accept: 'application/vnd.waipu.recording-created-v4+json',
            'Content-Type': 'application/vnd.waipu.recording-create-v4+json',
        },
        {
            programId,
            ...(recordingGroup ? { recordingGroup } : {}),
        },
    );

/**
 * delete a list of recordings
 */
export const deleteRecordings = (
    recordingIdList: string[],
    groupIds?: number[],
    recordingSelection?: RecordingSelection[],
) =>
    remove(
        `${RECORDINGS_BASE_URL}/api/recordings`,
        {
            'Content-Type': RECORDING_DELETE_CONTENT_TYPE,
        },
        {
            ids: recordingIdList,
            groupIds,
            recordingSelection,
        },
    );

/**
 * Unschedule a list of not yet started recordings
 */
export const unschedule = (recordingIdList: string[]) =>
    remove(
        `${RECORDINGS_BASE_URL}/api/recordings`,
        {
            Accept: 'application/vnd.waipu.recordings-v2+json',
            'Content-Type': 'application/vnd.waipu.pvr-recording-ids-v2+json',
        },
        { ids: recordingIdList },
    );

export const stop = (recordingId: string) =>
    post(`${RECORDINGS_BASE_URL}/api/recordings/${recordingId}/stop`, {
        Accept: RECORDING_ACCEPT,
    });

/**
 * @return {Object}
 */
export const deleteFailed = () =>
    remove(`${RECORDINGS_BASE_URL}/api/recordings?status=PVR_LIMIT_EXCEEDED`, {
        Accept: 'application/vnd.waipu.recordings-delete+json',
    });
