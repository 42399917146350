import { grid, gridInfo, details, query } from '../requests/epg2';
import { requestHookChain } from '../networking.v2';

const api = {
    gridInfo: requestHookChain(gridInfo),
    grid: requestHookChain(grid),
    details: requestHookChain(details),
    query: requestHookChain(query),
};

export default api;
