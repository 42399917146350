import { FunctionComponent, h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { styled } from '../../../../../packages/ui/components-styled/theme';
import { isValidInput, toDisplayValue } from './SortNumber.utils';

type SortNumberProps = {
    value?: number;
    editable: boolean;
    onChange: (value: number) => void;
};

const StyledSortNumber = styled('input', {
    background: '$nearBlack',
    height: '24px',
    width: '32px',
    textAlign: 'center',
    lineHeight: '24px',
    borderRadius: '4px',
    font: 'OpenSans',
    fontWeight: '$3',
    fontSize: '$2',
    outline: 'none',
    caretColor: '$blue',
});

export const SortNumber: FunctionComponent<SortNumberProps> = ({
    value: propValue,
    editable,
    onChange,
}) => {
    const [value, setValue] = useState(toDisplayValue(propValue));

    useEffect(() => {
        setValue(toDisplayValue(propValue));
    }, [propValue]);

    return (
        <StyledSortNumber
            value={value}
            readOnly={!editable}
            onClick={() => {
                // Removes current value when focused
                setValue('');
            }}
            onChange={(e) => {
                const val = e.target.value;

                if (isValidInput(val)) {
                    setValue(val);
                } else {
                    // Prevent Preact from updating the value regardless
                    // It's a weird "feature" of Preact
                    // https://github.com/preactjs/preact/issues/2228#issuecomment-570928366
                    e.target.value = value;
                }
            }}
            onBlur={() => {
                // On blur we bring back old value
                setValue(toDisplayValue(propValue));
            }}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    if (value === '') {
                        setValue(toDisplayValue(propValue));
                    } else if (parseInt(value, 10) === propValue) {
                        // Same value as initially, just blur so it has the same behviour as it it would get reordered
                        (e.target as HTMLInputElement).blur();
                    } else {
                        onChange(parseInt(value, 10));
                    }
                }
            }}
        />
    );
};
