/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, Fragment } from 'preact';
import MobileDetect from 'mobile-detect';

import { NotificationScreen } from '@exaring/ui';
import DOIBlocker from './DOIBlocker';

import constants from '../../constants';
import MobileBlockerPage from '../../pages/MobileBlocker';
import Header from '../Header';
import { userStore } from '../../state/Store';

export const appBlockerType = {
    noBlocker: false,
    hardBlockerMobile: 1,
    hardBlockerBrowser: 2,
    softBlockerBrowser: 3,
    softBlockerVersion: 4,
    hardBlockerIos: 5,
    hardBlockerVersion: 6,
    hardBlockerDunningLevel2: 7,
    hardUserConfirmation: 8,
};

export const appBlockerReverseType = {
    false: 'noBlocker',
    1: 'hardBlockerMobile',
    2: 'hardBlockerBrowser',
    3: 'softBlockerBrowser',
    4: 'softBlockerVersion',
    5: 'hardBlockerIos',
    6: 'hardBlockerVersion',
    7: 'hardBlockerDunningLevel2',
};

export const dunningLevelCheck = () => {
    const { isDunningLevel2 } = userStore();
    return isDunningLevel2 && appBlockerType.hardBlockerDunningLevel2;
};

export const userConfirmationCheck = () => {
    const { isUserConfirmed: emailIsConfirmed } = userStore();
    return !emailIsConfirmed && appBlockerType.hardUserConfirmation;
};

export const appBlockerCheck = (userAgent) => {
    const md = new MobileDetect(userAgent);
    const chromeVersion = md.version('Chrome');
    const firefoxVersion = md.version('Firefox');
    const edgeVersion = md.version('Edge');
    const safariVersion = md.version('Safari');

    // block all mobile devices but allow tablets
    if (md.mobile() && !md.tablet()) {
        return appBlockerType.hardBlockerMobile;
    }

    // block all Internet Explorers (should get most of them)
    if (md.match('Trident') || md.match('MSIE')) {
        return appBlockerType.softBlockerBrowser;
    }

    // block all iOS devices below iOS 10.3 as they are not compatible with css-grid-layout
    // and have severe auto-playback issues (even with our workaround)
    if (md.os() === 'iOS' && md.version('iOS') <= 10.3) {
        return appBlockerType.hardBlockerIos;
    }

    // put feature detection last because we still want to block IE, Edge and older iOS devices softly
    if (!Modernizr.cssgrid) {
        return appBlockerType.softBlockerBrowser;
    }

    // we have a polyfill for Promise.prototype.finally in place but still some user suffer by that (getting a black screen after start up)
    if (!Promise || typeof Promise.prototype.finally !== 'function') {
        return appBlockerType.hardBlockerVersion;
    }

    if (edgeVersion) {
        if (edgeVersion >= 95) {
            return appBlockerType.noBlocker;
        }
        return appBlockerType.softBlockerVersion;
    }

    if (chromeVersion) {
        if (chromeVersion >= 95) {
            return appBlockerType.noBlocker;
        }
        return appBlockerType.softBlockerVersion;
    }

    if (firefoxVersion) {
        if (firefoxVersion >= 88) {
            return appBlockerType.noBlocker;
        }
        return appBlockerType.softBlockerVersion;
    }

    if (safariVersion >= 11 && safariVersion < 100) {
        return appBlockerType.noBlocker;
    }
    if (safariVersion && safariVersion < 12) {
        return appBlockerType.softBlockerVersion;
    }

    return appBlockerType.softBlockerBrowser;
};

/*
type Props = {
    type: number, // child of appBlockerType
    brandName: string,
    cancelBlocker: function // this should clear the blocker and start/return to the app
}
 */
const AppBlockerScreen = ({ type, brandName, cancelBlocker, user }) => {
    const notOptimizedTitle = `${brandName} ist für Ihren Browser nicht optimiert.`;
    const notOptimizedContent = `Bitte aktualisieren Sie Ihren Browser um ${brandName} optimal nutzen zu können.`;
    const bestChoiceText = `Am besten funktioniert ${brandName} mit der aktuellsten Version von Google Chrome.`;

    switch (type) {
        case appBlockerType.hardBlockerMobile:
            return (
                <Fragment>
                    <Header hideInternalLinks />
                    <MobileBlockerPage brandName={brandName} />
                </Fragment>
            );

        case appBlockerType.hardBlockerBrowser:
            return (
                <Fragment>
                    <Header hideInternalLinks />
                    <NotificationScreen
                        title="Ihr Browser wird leider nicht unterstützt!"
                        content={bestChoiceText}
                        type="connection"
                    />
                </Fragment>
            );

        case appBlockerType.hardBlockerVersion:
            return (
                <Fragment>
                    <Header hideInternalLinks />
                    <NotificationScreen
                        title={notOptimizedTitle}
                        content={notOptimizedContent}
                        type="connection"
                    />
                </Fragment>
            );

        case appBlockerType.softBlockerBrowser:
            return (
                <Fragment>
                    <Header />
                    <NotificationScreen
                        title={notOptimizedTitle}
                        content={bestChoiceText}
                        type="connection"
                        buttons={[
                            {
                                label: 'Trotzdem weiter',
                                action: cancelBlocker,
                            },
                        ]}
                    />
                </Fragment>
            );

        case appBlockerType.softBlockerVersion:
            return (
                <Fragment>
                    <Header />
                    <NotificationScreen
                        title={notOptimizedTitle}
                        content={notOptimizedContent}
                        type="connection"
                        buttons={[
                            {
                                label: 'Trotzdem weiter',
                                action: cancelBlocker,
                            },
                        ]}
                    />
                </Fragment>
            );

        case appBlockerType.hardBlockerIos:
            return (
                <Fragment>
                    <Header hideInternalLinks />
                    <NotificationScreen
                        title={`${brandName} ist für Ihre iOS-Version nicht optimiert!`}
                        content={
                            <div className="center-children">
                                {brandName} ist für die aktuellsten Versionen von Safari, Chrome und
                                Firefox optimiert, welche erst ab iOS Version 10.3 verfügbar sind.
                                <br />
                                <br />
                                Laden Sie sich alternativ einfach unsere App für iPad und Smartphone
                                herunter!
                                <br />
                                <br />
                                <a
                                    href={constants.APPLE_APP_URL}
                                    className="app-store-links__link app-store-links__link--ios"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                />
                            </div>
                        }
                        type="connection"
                    />
                </Fragment>
            );

        case appBlockerType.hardBlockerDunningLevel2:
            return (
                <Fragment>
                    <Header hideInternalLinks />
                    <NotificationScreen
                        title={`Dieses ${brandName}-Nutzerkonto ist temporär gesperrt.`}
                        content="Bitte überprüfen Sie Ihre Kontoeinstellungen und Zahlungsdaten."
                        type="connection"
                        buttons={[
                            {
                                label: 'Zum Kontobereich',
                                action: () => {
                                    window.location.href = constants.CSC_ACCOUNTING_URL;
                                },
                            },
                        ]}
                    />
                </Fragment>
            );

        case appBlockerType.hardUserConfirmation:
            return (
                <Fragment>
                    <Header hideInternalLinks showLogout />
                    <DOIBlocker emailAddress={user?.emailAddress} />
                </Fragment>
            );

        default:
            // this case should never be reached
            console.error('Unknown appBlockerType ', type);
            return undefined;
    }
};

export default AppBlockerScreen;
