/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */
import { h } from 'preact';
import { NotificationScreen, keyboardCodes } from '@exaring/ui';
import { useNativeEvents } from '@exaring/ui/hooks';
import { useRef, useEffect, useState } from 'preact/hooks';
import { memo } from 'preact/compat';
import { isOnAir } from '@exaring/utils/data/program';
import SearchResultList from '../SearchResultList';
import { routeTo, routeToEpg } from '../../routes';
import { WebClientGAEvent } from '../../web-client-ga';
import { searchRenderCondition } from './helper';
import { epgStore, useSearchStore, useUiStore } from '../../state/Store';
import { getCurrentScreen } from '../../helper';

const SearchResultsEmpty = ({ goToEpg }) => {
    return (
        <NotificationScreen
            className="search-result"
            type="connection"
            title="Keine Ergebnisse gefunden"
            content={
                <span>
                    Entdecken Sie im{' '}
                    <a onClick={goToEpg} className="text-link">
                        Programm
                    </a>{' '}
                    was läuft.
                </span>
            }
        />
    );
};

const SearchResults = memo(({ track }) => {
    const {
        epgStations: { value: channelList, state },
        getStationById,
    } = epgStore();
    const { query: searchValue, getResultData, resetState: hardResetSearch } = useSearchStore();

    const _searchResult = getResultData();
    const { toggleSearchActive } = useUiStore();
    const searchActive = true; // TODO
    const isSearchPending = state === 'Loading';
    const [selectedResult, selectResult] = useState(0);

    useEffect(() => {
        if (searchValue) {
            selectResult(0);
        }
    }, [searchValue]);

    const onSearchResultClick = (e) => {
        const { id, isChannel, stationId: channel, title } = e.item;
        const _isOnAir = isOnAir(e.item) || isChannel;
        const searchQuery = searchValue || '';

        hardResetSearch();
        toggleSearchActive(false);

        if (isChannel) {
            track(WebClientGAEvent.Search, WebClientGAEvent.SearchClickChannel, {
                eventDetails: 'search text',
                channelName: channel,
                screenName: getCurrentScreen(),
                programTitle: title,
                programId: id,
                searchQuery,
            });
        } else {
            track(WebClientGAEvent.Search, WebClientGAEvent.SearchClickProgram, {
                channelName: channel,
                eventDetails: 'search text',
                screenName: getCurrentScreen(),
                programTitle: title,
                programId: id,
                searchQuery,
            });
        }

        if (_isOnAir) {
            routeTo(`/${channel}`);
        } else {
            routeToEpg(channel, id);
        }
    };

    const isChannelLocked = (stationId) => {
        const channel = channelList.find((item) => item.id === stationId);
        return channel?.locked;
    };

    // ignore hidden channel results
    const searchResult = _searchResult.filter(
        (res) =>
            getStationById(res.program.stationId) !== undefined &&
            !isChannelLocked(res.program.stationId),
    );

    const searchQuery = searchValue.toLowerCase();

    const matchedChannels = channelList
        .filter(
            (channel) =>
                (channel.displayName?.toLowerCase().includes(searchQuery) ||
                    channel.id?.toLowerCase().includes(searchQuery)) &&
                !isChannelLocked(channel.id),
        )
        .map((channel) => {
            const suffix = !channel.displayName.toLowerCase().includes(searchQuery)
                ? ` (${channel?.id})`
                : '';

            return {
                program: {
                    title: channel.displayName + suffix,
                    stationId: channel.id,
                    isChannel: true,
                },
            };
        });

    const goToEpg = () => {
        hardResetSearch();
        toggleSearchActive(false);
        routeToEpg();
    };

    const ref = useRef();

    const results = matchedChannels.concat(searchResult);

    const eventMap = {
        keydown: (e) => {
            if (e.code === keyboardCodes.up) {
                const nextSelected = selectedResult - 1;
                nextSelected >= 0 && selectResult(selectedResult - 1);
                return;
            }
            if (e.code === keyboardCodes.down) {
                const nextSelected = selectedResult + 1;
                nextSelected < results.length && selectResult(selectedResult + 1);
                return;
            }
            if (e.code === keyboardCodes.enter) {
                const selectedSearchResult = results[selectedResult];
                if (selectedSearchResult) {
                    onSearchResultClick({ item: selectedSearchResult.program });
                }
            }
        },
    };

    useNativeEvents(eventMap);

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollTop = 0;
        } // scroll to top on search query update
    });

    return searchActive && !searchResult.length && !matchedChannels.length && !isSearchPending ? (
        <SearchResultsEmpty goToEpg={goToEpg} />
    ) : (
        searchValue && (
            <SearchResultList
                resultsRef={ref}
                onItemClick={onSearchResultClick}
                items={results}
                query={searchValue}
                selectedResult={selectedResult}
            />
        )
    );
}, searchRenderCondition);

export default SearchResults;
