/*
 *  Copyright (C) Exaring AG - All Rights Reserved
 */
import { FunctionComponent, h } from 'preact';

import { LinkType, Program } from '@exaring/ui/components-styled/ProgramDetailPage/types';
import { ProgramDetailPage } from '@exaring/ui/components-styled/ProgramDetailPage';
import { epg2ProgramDetailsToProgramDetailPage } from '@exaring/ui/components-styled/ProgramDetailPage/helper';
import { useScrollLock } from '@exaring/ui/hooks/useScrollLock/useScrollLock';
// Will uncomment when implementing the following story
// import ShareIcon from '@exaring/assets/icons/share.svg';
import DeleteIcon from '@exaring/assets/icons/recording-delete.svg';
// @ts-expect-error no declaration file for this package
import streamPositionService from '@exaring/networking/services/stream-position';
import { getMicroSiteIdFromStation } from '@exaring/utils/data/channel';
import { isOnAir } from '@exaring/utils/data/program';
import { RecommendationContents } from '@exaring/ui/components-styled/types/Recommendation';
import { ModalBackdrop, Modal, StyledBackdropVariants } from '../../Modal/Modal';
import { IconButton } from '../../../../../../packages/ui/components-styled/IconButton';
import { styled } from '../../../../../../packages/ui/components-styled/theme';
import { useRecordingDetails } from '../hooks/useRecordingDetails';

import { didSucceed } from '../../../state/utils/RemoteData';

import RecordingButton from '../../RecordingButton';
import { useProgramDetails } from '../hooks/useProgramDetails';
import { programToProgramData } from '../../RecordingButton/RecordingButton';
import { ItemContextCallback } from '../RecordingGrid';
import {
    Routes,
    routeTo,
    routeToWaiputhekMediathek,
    routeToLive,
    routeToEpg,
    routeToWaiputhekDetails,
} from '../../../routes';
import { useEpgStore } from '../../../state/Store';

const eventNop = (event: MouseEvent | TouchEvent) => event.stopPropagation();

export const RecordingDetailsModal: FunctionComponent<
    StyledBackdropVariants & {
        onClose: () => void;
        onPlayContent: (recordingId: string) => () => void;
        onDeleteRecording: ItemContextCallback;
        recordingId?: string;
        programId?: string;
        stationDisplay?: string;
    }
> = ({
    onClose,
    onPlayContent,
    onDeleteRecording,
    recordingId,
    programId,
    fullscreen,
    modal,
    stationDisplay,
}) => {
    const {
        epgStations: { value: stations },
    } = useEpgStore();
    const { data: recordingDetails } = useRecordingDetails(recordingId);
    const { data: programDetails } = useProgramDetails(programId);
    const recording = didSucceed(recordingDetails) ? recordingDetails.value : undefined;
    const program = didSucceed(programDetails) ? programDetails.value : undefined;
    const channel = recording
        ? stations.find((station) => station.id === recording.programDetails.stationId)
        : stations.find((station) => station.id === program?.stationId);

    useScrollLock();

    const pageData = (): Program | undefined => {
        if (recording) {
            return epg2ProgramDetailsToProgramDetailPage(recording.programDetails);
        }
        if (program) {
            return epg2ProgramDetailsToProgramDetailPage(program);
        }

        return undefined;
    };

    const data = pageData();

    const StyledIconButton = styled(IconButton, {
        height: '36px !important',
        padding: 'unset',
        width: '62px',
        textAlign: 'center',
    });

    const ButtonsContainer = styled('div', {
        width: '144px',
        display: 'flex',
        justifyContent: 'end',
    });

    const headerButtons = (
        <ButtonsContainer>
            {/* Will uncomment when implementing the following story
            <StyledIconButton>
                <ShareIcon />
            </StyledIconButton> */}
            {recordingId && (
                <StyledIconButton
                    onClick={
                        recording
                            ? onDeleteRecording(recordingId, recording.recordingGroup as number)
                            : undefined
                    }
                >
                    <DeleteIcon />
                </StyledIconButton>
            )}
        </ButtonsContainer>
    );

    const handleRestart = async () => {
        await streamPositionService.updatePosition(recordingId, 0);
        routeTo(`${Routes.RECORDING_PLAYOUT_PAGE}/${recordingId}`);
    };

    const microSiteId = getMicroSiteIdFromStation(channel);

    const handleRecommendationClick = (args: {
        channelId: string;
        programId: string;
        recommendation: RecommendationContents;
        contentId?: string;
    }) => {
        const { channelId, programId: progId, recommendation, contentId } = args;
        if (contentId) {
            routeToWaiputhekDetails(contentId);
            return;
        }
        if (isOnAir(recommendation) && !recommendation.locked) {
            routeToLive(channelId);
            return;
        }
        routeToEpg(channelId, progId);
    };

    return (
        <ModalBackdrop
            fullscreen={fullscreen}
            css={{ marginTop: '$$gutterWidths' }}
            modal={modal}
            alignItems="flex-start"
            justifyContent="center"
            onClick={onClose}
        >
            <Modal
                css={{
                    viewTransitionName: 'detail-modal',
                    overflowY: 'hidden',
                    paddingRight: '0',
                    paddingLeft: '0',
                }}
                onClick={eventNop}
            >
                {data && (
                    <ProgramDetailPage
                        layout="modal"
                        isEpisodeView={!!recording && !!recording.recordingGroup}
                        program={data}
                        recording={recording}
                        channelNumber={channel?.enumeration}
                        onRecommendationClick={handleRecommendationClick}
                        // if there is no microSiteId we know that there is no matching mediathek for this channel
                        hideMediathekButton={channel ? !microSiteId : true}
                        hidePlayButton={!!programId && !recordingId}
                        stationDisplay={stationDisplay}
                        channelWithLogo={false}
                        headerButtons={headerButtons}
                        goTo={(_: Program | undefined, linkType: LinkType) => {
                            switch (linkType) {
                                case 'close':
                                    onClose();
                                    break;
                                case 'play':
                                    if (data?.id && recordingId) {
                                        onPlayContent(recordingId)();
                                    }
                                    break;
                                case 'restart':
                                    handleRestart();
                                    break;
                                case 'previous':
                                case 'next':
                                case 'mediathek':
                                    routeToWaiputhekMediathek(microSiteId);
                                    break;
                                case 'togglePlay':
                                    routeToLive(
                                        recording
                                            ? recording.programDetails.stationId
                                            : program!.stationId,
                                    );
                                    break;
                                case 'backToSeries':
                                    routeTo(
                                        `${Routes.RECORDING_PAGE_GROUP}/${
                                            recording!.recordingGroup
                                        }/${recording!.id}`,
                                    );
                                    break;
                                default:
                                    break;
                            }
                        }}
                        renderRecordingButton={(_program) => (
                            <RecordingButton
                                triggerAutoRecord={false}
                                key={_program.id}
                                program={programToProgramData(_program)}
                                size="big"
                                showLabel
                                isProgramDetails
                            />
                        )}
                    />
                )}
            </Modal>
        </ModalBackdrop>
    );
};
