import oauth2Service from '@exaring/networking/services/oauth2';
import { defaultHooksConfig } from '@exaring/networking/hooks';
import { initRuntimeHookCfg } from '@exaring/networking/runtime';

import { clamp, logError, logErrorWithDescription, loop } from '@exaring/utils';
import { isOnAir, isTTLValid, unix, now } from '@exaring/utils/date';
import { authRedirectUrl } from '@exaring/networking/oauth2';
import constants from './constants';
import store from './store';
import { epgStore, userStore } from './state/Store';
import { PLAYOUT_LIVE } from './actions/constants';
import { epgV2ProgramDetailsToV1 } from './helper';
import { setEpg2Store } from './actions/playout';
import { CDRequestEnum } from './state/UserStore';

// NOTICE this works only when no code run before this point needs both stores.
// If so, move this code before this point!
setEpg2Store(epgStore);

let runningOauthRefresh;

initRuntimeHookCfg(
    defaultHooksConfig({
        authToken: async () => {
            if (!runningOauthRefresh) {
                runningOauthRefresh = oauth2ActiveRefresh();
            }
            await runningOauthRefresh;
            runningOauthRefresh = undefined;
            return userStore().token;
        },
        disableAuth: false,
    }),
);

const oauth2ActiveRefresh = async () => {
    const { tokenTTL, refreshToken, setStateWithJwt } = userStore();

    if (tokenTTL - constants.AUTH_REFRESH_THRESHOLD - unix() <= 0) {
        try {
            const { data } = await oauth2Service.refreshToken(refreshToken, constants.CLIENT_ID);
            setStateWithJwt(data.access_token, data.refresh_token);
        } catch (e) {
            window.location.href = await authRedirectUrl(
                constants.AUTH_REDIRECT_URL,
                constants.CLIENT_ID,
            ); // else do a auth roundtrip
        }
    }
};

const deviceCapabilitiesTokenRefresh = async () => {
    const { deviceTokenTTL, cdRequestState, setDeviceCapabilities } = userStore();
    const cdRefreshEnabled = cdRequestState !== CDRequestEnum.ERROR;

    if (deviceTokenTTL > 0 && cdRefreshEnabled && !isTTLValid(deviceTokenTTL)) {
        setDeviceCapabilities(constants.RELEASE);
    }
};

const stillWatchingDetection = () => {
    const {
        playout: { playoutTimestamp },
    } = store.getState();

    if (!playoutTimestamp) {
        return;
    }

    const playoutMaxTime = playoutTimestamp + constants.STREAM_TTL;

    if (clamp(playoutMaxTime - unix(), 0, playoutMaxTime) === 0) {
        store.api.playout.interruptPlayout();
    }
};

let nextEpgUpdate;

const refreshEpgDataIfNeeded = async () => {
    const { playout } = store.getState();
    const { flushLiveCache, fetchEpgDetails, getLiveProgram, getProgramForTimestamp } = epgStore();
    const { playoutType, isTimeShifted } = playout;
    let { activeProgram } = playout;
    const isLive = playoutType === PLAYOUT_LIVE;

    if (isLive && activeProgram) {
        if (!nextEpgUpdate || nextEpgUpdate.unix() < now().unix()) {
            nextEpgUpdate = now().endOf('minute'); // define next minute update
            const { channel, startTime, stopTime } = activeProgram; // V1 Data
            flushLiveCache();

            if (isTimeShifted) {
                const {
                    player: { isPlaying: playerIsPlaying, timestamp: playerTimestamp },
                } = store.getState();

                const timeShiftProgramNeedsUpdated = unix(activeProgram.stopTime) < playerTimestamp;

                if (timeShiftProgramNeedsUpdated && playerIsPlaying) {
                    const newTimeShiftedProgram = await getProgramForTimestamp(
                        channel,
                        parseInt(playerTimestamp, 10) * 1000, // cast to modern timestamp and omit ms accuracy
                    );

                    if (!newTimeShiftedProgram) {
                        logError(new Error('Epg program for time shifted program was not found'));
                        return;
                    }

                    activeProgram = epgV2ProgramDetailsToV1(
                        await fetchEpgDetails(newTimeShiftedProgram.id),
                    );
                    store.api.playout.updateActiveProgram(activeProgram);
                }
            } else if (!isOnAir(startTime, stopTime)) {
                // NOTE be carful liveProgram is v2 data
                const liveProgram = await getLiveProgram(channel); // make sure live cache is in sync (this needs to be called always and maintains the general live cache)

                if (!liveProgram) {
                    // if no live program is loaded, nothing todo
                    return;
                }

                // verify if program is not live anymore or not details program data as only details data includes parental guidance data
                // if program is not on air anymore we need to change details data
                try {
                    const programDetails = await fetchEpgDetails(liveProgram.id);
                    if (programDetails) {
                        activeProgram = epgV2ProgramDetailsToV1(programDetails);
                        store.api.playout.updateActiveProgram(activeProgram);
                    }
                } catch (e) {
                    logErrorWithDescription(e, 'Active epg program could not be updated');
                }
            }
        }
    }
};

let appLoop;

const init = () => {
    appLoop = loop();
    appLoop.init(logError);
    appLoop.add(
        deviceCapabilitiesTokenRefresh,
        constants.DEVICE_CAPABILITIES_TOKEN_REFRESH_INTERVAL,
    );
    appLoop.add(stillWatchingDetection, constants.STILL_WATCHING_INTERVAL);
    appLoop.add(refreshEpgDataIfNeeded, constants.EPG_REFRESH_INTERVAL); // playout store

    return appLoop;
};

export default init;
