import { unix } from '@exaring/utils/date';
import { isNil } from '@exaring/utils/validators';
import { Recording } from '@exaring/networking/types/Recording/Recording';

export const isNew = (recording: Recording) => recording.isNew && isPlayableRecording(recording);

export const isPlayableRecording = (recording: Recording) =>
    recording.status === 'FINISHED' || recording.status === 'RECORDING';

export const isScheduledRecording = (recording: Recording) => recording.status === 'SCHEDULED';

export const sortRecordingTitle = (left: Recording, right: Recording) => {
    if (!isNil(left.episodeTitle) && !isNil(right.episodeTitle) && left.title === right.title) {
        return left.episodeTitle.localeCompare(right.episodeTitle);
    }

    if (!isNil(left.title) && !isNil(right.title)) {
        return left.title.localeCompare(right.title);
    }

    return 0;
};

export const sortRecordingChannel = (left: Recording, right: Recording) =>
    left.stationDisplay.localeCompare(right.stationDisplay);

export const sortRecordingStartTime = (left: Recording, right: Recording) =>
    unix(left.epgStartTime) - unix(right.epgStartTime);

export const sortRecordingEpisode = (left: Recording, right: Recording) => {
    const isEpisodeAvailable = !isNil(left.episode) && !isNil(right.episode);
    const isSeasonAvailable = !isNil(left.season) && !isNil(right.season);
    if (isEpisodeAvailable && isSeasonAvailable) {
        return (
            Number(left.season) - Number(right.season) ||
            Number(left.episode) - Number(right.episode)
        );
    }

    if (isEpisodeAvailable) {
        return Number(left.episode) - Number(right.episode);
    }

    return 0;
};
