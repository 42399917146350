/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { FunctionComponent, h, RenderableProps } from 'preact';
import { CSS } from '@stitches/react';
import { mapWithoutNil } from '@exaring/utils/index';
import { useMemo } from 'preact/hooks';
import { Scroller, ScrollerItem } from '../../Scroller';
import { RecommendationContents } from '../../types/Recommendation';
import { LayoutType } from '../../ProgramDetailPage/types';

const getProgramData = (url: string) => {
    const path = new URL(url).pathname.split('/');
    let programId = '';
    let stationId = '';
    let contentId = '';

    if (path[1] === 'epgdetails') {
        programId = path[Math.max(path.length - 1, 0)] || '';
        stationId = path[Math.max(path.length - 2, 0)] || '';
    }

    if (path[1] === 'tv') {
        programId = '';
        stationId = path[Math.max(path.length - 1, 0)] || '';
    }

    if (path[1] === 'waiputhek') {
        programId = '';
        stationId = path[Math.max(path.length - 1, 0)] || '';
        contentId = url.slice(url.indexOf('=') + 1);
    }

    return { programId, stationId, contentId };
};

type TileScrollerProps = RenderableProps<{
    data: (RecommendationContents & { dataId?: string })[];
    mapTile: (item: RecommendationContents, idx?: number) => JSX.Element | undefined;
    css?: CSS;
    onClick?: (args: {
        channelId: string;
        programId: string;
        recommendation: RecommendationContents;
        contentId?: string;
        tileIdx: number;
    }) => void;
    translateArrow?: string;
    defaultIndex?: number;
    layout?: LayoutType;
    rowIdx?: number;
    rowId?: string;
}>;

export const TileScroller: FunctionComponent<TileScrollerProps> = ({
    data,
    mapTile,
    css,
    translateArrow,
    defaultIndex,
    onClick,
    layout,
}) => {
    const scrollerItems = useMemo(
        () =>
            mapWithoutNil(data, (item, idx) => (
                <ScrollerItem
                    className="flicking-panel"
                    key={item.url}
                    as="div"
                    onClick={() => {
                        const {
                            programId,
                            stationId: channelId,
                            contentId,
                        } = getProgramData(item.url);

                        onClick?.({
                            channelId,
                            programId,
                            recommendation: item,
                            contentId,
                            tileIdx: idx,
                        });
                    }}
                >
                    {mapTile(item, idx)}
                </ScrollerItem>
            )),
        [data],
    );

    return (
        <Scroller
            css={css}
            layout={layout}
            translateArrow={translateArrow}
            defaultIndex={defaultIndex}
        >
            {scrollerItems}
        </Scroller>
    );
};
