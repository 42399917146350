import { epgStore } from '../../state/Store';

export const CLEARANCE_LEVEL = {
    VERIFICATION_REQUIRED: 0,
    PARENTAL_ADVISORY_CHANNEL: 1,
    PARENTAL_ADVISORY: 2,
    CLEARED: 3,
};

export const parentalRatingType = {
    'fsk-6': 6,
    'fsk-12': 12,
    'fsk-16': 16,
    'fsk-18': 18,
    'channel-fsk-18': 18,
    'channel-fsk-18+fsk-18': 18,
};

export const parentalGuidanceAgeByRating = (rating) =>
    parentalRatingType[rating] ? rating : undefined;

/**
 * Generate parentalRatingType for channel and program
 * param {object<channel>} channel
 * param {object<program>} program
 * returns {string<parentalRatingType>}
 */
// TODO: Check also if we need this function after removing epg v1
export const aggregatedParentalRating = (channel, program) => {
    const { ageRating: channelAgeRating } = channel || {};
    const channelParentalGuidance =
        channelAgeRating?.pinRequired &&
        parentalGuidanceAgeByRating(`channel-${channelAgeRating.parentalGuidance}`);
    const parentalGuidance =
        program?.pinRequired && parentalGuidanceAgeByRating(program.parentalGuidance);
    const parentalGuidanceRating = [];

    if (!channelParentalGuidance && !parentalGuidance) {
        return null; // eslint-disable-line no-restricted-syntax
    }

    channelParentalGuidance && parentalGuidanceRating.push(channelParentalGuidance);
    parentalGuidance && parentalGuidanceRating.push(parentalGuidance);

    return parentalGuidanceRating.join('+');
};

const aggregatedParentalRatingForRecordingsV4 = (channel, program) => {
    const { ageRating: channelAgeRating } = channel || {};
    const { ageRating: programAgeRating } = program || {};
    const channelParentalGuidance =
        channelAgeRating?.pinRequired &&
        parentalGuidanceAgeByRating(`channel-${channelAgeRating.parentalGuidance}`);
    const parentalGuidance =
        programAgeRating?.pinRequired &&
        parentalGuidanceAgeByRating(programAgeRating.parentalGuidance);
    const parentalGuidanceRating = [];

    if (!channelParentalGuidance && !parentalGuidance) {
        return null; // eslint-disable-line no-restricted-syntax
    }

    parentalGuidanceRating.push(channelParentalGuidance);
    parentalGuidanceRating.push(parentalGuidance);

    return parentalGuidanceRating.join('+');
};

const parentalGuidanceRatingWithChannelId = async (channelId) => {
    const { getStationById, getLiveProgram, fetchEpgDetails } = epgStore();
    const activeChannel = getStationById(channelId);
    const activeProgram = await getLiveProgram(channelId);
    const programDetails = await fetchEpgDetails(activeProgram?.id);

    return aggregatedParentalRating(activeChannel, programDetails.ageRating);
};

// TODO: Check if we still need this function after we remove legacy epg
const parentalGuidanceRatingWithProgram = (program) => {
    const { getStationById } = epgStore();
    const channel = getStationById(program.channel);

    return aggregatedParentalRating(channel, program);
};

const parentalGuidanceRatingWithProgramDetails = (programDetails) => {
    const { getStationById } = epgStore();
    const channel = getStationById(programDetails.channel);

    return aggregatedParentalRatingForRecordingsV4(channel, programDetails);
};

const parentalGuidanceLevelDetection = (channelId, activeChannelId, parentalGuidanceLevel) => {
    const channelChanged = activeChannelId !== channelId;

    return channelChanged ? CLEARANCE_LEVEL.VERIFICATION_REQUIRED : parentalGuidanceLevel;
};

export const verifyParentalGuidanceLive = async (
    channelId,
    activeChannelId,
    parentalGuidanceLevel,
) => {
    const parentalGuidanceRating = await parentalGuidanceRatingWithChannelId(channelId);
    const autoPause = !!parentalGuidanceAgeByRating(parentalGuidanceRating);

    return {
        parentalGuidanceRating,
        parentalGuidanceLevel: parentalGuidanceLevelDetection(
            channelId,
            activeChannelId,
            parentalGuidanceLevel,
        ),
        autoPause,
    };
};

export const verifyParentalGuidanceVod = (program, activeChannelId, parentalGuidanceLevel) => {
    const parentalGuidanceRating = parentalGuidanceRatingWithProgram(program);
    const autoPause = !!parentalGuidanceAgeByRating(parentalGuidanceRating);

    return {
        parentalGuidanceRating,
        parentalGuidanceLevel: parentalGuidanceLevelDetection(
            program.channel,
            activeChannelId,
            parentalGuidanceLevel,
        ),
        autoPause,
    };
};

export const verifyParentalGuidanceRecordingV4 = (
    programDetails,
    activeChannelId,
    parentalGuidanceLevel,
) => {
    const parentalGuidanceRating = parentalGuidanceRatingWithProgramDetails(programDetails);
    const autoPause = !!parentalGuidanceAgeByRating(parentalGuidanceRating);

    return {
        parentalGuidanceRating,
        parentalGuidanceLevel: parentalGuidanceLevelDetection(
            programDetails.stationId,
            activeChannelId,
            parentalGuidanceLevel,
        ),
        autoPause,
    };
};
