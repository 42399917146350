import { CSS, createStitches, keyframes } from '@stitches/react';
import { getBaseUrl } from '@exaring/utils';
import { normalize } from 'normalize-stitches';
import { Constants } from '../constants-env';
import { Colors, Fonts } from './themeConfig';

// ReExport CSS property so we can access it without importing stitches again.
export { createStitches, keyframes } from '@stitches/react';
export type { CSS, CSSProperties, VariantProps } from '@stitches/react';

// @todo migrate gradients
// @todo migrate colors and "fade" colors to hls
// @todo implement grid component

// to avoid redefine stitches base props for custom components
export type StyledComponentBaseProps = { className?: string; css?: CSS };

/**
 * All colors we use at exaring. In styled components use stitches color
 * syntax. This object exists as a fallback, so we can use the colors in any
 * part of our apps.
 * Exported here again for convenience.
 *
 * https://app.zeplin.io/styleguide/5db1b7aeb864471285b0ec2c/colors
 */
export { Colors, Fonts } from './themeConfig';

/*
 *  Gradients
 */

// @theme-gradient-default: svg-gradient(to right, @theme-logo-blue, @theme-burgundy);
// @theme-gradient-alternative: svg-gradient(to right, fade(@theme-bluish, 30%), fade(@theme-purple, 30%));
// @theme-gradient-white: svg-gradient(to right, fade(@theme-white, 0%) 0%, fade(@theme-white, 100%) 20%);

const CdnPath = `https://cdn.${getBaseUrl()}/web-commons/${Constants.WEB_COMMONS_VERSION}/flavors/${
    Constants.TENANT
}/assets/fonts`;

type RgbaColor = {
    color: keyof typeof Colors;
    opacity: number;
};

const hexToRgb = (hex: string): string => {
    const arrBuff = new ArrayBuffer(4);
    const vw = new DataView(arrBuff);
    vw.setUint32(0, parseInt(hex.replaceAll('#', ''), 16), false);
    const arrByte = new Uint8Array(arrBuff);

    return `${arrByte[1]},${arrByte[2]},${arrByte[3]}`;
};

/** The Breakpoints we use at exaring. This object is also used in the
 *  useMediaQuery() hook.
 */
export const MediaQuery = {
    xs: 320,
    sm: 768,
    md: 1024,
    lg: 1280,
    xl: 1680,
} as const;

export const { styled, css, globalCss, theme } = createStitches({
    theme: {
        colors: Colors,
        fonts: Fonts,
        fontSizes: {
            1: '12px',
            2: '14px',
            3: '18px',
            4: '28px',
            5: '36px',
            6: '20px',
            7: '24px',
            8: '16px',
        },
        fontWeights: {
            1: 300,
            2: 400,
            3: 600,
            4: 200,
        },
        space: {
            1: '15px',
            2: '20px',
            3: '25px',
            4: '30px',
            5: '5px',
            6: '8px',
            7: '10px',
        },
        radii: {
            1: '10px',
            2: '8px',
            3: '20px',
            4: '4px',
            5: '5px',
        },
        zIndices: {
            1: 1,
            modal: 101,
            header: 102,
            fullscreen: 103,
            searchResult: 104,
            toast: 105,
        },
    },
    media: {
        // migration guide:
        // @xs-lower: 320px; -> @xs
        // @s-lower: 768px; -> @sm
        // @m-lower: 1024px; -> @md
        // @l-lower: 1280px; -> @lg
        // @xl-lower: 1680px; -> @xl
        //
        // @large-screen-upper: 1024px;
        // @large-screen-lower: 768px;
        // @small-screen-upper: (@large-screen-lower - 1px);

        xs: `(min-width: ${MediaQuery.xs}px)`,
        sm: `(min-width: ${MediaQuery.sm}px)`,
        md: `(min-width: ${MediaQuery.md}px)`,
        lg: `(min-width: ${MediaQuery.lg}px)`,
        xl: `(min-width: ${MediaQuery.xl}px)`,
        'md-width-and-md-height': `(min-width: ${MediaQuery.md}px) and (min-height: ${MediaQuery.md}px)`,
        'lg-width-and-md-height': `(min-width: ${MediaQuery.lg}px) and (min-height: ${MediaQuery.md}px)`,
    },
    utils: {
        backgroundRgba: (value: RgbaColor) => {
            const rgb = hexToRgb(theme.colors[value.color].value); // get color by name

            return {
                background: `rgba(${rgb},${value.opacity})`,
            };
        },
        linearGradient: (value: string) => ({
            backgroundImage: `linear-gradient(${value})`,
        }),
        borderRadiusRight: (value: string) => ({
            borderTopRightRadius: value,
            borderBottomRightRadius: value,
        }),
        borderRadiusLeft: (value: string) => ({
            borderTopLeftRadius: value,
            borderBottomLeftRadius: value,
        }),
        borderRadiusTop: (value: string) => ({
            borderTopRightRadius: value,
            borderTopLeftRadius: value,
        }),
        borderRadiusBottom: (value: string) => ({
            borderBottomRightRadius: value,
            borderBottomLeftRadius: value,
        }),
    },
});

export const PulseLoadingIndicatorAnimation = keyframes({
    '0%': {
        backgroundColor: '$dark',
    },
    '50%': {
        backgroundColor: '$charcoalGrey',
    },
    '100%': {
        backgroundColor: '$dark',
    },
});

export const AppStyle = {
    ...normalize,
    'html, body': {
        margin: 0,
        height: '100%',
        width: '100%',
    },
    b: {
        fontWeight: '$3',
    },
    '@font-face': [
        {
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 300,
            src: `local("Open Sans Light"),
                local("OpenSans-Light"),
                url("${CdnPath}/OpenSans-Light.ttf") format("ttf"),
                url("${CdnPath}/OpenSans-Light.woff") format("woff"),
                url("${CdnPath}/OpenSans-Light.woff2") format("woff2")`,
        },
        {
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 400,
            src: `local("Open Sans"),
                local("OpenSans"),
                url("${CdnPath}/OpenSans.ttf") format("ttf"),
                url("${CdnPath}/OpenSans.woff") format("woff"),
                url("${CdnPath}/OpenSans.woff2") format("woff2")`,
        },
        {
            fontFamily: 'Open Sans',
            fontStyle: 'bold',
            fontWeight: 600,
            src: `local("Open Sans Semibold"),
                local("OpenSans-Semibold"),
                url("${CdnPath}/OpenSans-SemiBold.ttf") format("ttf"),
                url("${CdnPath}/OpenSans-SemiBold.woff") format("woff"),
                url("${CdnPath}/OpenSans-SemiBold.woff2") format("woff2")`,
        },
        {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 200,
            src: `local("Poppins ExtraLight"),
                local("PoppinsExtraLight"),
                url("${CdnPath}/Poppins-ExtraLight.ttf") format("ttf"),
                url("${CdnPath}/Poppins-ExtraLight.woff") format("woff"),
                url("${CdnPath}/Poppins-ExtraLight.woff2") format("woff2")`,
        },
        {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 300,
            src: `local("Poppins Light"),
                local("PoppinsLight"),
                url("${CdnPath}/Poppins-Light.ttf") format("ttf"),
                url("${CdnPath}/Poppins-Light.woff") format("woff"),
                url("${CdnPath}/Poppins-Light.woff2") format("woff2")`,
        },
        {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            src: `local("Poppins"),
                local("Poppins"),
                url("${CdnPath}/Poppins-Regular.ttf") format("ttf"),
                url("${CdnPath}/Poppins-Regular.woff") format("woff"),
                url("${CdnPath}/Poppins-Regular.woff2") format("woff2")`,
        },
        {
            fontFamily: 'Poppins',
            fontStyle: 'bold',
            fontWeight: 600,
            src: `local("Poppins Semibold"),
                local("PoppinsSemibold"),
                url("${CdnPath}/Poppins-SemiBold.ttf") format("ttf"),
                url("${CdnPath}/Poppins-SemiBold.woff") format("woff"),
                url("${CdnPath}/Poppins-SemiBold.woff2") format("woff2")`,
        },
    ],
} as const;

export const Globals = {
    headerHeight: '66px',
} as const;

export const GlobalStyle = globalCss(AppStyle);
