/* eslint-disable import/prefer-default-export */
import create from '@exaring/utils/data/schema/create';
import types from '@exaring/utils/data/schema/types';
import { isJsonContentType } from './helper';

/**
 * Set `Authorization` header to request object with current token of user store
 * @param  {Object<Request>} req
 * @param  {Object} config
 * @return {Object<Request>}
 */
const auth = async (req, { authToken }) => {
    if (req.authorize !== false && authToken) {
        req.headers.set(
            'Authorization',
            `Bearer ${typeof authToken === 'function' ? await authToken() : authToken}`,
        );
    }

    return req;
};

/**
 * Encodes load based on Content-Type header
 * @param  {Object<Request>} req
 * @param  {Object} config
 * @return {Object<Request>}
 */
const jsonEncoder = (req) => {
    const type = req.headers.get('Content-Type');

    if (isJsonContentType(type)) {
        req.body = JSON.stringify(req.body);
    }

    return req;
};

const dataValidator = (req, cfg, dataTypes = types) => {
    const contentType = req.headers.get('content-type');

    const schema = dataTypes[contentType];
    const { body } = req;

    // validate if needed
    if (body && schema) {
        // data-type list
        if (Array.isArray(body)) {
            req.body = body.map((data) => create(data, schema));
        }
        // data-type plain
        else {
            req.body = create(body, schema);
        }
    }

    return req;
};

export { auth, jsonEncoder, dataValidator };
