/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { date, iso8610Custom } from '../../../date';
import { typeValidators } from '../validator';

const createValidators = {
    ...typeValidators,
    date: (val) => {
        // reverse date transformation
        if (typeof val !== 'number') {
            throw Error('Date type has to be a timestamp');
        }

        return date(val).format(iso8610Custom);
    },
};

/**
 * This function does not interpolate any data attributes further more
 * it throws errors missing required fields.
 * @param  {Object}  data
 * @param  {Object}  schema
 * @param  {Array}  validators
 * @param  {Boolean} root indicator to always assign root data
 * @throws {Error} If required scheme attribute was not found in data attributes
 * @return {Object}
 */
const create = (data = {}, schema, validators = createValidators) => {
    const validatedData = {};
    const recursiveTypes = ['object']; // ignore arrays for now
    const schemaKeys = Object.keys(schema);

    schemaKeys.forEach((key) => {
        const schemaEntry = schema[key];
        let value = data[key];
        let setVal = false;

        if (schemaEntry) {
            const { type, default: _default, required, ...addtionalData } = schemaEntry;

            const _validator = validators[type];

            const attrNotGivenInData = !Object.prototype.hasOwnProperty.call(data, key);

            if (required && attrNotGivenInData) {
                throw new Error(`Required attribute \`${key}\` was not set`);
            } else if (attrNotGivenInData) {
                // no further processing required as attr is not set in data at all
                return;
            }

            setVal = !!(required || value !== undefined);

            if (recursiveTypes.includes(type) && _validator) {
                // recursive walk through iteratable tree
                value = create(value, _default, validators);
            } else if (_validator) {
                value = _validator(value, _default, addtionalData);
            }
        }

        if (setVal) {
            validatedData[key] = value;
        }
    });

    return validatedData;
};

export default create;
