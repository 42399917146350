/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { route } from 'preact-router';
import { strj, rootPath, isEmptyString } from '@exaring/utils';
import { tenantConstants } from './tenantConstants';
import { WaiputhekContext } from './types/WaiputhekContext';
import { WaiputhekCrumb } from './types/WaiputhekCrumb';
// eslint-disable-next-line import/no-cycle
import { notificationsStore } from './state/Store';

export const Routes = {
    LIVE_TV_PAGE: `/`,
    LIVE_TV_PATH: `/:channelId?`,
    VOD_PLAYOUT_PAGE: `/vod`,
    VOD_PLAYOUT_PATH: `/vod/:channelId/:programId`,
    EPG_PAGE: `/programm`,
    EPG_PATH: `/programm/:channelId?/:programId?`,
    RECORDING_PLAYOUT_PAGE: '/aufnahme',
    RECORDING_PLAYOUT_PATH: '/aufnahme/:recordingId',
    RECORDING_PAGE: `/aufnahmen`,
    RECORDING_PAGE_GROUP: `/aufnahmen/group`,
    RECORDING_PAGE_PROGRAM: `/aufnahmen/program`,
    RECORDING_SCHEDULED_PAGE: `/aufnahmen/geplant`,
    RECORDING_PATH: `/aufnahmen/:path*`,
    WAIPUTHEK_PAGE: tenantConstants.WAIPUTHEK_BASE_PATH,
    WAIPUTHEK_PATH: `${tenantConstants.WAIPUTHEK_BASE_PATH}/:breadcrumb*`,
    CHANNELSORT_PATH: `/channelsort`, // channelsort is deprecated and replace by channelconfig
    CHANNELCONFIG_PATH: `/channelconfig`,
    CHANNELSORT_NEW_PATH: `/channelsortNew`, // TODO: figure out better name before merging
} as const;

const noneLiveRoutes = Object.keys(Routes).filter(
    (key) => key.endsWith('_PAGE') && !key.startsWith('LIVE'),
);

export const routeTo = (_route: string, replace = false) => {
    notificationsStore().reset();
    route(_route, !!replace);
};

export const isLiveTVPathActive = () =>
    !noneLiveRoutes.includes(rootPath(window.location.pathname));

export const buildRouteWithEpgParams = (
    basePath: string,
    fallbackBasePath: string,
    channelId?: string | null,
    programId?: string | null,
    autoplay = false,
) => {
    let destinationRoute = basePath;

    if (typeof channelId === 'string') {
        destinationRoute = strj(destinationRoute, channelId, '/');

        if (typeof programId === 'string') {
            destinationRoute = strj(destinationRoute, programId, '/');

            if (autoplay) {
                destinationRoute = strj(destinationRoute, 'autoplay', '/');
            }
        }
    } else {
        destinationRoute = fallbackBasePath;
    }

    return destinationRoute;
};

/*
 * This function handles silent degradation from details to epg overview
 * page in case of missing required param values.
 *
 * @param channelId {string} optional
 * @param programId {string} optional
 * @param autoplay {boolean} optional
 * @param replace {boolean} optional
 */
export const routeToEpg = (
    channelId?: string,
    programId?: string,
    autoplay = false,
    replace = false,
) => {
    routeTo(
        buildRouteWithEpgParams(
            /* basePath */ Routes.EPG_PAGE,
            /* fallbackBasePath */ Routes.EPG_PAGE,
            channelId,
            programId,
            autoplay,
        ),
        replace,
    );
};

/*
 * @param channelId {string} optional
 * @param programId {string} optional
 */
export const routeToVoD = (
    channelId?: string | null,
    programId?: string | null,
    replace = true,
) => {
    routeTo(
        buildRouteWithEpgParams(
            /* basePath */ Routes.VOD_PLAYOUT_PAGE,
            /* fallbackBasePath */ Routes.EPG_PAGE,
            channelId,
            programId,
        ),
        replace,
    );
};

/*
 * @param channelId {string} optional
 */
export const routeToLive = (channelId: string) => {
    routeTo(strj(Routes.LIVE_TV_PAGE, channelId, ''), true);
};

//
// Waiputhek routing
//

export const WaiputhekCrumbKey = {
    CONTENT: 'content:',
    CHANNEL: 'channel:',
    MODULE: 'module:',
    CATEGORY: 'category:',
    FOCUS: 'focus',
} as const;

const createCrumb = (key: string, id: string): WaiputhekCrumb => ({
    key,
    id,
});

const crumbsToPath = (crumbs: WaiputhekCrumb[], appendFocus?: boolean): string => {
    const mapped = crumbs.map((c) => `${c.key}${c.id}`);
    if (appendFocus) {
        mapped.push(WaiputhekCrumbKey.FOCUS);
    }
    return mapped.join('/');
};

export const replaceHistoryState = (crumbs: WaiputhekCrumb[]) => {
    window.history.replaceState(
        window.history.state,
        '',
        waiputhekRouteByPath(waiputhekBackPathByCrumbs(crumbs)),
    );
};

const routeToWaiputhekByCrumbs = (
    crumbs?: WaiputhekCrumb[],
    backPath?: string,
    autoplay = false,
    replace = false,
): void => {
    routeToWaiputhekByPath(crumbs ? crumbsToPath(crumbs) : undefined, backPath, autoplay, replace);
};

const getCrumbsOfChannel = (crumbs: WaiputhekCrumb[]): WaiputhekCrumb[] | undefined => {
    const index = [...crumbs].reverse().findIndex((c) => c.key === WaiputhekCrumbKey.CHANNEL);
    return index > -1 ? crumbs.slice(0, crumbs.length - index) : undefined;
};

const getCurrentCrumbs = (
    moduleId?: string,
    categoryId?: string,
    crumbs?: WaiputhekCrumb[],
): WaiputhekCrumb[] => {
    const currentCrumbs = crumbs ? getCrumbsOfChannel(crumbs) : undefined;

    if (!currentCrumbs && moduleId && !isEmptyString(moduleId)) {
        return [createCrumb(WaiputhekCrumbKey.MODULE, moduleId)];
    }

    if (currentCrumbs && categoryId && !isEmptyString(categoryId)) {
        return [...currentCrumbs, createCrumb(WaiputhekCrumbKey.CATEGORY, categoryId)];
    }

    return currentCrumbs || [];
};

export const crumbsOfWaiputhekDetails = (
    contentId?: string,
    moduleId?: string,
    categoryId?: string,
    crumbs?: WaiputhekCrumb[],
) => {
    const currentCrumbs = getCurrentCrumbs(moduleId, categoryId, crumbs);
    if (contentId) {
        currentCrumbs.push(createCrumb(WaiputhekCrumbKey.CONTENT, contentId));
    }
    return currentCrumbs;
};

const crumbsOfWaiputhekMediathek = (
    channelId?: string,
    moduleId?: string,
    categoryId?: string,
    crumbs?: WaiputhekCrumb[],
) => {
    const currentCrumbs = getCurrentCrumbs(moduleId, categoryId, crumbs);
    if (channelId && !isEmptyString(channelId)) {
        currentCrumbs.push(createCrumb(WaiputhekCrumbKey.CHANNEL, channelId));

        if (categoryId && !isEmptyString(categoryId)) {
            currentCrumbs.push(createCrumb(WaiputhekCrumbKey.CATEGORY, categoryId));
        }
    }
    return currentCrumbs;
};

export const routeToWaiputhekDetails = (
    contentId?: string,
    moduleId?: string,
    categoryId?: string,
    crumbs?: WaiputhekCrumb[],
    backPath?: string,
    autoplay = false,
    replace = false,
    replaceHistory = true,
): void => {
    const targetCrumbs = crumbsOfWaiputhekDetails(contentId, moduleId, categoryId, crumbs);

    if (replaceHistory) {
        replaceHistoryState(targetCrumbs);
    }

    routeToWaiputhekByCrumbs(targetCrumbs, backPath, autoplay, replace);
};

export const routeToWaiputhekMediathek = (
    channelId?: string,
    categoryId?: string,
    moduleId?: string,
    crumbs?: WaiputhekCrumb[],
    backPath?: string,
    replace = false,
    replaceHistory = true,
): void => {
    const targetCrumbs = crumbsOfWaiputhekMediathek(channelId, moduleId, categoryId, crumbs);

    if (replaceHistory) {
        replaceHistoryState(targetCrumbs);
    }

    routeToWaiputhekByCrumbs(targetCrumbs, backPath, false, replace);
};

export const waiputhekRouteByPath = (path?: string) => {
    return strj(Routes.WAIPUTHEK_PAGE, path, '/');
};

export const routeToWaiputhekByPath = (
    path?: string,
    backPath?: string,
    autoplay = false,
    replace = true,
) => {
    const waiputhekRoute = waiputhekRouteByPath(path);
    const targetRoute = autoplay ? strj(waiputhekRoute, 'autoplay', '/') : waiputhekRoute;
    routeTo(
        backPath ? strj(targetRoute, `back=${encodeURIComponent(backPath)}`, '?') : targetRoute,
        replace,
    );
};

export const waiputhekBackPathByCrumbs = (crumbs: WaiputhekCrumb[]): string | undefined => {
    if (crumbs.length < 1) {
        return undefined;
    }

    const [last, secondLast, thirdLast] = crumbs.slice(-3).reverse();

    if (
        // .../module/content -> .../module/content/focus
        (last?.key === WaiputhekCrumbKey.CONTENT && secondLast?.key === WaiputhekCrumbKey.MODULE) ||
        // .../channel/category -> .../channel/category/focus
        (last?.key === WaiputhekCrumbKey.CATEGORY &&
            secondLast?.key === WaiputhekCrumbKey.CHANNEL) ||
        // .../channel/category/content -> .../channel/category/content/focus
        (last?.key === WaiputhekCrumbKey.CONTENT &&
            secondLast?.key === WaiputhekCrumbKey.CATEGORY &&
            thirdLast?.key === WaiputhekCrumbKey.CHANNEL)
    ) {
        return crumbsToPath(crumbs, true);
    }
    // .../channel/content -> .../channel
    if (last?.key === WaiputhekCrumbKey.CONTENT && secondLast?.key === WaiputhekCrumbKey.CHANNEL) {
        const channelCrumbs = getCrumbsOfChannel(crumbs);
        return channelCrumbs ? crumbsToPath(channelCrumbs) : undefined;
    }

    // find last channel in breadcrumb
    const reversedCrumbs = [...crumbs].reverse();
    const lastChannelIndex = reversedCrumbs.findIndex((c) => c.key === WaiputhekCrumbKey.CHANNEL);

    if (lastChannelIndex > -1) {
        // .../module/channel/... -> .../module/channel/focus
        if (reversedCrumbs[lastChannelIndex + 1]?.key === WaiputhekCrumbKey.MODULE) {
            return crumbsToPath(crumbs.slice(0, crumbs.length - lastChannelIndex), true);
        }

        // .../channel/channel or .../channel/channel/... -> .../channel/category
        const secondLastChannelIndex = reversedCrumbs
            .slice(lastChannelIndex + 1)
            .findIndex((c) => c.key === WaiputhekCrumbKey.CHANNEL);
        const rest = crumbs.slice(0, crumbs.length - lastChannelIndex - 1);
        const lastChannelCrumb = reversedCrumbs[lastChannelIndex];
        if (secondLastChannelIndex > -1 && lastChannelCrumb) {
            rest.push({
                key: WaiputhekCrumbKey.CATEGORY,
                id: lastChannelCrumb.id,
            });
        }

        // .../???/channel/... -> .../???
        return rest.length > 0 ? crumbsToPath(rest) : undefined;
    }

    return undefined;
};

const getCrumbId = (crumb: string, key?: string) => {
    const id = key ? crumb.substring(key.length) : undefined;
    return isEmptyString(id) ? undefined : id;
};

const evaluateCrumb = (crumb: string): WaiputhekCrumb | undefined => {
    const key = Object.values(WaiputhekCrumbKey).find((v) => crumb.startsWith(v));

    if (!key) {
        return undefined;
    }

    if (key === WaiputhekCrumbKey.FOCUS) {
        return { key, id: '' };
    }

    const id = getCrumbId(crumb, key);
    if (!id) {
        return undefined;
    }

    return { key, id };
};

const findChannelId = (crumbs: WaiputhekCrumb[]) =>
    [...crumbs].reverse().find((c) => c.key === WaiputhekCrumbKey.CHANNEL)?.id;

export const splitWaiputhekPath = (path?: string): WaiputhekCrumb[] => {
    if (path === undefined || isEmptyString(path)) {
        return [];
    }

    return path.split('/').reduce<WaiputhekCrumb[]>((reduced, crumb) => {
        const evaluated = isEmptyString(crumb) ? undefined : evaluateCrumb(crumb);
        return evaluated ? [...reduced, evaluated] : reduced;
    }, []);
};

export const waiputhekContextByCrumbs = (crumbs: WaiputhekCrumb[]): WaiputhekContext => {
    if (crumbs.length < 1) {
        return { type: 'highlights' };
    }

    const [last, secondLast, thirdLast, fourthLast] = crumbs.slice(-4).reverse();

    // .../content
    if (last?.key === WaiputhekCrumbKey.CONTENT) {
        return {
            type: 'content',
            contentId: last.id,
            channelId: findChannelId(crumbs),
        };
    }
    // .../channel
    if (last?.key === WaiputhekCrumbKey.CHANNEL) {
        return { type: 'channel', channelId: last.id };
    }
    // .../module
    if (last?.key === WaiputhekCrumbKey.MODULE) {
        return {
            type: 'highlights',
            focus: { sectionId: last.id },
        };
    }
    // .../channel/category
    if (last?.key === WaiputhekCrumbKey.CATEGORY && secondLast?.key === WaiputhekCrumbKey.CHANNEL) {
        return {
            type: 'channel',
            channelId: secondLast.id,
            focus: { sectionId: last.id },
        };
    }
    // .../focus
    if (last?.key === WaiputhekCrumbKey.FOCUS) {
        // .../module/channel/category/focus
        if (
            secondLast?.key === WaiputhekCrumbKey.CATEGORY &&
            thirdLast?.key === WaiputhekCrumbKey.CHANNEL &&
            fourthLast?.key === WaiputhekCrumbKey.MODULE
        ) {
            return {
                type: 'highlights',
                focus: {
                    categoryId: secondLast.id,
                    channelId: thirdLast.id,
                    sectionId: fourthLast.id,
                },
            };
        }
        // .../module/channel/focus
        if (
            secondLast?.key === WaiputhekCrumbKey.CHANNEL &&
            thirdLast?.key === WaiputhekCrumbKey.MODULE
        ) {
            return {
                type: 'highlights',
                focus: { channelId: secondLast.id, sectionId: thirdLast.id },
            };
        }
        // .../module/content/focus
        if (
            secondLast?.key === WaiputhekCrumbKey.CONTENT &&
            thirdLast?.key === WaiputhekCrumbKey.MODULE
        ) {
            return {
                type: 'highlights',
                focus: { contentId: secondLast.id, sectionId: thirdLast.id },
            };
        }
        // .../channel/category/content/focus
        if (
            secondLast?.key === WaiputhekCrumbKey.CONTENT &&
            thirdLast?.key === WaiputhekCrumbKey.CATEGORY &&
            fourthLast?.key === WaiputhekCrumbKey.CHANNEL
        ) {
            return {
                type: 'channel',
                channelId: fourthLast.id,
                focus: { contentId: secondLast.id, sectionId: thirdLast.id },
            };
        }
        // .../channel/content/focus, fallback if category missing
        if (
            secondLast?.key === WaiputhekCrumbKey.CONTENT &&
            thirdLast?.key === WaiputhekCrumbKey.CHANNEL
        ) {
            return { type: 'channel', channelId: thirdLast.id };
        }
    }

    return { type: 'highlights' };
};
