import { iso8610Custom, date, urlWithParams } from '@exaring/utils';
import constants from '../constants';
import { get } from '../request';

/**
 * @param {Number<unix-timestamp>} startTime
 * @param {Number<unix-timestamp>} stopTime
 * @param {Boolean} includeRunningAtStartTime
 * @return {Object}
 */
export const programs = (startTime, stopTime, includeRunningAtStartTime = true) => {
    return get(
        urlWithParams(`${constants.EPG_CHANNEL_BASE_URL}/api/programs`, {
            ...(startTime && { startTime: date(startTime).format(iso8610Custom) }),
            ...(stopTime && { stopTime: date(stopTime).format(iso8610Custom) }),
            includeRunningAtStartTime,
        }),
        { Accept: 'application/vnd.waipu.epg-channels-and-programs-v1+json' },
    );
};

/**
 * @param {Number<unix-timestamp>} startTime
 * @param {Number<unix-timestamp>} stopTime
 * @param {Boolean} includeRunningAtStartTime
 * @param {String} query
 * @param {Number} maxResults
 * @return {Object}
 */
export const programsQuery = (
    query = '',
    startTime,
    stopTime,
    includeRunningAtStartTime,
    maxResults = 50,
) => {
    const req = programs(startTime, stopTime, includeRunningAtStartTime);

    req.headers.set('Accept', 'application/vnd.waipu.epg-search-result-programs-v2+json');
    req.url = urlWithParams(req.url, {
        query: query || '',
        maxResults,
    });

    return req;
};

/**
 * @param {String} channelId
 * @return {Object}
 */
export const programCurrent = (channelId) => {
    if (!channelId) {
        throw new Error('missing parameter channelId');
    }

    return get(`${constants.EPG_CHANNEL_BASE_URL}/api/channels/${channelId}/programs/current`, {
        Accept: 'application/vnd.waipu.epg-program-v1+json',
    });
};

/**
 * @param {String} channelId
 * @param {String} programId
 * @return {Object}
 */
export const programDetails = (channelId, programId) => {
    return get(
        `${constants.EPG_CHANNEL_BASE_URL}/api/channels/${channelId}/programs/${programId}`,
        { Accept: 'application/vnd.waipu.epg-program-v1+json' },
    );
};

// @todo document other end points even if they are not implemented yet
