import { useRef, useEffect } from 'preact/hooks';

type Timer = ReturnType<typeof setTimeout>;

export const useDebounce = <T extends (...args: any[]) => void>(func: T, delay = 1000) => {
    const timer = useRef<Timer>();

    useEffect(() => {
        return () => {
            if (!timer.current) {
                return;
            }
            clearTimeout(timer.current);
        };
    }, []);

    const debouncedFunction = ((...args) => {
        const newTimer = setTimeout(() => {
            func(...args);
        }, delay);
        clearTimeout(timer.current);
        timer.current = newTimer;
    }) as T;

    return debouncedFunction;
};
