import { h, FunctionComponent } from 'preact';
import { RecordingDiscoveryContent } from '@exaring/networking/types/Recording/RecordingDiscovery';
import { styled } from '@exaring/ui/components-styled/theme';
import { TileScroller } from '../waiputhek/TileScroller';
import { Section } from '../waiputhek/Section';
import { useRecordingContentDiscovery } from './hooks/useRecordingContentDiscovery';
import { contentDiscoveryTileMapper } from './DiscoveryTile/DiscoveryTile';
import { didSucceed } from '../../state/utils/RemoteData';
import { Separator } from './Separator';

const SectionContent = styled('div', {
    position: 'relative',
    marginTop: '$$sectionGutter',
});

export const DiscoveryScroller: FunctionComponent = () => {
    const { data } = useRecordingContentDiscovery();
    const discoveryData = didSucceed(data) ? data.value : undefined;

    if (!discoveryData?.contents.length) {
        // eslint-disable-next-line no-restricted-syntax
        return <div style={{ height: '40px' }} />;
    }

    return (
        <SectionContent>
            <Separator />
            <Section
                id="top-10-recordings"
                headlineCss={{ marginLeft: 0 }}
                css={{ marginTop: '$$sectionGutter' }}
                title={discoveryData?.title ?? ''}
            >
                <TileScroller<RecordingDiscoveryContent>
                    css={{
                        margin: 'calc($$gutterWidth / 2) -$$containerIndent $$gutterWidth 0',
                        padding: '$$gutterWidth $$containerIndent 0 0',
                        marginLeft: '-$$containerIndent',
                        paddingLeft: '$$containerIndent',
                    }}
                    data={discoveryData?.contents ?? []}
                    dataIndex="url"
                    mapTile={contentDiscoveryTileMapper}
                />
            </Section>
        </SectionContent>
    );
};
