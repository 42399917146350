import { mostPopularRecordings, recordings, programs, vod } from '../requests/content-discovery';
import { requestHookChain } from '../networking.v2';

const api = {
    mostPopularRecordings: requestHookChain(mostPopularRecordings),
    recordings: requestHookChain(recordings),
    programs: requestHookChain(programs),
    vod: requestHookChain(vod),
};

export default api;
