import { h } from 'preact';

export default ({ className, title, onClick, dataTestId = '' }) => {
    return (
        <span
            {...(dataTestId ? { 'data-testid': dataTestId } : {})}
            className={`icon ${className || ''}`}
            title={title}
            onClick={onClick}
        />
    );
};
