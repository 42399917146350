import { h, FunctionComponent } from 'preact';
import { CSS } from '@stitches/react';
import { styled } from '@exaring/ui/components-styled/theme';
import { Typography } from '@exaring/ui/components-styled/Typography/Typography';

const Subtitle = styled('span', {
    display: 'inline',
    paddingLeft: '10px',
    color: '$white',
    fontSize: '$2',
    opacity: 0.25,
});

export const Headline: FunctionComponent<{
    id?: string;
    css?: CSS;
    className?: string;
    subtitle?: string;
}> = ({ id, css, className, subtitle, children }) => {
    return (
        <Typography
            id={id}
            variant="h2"
            css={{ marginLeft: '65px', marginBottom: 0, fontSize: '20px', ...css }}
            className={className}
        >
            {children}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Typography>
    );
};
