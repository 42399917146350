/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { memo } from 'preact/compat';
import { connect } from 'unistore/preact';
import actions from '../../actions/player';

const Player = ({ playerMount }) => {
    useEffect(() => {
        playerMount(true, /* rollbackState */ true);

        return () => {
            playerMount && playerMount(false);
        };
    }, [playerMount]);

    return (
        <div id="player-parent" className="player">
            <video id="player_video" disablePictureInPicture muted playsInline />
        </div>
    );
};

export default connect(() => {}, actions)(
    // prevent all rerenders
    memo(Player, () => false),
);
