/*
 *  Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, FunctionComponent } from 'preact';
import { Link } from 'preact-router';
import { styled } from '@exaring/ui/components-styled/theme';
import { GridContainer } from '@exaring/ui/components-styled/Grid';
import { Typography } from '@exaring/ui/components-styled/Typography';
import RecCloudIcon from '@exaring/assets/icons/empty-state-rec-cloud-icon.svg';

import { assetUrl } from '../../../helper';
import { Button } from '../../../../../../packages/ui/components-styled/Button';
import { HeroContainer, HeroContent, HeroImage, TextWrapper, Title } from './TileHeroShared';
import { Routes } from '../../../routes';

const GridContentContainer = styled(GridContainer, {
    top: '0px',
    left: '0px',
    right: '0px',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    position: 'absolute',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    '@md': {
        gap: '40px',
        flexDirection: 'row',
    },

    variants: {
        isEmpty: {
            true: {
                backgroundImage:
                    'linear-gradient(to bottom, rgba(24, 22, 35,0), rgba(24, 22, 35,1) 55%, #181623)',
                '@md': {
                    linearGradient:
                        '90deg, rgba(24, 22, 35,0) 18%, rgba(24, 22, 35,1) 50%, #181623',
                },
            },
        },
        isUpgrade: {
            true: {
                backgroundImage:
                    'linear-gradient(to bottom, rgba(15, 44, 75, 0), rgba(15, 44, 75, 0) 23%, #0f2c4b 50%)',
                '@md': {
                    linearGradient: '90deg, rgba(14,41,75,0) 38%, rgba(14,41,75,1) 50%',
                },
            },
        },
    },
});

const HeroTextWrapper = styled(TextWrapper, {
    textAlign: 'center',

    '@md': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
});

const HeroText = styled(Typography, {
    maxWidth: '840px',
    fontSize: '16px',
    marginRight: '$4',
    marginLeft: '$4',
    '> a': {
        color: '$white',
        textDecoration: 'underline',
        display: 'block',
    },
    '@md': {
        marginBottom: '$7',
        marginRight: '0px',
        marginLeft: '0px',
    },
});

const HeroButtonWrapper = styled('div', {
    textAlign: 'center',
});

const HeroButton = styled(Button, {
    width: '100%',
    fontSize: '16px',
    maxWidth: '240px',
    height: '48px',
    paddingLeft: '0px',
    paddingRight: '0px',
    textAlign: 'center',
    '&:hover': {
        backgroundColor: '$darkBlue',
        borderColor: '$darkBlue',
    },
});

const ButtonLink = styled(Button, {
    textDecoration: 'underline',
    border: 'none',
    background: 'none',
    margin: 'unset',
    padding: 'unset',
    width: 'unset',
});

const Price = styled(Title, {
    fontSize: '16px',
    fontWeight: '600',
    fontFamily: '$secondary',
    margin: 0,

    '@sm': {
        fontSize: '36px',
    },
});

const PriceInfo = styled(Title, {
    fontSize: '12px',
    opacity: '.3',
    '@md': {
        marginBottom: '$7',
    },
    '@lg': {
        marginBottom: '$2',
    },
});

const PriceContainer = styled('div', {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'baseline',
});

const HeroContentStyles = {
    width: '100%',
    '@md': {
        width: '50%',
    },
};

const TitleStyle = {
    textWrap: 'wrap',
    '@xs': {
        fontSize: '$5',
    },
    '@sm': {
        fontSize: '$5',
    },
    '@md': {
        fontSize: '$6',
    },
    '@lg': {
        fontSize: '$5',
    },
};

const LinkContainer = styled('div', {
    display: 'inline-block',
    textDecoration: 'underline',
});

const PlusContainer = styled('strong', {
    fontSize: '13px',
});

const WaipuContainer = styled('strong', {
    fontFamily: '$secondary',
});

const EmptyContentWrapperStyle = {
    marginTop: '-30px',
    display: 'flex',
};

const EmptyContentWrapper = styled('div', {
    height: 'fit-content',
    margin: 'auto',
});

export const TileHeroUpgradeContent: FunctionComponent<{
    onClick?: (event: PointerEvent) => void;
}> = ({ onClick }) => (
    <HeroTextWrapper>
        <Title css={TitleStyle}>
            Jetzt <strong>Aufnahmefunktion</strong> testen!
        </Title>
        <HeroText variant="body2">
            <WaipuContainer>waipu.tv</WaipuContainer> Perfect <PlusContainer>Plus</PlusContainer>{' '}
            inkl. 100 Aufnahmespeicher - jetzt 1 Monat kostenlos testen.{' '}
            <a href="https://www.waipu.tv/angebote/" target="_blank" rel="noreferrer">
                Mehr erfahren
            </a>
        </HeroText>
        <PriceContainer>
            <Price css={{ marginLeft: '10px' }}>0,00 €</Price>
        </PriceContainer>

        <PriceInfo variant="body2">mtl., ab dem 2. Monat 12,99 €, mtl. kündbar</PriceInfo>

        <HeroButtonWrapper>
            <HeroButton onClick={onClick}>1 Monat kostenlos testen</HeroButton>
        </HeroButtonWrapper>

        <HeroText
            css={{
                display: 'inherit',
                marginBottom: '0',
                '@md': {
                    display: 'none',
                },
            }}
            variant="body2"
        >
            <a href="https://www.waipu.tv/angebote/" target="_blank" rel="noreferrer">
                Pakete vergleichen
            </a>
        </HeroText>
    </HeroTextWrapper>
);

const RecCloudIconStyled = styled(RecCloudIcon, {
    '@sm': {
        marginBottom: '15px',
        marginTop: '48px',
    },
    '@md': {
        marginBottom: '30px',
        marginTop: '0',
    },
});

export const TileHeroEmptyContent: FunctionComponent<{
    onClick: (event: PointerEvent) => void;
}> = ({ onClick }) => {
    // it is a workaround as srolling with anchor does not work for some reason
    const handleTopTenClick = () => document.getElementById('top-10-recordings')?.scrollIntoView();

    return (
        <HeroTextWrapper css={EmptyContentWrapperStyle}>
            <EmptyContentWrapper>
                <RecCloudIconStyled viewBoxWidth="108" viewBoxHeight="48" />
                <Title css={TitleStyle}>
                    <strong>Jederzeit aufnehmen</strong>, jederzeit ansehen.
                </Title>
                <HeroText
                    variant="body2"
                    css={{
                        '@sm': {
                            maxWidth: '606px',
                        },
                        '@md': {
                            marginRight: '0',
                            marginLeft: '0',
                            maxWidth: '622px',
                        },
                        '@lg': {
                            maxWidth: '638px',
                        },
                    }}
                >
                    Nutzen Sie Ihren persönlichen Aufnahmespeicher. Über den Cloud-Speicher haben
                    Sie Zugriff auf Ihre Aufnahmen. Einfach unten in den{' '}
                    <LinkContainer>
                        <Link
                            onClick={handleTopTenClick}
                            href={`${Routes.RECORDING_PAGE}#top-10-recordings`}
                        >
                            Top 10
                        </Link>
                    </LinkContainer>
                    {` oder  über `}
                    <ButtonLink title={Routes.EPG_PAGE} onClick={onClick}>
                        {' '}
                        Programm
                    </ButtonLink>{' '}
                    Ihre erste Sendung aufnehmen.
                </HeroText>
            </EmptyContentWrapper>
        </HeroTextWrapper>
    );
};

export const TileHeroEmptyRoom: FunctionComponent<{
    imgUrl?: string;
    isEmpty?: boolean;
    isUpgrade?: boolean;
    imageContent?: JSX.Element[] | JSX.Element;
}> = ({ isEmpty = false, isUpgrade = false, imgUrl, children, imageContent }) => {
    return (
        <HeroContainer
            css={{
                minHeight: '680px',
                '@sm': {
                    minHeight: '680px',
                    maxHeight: '720px',
                },
                '@md': {
                    minHeight: '500px',
                    maxHeight: '720px',
                },
            }}
        >
            <GridContainer>
                <HeroContent md={6} css={HeroContentStyles}>
                    <HeroImage
                        src={imgUrl}
                        srcFallback={assetUrl('background-waipu-gradient.png')}
                    />
                </HeroContent>
            </GridContainer>
            <GridContentContainer isEmpty={isEmpty} isUpgrade={isUpgrade}>
                <HeroContent md={6} css={{ ...HeroContentStyles, sm: { height: '40%' } }}>
                    <GridContainer
                        justifyContent="center"
                        alignItems="center"
                        css={{
                            height: '100%',
                            padding: '$$gutterWidth',
                            overflowY: 'auto',
                        }}
                    >
                        {imageContent}
                    </GridContainer>
                </HeroContent>
                <HeroContent md={6} css={{ ...HeroContentStyles, sm: { height: '60%' } }}>
                    <GridContainer
                        justifyContent="center"
                        css={{
                            height: '100%',
                            padding: '30px 20px',
                            overflowY: 'auto',
                            alignItems: 'start',
                            '@md': {
                                padding: '13px 0px 0',
                            },
                            '@lg': {
                                padding: '30px 0px 0',
                            },
                        }}
                    >
                        {children}
                    </GridContainer>
                </HeroContent>
            </GridContentContainer>
        </HeroContainer>
    );
};
