import { Recording } from '@exaring/networking/types/Recording/Recording';
import { isNew, isPlayableRecording, isScheduledRecording } from './hooks/filter';

export enum FilterOption {
    All = 'all',
    New = 'new',
    Seen = 'seen',
    Scheduled = 'scheduled',
    Continue = 'continue',
}

interface FilterSelectOption {
    label: string;
    addSeparatorAfter?: boolean;
    disabled?: boolean;
    value: FilterOption;
}

export const filterSelectOptions: FilterSelectOption[] = [
    { label: 'Verfügbar', value: FilterOption.All },
    { label: 'Weiterschauen', value: FilterOption.Continue },
    { label: 'Nicht gesehen', value: FilterOption.New },
    { label: 'Bereits gesehen', value: FilterOption.Seen },
    { label: 'Geplant', value: FilterOption.Scheduled },
];

export const matchAllFilter = (recording: Recording) => isPlayableRecording(recording);
export const matchScheduledFilter = (recording: Recording) => isScheduledRecording(recording);
export const matchContinueFilter = (recording: Recording) =>
    !recording.isNew && !recording.fullyWatched;
export const matchSeenFilter = (recording: Recording) =>
    !recording.isNew && !!recording.fullyWatched;
export const matchNewFilter = (recording: Recording) => isNew(recording);

export const filter = (activeFilter: FilterOption) => (recording: Recording) => {
    if (activeFilter === FilterOption.All) {
        return matchAllFilter(recording);
    }

    if (activeFilter === FilterOption.Scheduled) {
        return matchScheduledFilter(recording);
    }

    if (activeFilter === FilterOption.Continue) {
        return matchContinueFilter(recording);
    }

    if (activeFilter === FilterOption.Seen) {
        return matchSeenFilter(recording);
    }

    if (activeFilter === FilterOption.New) {
        return matchNewFilter(recording);
    }

    return false; // filter out all not matching (status=UNKNOWN)
};

export const seasonFilter = (season: string) => (recording: Recording) => {
    if (season === '0') {
        // do not filter out any recording season on "all" filter
        return true;
    }

    return recording.season === season;
};
