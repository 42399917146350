import ctime from 'ctimejs';
import { safeBoolean } from '../../helper';

import { DeviceTokenV1 } from './vnd.dc.device-token-v1+json';
import { RecordingV2 } from './recording';
import { SupNewTVResponseV1, SupRegularResponseV1 } from './sup';
import { ProgramV1, ProgramV2 } from './program';

export const stringDataValidator = (val, defaultVal = '') => {
    return typeof val === 'string' ? val : defaultVal;
};

export const enumDataValidator = (val, defaultVal = '', additionalData) => {
    const { enum: data } = additionalData;
    const isValid = data && Array.isArray(data) && data.includes(val);
    return isValid ? val : defaultVal;
};

export const numberDataValidator = (val, defaultVal) => {
    const _value = Number(val);

    return Number.isNaN(_value) || !val ? defaultVal : _value;
};

export const booleanDataValidator = (val, defaultVal = false) => {
    return safeBoolean(val) || defaultVal;
};

export const dateDataValidator = (val, defaultVal) => {
    // tested: https://regex101.com/r/fxhKen/1
    const datePattern =
        /^([+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24:?00)([.,]\d+(?!:))?)?(\17[0-5]\d([.,]\d+)?)?([zZ]|([+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;

    const isDateString = !!(typeof val === 'string' && val.match(datePattern)?.[0]);

    return isDateString ? ctime(val).unix() : defaultVal;
};

export const urlDataValidator = (val, defaultVal = '') => {
    const _urlPattern =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    const _isValid = typeof val === 'string' && val.match(_urlPattern);

    return _isValid ? val : defaultVal;
};

export const arrayDataValidator = (val, defaultVal = []) => {
    return Array.isArray(val) ? val : defaultVal;
};

export const objectDataValidator = (val, defaultVal = {}) => {
    const isPlainObject = Object.prototype.toString.call(val).includes('Object');
    return isPlainObject ? val : defaultVal;
};

export const diffList = (array1, array2) => {
    return array1.filter((entry) => !array2.includes(entry));
};

// eslint-disable-next-line no-use-before-define
const validator = (schema) => (data) => validate(data, schema);

export const typeValidators = {
    array: arrayDataValidator,
    object: objectDataValidator,
    string: stringDataValidator,
    date: dateDataValidator, // @todo fix date validator to be more efficient
    number: numberDataValidator,
    url: urlDataValidator,
    boolean: booleanDataValidator,
    enum: enumDataValidator,
    'application/vnd.streamurlprovider.newtv-stream-url-v1+json': validator(SupNewTVResponseV1),
    'application/vnd.streamurlprovider.traditional-stream-url-v1+json':
        validator(SupRegularResponseV1),
    'application/vnd.dc.device-token-v1+json': validator(DeviceTokenV1),
    'application/vnd.waipu.recordings-v2+json': validator(RecordingV2),
    'application/vnd.waipu.epg-program-v1+json': validator(ProgramV1),
    'application/vnd.waipu.epg-program-v2+json': validator(ProgramV2),
};

/**
 * The intention of this function is to ensure always the
 * expected data structure by interpolating missing data by
 * given schema. Given data not found in schema is not validated
 * but remains in the resulting data structure.
 * @param  {Object} data
 * @param  {Object} schema
 * @param  {Object} validators
 * @return {Object} validated and interpolated data
 */
export const validate = (data = {}, schema, validators = typeValidators) => {
    const validatedData = {};
    const recursiveTypes = ['object']; // ignore arrays for now
    const schemaKeys = Object.keys(schema);
    const dataKeys = Object.keys(data);
    // const diffSchema = diffList(schemaKeys, dataKeys);
    const diffData = diffList(dataKeys, schemaKeys);

    // @todo logging should be revised and be more efficient.
    // right now it causes request bursts during validating lists.
    // diffSchema.forEach((attr) =>
    //     console.log(`Attribute \`${attr}\` was defined in schema but is missing in data`),
    // );
    // diffData.forEach((attr) =>
    //     console.log(`Attribute \`${attr}\` was defined in data but is missing in schema`),
    // );

    schemaKeys.concat(diffData).forEach((key) => {
        // mixin data attributes that are missing in schema as we do not ignore data
        const schemaEntry = schema[key];
        let value = data[key];
        if (schemaEntry) {
            const { type, default: _default } = schemaEntry;
            const _validator = validators[type];

            if (recursiveTypes.includes(type) && _validator) {
                // recursive walk through iteratable tree
                value = validate(value, _default);
            } else if (_validator) {
                value = _validator(value, _default);
            }
        }

        validatedData[key] = value; // interpolate data no matter what
    });

    return validatedData;
};
