import { h, FunctionComponent } from 'preact';
import { styled } from '@stitches/react';
import { GridContainer } from '@exaring/ui/components-styled/Grid';
import { Button } from '@exaring/ui/components-styled/Button';
import SortIcon from '@exaring/assets/icons/info.svg';
import ReloadIcon from '@exaring/assets/icons/reload.svg';
import { Typography } from '@exaring/ui/components-styled/Typography';

const StyledEmptyRoom = styled(GridContainer, { flexDirection: 'column' });

const StyledSortIcon = styled(SortIcon, {
    width: '48px',
    height: '48px',
    fill: '$white',
});

const ButtonLabel = styled('span', { marginLeft: '6px' });

export const RecordingResultEmptyRoom: FunctionComponent<{ onClick: () => void }> = ({
    onClick,
}) => {
    return (
        <StyledEmptyRoom alignItems="center" justifyContent="center">
            <StyledSortIcon />
            <Typography variant="h1">Keine Ergebnisse gefunden</Typography>
            <Button css={{ height: '64px', marginBottom: '$$gutterWidth' }} epg onClick={onClick}>
                <ReloadIcon /> <ButtonLabel>Filter zurücksetzen</ButtonLabel>
            </Button>
        </StyledEmptyRoom>
    );
};
