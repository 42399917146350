import { FunctionComponent, h } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { PageGrid, PageGridItem } from '../../../../../packages/ui/components-styled/PageGrid';
import { MoveDirection, useWaipuDragAndDrop } from './useWaipuDragAndDrop';
import { styled } from '../../../../../packages/ui/components-styled/theme';
import { SortItem, SortItemChannel } from '../SortItem';

const BlankItemStyled = styled('div', {
    height: '64px',
});

const BlankItem = () => (
    <PageGridItem sm={3} md={2}>
        <BlankItemStyled />
    </PageGridItem>
);

const StyledPageGrid = styled(PageGrid, {
    variants: {
        view: {
            grid: {
                rowGap: '20px',
            },
            list: {
                rowGap: '2px',
            },
        },
    },
});

const StyledChannelSortGridList = styled('div', {
    marginTop: '$4',
});

type ChannelSortGridProps = {
    channels: SortItemChannel[];
    onToggleFavorite: (id: string) => void;
    onToggleVisible: (id: string, show: boolean) => void;
    onReorder: (ids: string[], draggedChannel?: string) => void;
    onReorderError: () => void;
    view: 'grid' | 'list';
    /**
     * @deprecated only for usage with the old store, proper implementation should rely on the onReorder function
     */
    onChannelShift: (fromIndex: number, toIndex: number) => void;
};

export const ChannelSortGridList: FunctionComponent<ChannelSortGridProps> = ({
    channels,
    onToggleFavorite,
    onToggleVisible,
    onReorder,
    onReorderError,
    onChannelShift,
    view,
}) => {
    const [draggedIndex, setDraggedIndex] = useState<number | undefined>();
    const [borderIndex, setBorderIndex] = useState<number | undefined>();
    const [draggedOver, setDraggedOver] = useState<number | undefined>();
    const [dragDirection, setDragDirection] = useState<MoveDirection | undefined>();

    const onDragStart = useCallback((index: number) => {
        setBorderIndex(index);
        setTimeout(() => {
            setDraggedIndex(index);
        });
    }, []);
    const onDragOver = useCallback((index: number, direction: MoveDirection) => {
        setDraggedOver(index);
        setDragDirection(direction);
    }, []);
    const onDragEnd = useCallback(() => {
        setDraggedIndex(undefined);
        setBorderIndex(undefined);
        setDraggedOver(undefined);
        setDragDirection(undefined);
    }, []);

    const channelIds = channels.map((chann) => chann.id) as string[];

    const [parent, items, setItems] = useWaipuDragAndDrop(channelIds, {
        onDragStart,
        onDragOver,
        onDragEnd,
        onReorder,
    });

    useEffect(() => {
        setItems(channelIds);
    }, [channelIds.toString()]);

    const findChannelById = (item: string) =>
        channels.find((ch) => ch.id === item) as SortItemChannel;

    const visibleChannels = items
        .map(findChannelById)
        .filter((ch) => ch.visible && ch.locked === false);

    const sortableItems = items.map((item, index) => {
        if (index === draggedIndex) {
            return <BlankItem key={item} />;
        }

        const channel = findChannelById(item);

        // index+1 for visible channel, otherwise 0
        const sortNumber = visibleChannels.indexOf(channel) + 1;

        return (
            <SortItem
                view={view}
                key={item}
                channel={channel}
                sortNumber={sortNumber !== 0 ? sortNumber : undefined}
                showBorder={index === borderIndex}
                showDropLine={draggedOver === index ? dragDirection : undefined}
                onToggleFavorite={onToggleFavorite}
                onToggleVisible={onToggleVisible}
                onSortNumberChange={(i) => {
                    const maxValue = visibleChannels.length;
                    const originalVisibleIndex = sortNumber - 1;
                    const targetVisibleIndex = i - 1;

                    if (i > maxValue || i < 1) {
                        onReorderError();
                    } else if (originalVisibleIndex !== targetVisibleIndex) {
                        onChannelShift(originalVisibleIndex, targetVisibleIndex);
                    }
                }}
            />
        );
    });

    return (
        <StyledChannelSortGridList>
            <StyledPageGrid ref={parent} sm={6} view={view}>
                {sortableItems}
            </StyledPageGrid>
        </StyledChannelSortGridList>
    );
};
