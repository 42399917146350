import { Recording } from '@exaring/networking/types/Recording/Recording';
import {
    sortRecordingChannel,
    sortRecordingStartTime,
    sortRecordingTitle,
    sortRecordingEpisode,
} from './hooks/filter';

export enum SortingOption {
    DateDesc = 'dateDesc',
    DateAsc = 'dateAsc',
    TitleDesc = 'titleDesc',
    TitleAsc = 'titleAsc',
    ChannelDesc = 'channelDesc',
    ChannelAsc = 'channelAsc',
    EpisodeAsc = 'episodeAsc',
    EpisodeDesc = 'episodeDesc',
}

export interface SortSelectOption {
    label: string;
    addSeparatorAfter?: boolean;
    disabled?: boolean;
    value: SortingOption;
}

export const sortingSelectOptions: readonly SortSelectOption[] = [
    { label: 'Neueste', value: SortingOption.DateDesc },
    { label: 'Älteste', value: SortingOption.DateAsc },
    { label: 'Titel (A-Z)', value: SortingOption.TitleAsc },
    { label: 'Titel (Z-A)', value: SortingOption.TitleDesc },
    { label: 'Sender (A-Z)', value: SortingOption.ChannelAsc },
    { label: 'Sender (Z-A)', value: SortingOption.ChannelDesc },
    { label: 'Folgen aufsteigend', value: SortingOption.EpisodeAsc },
    { label: 'Folgen absteigend', value: SortingOption.EpisodeDesc },
];

export const sorting = (activeSorting: SortingOption) => (left: Recording, right: Recording) => {
    switch (activeSorting) {
        case SortingOption.ChannelAsc: {
            return sortRecordingChannel(left, right);
        }
        case SortingOption.ChannelDesc: {
            return sortRecordingChannel(right, left);
        }
        case SortingOption.TitleAsc: {
            return sortRecordingTitle(left, right);
        }
        case SortingOption.TitleDesc: {
            return sortRecordingTitle(right, left);
        }
        case SortingOption.DateAsc: {
            return sortRecordingStartTime(left, right);
        }
        case SortingOption.DateDesc: {
            return sortRecordingStartTime(right, left);
        }
        case SortingOption.EpisodeAsc: {
            return sortRecordingEpisode(left, right);
        }
        case SortingOption.EpisodeDesc: {
            return sortRecordingEpisode(right, left);
        }
        default: {
            return 0;
        }
    }
};
