/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';
import { classnames, strj } from '@exaring/utils';

export default ({ name, className, selected, options, onChange, iconName, disabled }) => {
    const _className = classnames('select', disabled && 'is-disabled', className);
    const _classNameIcon = classnames('icon', iconName && strj('icon', iconName, '--'));

    return (
        <div className={_className}>
            <span className={_classNameIcon} />
            <select
                onChange={onChange}
                value={selected}
                disabled={disabled}
                className="select__field"
            >
                {options.map((item, idx) => {
                    return (
                        <option key={strj(name, `${idx}`, '-')} value={item.value}>
                            {item.label}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};
