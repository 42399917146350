/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */
import { ImageWithFallback } from './ImageWithFallback';
import { styled } from '../../theme';

export const StyledTileImage = styled(ImageWithFallback, {
    objectFit: 'cover',
    backgroundSize: 'cover',
    width: '100%',
    minHeight: '111px',
    aspectRatio: 16 / 9,
});
