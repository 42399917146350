import { sessionStorageSet, sessionStorageRemove, sessionStorageGet } from '@exaring/utils';

const LANG_KEY_STRING = {
    de: 'Deutsch',
    en: 'English',
    fr: 'Français',
    tr: 'Türkçe',
    es: 'Español',
    qaa: 'Original',
    qks: 'Deutsch (Klare Sprache)',
    qad: 'Akustische Bildbeschreibung',
    qde: 'Deutsch (Dolby Digital)',
    qen: 'English (Dolby Digital)',
    // custom
    OFF: 'Aus',
};

export type LangKeyType = keyof typeof LANG_KEY_STRING;

export interface LanguageSettings {
    languages: LangKeyType[];
    selectedLanguage: LangKeyType;
}

export const translateLang = (lang: LangKeyType) => {
    return LANG_KEY_STRING[lang];
};

type LangType = 'audio' | 'text';

const typeToSessionStorageKey = (type: LangType) => {
    return type === 'audio' ? 'tracksAudio' : 'tracksText';
};

export const getLangSetting = (type: LangType) => {
    const langSetting = sessionStorageGet(typeToSessionStorageKey(type));
    // eslint-disable-next-line no-restricted-syntax
    return langSetting ?? undefined;
};

export const persistLangSetting = (lang: LangKeyType, type: LangType) => {
    sessionStorageSet(typeToSessionStorageKey(type), lang);
};

export const removeLangSetting = (type: LangType) => {
    sessionStorageRemove(typeToSessionStorageKey(type));
};
