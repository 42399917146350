import shaka from 'shaka-player';
import { inRange, getParameterByName, localStorageItem } from '@exaring/utils';
import constants from '../constants';
import { isSafari, streamProtocolBrowserDetection } from '../helper';
import ErrorTypes from '../errorTypes';
import { WaipuAbrManager } from './shaka-abr';
import { getLangSetting, persistLangSetting, removeLangSetting } from './language';
import {
    getSelectedLanguage,
    hideSubtitlesForSafari,
    showSubtitlesForSafari,
} from './safariSubtitlesFix';

let videoElement;
let player;
let unexpectedErrorHandler;

export const Events = {
    Loading: 'loading',
    Unloading: 'unloading',
    Playing: 'playing',
    Pause: 'pause',
    Seeked: 'seeked',
    Seek: 'seeking',
    PlaybackFinished: 'ended',
    Error: 'error',
    Loadedmetadata: 'loadedmetadata',
    Timelineregionadded: 'timelineregionadded',
};

const errorCategories = {
    1: 'network error',
    2: 'error parsing text streams',
    3: 'error parsing or processing audio or video streams',
    4: 'error parsing the Manifest',
    5: 'streaming error',
    6: 'drm error',
    7: 'player error',
    8: 'cast error',
    9: 'database storage error',
    10: 'ad insertion error',
};

const listenerTargetMap = {
    onSeek: {
        target: 'videoElement',
        eventName: Events.Seek,
    },
    onError: {
        target: 'player',
        eventName: Events.Error,
    },
    onSeeked: {
        target: 'videoElement',
        eventName: Events.Seeked,
    },
    onPaused: {
        target: 'videoElement',
        eventName: Events.Pause,
    },
    onPlayed: {
        target: 'videoElement',
        eventName: Events.Playing,
    },
    onLoaded: {
        target: 'player',
        eventName: Events.Loading,
    },
    onUnloaded: {
        target: 'player',
        eventName: Events.Unloading,
    },
    onFinished: {
        target: 'videoElement',
        eventName: Events.PlaybackFinished,
    },
    onLoadedmetadata: {
        target: 'videoElement',
        eventName: Events.Loadedmetadata,
    },
    onTimelineregionadded: {
        target: 'player',
        eventName: Events.Timelineregionadded,
    },
};

const playerConfig = {
    streaming: {
        // https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.StreamingConfiguration

        // The number of seconds of content that the StreamingEngine will attempt to buffer ahead of the playhead.
        // This value must be greater than or equal to the rebuffering goal.
        bufferingGoal: 10,

        // The minimum number of seconds of content that the StreamingEngine must buffer before it can begin
        // playback or can continue playback after it has entered into a buffering state (i.e., after it has
        // depleted one more more of its buffers).
        rebufferingGoal: 5,

        // If true, adjust the start time backwards so it is at the start of a segment. This affects both explicit
        // start times and calculated start time for live streams.This can put us further from the live edge.
        // Defaults to false.
        startAtSegmentBoundary: false,

        // Desktop Safari has both MediaSource and their native HLS implementation. Depending on the application's
        // needs, it may prefer one over the other.Examples: FairPlay is only supported via Safari's native HLS, but
        // it doesn't have an API for selecting specific tracks.
        useNativeHlsOnSafari: true,
    },
    abr: {
        // https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.AbrConfiguration

        // If true, enable adaptation by the current AbrManager.
        enabled: true,

        // The minimum amount of time that must pass between switches, in seconds
        switchInterval: 6,

        // The default bandwidth estimate to use if there is not enough data, in bit/sec.
        defaultBandwidthEstimate: 1000000,
    },
    abrFactory: () => new WaipuAbrManager(),
    manifest: {
        dash: {
            // https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.DashManifestConfiguration

            // If true will cause DASH parser to ignore minBufferTime from manifest. It allows player config to take
            // precedence over manifest for rebufferingGoal.Defaults to false if not provided.
            ignoreMinBufferTime: true,
        },
    },
    // FIXME: this has caused 7000 error on recordings playback
    // TODO: find a better way to handle this
    // cmcd: {
    //     enabled: true,
    //     useHeaders: false,
    //     sessionId: '',
    //     contentId: '',
    // },
};
const isLive = () => player.isLive();

const getCurrentTime = () => videoElement.currentTime;

const load = async (stream, isLiveTv = false, isTimeshift = false, startAt) => {
    let offset = startAt;
    // make sure to not start directly at live edge to prevent buffering issues
    if (isLiveTv && !isTimeshift) {
        offset = -8;
    }

    const protocol = streamProtocolBrowserDetection();
    try {
        /**
         * DO NOT REMOVE!!!
         * A necessary short circuit for Playwright end-to-end testing.
         * In Playwright we test the web client functionality and not the
         * MediaPlayer and video streaming logic. To avoid unwanted browser
         * errors with DRM content and missing media codecs, we stub out
         * the media player here, by returning some popcorn 🍿😎📽️
         */
        if (constants.ENV === 'dev' && localStorageItem('WEB_CLIENT_PLAYWRIGHT')) {
            return Promise.resolve('🍿');
        }
        return await player.load(stream[protocol], offset);
    } catch (e) {
        // create a generic unknown error if load throws unexpected errors
        unexpectedErrorHandler?.({
            category: e.category,
            detail: {
                code: e.code,
                data: e.data,
            },
        });

        return undefined;
    }
};

const unload = () => player.unload();

const play = () => videoElement.play();

const pause = () => videoElement.pause();

const setVolume = (volume) => {
    videoElement.volume = volume / 100;
};

const toggleMute = (muted) => {
    videoElement.muted = muted;
};

const seek = (position) => {
    videoElement.currentTime = position;
};

const timeShift = (pos, calledAfterLoad = false) => {
    if (calledAfterLoad) {
        return;
    }
    const { start } = player.seekRange();
    videoElement.currentTime = pos || start;
};

const playerVersion = () => shaka.Player.version;
const playerTechnicalInfo = async () => ({
    probeSupport: await shaka.Player.probeSupport(),
});

export const createPlayer = (drm) => {
    videoElement = document.getElementById('player_video');
    if (!videoElement) {
        throw new Error('video element not found');
    }
    player = new shaka.Player(videoElement);
    videoElement.muted = true;
    window.video = videoElement; // FIXME: don't use window
    window.player = player;
    window.shaka = shaka;

    shaka.polyfill.installAll();

    if (isSafari()) {
        // needed for Apple FairPlay DRM
        shaka.polyfill.PatchedMediaKeysApple.install();
        player.getNetworkingEngine().registerResponseFilter((type, response) => {
            if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
                if (player.keySystem() === 'com.apple.fps') {
                    const responseText = shaka.util.StringUtils.fromUTF8(response.data);
                    response.data = shaka.util.Uint8ArrayUtils.fromBase64(responseText).buffer;
                }
            }
        });
    }

    player.getNetworkingEngine().registerRequestFilter((type, request) => {
        if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
            request.headers['dt-custom-data'] = drm.fairplay
                ? drm?.fairplay?.headers['dt-custom-data']
                : drm?.widevine?.headers['dt-custom-data'];
        }
    });

    const conf = {
        servers: {
            'com.widevine.alpha': `${drm?.widevine?.LA_URL}?specConform=true`,
            'com.apple.fps': drm?.fairplay?.LA_URL,
        },
        advanced: {
            'com.widevine.alpha': {
                videoRobustness: drm?.widevine?.videoRobustness,
                audioRobustness: drm?.widevine?.audioRobustness,
            },
            'com.apple.fps': {
                serverCertificateUri: drm?.fairplay?.certificateURL,
            },
        },
        // needed for Apple FairPlay DRM
        initDataTransform: (initData, initDataType, drmInfo) => {
            if (initDataType !== 'skd') {
                return initData;
            }

            // 'initData' is a buffer containing an 'skd://' URL as a UTF-8 string.
            const skdUri = shaka.util.StringUtils.fromBytesAutoDetect(initData);
            const contentId =
                getParameterByName('assetId', skdUri) || getParameterByName('assetid', skdUri);
            if (!contentId) {
                return initData;
            }

            const cert = drmInfo.serverCertificate;
            return shaka.util.FairPlayUtils.initDataTransform(initData, contentId, cert);
        },
    };

    playerConfig.drm = conf;
    player.configure(playerConfig);

    return player;
};

export const loadLanguageSettings = () => {
    const preferredAudioLanguage = getLangSetting('audio');
    const preferredTextLanguage = getLangSetting('text');
    if (preferredTextLanguage !== undefined) {
        showSubtitlesForSafari();
        playerConfig.autoShowText = shaka.config.AutoShowText.ALWAYS;
    } else {
        hideSubtitlesForSafari();
        playerConfig.autoShowText = shaka.config.AutoShowText.NEVER;
    }
    playerConfig.preferredTextLanguage = preferredTextLanguage;
    playerConfig.preferredAudioLanguage = preferredAudioLanguage;
    player.configure(playerConfig);
};

const setAudioLanguage = (lang) => {
    if (lang) {
        player.selectAudioLanguage(lang);

        persistLangSetting(lang, 'audio');
    }
};
const setTextLanguage = (lang) => {
    const langEnabled = lang && lang !== 'OFF';
    if (langEnabled) {
        showSubtitlesForSafari();
        player.selectTextLanguage(lang);

        persistLangSetting(lang, 'text');
    } else {
        hideSubtitlesForSafari();
        removeLangSetting('text');
    }
    player.setTextTrackVisibility(langEnabled);
};

const getAudioInfo = () => {
    const variants = player?.getVariantTracks();
    const languages = player?.getAudioLanguages() || [];
    const activeVariant = variants?.find((v) => v.active);
    const selectedLanguage = activeVariant?.language || 'OFF';

    return {
        languages,
        selectedLanguage,
    };
};

const getTextInfo = () => {
    const tracks = player.getTextTracks();
    const languages = player.getTextLanguages() || [];

    if (languages?.length > 0) {
        languages?.unshift('OFF');
    }

    const activeTrack = tracks?.find((v) => v.active);
    const selectedLanguage = getSelectedLanguage(activeTrack?.language, player);

    return {
        languages,
        selectedLanguage,
    };
};

const errorHandler = (errorCallback) => (error) => {
    const errorCode = error.detail?.code;
    const statusCode = error.detail?.data[1];
    const errorCategory = errorCategories[error.category];
    const message = Array.isArray(error?.detail?.data)
        ? `${errorCategory} - ${error.detail.data.join('; ')}`
        : errorCategory;

    let errorType;

    switch (true) {
        case inRange(errorCode, 3000, 3999, /* bounds */ true):
            errorType = ErrorTypes.Playback;
            break;
        case inRange(errorCode, 1000, 1999, /* bounds */ true):
            if (statusCode === 401) {
                errorType = ErrorTypes.StreamExpired;
            } else {
                errorType = statusCode === 406 ? ErrorTypes.StreamLimitation : ErrorTypes.Network;
            }
            break;
        case inRange(errorCode, 6000, 6999, /* bounds */ true):
            errorType = ErrorTypes.Drm;
            break;
        default:
            errorType = ErrorTypes.Other;
            break;
    }

    errorCallback(errorType, errorCode, message);
};

const initPlayer = (handlers) => {
    const bindEventListeners = ([name, handler]) => {
        if (!listenerTargetMap[name]) {
            return;
        }
        let _handler = handler;
        const { target, eventName } = listenerTargetMap[name];

        if (target !== 'player' && target !== 'videoElement') {
            throw new Error('target not supported');
        }

        if (target === 'player') {
            if (eventName === Events.Error) {
                unexpectedErrorHandler = errorHandler(handler); // this is actually used to track unhandled errors of the shaka
                _handler = errorHandler(handler);
            }
            player.addEventListener(eventName, _handler);
        } else {
            videoElement.addEventListener(eventName, _handler);
        }
    };

    handlers && Object.entries(handlers).map(bindEventListeners);

    return {
        load,
        unload,
        play,
        pause,
        setVolume,
        toggleMute,
        timeShift,
        seek,
        getCurrentTime,
        isLive,
        playerVersion,
        playerTechnicalInfo,
        loadLanguageSettings,
        setAudioLanguage,
        setTextLanguage,
        getAudioInfo,
        getTextInfo,
    };
};

export default initPlayer;
