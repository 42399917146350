/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { addBreadcrumbMessage } from '../error-logging';
import { serialRecordingAvailable } from '../data/program';

/**
 * Returns epg data in a way formatted for mux stream logging
 * @param {object} epgData
 * @returns {{video_duration: number, video_title: string, video_producer: Object.channelId, video_series: (*|null), video_id: *}}
 */
export const parseMetaData = (epgData, videoTypes, streamUrl) => {
    const { episode, season, episodeTitle, startTime, channel, title } = epgData || {};
    const { videoStreamType, videoContentType } = videoTypes || {};
    const isSerial = serialRecordingAvailable(epgData || {}) || episode;
    const episodeInfo = episode ? ` Episode ${episode}` : '';
    const serialInfo = season ? ` (S${season}E${episode})` : episodeInfo;
    const titleSupplement = ` - ${episodeTitle || ''}${serialInfo || startTime}`;

    let videoTitle;
    if (videoStreamType === 'Livestream') {
        videoTitle = channel;
    } else {
        videoTitle = isSerial ? `${title}${titleSupplement}` : title;
    }

    return {
        video_title: videoTitle,
        video_stream_type: videoStreamType,
        video_content_type: videoContentType,
        video_source_url: streamUrl,
    };
};

/**
 * Sends an event to Mux data
 * @param playerInstance
 * @param event
 * @param metaData
 */
export const logStreamEvent = (playerInstance = {}, event, metaData) => {
    if (typeof playerInstance.mux === 'undefined') {
        addBreadcrumbMessage('Could not emit event - Mux object not available on player instance');
    } else {
        playerInstance.mux.emit(event, metaData);
    }
};

const defaultErrorParser = (error) => {
    return {
        player_error_code: error.player_error_code,
        player_error_message: error.player_error_message,
    };
};

/**
 * Initializes Mux stream logging for the given player instance
 *
 * @param {object} playerInstance - player instance
 * @param {string} environmentKey - Mux env key
 * @param {string} playerName
 * @param {string} playerVersion
 * @param {string} viewerUserId
 * @param {object} metadata
 * @param {boolean} debug
 * @param {boolean} automaticErrorTracking
 * @param {function} errorTranslator
 * @param {string} muxFunctionName - the global function name to be called for mux init
 */
export const initStreamLogging = (
    playerInstance,
    {
        environmentKey,
        playerName,
        playerVersion,
        viewerUserId,
        metadata = {},
        debug = false,
        automaticErrorTracking = true,
        errorTranslator = defaultErrorParser,
    } = {},
    muxFunctionName = 'initShakaPlayerMux',
    playerBase,
) => {
    if (!environmentKey || !playerName || !playerVersion || !playerInstance) {
        throw new Error('Missing init data');
    }

    if (typeof window[muxFunctionName] === 'undefined') {
        addBreadcrumbMessage(`${muxFunctionName} is not available`);
        return;
    }

    window[muxFunctionName](
        playerInstance,
        {
            debug,
            errorTranslator,
            automaticErrorTracking,
            data: {
                env_key: environmentKey, // required

                // Mandatory Metadata
                player_name: playerName,
                player_version: playerVersion,
                player_init_time: Date.now(),

                // User Data
                viewer_user_id: viewerUserId,

                // more meta data
                ...metadata,
            },
        },
        playerBase,
    );
};
