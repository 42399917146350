import ctime from 'ctimejs';
import { constants, RecordingState } from '../constants';
import { dateDuration, progress as dateProgress } from '../../date';

// helper recording

/**
 * returns the duration in given timescale
 * @param {object} recording
 * @param {boolean} min should difference be converted to minutes (default false)
 * @returns {number} {number} difference in minutes or seconds (default seconds)
 */
export const duration = (recording = {}, min = false) =>
    dateDuration(recording.startTime, recording.stopTime, min);

/**
 * returns the progress the program has played
 * @param {object} recording programData for program
 * @param {timestamp} pos (optional) if current position is different to now()
 * @returns {number} percentage of played position
 */
export const progress = (recording = {}, pos = undefined) => {
    const { startTime, stopTime, position } = recording;
    const _position = pos || ctime(startTime).add(position, 's');
    return dateProgress(startTime, stopTime, _position);
};

/**
 * Helper-Classes to compare our retrieved recording-status with possible RecordingStates
 * @param {string} state
 * @returns {boolean} is given status or the status from given recording
 *  possible States: FINISHED, SCHEDULED, RECORDING, RECORDABLE, UNKNOWN
 */

export const isFinished = (state = '') => {
    return `${state}`.toUpperCase() === RecordingState.FINISHED;
};

export const isScheduled = (state = '') => {
    return `${state}`.toUpperCase() === RecordingState.SCHEDULED;
};

export const isRecording = (state = '') => {
    return `${state}`.toUpperCase() === RecordingState.RECORDING;
};

export const isRecordable = (state = '') => {
    return `${state}`.toUpperCase() === RecordingState.RECORDABLE;
};

export const isUnknown = (state = '') => {
    return `${state}`.toUpperCase() === RecordingState.UNKNOWN || !state;
};

/**
 * returns if the given recording belongs to a serial-recording
 * @param {object} recording
 * @returns {boolean} true or false
 */
export const belongsToRecordingGroup = (recording = {}) => {
    return !!recording.recordingGroup;
};

/**
 * Helper-Classes to compare if recording is available for playing
 * @param {string} state
 * @returns {boolean} is given recording available
 */
export const isAvailable = (state = '') => {
    return isRecording(state) || isFinished(state);
};

/**
 * Helper-Classes to compare if recording is locked
 * @param {object} recording
 * @returns {boolean} is given recording locked
 */
export const isLocked = (recording = {}) => {
    return !!recording.locked;
};

/**
 * Helper-Classes to compare if Recording is finished but locked
 * @param {object} recording
 * @returns {boolean} is given recording locked & finished
 */
export const isLockedAndFinished = (recording = {}) => {
    const { locked, status } = recording;
    return !!locked && isFinished(status);
};

/**
 * Helper-Classes to compare if Recording is new and hasn't been watched yet
 * @param {object} recording
 * @returns {boolean} is given status or the status from given recording
 */
export const isNew = (recording = {}) => {
    const { watched, status } = recording;
    return !watched && isFinished(status);
};

/**
 * Helper-Classes to compare if recording is certain time long and ready to play
 * @param {object} recording
 * @returns {boolean} is given recording is Ready to play
 */
export const readyToPlay = (recording = {}) => {
    const start = ctime(recording.startTime);
    return ctime().diff(start) > constants.RECORDING_READY_TIME;
};

/**
 * Returns all playout URL for given recording.
 * @param {object} recording
 * @returns {object}
 */
export const playoutUrls = (recording) => {
    if (!recording?.streamingDetails?.streams) {
        return undefined;
    }

    return recording.streamingDetails.streams.reduce(
        (acc, val) => ({ ...acc, [val.protocol]: val.href }),
        {},
    );
};

/**
 * Returns a single playout URL for given recording & protocol.
 * @param {object} recording
 * @param {string} protocol Can be 'MPEG_DASH' or 'HLS'. (Optional, default: 'MPEG_DASH')
 * @returns {string} stream url
 */
export const playoutUrl = (recording, protocol = 'MPEG_DASH') => {
    const urls = playoutUrls(recording);

    return urls?.[protocol];
};
