/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { events } from '@exaring/utils';
import { useEffect } from 'preact/hooks';

const useNativeEvents = (eventMap, eventTarget = document) => {
    useEffect(() => {
        events.addEventsTo(eventTarget, eventMap);

        return () => {
            events.removeEventsFrom(eventTarget, eventMap);
        };
    }, [eventMap, eventTarget]);
};

export default useNativeEvents;
