import { h, FunctionComponent } from 'preact';
import { isNil } from '@exaring/utils/validators';
import { useEffect, useState } from 'preact/hooks';

// Components
import { GridContainer } from '@exaring/ui/components-styled/Grid';
import { Dropdown } from '@exaring/ui/components-styled/DropDown';
import { styled } from '@exaring/ui/components-styled/theme';
import CloseIcon from '@exaring/assets/icons/close.svg';
import { Button, StyledEditIcon } from '@exaring/ui/components-styled/Button';
import { ToggleButton } from '@exaring/ui/components-styled/ToggleButton/ToggleButton';
import { useScrollLock } from '@exaring/ui/hooks/useScrollLock/useScrollLock';
import { HeaderMenu } from '../../PageHeadline/PageHeader';
import { TileHero } from '../../Tile/TileHero/TileHero';
import { RecordingList } from '../RecordingList';
import { RecordingFilterSortingMenu } from '../RecordingFilterSortingMenu';

// Data
import { FilterOption, filter, seasonFilter } from '../filter';
import { SortingOption, sorting } from '../sorting';
import { didNotAsk, didSucceed, isLoading } from '../../../state/utils/RemoteData';
import { useMemoData } from '../../../hooks/useMemoData';
import { recordingStore, useRecordingStore, notificationsStore } from '../../../state/Store';
import { RecordingResultEmptyRoom } from '../RecordingResultEmptyRoom';
import { ModalBackdrop, Modal, StyledBackdropVariants } from '../../Modal/Modal';
import RecordingButton from '../../RecordingButton';
import { RecordingEditMenu } from '../RecordingEditMenu';
import { useRecordingSelection } from '../hooks/useRecordingSelection';
import { useRecordingDetails } from '../hooks/useRecordingDetails';
import { Headline } from '../../PageHeadline/Headline';
import { Routes, routeTo } from '../../../routes';
import { WebClientGAEvent, WebClientGA } from '../../../web-client-ga';
import { trackingFilterValueConverter, trackingSortValueConverter } from '../../../helper';
import type { ItemContextCallback } from '../RecordingGrid';

const StyledCloseIcon = styled(CloseIcon, {
    color: '$white',
    cursor: 'pointer',
});

const FlexContainer = styled('div', {
    display: 'flex',
    background: 'rgba(9, 7, 20, 0.9)',
    justifyContent: 'space-between',
    columnGap: '$$gutterWidth',
});

const ContentWrapper = styled('div', {});

const StyledButton = styled(Button, {
    fontSize: '$2',
    paddingLeft: '15px',
    paddingRight: '11px',
    marginBottom: '20px !important',
    marginTop: '$$gutterWidth !important',
    height: '36px',
});

const headerStyles = {
    background: 'rgba(9, 7, 20, 0.9)',
    backdropFilter: 'blur(4px)',
};

const BackgroundWrapper = styled('div', {
    backdropFilter: 'blur(4px)',
    position: 'sticky',
    top: 0,
    zIndex: '2',
});

const eventNop = (event: MouseEvent | TouchEvent) => event.stopPropagation();

export const RecordingSeriesModal: FunctionComponent<
    StyledBackdropVariants & {
        recordingGroup: number;
        onClose: () => void;
        path: string;
        onDeleteContent?: ItemContextCallback;
    }
> = ({ recordingGroup, onClose, modal, fullscreen, path, onDeleteContent }) => {
    const episodeId = path.match(/\/(\d+)$/)?.[1];
    const { recordings, getRecordingGroup, recordingGroups } = useRecordingStore();
    const notifications = notificationsStore();
    const [isEditMode, setIsEditMode] = useState<boolean>(false);

    useEffect(() => {
        if (episodeId) {
            const currentEpisode = document.getElementById(episodeId);
            currentEpisode?.scrollIntoView();
        }
    }, []);

    useScrollLock();

    const {
        getSelectedRecordings,
        selectRecording,
        toggleRecordingSelection,
        clearRecordingSelection,
        isRecordingSelected,
    } = useRecordingSelection();

    const isRecordingOverviewLoading = isLoading(recordings) || didNotAsk(recordings);

    const [activeFilter, setActiveFilter] = useState<FilterOption>(FilterOption.All);
    const [activeSeasonFilter, setActiveSeasonFilter] = useState<string>('0');
    const [activeSorting, setActiveSorting] = useState<SortingOption>(SortingOption.DateDesc);
    useEffect(() => {
        clearRecordingSelection();
    }, [isEditMode]); // Reset selection when edit mode is toggled

    const recordingGroupRemoteData = recordingGroups[recordingGroup];
    const recordingGroupValue =
        recordingGroupRemoteData && didSucceed(recordingGroupRemoteData)
            ? recordingGroupRemoteData.value
            : undefined;
    const stationDisplay = recordingGroupValue?.[0]?.stationDisplay;

    const recordingGroupDataLoading =
        isRecordingOverviewLoading ||
        (recordingGroupRemoteData &&
            (isLoading(recordingGroupRemoteData) || didNotAsk(recordingGroupRemoteData)));

    const firstRecordingItem = recordingGroupValue?.[0];
    const { data: recordingDetails } = useRecordingDetails(firstRecordingItem?.id);

    const recording = didSucceed(recordingDetails) ? recordingDetails.value : undefined;

    const recordingGroupWithoutSeasonFilter = useMemoData(
        recordingGroupRemoteData && didSucceed(recordingGroupRemoteData)
            ? recordingGroupRemoteData.value
            : [],
        filter(activeFilter),
        sorting(activeSorting),
    );

    const memoizedRecordingGroup = useMemoData(
        recordingGroupWithoutSeasonFilter,
        seasonFilter(activeSeasonFilter),
        sorting(activeSorting),
    );

    const reducedSeasons = recordingGroupWithoutSeasonFilter
        .reduce((uniqueArray, rec) => {
            if (rec.season && uniqueArray.indexOf(rec.season.trim()) === -1) {
                uniqueArray.push(rec.season);
            }
            return uniqueArray;
        }, [] as string[])
        .sort((a, b) => parseInt(a, 10) - parseInt(b, 10)); // ascending;

    const seasonFilterOptions = ['0', ...reducedSeasons].map((val) => ({
        value: val,
        label: val === '0' ? 'Alle Staffeln' : `Staffel ${val}`,
    }));

    const allRecordingsSelected =
        memoizedRecordingGroup.length > 0 &&
        memoizedRecordingGroup.length === getSelectedRecordings()!.length;

    const emptyRoom = !recordingGroupDataLoading ? (
        <RecordingResultEmptyRoom
            onClick={() => {
                setActiveFilter(FilterOption.All);
                setActiveSorting(SortingOption.DateDesc);
            }}
        />
    ) : null; // eslint-disable-line no-restricted-syntax

    const toggleRecordingCallback =
        (recordingIdentifier: string) => (event: MouseEvent | TouchEvent) => {
            event?.stopPropagation();
            const contextRecording = memoizedRecordingGroup.find(
                (foundRecording) => foundRecording.id === recordingIdentifier,
            );

            if (contextRecording) {
                toggleRecordingSelection(contextRecording, false);
            }
        };

    const getRecordingById = (recordingIdentifier: string) => {
        return memoizedRecordingGroup.find((rec) => rec.id === recordingIdentifier);
    };

    const playMediaCallback = (recordingIdentifier: string) => () => {
        const foundRecording = getRecordingById(recordingIdentifier);

        if (foundRecording?.locked) {
            notifications.createRecordingStorageUpsellingToast();
        } else {
            routeTo(`${Routes.RECORDING_PLAYOUT_PAGE}/${recordingIdentifier}`);
        }
    };

    const deleteSelectedRecordings = async () => {
        if (getSelectedRecordings().length > 0) {
            const recordingIds = getSelectedRecordings().map(
                (selectedRecording) => selectedRecording.id,
            );
            WebClientGA().trackEvent({
                eventName: WebClientGAEvent.Recordings,
                eventDescription: WebClientGAEvent.DeleteSelectedRecordings,
                screenName: 'serial_recordings_overview',
            });
            await recordingStore().deleteRecordings(recordingIds, undefined, ['AVAILABLE']);
            await recordingStore().initRecordingsSummary();
            getRecordingGroup(recordingGroup, undefined, true);
            clearRecordingSelection();
        }
        setIsEditMode(false); // reset edit mode after deletion
    };

    const openRecordingDetails = (recordingIdentifier: string) => () => {
        const currentRecording = getRecordingById(recordingIdentifier);
        WebClientGA().trackEvent({
            eventName: WebClientGAEvent.Recordings,
            eventDescription: WebClientGAEvent.SelectRecording,
            screenName: 'serial_recordings_overview',
            programId: currentRecording?.programId,
            programTitle: currentRecording?.title,
            channelName: currentRecording?.stationDisplay,
        });
        routeTo(`${Routes.RECORDING_PAGE}/${recordingIdentifier}`);
    };

    const disableSortingByEpisode = isNil(memoizedRecordingGroup[0]?.episode);

    return (
        <ModalBackdrop
            css={{ marginTop: '$$gutterWidths' }}
            modal={modal}
            fullscreen={fullscreen}
            alignItems="flex-start"
            justifyContent="center"
            onClick={onClose}
        >
            <Modal
                css={{
                    viewTransitionName: 'detail-modal',
                    overflowY: 'auto',
                    marginTop: '20px',
                    marginBottom: '20px',
                    padding: '0 $$gutterWidth',
                }}
                onClick={eventNop}
            >
                <BackgroundWrapper>
                    <HeaderMenu xs={12} css={headerStyles}>
                        <StyledCloseIcon onClick={onClose} />
                    </HeaderMenu>

                    <FlexContainer>
                        {isEditMode && (
                            <Headline
                                css={{
                                    marginLeft: '0',
                                    marginTop: '$$gutterWidth',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Serienaufnahme verwalten
                            </Headline>
                        )}
                        <FlexContainer css={{ width: '100%', background: 'none' }}>
                            {isEditMode ? (
                                <RecordingEditMenu
                                    css={{ marginTop: '$$gutterWidth', width: '100%' }}
                                    onDelete={() =>
                                        notifications.confirmDeleteRecordings(
                                            getSelectedRecordings().length,
                                            {
                                                onConfirm: deleteSelectedRecordings,
                                            },
                                        )
                                    }
                                    disabled={getSelectedRecordings().length === 0}
                                />
                            ) : (
                                <RecordingFilterSortingMenu
                                    css={{
                                        marginTop: '$$gutterWidth',
                                        marginBottom: '20px',
                                        width: '100%',
                                        justifyContent: 'space-between',
                                    }}
                                    disableSorting={memoizedRecordingGroup.length <= 1}
                                    disableFiltering={memoizedRecordingGroup.length <= 1}
                                    activeFilter={activeFilter}
                                    activeSorting={activeSorting}
                                    setActiveFilter={(value) => {
                                        WebClientGA().trackEvent(
                                            {
                                                eventName: WebClientGAEvent.Recordings,
                                                eventDescription: WebClientGAEvent.SelectFilter,
                                                screenName: 'serial_recordings_overview',
                                            },
                                            { event_details: trackingFilterValueConverter(value) },
                                        );
                                        setActiveFilter(value);
                                        setActiveSeasonFilter('0'); // always reset season filter on status filter change to avoid empty states
                                    }}
                                    setActiveSorting={(value) => {
                                        WebClientGA().trackEvent(
                                            {
                                                eventName: WebClientGAEvent.Recordings,
                                                eventDescription: WebClientGAEvent.SelectSortOrder,
                                                screenName: 'serial_recordings_overview',
                                            },
                                            { event_details: trackingSortValueConverter(value) },
                                        );
                                        setActiveSorting(value);
                                    }}
                                    isSeriesView
                                    disableSortingByEpisode={disableSortingByEpisode}
                                />
                            )}

                            <StyledButton
                                epg
                                onClick={() => {
                                    WebClientGA().trackEvent({
                                        eventName: WebClientGAEvent.Recordings,
                                        eventDescription: isEditMode
                                            ? WebClientGAEvent.ExitEditRecordings
                                            : WebClientGAEvent.EditRecordings,
                                        screenName: 'serial_recordings_overview',
                                    });
                                    setIsEditMode((state) => !state);
                                }}
                                active={isEditMode}
                                icon={<StyledEditIcon />}
                            />
                        </FlexContainer>
                    </FlexContainer>
                </BackgroundWrapper>
                <ContentWrapper>
                    <TileHero
                        css={{
                            '& img': {
                                minHeight: '244px',
                                maxHeight: '244px',
                            },
                            '& > div': {
                                linearGradient: '90deg, rgba(9,7,20,0) 38%, rgba(9,7,20,1) 50%',
                            },
                            '& > div:hover': {
                                linearGradient: '90deg, rgba(9,7,20,0) 38%, rgba(9,7,20,1) 50%',
                            },
                            fontSize: '36px',
                            '& p': {
                                fontFamily: 'OpenSans-SemiBold, Open Sans',
                            },
                        }}
                        seasonList={reducedSeasons}
                        data={recordingDetails}
                        stationDisplay={stationDisplay}
                        selectable={false}
                        layout="modal"
                    />

                    <GridContainer
                        css={{ height: '36px', flexWrap: 'wrap', margin: '$$gutterWidth 0' }}
                        justifyContent="space-between"
                    >
                        {seasonFilterOptions.length > 1 && (
                            <Dropdown<string>
                                css={{ fontSize: '14px' }}
                                onChange={(value) => {
                                    WebClientGA().trackEvent(
                                        {
                                            eventName: WebClientGAEvent.Recordings,
                                            eventDescription: WebClientGAEvent.NavigateSeasons,
                                            screenName: 'serial_recordings_overview',
                                        },
                                        {
                                            event_details:
                                                value === '0' ? 'all' : `season_${value}`,
                                        },
                                    );
                                    setActiveSeasonFilter(value as string);
                                }}
                                options={seasonFilterOptions}
                                selected={activeSeasonFilter}
                            />
                        )}
                        {isEditMode ? (
                            <ToggleButton
                                css={{
                                    height: '36px',
                                    padding: '15px 20px',
                                    marginLeft: 'auto',
                                }}
                                onClick={() => {
                                    if (allRecordingsSelected) {
                                        WebClientGA().trackEvent({
                                            eventName: WebClientGAEvent.Recordings,
                                            eventDescription: WebClientGAEvent.UnmarkAllEpisodes,
                                            screenName: 'serial_recordings_overview',
                                        });
                                        clearRecordingSelection();
                                    } else {
                                        WebClientGA().trackEvent({
                                            eventName: WebClientGAEvent.Recordings,
                                            eventDescription: WebClientGAEvent.MarkAllEpisodes,
                                            screenName: 'serial_recordings_overview',
                                        });
                                        selectRecording(memoizedRecordingGroup);
                                    }
                                }}
                                selected={allRecordingsSelected}
                            >
                                {allRecordingsSelected ? 'Alle abwählen' : 'Alle auswählen'}
                            </ToggleButton>
                        ) : (
                            recording?.programDetails && (
                                <RecordingButton
                                    css={{
                                        width: 'auto',
                                        height: '36px',
                                        padding: '15px',
                                        backgroundColor: '$dark',
                                    }}
                                    showLabel
                                    program={recording.programDetails}
                                    iconSize={32}
                                    text="tiny"
                                    spacing="tiny"
                                    isProgramDetails
                                />
                            )
                        )}
                    </GridContainer>
                    {memoizedRecordingGroup.length > 0 ? (
                        <RecordingList
                            recordings={memoizedRecordingGroup}
                            onPlayContent={playMediaCallback}
                            onDeleteContent={onDeleteContent}
                            onOpenDetails={openRecordingDetails}
                            editMode={isEditMode}
                            isContentSelected={isRecordingSelected}
                            onSelectContent={toggleRecordingCallback}
                            defaultStation={stationDisplay}
                        />
                    ) : (
                        emptyRoom
                    )}
                </ContentWrapper>
            </Modal>
        </ModalBackdrop>
    );
};
