import { typeValidators } from '@exaring/utils/data/schema/validator';
import { isJsonContentType, isBinaryContentType } from './helper';
import { throwIfInErrorRange } from './NetworkError/NetworkError';

const jsonResponse = async (response) => {
    try {
        return response.json();
    } catch (e) {
        return undefined;
    }
};

const textResponse = async (response) => {
    try {
        return response.text();
    } catch (e) {
        return undefined;
    }
};

const binaryResponse = async (response) => {
    try {
        return response.arrayBuffer();
    } catch (e) {
        return undefined;
    }
};

const statusCode = async (response) => {
    throwIfInErrorRange(response.status, response.statusText || 'Empty response', response);

    return response;
};

/**
 * Validates data attribute of response if given header is matching validator object
 * @param  {Object<Response>} response
 * @param  {Object} validators
 * @return {Object<Response>}
 */
const dataValidator = async (response, cfg, validators = typeValidators) => {
    const contentType = response.headers.get('content-type');

    const validator = validators[contentType];
    const { data } = response;

    // validate if needed
    if (data && validator) {
        if (Array.isArray(data)) {
            // data-type list
            response.data = data.map(validator);
        } else {
            // data-type plain
            response.data = validator(data);
        }
    }

    return response;
};

/**
 * Decodes json load if content-type is set properly
 * @param  {Object<Response>} response
 * @return {Object<Response>}
 */
const jsonDecoder = async (response) => {
    if (isJsonContentType(response.headers.get('content-type'))) {
        response.data = await jsonResponse(response);
    }

    return response;
};

/**
 * Decodes binary load if content-type is set properly
 * @param  {Object<Response>} response
 * @return {Object<Response>}
 */
const binaryDecoder = async (response) => {
    if (isBinaryContentType(response.headers.get('content-type'))) {
        response.data = await binaryResponse(response);
    }

    return response;
};

export { statusCode, jsonResponse, textResponse, binaryDecoder, jsonDecoder, dataValidator };
