import { z } from 'zod';
import { isBoolean, isObject, isString } from '../../utils';

export type ParentalGuidance = 'fsk-6' | 'fsk-12' | 'fsk-16' | 'fsk-18';

export type CastMember = { name: string; role: string };

export type CrewMember = { name: string; jobTitle: string };

export type ProgramSeries = {
    id: string;
    seasonNumber?: string;
    episodeNumber?: string;
    episodeTitle?: string;
    episodeTitleOriginal?: string;
};

const textContentTopicSchema = z.object({
    title: z.string(), // required
    titleSideline: z.string(), // required
    descShort: z.string(), // required
    descLong: z.string(), // required
});

const textContentSchema = z.object({
    title: z.string(), // required
    titleOriginal: z.string(), // required
    titleSideline: z.string(), // required
    overline: z.string(), // required
    descShort: z.string(), // required
    descLong: z.string(), // required
    background: z.string(), // required
    topics: z.array(textContentTopicSchema), // required
    imprint: z.string(), // required
});

const contentMetaSchema = z.object({
    mainGenre: z.string(), // required
    subGenres: z.string().array(), // required
    typeFlag: z.string(), // required
    liveShow: z.boolean(), // required
});

const seriesSchema = z.object({
    id: z.string(), // required
    seasonNumber: z.string().optional(),
    episodeNumber: z.string().optional(),
    episodeTitle: z.string().optional(),
    episodeTitleOriginal: z.string().optional(),
});
const restrictionsSchema = z.object({
    recordingForbidden: z.boolean(),
    instantRestartForbidden: z.boolean().optional(),
    pauseForbidden: z.boolean().optional(),
    seekingForbidden: z.boolean().optional(),
});

const ageRatingSchema = z.object({
    pinRequired: z.boolean(), // required
    parentalGuidance: z.string().nullish().default(''),
});

const subProgramsSchema = z.object({
    title: z.string(), // required
    titleSideline: z.string(), // required
    startTime: z.string().nullish(),
    stopTime: z.string().nullish(),
});

const crewMemberSchema = z.object({
    name: z.string(), // required
    jobTitle: z.string(), // required
});

const castMemberSchema = z.object({
    name: z.string(), // required
    role: z.string().nullish(),
    hint: z.string().nullish(),
});

const productionSchema = z.object({
    year: z.string().nullish(),
    countries: z.string().array(), // required
    crewMembers: z.array(crewMemberSchema), // required
    castMembers: z.array(castMemberSchema), // required
});

const dmbMetaSchema = z.object({
    id: z.string(), // required
});

// NOT IN RECORDINGS details
const newTvMetaSchema = z.object({
    contentId: z.string(),
    source: z.string(),
});

export const programDetailsSchema = z.object({
    id: z.string(), // required
    stationId: z.string(), // required
    startTime: z.string(), // required
    stopTime: z.string(), // required
    rerun: z.boolean(), // required
    textContent: textContentSchema, // required
    contentMeta: contentMetaSchema, // required
    newTvMeta: newTvMetaSchema.nullish(),
    series: seriesSchema.nullish(),
    restrictions: restrictionsSchema, // required
    ageRating: ageRatingSchema.nullish(), // actually can have null -.-
    subPrograms: z.array(subProgramsSchema).optional(), // optional
    imageUrls: z.string().array(), // required
    production: productionSchema, // required
    dmbMeta: dmbMetaSchema.nullish(),
});

export type ProgramDetails = z.infer<typeof programDetailsSchema>;

export const isProgramDetails = (value: unknown): value is ProgramDetails => {
    if (isObject(value)) {
        const { ageRating, stationId } = value;

        return isObject(ageRating) && isBoolean(ageRating.pinRequired) && isString(stationId);
    }
    return false;
};

const isValidJson = (value: unknown): value is ProgramDetails => {
    return isProgramDetails(value);
};

export const decode = (value: unknown): ProgramDetails | undefined => {
    return isValidJson(value) ? value : undefined;
};
