/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, RenderableProps } from 'preact';
import { CSS } from '@stitches/react';
import { Scroller, ScrollerItem } from '@exaring/ui/components-styled/Scroller';
import { mapWithoutNil } from '@exaring/utils/index';
import { useMemo } from 'preact/hooks';

export const TileScroller = <T,>({
    data,
    mapTile,
    dataIndex,
    css,
    translateArrow,
    defaultIndex,
}: RenderableProps<{
    data: T[];
    mapTile: (item: T, idx?: number) => JSX.Element | undefined;
    dataIndex: string;
    css?: CSS;
    translateArrow?: string;
    defaultIndex?: number;
}>) => {
    const scrollerItems = useMemo(
        () =>
            mapWithoutNil(data, (item, idx) => (
                <ScrollerItem className="flicking-panel" key={(item as any)?.[dataIndex]} as="div">
                    {mapTile(item, idx)}
                </ScrollerItem>
            )),
        [data],
    );

    return (
        <Scroller css={css} translateArrow={translateArrow} defaultIndex={defaultIndex}>
            {scrollerItems}
        </Scroller>
    );
};
