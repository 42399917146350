/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';
import ButtonGroup from '../button-group';
import Section from '../section';
import Text from '../../typography/text';
// ModalDialogue.propTypes = {
//      text: React.PropTypes.string,
//      className: React.PropTypes.string,
//      buttons: React.PropTypes.arrayOf(
//                 React.PropTypes.shape({
//                     className: React.PropTypes.string,
//                     action: React.PropTypes.func.isRequired,
//                     label: React.PropTypes.string.isRequired
//                 })
//             )
// };

export default ({ hidden, className, text, buttons }) => {
    if (hidden) {
        return null;
    }

    return (
        <div className={`modal ${className || ''}`}>
            <Section className="dialog">
                <Text content={text} />
                {buttons && buttons.length > 0 && <ButtonGroup buttons={buttons} />}
            </Section>
        </div>
    );
};
