/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */
import * as Sentry from '@sentry/browser';
import constants from '../../constants';

export default class MediaPlayerError extends Error {
    errorCode?: number;

    errorType: string;

    errorMessage?: string;

    constructor({
        errorCode,
        errorType,
        errorMessage,
    }: {
        errorCode?: number;
        errorType: string;
        errorMessage?: string;
    }) {
        const code = `Code: ${errorCode || 'NO_CODE'}`;
        const message = errorMessage ? `${code} - ${errorMessage}` : code;

        // given string will be set as `this.message`
        super(message);

        this.name = `MediaPlayerError - ${errorType} (${constants.PLAYER_NAME})`;
        if (errorCode) {
            this.errorCode = errorCode;
            Sentry.setTag('playerCode', errorCode);
        } else {
            Sentry.setTag('playerCode', undefined);
        }

        this.errorType = errorType;
        this.errorMessage = errorMessage;
    }
}
