/*
 *  Copyright (C) Exaring AG - All Rights Reserved
 */
import EpgServiceApi from '@exaring/networking/services/epg2';
import StationServiceApi from '@exaring/networking/services/station';
import { infSequenceOffset } from '@exaring/utils/helper';
import { Program } from '@nessprim/planby';
import { now } from '@exaring/utils/date';
import { localStorageItem, logErrorWithDescription } from '@exaring/utils';
import {
    decode as decodeProgramDetails,
    ProgramDetails,
} from '@exaring/networking/types/ProgramDetails';
import { StoreSlice } from './utils/StoreSlice';
import { decode as decodeEpgGrid } from '../types/api/epg/Grid';
import { EpgGridInfo, decode as decodeEpgGridInfo } from '../types/api/epg/GridInfo';
import { Channel, decode as decodeStationConfig } from '../types/api/epg/StationConfig';
import { decode as decodeEpgStation } from '../types/api/epg/Stations';

export const getLiveUTCTimeSlot = (timeSlotSize = 4) => {
    return getUTCTimeSlotForTimestamp(now().time(), timeSlotSize);
};

const getUTCTimeSlotForTimestamp = (timestamp: number, timeSlotSize = 4) => {
    try {
        const nowDate = new Date(timestamp);
        const utcHour = nowDate.getUTCHours();
        const startOfTimeSlotHour = utcHour - (utcHour % timeSlotSize);

        const timeslotStart = new Date();
        timeslotStart.setUTCHours(startOfTimeSlotHour, 0, 0, 0); // shift to start of time slot
        return timeslotStart.toISOString();
    } catch (e: any) {
        logErrorWithDescription(e, 'while trying to generate iso string from UTC timeslot');
    }

    return '';
};

const programBelongsToChannel = (program: Program, stationId: string) => {
    return program.channelUuid === stationId;
};

const programIsOnAir = (program: Program, liveTimestamp?: number) => {
    try {
        const onAirTime = liveTimestamp ? new Date(liveTimestamp) : new Date();
        const utcNow = onAirTime.toISOString();

        return program.till > utcNow && program.since < utcNow;
    } catch (e: any) {
        logErrorWithDescription(e, 'while trying to generate iso string for programIsOnAir');
    }

    return false;
};

export type FetchState = 'NotAsked' | 'Loading' | 'Success' | 'Error';
type PreviewType = 'Channel' | 'Program';
type EpgPreview = { type: PreviewType; id: string };
type StationId = string;
type ProgramId = string;
type UTCDateTime = string;
type StationTimeslotId = string; // StationId+UTCTimeslot

export type State = {
    // State
    userHandle?: string;
    showOnlyFavorites: boolean;

    epgData: {
        state: Record<StationId, FetchState>; // general epg data fetch state of a channel (if any timeslot is loading)
        value: Program[];
        live: Record<StationId, Program>;
        stationTimeslotState: Record<StationTimeslotId, FetchState>; // keeps track of all channel timeslot data states
        programIdIndex: ProgramId[]; // contains all loaded program ids to avoid collision in timeslot overlapping programs
    };

    preview?: EpgPreview;

    epgStations: {
        state: FetchState;
        value: Channel[];
        favorites: Channel[]; // list of filtered channel list to avoid capsulated filtering in components
        idxMap: Record<string, number>; // stationId index to fast access channel list
    };

    epgInfo: {
        state: FetchState;
        value?: EpgGridInfo;
    };

    programDetails: {
        state: FetchState;
        value?: ProgramDetails;
        error?: any;
    };

    setPreview: (preview?: EpgPreview) => void;
    fetchProgramDetails: (programId: string) => Promise<ProgramDetails | undefined>;
    fetchEpgData: (stationId: StationId, timeslot: string) => Promise<void>;
    fetchEpgStations: () => Promise<Channel[]>;
    fetchEpgInfo: () => Promise<void>;
    isTimeslotOnError: (stationId: StationId, timeslot: UTCDateTime) => boolean;
    getLiveProgram: (stationId: string) => Promise<Program | undefined>;
    getProgramForTimestamp: (
        stationId: StationId,
        timestamp: number,
    ) => Promise<Program | undefined>;
    fetchEpgDetails: (programId: string) => Promise<ProgramDetails | undefined>;

    setFavorites: (value: boolean) => void;
    toggleFavorites: () => void;
    setUserHandle: (userHandle: string) => void;
    updateStation: (stationId: string, favorite?: boolean, visible?: boolean) => Promise<void>;
    getStationById: (stationId: string) => Channel | undefined;
    getNextAndPreviousChannel: (stationId: string) => [Channel | undefined, Channel | undefined];
};

export const getLoadingChannels = (animated = true): Channel[] =>
    Array(10)
        .fill(0)
        .map((_, index) => ({
            isNestedChild: false,
            type: 'loading',
            uuid: index.toString(),
            logo: '',
            animated,
        }));

const loadingChannels = getLoadingChannels();

export const State: StoreSlice<State> = (set, get) => ({
    // Initial State
    userHandle: '',
    showOnlyFavorites: localStorageItem('epg.showOnlyFavorites'),

    // epg grid data
    epgData: {
        state: {},
        value: [],
        live: {}, // TODO using EpgGridInfo.slotSize
        stationTimeslotState: {},
        programIdIndex: [],
    },

    preview: undefined,

    epgStations: {
        state: 'NotAsked',
        value: [],
        favorites: [],
        idxMap: {},
    },

    epgStationSettings: {
        state: 'NotAsked',
        value: undefined,
    },

    epgInfo: {
        state: 'NotAsked',
        value: undefined,
    },

    programDetails: {
        state: 'NotAsked',
        value: undefined,
    },

    // Actions
    setUserHandle: (userHandle: string) => {
        set((state) => {
            state.userHandle = userHandle;
        }, 'EPG/setUserHandle');
    },

    setFavorites: (value) => {
        set((state) => {
            localStorageItem('epg.showOnlyFavorites', value);
            state.showOnlyFavorites = value;
        }, 'EPG/setFavorites');
    },

    setPreview: (preview?: EpgPreview) => {
        set((state) => {
            state.preview = preview;
        }, 'EPG/setPreview');
    },

    toggleFavorites: () => {
        set((state) => {
            localStorageItem('epg.showOnlyFavorites', !state.showOnlyFavorites);
            state.showOnlyFavorites = !state.showOnlyFavorites;
        }, 'EPG/toggleFavorites');
    },

    fetchProgramDetails: async (programId: string) => {
        set((state) => {
            state.programDetails.state = 'Loading';
        }, 'EPG/fetchProgramDetails');

        try {
            const { data } = await EpgServiceApi.details(programId);

            if (data) {
                const programDetails = decodeProgramDetails(data);
                if (programDetails) {
                    set((state) => {
                        state.programDetails = {
                            state: 'Success',
                            value: programDetails,
                        };
                    }, 'EPG/fetchProgramDetails');
                    return programDetails;
                }
            }
        } catch (e: any) {
            set((state) => {
                state.programDetails = {
                    state: 'Success',
                    value: undefined,
                    error: e,
                };
            }, 'EPG/fetchProgramDetails');
        }
        return undefined;
    },

    fetchEpgData: async (stationId, timeslot) => {
        const { stationTimeslotState } = get().epgData;
        const stationTimeslotCache = stationTimeslotState[`${stationId}${timeslot}`];

        if (stationTimeslotCache === 'Success') {
            return;
        }

        set((state) => {
            state.epgData.state = { ...state.epgData.state, [stationId]: 'Loading' }; // TODO remove as not sufficient
            state.epgData.stationTimeslotState = {
                ...state.epgData.stationTimeslotState,
                [`${stationId}${timeslot}`]: 'Loading',
            };
        }, 'EPG/fetchEpgData');

        try {
            const { data: epgGridData } = await EpgServiceApi.grid(stationId, timeslot);
            const { value: epgData, programIdIndex } = get().epgData;
            const stations = get().epgStations;

            if (epgGridData) {
                const channelDetails = {
                    channelUuid: stationId,
                    channelIndex: stations.idxMap[stationId] as number,
                };
                let newGridData = decodeEpgGrid(epgGridData, channelDetails) || [];
                newGridData = newGridData.filter((p) => !programIdIndex.includes(p.id)); // drop all already loaded programs from previous loaded timeslots

                set((state) => {
                    state.epgData.state = { ...state.epgData.state, [stationId]: 'Success' };
                    state.epgData.stationTimeslotState = {
                        ...state.epgData.stationTimeslotState,
                        [`${stationId}${timeslot}`]: 'Success',
                    };
                    state.epgData.value = epgData.concat(newGridData);
                    state.epgData.programIdIndex = programIdIndex.concat(
                        newGridData.map((p) => p.id),
                    );
                }, 'EPG/fetchEpgData');
            }
        } catch (e) {
            set((state) => {
                state.epgData.state = { ...state.epgData.state, [stationId]: 'Error' };
                state.epgData.stationTimeslotState = {
                    ...state.epgData.stationTimeslotState,
                    [`${stationId}${timeslot}`]: 'Error',
                };
            }, 'EPG/fetchEpgData/error');
        }
    },

    isTimeslotOnError: (stationId, timeslot) => {
        const { stationTimeslotState } = get().epgData;
        return stationTimeslotState[`${stationId}${timeslot}`] === 'Error';
    },

    fetchEpgStations: async () => {
        const { userHandle } = get();

        set((state) => {
            state.epgStations = {
                state: 'Loading',
                value:
                    state.epgStations.value.length > 0 ? state.epgStations.value : loadingChannels,
                favorites: [],
                idxMap: {},
            };
        }, 'EPG/fetchEpgStations');

        try {
            if (userHandle) {
                const fetchStationsConfig = StationServiceApi.config();
                const fetchStations = StationServiceApi.stations();

                const [{ data: stationConfig }, { data: stations }] = await Promise.all([
                    fetchStationsConfig,
                    fetchStations,
                ]);

                const decodedStations = decodeEpgStation(stations);
                const decodedStationConfig = decodeStationConfig(stationConfig, decodedStations);

                if (!decodedStationConfig) {
                    throw Error('Malformed station config');
                }

                if (!decodedStations) {
                    throw Error('Malformed station settings');
                }

                const stationIndex = decodedStationConfig.map((channel) => channel.id);
                let enumeration = 0;
                const idxMap: Record<string, number> = {};
                const favorites: Channel[] = [];
                const finalStationList = decodedStations.reduce(
                    (
                        acc,
                        { stationId, locked, omitted, userSettings: { visible, favorite } },
                        currentIndex,
                    ) => {
                        idxMap[stationId] = currentIndex;

                        const stationIdx = stationIndex.indexOf(stationId);
                        const station = decodedStationConfig[stationIdx];

                        if (!locked && visible) {
                            enumeration += 1;
                        }

                        if (station) {
                            const data = {
                                ...station,
                                enumeration: locked ? undefined : enumeration,
                                favorite,
                                visible,
                                locked,
                                omitted,
                            };

                            if (favorite && visible) {
                                favorites.push(data);
                            }

                            return acc.concat(data);
                        }

                        return acc;
                    },
                    <Channel[]>[],
                );

                set((state) => {
                    state.epgStations = {
                        state: 'Success',
                        value: finalStationList,
                        favorites,
                        idxMap,
                    };
                }, 'EPG/fetchEpgStations');
            } else {
                throw Error('UserHandle not set');
            }
        } catch (e) {
            // TODO handle 404 for new users

            set((state) => {
                state.epgStations = {
                    state: 'Error',
                    value: [],
                    favorites: [],
                    idxMap: {},
                };
            }, 'EPG/fetchEpgStations/error');
        }

        return [];
    },

    fetchEpgInfo: async () => {
        set((state) => {
            state.epgInfo = {
                state: 'Loading',
                value: undefined,
            };
        }, 'EPG/fetchEpgInfo');
        try {
            const { data: epgGridInfoData } = await EpgServiceApi.gridInfo();

            if (epgGridInfoData) {
                set((state) => {
                    state.epgInfo = {
                        state: 'Success',
                        value: decodeEpgGridInfo(epgGridInfoData),
                    };
                }, 'EPG/fetchEpgInfo');
            }
        } catch (e) {
            set((state) => {
                state.epgInfo = {
                    state: 'Error',
                };
            }, 'EPG/fetchEpgInfo/error');
        }
    },

    updateStation: async (stationId: string, favorite?: boolean, visible?: boolean) => {
        try {
            await StationServiceApi.update(stationId, favorite, visible); // TODO: could be handled asynchronously
            const { value: stations, idxMap } = get().epgStations;

            const updateFavoriteState = (channel: Channel) =>
                channel.uuid === stationId ? { ...channel, favorite } : channel;

            const nextStations = stations.map(updateFavoriteState);

            set((state) => {
                state.epgStations = {
                    state: 'Success',
                    value: nextStations,
                    favorites: nextStations.filter(
                        (channel) => channel.visible && channel.favorite,
                    ),
                    idxMap,
                };
            }, 'EPG/updateStation');
        } catch (e) {
            // TODO error handling
            set(() => {}, 'EPG/updateStation/error');
        }
    },

    getStationById: (stationId: string) => {
        const { value: stations, idxMap } = get().epgStations;
        const index = idxMap[stationId?.toLowerCase()];
        return index !== undefined ? stations[index] : undefined;
    },

    getNextAndPreviousChannel: (stationId: string) => {
        const favoriteFilter = get().showOnlyFavorites;
        const { value: stations, favorites } = get().epgStations;

        let list = favoriteFilter ? favorites : stations;

        list = list.filter((channel) => !channel.locked && channel.visible); // ignore locked channels

        // fallback to stations list if favorite list is empty
        if (list.length === 0 && favoriteFilter) {
            list = stations;
        } else if (stations.length === 0) {
            throw Error('Stations list is empty');
        }

        let index = list.findIndex((channel: Channel) => channel.uuid === stationId?.toLowerCase());

        // fallback on edge case if list was changed and/or channel is not in the selected list -> take first channel in list
        if (index === -1) {
            index = 0;
        }

        const previousIndex = infSequenceOffset(list, index - 1); // this is cheap and boundary protected based on the given array
        const nextIndex = infSequenceOffset(list, index + 1);

        return [list[previousIndex], list[nextIndex]];
    },

    fetchEpgDetails: async (programId: string) => {
        try {
            const { data } = await EpgServiceApi.details(programId);
            const decodedDetails = decodeProgramDetails(data);

            if (!decodedDetails) {
                throw Error('Program details malformed');
            }

            return decodedDetails;
        } catch (e) {
            console.error(e); // TODO handle error
        }

        return undefined;
    },

    flushLiveCache: () => {
        set((state) => {
            state.epgStations.value = [...state.epgStations.value]; //  needed to cause flush component caches TODO find better way to do so
        }, 'EPG/flushLiveCache');
    },

    getLiveProgram: async (stationId: string) => {
        const { fetchEpgData } = get();
        await fetchEpgData(stationId, getLiveUTCTimeSlot()); // make sure live TS data is in cache
        const { epgData } = get(); // important to get data after wait for promise

        // cache lookup: find live program
        const liveProgram = epgData.value.find(
            (program) => programBelongsToChannel(program, stationId) && programIsOnAir(program),
        );

        if (liveProgram) {
            set((state) => {
                state.epgData.live = { ...state.epgData.live, ...{ [stationId]: liveProgram } };
            }, 'EPG/getLiveProgram');
        }

        return liveProgram;
    },

    /**
     * Fetch epg program for relative "live" time slot
     */
    getProgramForTimestamp: async (stationId: string, liveTimestamp: number) => {
        const { fetchEpgData } = get();
        await fetchEpgData(stationId, getUTCTimeSlotForTimestamp(liveTimestamp)); // make sure live TS data is in cache
        const { epgData } = get(); // important to get data after wait for promise

        // cache lookup: find live program
        const liveProgram = epgData.value.find(
            (program) =>
                programBelongsToChannel(program, stationId) &&
                programIsOnAir(program, liveTimestamp),
        );

        return liveProgram;
    },
});
