/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, FunctionComponent } from 'preact';
import { useState } from 'preact/hooks';
import {
    getBaseUrl,
    validateProperties,
    isString,
    isStringArray,
    isObject,
    isArrayOf,
} from '@exaring/utils';
import FacebookIcon from '@exaring/assets/icons/social/facebook.svg';
import InstagramIcon from '@exaring/assets/icons/social/instagram.svg';
import YoutubeIcon from '@exaring/assets/icons/social/youtube.svg';
import TwitterIcon from '@exaring/assets/icons/social/twitter.svg';
import PaypalIcon from '@exaring/assets/icons/payment-methods/paypal.svg';
import MastercardIcon from '@exaring/assets/icons/payment-methods/mastercard.svg';
import MastercardSmallIcon from '@exaring/assets/icons/payment-methods/mastercard-squared.svg';
import SepaIcon from '@exaring/assets/icons/payment-methods/sepa.svg';
import VisaIcon from '@exaring/assets/icons/payment-methods/visa.svg';
import ExpandIcon from '@exaring/assets/icons/expand.svg';
import CollapseIcon from '@exaring/assets/icons/collapse.svg';
import { styled, css } from '../theme';
import { GridContainer, GridItem } from '../Grid/Grid';
import {
    TextLink,
    IconLink,
    LinkSectionData,
    FooterComponent,
    LinkFooterListItemProps,
    SocialLinkProps,
} from './types';

const isStringOrStringArray = (value: unknown): boolean => isString(value) || isStringArray(value);

const isTextLink = (value: unknown): value is TextLink =>
    isObject(value) &&
    validateProperties(value, {
        url: isStringOrStringArray,
        label: isStringOrStringArray,
    });

const isTextLinkList = (value: unknown[]): value is Array<TextLink> => isArrayOf(isTextLink, value);

//
// Statics
//

const MAX_CONTENT_SIZE = '960px';

const waipuDomain = getBaseUrl(window.location.href);

export const Links = {
    FACEBOOK_URL: 'https://www.facebook.com/waipu.tv/',
    INSTAGRAM_URL: 'https://www.instagram.com/waipu.tv',
    YOUTUBE_URL: 'https://www.youtube.com/channel/UCM8k2fsf7zhm2uhEeRuv6nA',
    TWITTER_URL: 'https://twitter.com/waiputv',
    IMPRINT_URL: `https://www.${waipuDomain}/impressum`,
    TERMINATION_URL: `https://microsite.${waipuDomain}/waipu/kuendigung`,
    TERMS_AND_CONDITIONS_URL: `https://www.${waipuDomain}/agb`,
    DATA_PRIVACY_STATEMENT_URL: `https://www.${waipuDomain}/dse`,
    CE_DECLARATION_URL: `https://www.${waipuDomain}/ce-konformitaet/`,
    WEBSITE_HD_TV_URL: `https://www.${waipuDomain}/hd-fernsehen/`,
    WEBSITE_OFFERS_URL: `https://www.${waipuDomain}/angebote/`,
    WEBSITE_NETFLIX_URL: `https://www.${waipuDomain}/netflix/`,
    WEBSITE_OPTIONS_URL: `https://www.${waipuDomain}/optionen/`,
    WEBSITE_PRESS_URL: `https://www.${waipuDomain}/presse/`,
    WEBSITE_TRANSPARENCY: `https://www.${waipuDomain}/transparenzhinweise`,
    WEBSITE_CANCELLATION_URL: `https://microsite.waipu.tv/waipu/kuendigung`,
    EXARING_URL: 'http://www.exaring.de/',
    EXARING_CAREER_URL: 'http://www.exaring.de/#karriere',
    EXARING_ADVERTISING_URL: 'https://www.exaring.de/#werbevermarktung',
    HELP_DESK_URL: 'https://hilfe.waipu.de/hc/de',
    HELP_DESK_FAQ_URL: 'https://hilfe.waipu.de/hc/de/categories/200901179-FAQ',
    HELP_DESK_FIRST_STEPS_URL: 'https://hilfe.waipu.de/hc/de/categories/200901189-Neu-bei-waipu-tv',
    HELP_DESK_SETUP_URL: 'https://hilfe.waipu.de/hc/de/categories/200901199-waipu-tv-einrichten',
    HELP_DESK_USAGE_URL: 'https://hilfe.waipu.de/hc/de/categories/200927235-waipu-tv-nutzen',
    HELP_DESK_ACCOUNT_URL: 'https://hilfe.waipu.de/hc/de/categories/200901209-Konto-und-Profil',
    HELP_DESK_CONTACT_URL: `https://hilfe.waipu.de/hc/de/requests/new`,
} as const;

const PaymentLinks: IconLink[] = [
    { icon: PaypalIcon, visibility: 'always', minHeight: '14px', maxHeight: '20px' },
    { icon: VisaIcon, visibility: 'always', minHeight: '12px', maxHeight: '20px' },
    { icon: MastercardSmallIcon, visibility: 'xs-only', minHeight: '24px', maxHeight: '24px' },
    { icon: MastercardIcon, visibility: 'sm', minHeight: '20px', maxHeight: '20px' },
    { icon: SepaIcon, visibility: 'always', minHeight: '11px', maxHeight: '20px' },
];

const UpsellLinks: TextLink[] = [
    {
        url: Links.WEBSITE_OFFERS_URL,
        label: 'Angebote',
        dataClickClass: 'footer',
        dataClickId: 'offers',
    },
    {
        url: Links.WEBSITE_NETFLIX_URL,
        label: 'Netflix',
        dataClickClass: 'footer',
        dataClickId: 'netflix',
    },
    {
        url: Links.WEBSITE_OPTIONS_URL,
        label: 'Optionen',
        dataClickClass: 'footer',
        dataClickId: 'options',
    },
    {
        url: Links.WEBSITE_HD_TV_URL,
        label: 'HD Fernsehen',
        dataClickClass: 'footer',
        dataClickId: 'hd_tv',
    },
];

const ServiceLinks: TextLink[] = [
    {
        url: Links.HELP_DESK_URL,
        label: 'Hilfebereich',
        dataClickClass: 'footer',
        dataClickId: 'help_section',
    },
    {
        url: Links.HELP_DESK_FAQ_URL,
        label: 'Häufige Fragen',
        dataClickClass: 'footer',
        dataClickId: 'faq',
    },
    {
        url: Links.HELP_DESK_CONTACT_URL,
        label: 'Kontakt',
        dataClickClass: 'footer',
        dataClickId: 'contact',
    },
];

const AboutLinks: TextLink[] = [
    {
        url: Links.EXARING_URL,
        label: 'Über uns',
        dataClickClass: 'footer',
        dataClickId: 'about_us',
    },
    {
        url: Links.EXARING_CAREER_URL,
        label: 'Karriere',
        dataClickClass: 'footer',
        dataClickId: 'career',
    },
    {
        url: Links.WEBSITE_PRESS_URL,
        label: 'Presse',
        dataClickClass: 'footer',
        dataClickId: 'press',
    },
    {
        url: Links.EXARING_ADVERTISING_URL,
        label: 'Werbung',
        dataClickClass: 'footer',
        dataClickId: 'advertisement',
    },
];

const SocialIconSize = 26;
const SocialIcons = [
    {
        url: Links.FACEBOOK_URL,
        title: 'Facebook',
        icon: FacebookIcon,
        dataClickClass: 'footer',
        dataClickId: 'facebook',
    },
    {
        url: Links.INSTAGRAM_URL,
        title: 'Instagram',
        icon: InstagramIcon,
        dataClickClass: 'footer',
        dataClickId: 'instagram',
    },
    {
        url: Links.YOUTUBE_URL,
        title: 'YouTube',
        icon: YoutubeIcon,
        dataClickClass: 'footer',
        dataClickId: 'youtube',
    },
    {
        url: Links.TWITTER_URL,
        title: 'Twitter',
        icon: TwitterIcon,
        dataClickClass: 'footer',
        dataClickId: 'twitter',
    },
] as const;

const LegalLinks: TextLink[] = [
    {
        url: Links.IMPRINT_URL,
        label: 'Impressum',
        dataClickClass: 'footer',
        dataClickId: 'site_notice',
    },
    {
        url: Links.TERMS_AND_CONDITIONS_URL,
        label: 'AGB',
        dataClickClass: 'footer',
        dataClickId: 'terms_and_conditions',
    },
    {
        url: Links.DATA_PRIVACY_STATEMENT_URL,
        label: 'Datenschutz',
        dataClickClass: 'footer',
        dataClickId: 'data_privacy',
    },
    {
        url: Links.CE_DECLARATION_URL,
        label: 'CE-Deklaration',
        dataClickClass: 'footer',
        dataClickId: 'conformity_declaration',
    },
    {
        url: Links.WEBSITE_CANCELLATION_URL,
        label: 'Kündigung',
        dataClickClass: 'footer',
        dataClickId: 'cancellation',
    },
    {
        url: Links.WEBSITE_TRANSPARENCY,
        label: 'Transparenz',
        dataClickClass: 'footer',
        dataClickId: 'transparency',
    },
];

const Sections: LinkSectionData[] = [
    { id: 'payment-toggle', label: 'Sicher bezahlen', values: PaymentLinks },
    { id: 'waipu-toggle', label: 'Mehr über waipu.tv', values: UpsellLinks },
    { id: 'service-toggle', label: 'Kundenservice', values: ServiceLinks },
    { id: 'company-toggle', label: 'Unternehmen', values: AboutLinks },
];

//
// Styled components
//

const Row = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '30px',

    '@sm': {
        paddingBottom: '20px',
    },
});

const StyledFooter = styled('footer', {
    backgroundColor: '$white',
    color: '$black',
    borderBottom: '$7 solid $black',
    lineHeight: '20px',
    fontFamily: '$primary',
    fontSize: '$2',
    fontWeight: '$1',
});

const StyledFooterBarContainer = styled('section', {
    padding: '$3 $4',
    borderTop: '1px solid $lightgray',

    '@xs': {
        padding: '$4 $4',
        borderTop: 'none',
    },
    '@sm': {
        padding: '$3 $4',
    },
});

const StyledList = styled('ul', {
    listStyle: 'none',
    padding: 0,
    margin: 0,
});

const StyledSocialList = styled(Row, {
    justifyContent: 'center',

    '@xs': {
        gap: '$3',
        paddingBottom: '30px',
    },
    '@sm': {
        gap: '$1',
    },
    '@md': {
        gap: '$4',
    },
});

const StyledLegalList = styled(Row, {
    justifyContent: 'center',
    flexWrap: 'wrap',

    '@xs': {
        gap: '$3 $2',
    },
    '@sm': {
        gap: '$1',
    },
    '@md': {
        gap: '$3',
    },
});

const StyledLink = styled('a', {
    display: 'inline-flex',
    fontWeight: '$2',
    color: '$black',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    '&:hover, &:focus': {
        textDecoration: 'underline',
    },
});

const StyledIconLink = styled(StyledLink, {
    width: '$3',
    height: '$3',
    display: 'inline-block',
    overflow: 'hidden',
    backgroundPosition: 'left center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 24,
});

const StyledCopyrightSpan = styled('span', {
    display: 'block',
    color: '$darkgray',
    textAlign: 'center',
});

const StyledContent = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '$4',
    paddingRight: '$4',

    '@sm': {
        paddingTop: '$8',
    },
});

const StyledFooterList = styled(StyledList, {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '$1',
    paddingLeft: '$4',
    gap: '$2',

    '@sm': {
        marginTop: '$2',
        paddingLeft: 0,
        flexDirection: 'column',
        gap: 0,
    },

    variants: {
        orientation: {
            row: {
                flexDirection: 'row',
                gap: '$2',
                marginTop: '$1',
            },
            column: {
                flexDirection: 'column',
                gap: 0,
                marginTop: '$2',
            },
        },
    },
});

const StyledToggleCheckbox = styled('input', {
    // hide the checkbox
    position: 'absolute',
    zIndex: -1,
    opacity: 0,
    '@sm': {
        display: 'none',
    },
});

const StyledToggleLabel = styled('label', {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '$2',
    fontWeight: '$2',
    lineHeight: '20px',

    '@sm': {
        fontSize: '$3',
        fontWeight: '$1',
        lineHeight: 'normal',
        cursor: 'default',
    },
});

const StyledToggleSection = styled('section', {
    paddingTop: '$1',
    paddingBottom: '$1',
    borderBottom: '1px solid $lightgray',
    '@sm': {
        borderBottom: 'none',
    },
});

const StyledToggleChildren = styled('div', {
    variants: {
        display: {
            false: {
                display: 'none',
                '@sm': {
                    display: 'block',
                },
            },
        },
    },
});

const StyledListItem = styled('li', {
    marginBottom: '$2',
    variants: {
        visibility: {
            always: {
                display: 'visible',
            },
            'xs-only': {
                '@xs': {
                    display: 'visible',
                },
                '@sm': {
                    display: 'none',
                },
            },
            sm: {
                '@xs': {
                    display: 'none',
                },
                '@sm': {
                    display: 'inline-block',
                },
            },
        },
    },
});

const ExpandCollapseStyle = css({
    // The properties 'height', 'top' need exact pixel values for positioning,
    // that's why we can't use stitches theme tokens here.
    height: 32,
    top: 12,
    position: 'relative',
    '@sm': {
        display: 'none',
    },
});
const StyledExpandIcon = styled(ExpandIcon, ExpandCollapseStyle);
const StyledCollapseIcon = styled(CollapseIcon, ExpandCollapseStyle);

//
// Components
//

const SocialLink: FunctionComponent<SocialLinkProps> = ({
    href,
    title,
    icon,
    dataClickClass,
    dataClickId,
}) => (
    <StyledIconLink
        target="_blank"
        href={href}
        rel="noopener noreferrer"
        title={title}
        data-click-class={dataClickClass}
        data-click-id={dataClickId}
    >
        {icon || title}
    </StyledIconLink>
);

const FooterBar = () => {
    return (
        <StyledFooterBarContainer>
            <StyledSocialList>
                {SocialIcons.map(({ url, title, icon: Icon, dataClickClass, dataClickId }) => (
                    <SocialLink
                        href={url}
                        title={title}
                        dataClickClass={dataClickClass}
                        dataClickId={dataClickId}
                        icon={<Icon disableBoundingReset height={SocialIconSize} />}
                    />
                ))}
            </StyledSocialList>
            <StyledLegalList>
                {LegalLinks.map(({ url, label, dataClickClass, dataClickId }) => (
                    <StyledLink
                        href={url}
                        data-click-class={dataClickClass}
                        data-click-id={dataClickId}
                    >
                        {label}
                    </StyledLink>
                ))}
            </StyledLegalList>
            <StyledCopyrightSpan>&copy; EXARING AG</StyledCopyrightSpan>
        </StyledFooterBarContainer>
    );
};

const LinkFooterListItem: FunctionComponent<LinkFooterListItemProps> = ({
    href,
    label,
    dataClickId,
    dataClickClass,
}) => (
    <StyledListItem>
        <StyledLink
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            data-click-class={dataClickClass}
            data-click-id={dataClickId}
        >
            {label}
        </StyledLink>
    </StyledListItem>
);

const IconLinkList: FunctionComponent<{
    values: IconLink[];
}> = ({ values }) => (
    <StyledFooterList>
        {values.map(({ icon: Icon, visibility, minHeight, maxHeight }) => (
            <StyledListItem visibility={visibility}>
                <Icon disableBoundingReset style={{ minHeight, maxHeight }} />
            </StyledListItem>
        ))}
    </StyledFooterList>
);

const TextLinkList: FunctionComponent<{
    values: TextLink[];
}> = ({ values }) => (
    <StyledFooterList orientation="column">
        {values.map(({ url, label, dataClickClass, dataClickId }) => (
            <LinkFooterListItem
                href={url}
                label={label}
                dataClickClass={dataClickClass}
                dataClickId={dataClickId}
            />
        ))}
    </StyledFooterList>
);

const LinkSection: FunctionComponent<LinkSectionData> = ({ id, label, values }) => (
    <GridItem sm={3}>
        <FooterToggle id={id} label={label}>
            {isTextLinkList(values) ? (
                <TextLinkList values={values} />
            ) : (
                <IconLinkList values={values} />
            )}
        </FooterToggle>
    </GridItem>
);

const FooterToggle: FunctionComponent<{
    id: string;
    label: string;
    open?: boolean;
}> = ({ id, open = false, label, children }) => {
    const [checked, setChecked] = useState(open);

    const handleCheckboxChange = (event: any) => {
        setChecked(event?.target?.checked);
    };

    return (
        <StyledToggleSection>
            <StyledToggleCheckbox
                id={id}
                type="checkbox"
                checked={checked}
                onChange={handleCheckboxChange}
            />
            <StyledToggleLabel htmlFor={id}>
                {checked ? <StyledCollapseIcon /> : <StyledExpandIcon />}
                {label}
            </StyledToggleLabel>

            <StyledToggleChildren display={checked}>{children}</StyledToggleChildren>
        </StyledToggleSection>
    );
};

//
// Component
//

export const Footer: FooterComponent = () => (
    <StyledFooter>
        <StyledContent>
            <GridContainer css={{ maxWidth: MAX_CONTENT_SIZE, flexGrow: 1 }}>
                {Sections.map(({ id, label, values }) => (
                    <LinkSection id={id} label={label} values={values} />
                ))}
            </GridContainer>
        </StyledContent>
        <FooterBar />
    </StyledFooter>
);
