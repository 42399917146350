import { CTime, isNil } from '@exaring/utils';
import { useContext, useMemo, useEffect } from 'preact/hooks';
import { ScrollContext } from '../context/ScrollContext';

export const useEpgTimeScroll = (
    start: CTime,
    end: CTime,
    totalWidthPx: number,
    scrollXPx: number,
    offsetLeftPx: number,
    scrollToRight: (px?: number) => void,
) => {
    const startEndSecondsDiff = useMemo(() => end.diff(start), [start, end]);

    const pxPerSecond = useMemo(
        () => totalWidthPx / startEndSecondsDiff,
        [startEndSecondsDiff, totalWidthPx],
    );
    const secondsPerPx = useMemo(
        () => startEndSecondsDiff / totalWidthPx,
        [startEndSecondsDiff, totalWidthPx],
    );

    const scrollContext = useContext(ScrollContext);
    const { setScrollToTimeHandler, setDayUnix, setHour } = scrollContext;

    const scrollTime = useMemo(
        () => start.add(secondsPerPx * (scrollXPx + offsetLeftPx), 'seconds'),
        [start, secondsPerPx, scrollXPx, offsetLeftPx],
    );

    useEffect(() => {
        const scrollHour = scrollTime.get('hour');
        const scrollMinute = scrollTime.get('minute');

        setScrollToTimeHandler((dayUnix, hour) => {
            let secondsDiff = 0;

            if (!isNil(dayUnix) && !isNil(hour)) {
                secondsDiff = dayUnix - scrollTime.unix() + hour * 3600;
            } else if (!isNil(dayUnix)) {
                secondsDiff = dayUnix - scrollTime.unix() + scrollHour * 3600 + scrollMinute * 60;
            } else if (!isNil(hour)) {
                secondsDiff = (hour - scrollHour) * 3600 - scrollMinute * 60;
            }

            scrollToRight(pxPerSecond * secondsDiff);
        });
    }, [setScrollToTimeHandler, scrollToRight, scrollTime, pxPerSecond]);

    useEffect(() => {
        const hour = scrollTime.get('hour');
        const minutes = scrollTime.get('minutes');
        const decimalMinutes = minutes !== 0 ? minutes / 60 : 0;
        setDayUnix(scrollTime.unix());
        setHour(hour + decimalMinutes);
    }, [scrollTime, setDayUnix, setHour]);
};
