/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, FunctionComponent } from 'preact';
import { CSS } from '@stitches/react';
import Flicking, { ViewportSlot } from '@egjs/preact-flicking';
import { Arrow } from '@egjs/flicking-plugins';
import '@egjs/preact-flicking/dist/flicking.css';

import { useRef } from 'preact/hooks';
import { styled } from '../theme';
import { GridItem } from '../Grid';
import { LayoutType } from '../ProgramDetailPage/types';

const leftPseudoClasses = {
    '&:before': {
        top: '50%',
        left: '27%',
        transform: 'rotate(-45deg)',
        transformOrigin: '0% 50%',
    },
    '&:after': {
        top: 'calc(50% - 1px)',
        left: '12px',
        transform: 'rotate(45deg)',
        transformOrigin: '0% 50%',
    },
};

const rightPseudoClasses = {
    '&:before': {
        top: '50%',
        right: '27%',
        transform: 'rotate(45deg)',
        transformOrigin: '100% 50%',
    },
    '&:after': {
        top: 'calc(50% - 1px)',
        right: '12px',
        transform: 'rotate(-45deg)',
        transformOrigin: '100% 50%',
    },
};

const StyledArrow = styled('span', {
    display: 'none',
    position: 'absolute',
    width: '44px',
    height: '44px',
    top: '50%',
    transform: 'translateY(-50%)',
    opacity: '0.9',
    borderRadius: '5px',
    backgroundColor: '$nearBlack',

    cursor: 'pointer',
    zIndex: 2,

    '@media (hover:none), (hover:on-demand), (pointer:coarse)': {
        display: 'inherit',
    },

    '&:after,&:before': {
        content: '',
        width: '23px',
        height: '2px',
        position: 'absolute',
        backgroundColor: '$white',
    },

    '&:hover:after': {
        top: 'calc(50% - 2px)',
    },

    '&:hover': {
        '&:after,&:before': {
            height: '3px',
        },
    },

    variants: {
        position: {
            left: {
                left: '10px',
                ...leftPseudoClasses,
            },
            right: {
                right: '10px',
                ...rightPseudoClasses,
            },
            leftEdge: {
                left: '-10px',
                ...leftPseudoClasses,
            },
            rightEdge: {
                right: '-10px',
                ...rightPseudoClasses,
            },
        },
    },
});

const FlickingContainer = styled(Flicking as any, {
    color: '$white',
    padding: '20px 0 20px 65px',

    variants: {
        layout: {
            modal: {
                marginTop: '24px',
                padding: '0px $$containerIndent 0 0',
            },
            player: {
                marginTop: '24px',
                padding: '0px $$containerIndent 0 0',
            },
            epg: {
                marginLeft: '-70px',
                padding: '20px 65px',
                width: 'calc(100% + 140px)',
            },
            mediathek: {
                marginLeft: '-70px',
                padding: '20px 65px',
                width: 'calc(100% + 140px)',
            },
            microSite: {},
        },
    },

    [`&:hover ${StyledArrow}`]: {
        display: 'inherit',
    },
});

export const ScrollerItem = styled(GridItem, {
    marginRight: '$2',
    transition: 'transform 0.2s',
    '&:hover': {
        transform: 'scale(1.05)',
    },
});

export const Scroller: FunctionComponent<{
    css?: CSS;
    translateArrow?: string;
    bulletCount?: number;
    children?: JSX.Element[];
    defaultIndex?: number;
    layout?: LayoutType;
}> = ({ css, translateArrow, children, defaultIndex, layout }) => {
    const plugins = useRef([new Arrow({ moveByViewportSize: true })]);
    const arrowCss = translateArrow ? { transform: `translateY(${translateArrow})` } : undefined;

    const stickArrowsToEdges = layout === 'player' || layout === 'modal';

    return (
        <FlickingContainer
            layout={layout}
            css={css}
            plugins={plugins.current}
            align="prev"
            defaultIndex={defaultIndex || 0}
            panelsPerView={-1}
            viewportTag="div"
            circular={false} // endless scrolling. If the sum of the panel sizes is too small, circular will not be enabled.
            bound // Only can be enabled when circular=false. Prevent the view(camera element) from going out of the first/last panel, so it won't show empty spaces before/after the first/last panel
            duration={300} // Default duration of the animation (ms)
            inputType={['touch', 'mouse']}
            moveType="snap"
            threshold={40} // It should be dragged above the threshold to change the current panel. (moveType: ["strict", { count: 1 }])
            interruptable // Set animation to be interruptable by click/touch.
            bounce="10%" // The size value of the bounce area. Only can be enabled when circular=false.
            preventClickOnDrag // Automatically prevent click event if the user has dragged at least a single pixel on the viewport element.
            renderOnlyVisible // Whether to render visible panels only. This can dramatically increase performance when there're many panels
            autoResize // Whether to automatically call resize() when the viewport element(.flicking-viewport)'s size is changed. Change window size or change orientation(on mobile) to see its effect.
            resizeDebounce={100} // Delays size recalculation from autoResize by the given time in milisecond.
        >
            {children}

            <ViewportSlot>
                <StyledArrow
                    className="flicking-arrow-prev"
                    position={stickArrowsToEdges ? 'leftEdge' : 'left'}
                    css={arrowCss}
                />
                <StyledArrow
                    className="flicking-arrow-next"
                    position={stickArrowsToEdges ? 'rightEdge' : 'right'}
                    css={arrowCss}
                />
            </ViewportSlot>
        </FlickingContainer>
    );
};
