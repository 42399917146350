/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, ComponentChildren } from 'preact';
import { forwardRef } from 'preact/compat';
import tileFallback from '@exaring/assets/images/tileFallback-m.png';
import { formatDateTime } from '@exaring/utils/date';
import { CSS } from '../../theme';
import { ProgramProgressBar } from '../../ProgramProgressBar/ProgramProgressBar';
import { Typography } from '../../Typography/Typography';
import { StyledTileContainer } from '../parts/StyledTileContainer';
import { StyledTileImage } from '../parts/StyledTileImage';
import { StyledMetaSpan } from '../parts/StyledMetaSpan';
import { headlineStyle } from '../styles';
import { StyledTileContent } from '../parts/StyledTileContent';
import { VideoTileImageContainer } from '../parts/VideoTileImageContainer';

export const MediumTile = forwardRef<
    HTMLDivElement,
    {
        css?: CSS;
        className?: string;
        title?: string;
        airtime?: string;
        duration?: string;
        genre?: string;
        imageSrc?: string;
        locked?: boolean;
        channel?: string;
        contentStyles?: CSS;
        children?: ComponentChildren;
        noMediaOverlay?: boolean;
        onMediaPlayout?: (event: MouseEvent) => void;
        onOpenDetails?: (event: MouseEvent) => void;
        progressbarPosition?: number;
        startTime?: string;
    }
>(
    (
        {
            css,
            className,
            title,
            airtime,
            duration,
            genre,
            imageSrc,
            locked,
            channel,
            children,
            contentStyles,
            noMediaOverlay,
            onMediaPlayout,
            onOpenDetails,
            progressbarPosition,
            startTime,
        },
        ref,
    ) => {
        return (
            <StyledTileContainer
                ref={ref}
                size={{
                    '@initial': 'mobile',
                    '@sm': 'default',
                }}
                css={css}
                className={className}
            >
                <VideoTileImageContainer
                    locked={locked}
                    onClick={onMediaPlayout}
                    hideAllOverlays={noMediaOverlay}
                >
                    <StyledTileImage src={imageSrc} srcFallback={tileFallback} />
                    {!!progressbarPosition && progressbarPosition > 0 && (
                        <ProgramProgressBar progress={progressbarPosition} noBorderRadius />
                    )}
                </VideoTileImageContainer>

                <StyledTileContent css={contentStyles} onClick={onOpenDetails as any}>
                    <Typography css={headlineStyle} variant="h2">
                        {title}
                    </Typography>
                    {airtime && <StyledMetaSpan>{airtime}</StyledMetaSpan>}
                    <StyledMetaSpan css={{ color: '$lightBlue' }}>{`${formatDateTime(
                        startTime,
                        !!startTime && !!duration,
                    )}${duration}`}</StyledMetaSpan>
                    <StyledMetaSpan>{genre}</StyledMetaSpan>
                    {channel && <StyledMetaSpan>{channel}</StyledMetaSpan>}
                    {children}
                </StyledTileContent>
            </StyledTileContainer>
        );
    },
);
