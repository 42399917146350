import { h, Fragment } from 'preact';
import { useEffect, useCallback } from 'preact/hooks';
import { connect } from 'unistore/preact';

import { memo } from 'preact/compat';

import { Spinner } from '@exaring/ui';

import playerActions from '../../../actions/player';
import { parentalRatingType, CLEARANCE_LEVEL } from '../../../actions/helper/parental-guidance';

import AgeVerificationHint from './AgeVerificationHint';
import AgeVerificationModule from './AgeVerificationModule';
import { OSDOverlayScreen } from '../OSD/OSDOverlayScreen';
import { WebClientGAEvent } from '../../../web-client-ga';
import { trackWithUserContext } from '../../../tracking';
import { userStore, useUserStore } from '../../../state/Store';

let _timeout;

const cleanTimeout = (cb, timer = 3000) => {
    clearTimeout(_timeout);
    _timeout = setTimeout(cb, timer);
};

const { VERIFICATION_REQUIRED, PARENTAL_ADVISORY, PARENTAL_ADVISORY_CHANNEL, CLEARED } =
    CLEARANCE_LEVEL;

const isAdvisory = (level) => [PARENTAL_ADVISORY, PARENTAL_ADVISORY_CHANNEL].includes(level);

const nextClearance = (rating, level) => {
    const isProgramOnlyRating = !rating.includes('+');
    if (isProgramOnlyRating || level === PARENTAL_ADVISORY) {
        return CLEARED;
    }
    return PARENTAL_ADVISORY;
};

const parentalControlRatingText = (level, rating) => {
    if (
        level === PARENTAL_ADVISORY_CHANNEL ||
        (level === VERIFICATION_REQUIRED && rating.includes('channel'))
    ) {
        return `Dieser Channel kann Inhalte enthalten, die für Zuschauer unter ${parentalRatingType[rating]} Jahren nicht geeignet sind.`;
    }

    return `Dieser Film ist nicht für Zuschauer unter ${parentalRatingType[rating]} Jahren geeignet.`;
};

const AgeVerificationBlocker = ({
    rating = 'fsk-18',
    allowedFSK,
    parentalGuidanceLevel,
    ageVerificationLoading,
    handleAgeVerification,
    pinEnabled,
    play,
    pause,
    pinRequired,
}) => {
    useEffect(() => {
        trackWithUserContext(WebClientGAEvent.ParentalControlBlocker);
        userStore().checkForUserAgeVerification();
        return () => {
            clearTimeout(_timeout);
        };
    }, []); // eslint-disable-line

    useEffect(() => {
        if ((!pinEnabled && !!allowedFSK) || !pinRequired) {
            cleanTimeout(() => {
                handleAgeVerification(CLEARED);
                play();
            }, 1000);
        } else if (isAdvisory(parentalGuidanceLevel)) {
            // clearance level 2 -> soft blocker, with auto release
            pause();
            cleanTimeout(() => {
                handleAgeVerification(nextClearance(rating, parentalGuidanceLevel));
            });
        } else if (parentalGuidanceLevel === CLEARED) {
            // clearance
            play();
        }
    }, [
        pinEnabled,
        allowedFSK,
        parentalGuidanceLevel,
        rating,
        handleAgeVerification,
        play,
        pause,
        pinRequired,
    ]);

    const renderVerificationContent = useCallback(() => {
        if (!allowedFSK) {
            return <AgeVerificationHint />;
        }
        if (!pinEnabled || isAdvisory(parentalGuidanceLevel) || !pinRequired) {
            // dont show pin dialog as blocker is automatically resolved after 3sek.
            return undefined;
        }
        return (
            <AgeVerificationModule
                onVerificationSuccess={() => {
                    handleAgeVerification(nextClearance(rating, parentalGuidanceLevel));
                }}
            />
        );
    }, [pinEnabled, allowedFSK, handleAgeVerification, parentalGuidanceLevel, rating, pinRequired]);

    const renderVerificationInfo = useCallback(
        () => parentalControlRatingText(parentalGuidanceLevel, rating),
        [parentalGuidanceLevel, rating],
    );

    return (
        parentalGuidanceLevel < CLEARANCE_LEVEL.CLEARED && (
            <OSDOverlayScreen type="warn-white" className="media-player__blocker">
                {ageVerificationLoading ? (
                    <Spinner key="Spinner" />
                ) : (
                    <Fragment>
                        <div className="notification__title">
                            <span className="rating-info">{renderVerificationInfo()}</span>
                        </div>
                        <div className="notification__content">{renderVerificationContent()}</div>
                    </Fragment>
                )}
            </OSDOverlayScreen>
        )
    );
};

const connectUserStore = (Component) => {
    return (props) => {
        const { ageVerificationLoading, allowedFSK, pinEnabled } = useUserStore();

        return (
            <Component
                {...props}
                ageVerificationLoading={ageVerificationLoading}
                allowedFSK={allowedFSK}
                pinEnabled={pinEnabled}
            />
        );
    };
};

export default memo(connectUserStore(connect(undefined, playerActions)(AgeVerificationBlocker)));
