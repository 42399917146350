/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, Fragment } from 'preact';
import { useRef, useEffect, useCallback } from 'preact/hooks';
import { memo } from 'preact/compat';
import { SearchInput, Close } from '@exaring/ui/components/header--logged-in/navigation-items';
import { events } from '@exaring/utils';
import { useNativeEvents } from '@exaring/ui/hooks';
import { keyboardCodes } from '@exaring/ui';

import { useSearchStore, useUiStore } from '../../state/Store';
import { WebClientGAEvent } from '../../web-client-ga';
import { searchRenderCondition } from './helper';
import { getCurrentScreen } from '../../helper';

let pendingSearch;

const throttledDebounce = (cb, ms) => {
    clearTimeout(pendingSearch);
    pendingSearch = setTimeout(cb, ms);
};

const SearchBar = memo(({ track }) => {
    const ref = useRef();
    const { setShowBuildInfo, isSearchActive } = useUiStore();
    const {
        search: { state },
        searchEpg,
        resetState,
        resetResultData: resetResults,
        updateQuery,
        query: searchQuery,
    } = useSearchStore();
    const { toggleSearchActive } = useUiStore();
    const isSearchPending = state === 'Loading';

    const getInput = useCallback(() => ref.current, [ref]);

    const getSearchValue = useCallback(() => {
        return getInput()?.value || '';
    }, [getInput]);

    const setSearchValue = useCallback(
        (val) => {
            const input = getInput();
            if (input?.value) {
                input.value = val;
            }
        },
        [getInput],
    );

    const resetSearch = useCallback(
        (restoreActive = false) => {
            resetState();
            toggleSearchActive(restoreActive);

            setSearchValue('');
        },
        [resetState, setSearchValue, toggleSearchActive],
    );

    const closeSearch = useCallback(() => {
        resetSearch();
        track(WebClientGAEvent.Search, WebClientGAEvent.SearchClosed, {
            screenName: getCurrentScreen(),
        });
    }, [resetSearch, track]);

    const _search = useCallback(() => {
        const val = getSearchValue().trim();

        if (isSearchActive && val.length > 2) {
            searchEpg(val);
        } else {
            resetResults();
        }
    }, [getSearchValue, resetResults, isSearchActive, searchEpg]);

    const search = useCallback(() => throttledDebounce(_search, 350), [_search]); // debounce search requests

    const onUpdate = useCallback(() => {
        const matchValue = getSearchValue().trim().toLowerCase();

        if ([':debug', ':hilfe'].includes(matchValue)) {
            setShowBuildInfo(true);
            closeSearch();
            return;
        }

        if (matchValue.startsWith(':epg2')) {
            const commandList = matchValue.split(' ');
            if (commandList[1]) {
                closeSearch();
            }

            return;
        }

        updateQuery(getSearchValue());
        search();
    }, [getSearchValue, updateQuery, search, setShowBuildInfo, closeSearch]);

    const focusSearch = useCallback(() => {
        getInput().focus();
    }, [getInput]);

    const eventMap = {
        keydown: (e) => {
            const { code } = e;
            if (code === keyboardCodes.escape) {
                events.pauseEvent(e); // prevent other esc handler from running
                if (getSearchValue().length) {
                    resetSearch(true);
                } else {
                    closeSearch();
                }
            } else if (code === keyboardCodes.enter) {
                search();
            }
        },
    };

    useNativeEvents(eventMap);

    useEffect(() => {
        focusSearch();
    }, [focusSearch]);

    return (
        <Fragment>
            <SearchInput
                icon="search"
                iconClassName={isSearchPending && 'icon--onPending'}
                className="header--logged-in__main-item--search"
                inputRef={ref}
                searchQuery={searchQuery}
                onInputUpdate={onUpdate}
                action={focusSearch}
            />
            <Close
                action={() => {
                    resetSearch();
                    track(WebClientGAEvent.Search, WebClientGAEvent.SearchClosed, {
                        screenName: getCurrentScreen(),
                    });
                }}
            />
        </Fragment>
    );
}, searchRenderCondition);

export default SearchBar;
