import { getDaiConsent, getEuConsent, isEmptyString, isNil } from '@exaring/utils';

export const enableDaiTracking = () => {
    const cookie = getDaiConsent();
    return !isNil(cookie) && !isEmptyString(cookie) && cookie !== 'false';
};

export const getGdprConsent = () => {
    if (!enableDaiTracking()) {
        return undefined;
    }

    return getEuConsent();
};
