/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

/**
 * Verify if a value is in range of min and max with optional boundary
 * inclusion.
 * @param {number} value
 * @param {number} min
 * @param {number} max
 * @param {boolean} [bounds] flag to enable boundary inclusion
 * @returns {boolean}
 */
export const inRange = (value, min, max, includeBoundaries = false) => {
    if (includeBoundaries) {
        return value >= Math.min(min, max) && value <= Math.max(min, max);
    }

    return value > Math.min(min, max) && value < Math.max(min, max);
};

/**
 * Returns integer number based on defined range.
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
export const randomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
};

/**
 * Returns the value clipped within the given boundary.
 * @param {number} value
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
export const clamp = (value, min, max) =>
    Math.min(Math.max(value, Math.min(min, max)), Math.max(min, max));

/**
 * Returns number of decimals in a number
 * @param {number} value
 * @returns {number}
 */
export const decimalCount = (value) => {
    if (typeof value !== 'number') {
        return 0;
    }

    const strNumber = `${value}`;
    const isFloat = strNumber.includes('.');

    return isFloat ? strNumber.split('.')[1].length : 0;
};
