/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';
import { NotificationScreen } from '@exaring/ui';
import { isEdgeBrowser } from '../../helper';
import ErrorTypes, { PlayoutErrorTypes } from '../../errorTypes';

const { POLICY, MAINTENANCE, GENERAL_ERROR, DENIED_BY_LOCATION } = PlayoutErrorTypes;
const {
    StreamLimitation,
    Network,
    Drm,
    Playback,
    General,
    Setup,
    Vr,
    Source,
    Module,
    Other,
    StillWatching,
    StreamExpired,
} = ErrorTypes;

const getNotifyProps = (errorType, action, program) => {
    const props = {
        type: 'info',
        title: 'Entschuldigung, es ist ein unerwarteter Fehler aufgetreten!',
        content: 'Bitte probieren Sie es später noch einmal.',
        buttons: [{ label: 'Erneut versuchen', action }],
        unkownError: true,
    };

    const drmContent =
        'Bitte versuchen Sie es später noch einmal und stellen Sie sicher, dass Sie einen von uns ' +
        'unterstützen Browser (Firefox, Chrome, Safari) mit der aktuellsten Browser-Version verwenden.';

    switch (errorType) {
        case StillWatching:
            return {
                ...props,
                title: 'Schauen Sie noch?',
                content: '',
                buttons: [{ label: 'Ja', action }],
                unkownError: false,
            };

        case StreamExpired:
            return {
                ...props,
                title: 'Der Stream ist nicht mehr gültig.',
                unkownError: false,
            };

        case StreamLimitation:
            return {
                ...props,
                title: 'Maximale Streamanzahl erreicht.',
                content: 'Bitte beenden Sie einen laufenden Stream, um jetzt fernzusehen.',
                unkownError: false,
            };

        case Network:
            return {
                ...props,
                type: 'network',
                title: 'Entschuldigung, es ist ein Netzwerk-Fehler aufgetreten!',
            };
        case Drm:
            return {
                ...props,
                title: 'Entschuldigung, der Inhalt kann leider nicht abgespielt werden.',
                content: isEdgeBrowser()
                    ? `Möglicherweise wird "${program.channelDisplay}" von Ihrem Browser nicht unterstützt. ${drmContent}`
                    : drmContent,
            };
        case Playback:
            return {
                ...props,
                title: 'Entschuldigung, es ist ein Fehler während der Wiedergabe aufgetreten!',
            };
        case POLICY: {
            const title =
                'Aus lizenzrechtlichen Gründen ist diese Sendung bei Ihnen nicht verfügbar.';
            const content = `Bitte stellen Sie sicher, dass Sie keinen VPN oder Proxy-Dienst verwenden. ${props.content}`;

            return {
                ...props,
                errorCode: 'POLICY',
                title,
                content,
            };
        }

        case MAINTENANCE:
            return {
                ...props,
                errorCode: 'MAINTENANCE',
                title: 'Aufgrund von Wartungsarbeiten ist diese Sendung derzeit leider nicht verfügbar.',
            };

        case DENIED_BY_LOCATION:
            return {
                ...props,
                errorCode: 'DENIED_BY_LOCATION',
                title: 'Aus lizenzrechtlichen Gründen ist diese Sendung für Ihren Standort nicht verfügbar.',
            };

        case General:
        case GENERAL_ERROR:
        case Setup:
        case Source:
        case Vr:
        case Module:
        case Other:
        default:
            return props;
    }
};

const MediaPlayerErrorScreen = ({ errorType, errorCode, action, program, hideButtons }) => {
    let notifyProps = getNotifyProps(errorType, action, program);

    const errCode = errorCode || notifyProps.errorCode;

    if (errCode && notifyProps.unkownError) {
        notifyProps.content = `${notifyProps.content} (${errCode})`;
    }

    notifyProps = {
        ...notifyProps,
        buttons: !hideButtons && notifyProps.buttons,
    };

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <NotificationScreen {...notifyProps} />;
};

export default MediaPlayerErrorScreen;
