/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { useEffect, useReducer } from 'preact/hooks';
import { logError } from '@exaring/utils';

// ToDo: by replacing this stateReducer we could make our hooks work with unistore or any other state management solution
// for now this should suffice
const stateReducer = (state, newState) => ({ ...state, ...newState });

/**
 * Custom hook for async functions
 * whatever the async action resolves to is handed back in state.result
 * @param {function} action -> the async method that you want to execute
 * @param {array} args -> any function parameters that you want to supply to the action
 * @returns {[{
 *      isLoading: Boolean,
 *      isError: Boolean,
 *      result: any,
 * }]}
 */
export default function useAsyncAction(action, args) {
    const [state, setState] = useReducer(stateReducer, {
        isLoading: false,
        isError: false,
        result: null,
    });

    useEffect(() => {
        let didCancel = false;

        const doAsyncAction = async () => {
            setState({ isLoading: true });
            try {
                const result = await action(...args);
                if (!didCancel) {
                    setState({ isLoading: false, result });
                }
            } catch (error) {
                if (!didCancel) {
                    setState({ isLoading: false, isError: true, result: error });
                    logError(error);
                }
            }
        };

        doAsyncAction();

        // set didCancel to true on unmount in order to waive the result in case the component gets unmounted in the meantime
        return () => {
            didCancel = true;
        };
        // -> can't hold ...args as dependencies as it creates an infinite loop because of spread operator not being statically analysable
    }, [action, ...args]);
    return [state];
}
