import { ComponentChildren, FunctionComponent, h } from 'preact';
import { useCallback, useRef } from 'preact/hooks';
import { useScrollLock } from '../../hooks/useScrollLock/useScrollLock';
import { Globals, styled } from '../theme';
import { Button } from '../Button';
import { useClickAway } from '../../hooks/useClickAway';
import { ModalIcon, BigToastIcon } from './BigToastIcon';

const StyledModal = styled('div', {
    position: 'fixed',
    top: Globals.headerHeight,
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
    zIndex: '$toast',
    backgroundImage:
        'linear-gradient(to bottom, rgba(9, 7, 20, 0), rgba(9, 7, 20, 0.9) calc(249px + 50%))',

    variants: {
        isHidden: {
            true: {
                display: 'none',
            },
        },
    },
});

const ToastBox = styled('div', {
    position: 'fixed',
    bottom: '74px',
    backgroundColor: '#0f2c4b',
    borderRadius: '$3',
    width: '646px',
    padding: '$3 $2',

    '@md': {
        width: '888px',
        padding: '$3 122px',
    },
});

const Title = styled('h2', {
    color: '$white',
    fontSize: '20px',
    fontWeight: '$3',
    textAlign: 'center',
});

const Content = styled('p', {
    color: '$white',
    fontSize: '$2.5',
    fontWeight: '$1',
    fontStyle: 'normal',
    textAlign: 'center',
    margin: '$7 0 $2',
});

const ButtonContainer = styled('div', {
    textAlign: 'center',
});

interface ToastProps {
    isVisible: boolean;
    icon?: ModalIcon;
    title: string;
    onHideToast: () => void;
    confirmLabel: string;
    cancelLabel: string;
    onConfirm: () => void;
    onCancel?: () => void;
    children: ComponentChildren;
}

/**
 * Shows a modal with a title, content and two buttons.
 * If no onCancel is provided, the modal will be closed on click on the cancel button.
 */
export const BigToast: FunctionComponent<ToastProps> = ({
    isVisible,
    icon,
    onHideToast,
    title,
    children,
    confirmLabel,
    cancelLabel,
    onCancel,
    onConfirm,
}) => {
    useScrollLock();

    const toastRef = useRef(null); // eslint-disable-line no-restricted-syntax

    const handleClickAway = useCallback(() => {
        if (isVisible) {
            onHideToast();
        }
    }, [isVisible, onHideToast]);

    useClickAway(toastRef, handleClickAway);

    return (
        <StyledModal isHidden={!isVisible}>
            <ToastBox ref={toastRef}>
                <Title>{title}</Title>
                <Content>{children}</Content>
                <ButtonContainer>
                    <Button css={{ margin: '0 20px 0 0' }} onClick={onConfirm}>
                        {confirmLabel}
                    </Button>
                    <Button css={{ margin: '0' }} onClick={onCancel || onHideToast} secondary dark>
                        {cancelLabel}
                    </Button>
                </ButtonContainer>
                <BigToastIcon icon={icon} />
            </ToastBox>
        </StyledModal>
    );
};
