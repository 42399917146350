/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, Component } from 'preact';

/**
 * Basic Select Input Field
 *
 * The component is not handling any visible / invisible state,
 * make sure within the parent to only render the popup-menu, the moment it is needed.
 *
    <SelectInput
        className="sample__my-popup-menu"                      // a specific className can be used to position it via css
        onChange={handleChange(value, selectedIndex, event)}   // is called when user select a different value
        selectedValue={-1}
        items={[                                               // array with the options
            {
                label: 'All',                                  // label of select option
                value: -1
            },
            {
                label: 'Season 5',
                value: 5
            }
        ]}
    />
 */
// @deprecated
class SelectInput extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        const { onChange } = this.props;
        onChange(e.target.value, e.target.selectedIndex, e);
    }

    render({ className, selectedValue, items }) {
        return (
            <div className={`form-item ${className || ''}`}>
                <select value={selectedValue} onChange={this.onChange}>
                    {items.map((option) => {
                        return (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        );
                    })}
                </select>
            </div>
        );
    }
}

export default SelectInput;
