import { h, FunctionComponent } from 'preact';
import Arrow from '@exaring/assets/icons/arrow-big.svg';
import { styled } from '@exaring/ui/components-styled/theme';
import { useContext } from 'preact/hooks';
import { date } from '@exaring/utils';
import { ScrollContext } from '../context/ScrollContext';
import { useEpgDateRange } from '../hooks/useEpgDateRange';

const ArrowRight = styled(Arrow, {
    $$size: '44px',
    width: '$$size',
    height: '$$size',
    background: '$nearBlack',
    borderRadius: '4px',
    variants: {
        show: {
            false: {
                visibility: 'hidden',
            },
        },
    },
    '&:hover': {
        cursor: 'pointer',
    },
});
const ArrowLeft = styled(ArrowRight, { transform: 'rotateY(180deg)', width: '64px' });

const Paging = styled('div', {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: '2',
    top: '50%',
    left: `0px`,
    right: `0px`,
    pointerEvents: 'none',
    '> *': {
        pointerEvents: 'auto',
    },
});

export const EpgPaging: FunctionComponent<{
    show?: boolean;
    indentLeft?: number;
    indentRight?: number;
    onClickArrowLeft?: () => void;
    onClickArrowRight?: () => void;
}> = ({ show = true, indentLeft, indentRight }) => {
    const scrollContext = useContext(ScrollContext);
    const [startDate, endDate] = useEpgDateRange();
    const { hour, dayUnix, scrollToTimeRef } = scrollContext;

    const getPreviousTimeslot = (): number => {
        return hour - (hour % 2) - 2;
    };

    const getNextTimeslot = (): number => {
        return hour - (hour % 2) + 2;
    };

    const showLeftPaging = () => {
        return !(startDate.toString() >= date(dayUnix).startOf('hour').toString());
    };

    const showRightPaging = () => {
        // NOTE useEpgTimeScroll is just scrolling to "last" timeslot so we have to add two hours
        // to compare if bounding timeslot is reached
        return !(endDate.toString() <= date(dayUnix).add(2, 'hours').startOf('hour').toString());
    };

    return (
        <Paging
            css={{
                ...(indentLeft ? { left: `${indentLeft}px` } : {}),
                ...(indentRight ? { right: `${indentRight}px` } : {}),
            }}
        >
            <ArrowLeft
                show={show && showLeftPaging()}
                onClick={() => {
                    scrollToTimeRef?.current?.(undefined, getPreviousTimeslot());
                }}
            />
            <ArrowRight
                show={show && showRightPaging()}
                onClick={() => {
                    scrollToTimeRef?.current?.(undefined, getNextTimeslot());
                }}
            />
        </Paging>
    );
};
