import { SentryEvent, SentryHint } from '@exaring/utils/error-logging/types';
import { NetworkingError } from '@exaring/networking/hooks/NetworkError/NetworkError';
import { setMediaPlayerErrorGroup } from './components/MediaPlayer/setMediaPlayerErrorGroup';
import MediaPlayerError from './components/MediaPlayer/MediaPlayerError';

export const errorHandler = (event: SentryEvent, hint: SentryHint): SentryEvent | void => {
    let composedEvent: SentryEvent | void = event;
    const exception = hint.originalException;

    // route exception types
    if (exception instanceof MediaPlayerError) {
        composedEvent = setMediaPlayerErrorGroup(event, hint);
    }

    if (exception instanceof NetworkingError) {
        if (exception.customCause === 'ClientError') {
            // all client errors get grouped (status code 400-499)
            composedEvent = { ...event, fingerprint: ['networking', exception.customCause] };
        } else {
            // explicitly mute all server errors in sentry (status code 500-599)
            composedEvent = undefined;
        }
    }

    return composedEvent;
};
