import { FunctionComponent, h } from 'preact';
import { CSS } from '@stitches/react';
import { styled, MediaQuery } from '@exaring/ui/components-styled/theme';
import { Dropdown } from '@exaring/ui/components-styled/DropDown';
import { Button } from '@exaring/ui/components-styled/Button';
import { useWindowSize } from '@exaring/ui/hooks/useWindowSize';
import { CTime, now, weekdayAndDateFormat } from '@exaring/utils';
import { useCallback, useContext, useMemo, useState } from 'preact/hooks';
import {
    EpgChannelSortButton,
    EpgFilterAllChannelsButton,
    EpgFilterFavoritesButton,
} from './Buttons';
import { WebClientGA, WebClientGAEvent } from '../../../web-client-ga';
import { Routes, routeTo } from '../../../routes';
import { useEpgStore } from '../../../state/Store'; // eslint-disable-line import/no-named-as-default
import { ScrollContext } from '../context/ScrollContext';

// used for better relative time dropdown timeslot handling with minute precision
type FractionalHour = number;
type UnixTimestamp = number;

interface SelectHourOption {
    label: string;
    value: FractionalHour;
    addSeparatorAfter?: boolean;
}

interface SelectDateOption {
    label: string;
    value: UnixTimestamp;
    hours: readonly SelectHourOption[];
}

const RESET_TARGET_TIMEOUT_MS = 1000;

const StyledSettingsBar = styled('div', {
    display: 'flex',
    width: '100%',
    columnGap: '2px',
    height: '$$settingsBarHeight',
});

const routeToChannelConfigPage = () => {
    WebClientGA().trackEvent({
        eventName: WebClientGAEvent.ChannelSort,
        eventDescription: WebClientGAEvent.OpenChannelSort,
        screenName: 'epg',
    });
    routeTo(Routes.CHANNELCONFIG_PATH);
};

/* returns 20.25 for 20:15 and so on */
const getCurrentHour = () => {
    const hour = now().get('h');
    const minutes = now().get('m');
    const decimalMinutes = minutes !== 0 ? minutes / 60 : 0;
    return hour + decimalMinutes;
};
const getStartHour = () => now().subtract(4, 'h').get('hour');
const getStartOfToday = () => now().startOf('day');

const hourSelectOptions = [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22].map((hour) => ({
    label: `${hour < 10 ? '0' : ''}${hour}:00`,
    value: hour,
}));

const createTodayHourSelectOptions = (
    startHour: number,
    currentHour: number,
): readonly SelectHourOption[] =>
    [
        { label: 'Jetzt', value: currentHour },
        {
            label: '20:15',
            value: 20.25,
            addSeparatorAfter: true,
        },
        ...hourSelectOptions.filter((option) => option.value >= startHour),
    ] as const;

const createDateSelectOptions = (startOfToday: CTime): readonly SelectDateOption[] =>
    Array.from({ length: 12 }, (_, i) => {
        const offsetDay = startOfToday.add(i + 2, 'day');
        return {
            label: offsetDay.format((t) => weekdayAndDateFormat(t, true, true)),
            value: offsetDay.unix(),
            hours: hourSelectOptions,
        };
    });

const StyledNowButton = styled(Button, {
    marginLeft: '20px',
    fontSize: '14px',
    '&:hover': {
        color: '$white',
    },
});

const StyledEpgChannelSortButton = styled(EpgChannelSortButton, {
    marginLeft: 'auto',
    '&:hover > svg': {
        fill: '$white',
    },
});

const StyledEpgFilterFavoritesButton = styled(EpgFilterFavoritesButton, {
    '&:hover > svg': {
        fill: '$white',
    },
});

const createDropdownData = (
    startOfToday: CTime,
    startHour: number,
    currentHour: number,
): readonly SelectDateOption[] =>
    [
        {
            label: 'Heute',
            value: startOfToday.unix(),
            hours: createTodayHourSelectOptions(startHour, currentHour),
        },
        { label: 'Morgen', value: startOfToday.add(1, 'day').unix(), hours: hourSelectOptions },
        ...createDateSelectOptions(startOfToday),
    ] as const;

const snapDateOption = (dayUnix: UnixTimestamp, dateSelectOptions: readonly SelectDateOption[]) =>
    [...dateSelectOptions].reverse().find((option) => option.value <= dayUnix) ??
    dateSelectOptions[0];

const snapHour = (hour: FractionalHour, options: readonly SelectHourOption[] | undefined) =>
    (options &&
        [...options].sort((a, b) => b.value - a.value).find((option) => option.value <= hour)
            ?.value) ??
    0;

export const SettingsBar: FunctionComponent<{ css?: CSS }> = ({ css }) => {
    const { showOnlyFavorites, toggleFavorites } = useEpgStore();
    const windowSize = useWindowSize();

    const startOfToday = useMemo(() => getStartOfToday(), []);
    const startHour = useMemo(() => getStartHour(), []);
    const currentHour = useMemo(() => getCurrentHour(), []);

    const dateSelectOptions = useMemo(
        () => createDropdownData(startOfToday, startHour, currentHour),
        [startHour, startOfToday, currentHour],
    );

    const [targetTime, setTargetTime] = useState<{
        date: UnixTimestamp | undefined;
        hour: FractionalHour | undefined;
    }>({ date: undefined, hour: undefined });

    const scrollContext = useContext(ScrollContext);
    const { hour, dayUnix, scrollToTimeRef } = scrollContext;

    const selectedDateOption = useMemo(
        () => snapDateOption(dayUnix, dateSelectOptions),
        [dateSelectOptions, dayUnix],
    );
    const selectedHour = useMemo(
        () => snapHour(hour, selectedDateOption?.hours),
        [hour, selectedDateOption?.hours],
    );

    const scrollTo = useCallback(
        (targetDayUnix: UnixTimestamp | undefined, targetHour: FractionalHour | undefined) => {
            // avoid switching of selected date/hour in dropdowns while automatic scrolling
            setTargetTime({ date: targetDayUnix, hour: targetHour ?? selectedHour });
            setTimeout(() => {
                setTargetTime({ date: undefined, hour: undefined });
            }, RESET_TARGET_TIMEOUT_MS);

            // scroll automatically to position
            scrollToTimeRef?.current?.(targetDayUnix, targetHour);
        },
        [scrollToTimeRef, selectedHour],
    );

    const handleTimeSelectChange = useCallback(
        (value: string, label?: string) => {
            const getDetails = () => {
                switch (label) {
                    case 'Jetzt':
                        return 'now';
                    case '20:15':
                        return 'prime_time';
                    default:
                        return value;
                }
            };
            if (label && (label === 'Jetzt' || label === '20:15')) {
                WebClientGA().trackEvent(
                    {
                        eventName: WebClientGAEvent.EPG,
                        eventDescription: WebClientGAEvent.SelectTime,
                        screenName: 'epg',
                    },
                    {
                        event_details: getDetails(),
                    },
                );
            }

            scrollTo(undefined, Number.parseFloat(value));
        },
        [scrollTo],
    );
    const handleDateSelectChange = useCallback(
        (value: string) => {
            const selected = dateSelectOptions.find((val) => val.value === Number(value));
            WebClientGA().trackEvent(
                {
                    eventName: WebClientGAEvent.EPG,
                    eventDescription: WebClientGAEvent.SelectDate,
                    screenName: 'epg',
                },
                {
                    event_details: selected
                        ? dateSelectOptions.indexOf(selected)?.toString()
                        : undefined,
                },
            );
            scrollTo(Number.parseInt(value, 10), undefined);
        },
        [scrollTo],
    );
    const handleNowButtonClick = useCallback(() => {
        WebClientGA().trackEvent(
            {
                eventName: WebClientGAEvent.EPG,
                eventDescription: WebClientGAEvent.SelectTime,
                screenName: 'epg',
            },
            {
                event_details: 'now',
            },
        );
        scrollTo(dateSelectOptions[0]?.value, dateSelectOptions[0]?.hours[0]?.value);
    }, [dateSelectOptions, scrollTo]);

    const handleClickAllChannels = () => {
        WebClientGA().trackEvent({
            eventName: WebClientGAEvent.EPG,
            eventDescription: WebClientGAEvent.DisplayAllChannels,
            screenName: 'epg',
        });
        toggleFavorites();
    };

    const handleClickFavouriteChannels = () => {
        WebClientGA().trackEvent({
            eventName: WebClientGAEvent.EPG,
            eventDescription: WebClientGAEvent.DisplayFavourites,
            screenName: 'epg',
        });
        toggleFavorites();
    };

    return (
        <StyledSettingsBar css={css}>
            <EpgFilterAllChannelsButton
                isActive={!showOnlyFavorites}
                onClick={handleClickAllChannels}
            />
            <StyledEpgFilterFavoritesButton
                isActive={showOnlyFavorites}
                onClick={handleClickFavouriteChannels}
            />
            <StyledNowButton epg onClick={handleNowButtonClick}>
                Jetzt
            </StyledNowButton>
            <Dropdown<number>
                css={{ marginLeft: '20px' }}
                onChange={(val) =>
                    handleTimeSelectChange(
                        val,
                        selectedDateOption?.hours.find((option) => option.value === Number(option))
                            ?.label,
                    )
                }
                options={selectedDateOption?.hours ?? []}
                selected={targetTime.hour ?? selectedHour}
            />
            <Dropdown<number>
                css={{ marginLeft: '20px' }}
                onChange={handleDateSelectChange}
                options={dateSelectOptions}
                selected={targetTime.date ?? selectedDateOption?.value}
            />
            <StyledEpgChannelSortButton
                showTitle={windowSize.width > MediaQuery.md}
                onClick={routeToChannelConfigPage}
            />
        </StyledSettingsBar>
    );
};
