import { h, FunctionComponent } from 'preact';
import { memo } from 'preact/compat';
import { connect } from 'unistore/preact';
import SubtitleIcon from '@exaring/assets/icons/subtitle.svg';
import { styled } from '@exaring/ui/components-styled/theme';
import type { Program } from '@exaring/ui/components-styled/types/Epg';
import { MultiLayerMenu } from '@exaring/ui/components-styled/MultiLayerMenu';
import {
    InfoItem,
    Menu,
    MenuItem,
} from '@exaring/ui/components-styled/MultiLayerMenu/MultiLayerMenu';
import store from '../../../store';
import playerActions from '../../../actions/player';
import { LangKeyType, LanguageSettings, translateLang } from '../../../player/language';
import { WebClientGAEvent, WebClientGA } from '../../../web-client-ga';
import { PLAYOUT_LIVE } from '../../../actions/constants';
import { PlayerType } from '../../../types/tracking';
import { getCurrentScreen } from '../../../helper';

const infoItem: InfoItem = {
    label: 'SPRACHVERSIONEN',
    infoText:
        'Sprachversionen werden vom Sender zur Verfügung gestellt, sie können je nach Sendung Abweichungen enthalten.',
};

const StyledButton = styled('div', {
    marginRight: '$7',
    cursor: 'pointer',

    variants: {
        disabled: {
            true: {
                opacity: 0.3,
                pointerEvents: 'none',
            },
        },
    },
});

const SubtitleButton: FunctionComponent<{
    isPlaying: boolean;
    program: Program;
    setAudioLanguage: (lang: LangKeyType) => void;
    setTextLanguage: (lang: LangKeyType) => void;
    subtitleTracks: LanguageSettings;
    audioTracks: LanguageSettings;
    playout: any;
}> = ({
    isPlaying = false,
    setAudioLanguage,
    setTextLanguage,
    subtitleTracks,
    audioTracks,
    program,
    playout,
}) => {
    let audioItems: MenuItem[] = [];
    let textItems: MenuItem[] = [];
    let selectionAvailable = false;
    let playerType: PlayerType;
    if (playout?.isNewTV) {
        playerType = 'vod';
    } else if (playout?.playoutType === PLAYOUT_LIVE) {
        playerType = playout?.isTimeShifted ? 'time_shift' : 'livetv';
    } else {
        playerType = 'recording';
    }

    if (isPlaying) {
        const textInfo = subtitleTracks;
        const audioInfo = audioTracks;

        audioItems = (audioInfo?.languages || []).map((lang: LangKeyType) => ({
            label: translateLang(lang),
            onClick: () => {
                WebClientGA().trackEvent(
                    {
                        eventName: WebClientGAEvent.PlayerControls,
                        eventDescription: WebClientGAEvent.SelectAudioTrack,
                        channelName: program.channel,
                        screenName: getCurrentScreen(),
                        programId: program.id as string,
                        programTitle: program.title,
                        playerType,
                    },
                    {
                        event_details: audioInfo?.selectedLanguage,
                    },
                );
                setAudioLanguage(lang);
            },
            selected: lang === audioInfo?.selectedLanguage,
        }));

        textItems = (textInfo?.languages || []).map((lang: LangKeyType) => ({
            label: translateLang(lang),
            onClick: () => {
                WebClientGA().trackEvent(
                    {
                        eventName: WebClientGAEvent.PlayerControls,
                        eventDescription: WebClientGAEvent.SelectSubtitles,
                        channelName: program.channel,
                        programId: program.id as string,
                        screenName: getCurrentScreen(),
                        programTitle: program.title,
                        playerType,
                    },
                    {
                        event_details: textInfo?.selectedLanguage,
                    },
                );
                setTextLanguage(lang);
            },
            selected: lang === textInfo?.selectedLanguage,
        }));

        selectionAvailable = audioInfo?.languages?.length > 1 || textInfo?.languages?.length > 1;
    }

    const audioMenu: Menu[] = [
        {
            title: 'Audiosprache',
            items: audioItems,
        },
    ];

    const subtitleMenu: Menu[] = [
        {
            title: 'Untertitel-Sprache',
            items: textItems,
        },
    ];

    const mainMenu: Menu[] = [
        {
            title: 'Spracheinstellungen',
            items: [
                { label: 'Audiosprache', subMenu: audioMenu },
                { label: 'Untertitel-Sprache', subMenu: subtitleMenu },
            ],
        },
    ];

    return (
        <MultiLayerMenu
            menu={mainMenu}
            info={infoItem}
            button={
                <StyledButton disabled={!selectionAvailable}>
                    <SubtitleIcon />
                </StyledButton>
            }
        />
    );
};

const combinedActions = () => {
    return {
        ...playerActions(store),
    };
};

const mapStateToProps = ({ player }: any) => {
    return {
        audioTracks: player.audioTracks,
        subtitleTracks: player.subtitleTracks,
    };
};

export default memo(connect(mapStateToProps, combinedActions)(SubtitleButton as any) as any); // remove memo after rewrite of OSD
