import { useContext, useEffect } from 'preact/hooks';
import { Channel } from '../../../types/api/epg/StationConfig';
import { ScrollContext } from '../context/ScrollContext';

export const useEpgChannelScroll = (
    channels: Channel[],
    channelHeightPx: number,
    scrollY: number,
    scrollToTop: (px?: number) => void,
) => {
    const scrollContext = useContext(ScrollContext);
    const { setScrollToChannelHandler } = scrollContext;

    useEffect(() => {
        setScrollToChannelHandler((uuid?) => {
            if (!uuid) {
                return;
            }

            const index = channels.findIndex((channel) => channel.uuid === uuid);
            if (index < 0) {
                return;
            }

            const positionPx = index * channelHeightPx;
            scrollToTop(positionPx - scrollY);
        });
    }, [setScrollToChannelHandler, scrollToTop, channels, channelHeightPx, scrollY]);
};
