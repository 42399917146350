import { h, FunctionComponent } from 'preact';

import { PopupMenu } from '@exaring/ui';
import { isRecording, isScheduled } from '@exaring/utils/data/recording';
import { css } from '@exaring/ui/components-styled/theme';
import { classnames } from '@exaring/utils';

const openUp = css({
    bottom: '20px',
    left: '14px',
});

const RecordingButtonPopup: FunctionComponent<{
    recordingState: 'RECORDABLE' | 'SCHEDULED' | 'RECORDING' | 'FINISHED' | 'UNKNOWN';
    showLabel: boolean;
    closePopupMenu: () => void;
    handleSerials: () => void;
    handleSingle: () => void;
    shouldOpenUp?: boolean;
}> = ({
    recordingState,
    showLabel,
    closePopupMenu,
    handleSerials,
    handleSingle,
    shouldOpenUp = false,
}) => {
    let singleActionLabel;
    let serialActionLabel;

    if (isScheduled(recordingState) || isRecording(recordingState)) {
        singleActionLabel = 'Folge verwerfen';
        serialActionLabel = 'Serie verwerfen';
    } else {
        singleActionLabel = 'Folge aufnehmen';
        serialActionLabel = 'Serie aufnehmen';
    }

    return (
        <PopupMenu
            className={classnames(
                'recording-button__popup-menu',
                // When a label is shown the text should be aligned to the left,
                // all the other variants should have centered text
                showLabel && 'recording-button__popup-menu--left-aligned',
                shouldOpenUp && openUp(),
            )}
            doClose={closePopupMenu}
            items={[
                {
                    label: serialActionLabel,
                    action: handleSerials,
                },
                {
                    label: singleActionLabel,
                    action: handleSingle,
                },
            ]}
        />
    );
};

export default RecordingButtonPopup;
