/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';
import { conditionalCb } from '@exaring/utils';
import { useThrottle } from '@exaring/ui/hooks/throttle';
import { WebClientGAEvent, WebClientGA } from '../../../web-client-ga';
import { notificationsStore } from '../../../state/Store';

import OSDButton from './OSDButton';
import OSDButtonGroup from './OSDButtonGroup';

import FavoriteButton from '../../FavoriteButton';
import { getCurrentScreen } from '../../../helper';

// {<} {channel-icon} {>}
const ChannelControls = ({
    channelTitle,
    channelImageSrc,
    isDisabled,
    onChannelLogoClick,
    onNavigatePrevious,
    onNavigateNext,
    toggleChannel,
    isFavourite,
    children,
}) => {
    const throttled = useThrottle(toggleChannel, 250);
    const notifications = notificationsStore();

    const toggleFavorite = () => {
        throttled();
        notifications.toggleFavorite(isFavourite);
    };

    const handleToggleFavourite = () => {
        const evtDescription = isFavourite
            ? WebClientGAEvent.UnmarkFavourite
            : WebClientGAEvent.MarkFavourite;
        WebClientGA().trackEvent({
            eventName: WebClientGAEvent.ChannelSort,
            eventDescription: evtDescription,
            channelName: channelTitle,
            screenName: getCurrentScreen(),
        });
    };

    return (
        <OSDButtonGroup
            className="osd__controls__icon-group--left osd__controls__icon-group--live"
            isDisabled={isDisabled}
        >
            <div
                onClick={toggleFavorite}
                className="osd__icon"
                title={
                    isFavourite
                        ? 'Sender von Favoriten entfernen'
                        : 'Sender zu Favoriten hinzufügen'
                }
            >
                <FavoriteButton isActive={isFavourite} onClick={handleToggleFavourite} />
            </div>
            <OSDButton
                isDisabled={isDisabled}
                onAction={conditionalCb(!isDisabled, onNavigatePrevious)}
                className="osd__icon--navigation-left icon--arrow"
            />

            <div
                className="osd__icon osd__icon--channel-logo"
                onClick={conditionalCb(!isDisabled, onChannelLogoClick)}
            >
                {channelImageSrc && <img src={channelImageSrc} title={channelTitle} />}
            </div>

            {children}

            <OSDButton
                isDisabled={isDisabled}
                onAction={conditionalCb(!isDisabled, onNavigateNext)}
                className="osd__icon--navigation-right icon--arrow"
            />
        </OSDButtonGroup>
    );
};

export default ChannelControls;
