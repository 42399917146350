export const schemaBase = 'application/vnd.waipu';

export const constants = {
    imageLargeWidth: 640,
    imageLargeHeight: 360,
    imageWidth: 288 * 1.5,
    imageHeight: 162 * 1.5,
    RECORDING_READY_TIME: 60,
};

export const RecordingState = {
    RECORDABLE: 'RECORDABLE',
    SCHEDULED: 'SCHEDULED',
    RECORDING: 'RECORDING',
    FINISHED: 'FINISHED',
    UNKNOWN: 'UNKNOWN',
};
