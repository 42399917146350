import { FunctionComponent, h, Fragment } from 'preact';
import { useEpgStore } from '../../../state/Store';
import { ProgramPreview } from './ProgramPreview';
import { ChannelPreview } from './ChannelPreview';

export const Preview: FunctionComponent = () => {
    const { preview } = useEpgStore();

    if (preview === undefined) {
        return <ProgramPreview />;
    }

    const { type, id } = preview;

    if (type === 'Program') {
        return <ProgramPreview programId={id} />;
    }

    if (type === 'Channel') {
        return <ChannelPreview channelId={id} />;
    }

    return <></>;
};
