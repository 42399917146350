/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';
import { keyboardCodes } from '@exaring/ui';
import { useNativeEvents } from '@exaring/ui/hooks';

import {
    Header as WebUiHeader,
    MainNavigation,
    HorizontalSeparator,
} from '@exaring/ui/components/header--logged-in';
import { Logout } from '@exaring/ui/components/header--logged-in/navigation-items';
import { events } from '@exaring/utils';
import SearchBar from './SearchBar';
import SearchResults from './SearchResults';
import { UserLinks, InternalLinks, InternalLinksEmpty } from './navigationItems';

import constants from '../../constants';
import { WebClientGA, WebClientGAEvent } from '../../web-client-ga';
import { routeTo, routeToEpg, Routes, isLiveTVPathActive } from '../../routes';
import { useSearchStore, useUiStore, userStore } from '../../state/Store';
import { getCurrentScreen } from '../../helper';

const track = (eventName, eventDescription, additionals = {}) => {
    const { userHandle } = userStore();
    WebClientGA().trackEvent({
        eventName,
        eventDescription,
        userHandle,
        ...additionals,
    });
};

const Header = ({ id = '', hideInternalLinks, showLogout, children }) => {
    const { resetState: hardResetSearch } = useSearchStore();
    const { isSearchActive: searchActive, toggleSearchActive: toggleActive } = useUiStore();

    const eventMap = {
        keydown: (event) => {
            const { code, target } = event;
            const disallowedTargets = ['INPUT', 'TEXTAREA'];

            if (
                code === keyboardCodes.s &&
                !searchActive &&
                !disallowedTargets.includes(target?.tagName?.toUpperCase())
            ) {
                toggleActive(true);
            }
        },
    };
    useNativeEvents(eventMap);

    const showInternalLinks = !hideInternalLinks && !searchActive;
    const showEmptyInternalLinks = hideInternalLinks && !searchActive;
    const _showLogout = showLogout && !searchActive;
    const showUserLinks = !showLogout && !searchActive;

    return (
        <WebUiHeader>
            <MainNavigation
                id={`${id}-main-nav`}
                onBrandClick={(e) => {
                    events.pauseEvent(e);

                    if (searchActive) {
                        hardResetSearch();
                        toggleActive(false);

                        track(WebClientGAEvent.Search, WebClientGAEvent.SearchClosed, {
                            screenName: getCurrentScreen(),
                        });
                        return;
                    }

                    track(WebClientGAEvent.Search, WebClientGAEvent.BrandClick);

                    isLiveTVPathActive() ? routeToEpg() : routeTo(Routes.LIVE_TV_PAGE);
                }}
            >
                {showInternalLinks && <InternalLinks toggleActive={toggleActive} track={track} />}
                {showEmptyInternalLinks && <InternalLinksEmpty />}

                {_showLogout && (
                    <Logout
                        action={(e) => {
                            events.pauseEvent(e);

                            track(WebClientGAEvent.Logout);

                            window.location.href = constants.LOGOUT_URL;
                        }}
                    />
                )}

                {showUserLinks && <UserLinks />}

                {searchActive && <SearchBar track={track} />}
            </MainNavigation>

            <HorizontalSeparator />

            {searchActive && <SearchResults track={track} />}

            {children}
        </WebUiHeader>
    );
};

export default Header;
