import '@exaring/assets/styles/VisbyRoundCF.css';
import '@exaring/assets/styles/PoppinsSemiBold.css';

import { styled } from '../../theme';
import { Typography } from '../../Typography/Typography';

export const RecordingDiscoveryNumber = styled(Typography, {
    width: '45px',
    fontWeight: '$5',
    lineHeight: '62px',
    fontSize: '62px',
    textAlign: 'center',
    paddingRight: '5px',
    letterSpacing: '-4px',
    fontFamily: 'VisbyRoundCF, PoppinsSemiBold, SansSerif',
    background: `
            linear-gradient(90deg, rgba(0,0,0,0) 38%, rgba(0,0,0,1) 93%), /* shadow gradient form right to left overlapping the color gradient */
        linear-gradient($purple 2%, $bluish 15%) /* purple to blue gradient from top to bottom */`,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    // fix background being visible on hover in Safari
    border: '1px solid $nearBlack',
});
