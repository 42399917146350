import { unix, strj } from '@exaring/utils';
import {
    RecordingSummaryV2Schema,
    RecordingsV2Schema,
    RecordingV2Schema,
    RecordingStartV2Schema,
} from '@exaring/utils/data/schema/recording';
import constants from '../constants';
import { get, post, remove } from '../request';

/**
 * @return {Object}
 */
export const summary = () =>
    get(`${constants.RECORDINGS_BASE_URL}/summary`, {
        Accept: RecordingSummaryV2Schema,
    });

/**
 * Complete list of all recordings as recording.v2
 * @return {Object}
 */
export const recordings = () =>
    get(constants.RECORDINGS_BASE_URL, {
        Accept: RecordingsV2Schema,
    });

/**
 * @param {String} recordingId
 * @return {Object}
 */
export const details = (recordingId) =>
    get(`${constants.RECORDINGS_BASE_URL}/${recordingId}`, {
        Accept: RecordingV2Schema,
    });

/**
 * @param {String} programId
 * @param {String} channelId
 * @param {Any} startTime
 * @param {Any} stopTime
 * @param {String} recordingGroup
 * @return {Object}
 */
export const schedule = (programId, channelId, startTime, stopTime, recordingGroup = undefined) =>
    post(
        constants.RECORDINGS_BASE_URL,
        {
            Accept: RecordingV2Schema,
            'Content-Type': RecordingStartV2Schema,
        },
        {
            programId,
            channelId,
            startTime: unix(startTime),
            stopTime: unix(stopTime),
            ...(recordingGroup ? { recordingGroup } : {}),
        },
    );

/**
 * Unschedule a list of recordings
 * @param {Array<String|Number>} recordingIdList
 * @return {Object}
 */
export const unschedule = (recordingIdList) =>
    remove(
        constants.RECORDINGS_BASE_URL,
        {
            Accept: RecordingsV2Schema,
            'Content-Type': 'application/vnd.waipu.pvr-recording-ids-v2+json',
        },
        { ids: recordingIdList },
    );

/**
 * Stop a single recording by id
 * @param {String} recordingId
 * @return {Object}
 */
export const stop = (recordingId) =>
    post(`${constants.RECORDINGS_BASE_URL}/${recordingId}/stop`, {
        Accept: RecordingV2Schema,
    });

/**
 * @return {Object}
 */
export const deleteFailed = () =>
    remove(`${constants.RECORDINGS_BASE_URL}?status=PVR_LIMIT_EXCEEDED`, {
        Accept: 'application/vnd.waipu.recordings-delete+json',
    });

/**
 * recording-scheduler
 */

/**
 * Trigger a serial recording. If recordingGroup
 * is set, the given serial recording will be restarted.
 * @param {String} title program title
 * @param {String} channel channel id
 * @param {String} recordingGroup group id
 * @return {Object}
 */
export const scheduleSerial = (title, channel, recordingGroup) =>
    post(
        strj(`${constants.SERIALS_RECORDING_BASE_URL}/serials`, recordingGroup, '/'),
        {
            Accept: 'application/vnd.waipu.recording-scheduler-serial-recording-v1+json',
            'Content-Type': 'application/vnd.waipu.recording-scheduler-serials-v1+json',
        },
        { title, channel, ...(recordingGroup ? { recordingJobId: recordingGroup } : {}) },
    );

/**
 * @return {Object}
 */
export const scheduledSerials = () =>
    get(`${constants.SERIALS_RECORDING_BASE_URL}/serials`, {
        Accept: 'application/vnd.waipu.recording-scheduler-serial-recordings-v1+json',
    });

/**
 * @param {Array<String|Number>} recordingIdList
 * @param {Array<Object>} deleteOptions delete flags
 * @return {Object}
 */
export const unscheduleSerials = (
    recordingIdList,
    deleteOptions = {
        deleteFutureRecordings: true,
        deleteFinishedRecordings: false,
        deleteRunningRecordings: true,
    },
) =>
    post(
        `${constants.SERIALS_RECORDING_BASE_URL}/delete-requests`,
        {
            Accept: 'application/vnd.waipu.recording-scheduler-serial-recordings-v1+json',
            'Content-Type':
                'application/vnd.waipu.recording-scheduler-delete-serial-recordings-v1+json',
        },
        {
            serialRecordings: recordingIdList.map((id) => ({
                id,
                ...deleteOptions,
            })),
        },
    );
