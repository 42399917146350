/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';
import { classnames } from '@exaring/utils';

const renderMainNavigation = (navigationMenuItems) => {
    return (
        <nav className="header__main-links">
            <ul className="header__main-navbar">
                {(navigationMenuItems || []).map((item) => {
                    const itemClasses = classnames('header__main-link', item.className);

                    const linkClasses = classnames(
                        'header__main-item',
                        item.active && 'header__main-item--active',
                    );

                    return (
                        <li className={linkClasses} key={item.label}>
                            <a onClick={item.action} className={itemClasses}>
                                {item.label}
                            </a>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

const Header = ({
    navigationMenuItems,
    headerText,
    onBrandClickAction,
    hideInternalLinks,
    isMMS,
}) => {
    const showMainNav = navigationMenuItems && !hideInternalLinks;

    return (
        <header className="header">
            <div className="header__fixed">
                <a
                    onClick={onBrandClickAction}
                    className={`header__logo ${isMMS && 'header__logo--mms'}`}
                >
                    Waipu
                </a>

                {headerText && !isMMS && <span className="header__service-text">{headerText}</span>}
                {isMMS && <span className="header__logo-seperator" />}

                {isMMS && <a className="header__mms-logo">MMS</a>}

                <div className="header__menu">
                    {showMainNav && renderMainNavigation(navigationMenuItems)}
                </div>
            </div>
        </header>
    );
};
// Header.propTypes = {
//     headerText: React.PropTypes.string,
//     navigationMenuItems: React.PropTypes.arrayOf(
//                 React.PropTypes.shape({
//                     label: React.PropTypes.string.isRequired,
//                     active: React.PropTypes.string,
//                     className: React.PropTypes.string,
//                     action: React.PropTypes.func.isRequired
//                 })
//             ),
//     onBrandClickAction: React.PropTypes.func
// };

export default Header;
