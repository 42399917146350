import { constants } from '../constants';
import {
    dateDuration,
    progress as dateProgress,
    isOnAir as dateOnAir,
    now,
    dateWithOffset,
} from '../../date';
import { toUrlString } from '../../url';
import { strj } from '../../string';

/**
 * returns the duration in given timescale
 * @param {object} program programData for program
 * @param {boolean} min should difference be converted to minutes (default false)
 * @returns {number} {number} difference in minutes or seconds (default seconds)
 */
export const duration = (program = {}, min = false) =>
    dateDuration(program.startTime, program.stopTime, min);

/**
 * returns the progress the program has played
 * @param {object} program programData for program
 * @param {number} position (optional) if current position is different to now()
 * @returns {number} percentage of played position
 */
export const progress = (program = {}, position = now()) =>
    dateProgress(program.startTime, program.stopTime, position);

/**
 * returns if the given program is currently on air
 * @param {object} program programData for program
 * @param {number} startOffset start offset in seconds
 * @param {number} endOffset end offset in seconds
 * @returns {boolean} true or false
 */
export const isOnAir = (program = {}, startOffset = 0, endOffset = 0) => {
    const { startTime, stopTime } = program;
    const _startTime = dateWithOffset(startTime, startOffset);
    const _endTime = dateWithOffset(stopTime, endOffset);

    return dateOnAir(_startTime, _endTime);
};
/**
 * returns if program is a newTV or TVFuse Content
 * @param {object} program programData for program
 * @returns {boolean} true or false
 */
export const isTVFuse = (program = {}) =>
    !!program.streamUrlProvider || !!program.newTvMeta?.contentId;

/**
 * returns a short-information for programData
 * @param {object} program programData for program
 * @returns {string} "SitCom ⋅ 50 min"
 */
export const shortInfo = (program = {}) => {
    if (program.genreDisplayName && program.duration) {
        return `${program.genreDisplayName} ⋅ ${program.duration} Min.`;
    }

    return '';
};

/**
 * takes the previewImages and returns the first preview-image
 * with dimensions
 * @param {object} program
 * @param {object} dimensions
 * @returns {string} Url to the first of the preview-images
 */

export const previewImage = (program = {}, dimensions = {}) => {
    if (program?.previewImages?.[0]) {
        const dim = {
            width: constants.imageWidth,
            height: constants.imageHeight,
            ...dimensions,
        };

        return strj(program.previewImages[0], toUrlString(dim), '?');
    }

    return '';
};

/**
 * Greps all Links to the previewImages a
 * and appends the image-dimensions to the url
 * @param {object} program
 * @returns {array} Array of urls to the preview Images
 */

export const previewImages = (program = {}) => {
    const dimensions = {
        width: constants.imageLargeWidth,
        height: constants.imageLargeHeight,
    };

    if (program?.previewImages?.length) {
        return program.previewImages.map((url) => {
            return strj(url, toUrlString(dimensions), '?');
        });
    }

    return [];
};

/**
 * Greps all Links to the previewImages a
 * and sets the resolution
 * @param {array} imageUrls
 * @param {string} resolution
 * @returns {array} Array of urls to the preview Images
 */

export const previewImagesEpg2 = (
    imageUrls = [],
    resolution = 'm', // eslint-disable-next-line no-template-curly-in-string
) => imageUrls.map((url) => url.replace('${resolution}', resolution));
/**
 * returns if a serial-recording can be started for this program
 * @param {object} program
 * @returns {boolean} true or false
 */
export const serialRecordingAvailable = (program = {}) => {
    return !!program.series;
};

export const adjustImageRes = (url, width, height, shape) => {
    const resRegex = /\/(standard|small|large)\?res=(\d+)x(\d+)/;
    const match = url?.match(resRegex);

    if (match) {
        const oldShape = match[1];
        const newRes = `${width}x${height}`;
        const newShape = shape ?? oldShape;
        return url.replace(resRegex, `/${newShape}/?res=${newRes}`);
    }

    return url;
};
