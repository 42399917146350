/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */
import { Map, List } from 'immutable';

import Channel from '@exaring/networking/models/channel';
import station from '@exaring/networking/services/station';
import { GA } from '@exaring/utils';
import { WebClientGAEvent } from '../web-client-ga';

const track = (event, eventDescription, channelName, custom) => {
    GA.track({ event, event_description: eventDescription, channel_name: channelName, ...custom });
};

/* eslint-disable no-bitwise */
export const ERROR_NONE = 0;
export const ERROR_INIT = 1 << 0;
export const ERROR_SAVE = 1 << 1;
export const ERROR_RESET = 1 << 2;
/* eslint-enable no-bitwise */

export const initialState = Map({
    channelsAll: List(),
    channelsVisible: List(),
    channelsHidden: List(),
    editMode: false,
    showResetDialog: false,
    error: ERROR_NONE,
    resetState: undefined,
    showAllChannelsState: undefined,
});

const sortByNameComparator = (a, b) => {
    const nameA = a.displayName.toLowerCase();
    const nameB = b.displayName.toLowerCase();

    if (nameA < nameB) {
        return -1;
    }

    if (nameA > nameB) {
        return 1;
    }

    return false;
};

const createLinks = (id) => [
    {
        href: `https://snapshot.exaring.tv?channel=${id}`,
        rel: 'liveImage',
        type: 'image/jpeg',
    },
    {
        href: `https://images.waipu-dev.net/api/channels/${id}/logosd.png`,
        rel: 'iconsd',
        type: 'image/png',
    },
    {
        href: `https://images.waipu-dev.net/api/channels/${id}/logohd.png`,
        rel: 'iconhd',
        type: 'image/png',
    },
    {
        href: `https://images.waipu-dev.net/api/channels/${id}/logolargesd.png`,
        rel: 'iconlargesd',
        type: 'image/png',
    },
    {
        href: `https://images.waipu-dev.net/api/channels/${id}/logolargehd.png`,
        rel: 'iconlargehd',
        type: 'image/png',
    },
    {
        href: `https://images.waipu-dev.net/api/channels/${id}/logosamsungsd.png`,
        rel: 'iconsamsungsd',
        type: 'image/png',
    },
    {
        href: `https://images.waipu-dev.net/api/channels/${id}/logosamsunghd.png`,
        rel: 'iconsamsunghd',
        type: 'image/png',
    },
    {
        href: `https://playout-url-provider.waipu-dev.net/api/channels/${id}`,
        rel: 'livePlayout',
        type: 'application/vnd.waipu.playout-urls-live-v1+json',
    },
    {
        href: `https://epg.waipu-dev.net/api/channels/${id}/programs`,
        rel: 'programs',
        type: 'application/vnd.waipu.epg-programs-v1+json',
    },
];

const mapChannelData = (val) => {
    const id = val.stationId.toUpperCase();

    return {
        id,
        stationId: val.stationId,
        displayName: val.displayName || 'Unbekannt',
        faved: val.userSettings.favorite,
        hidden: !val.userSettings.visible,
        links: createLinks(id),
        locked: val.locked,
    };
};

export const actions = (store) => {
    const setChannelSortState = (channelSort) => {
        store.setState({
            channelSort,
        });

        return Promise.resolve(store.getState());
    };

    const __actions = {
        init: async (state = store.getState()) => {
            __actions.clearError(state);

            try {
                const { data } = await station.stations();
                const channels = data?.reduce((acc, val) => {
                    acc.push(new Channel(mapChannelData(val)));
                    return acc;
                }, []);

                return __actions.updateChannelIndex(store.getState(), List(channels));
            } catch (e) {
                setChannelSortState(state.channelSort.set('error', ERROR_INIT));
            }

            return undefined;
        },

        sortChannels: (state = store.getState(), orderedIds, draggedChannel) => {
            const channels = state.channelSort.get('channelsAll');
            const reorderedChannels = channels.sort((a, b) => {
                return orderedIds.indexOf(a.id) - orderedIds.indexOf(b.id);
            });

            const newChannelPos = orderedIds.indexOf(draggedChannel);
            track(WebClientGAEvent.ChannelSort, WebClientGAEvent.MoveChannel, draggedChannel, {
                event_details: newChannelPos,
                screen_name: 'channel_sort',
            });

            return __actions
                .updateChannelIndex(store.getState(), reorderedChannels)
                .then(__actions.saveChannels);
        },

        shiftVisibleChannel: (state = store.getState(), fromIdx, targetIdx) => {
            if (fromIdx === targetIdx) {
                return undefined;
            }

            const idxShift = fromIdx < targetIdx ? 1 : 0;
            let channelsAll = state.channelSort.get('channelsAll');
            const channelsVis = state.channelSort.get('channelsVisible');

            const sourceItem = channelsVis.get(fromIdx);
            const targetItem = channelsVis.get(targetIdx);

            channelsAll = channelsAll.delete(channelsAll.indexOf(sourceItem));
            const targetIndex = channelsAll.indexOf(targetItem);
            channelsAll = channelsAll.insert(targetIndex + idxShift, sourceItem);

            track(
                WebClientGAEvent.ChannelSort,
                WebClientGAEvent.MoveChannel,
                sourceItem?.displayName,
                {
                    event_details: targetIndex + idxShift,
                    screen_name: 'channel_sort',
                },
            );

            return __actions
                .updateChannelIndex(store.getState(), channelsAll)
                .then(__actions.saveChannels);
        },

        toggleChannel: (state = store.getState(), channelId, show = false) => {
            let channelsAll = state.channelSort.get('channelsAll');
            let ctxChannel;
            channelsAll = channelsAll.map((item) => {
                if (item.id === channelId) {
                    item.hidden = !show;
                    ctxChannel = item;
                }
                return item;
            });

            const evtDescription = show
                ? WebClientGAEvent.AddChannel
                : WebClientGAEvent.RemoveChannel;
            track(WebClientGAEvent.ChannelSort, evtDescription, ctxChannel.displayName, {
                screen_name: 'channel_sort',
            });

            return __actions
                .updateChannel(store.getState(), ctxChannel)
                .then(__actions.updateChannelIndex(store.getState(), channelsAll))
                .then(() => __actions.saveChannels());
        },

        showHiddenChannels: (state = store.getState()) => {
            setChannelSortState(state.channelSort.set('showAllChannelsState', undefined));

            const channelsAll = state.channelSort.get('channelsAll');
            const hiddenChannelsCount = channelsAll.filter((channel) => channel.hidden).size;

            const updatedChannels = channelsAll.map((channel) => ({
                ...channel,
                hidden: false,
            }));

            const payload = updatedChannels.toJS().map(({ stationId, faved }) => ({
                stationId,
                favorite: faved,
                visible: true,
            }));

            return station
                .updateAll(payload)
                .then(() => {
                    setChannelSortState(
                        state.channelSort
                            .set('channelsAll', updatedChannels)
                            .set('showAllChannelsState', hiddenChannelsCount),
                    );
                })
                .catch(() => {
                    setChannelSortState(state.channelSort.set('showAllChannelsState', 'error'));
                });
        },

        toggleResetDialog: (state = store.getState(), show = false) => {
            return setChannelSortState(state.channelSort.set('showResetDialog', show));
        },

        cancelResetChannels: () => {
            track(WebClientGAEvent.ChannelSort, WebClientGAEvent.RevokeChannelReset, undefined, {
                screen_name: 'channel_sort',
            });
        },

        resetChannels: (state = store.getState()) => {
            track(WebClientGAEvent.ChannelSort, WebClientGAEvent.ResetChannels, undefined, {
                screen_name: 'channel_sort',
            });

            // Clear the state before doing anything
            setChannelSortState(state.channelSort.set('resetState', undefined));

            return station
                .reset()
                .then(() => {
                    setChannelSortState(state.channelSort.set('resetState', 'success'));
                    return __actions
                        .toggleResetDialog(store.getState(), false)
                        .then(__actions.init);
                })
                .catch(() => {
                    const errorState = state.channelSort
                        .set('error', ERROR_RESET)
                        .set('resetState', 'error')
                        .set('showResetDialog', false);

                    return setChannelSortState(errorState);
                });
        },

        toggleFavorite: (state = store.getState(), channelId) => {
            let channelsAll = state.channelSort.get('channelsAll');
            let ctxChannel;

            channelsAll = channelsAll.map((item) => {
                const channel = new Channel(item);

                if (channel.id === channelId) {
                    channel.faved = !channel.faved;
                    ctxChannel = channel;
                }

                return channel;
            });

            if (!ctxChannel) {
                // channel not found, nothing to toggle
                return Promise.resolve();
            }

            const evtDescription = ctxChannel.faved
                ? WebClientGAEvent.MarkFavourite
                : WebClientGAEvent.UnmarkFavourite;
            track(WebClientGAEvent.ChannelSort, evtDescription, ctxChannel.displayName, {
                screen_name: 'channel_sort',
            });

            return __actions
                .updateChannel(store.getState(), ctxChannel)
                .then(__actions.updateChannelIndex(store.getState(), channelsAll))
                .then(() => __actions.saveChannels());
        },

        updateChannel: async (state = store.getState(), channel) => {
            let response;

            __actions.clearError(state);

            try {
                response = await station.update(channel.stationId, channel.faved, !channel.hidden);
            } catch (e) {
                setChannelSortState(state.channelSort.set('error', ERROR_SAVE));
            }

            return response;
        },

        saveChannels: async (state = store.getState()) => {
            let response;
            const channelsAll = state.channelSort
                .get('channelsAll')
                .toJS()
                .map((channel) => channel.stationId);
            await __actions.clearError(state);

            try {
                response = await station.reorder(channelsAll);
            } catch (e) {
                await setChannelSortState(state.channelSort.set('error', ERROR_SAVE));
            }

            return response;
        },

        updateChannelIndex: (state = store.getState(), channelsAll) => {
            const { channelSort } = state;
            const nextState = channelSort
                .set('channelsAll', channelsAll)
                .set(
                    'channelsVisible',
                    channelsAll.filter((item) => !item.hidden),
                )
                .set(
                    'channelsHidden',
                    channelsAll.filter((item) => !!item.hidden).sort(sortByNameComparator),
                );

            return setChannelSortState(nextState);
        },

        setEditMode: (state = store.getState(), edit = false) => {
            const { channelSort } = state;

            return setChannelSortState(channelSort.set('editMode', edit));
        },

        clearError: (state = store.getState()) => {
            const { channelSort } = state;

            return setChannelSortState(channelSort.set('error', ERROR_NONE));
        },
    };

    return __actions;
};

export default actions;
