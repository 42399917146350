/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */
import { h } from 'preact';
import { NotificationScreen } from '@exaring/ui';

export const OSDOverlayScreen = ({ className, type, children, opaque = true }) => {
    return (
        <NotificationScreen className={className} type={type} content={children} opaque={opaque} />
    );
};
