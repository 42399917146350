/* eslint-disable import/prefer-default-export */
import { readFromBinaryFile, parseExifCommentData } from '../../exif';

export const arrayBufferToImage = (arrayBuffer) => {
    const exifData = readFromBinaryFile(arrayBuffer);
    const exifCommentData = parseExifCommentData(exifData.UserComment);

    const blob = new Blob([new Uint8Array(arrayBuffer)], { type: 'image/jpeg' });

    return { ...exifCommentData, blob };
};
