import { h, FunctionComponent, Fragment } from 'preact';
import { CSS } from '@stitches/react';
import { Button, StyledDeleteIcon } from '@exaring/ui/components-styled/Button';
import { ShowOnlyOnDesktop } from '@exaring/ui/components-styled/ShowOnlyOnDesktop/ShowOnlyOnDesktop';
import { HeaderMenu } from '../PageHeadline/PageHeader';

export const RecordingEditMenu: FunctionComponent<{
    css?: CSS;
    onDelete: () => void;
    disabled?: boolean;
}> = ({ css, onDelete, disabled }) => {
    return (
        <>
            <HeaderMenu css={css}>
                <Button
                    css={{
                        fontSize: '$2',
                        height: '36px',
                    }}
                    epg
                    onClick={onDelete}
                    active={false}
                    disabled={disabled}
                    icon={<StyledDeleteIcon />}
                >
                    <ShowOnlyOnDesktop>Löschen</ShowOnlyOnDesktop>
                </Button>
            </HeaderMenu>
        </>
    );
};
