import {
    recordings,
    recordingDetails,
    recordingGroup,
    schedule,
    unschedule,
    stop,
    streamingDetails,
    deleteRecordings,
    deleteFailed,
} from '../requests/recordings.v4';
import { requestHookChain } from '../networking.v2';

const api = {
    recordings: requestHookChain(recordings),
    recordingDetails: requestHookChain(recordingDetails),
    streamingDetails: requestHookChain(streamingDetails),
    recordingGroup: requestHookChain(recordingGroup),
    schedule: requestHookChain(schedule),
    unschedule: requestHookChain(unschedule),
    deleteRecordings: requestHookChain(deleteRecordings),
    stop: requestHookChain(stop),
    deleteFailed: requestHookChain(deleteFailed),
};

export default api;
