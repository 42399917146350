import { z } from 'zod';

const recordingSummarySchema = z.object({
    availableRecordingHours: z.number(),
    finishedRecordingsSeconds: z.number(),
    numberOfFinishedRecordings: z.number(),
    numberOfLostRecordings: z.number(),
});

export type RecordingSummary = z.infer<typeof recordingSummarySchema>;

export const isValidRecordingSummaryJson = (value: unknown): value is RecordingSummary => {
    return recordingSummarySchema.safeParse(value).success;
};

export const decodeRecordingSummary = (value: unknown): RecordingSummary | undefined => {
    return isValidRecordingSummaryJson(value) ? value : undefined;
};
