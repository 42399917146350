import { CSS } from '../theme';

export const clipTextOverflowStyle: CSS = {
    display: '-webkit-box',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
};

export const headlineStyle = {
    ...clipTextOverflowStyle,
    fontFamily: 'inherit',
    gridArea: 'headline',
    fontWeight: '$3',
    fontSize: '$8',
    letterSpacing: 'normal',
    alignSelf: 'flex-start',
    '-webkit-line-clamp': '2',
    overflow: 'hidden',
    color: '$white',
    lineHeight: '1.5',
    minHeight: '48px',
    margin: '0px',
};
