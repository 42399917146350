/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */
import { List, Map } from 'immutable';
import { localStorageItem } from '@exaring/utils';
import type { Program } from '@exaring/ui/components-styled/types/Epg';
import { Program as Epg2Program } from '@nessprim/planby';
import { isSafari, epgV2ProgramDetailsToV1 } from '../helper';
import { PLAYOUT_LIVE, PLAYOUT_RECORDING, PLAYOUT_VOD } from './constants';

/*
 * Index native <Array[Object]> by given key
 * This function should be used to generate a <Map> index from a native
 * array.
 *
 * WARNING: This function is not compatible with immutable.js <List> type.
 * @returns <Map>
 */
export function generateIndex<T extends Record<string, any>>(
    list: T[],
    key: string,
    overrideOnCollision = true,
) {
    return Map().withMutations((idx) => {
        for (let i = 0, n = list.length; i < n; i += 1) {
            if (list[i]?.[key] && (overrideOnCollision || !idx.has(list[i]?.[key]))) {
                idx.set(list[i]?.[key], list[i]);
            }
        }
    });
}

/**
 * Use in case you want to group list entities and index them by the
 * given key.
 *
 * @param list<Array|List[Object]>
 * @param _idxKey<string>
 * @returns <Object>
 */
export function generateGroupIndex<T extends Record<string, any>>(list: T[], _idxKey: string) {
    const sIdx: Record<string, any> = {};

    list.forEach((item) => {
        const key = item[_idxKey];

        if (!sIdx[key]) {
            sIdx[key] = List();
        }

        sIdx[key] = sIdx[key].push(item);
    });

    return Map(sIdx);
}

/**
 * Creates a fake recording object to be used in the recording store
 *
 * @param {object} program
 * @param {string} status
 * @param {string/number} programId
 * @param {number} groupId
 * @returns {Recording}
 */

export const createPlaceHolder = (
    program: Program,
    status: string,
    programId: string,
    groupId: number,
) => {
    return {
        id: programId,
        channelId: program.channel,
        epgData: program,
        startTime: program.startTime,
        stopTime: program.stopTime,
        recordingGroup: groupId,
        isPlaceHolder: true,
        programId,
        status,
    };
};

export const CrudAction = {
    Set: 1,
    Delete: 2,
};

/*
 * @deprecated This function is not as useful as expected.
 *
 * This function should be used to perform store transactions. You should use it
 * to set, update or delete entities of a store.
 *
 * CrudAction.Delete also handles <Array> transaction sets to update multiple entities.
 * @param: transaction {
 *     state: <object>, // state reference of store
 *     entity: <Object>, // updated entity
 *     action: <CrudAction>, // action type
 *     stateIdx: <String>, // store reference name ex. 'recording'
 *     idxKey: <String> // index key to find entity in store
 * }
 */
export function transaction(_transaction: any) {
    const nextState: any = {};

    switch (_transaction.action) {
        case CrudAction.Set:
            nextState[_transaction.stateIdx] = _transaction.state.set(
                _transaction.entity[_transaction.idxKey],
                _transaction.entity,
            );
            break;
        case CrudAction.Delete:
            if (Array.isArray(_transaction.entity)) {
                nextState[_transaction.stateIdx] = _transaction.state.withMutations((idx: any) => {
                    for (let i = 0, n = _transaction.entity.length; i < n; i += 1) {
                        idx.delete(_transaction.entity[i][_transaction.idxKey]);
                    }
                });
            } else {
                nextState[_transaction.stateIdx] = _transaction.state.delete(
                    _transaction.entity[_transaction.idxKey],
                );
            }
            break;
        default: // do nothing
    }

    return nextState;
}

export function lastActiveChannel(): string | undefined {
    return localStorageItem('lastActiveChannel');
}

export function setLastActiveChannel(value: any) {
    return localStorageItem('lastActiveChannel', value);
}

export const epgV2ProgramToV1 = (program: Epg2Program) => {
    return {
        id: program?.id,
        title: program?.title,
        startTime: program?.since,
        stopTime: program?.till,
        channel: program?.channelUuid,
        duration: 0, // TODO if needed?
    };
};

// ToDo: Turn this helper into an action in the epg store to look for programs on specific channels and then write unit tests
/**
 * Returns the current active program for the current active channel
 * @param store - needs our store to work on
 * @returns {object}
 */
export const getCurrentProgram = async (playout: any, epgStore: any) => {
    const { activeChannelId } = playout;

    if (!activeChannelId) {
        return undefined;
    }

    const { getLiveProgram, fetchEpgDetails } = epgStore;
    const liveProgram = await getLiveProgram(activeChannelId.toLowerCase());
    let liveProgramDetails;

    if (liveProgram) {
        liveProgramDetails = await fetchEpgDetails(liveProgram?.id);
    }

    return liveProgramDetails ? epgV2ProgramDetailsToV1(liveProgramDetails) : liveProgram;
};

export const getVideoStreamAndContentType = (
    playout: any,
): {
    videoStreamType: 'Livestream' | 'TVFuse' | 'Recording';
    videoContentType: 'CHANNEL' | 'VOD' | 'RECORDING';
    // eslint-disable-next-line no-restricted-syntax
} | null => {
    const { playoutType } = playout;

    switch (playoutType) {
        case PLAYOUT_LIVE:
            return { videoStreamType: 'Livestream', videoContentType: 'CHANNEL' };
        case PLAYOUT_VOD:
            return { videoStreamType: 'TVFuse', videoContentType: 'VOD' };
        case PLAYOUT_RECORDING:
            return { videoStreamType: 'Recording', videoContentType: 'RECORDING' };
        default:
            // This is legacy code, that still uses the `null` value.
            // eslint-disable-next-line no-restricted-syntax
            return null;
    }
};

export const getStreamUrl = (playerStore: any): string => {
    const { hlsUrl, dashUrl } = playerStore;
    return isSafari() ? hlsUrl : dashUrl;
};

export const getAvailableRecordingSeconds = (
    maxStorage: number,
    usedStorage: number,
): number | undefined => (maxStorage && usedStorage ? maxStorage - usedStorage : undefined);
