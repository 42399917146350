/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, Fragment } from 'preact';
import { rootPath, events } from '@exaring/utils';
import {
    Television,
    Epg,
    Waiputhek,
    Recordings,
    Search,
} from '@exaring/ui/components/header--logged-in/navigation-items';
import { Routes, routeTo } from '../../../routes';
import { FeatureFlags } from '../../../constants';
import { WebClientGAEvent } from '../../../web-client-ga';
import strings from '../../../strings';
import { getCurrentScreen } from '../../../helper';

const isPathActive = (path) => path === rootPath(window.location.pathname);

const InternalLinks = ({ toggleActive, track }) => {
    const isProgramActive =
        isPathActive(Routes.EPG_PAGE) || isPathActive(Routes.CHANNELCONFIG_PATH);
    const isRecordingActive =
        isPathActive(Routes.RECORDING_PAGE) || isPathActive(Routes.RECORDING_PLAYOUT_PAGE);
    const isWaiputhekContentPlaying = isPathActive(Routes.VOD_PLAYOUT_PAGE);
    const isWaiputhekActive = isPathActive(Routes.WAIPUTHEK_PAGE) || isWaiputhekContentPlaying;
    const isTvActive =
        !isProgramActive && !isRecordingActive && !isWaiputhekActive && !isWaiputhekContentPlaying;

    const bindToRoute = (route, trackId, eventDescription, aditionals) => () => {
        track(trackId, eventDescription, aditionals);
        return routeTo(route);
    };

    return (
        <Fragment>
            <Television
                active={isTvActive}
                action={bindToRoute(
                    Routes.LIVE_TV_PAGE,
                    WebClientGAEvent.Menu,
                    WebClientGAEvent.LiveTV,
                    {
                        screenName: getCurrentScreen(),
                    },
                )}
            />
            <Epg
                active={isProgramActive}
                action={bindToRoute(Routes.EPG_PAGE, WebClientGAEvent.Menu, WebClientGAEvent.Epg, {
                    screenName: getCurrentScreen(),
                })}
            />
            {FeatureFlags.enableWaiputhek && (
                <Waiputhek
                    active={isWaiputhekActive}
                    action={bindToRoute(
                        Routes.WAIPUTHEK_PAGE,
                        WebClientGAEvent.Menu,
                        WebClientGAEvent.Waiputhek,
                        {
                            screenName: getCurrentScreen(),
                        },
                    )}
                    label={strings.waiputhekLabel}
                    tooltip={strings.waiputhekLabel}
                />
            )}
            <Recordings
                active={isRecordingActive}
                action={bindToRoute(
                    Routes.RECORDING_PAGE,
                    WebClientGAEvent.Menu,
                    WebClientGAEvent.Recordings,
                    {
                        screenName: getCurrentScreen(),
                    },
                )}
            />
            <Search
                action={(e) => {
                    events.pauseEvent(e);
                    toggleActive();
                    track(WebClientGAEvent.Menu, WebClientGAEvent.Search, {
                        screenName: getCurrentScreen(),
                    });
                }}
            />
        </Fragment>
    );
};

export default InternalLinks;
