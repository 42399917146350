/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { CSS } from '@stitches/react';
import { h, RenderableProps } from 'preact';
import { forwardRef } from 'preact/compat';
import { styled } from '../theme';

const GRID_COL_WIDTH = 100 / 12; // in %
const GRID_COL_SIZES = [1, 1.5, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] as const;

//
// Styled components
//

const StyledGridContainer = styled('div', {
    display: 'flex',
    flexWrap: 'wrap',
});

const StyledGridItem = styled('div', {
    variants: {
        gridWidth: GRID_COL_SIZES.reduce<{ [size: number]: CSS }>(
            (previous, current) => ({
                ...previous,
                [current]: { width: `${current * GRID_COL_WIDTH}%` },
            }),
            {},
        ),
    },
});

//
// Components
//

export const GridContainer = forwardRef<
    HTMLDivElement,
    RenderableProps<{
        direction?: 'row' | 'row-reverse' | 'column';
        justifyContent?:
            | 'flex-start'
            | 'flex-end'
            | 'center'
            | 'space-between'
            | 'space-around'
            | 'space-evenly';
        alignItems?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline';
        className?: string;
        onClick?: (event: MouseEvent | TouchEvent) => void;
        css?: CSS;
    }>
>(({ children, justifyContent, alignItems, direction, css, className, onClick }, ref) => {
    return (
        <StyledGridContainer
            ref={ref}
            className={className}
            css={{ ...css, justifyContent, alignItems, flexDirection: direction }}
            onClick={onClick as any}
        >
            {children}
        </StyledGridContainer>
    );
});

export interface GridItemProps {
    initialSize?: number;
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    css?: CSS;
    className?: string;
    as?: any;
    onClick?: () => void;
}

export const GridItem = forwardRef<HTMLDivElement, RenderableProps<GridItemProps>>(
    ({ children, initialSize, xs, sm, md, lg, xl, css, className, as, onClick }, ref) => {
        return (
            <StyledGridItem
                ref={ref}
                className={className}
                gridWidth={{
                    '@initial': initialSize ?? 12,
                    '@xs': xs,
                    '@sm': sm,
                    '@md': md,
                    '@lg': lg,
                    '@xl': xl,
                }}
                css={css}
                as={as}
                onClick={onClick}
            >
                {children}
            </StyledGridItem>
        );
    },
);
