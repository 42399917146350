import { logErrorWithDescription } from '@exaring/utils/error-logging';
import { codeChallenge as hashCodeChallenge } from '@exaring/utils/crypto';
import { randomString } from '@exaring/utils/string';
import { randomNumber } from '@exaring/utils/math';
import {
    sessionStorageGet,
    sessionStorageSet,
    sessionStorageRemove,
} from '@exaring/utils/session-storage';
import {
    removeUrlParams,
    removeUrlHashParams,
    urlTplParams,
    getParameterByName,
    replaceHistoryState,
} from '@exaring/utils/url';
import oauth2Service from '../services/oauth2';
import { token } from '../requests/auth';

export const getOrSetSession = (key, generator) => {
    try {
        let val = sessionStorageGet(key);

        if (!val) {
            val = generator?.();
            sessionStorageSet(key, val);
        }

        return val;
    } catch (e) {
        logErrorWithDescription(e, 'during oauth2'); // possibly user has disallowed sessionStorage?
        return undefined;
    }
};

export const cleanSession = (key) => sessionStorageRemove(key);

export const cachedCodeChallenge = () => getOrSetSession('c', randomString);

export const cleanupCachedCodeChallenge = () => cleanSession('c');

export const cachedState = () => getOrSetSession('s', () => randomNumber(1e6, 1e12));

export const cleanupCachedState = () => cleanSession('s');

export const cleanupAuthCache = () => {
    cleanupCachedCodeChallenge();
    cleanupCachedState();
    cleanSession('urlState'); // todo: remove, just for transition purposes, cleanup old states
};

export const authRedirectUrl = async (baseUrl, clientId, redirectUrl) => {
    const params = {
        redirect_uri: encodeURI(
            redirectUrl ??
                removeUrlHashParams(
                    removeUrlParams(window.location.href.replace('http://', 'https://'), 'state'),
                ),
        ),
        client_id: clientId,
        code_challenge: await hashCodeChallenge(cachedCodeChallenge()),
        response_type: 'code',
        code_challenge_method: 'S256',
        state: cachedState(),
    };

    return urlTplParams(baseUrl, params);
};

export const oauth2 = async ({
    clientId,
    onSuccess,
    onUnauthorized,
    onError,
    fetchFn = oauth2Service.token,
    doReplaceHistory = true,
}) => {
    const code = getParameterByName('code');

    const locationUrl = new URL(window.location.href);
    const locationSearchPath = new URLSearchParams(locationUrl.search);

    const authStateInStorage = cachedState();
    const authStateInURL = locationSearchPath.get('state');

    const stateIsValid = parseInt(authStateInStorage, 10) === parseInt(authStateInURL, 10);

    if (doReplaceHistory) {
        replaceHistoryState(
            removeUrlParams(removeUrlHashParams(window.location.href), 'state', 'code'),
        );
    }

    /* eslint-disable no-unused-expressions */
    if (code && stateIsValid) {
        // exchange code with access token
        try {
            const { data } = await fetchFn(
                code,
                cachedCodeChallenge(),
                window.location.href,
                clientId,
            );
            cleanupAuthCache();
            onSuccess?.(data);
        } catch (e) {
            cleanupAuthCache();
            onError?.(e);
        }
    } else if (code && !stateIsValid) {
        cleanupAuthCache();
        onError?.(Error('State is not valid'));
    } else {
        cleanupAuthCache();
        onUnauthorized?.(authStateInStorage && authStateInURL); // indicate unauthorized redirect
    }
}; /* eslint-enable no-unused-expressions */

export const doAuth = (clientId) => {
    return new Promise((resolve, reject) => {
        oauth2({
            clientId,
            fetchFn: async (...args) => {
                const { url, ...opts } = token(...args);
                const res = await fetch(url, opts);
                const data = await res.json();
                return { data };
            },
            onSuccess: resolve,
            onError: reject,
            onUnauthorized: reject,
            doReplaceHistory: false,
        });
    });
};
