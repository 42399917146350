import { h, Fragment, FunctionComponent } from 'preact';
import { CSS } from '@stitches/react';
import { styled } from '@exaring/ui/components-styled/theme';
import ArrowBackIcon from '@exaring/assets/icons/arrow-back.svg';
import { Headline } from './Headline';

export const PAGE_HEADLINE_HEIGHT = 84;

const StyledBackIcon = styled(ArrowBackIcon, {
    width: '44px',
    height: '44px',
    marginLeft: '6px',
    fill: '$white',
    cursor: 'pointer',
});

const StyledHeadlineContainer = styled('div', {
    position: 'fixed',
    width: '100%',
    zIndex: 3,
    display: 'flex',
    alignItems: 'center',
    height: `${PAGE_HEADLINE_HEIGHT}px`,
    backgroundRgba: { color: 'nearBlack', opacity: 0.9 },
});

const StyledImage = styled('img', {
    position: 'absolute',
    top: '20px',
    right: '65px',
    height: '110px',
    display: 'none',

    '@sm': {
        display: 'initial',
    },
});

const StyledHeadlineStub = styled('div', {
    width: '100%',
    height: '100px',
});

export const PageHeadline: FunctionComponent<{
    title?: string;
    subtitle?: string;
    imgUrl?: string;
    onBack?: () => void;
    className?: string;
    css?: CSS;
}> = ({ title, subtitle, imgUrl, onBack, css, className }) => {
    return (
        <>
            <StyledHeadlineContainer css={css} className={className}>
                {onBack && <StyledBackIcon onClick={onBack} />}
                <Headline subtitle={subtitle} css={onBack ? { marginLeft: '15px' } : undefined}>
                    {title}
                </Headline>
                {imgUrl && <StyledImage src={imgUrl} />}
            </StyledHeadlineContainer>
            <StyledHeadlineStub />
        </>
    );
};
