/* eslint-disable no-restricted-syntax */

import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { copyToClipboard, isNil, logErrorWithDescription } from '@exaring/utils';

import { playerInfo, playerTechInfo } from '../../actions/player';
import constants from '../../constants';
import { detectBrowser, detectOS, isSafari as isSafariBrowser } from '../../helper';

const NO_PLAYER_INFO_ERROR = 'Fehler: "Player nicht initialisiert"';

const Buildinfo = ({ hideBuildInfo }) => {
    const navigator = window.navigator || {};
    const userAgent = navigator.userAgent || 'unknown';
    const platform = navigator.platform || 'unknown';
    const OSVersion = detectOS(userAgent, platform);
    const Browser = detectBrowser(navigator);
    const [techInfo, setTechInfo] = useState(null);
    const isSafari = isSafariBrowser();

    useEffect(() => {
        const getPlayerInfo = async () => {
            setTechInfo((await playerTechInfo()) || NO_PLAYER_INFO_ERROR);
        };
        getPlayerInfo();
    }, []);

    const getDRMsupport = () => {
        if (isNil(techInfo)) {
            return 'loading...';
        }

        if (techInfo === NO_PLAYER_INFO_ERROR) {
            return NO_PLAYER_INFO_ERROR;
        }

        try {
            if (isSafari) {
                return techInfo.probeSupport.drm['com.apple.fps'] ? 'Yes' : 'No';
            }

            return techInfo.probeSupport.drm['com.widevine.alpha'] ? 'Yes' : 'No';
        } catch (e) {
            logErrorWithDescription(e, 'while trying to determine DRM support.');
        }

        return 'No';
    };

    const copyDebugInfoToClipBoard = async () => {
        let techInfoString;

        try {
            techInfoString = JSON.stringify(techInfo, null, 2);
        } catch (e) {
            techInfoString = 'Error while getting technical information.';
            logErrorWithDescription(e, 'while trying to create tech info');
        }

        // array join to maintain correct format without tabs
        const debugInfo = [
            `WebClient: ${constants.RELEASE} (${constants.GIT_HASH})`,
            `Player: ${playerInfo()}`,
            `Browser: ${Browser.name} - Version: ${Browser.version} (${navigator.userAgent})`,
            `Plattform: ${platform}`,
            `Betriebssystem: ${OSVersion}`,
            `DRM Support (${isSafari ? 'Fairplay' : 'Widevine'}): ${getDRMsupport()}`,
            `Technische Details:`,
            `${techInfoString}`,
        ].join('\n');

        await copyToClipboard(debugInfo);
    };

    return (
        <div className="debug-wrapper">
            <div className="debug-info__header">
                <span>
                    <span className="debug-info__icon icon icon--info" />
                    Debug-Informationen
                    <button className="copy-btn" type="button" onClick={copyDebugInfoToClipBoard}>
                        In Zwischenablage kopieren
                    </button>
                </span>
                <span className="debug-info__icon icon icon--close-white" onClick={hideBuildInfo} />
            </div>
            <span className="debug-info">
                <b>WebClient:</b> {constants.RELEASE} ({constants.GIT_HASH})
            </span>
            <span className="debug-info player-info">
                <b>Player:</b> {playerInfo()}
            </span>
            <span
                className="debug-info"
                style={{
                    maxWidth: '500px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                <b>Browser:</b> {Browser.name} - Version: {Browser.version}
                <br />({navigator.userAgent})
            </span>
            <span className="debug-info">
                <b>Plattform:</b> {platform}
            </span>
            <span className="debug-info">
                <b>Betriebssystem:</b> {OSVersion}
            </span>
            <span className="debug-info">
                <b>DRM Support ({isSafari ? 'Fairplay' : 'Widevine'}):</b> {getDRMsupport()}
            </span>
            <span className="debug-info">
                <b>Technische Details:</b>
                <br />
                <textarea className="technical-info-area">
                    {JSON.stringify(techInfo, null, 2)}
                </textarea>
            </span>
        </div>
    );
};

export default Buildinfo;
