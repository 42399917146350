/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import createStore from 'unistore';
import devtools from 'unistore/devtools';
import { unistoreSetup } from '@exaring/utils';
import { initialState as channelSort, actions as channelSortActions } from './actions/channel-sort';
// eslint-disable-next-line import/no-cycle
import playoutActions, { initialState as playout } from './actions/playout'; // FIXME dependency cycle
import playerActions, { initialState as player } from './actions/player';

const initialState = {
    playout,
    player,
    channelSort,
};

// Redux DevTools are slowing down the app significantly and should only be enabled for debug purposes
const shouldEnableReduxDevTools = false;
const store =
    process.env.NODE_ENV !== 'production' && shouldEnableReduxDevTools
        ? devtools(createStore(initialState))
        : createStore(initialState);

const scopes = {
    // scope: actions
    playout: playoutActions,
    player: playerActions,
    channelSort: channelSortActions,
};

unistoreSetup(store, scopes);

export default store;
