/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';
import Icon from '../../elements/icon';

import ChannelLogo from '../channel-logo';

export default ({ id, label, iconUrl, sourceSet, title, onAddAction }) => {
    return (
        <div className="channel-list__item">
            <div className="channel-list__item__action-bar-left">
                <span className="channel-list__item__action-bar-left__alpha-label">{label}</span>
            </div>
            <div className="channel-list__item__content">
                <div className="channel-list__item__content__image">
                    <ChannelLogo src={iconUrl} sourceSet={sourceSet} />
                </div>
                <h2 className="channel-list__item__content__title">{title}</h2>
            </div>
            <div className="channel-list__item__action-bar-right">
                <Icon className="icon--channel-add" onClick={() => onAddAction(id)} />
            </div>
        </div>
    );
};

/*
SelectableChannelListItem.propTypes = {
    id: React.PropTypes.string,
    title: React.PropTypes.string,
    iconUrl: React.PropTypes.string,
    hidden: React.PropTypes.bool,
    label: React.PropTypes.string,
    onAddAction: React.PropTypes.func
} */
