/*
 * Recording stream position
 * This service handles recording position as well tvfuse VOD stream positions
 * @docs http://business-systems.doc.exaring.net/doc/recording-stream-position/index.html
 */

import constants from '../constants';
import { get, put } from '../request';

/**
 * @param {String} id (recording or tvfuse id)
 * @return {Object}
 */
export const position = (id) =>
    get(`${constants.STREAM_POSITION_BASE_URL}/stream-positions/${id}`, {
        Accept: 'application/vnd.waipu.stream-position.v1+json',
    });

/**
 * @param {String} id (recording or tvfuse id)
 * @param {Number} position
 * @return {Object}
 */
export const updatePosition = (id, streamPosition) => {
    return put(
        `${constants.STREAM_POSITION_BASE_URL}/stream-positions/${id}`,
        { 'Content-Type': 'application/vnd.waipu.stream-position-request.v1+json' },
        { position: streamPosition },
    );
};
