import { h, FunctionComponent, ComponentChildren } from 'preact';
import { assetUrl } from '../helper';
import { TileHeroEmptyRoom } from '../components/Tile/TileHero/TileHeroEmptyRoom';
import { WaipuPackageLogo } from '../components/Tile/TileHero/TileHeroShared';

interface TileHeroEmptyRoomWrapperProps {
    children: ComponentChildren;
    isFreeUser: boolean;
}

export const TileHeroEmptyRoomWrapper: FunctionComponent<TileHeroEmptyRoomWrapperProps> = ({
    isFreeUser,
    children,
}) => {
    return (
        <TileHeroEmptyRoom
            imgUrl={assetUrl('recordings-empty-room.jpg')}
            isUpgrade={isFreeUser}
            isEmpty={!isFreeUser}
            imageContent={
                isFreeUser ? (
                    <WaipuPackageLogo filename="paketlogo-small-badge-perfect-plus" />
                ) : undefined
            }
        >
            {children}
        </TileHeroEmptyRoom>
    );
};
