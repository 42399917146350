import constants from '../constants';
import { get } from '../request';

/**
 * @param {String} channelId
 * @param {String} programId
 * @return {Object}
 */
export const recordingPlayoutUrl = (channelId, programId) => {
    if (!channelId) {
        throw new Error('missing parameter channelId');
    }
    if (!programId) {
        throw new Error('missing parameter programId');
    }

    return get(
        `${constants.WAIPUTHEK_BASE_URL}/channels/${channelId}/programs/${programId}/stream`,
        {
            Accept: 'application/vnd.waipu.waiputhek-recording-stream-v1+json',
        },
    );
};

export const getPageHighlights = () => {
    return get(`${constants.WAIPUTHEK_BASE_URL}/pages/highlights`, {
        Accept: 'application/vnd.waipu.waiputhek-page-v1+json',
    });
};
