/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, Fragment } from 'preact';
import { Help, Account } from '@exaring/ui/components/header--logged-in/navigation-items';

const UserLinks = () => {
    return (
        <Fragment>
            <Help />
            <Account />
        </Fragment>
    );
};

export default UserLinks;
