import { isSafari } from '../helper';
import { getLangSetting, LangKeyType } from './language';

declare global {
    interface Window {
        player: shaka.Player;
    }
}

const STYLE_TAG_ID = 'hideSubtitles';

export const isSubtitlesHiddenForSafari = () => {
    return !!document.querySelector(`#${STYLE_TAG_ID}`);
};

/**
 * adds a new style tag to the head to hide the subtitles.
 * this is necessary because there is a bug in safari which causes the subtitles
 * to always be visible even though it is disabled via shaka player config
 */
export const hideSubtitlesForSafari = () => {
    if (isSafari()) {
        if (!isSubtitlesHiddenForSafari()) {
            const styleElement = document.createElement('style');
            styleElement.id = STYLE_TAG_ID;
            styleElement.textContent =
                '#player_video::-webkit-media-text-track-container {display: none;}';
            document.head.appendChild(styleElement);
        }
    }
};

/**
 * removes the style tag from the head to show the subtitles again.
 */
export const showSubtitlesForSafari = () => {
    if (isSafari()) {
        document.querySelector(`#${STYLE_TAG_ID}`)?.remove();
    }
};

/**
 * returns the selected language for the subtitles.
 *
 * safari needs to be handled differently because of a bug in shaka player.
 * We cannot rely on the activeLanguageTrack and have to use the lang setting
 * from the session storage instead.
 */
export const getSelectedLanguage = (activeLanguageTrack: LangKeyType) => {
    if (isSafari()) {
        if (isSubtitlesHiddenForSafari()) {
            return 'OFF';
        }
        const lang = getLangSetting('text');
        return lang || 'OFF';
    }
    return activeLanguageTrack || 'OFF';
};

export const safariSubtitlesFix = () => {
    if (isSafari()) {
        const lang = getLangSetting('text');
        if (lang) {
            showSubtitlesForSafari();
            window.player.selectTextLanguage(lang);
        } else {
            hideSubtitlesForSafari();
        }
    }
};
