export const StreamQuality = ['hd', 'sd'];

/**
 * recording or playout streams array compliant
 * @param {Array} streams
 * @returns {object}
 */
export const playoutUrlsWithStreamsArray = (streams) => {
    return streams
        .map(
            (protocol) =>
                protocol?.links
                    .filter((link) => StreamQuality.includes(link.rel) && link.href) // filter sd and hd links or empty links
                    .map((link) => {
                        return { protocol: protocol.protocol, link: link.href };
                    })[0] || [{ protocol: undefined, link: undefined }], // map to only one url value (available quality will always be on index-0)
        )
        .reduce((acc, val) => {
            return { [acc.protocol]: acc.link, [val.protocol]: val.link }; // transform array of streams to single object
        });
};

/**
 * @param {object} playout
 * @returns {object}
 */
export const playoutUrls = (playout) => {
    return playoutUrlsWithStreamsArray(playout.streams);
};
