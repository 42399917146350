/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

const falsyStrings = ['false', '0', 'undefined', 'null', 'NaN', ''];
/**
 * Cast to boolean from any type with logical correct result.
 * logical correct falsy cast from: false, 0, undefined, null, NaN, '' (empty string)
 * logical correct truthy cast from: Any other than falsy
 * @param {any} value to cast to boolean
 * @returns {boolean}
 */
export const safeBoolean = (val) => !falsyStrings.includes(`${val}`);

export function isTouchDevice() {
    /* global DocumentTouch */
    return 'ontouchstart' in window || (window.DocumentTouch && document instanceof DocumentTouch);
}

export function infinitySequencer(targetLength, offset) {
    let newOffset;

    newOffset = offset / targetLength;

    if (offset === Math.floor(offset)) {
        newOffset = 0;
    } else {
        newOffset = offset.toString().split('.');
        newOffset = Math.floor(targetLength * (newOffset[1] / 100));
    }

    return newOffset;
}

export function infSequenceOffset(target, offset, pageSize = 0) {
    if (target.length === 0) {
        return undefined;
    }

    let newOffset = Math.abs(offset);
    const isNegative = offset < 0;
    const remainder = target.length % pageSize;

    // out of bounds
    if (newOffset + 1 > target.length) {
        newOffset = infinitySequencer(target.length, newOffset);
    }

    if (pageSize && remainder && isNegative) {
        // shift offset to fit page size
        // last page special offset shift
        if (target.length === target.length - newOffset + pageSize) {
            newOffset -= pageSize - remainder;
        } else {
            newOffset += pageSize - remainder; // odd offset defaulft
        }
    }

    if (isNegative) {
        return newOffset > 0 ? target.length - newOffset : target.length - 1;
    }

    return newOffset;
}

export function debounce(callback, ms, context = this) {
    let timer;

    return function debouncedInner(...args) {
        clearTimeout(timer);

        timer = setTimeout(() => {
            callback.apply(context, args);
        }, ms);
    };
}

/**
 * expects a object for which it calculates the position in the viewport
 * @param object
 * @returns  {Object}
 */
export function findPos(_obj) {
    let obj = _obj;

    let curleft = 0;
    let curtop = 0;
    let parentNode = obj.offsetParent;

    while (parentNode) {
        parentNode = obj.offsetParent;

        curleft += obj.offsetLeft;
        curtop += obj.offsetTop;

        obj = parentNode;
    }

    return {
        left: curleft,
        top: curtop,
    };
}

/**
 * implementation of the Java-helper hashCode for Strings
 * return of negative integer are valid
 * @param codableString (String)
 * @returns  {number}
 */
export function hashCode(codableString) {
    let hash = 0;
    let i;
    let char;
    const stringLength = codableString.length || 0;

    if (stringLength === 0) {
        return hash;
    }

    /* eslint-disable no-bitwise */
    for (i = 0; i < stringLength; i += 1) {
        char = codableString.charCodeAt(i); // fetch next character
        hash = (hash << 5) - hash + char; // rightshift hash 5 positions 1001 becomes 100100000
        hash &= hash; // convert the hash to a 32bit int
    }
    /* eslint-enable no-bitwise */
    return hash;
}

/**
 * simple polyfill for requestAnimationFrame
 * @param {function} cb callback to execute between two frames
 * @returns {number}
 */
export function rAF(cb) {
    const rAf =
        window.requestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        function rAFInner() {
            return setTimeout(cb, 16);
        };

    return rAf(cb);
}

export function cancelrAF(id) {
    const crAf =
        window.requestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        clearTimeout;

    return crAf(id);
}

/**
 * conditional determine whether or not return given param `cb` or a void function
 * @param {boolean} condition
 * @param {function} cb function to call if condition is truthy
 * @returns {function}
 */
export const conditionalCb = (condition = false, cb) => {
    return safeBoolean(condition) ? cb : () => {};
};
export const isEdgeBrowser = () => window.navigator.userAgent.indexOf('Edge') > -1;

export const isSafari = () => {
    const ua = window.navigator.userAgent;
    return ua.includes('Safari') && !ua.includes('Chrome');
};

export const streamProtocolBrowserDetection = () => (isSafari() ? 'hls' : 'mpd');

/**
 * function to retry a given action based on a given condition
 * @param {() => boolean} conditionalCallback conditional function, if resolves true, actionCallback is executed
 * @param {() => void} actionCallback function to call if conditionalCallback is truthy
 * @param {number} retries number of maximun amount of retrues
 * @param {number} retryIntervalInMs number of milliseconds for the polling interval
 */
export const retryIfNeeded = (
    conditionalCallback,
    actionCallback,
    retries = 3,
    retryIntervalInMs = 1000,
) => {
    if (conditionalCallback()) {
        actionCallback();
        return;
    }

    if (Math.max(retries - 1, 0) === 0) {
        return;
    }

    setTimeout(() => {
        retryIfNeeded(conditionalCallback, actionCallback, retries - 1, retryIntervalInMs);
    }, retryIntervalInMs);
};

/** @type {(value: string) => Promise<void>} */
export const copyToClipboard = async (value) => {
    await navigator.clipboard.writeText(value);
};

/** @type {(id: string) => boolean} */
export const isTunerServiceId = (id) =>
    /^([a-z|\d|\-|_]+:[a-z|\d|\-|_]+:[a-z|\d|\-|_]+)$/i.test(id);
