/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';

// type PopupMenuItem = {
//     action: Function,
//     label: string,
//     className?: string
// }

// type PopupMenuProps = {
//     items: Array<PopupMenuItem>,
//     doClose: Function,
//     className?: string
// }

/**
 * Use the popup-menu to present options for some action to the user.
 *
 * The component is not handling any visible / invisible state,
 * make sure within the parent to only render the popup-menu, the moment it is needed.
 *
    <PopupMenu
        className="sample__my-popup-menu"       // a specific className can be used to position it via css
        doClose={closePopupMenu}                // is called when ever the user clicked some were on the screen other then the items
        items={[                                // array with the options
            {
                label: 'say hello',             // string to be shown with the option
                action: () => {                 // callback
                    console.log('hello World');
                    closePopupMenu();
                }
            },
            {
                label: 'aboard',
                action: closePopupMenu
                className: 'button--aboard'     // add a additional className per item if needed
            }
        ]}
    />
 */
export default function PopupMenu({ className, doClose, items } /*: PopupMenuProps */) {
    return (
        <div className={`popup-menu ${className || ''}`}>
            <div className="popup-menu__background" onClick={doClose} />
            <div className="popup-menu__body">
                {items.map((button) => {
                    return (
                        <button
                            key={button.label}
                            type="button"
                            className={`popup-menu__item ${button.className || ''}`}
                            onClick={button.action}
                        >
                            {button.label}
                        </button>
                    );
                })}
            </div>
        </div>
    );
}
