import { Colors } from '@exaring/ui/components-styled/theme';

export const epgTheme = {
    primary: {
        600: Colors.dark, // program slot background
        900: Colors.nearBlack, // epg background
    },
    grey: { 300: Colors.white }, // program text
    white: '#fff',
    teal: { 100: '#38B2AC' },
    green: {
        200: '',
        // live edge
        300: Colors.brightNavyBlue,
    },
    scrollbar: {
        border: '#ffffff',
        thumb: {
            bg: '#e1e1e1',
        },
    },
    loader: {
        // not relevant as we using a custom loading indicator
        teal: '#5DDADB',
        purple: '#3437A2',
        pink: '#F78EB6',
        bg: '#171923db',
    },
    gradient: {
        blue: {
            // live program bg
            300: '#262430',
            600: '#262430',
            900: '#262430',
        },
    },

    text: {
        // timeline text
        grey: {
            300: '#a0aec0',
            500: '#718096',
        },
    },

    timeline: {
        divider: {
            bg: '#718096',
        },
    },

    grid: {
        // We don't really use those colors, but required by TS
        item: '#7180961a',
        divider: '#7180961a',
        highlight: '#38B2AC',
    },
};
