/* eslint-disable no-restricted-syntax */

/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

export function loadFile(fileUrl, fn, type = 'script') {
    // @deprecated see new async file loader
    const file =
        type === 'script' ? document.createElement('script') : document.createElement('link');

    file.rel = type === 'script' ? 'script' : 'stylesheet';
    file.type = type === 'script' ? 'text/javascript' : 'text/css';

    let done = false;
    let timer;

    function ready(err) {
        done = true;
        file.onload = null;
        file.onerror = null;
        file.onreadystatechange = null;
        clearTimeout(timer);
        fn(err);
    }

    const onLoadAndReadyStateChanged = function onLoad() {
        if (
            !done &&
            (!this.readyState || this.readyState === 'complete' || this.readyState === 'loaded')
        ) {
            ready(null);
        }
    };

    file.onreadystatechange = onLoadAndReadyStateChanged;
    file.onload = onLoadAndReadyStateChanged;

    file.onerror = (error) => {
        if (!done) {
            ready(error || new Error('Could not load file'));
        }
    };

    timer = setTimeout(() => {
        ready(new Error('Script loading timed-out'));
    }, 3e4); // 30 sec.

    if (type === 'script') {
        file.src = fileUrl;
    } else {
        if (file.addEventListener) {
            file.addEventListener('load', ready(null), false);
        }

        const cssnum = document.styleSheets.length;
        const ti = setInterval(() => {
            if (document.styleSheets.length > cssnum) {
                ready(null);
                clearInterval(ti);
            }
        }, 10);

        file.href = fileUrl;
    }
    document.getElementsByTagName('head')[0].appendChild(file);
}

export const loadFileAsync = (fileUrl, type = 'script', timeout = 3e4 /* 30sec */) => {
    return new Promise((resolve, reject) => {
        const file =
            type === 'script' ? document.createElement('script') : document.createElement('link');

        file.rel = type === 'script' ? 'script' : 'stylesheet';
        file.type = type === 'script' ? 'text/javascript' : 'text/css';
        file.async = true;
        let done = false;
        let timer;

        function ready() {
            done = true;
            file.onload = null;
            file.onerror = null;
            file.onreadystatechange = null;
            clearTimeout(timer);
            resolve();
        }

        const onLoadAndReadyStateChanged = function onLoad() {
            if (
                !done &&
                (!this.readyState || this.readyState === 'complete' || this.readyState === 'loaded')
            ) {
                ready(null);
            }
            resolve();
        };

        file.onreadystatechange = onLoadAndReadyStateChanged;
        file.onload = onLoadAndReadyStateChanged;

        file.onerror = (error) => {
            if (!done) {
                reject(error || new Error('Could not load file'));
            }
            reject(error);
        };

        timer = setTimeout(() => {
            reject(new Error('Script loading timed-out'));
        }, timeout);

        if (type === 'script') {
            file.src = fileUrl;
        } else {
            if (file.addEventListener) {
                file.addEventListener('load', ready(null), false);
            }

            const cssnum = document.styleSheets.length;
            const ti = setInterval(() => {
                if (document.styleSheets.length > cssnum) {
                    ready(null);
                    clearInterval(ti);
                }
            }, 10);

            file.href = fileUrl;
        }
        document.getElementsByTagName('head')[0].appendChild(file);
    });
};

export default loadFile;
