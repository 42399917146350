/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';

const InternalLinksEmpty = () => (
    <li className="header--logged-in__main-item header--logged-in__main-item--empty on-mobile" />
);

export default InternalLinksEmpty;
