import { useEffect, useRef } from 'preact/hooks';

export const useDelayedHover = (callback: () => void, delay: number) => {
    const timerRef = useRef<any>(undefined);
    const isMouseOver = useRef(false); // ref instead of state, to prevent unnecessary rerenders

    const onMouseEnter = () => {
        isMouseOver.current = true;
        timerRef.current = setTimeout(() => {
            if (isMouseOver.current) {
                callback();
            }
        }, delay);
    };

    useEffect(() => {
        return () => {
            clearTimeout(timerRef.current);
        };
    }, []);

    return {
        onMouseEnter,
        onMouseLeave: () => {
            isMouseOver.current = false;
            clearTimeout(timerRef.current);
        },
    };
};
