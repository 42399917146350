/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

/* eslint max-depth: ["error", 4] */

import constants from '../constants';

/**
 * Custom data encoding for license servers.
 * @param userHandle
 * @returns {string}
 */
function encodeData(userHandle) {
    const customData = {
        userId: userHandle,
        sessionId: 'default',
        merchant: 'exaring',
    };
    const encodedData = JSON.stringify(customData);
    return window.btoa(encodedData);
}

export const drmConfig = (userHandle) => {
    return {
        widevine: {
            LA_URL: constants.LS_WIDEVINE,
            headers: { 'dt-custom-data': encodeData(userHandle) },
            videoRobustness: 'SW_SECURE_CRYPTO',
            audioRobustness: 'SW_SECURE_CRYPTO',
        },
        fairplay: {
            LA_URL: constants.LS_FAIRPLAY,
            certificateURL: constants.LS_FAIRPLAY_CERT,
            headers: { 'dt-custom-data': encodeData(userHandle) },
        },
    };
};
