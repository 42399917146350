import { h } from 'preact';
import { classnames } from '@exaring/utils';

/* eslint-disable react/button-has-type */

export default ({
    className,
    action,
    disabled,
    label,
    children,
    title,
    type,
    dataClickClass,
    dataClickId,
}) => {
    const _className = classnames('button', className);
    return (
        <button
            type={type || 'button'}
            className={_className}
            onClick={action}
            disabled={disabled}
            title={title}
            data-click-class={dataClickClass}
            data-click-id={dataClickId}
        >
            {label || children}
        </button>
    );
};
