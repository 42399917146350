import { useEffect } from 'preact/hooks';

const cleanupFunction = () => {};

/**
 * A hook to manage scroll locking based on the value of `scrollLock`.
 * When `scrollLock` is true, it locks the scrolling by setting the overflow style of the HTML element to 'hidden'.
 * When `scrollLock` is false, it releases the scroll lock by resetting the overflow style of the HTML element.
 * When `scrollLock` is undefined, it defaults to true, thus enabling scroll lock.
 *
 * @param {boolean | undefined} [scrollLock=true] - A boolean indicating whether to enable or disable scroll lock.
 * @returns {void} No return value.
 */
export const useScrollLock = (scrollLock: boolean = true) => {
    useEffect(() => {
        if (!scrollLock) {
            return cleanupFunction;
        }

        const htmlElement = document.querySelector('html');
        if (!htmlElement) {
            return cleanupFunction;
        }

        const originalOverflow = htmlElement.style.overflow;
        htmlElement.style.overflow = 'hidden';

        return () => {
            htmlElement.style.overflow = originalOverflow || 'auto';
        };
    }, [scrollLock]);
};
