const ErrorTypes = {
    Network: 'network',
    General: 'general',
    Setup: 'setup',
    Source: 'source',
    Playback: 'playback',
    Drm: 'drm',
    Vr: 'vr',
    Module: 'module',
    Other: 'other',
    StreamLimitation: 'stream-limitation',
    StreamExpired: 'stream-expired',
    StillWatching: 'still-watching',
} as const;

export const PlayoutErrorTypes = {
    POLICY: 'stream-url-provider/denied-by-policy',
    MAINTENANCE: 'stream-url-provider/maintenance',
    DENIED_BY_LOCATION: 'stream-url-provider/denied-by-location',
    GENERAL_ERROR: 'stream-url-provider/general-error',
} as const;

export default ErrorTypes;
