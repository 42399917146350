/* eslint-disable no-template-curly-in-string */
import { isObject, isString, isArrayOf, isBoolean } from '@exaring/utils';
import { Program } from '@nessprim/planby';

interface EpgGridItem {
    id: string; // new program identifier ex `a9a108c8-4393-43c7-b361-701ea4a81705`
    title?: string;
    genre?: string;
    previewImage?: string; // pattern format like `https://images-dev.wpstr.tv/dmb/ard/1615961366/259375715/ard_221229_1215_40346b49_ard-buffet_generic.jpg?res=${resolution}`
    startTime?: string; // utc-0 iso string ex. `2022-12-29T12:00:00Z`
    stopTime?: string; // utc-0 iso string
    recordingForbidden?: boolean;
    seriesId?: number;
}

const isValidJson = (value: unknown): value is Array<EpgGridItem> => {
    if (isArrayOf(isObject, value) && value[0]) {
        const { id, recordingForbidden, startTime, stopTime } = value[0];
        return (
            isString(id) &&
            isString(startTime) &&
            isString(stopTime) &&
            isBoolean(recordingForbidden)
        );
    }

    return false;
};

type ChannelDetails = {
    channelUuid: string;
    channelIndex: number;
};

const mapToPlanby = (epgProgram: EpgGridItem, channelDetails: ChannelDetails): Program => {
    return {
        channelUuid: channelDetails.channelUuid,
        id: epgProgram.id,
        description: '',
        genre: epgProgram.genre || '',
        title: epgProgram.title || '',
        till: epgProgram.stopTime || '',
        since: epgProgram.startTime || '',
        image: (epgProgram.previewImage || '').replace('${resolution}', '320x180'),
        recordingForbidden: epgProgram.recordingForbidden,
        seriesId: epgProgram.seriesId,
        channelIndex: channelDetails.channelIndex,
        index: channelDetails.channelIndex,
        // Undocumented prop that seems to be only used for drag-n-drop, which is not used in our case
        // Passing dummy object to satisfy TS
        // Introduced in  https://github.com/Nessprim/planby/commit/9ed61fa570dbc9104791307fab909320597cb174
        channelPosition: {
            top: 0,
            height: 0,
        },
    };
};

export const decode = (
    value: unknown,
    channelDetails: ChannelDetails,
): Array<Program> | undefined => {
    return isValidJson(value) ? value.map((item) => mapToPlanby(item, channelDetails)) : undefined;
};
