/*
 *  Copyright (C) Exaring AG - All Rights Reserved
 */

import WaiputhekApi from '@exaring/networking/services/waiputhek';
import TunerServiceApi from '@exaring/networking/services/tuner';
import { StoreSlice } from './utils/StoreSlice';
import { decode as decodeHighlights } from '../types/api/Highlights';
import { Mediathek, decode as decodeMediathek } from '../types/api/Mediathek';
import { HighlightSection } from '../types/api/HighlightSection';

//
//  State Definition
//

export type State = {
    // State
    highlightsData: {
        state: FetchState;
        value: Array<HighlightSection>;
    };

    mediathekData: {
        state: FetchState;
        value?: Mediathek;
    };

    // Actions aka. State Transformations
    fetchHighlights: () => Promise<void>;
    fetchMediathek: (channelId: string) => Promise<void>;
};

type FetchState = 'NotAsked' | 'Loading' | 'Success' | 'Error';

//
//  Store
//

export const State: StoreSlice<State> = (set /* , get */) => ({
    // Initial State
    highlightsData: {
        state: 'NotAsked',
        value: [],
    },
    mediathekData: {
        state: 'NotAsked',
        value: undefined,
    },

    // Actions
    fetchHighlights: async () => {
        set((state) => {
            state.highlightsData = {
                state: 'Loading',
                value: [],
            };
        }, 'Waiputhek/fetchHighlights');
        const response = await WaiputhekApi.getPageHighlights();
        set((state) => {
            state.highlightsData = {
                state: 'Success',
                value: decodeHighlights(response?.data)?.modules || [],
            };
        }, 'Waiputhek/fetchHighlights');
    },
    fetchMediathek: async (channelId: string) => {
        set((state) => {
            state.mediathekData = {
                state: 'Loading',
                value: undefined,
            };
        }, 'Waiputhek/fetchMediathek');
        try {
            const response = await TunerServiceApi.mediaLibrary(channelId);
            set((state) => {
                state.mediathekData = {
                    state: 'Success',
                    value: decodeMediathek(response?.data),
                };
            }, 'Waiputhek/fetchMediathek');
        } catch (error) {
            set((state) => {
                state.mediathekData = { state: 'Error' };
            }, 'Waiputhek/fetchMediathek/error');
        }
    },
});
