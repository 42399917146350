import cookie from 'cookie';

const getCookie = (name) => {
    try {
        const cookies = cookie.parse(document.cookie);
        return cookies[name];
    } catch {
        return undefined;
    }
};

export const getWaipuDeviceId = () => getCookie('waipu_device_id');
export const getDaiConsent = () => getCookie('cmp-enable-waipu');
export const getEuConsent = () => getCookie('euconsent-v2');
