import { h } from 'preact';
import { classnames } from '@exaring/utils';

const VerificationModuleInput = ({
    handleFocus,
    handleChange,
    inputRef,
    inputKey,
    hasError,
    value,
}) => {
    return (
        <input
            className={classnames(
                'media-player-input-group__input',
                hasError && 'input--error',
                `ver-input-${inputKey}`,
            )}
            key={inputKey}
            onFocus={() => handleFocus(inputKey)}
            onChange={() => handleChange(inputKey)}
            value={value}
            ref={inputRef}
            maxLength="1"
            type="password"
        />
    );
};

export default VerificationModuleInput;
