import { isObject, isString, isArrayOf, isNumber } from '@exaring/utils';

export interface EpgGridInfo {
    slotSizeHours: number;
    timeSlots: Array<string>;
}

const isValidJson = (value: unknown): value is EpgGridInfo => {
    if (isObject(value)) {
        const { slotSizeHours, timeSlots } = value;
        return isNumber(slotSizeHours) && isArrayOf(isString, timeSlots);
    }

    return false;
};

export const decode = (value: unknown): EpgGridInfo | undefined => {
    return isValidJson(value) ? value : undefined;
};
