/**
 * Safe and fast string join function by delimiter. Type safe casting
 * from falsy to empty string. Also handles white spaces before and
 * after given params by triming.
 * @benchmark against more flexible array solution: http://jsben.ch/6yYlT
 * @param {string} left
 * @param {string} right
 * @param {string} delimiter
 * @returns {string} The destination index
 * @private
 */
export function strj(left, right, delimiter = ' ') {
    const l = `${left || ''}`.trim();
    const r = `${right || ''}`.trim();

    return l && r ? l + delimiter + r : l || r;
}

/**
 * Safe and fast string join function by delimiter. Type safe casting
 * from falsy to empty string. Also removes white spaces before and
 * after given parameters. (except for less than three parameters
 * as that means no delimiter is given and the default delimiter is
 * used [space])
 * @param {any} param1
 * @param {any} param2
 * @param {any} ...
 * @param {any} delimiter
 * @returns {string}
 * @private
 */
export const strjchain = (...params) => {
    let delimiter; // undefined -> default delimiter
    if (params.length > 2) {
        delimiter = params.pop();
    } // assume last param to be delimiter
    return params.reduce((left, right) => strj(left, right, delimiter));
};

/**
 * Joining n strings with a whitespace, applies trim on each argument.
 * @benchmark beats arrj http://jsben.ch/aiwTD
 * @param {string} n arguments
 * @returns {string} joined classname string
 */
export function classnames(...args) {
    return strjchain(...args.concat(' '));
}

export const randomString = (length = 128) => {
    let text = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    const charactersLen = characters.length;

    for (let i = 0; i < length; i += 1) {
        text += characters.charAt(Math.floor(Math.random() * charactersLen));
    }

    return text;
};

export const trimPath = (path = '') => {
    return `${path}`.replace(/\//g, ' ').trim().replace(/\s+/g, '/');
};

/**
 * Mask given text (e.g. "0123456789") with "*":
 *
 * @param {string} text text to mask
 * @param {number} unmaskedCount number of chars remining unmasked,
 *     - at beginning, if < 0 (e.g. "0123******" if -4)
 *     - or end, if > 0 (e.g. "******6789" if 4)
 * @returns {string} maked text
 */
export const maskText = (text, unmaskedCount) => {
    const absUnmaskedCount = Math.abs(unmaskedCount);
    if (!text || text.length <= absUnmaskedCount) {
        return text;
    }

    const masked = Array(text.length - absUnmaskedCount)
        .fill('*')
        .join('');
    const unmasked = text.substr(unmaskedCount > 0 ? -unmaskedCount : 0, absUnmaskedCount);

    return unmaskedCount > 0 ? masked.concat(unmasked) : unmasked.concat(masked);
};

export const isEmptyString = (val) => val === '';

export const isNumericString = (val) => !Number.isNaN(parseInt(val, 10));

export const isEmailAddress = (val) =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        val,
    );

export const isGermanZipCode = (val) => /^[0-9]{5}$/.test(val);

/**
 * Transforms given string first character to lowercase.
 * @param {string} str
 * @param {bool} abbreviation ignores any abbreviation but it must be at least 2 characters long
 * @returns {string} lower camel case string
 */
export const firstCharToLower = (str, abbreviation = false) => {
    const _str = `${str}`;
    if (abbreviation && _str.length >= 2 && _str.toUpperCase() === str) {
        return str;
    }
    return _str[0].toLowerCase() + _str.slice(1);
};

/**
 * Transforms given string to lowercase but only all uppercase string. IBAN or BIC for example.
 * @param {string} str
 * @returns {string}
 */
export const upperToLower = (str) => {
    const _str = `${str}`;
    if (_str.length >= 2 && _str.toUpperCase() === str) {
        return _str.toLowerCase();
    }
    return _str;
};

/**
 * @param {string} str replace string
 * @param {Record<string, string>} definitions replace definitions
 */
export const strReplaceDefinitions = (str, definitions) => {
    return Object.keys(definitions).reduce((previousVal, currentVal) => {
        return previousVal.replace(RegExp(`${currentVal}`, 'g'), definitions[currentVal]);
    }, str);
};

/**
 * @param {string} str
 * @returns {string | undefined}
 */
export const capitalize = (str) =>
    typeof str === 'string' && str.length > 0 ? str.charAt(0).toUpperCase() + str.slice(1) : str;

/** Check if given value is a string and provide a type guard for typescript */
export const isString = (value) => typeof value === 'string';

/** Check if given value is an array of strings and provide a
 *  type guard for typescript */
export const isStringArray = (value) =>
    Array.isArray(value) && value.filter(isString).length === value.length;

/**
 * Cleanup all none alphabetic characters
 */
export const alphabeticOnlyString = (value) => value.replace(/[^A-Za-z]/g, '');

/**
 * Generate a string from character array
 */
export const characterArrayToString = (charArray) => {
    return String.fromCharCode.apply(undefined, [...charArray]);
};

/**
 * Check if given string matches at least one of the strings in given list
 */
export const isStringOf = (value, stringList) => stringList.some((x) => x === value);

export const toLower = (str) => str.toLowerCase();
export const trim = (str) => str.trim();

let textCanvasCache;
export const getRenderedStringWidth = (text, font) => {
    if (!textCanvasCache) {
        textCanvasCache = document.createElement('canvas');
    }

    const canvas = textCanvasCache;
    const context = canvas.getContext('2d');
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
};

/**
 * To avoid confusion, this is not comparing a string length with a defined max
 * length. This is actually testing the rendered text bounding box width against
 * the defined max width and is truncating the tail of the given string.
 */
export const truncateString = (str, maxWidth, truncateGlyph = '…', font = '16px Open Sans') => {
    let truncatedString = str;
    let truncatedLength = 0;
    const sourceStringLength = str.length || 0;
    const underflowProtectedWidth = Math.max(parseInt(maxWidth, 10), 1);

    if (getRenderedStringWidth(truncatedString, font) <= underflowProtectedWidth) {
        // if string just fits in or is smaller than available space
        return str;
    }

    // calculate width including truncateGlyph
    while (
        getRenderedStringWidth(truncatedString + truncateGlyph, font) > underflowProtectedWidth
    ) {
        truncatedLength += 1;
        truncatedString = truncatedString.slice(
            0,
            Math.max(sourceStringLength - truncatedLength, 0),
        );
    }

    return `${truncatedString}${truncatedString.length > 0 ? truncateGlyph : ''}`; // only render truncate glyph when there is space for at least one glyph
};
