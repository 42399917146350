import { h, RenderableProps, Fragment } from 'preact';
import { CSS } from '@stitches/react';
import ArrowUp from '@exaring/assets/icons/arrow-up.svg';
import Sort from '@exaring/assets/icons/sort.svg';
import FilterIcon from '@exaring/assets/icons/filter.svg';
import { styled } from '../theme';

const StyledDiv = styled('div', {
    display: 'block',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: '$dark',
    padding: '6px 12px',
    pointerEvents: 'none',

    '&:nth-child(2)': {
        right: '32px',
    },
});

const StyledContainer = styled('div', {
    borderRadius: '4px',
    overflow: 'hidden',
    fontSize: '$2',
    backgroundColor: '$dark',
    padding: '6px 12px',
    '& select': {
        padding: 'unset',
    },
    position: 'relative',

    '&:hover': {
        backgroundColor: '$charcoalGrey',

        [`${StyledDiv}`]: {
            backgroundColor: '$charcoalGrey',
        },
    },

    variants: {
        disabled: {
            true: {
                backgroundColor: '$dark',
                opacity: '.2',
                pointerEvents: 'none',
            },
        },
    },
});

const StyledSelect = styled('select', {
    height: '100%',
    width: 'max(100%, 207px)',
    minWidth: '207px',
    paddingLeft: '12px',
    paddingRight: '42px', // right arrow
    cursor: 'pointer',
    appearance: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:disabled': {
        color: '$white',
        cursor: 'inherit',
        opacity: 1,
    },
    '&:disabled > option': {
        color: '$white',
    },
});

const StyledArrowIcon = styled(ArrowUp, {
    width: '46px',
    height: '46px',
    position: 'absolute',
    right: '-8px',
    top: '3px',
    pointerEvents: 'none',
    fill: '$white',
});

export const StyledSortIcon = styled(Sort, {
    width: '32px',
    height: '32px',
    position: 'absolute',
    right: '3px',
    top: '3px',
    pointerEvents: 'none',
    fill: '$white',
});

export const StyledFilterIcon = styled(FilterIcon, {
    width: '32px',
    height: '32px',
    position: 'absolute',
    right: '3px',
    top: '3px',
    pointerEvents: 'none',
    fill: '$white',
});

export interface SelectOption {
    label: string;
    value: unknown;
    addSeparatorAfter?: boolean;
    disabled?: boolean;
}

export const Dropdown = <T extends string | number | string[]>({
    label,
    css,
    options,
    selected,
    onChange,
    icon = <StyledArrowIcon />,
    disabled = false,
}: RenderableProps<{
    label?: string;
    css?: CSS;
    options: readonly {
        label: string;
        value: T;
        addSeparatorAfter?: boolean;
        disabled?: boolean;
    }[];
    selected?: T;
    onChange: (value: string) => void;
    icon?: JSX.Element;
    disabled?: boolean;
}>) => {
    const handleChange = (event: any) => {
        if (disabled) {
            event.stopPropagation();
        }
        onChange(event.currentTarget.value);
    };

    return (
        <StyledContainer disabled={disabled} css={css}>
            {icon}
            {label && <StyledDiv>{label}</StyledDiv>}
            <StyledSelect disabled={disabled} onChange={handleChange} value={selected}>
                {options.map((item) => (
                    <>
                        <option key={item.value} value={item.value} disabled={item.disabled}>
                            {item.label}
                        </option>
                        {item.addSeparatorAfter && <option disabled>────</option>}
                    </>
                ))}
            </StyledSelect>
        </StyledContainer>
    );
};
