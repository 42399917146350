import { Fragment, FunctionalComponent, h } from 'preact';
import { useEffect } from 'preact/compat';
import { route } from 'preact-router';

interface RedirectProps {
    to: string;
}

const Redirect: FunctionalComponent<RedirectProps> = ({ to }) => {
    useEffect(() => {
        route(to);
    }, [to]);

    return <Fragment />;
};

export default Redirect;

// based on this post: https://github.com/preactjs/preact-router/pull/201
