/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, FunctionComponent, JSX } from 'preact';

import { strj, epgDetailsDateFormat, epgDetailsLiveDateFormat } from '@exaring/utils';
import { isOnAir, isTVFuse } from '@exaring/utils/data/program';

import { LayoutType, Program } from '../../ProgramDetailPage/types';
import { styled } from '../../theme';
import { Typography } from '../../Typography';
import { PillText } from '../../Pill';

const Heading = styled(Typography, {
    opacity: 0.9,
    color: '$verylightgray',
});

const Title = styled(Heading, {
    gridArea: 'headline',
    marginTop: '14px',
    paddingLeft: '2px',

    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',

    color: '$white',
    fontSize: '$5',
    lineHeight: 'normal',
    maxHeight: '2.7em',
    variants: {
        layout: {
            modal: {},
            player: {
                marginTop: '92px',
            },
            mediathek: {},
            microSite: {},
            epg: {},
        },
    },
});

const EpisodeTitle = styled(Heading, {
    gridArea: 'subheadline',
    marginTop: '$7',
    fontSize: 20,
    lineHeight: 1.8,
});

const MoreInfo = styled(Typography, {
    gridArea: 'infos',
    fontSize: '$1',
    lineHeight: 1.69,
    color: '$mediumGray',
    margin: '$7 0 $2 !important',
});

const PillInfo = styled(PillText, {
    gridArea: 'addtional',
    marginTop: '$7',
});

const Time = styled(Typography, {
    gridArea: 'time',
    fontSize: '16px',
    lineHeight: 1.69,
    margin: '$1 0 0',
    color: '$lightBlue',
});

export const TeaserLayout = styled('section', {
    display: 'grid',
    grid: `
        "headline"
        "subheadline"
        "addtional"
        "time"
        "infos"
    `,
    alignItems: 'center',
    variants: {
        layout: {
            modal: {
                marginTop: '14px',
            },
            epg: {},
            mediathek: {},
            microSite: {},
            player: {},
        },
    },
});

type Descriptor = {
    id: keyof Pick<
        Program,
        | 'genreDisplayName'
        | 'country'
        | 'year'
        | 'season'
        | 'episode'
        | 'duration'
        | 'parentalGuidance'
    >;
    label?: string;
    unit?: string;
    mapper?: (val: unknown) => string;
};

const programInfoDescriptors: Descriptor[] = [
    {
        id: 'genreDisplayName',
    },
    {
        id: 'country',
    },
    {
        id: 'year',
    },
    {
        id: 'season',
        label: 'Staffel',
    },
    {
        id: 'episode',
        label: 'Episode',
    },
    {
        id: 'parentalGuidance',
        label: 'FSK',
        mapper: (val: unknown) => {
            const value = val as string;
            // very simple type guard
            if (value.includes('fsk')) {
                const fskAge = value.replace('fsk-', ''); // receive pure number value
                return parseInt(fskAge, 10) > 0 ? fskAge : '';
            }

            return '';
        },
    },
];

// @todo move to program helper
export const programDetailsShort = (program: Program): string => {
    const descriptors = programInfoDescriptors.filter(({ id }) => program[id]);

    const programInfoItems = descriptors.map((descriptor) => {
        const value = descriptor.mapper
            ? descriptor.mapper?.(program[descriptor.id])
            : program[descriptor.id];

        return value ? strj(strj(descriptor.label, value, ' '), descriptor.unit, ' ') : false; // include data with unit only if value is set
    });

    return programInfoItems.filter(Boolean).join(' ⋅ ');
};

export const ProgramTeaserInformations: FunctionComponent<{
    layout?: LayoutType;
    title?: JSX.Element | string;
    episodeTitle?: JSX.Element | string;
    pillInfos?: JSX.Element | string;
    timeInfos?: JSX.Element | string;
    moreInfos?: JSX.Element | string;
    hasAirDate?: boolean;
}> = ({
    layout,
    title = '',
    episodeTitle = '',
    pillInfos = '',
    timeInfos = '',
    moreInfos = '',
    hasAirDate = false,
}) => {
    return (
        <TeaserLayout layout={layout}>
            <Title tagName="h2" nogutter layout={layout}>
                {title}
            </Title>
            {episodeTitle && (
                <EpisodeTitle tagName="h3" nowrap nogutter>
                    {episodeTitle}
                </EpisodeTitle>
            )}
            {pillInfos && <PillInfo>{pillInfos}</PillInfo>}
            {hasAirDate && (
                <Time variant="body2" nogutter>
                    {timeInfos}
                </Time>
            )}
            <MoreInfo variant="body2">{moreInfos}</MoreInfo>
        </TeaserLayout>
    );
};

export const ProgramTeaser: FunctionComponent<{
    program: Program;
    layout?: LayoutType;
}> = ({ program, layout }) => (
    <ProgramTeaserInformations
        title={program.title}
        layout={layout}
        episodeTitle={program.episodeTitle || ''}
        moreInfos={programDetailsShort(program)}
        timeInfos={
            isOnAir(program)
                ? epgDetailsLiveDateFormat(program.startTime, program.stopTime)
                : epgDetailsDateFormat(program.startTime, program.duration)
        }
        hasAirDate={!isTVFuse(program)}
    />
);
