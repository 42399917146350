import { FunctionComponent, h } from 'preact';
import FavoriteActiveIcon from '@exaring/assets/icons/favorite-active.svg';
import FavoriteInactiveIcon from '@exaring/assets/icons/favorite-inactive.svg';
import EyeVisibleIcon from '@exaring/assets/icons/eye-on.svg';
import EyeHiddenIcon from '@exaring/assets/icons/eye-off.svg';
import LockIcon from '@exaring/assets/icons/lock.svg';
import { SortNumber } from './SortNumber';
import {
    ChannelIconWrapper,
    ChannelLogo,
    ChannelName,
    DragIconStyled,
    IconButon,
    LockIconWrapper,
    SortItemContent,
    StyledSortItem,
} from './SortItem.styles';
import { getDropLine, getLogo } from './SortItem.utils';

import {
    SortItemProps as ImportedSortItemProps,
    SortItemChannel as ImportedSortItemChannel,
} from './SortItem.types';

export type SortItemProps = ImportedSortItemProps;
export type SortItemChannel = ImportedSortItemChannel;

const noop = () => {};

export const SortItem: FunctionComponent<SortItemProps> = ({
    sortNumber,
    showDropLine,
    showBorder = false,
    channel,
    onToggleFavorite,
    onToggleVisible,
    onSortNumberChange,
    view = 'grid',
    disableEditing = false,
}) => {
    const favIcon = channel.favorite ? (
        <FavoriteActiveIcon data-testid="icon-favorite" />
    ) : (
        <FavoriteInactiveIcon data-testid="icon-not-favorite" />
    );
    const visIcon = channel.visible ? (
        <EyeVisibleIcon data-testid="icon-visible" />
    ) : (
        <EyeHiddenIcon data-testid="icon-hidden" />
    );

    const columns =
        view === 'grid'
            ? {
                  xs: undefined,
                  sm: 3,
                  md: 2,
                  lg: undefined,
                  xl: undefined,
              }
            : ({
                  xs: 'full',
                  sm: 'full',
                  md: 'full',
                  lg: 'full',
                  xl: 'full',
              } as const);

    return (
        <StyledSortItem
            xs={columns.xs}
            sm={columns.sm}
            md={columns.md}
            lg={columns.lg}
            xl={columns.xl}
            border={showBorder}
            dropLine={getDropLine(view, showDropLine)}
            hidden={!channel.visible || channel.locked}
        >
            <SortItemContent>
                <SortNumber
                    value={sortNumber}
                    onChange={onSortNumberChange || noop}
                    editable={!disableEditing && channel.visible}
                />
                <ChannelIconWrapper>
                    <LockIconWrapper visible={channel.locked}>
                        <LockIcon />
                    </LockIconWrapper>
                    <ChannelLogo src={getLogo(channel)} />
                </ChannelIconWrapper>
                <ChannelName>{channel.displayName}</ChannelName>

                {onToggleFavorite !== undefined && (
                    <IconButon
                        active={channel.favorite}
                        onClick={() => {
                            onToggleFavorite(channel.id);
                        }}
                    >
                        {favIcon}
                    </IconButon>
                )}
                <IconButon
                    onClick={() => {
                        onToggleVisible(channel.id, !channel.visible);
                    }}
                >
                    {visIcon}
                </IconButon>
                {onSortNumberChange !== undefined && (
                    <DragIconStyled viewBoxWidth="24" viewBoxHeight="24" />
                )}
            </SortItemContent>
        </StyledSortItem>
    );
};
