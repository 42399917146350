import { h } from 'preact';
import { styled } from '@exaring/ui/components-styled/theme';
import { IconButton } from '@exaring/ui/components-styled/IconButton';
import { Typography } from '@exaring/ui/components-styled/Typography';
import { GridContainer, GridItem } from '@exaring/ui/components-styled/Grid';
import AllChannelIcon from '@exaring/assets/icons/all-channels-toggle-off.svg';
import ChannelSortIcon from '@exaring/assets/icons/channel-sort-toggle.svg';
import FavoriteInactiveIcon from '@exaring/assets/icons/favorite-inactive.svg';
import { Routes, routeTo } from '../../routes';
import { useEpgStore } from '../../state/Store';

const StyledContainer = styled('div', {
    position: 'absolute',
    top: '60px',
    left: 0,
    bottom: 0,
    width: '100%',
    minHeight: '210px',
    paddingRight: '$$marginLeft',
    backgroundImage: 'linear-gradient(to bottom, rgba(9, 7, 20, 0), rgba(9, 7, 20, 0.9) 50%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
});

const StyledIconButton = styled(IconButton, {
    paddingLeft: '18px',
    paddingRight: '18px',

    '> p': {
        fontSize: '$2.5',
    },
});

const StyledTitleTypography = styled(Typography, {
    fontWeight: '$3',
    fontSize: '20px',
    marginTop: '10px',
    marginBottom: '10px',

    '@xl': {
        fontSize: '$4',
        marginTop: '20px',
        marginBottom: '20px',
    },
});

const StyledDescriptionTypography = styled(Typography, {
    '@xl': {
        marginBottom: '30px',
    },
});

const StyledSpan = styled('span', {
    color: '$blue',
});

const routeToChannelConfigPage = () => routeTo(Routes.CHANNELCONFIG_PATH);

export const EmptyFavourites = () => {
    const { toggleFavorites } = useEpgStore();

    return (
        <StyledContainer>
            <FavoriteInactiveIcon width="48px" height="48px" />
            <StyledTitleTypography>
                Es wurden noch keine Favoriten hinzugefügt.
            </StyledTitleTypography>
            <StyledDescriptionTypography centered>
                Markieren Sie unter „<StyledSpan>Alle Sender</StyledSpan>“ oder in der „
                <StyledSpan>Sendersortierung</StyledSpan>“ Ihre Lieblingssender
                <br />
                und stellen Sie sich so Ihre persönliche Favoritenliste zusammen.
            </StyledDescriptionTypography>
            <GridContainer
                direction="row"
                justifyContent="center"
                css={{ flexWrap: 'nowrap', gap: '20px', width: '100%' }}
            >
                <GridItem xs={6} sm={4} md={3} xl={1.5}>
                    <StyledIconButton
                        size="parent"
                        icon={<AllChannelIcon />}
                        onClick={toggleFavorites}
                    >
                        Alle Sender
                    </StyledIconButton>
                </GridItem>
                <GridItem xs={6} sm={4} md={3} xl={1.5}>
                    <StyledIconButton
                        size="parent"
                        icon={<ChannelSortIcon />}
                        onClick={routeToChannelConfigPage}
                    >
                        Sendersortierung
                    </StyledIconButton>
                </GridItem>
            </GridContainer>
        </StyledContainer>
    );
};
