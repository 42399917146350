/*
 *  Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, FunctionComponent } from 'preact';
import { VariantProps } from '@stitches/react';
import DeleteIcon from '@exaring/assets/icons/recording-delete.svg';
import ArrowIcon from '@exaring/assets/icons/arrow-back.svg';
import CircleUncheckedIcon from '@exaring/assets/icons/circle-unchecked.svg';
import CircleCheckedIcon from '@exaring/assets/icons/circle-checked.svg';
import { CSS, Colors, styled } from '../../theme';

const defaultSize = {
    width: '32px',
    height: '32px',
};

export const TileMenu = styled('div', {
    position: 'relative',
    textAlign: 'center',

    variants: {
        visible: {
            true: {
                visibility: 'visible',
            },
        },
    },
});

const iconStyles = {
    ...defaultSize,
    variants: {
        size: {
            default: defaultSize,
            medium: { width: '32px', height: '32px' },
            large: { width: '64px', height: '64px' },
        },
    },
};

export const Trashcan = styled(DeleteIcon, {
    ...iconStyles,

    variants: {
        hover: {
            true: {
                '&:hover': {
                    fill: Colors.aquamarine,
                },
            },
        },
    },
});

export const CircleChecked = styled(CircleCheckedIcon, iconStyles);
export const CircleUnchecked = styled(CircleUncheckedIcon, iconStyles);

export const MoreArrow = styled(ArrowIcon, {
    transform: 'rotate(180deg)',
    ...defaultSize,
    variants: {
        size: {
            default: defaultSize,
            medium: { width: '32px', height: '32px' },
            large: { width: '64px', height: '64px' },
        },
    },
});

export type StyledButtonVariants = VariantProps<typeof MoreArrow>;
type MenuProps = VariantProps<typeof TileMenu>;

export const CircleIcon: FunctionComponent<{
    css?: CSS;
    className?: string;
    iconSize?: StyledButtonVariants['size'];
    onClick?: (event: MouseEvent) => void;
    checked?: boolean;
}> = ({ onClick, css, className, iconSize, checked }) => {
    const Component = checked ? CircleChecked : CircleUnchecked;
    return (
        <Component
            css={css}
            className={className}
            onClick={onClick}
            width="100%"
            height="100%"
            size={iconSize}
            color={checked ? Colors.aquamarine : Colors.white}
        />
    );
};

export const TrashcanIcon: FunctionComponent<{
    css?: CSS;
    className?: string;
    iconSize?: StyledButtonVariants['size'];
    onClick?: (event: MouseEvent) => void;
    hover?: boolean;
}> = ({ onClick, css, className, iconSize, hover }) => (
    <Trashcan
        css={css}
        className={className}
        onClick={onClick}
        width="100%"
        height="100%"
        size={iconSize}
        color={Colors.white}
        hover={hover}
    />
);

export const StyledMenu: FunctionComponent<
    {
        css?: CSS;
        className?: string;
        locked?: boolean;
        onClick?: () => void;
        visible?: boolean;
        hidePlayOverlay?: boolean;
    } & MenuProps
> = ({ onClick, css, className, children, ...props }) => {
    return (
        <TileMenu {...props} onClick={onClick} css={css} className={className}>
            {children}
        </TileMenu>
    );
};
