import { RefObject } from 'preact';
import { useEffect, useState, useRef, MutableRef, useCallback } from 'preact/hooks';
import { useDebounce } from '../useDebounce';

type UseScrollViewport = [RefObject<HTMLElement>, number, number, number, number, number, number];

export const useScrollViewport = (
    ref?: MutableRef<HTMLDivElement>,
    debounceDelay?: number,
): UseScrollViewport => {
    const domRef = useRef<HTMLElement>(ref?.current || null);
    const [scrollX, setScrollX] = useState<number>(0);
    const [scrollY, setScrollY] = useState<number>(0);
    const [width, setWidth] = useState<number>(0);
    const [height, setHeight] = useState<number>(0);
    const [scrollWidth, setScrollWidth] = useState<number>(0);
    const [scrollHeight, setScrollHeight] = useState<number>(0);

    const onScrollCb = useCallback(() => {
        setScrollX(Math.floor(domRef.current?.scrollLeft || 0));
        setScrollY(Math.floor(domRef.current?.scrollTop || 0));
        setWidth(Math.floor(domRef.current?.clientWidth || 0));
        setHeight(Math.floor(domRef.current?.clientHeight || 0));
        setScrollWidth(Math.floor(domRef.current?.scrollWidth || 0));
        setScrollHeight(Math.floor(domRef.current?.scrollHeight || 0));
    }, [setScrollX, setScrollY, setWidth, setHeight, setScrollWidth, setScrollHeight]);

    const onScroll = useCallback(useDebounce(onScrollCb, debounceDelay), [onScrollCb]);

    useEffect(() => {
        const node = domRef.current;

        if (node) {
            node.addEventListener('scroll', onScroll);
            window.addEventListener('resize', onScroll);

            return () => {
                node.removeEventListener('scroll', onScroll);
                window.removeEventListener('resize', onScroll);
            };
        }

        return undefined;
    }, [onScroll, domRef]);

    useEffect(onScroll, [onScroll]); // init

    return [domRef, scrollX, scrollY, width, height, scrollWidth, scrollHeight];
};
