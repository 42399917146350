/*
 *  Copyright (C) Exaring AG - All Rights Reserved
 */
import ageVerificationService from '@exaring/networking/services/age-verification';
import { diffInSeconds, jwtDecode, logError, strj, unix } from '@exaring/utils';
import {
    isDunningLevel2,
    isFreeUser,
    isTrackingForbidden,
    isUserConfirmed,
    isInstantRestartAllowed,
} from '@exaring/utils/data/jwt';
import sup from '@exaring/networking/services/stream-url-provider';
import { jwtSchema } from '@exaring/utils/data/schema/jwt.zod';
import { StoreSlice } from './utils/StoreSlice';

export enum CDRequestEnum {
    NOT_ASKED = 'NOT_ASKED',
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

export type State = {
    firstName: string;
    lastName: string;
    fullName: string;
    gender: string;
    emailAddress: string;
    userHandle?: string;
    token?: string;
    refreshToken?: string;
    tokenTTL?: number;

    deviceToken?: string;
    deviceTokenTTL?: number;
    cdRequestState: CDRequestEnum;
    lastRequest: number;

    isDunningLevel2: boolean;
    isUserConfirmed: boolean; // email address is confirmed
    isFreeUser: boolean;
    isTrackingForbidden: boolean;
    isInstantRestartAllowed: boolean;

    ageVerificationLoading: boolean;
    verifyPinLoading: boolean;
    allowedFSK: undefined;
    pinEnabled: boolean;

    checkForUserAgeVerification: () => Promise<void>;

    setStateWithJwt: (jwt: string, refreshToken: string) => void;

    setDeviceCapabilities: (
        appVersion: string,
        manufacturer?: string,
        platform?: string,
        model?: string,
        type?: 'web' | 'tv' | 'tablet' | 'mobile' | 'receiver',
    ) => Promise<void>;

    setDeviceToken: (token: string, tokenTTL: string | number | Date) => void;
};

export const initialDeviceTokenStates: Pick<
    State,
    'deviceToken' | 'deviceTokenTTL' | 'cdRequestState' | 'lastRequest'
> = {
    deviceToken: undefined,
    deviceTokenTTL: undefined,
    cdRequestState: CDRequestEnum.NOT_ASKED,
    lastRequest: 0,
};

export const initialState = {
    firstName: '',
    lastName: '',
    fullName: '',
    gender: '',
    emailAddress: '',
    userHandle: undefined,
    token: undefined,
    tokenTTL: undefined,

    ...initialDeviceTokenStates,

    isDunningLevel2: false,
    isUserConfirmed: false, // email address is confirmed
    isFreeUser: false,
    isTrackingForbidden: false,
    isInstantRestartAllowed: false,

    ageVerificationLoading: false,
    verifyPinLoading: false,
    allowedFSK: undefined,
    pinEnabled: false,
};

export const State: StoreSlice<State> = (set, get) => ({
    ...initialState,

    checkForUserAgeVerification: async () => {
        set((state) => {
            state.ageVerificationLoading = true;
        }, 'User/checkForUserAgeVerification');
        try {
            const {
                data: { allowedFSK, enabled },
            } = await ageVerificationService.verification();
            set((state) => {
                state.allowedFSK = allowedFSK;
                state.pinEnabled = enabled;
            }, 'User/checkForUserAgeVerification');
        } catch (e) {
            set((state) => {
                state.allowedFSK = undefined;
            }, 'User/checkForUserAgeVerification');
        } finally {
            set((state) => {
                state.ageVerificationLoading = false;
            }, 'User/checkForUserAgeVerification');
        }
    },

    setStateWithJwt: (jwt, refreshToken) => {
        const token = jwtSchema.passthrough().parse(jwtDecode(jwt));

        set((state) => {
            state.firstName = token.firstName;
            state.lastName = token.lastName;
            state.fullName = strj(token.firstName, token.lastName, ' ');
            state.gender = token.gender;
            state.emailAddress = token.sub;
            state.userHandle = token.userHandle;
            state.token = jwt;
            state.refreshToken = refreshToken;
            state.tokenTTL = token.exp;
            state.isDunningLevel2 = isDunningLevel2(token);
            state.isFreeUser = isFreeUser(token);
            state.isTrackingForbidden = isTrackingForbidden(token);
            state.isInstantRestartAllowed = isInstantRestartAllowed(token);
            state.isUserConfirmed = isUserConfirmed(token);
        }, 'User/setStateWithJwt');
    },

    setDeviceCapabilities: async (appVersion, manufacturer, platform, model, type = 'web') => {
        try {
            const { lastRequest, cdRequestState } = get();
            const TTLMinThreshold = 60 * 5;

            if (
                /* eslint-disable no-bitwise */
                cdRequestState !== CDRequestEnum.LOADING &&
                diffInSeconds(lastRequest, unix()) > TTLMinThreshold
            ) {
                set((state) => {
                    state.cdRequestState = CDRequestEnum.LOADING;
                }, 'User/setDeviceCapabilities');

                const dc = await sup.deviceCapabilities({
                    type,
                    model: model || '',
                    manufacturer: manufacturer || '',
                    platform: platform || '',
                    appVersion: appVersion || '',
                });

                const { token, expiresAt } = dc.data;
                set((state) => {
                    state.cdRequestState = CDRequestEnum.SUCCESS;
                }, 'User/setDeviceCapabilities');

                get().setDeviceToken(token, expiresAt);
            }
        } catch (e: any) {
            logError(e);

            set((state) => {
                state.deviceToken = initialDeviceTokenStates.deviceToken;
                state.deviceTokenTTL = initialDeviceTokenStates.deviceTokenTTL;
                state.cdRequestState = initialDeviceTokenStates.cdRequestState;
                state.lastRequest = initialDeviceTokenStates.lastRequest;

                state.cdRequestState = CDRequestEnum.ERROR;
            }, 'User/setDeviceCapabilities');
        }
    },

    setDeviceToken: (token, tokenTTL = 0) => {
        set((state) => {
            state.deviceToken = token;
            state.deviceTokenTTL = unix(tokenTTL);
            state.lastRequest = unix();
        }, 'User/setDeviceToken');
    },
});
