import epg2Service from '@exaring/networking/services/epg2';
import { isProgramDetails, ProgramDetails } from '@exaring/networking/types/ProgramDetails';
import { DeferredRemoteData, useRemoteData } from '../../../hooks/useRemoteData';
import { notAsked } from '../../../state/utils/RemoteData';

export const useProgramDetails = (programId?: string): DeferredRemoteData<ProgramDetails> => {
    const result = useRemoteData(
        ({ programId: id }) => {
            return epg2Service.details(id);
        },
        isProgramDetails,
        { programId },
    );

    return programId ? result : { data: notAsked() };
};
