/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { isNil, isEmptyString } from '@exaring/utils';

interface WaiputhekFocus {
    // row
    sectionId: string;

    // column
    contentId?: string;
    channelId?: string;
    categoryId?: string;
}

interface WaiputhekBaseContext {
    focus?: WaiputhekFocus;
}

interface WaiputhekContentContext extends WaiputhekBaseContext {
    type: 'content';
    contentId: string;
    channelId?: string;
}

interface WaiputhekChannelContext extends WaiputhekBaseContext {
    type: 'channel';
    channelId: string;
}

interface WaiputhekHighlightsContext extends WaiputhekBaseContext {
    type: 'highlights';
}

export type WaiputhekContext =
    | WaiputhekContentContext
    | WaiputhekChannelContext
    | WaiputhekHighlightsContext;

export const isContent = (value: WaiputhekContext): value is WaiputhekContentContext =>
    value.type === 'content' && !isNil(value.contentId) && !isEmptyString(value.contentId.trim());

export const isChannel = (value: WaiputhekContext): value is WaiputhekChannelContext =>
    value.type === 'channel' && !isNil(value.channelId) && !isEmptyString(value.channelId.trim());
