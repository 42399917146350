import { logError } from '@exaring/utils';
import { z } from 'zod';

const protocolSchema = z.enum(['HLS', 'MPEG_DASH']);

const streamsSchema = z.object({
    protocol: protocolSchema,
    href: z.string(),
});

const streamingDetailsSchema = z.object({
    sharpStartTime: z.string(),
    recordingStopTime: z.string(),
    streamPosition: z.number(),
    streams: z.array(streamsSchema),
});

// zod infers types from schema, so no need to write separate type definitions
export type StreamingDetails = z.infer<typeof streamingDetailsSchema>;

const isValidStreamingDetailsJson = (value: unknown): value is StreamingDetails => {
    try {
        // we could also use safeParse if we don't want zod to throw errors
        // https://zod.dev/?id=safeparse
        streamingDetailsSchema.parse(value);
        return true;
    } catch (e: any) {
        logError(e);
        return false;
    }
};

export const decodeStreamingDetails = (value: unknown): StreamingDetails | undefined => {
    return isValidStreamingDetailsJson(value) ? value : undefined;
};
