/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */
/* eslint-disable no-restricted-syntax */

import { GA, unix } from '@exaring/utils';
import visibility from 'visibilityjs';
// eslint-disable-next-line import/no-cycle
import constants from './constants';
import { userStore } from './state/Store';

const WebClientGAEvent = {
    // Keep in sync with web-client-ga.d.ts
    Login: 'login',
    Logout: 'logout',
    BrandClick: 'brandClick',
    Exit: 'screen_exit',
    TabInactive: 'tabInactive',
    TabActive: 'tabActive',
    AppBlockerScreen: 'appBlockerScreen',

    // player low storage hint
    PlayerStorageHintDisplay: 'displayPlayerStorageHint',
    PlayerStorageHintManualHide: 'manuallyHidePlayerStorageHint',
    PlayerStorageHintAutoHide: 'autoHidePlayerStorageHint',
    PlayerStorageHintGoToRecordings: 'playerStorageHintRedirectRecordings',
    PlayerStorageHintOptOut: 'playerStorageHintUserOptout',

    // channel-list
    OsdChannelListZapping: 'osdChannelListZapping',

    // zapping
    Zapping: 'zapping',
    Mute: 'mute',
    UnMute: 'unMute',
    EnterScreen: 'enterScreen',
    EnterFullscreen: 'enterFullscreen',
    Play: 'play',
    Pause: 'pause',
    QuickZapperEmptyFavoriteAdd: 'quickZapperEmptyFavoriteAdd',

    // recording
    Recordings: 'recordings',
    RecordingPlay: 'recordingPlay',
    Schedule: 'schedule',
    Unschedule: 'unschedule',
    ScheduleSerials: 'scheduleSerials',
    UnscheduleSerials: 'unscheduleSerials',
    StopRecording: 'stop_recording',
    ScheduleRequestByFreeUser: 'scheduleRequestByFreeUser',
    DeleteSelectedRecordings: 'delete_selected_recordings',
    EditRecordings: 'edit_recordings',
    DeleteRecording: 'delete_recording',
    ExitEditRecordings: 'exit_edit_recordings',
    HideStorageHint: 'hide_storage_hint',
    NavigateSeasons: 'navigate_seasons',
    NavigateEpisodesnext: 'navigate_episodes_next',
    NavigateEpisodesPrevious: 'navigate_episodes_previous',
    SelectSortOrder: 'select_sort_order',
    SelectFilter: 'select_filter',
    ScheduleRecording: 'schedule_recording',
    ScheduleSerialRecording: 'schedule_serial_recording',
    MarkAllEpisodes: 'mark_all_episodes',
    UnmarkAllEpisodes: 'unmark_all_episodes',
    MarkAllRecordings: 'mark_all_recordings',
    UnmarkAllRecordings: 'unmark_all_recordings',
    MarkRecording: 'mark_recording',
    UnmarkRecording: 'unmark_recording',
    SelectRecording: 'select_recording',
    SelectStageRecording: 'select_stage_recording',
    StopSerialRecording: 'stop_serial_recording',

    // tvfuse
    TVFuseAutoplay: 'tvFuseAutoplay',

    // search
    SearchOpened: 'searchOpened',
    SearchClosed: 'cancel_search',
    SearchClickChannel: 'select_channel',
    SearchClickProgram: 'select_program',

    // Timeshift
    TimeshiftInstantRestart: 'timeshiftInstantRestart',
    TimeshiftExitRevoke: 'timeshiftExitRevoke',

    // DOI
    DOIBlockerScreen: 'doiBlockerScreen',
    DOIResendEmail: 'doiResendEmail',
    DOIChangeEmail: 'doiChangeEmail',

    // epg
    EPG: 'epg',
    EpgEmptyFavoriteAdd: 'epgEmptyFavoriteAdd',
    DisplayAllChannels: 'display_all_channels',
    DisplayFavourites: 'display_favourites',
    GoToChannel: 'go_to_channel',
    OpenMediaLibrary: 'open_media_library',
    SelectProgram: 'select_program',
    SelectHighlightVideo: 'select_highlight_video',
    SelectTime: 'select_time',
    SelectDate: 'select_date',

    // waiputhek
    WaiputhekSelectHighlightCollection: 'select_highlight_collection',
    WaiputhekSelectHighlightVideo: 'select_highlight_video',

    // channel-sort
    CancelResetDialog: 'cancelResetDialog',
    AddChannel: 'add_channel',
    MarkFavourite: 'mark_favourite',
    MoveChannel: 'move_channel',
    OpenChannelSort: 'open_channel_sort',
    RemoveChannel: 'remove_channel',
    ResetChannels: 'reset_channels',
    RevokeChannelReset: 'revoke_channel_reset',
    UnmarkFavourite: 'unmark_favourite',
    // @see channel-sort store in web-commons for shared interaction tracking

    // channel-navigation
    ChannelSort: 'channel_sort',
    ChannelNavigationFavorites: 'channelNavigationFavorites',
    ChannelNavigationAll: 'channelNavigationAll',
    ChannelNavigationChannelSort: 'channelNavigationChannelSort',

    // Parental control
    ChildProtection: 'child_protection',
    ParentalControlBlocker: 'parentalControlBlocker',
    ParentalControlPinVerification: 'parentalControlPinVerification',
    PinVerificationSuccessful: 'pin_verification_successful',
    PinVerificationUnsuccessful: 'pin_verification_unsuccessful',
    ParentalControlPinCreate: 'parentalControlPinCreate',
    ParentalControlPinForgotton: 'parentalControlPinForgotton',

    // Data privacy
    TrackingAuthorized: 'tracking_authorized',

    // Menu
    Menu: 'menu',
    LiveTV: 'livetv',
    Epg: 'epg',
    Search: 'search',
    Waiputhek: 'waiputhek',

    // Player
    PlayerControls: 'player_controls',
    ToggleFullScreen: 'toggle_fullscreen',
    JumpToLive: 'jump_to_live',
    ToggleMuted: 'toggle_muted',
    Restart: 'restart',
    ChangeLanguageSettings: 'change_language_settings',
    SelectAudioTrack: 'select_audio_track',
    SelectSubtitles: 'select_subtitles',
    ScreenView: 'screen_view',

    AppSettings: 'app_settings',
    Funnel: 'funnel',
    ScrollDepth: 'scroll_depth',

    // Quickzapper
    QuickZapper: 'quickzapper',
};

// ToDo: the custom parameter in this function is useless and should be incorporated into the main argument object
const trackEvent = (
    {
        eventName,
        eventDescription,
        channelName,
        screenName,
        programId,
        programTitle,
        searchQuery,
        playerType,
    },
    custom = {},
) => {
    const baseLoad = {
        program_id: programId || null,
        channel_name: channelName || null,
        program_title: programTitle || null,
        search_query: searchQuery || null,
        player_type: playerType || null,
        screen_name: screenName || null,
    };
    const customLoad = {
        event_details: custom?.event_details || null,
        section_id: custom?.section_id || null,
        section_position: custom?.section_position || null,
    };
    GA.track({
        ...baseLoad,
        ...customLoad,
        event: eventName || null,
        event_description: eventDescription || null,
    });
};

const dynbaseLoad = () => {
    const { userHandle } = userStore();
    return {
        app_version: constants.RELEASE,
        network_connection: 'wlan',
        device_platform: 'Web Player',
        tenant: constants.TENANT,
        user_agent: window.navigator.userAgent,
        user_id: userHandle,
        timestamp: unix(),
    };
};

let _bridgeSingleton = null;

const initRuntimeTracking = (_tracker) => {
    GA.init(constants.GA_ID);
    GA.setBaseLoadCb(dynbaseLoad);

    if (visibility.isSupported()) {
        visibility.change((_, state) => {
            _tracker.trackEvent({
                eventName:
                    state === 'visible' ? WebClientGAEvent.TabActive : WebClientGAEvent.TabInactive,
            });
        });
    }

    window.addEventListener('beforeunload', () => {
        _tracker.trackEvent({
            eventName: WebClientGAEvent.Exit,
        });
    });
};

const WebClientGA = () => {
    if (_bridgeSingleton) {
        return _bridgeSingleton;
    }

    _bridgeSingleton = { trackEvent, disable: GA.disable, enable: GA.enable };

    initRuntimeTracking(_bridgeSingleton);

    return _bridgeSingleton;
};

export { WebClientGA, WebClientGAEvent };
