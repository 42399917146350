/*
 *  Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, FunctionComponent } from 'preact';
import { useEffect } from 'preact/hooks';
import { connect } from 'unistore/preact';
import { RecommendationContents } from '@exaring/ui/components-styled/types/Recommendation';
import { getMicroSiteIdFromStation } from '@exaring/utils/data/channel';
import { isTunerServiceId } from '@exaring/utils';
import { logError } from '@exaring/utils/error-logging';
import { ProgramDetailPage } from '@exaring/ui/components-styled/ProgramDetailPage';
import { LinkType, Program } from '@exaring/ui/components-styled/ProgramDetailPage/types';
import { Globals, styled } from '@exaring/ui/components-styled/theme';
import epgService from '@exaring/networking/services/epg';
import tunerService from '@exaring/networking/services/tuner';
import { useAsyncAction } from '@exaring/ui/hooks';
import Header from '../components/Header';
import { routeToVoD, routeToWaiputhekMediathek } from '../routes';
import { epgStore } from '../state/Store';

const WaiputhekPageStyled = styled('div', {
    position: 'relative',
    top: Globals.headerHeight,
    width: '100%',
});

const routingHandler = (
    program: Program | undefined,
    linkType: LinkType,
    goBack: () => void,
    microSiteId?: string,
) => {
    switch (linkType) {
        case 'close':
            goBack();
            break;
        case 'play':
            if (program) {
                routeToVoD(program.channel, program.id, false);
            }
            break;
        case 'mediathek':
            if (microSiteId) {
                routeToWaiputhekMediathek(microSiteId);
            } else if (program?.channel) {
                routeToWaiputhekMediathek(program.channel);
            }
            break;
        default:
            logError(new Error(`Routing: Link type unknown: "${linkType}"`));
            break;
    }
};

interface StateProps {
    microSiteId?: string;
    isEpgInitialized: boolean;
}

interface Actions {
    initTimeCluster?: () => void;
}

interface WaiputhekDetailsProps {
    id: string;
    goBack: () => void;
    channelId?: string;
    onRecommendationClick?: (args: {
        channelId: string;
        programId: string;
        recommendation: RecommendationContents;
        contentId?: string;
    }) => void;
}

type ResponseError = any;
type Result = {
    program?: Program;
    error?: ResponseError;
};

const fetchProgramData = async (channelId: string, programId: string) => {
    const result: Result = {};
    try {
        const useTunerService = isTunerServiceId(programId);
        const { data } = await (useTunerService
            ? tunerService.programDetails(programId) // for mediathek content
            : epgService.programDetails(channelId, programId)); // for all other content
        result.program = data;
    } catch (e: any) {
        result.error = e;
        if (e.response.status !== 404) {
            logError(e);
        }
    }

    return result;
};

const WaiputhekDetails: FunctionComponent<StateProps & Actions & WaiputhekDetailsProps> = ({
    id,
    goBack,
    channelId,
    onRecommendationClick,
    microSiteId,
    isEpgInitialized,
    initTimeCluster,
}) => {
    const [programState] = useAsyncAction(fetchProgramData, [channelId, id]);

    const { result, isLoading } = programState;
    const { program, error } = result || {};
    const errorCode = error?.response ? error?.response?.status || 500 : undefined;

    useEffect(() => {
        if (!isEpgInitialized && initTimeCluster) {
            initTimeCluster();
        }
    }, [initTimeCluster, isEpgInitialized]);

    const hideMediathekButton = microSiteId === undefined && !isTunerServiceId(id);
    return (
        <WaiputhekPageStyled>
            <Header />
            <ProgramDetailPage
                layout="mediathek"
                program={program}
                isLoading={isLoading}
                errorCode={errorCode}
                hideMediathekButton={hideMediathekButton}
                goTo={(prog: Program | undefined, linkType: LinkType) => {
                    routingHandler(prog, linkType, goBack, microSiteId);
                }}
                onRecommendationClick={onRecommendationClick}
            />
        </WaiputhekPageStyled>
    );
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Store {}

const mapStateToProps = (
    _: any,
    { id: programId, channelId }: WaiputhekDetailsProps,
): StateProps => {
    const {
        epgStations: { state: epgState },
        getStationById,
    } = epgStore();
    const isEpgInitialized = epgState === 'Success';
    if (!isTunerServiceId(programId) && channelId) {
        const channel = getStationById(channelId.toLowerCase());
        const microSiteId = channel ? getMicroSiteIdFromStation(channel) : undefined;
        return { microSiteId, isEpgInitialized: !!isEpgInitialized };
    }
    return { microSiteId: undefined, isEpgInitialized: !!isEpgInitialized };
};

export const WaiputhekDetailsPage = connect<WaiputhekDetailsProps, Actions, Store, StateProps>(
    mapStateToProps,
)(WaiputhekDetails);
