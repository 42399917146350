/*
 *  Copyright (C) Exaring AG - All Rights Reserved
 */
import RecordingServiceApi from '@exaring/networking/services/recordings-v4';
import RecordingV2ServiceApi from '@exaring/networking/services/recordings';
import RecordingScheduler from '@exaring/networking/services/recording-scheduler';
import { localStorageItem, logError } from '@exaring/utils';
import {
    Recording,
    RecordingPlaceholder,
    isPlaceHolder,
    isValidRecordingListJson,
    RecordingSelection,
} from '@exaring/networking/types/Recording/Recording';
import { StoreRoot } from './utils/StoreSlice';
import { decodeStreamingDetails, StreamingDetails } from '../types/api/StreamingDetails';
import {
    RemoteData,
    RemoteDataStates,
    didSucceed,
    isLoading,
    notAsked,
    remoteDataStateResolver,
    success,
    loading,
} from './utils/RemoteData';
import constants from '../constants';
import { isValidRecordingSummaryJson } from '../types/api/RecordingSummary';

/**
 * If you expect things getting easier with the "new" recording V4 api
 * you are bloody wrong.
 *
 * As the recording service is ridiculously confusing here some fundamentals and notes
 *
 * scheduling serials still the same
 * refer http://business-systems.doc.exaring.net/swagger-doc/swagger-dist/index.html?url=https%3a%2f%2frecording-scheduler.waipu-dev.net%2fapi%2fdocs#/Scheduling
 *
 * Difference between group id, serial id and series id
 *
 * group id
 * The group id is basically the identifier that is created when you schedule
 * a serial recording.
 *
 * series id
 * Is the identifier that is added to programs that belong to a series. It
 * should be possible to start a serial recording with only the series id
 * but it actually does not work without the program title and channel id. ¯\_(ツ)_/¯
 * (series id is only available in the epg details data thus has to be requested
 * beforehand)
 *
 * serial id
 * just the identifier of a scheduled serial recording and its actually the same
 * as the group id. If you read it somewhere in the docs, its equal the group id. ¯\_(ツ)_/¯
 */

export type State = {
    recordings: RemoteData<Array<Recording | RecordingPlaceholder>>;

    recordingGroups: Record<number, RemoteData<Recording[]>>;

    recordingsSummary: RemoteData<{
        maxStorage: number;
        usedStorage: number;
        numberOfFailedRecordings: number;
    }>;

    showUpsellingModal: boolean;

    streamingDetailsStatus: RemoteDataStates;

    setShowUpsellingModal: (show: boolean) => void;

    initRecordingsSummary: () => Promise<void>;

    /**
     * Rollback to init streaming details status
     */
    resetStreamingDetailsStatus: () => void;

    /**
     * Get all aggregated recordings. This data includes singles and serial recordings.
     */
    fetchRecordings: () => Promise<void>;

    /**
     * Get details for a recordings.
     */
    fetchStreamingDetails: (
        recordingId: string,
        gdprConsent?: string,
    ) => Promise<{ streamingDetails?: StreamingDetails; status: RemoteDataStates } | undefined>;

    /**
     * @param recordingGroup
     * @param serialId
     * @returns
     */
    getRecordingGroup: (
        recordingGroup: number,
        serialId?: string,
        refetch?: boolean,
    ) => Recording[];

    /**
     * Get all recordings which belong to a serial recording
     * @param recordingGroup grouping number which the program belongs to
     * @param serialId id given by scheduling a serial recording
     */
    fetchRecordingGroup: (recordingGroup: number, serialId?: string) => Promise<void>;

    schedule: (
        programId: string,
        recordingGroup?: number,
        isProgramOnAir?: boolean,
    ) => Promise<any>;

    unschedule: (recordingIds: string[]) => Promise<any>;

    stop: (recordingId: string) => void;

    deleteRecordings: (
        recordingIds: string[],
        groupIds?: number[],
        selection?: RecordingSelection[],
        episodesNum?: number,
    ) => Promise<void>;

    scheduleSerial: (
        programId: string,
        channelId?: string,
        title?: string,
        serialId?: string,
        isProgramOnAir?: boolean,
    ) => Promise<void>;

    unscheduleSerials: (serialIds: Array<number>, stopRunningRecording: boolean) => Promise<void>;

    deleteFailedRecordings: () => Promise<void>;
};

export const State: StoreRoot<State> = (set, get) => ({
    recordings: notAsked(),

    streamingDetailsStatus: RemoteDataStates.NotAsked,

    recordingDetails: {
        state: 'NotAsked',
        value: undefined,
    },

    recordingGroups: {},

    recordingsSummary: notAsked(),

    showUpsellingModal: false,

    setShowUpsellingModal: (show: boolean) => {
        set((state) => {
            state.showUpsellingModal = show;
        }, 'Recording/setShowUpsellingModal');
    },

    initRecordingsSummary: async () => {
        set((state) => {
            state.recordingsSummary = loading();
        }, 'Recording/initRecordingsSummary');

        await remoteDataStateResolver(
            () => RecordingV2ServiceApi.summary(),
            isValidRecordingSummaryJson,
            (resolvedState) => {
                if (didSucceed(resolvedState)) {
                    const { value: recordingsSummary } = resolvedState;

                    const maxStorage = recordingsSummary.availableRecordingHours * 3600;
                    const usedStorage = recordingsSummary.finishedRecordingsSeconds;
                    const numberOfFailedRecordings = recordingsSummary.numberOfLostRecordings;
                    const hasLowStorage =
                        !!maxStorage &&
                        !!usedStorage &&
                        maxStorage - usedStorage < constants.LOW_RECORDING_STORAGE_BOUNDARY;

                    if (!hasLowStorage && localStorageItem('user_storage_optout')) {
                        localStorageItem('user_storage_optout', undefined);
                    }

                    set((state) => {
                        state.recordingsSummary = success({
                            maxStorage,
                            usedStorage,
                            numberOfFailedRecordings,
                        });
                    }, 'Recording/initRecordingsSummary');
                }
            },
        );
    },

    resetStreamingDetailsStatus: () => {
        set(
            (state) => {
                state.streamingDetailsStatus = RemoteDataStates.NotAsked;
            },

            'Recording/resetStreamingDetails',
        );
    },

    fetchRecordings: async () => {
        remoteDataStateResolver(
            () => RecordingServiceApi.recordings(),
            isValidRecordingListJson,
            (resolvedState) => {
                set((state) => {
                    state.recordings = resolvedState;
                    if (didSucceed(resolvedState)) {
                        resolvedState.value.forEach((recording) => {
                            if (recording.recordingGroup) {
                                state.recordingGroups[recording.recordingGroup] = notAsked(); // initialize the available data
                            }
                        });
                    }
                }, 'Recording/fetchRecordings');
            },
        );
    },

    fetchStreamingDetails: async (recordingId: string, gdprConsent?: string) => {
        set((state) => {
            state.streamingDetailsStatus = RemoteDataStates.Loading;
        }, 'Recording/setShowUpsellingModal');

        try {
            const { data: streamingDetails } = await RecordingServiceApi.streamingDetails(
                recordingId,
                gdprConsent,
            );

            if (streamingDetails) {
                set((state) => {
                    state.streamingDetailsStatus = RemoteDataStates.Success;
                }, 'Recording/setShowUpsellingModal');

                const value = decodeStreamingDetails(streamingDetails);
                if (value) {
                    // I think there is no need to store the streamingDetails in the store
                    return streamingDetails;
                }
            }
        } catch (e: any) {
            logError(e);
            set((state) => {
                state.streamingDetailsStatus = RemoteDataStates.Failed;
            }, 'Recording/setShowUpsellingModal');
        }
        return undefined;
    },

    getRecordingGroup: (recordingGroup: number, seriesId?: string, refetch = false) => {
        const { recordingGroups, fetchRecordingGroup } = get().recording;

        const foundRecordingGroup = recordingGroups[recordingGroup];

        if (!refetch && foundRecordingGroup && didSucceed(foundRecordingGroup)) {
            return foundRecordingGroup.value;
        }

        // fetch data
        fetchRecordingGroup(recordingGroup, seriesId);

        return [];
    },

    fetchRecordingGroup: async (recordingGroup: number, seriesId?: string) => {
        const { recordingGroups } = get().recording;
        const foundRecordingGroup = recordingGroups[recordingGroup];

        if (foundRecordingGroup && isLoading(foundRecordingGroup)) {
            return;
        }

        remoteDataStateResolver(
            () => RecordingServiceApi.recordingGroup(recordingGroup, seriesId),
            isValidRecordingListJson,
            (resolvedState) => {
                set(
                    (state) => {
                        state.recordingGroups[recordingGroup] = resolvedState;
                    },

                    'Recording/fetchRecordingGroup',
                );
            },
        );
    },

    schedule: async (programId: string, recordingGroup?: number, isProgramOnAir?: boolean) => {
        try {
            const { data } = await RecordingServiceApi.schedule(programId, recordingGroup);

            if (data) {
                set(
                    (state) => {
                        if (didSucceed(state.recordings)) {
                            const nextRecordingsList = state.recordings.value.concat([
                                {
                                    id: data.recordingId,
                                    programId,
                                    ...(recordingGroup ? { recordingGroup } : {}),
                                    status: isProgramOnAir ? 'RECORDING' : 'SCHEDULED',
                                    placeHolder: true,
                                },
                            ]);
                            state.recordings = success(nextRecordingsList);
                        }
                    },

                    'Recording/schedule',
                );
            }

            return data;
        } catch (e) {
            logError(e as Error);
        }
        return undefined;
    },

    unschedule: async (recordingIds: string[]) => {
        const { fetchRecordingGroup, recordings: recordingsRemoteData } = get().recording;
        try {
            let recordings: Array<Recording | RecordingPlaceholder>;

            if (didSucceed(recordingsRemoteData)) {
                recordings = recordingsRemoteData.value;
            } else {
                return;
            }

            await RecordingServiceApi.unschedule(recordingIds);

            recordingIds.forEach((recId) => {
                const foundGroupRecording = recordings.find((rec) => {
                    return rec.id === recId && rec.recordingGroup;
                });

                // update recording group if recording was member of group recording
                if (!isPlaceHolder(foundGroupRecording) && foundGroupRecording?.recordingGroup) {
                    fetchRecordingGroup(
                        foundGroupRecording.recordingGroup,
                        foundGroupRecording.seriesId,
                    );
                } else {
                    set((state) => {
                        if (didSucceed(state.recordings)) {
                            state.recordings.value = state.recordings.value.filter(
                                (rec) => rec.id !== recId,
                            );
                        }
                    }, 'Recording/unschedule');
                }
            });
        } catch (e) {
            console.error(e);
        }
    },

    stop: async (recordingId: string) => {
        try {
            await RecordingServiceApi.stop(recordingId);

            set((state) => {
                if (didSucceed(state.recordings)) {
                    state.recordings.value = state.recordings.value.filter(
                        (rec) => rec.id !== recordingId,
                    );
                }
            }, 'Recording/stop');
        } catch (e) {
            console.error(e);
        }
    },

    scheduleSerial: async (
        programId: string,
        channelId?: string,
        title?: string,
        seriesId?: string,
        isProgramOnAir?: boolean,
    ) => {
        const { fetchRecordingGroup, schedule, fetchRecordings } = get().recording;
        // TODO add storage check and throw notification if not recordable

        try {
            // first publish a placeholder to lock recording button
            set((state) => {
                if (didSucceed(state.recordings)) {
                    state.recordings.value = state.recordings.value.concat([
                        {
                            id: programId,
                            programId,
                            status: 'UNKNOWN',
                            placeHolder: true,
                        },
                    ]);
                }
            }, 'Recording/scheduleSerial');

            // schedule serial recording (all future recordings)
            const {
                data: { id: recordingGroup },
            } = await RecordingScheduler.scheduleSerial(title, channelId, seriesId);

            // remove placeholder
            set((state) => {
                if (didSucceed(state.recordings)) {
                    state.recordings.value = state.recordings.value.filter(
                        (rec) => !(rec.programId === programId && isPlaceHolder(rec)),
                    );
                }
            }, 'Recording/scheduleSerial');

            await fetchRecordings();

            // then add `unknown` placeholder and start the single live recording and add it to recording group
            if (isProgramOnAir) {
                await schedule(programId, recordingGroup, isProgramOnAir);
            }

            // fetch all scheduled group recordings
            await fetchRecordingGroup(recordingGroup);
        } catch (e) {
            console.error(e);
        }
    },

    deleteRecordings: async (
        recordingIds: string[],
        groupIds?: number[],
        recordingSelection?: RecordingSelection[],
        episodesNum?: number,
    ) => {
        const recordingsCount = recordingIds.length;
        try {
            await RecordingServiceApi.deleteRecordings(recordingIds, groupIds, recordingSelection);

            set((state) => {
                if (didSucceed(state.recordings)) {
                    state.recordings = success(
                        state.recordings.value.filter((rec) => !recordingIds.includes(rec.id)),
                    );
                }
            }, 'Recording/deleteRecordings');
            get().notifications.doneDeleteRecordings(
                episodesNum && episodesNum > 0 ? episodesNum : recordingsCount,
            );
        } catch (e) {
            get().notifications.errorDeleteRecordings(recordingsCount);
            console.error(e);
        }
    },

    unscheduleSerials: async (seriesIds: Array<number>) => {
        try {
            // unschedule only future recordings
            await RecordingScheduler.unscheduleSerials(seriesIds, {
                deleteFutureRecordings: true,
                deleteFinishedRecordings: false,
                deleteRunningRecordings: false,
            });

            const { stop, fetchRecordings, recordings: recordingsRemoteData } = get().recording;

            if (!didSucceed(recordingsRemoteData)) {
                return;
            }

            const recordingsList = recordingsRemoteData.value;

            // stop all active recording
            seriesIds.forEach(async (serialId) => {
                const activeRecording = recordingsList.find(
                    (rec) => rec.status === 'RECORDING' && rec.recordingGroup === serialId,
                );

                if (activeRecording) {
                    await stop(activeRecording.id);
                }

                const { recordingGroups } = get().recording;

                set((state) => {
                    state.recordingGroups = { ...recordingGroups, [serialId]: notAsked() }; // reset recording group data
                }, 'Recording/unscheduleSerials');
            });

            setTimeout(async () => {
                // give BS 2 Sec. window to update the recording list and refetch
                await fetchRecordings();
            }, 2000);
        } catch (e) {
            console.error(e);
        }
    },

    deleteFailedRecordings: async () => {
        RecordingServiceApi.deleteFailed(); // fire and forget
    },
});
