/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
import { h, Fragment } from 'preact';
import { BigToast } from '@exaring/ui/components-styled/BigToast/BigToast';
import { Snackbar } from '@exaring/ui/components-styled/Snackbar';
import { rootPath } from '@exaring/utils';
import { isHint, isToast, isSnack } from '../../state/NotificationsStore';
import { useNotificationsStore } from '../../state/Store';
import { Hint } from './Hint';
import { Routes } from '../../routes';

const isPathActive = (path: string) => path === rootPath(window.location.pathname);
const recordingsHintStyles = { bottom: '0px' };

export default () => {
    const store = useNotificationsStore();
    const { notifications } = store;

    const isRecordingsPage = isPathActive(Routes.RECORDING_PAGE);
    if (notifications.length > 0) {
        const hint = notifications.filter(isHint)[0];
        const toast = notifications.filter(isToast)[0];
        const snacks = notifications.filter(isSnack);

        return (
            <>
                {toast && (
                    <BigToast
                        isVisible
                        icon={toast.props.icon as any}
                        title={toast.props.title}
                        confirmLabel={toast.props.confirmLabel}
                        cancelLabel={toast.props.cancelLabel}
                        onConfirm={toast.props.onConfirm as any}
                        onHideToast={toast.props.onCancel as any}
                    >
                        {toast.props.message()}
                    </BigToast>
                )}
                {snacks &&
                    snacks.map((snack, idx) => (
                        <Snackbar
                            key={snack.id}
                            type={snack.props.type}
                            onClose={snack.props.onClose}
                            css={{
                                transform: `translateY(-${idx * 55}px)`,
                                transition: 'transform 0.3s ease-in-out',
                                ...(snack.props.css || {}),
                            }}
                        >
                            {snack.props.message}
                        </Snackbar>
                    ))}
                {hint && (
                    <Hint
                        title={hint.props.title}
                        titleEmphasize={hint.props.titleEmphasize}
                        message={hint.props.message}
                        confirmLabel={hint.props.confirmLabel}
                        cancelLabel={hint.props.cancelLabel}
                        onConfirm={hint.props.onConfirm}
                        onCancel={hint.props.onCancel}
                        onClose={hint.props.onClose}
                        docked
                        style={isRecordingsPage ? recordingsHintStyles : undefined}
                    />
                )}
            </>
        );
    }

    return null;
};
