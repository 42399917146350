interface ENV {
    CONSTANTS_CONFIG?: string;
}

type Tenant = 'waipu' | 'o2';

interface ConstantsEnv {
    WEB_CLIENT_BASE_URL?: string;
    LOGOUT_URL?: string;
    HELP_URL?: string;
    CSC_HOME_URL?: string;
    CSC_CHECKOUT_URL?: string;
    CSC_PAYMENT_URL?: string;
    WEB_COMMONS_VERSION?: string;
    TENANT?: Tenant;
}

const getConfig = (): ENV => {
    return {
        CONSTANTS_CONFIG: process.env.CONSTANTS_CONFIG,
    };
};

const parseConstantsConfig = (env: ENV): ConstantsEnv => {
    if (!env.CONSTANTS_CONFIG) {
        throw new Error(`Missing key "CONSTANTS_CONFIG" in env vars`);
    }
    const conf = env.CONSTANTS_CONFIG;
    const rawEnvData =
        typeof conf === 'object' && !Array.isArray(conf) // we need to bridge this, because webpack define plugin auto evaluates defined env values back to JS
            ? conf
            : JSON.parse(conf);
    const constantsConfig = rawEnvData as ConstantsEnv;

    for (const [key, value] of Object.entries(constantsConfig)) {
        if (value === undefined) {
            throw new Error(`Missing key ${key} in "CONSTANTS_CONFIG"`);
        }
    }
    return constantsConfig;
};

export const Constants = parseConstantsConfig(getConfig());
