import { styled } from '../../theme';

export const StyledImageOverlay = styled('span', {
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    position: 'absolute',
    height: '100%',
    width: '100%',
});
