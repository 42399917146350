export enum RemoteDataStates {
    NotAsked = 'notAsked',
    Loading = 'loading',
    Success = 'success',
    Failed = 'failed',
}

export type RemoteDataState = RemoteDataStates[keyof RemoteDataStates];

export interface NotAsked {
    status: RemoteDataStates.NotAsked;
}
export interface Loading {
    status: RemoteDataStates.Loading;
}
export interface Failed {
    status: RemoteDataStates.Failed;
    error: Error;
}
export interface Success<T> {
    status: RemoteDataStates.Success;
    value: T;
}

//
//  Type Constructors
//

export const notAsked = (): NotAsked => ({ status: RemoteDataStates.NotAsked });

export const loading = (): Loading => ({ status: RemoteDataStates.Loading });

export const failed = (error: Error): Failed => ({
    status: RemoteDataStates.Failed,
    error,
});

export const success = <T>(value: T): Success<T> => ({
    status: RemoteDataStates.Success,
    value,
});

export const isLoading = <T>(data: RemoteData<T>): data is Loading => {
    return data.status === RemoteDataStates.Loading;
};

export const didFail = <T>(data: RemoteData<T>): data is Failed => {
    return data.status === RemoteDataStates.Failed;
};

export const didSucceed = <T>(data: RemoteData<T>): data is Success<T> => {
    return data.status === RemoteDataStates.Success;
};

export const didNotAsk = <T>(data: RemoteData<T>): boolean => {
    return data.status === RemoteDataStates.NotAsked;
};

export type RemoteData<T> = NotAsked | Loading | Failed | Success<T>;

type TypeGuard<T> = (value: any) => value is T;

export const MalformedDataError = 'RemoteData: Data malformed';

/**
 * This util function allows any promise with the correct interface
 * to mediate all resolving states to the container type RemoteData.
 * This avoids a lot of boiler code and is particularly useful when used
 * in stores like Zustand to mediate all resolving states to a store state.
 *
 * 1) You define one state in your store like
 *
 *     search: RemoteData<SearchData>;
 *
 * 2) Use the util function within a store action to set the state
 *
 *    remoteDataStateResolver(
 *        () => epg2Service.query(query, constants.SEARCH_RESULT_LIMIT), // resource
 *        isSearchResult, // type guard for response data
 *        (resolvedState) => {
 *            set((state) => { // using Zustand set function to set the resolving state
 *                state.search = resolvedState;
 *            });
 *         },
 *    ),
 */
export const remoteDataStateResolver = async <S>(
    resource: () => Promise<{ data: any }>,
    validator: TypeGuard<S>,
    remoteDataResolver: (resolvedData: RemoteData<S>) => void,
): Promise<RemoteData<S>> => {
    try {
        remoteDataResolver(loading());

        const { data } = await resource();

        if (validator(data)) {
            remoteDataResolver(success(data));
            return success(data);
        }

        throw Error(MalformedDataError);
    } catch (e) {
        const error = e as Error;

        remoteDataResolver(failed(error));

        return failed(error);
    }
};
