/* eslint-disable react/require-default-props */
/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, Fragment, FunctionComponent } from 'preact';
import { classnames } from '@exaring/utils';
import { CSSProperties } from 'preact/compat';

type HintProps = {
    title: string;
    titleEmphasize?: string | number;
    message?: string;
    confirmLabel?: string;
    cancelLabel?: string;
    onConfirm?: () => Promise<void> | void;
    onCancel?: () => Promise<void> | void;
    onClose?: () => Promise<void> | void;
    fixedBottom?: boolean;
    docked?: boolean;
    style?: CSSProperties;
};

export const Hint: FunctionComponent<HintProps> = ({
    title,
    titleEmphasize,
    message,
    confirmLabel,
    cancelLabel,
    onConfirm,
    onCancel,
    onClose,
    fixedBottom = false,
    docked = false,
    style,
}: HintProps) => {
    return (
        <div
            className={classnames(
                'notification-overlay',
                fixedBottom && 'fixed__bottom',
                docked && 'docked',
            )}
            style={style}
        >
            <div className="notification-overlay__info">
                <p>
                    {title}
                    {titleEmphasize && (
                        <>
                            {' '}
                            <b>{titleEmphasize}</b>
                        </>
                    )}
                </p>
                {message && <p className="info--small">{message}</p>}
            </div>
            <div className="notification-overlay__buttons">
                {cancelLabel && (
                    <button
                        type="button"
                        className="button button--transparent notification-overlay__opt-out"
                        onClick={onCancel}
                    >
                        {cancelLabel}
                    </button>
                )}
                {confirmLabel && (
                    <button
                        autoFocus
                        type="button"
                        className="button button--transparent"
                        onClick={onConfirm}
                    >
                        {confirmLabel}
                    </button>
                )}
                <span className="notification-overlay__hide" onClick={onClose}>
                    x
                </span>
            </div>
        </div>
    );
};
