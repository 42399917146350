import { h } from 'preact';
import LockedIcon from '@exaring/assets/icons/locked.svg';
import tileFallbackWaiputhek from '@exaring/assets/images/tileFallback.png';
import tileFallbackWaipu from '@exaring/assets/images/tileFallback-m.png';
import { Colors, styled } from '../../theme';
import { Typography } from '../../Typography';
import { RecommendationContents } from '../../types/Recommendation';
import { ProgramProgressBar } from '../../ProgramProgressBar/ProgramProgressBar';
import { ImageWithFallback } from '../../Tile/parts/ImageWithFallback';

const Cover = styled(ImageWithFallback, {
    borderRadius: '$1',
    maxWidth: '100%',
    maxHeight: '100%',
    height: 'auto',
    '&:hover::before': {
        content: '',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.6)',
    },
});

const RecommendationTitle = styled(Typography, {
    // `height` is set to 33px to fix the issue in Safari, https://exaring.atlassian.net/browse/TVFUS-51546
    height: '33px',
    width: '100%',
    zIndex: '2',
    borderRadius: '$1',
    position: 'absolute',
    bottom: '-5px',
    paddingRight: '10px',
    paddingLeft: '10px',
    fontSize: '$8',
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    margin: 'unset',
});

const RecommendationItem = styled('div', {
    position: 'relative',
    width: '202px',
    borderRadius: '$1',
    height: '108px',
    cursor: 'pointer',
    '@sm': {
        width: '207px',
        height: '111px',
    },
    '&:hover': {
        transform: 'scale(1.05)',
    },
    '&:hover p': {
        background: 'transparent',
    },

    '&:hover::before': {
        content: '',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.6)',
        transform: 'scale(1.1)',
    },
    '&:after': {
        content: '',
        position: 'absolute',
        top: '52%',
        left: 0,
        width: '100%',
        height: '59px',
        background: 'linear-gradient(to bottom, rgba(9, 7, 20, 0) 0%, rgba(9, 7, 20, 0.9) 90%)',
    },
});

const StyledLockedIcon = styled(LockedIcon, {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
});

const progressBarCss = {
    width: '202px',
    '@sm': {
        width: '207px',
    },
};

export const contentDiscoveryTileMapper = (
    recommendation: RecommendationContents & { dataId?: string },
): JSX.Element => {
    const getFallback = () => {
        if (recommendation?.dataId === 'more_episodes') {
            return tileFallbackWaipu;
        }
        return tileFallbackWaiputhek;
    };
    return (
        <RecommendationItem>
            <Cover
                src={recommendation.img}
                srcFallback={getFallback()}
                alt={recommendation.channelDisplay}
            />
            {recommendation.locked && (
                <StyledLockedIcon width={32} height={32} color={Colors.white} />
            )}
            <RecommendationTitle>
                {recommendation?.dataId === 'more_episodes'
                    ? recommendation.episodeTitle
                    : recommendation.title}
            </RecommendationTitle>
            {!!recommendation.positionPercentage && (
                <ProgramProgressBar
                    progress={recommendation.positionPercentage}
                    css={progressBarCss}
                />
            )}
        </RecommendationItem>
    );
};
