/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, FunctionComponent } from 'preact';
import ArrowBackIcon from '@exaring/assets/icons/arrow-back.svg';
import { styled, CSS, Colors } from '../../theme';
import { Typography } from '../../Typography/Typography';
import { StyledTileContainer } from '../parts/StyledTileContainer';
import { StyledTileImage } from '../parts/StyledTileImage';
import { clipTextOverflowStyle } from '../styles';

const ArrowBackIconStyled = styled(ArrowBackIcon, { transform: 'rotate(180deg)' });

const StyledTile = styled(StyledTileContainer, {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '258px',
    backgroundColor: '$dark',
});

const StyledImageContainer = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '$2',

    variants: {
        fullWidth: {
            true: {
                marginTop: 0,
            },
        },
    },
});

const StyledImage = styled(StyledTileImage, {
    width: 'auto',
    height: 'auto',
    maxHeight: '135px',

    variants: {
        size: {
            mobile: { width: '121px', margin: '0 7px' },
            default: { width: '135px', margin: 0 },
        },
        fullWidth: {
            true: {
                width: '100%',
                maxHeight: 'unset',
            },
        },
    },

    compoundVariants: [
        {
            size: 'mobile',
            fullWidth: true,
            css: {
                width: '100%',
            },
        },
        {
            size: 'default',
            fullWidth: true,
            css: {
                width: '100%',
            },
        },
    ],
});

const StyledTitleContainer = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 10px 0 18px',
    height: '51px',
});

const StyledTypography = styled(Typography, {
    ...clipTextOverflowStyle,
    fontSize: '$2.5',
    color: '$white',
    margin: 0,
    maxLines: 1,
});

export const LinkTile: FunctionComponent<{
    css?: CSS;
    className?: string;
    title?: string;
    imageSrc?: string;
    imageFullWidth?: boolean;
    onClick?: () => void;
}> = ({ css, className, title, imageSrc, imageFullWidth, onClick }) => (
    <StyledTile
        size={{
            '@initial': 'mobile',
            '@sm': 'default',
        }}
        css={css}
        className={className}
        onClick={onClick}
    >
        <StyledImageContainer fullWidth={imageFullWidth}>
            <StyledImage
                size={{
                    '@initial': 'mobile',
                    '@sm': 'default',
                }}
                fullWidth={imageFullWidth}
                src={imageSrc}
            />
        </StyledImageContainer>
        <StyledTitleContainer>
            <StyledTypography variant="h2">{title ?? 'zu allen Videos'}</StyledTypography>
            <ArrowBackIconStyled fill={Colors.white} />
        </StyledTitleContainer>
    </StyledTile>
);
