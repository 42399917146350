import { keyframes } from '@stitches/react';
import { styled } from '../theme';

const skeletonLoading = keyframes({
    '0%': { backgroundColor: '$dark' },
    '100%': { backgroundColor: '$charade' },
});

export const RectangleSkeleton = styled('div', {
    height: '100%',
    width: '100%',
    backgroundColor: '$dark',
    borderRadius: '4px',

    variants: {
        animated: {
            true: {
                animation: `${skeletonLoading} 1s linear infinite alternate`,
            },
        },
    },

    defaultVariants: {
        animated: true,
    },
});
