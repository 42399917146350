/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, FunctionComponent } from 'preact';
import { CSS } from '@stitches/react';
import LockedIcon from '@exaring/assets/icons/locked.svg';
import { styled, Colors } from '../../theme';
import { Typography } from '../../Typography/Typography';
import { StyledTileImage } from '../parts/StyledTileImage';
import { StyledTileImageContainer } from '../parts/StyledTileImageContainer';
import { StyledImageOverlay } from '../parts/StyledImageOverlay';
import { headlineStyle } from '../styles';

const TILE_SIZE = '141px';

const StyledTile = styled('div', {
    margin: '0 1px 1px 0',
    cursor: 'pointer',

    maxWidth: '141px',
    width: TILE_SIZE,

    [`&:hover ${StyledImageOverlay}`]: {
        border: '$charcoalGrey solid 3px',
    },

    variants: {
        size: {
            mobile: { width: '93px' },
            default: { width: TILE_SIZE },
        },
    },
});

const StyledImage = styled(StyledTileImage, {
    maxHeight: TILE_SIZE,
    borderRadius: '100%',
    variants: {
        size: {
            mobile: { minHeight: '92px' },
            default: { minHeight: TILE_SIZE },
        },
    },
});

const TileImageContainer: FunctionComponent<{
    css?: CSS;
    className?: string;
    clickable?: boolean;
}> = ({ clickable = true, children, css, className }) => {
    return (
        <StyledTileImageContainer
            css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...css,
            }}
            className={className}
        >
            <StyledImageOverlay css={{ borderRadius: '100%' }}>
                {!clickable && <LockedIcon width={45} height={45} color={Colors.white} />}
            </StyledImageOverlay>
            {children}
        </StyledTileImageContainer>
    );
};

export const ChannelTile: FunctionComponent<{
    css?: CSS;
    className?: string;
    title?: string;
    imageSrc?: string;
    clickable?: boolean;
    onClick?: (e: any) => void;
}> = ({ css, className, title, imageSrc, clickable = true, onClick }) => (
    <StyledTile
        size={{
            '@initial': 'mobile',
            '@sm': 'default',
        }}
        css={css}
        className={className}
        onClick={onClick}
    >
        <TileImageContainer clickable={clickable}>
            <StyledImage
                size={{
                    '@initial': 'mobile',
                    '@sm': 'default',
                }}
                src={imageSrc}
            />
        </TileImageContainer>

        <Typography
            css={{
                ...headlineStyle,
                marginTop: '12px',
            }}
            centered
            variant="body1"
        >
            {title}
        </Typography>
    </StyledTile>
);
