import { Button } from '../../../../packages/ui/components-styled/Button';
import { Globals, styled } from '../../../../packages/ui/components-styled/theme';

export const ChannelSortContent = styled('div', {
    marginTop: Globals.headerHeight,
    paddingTop: '92px',
    paddingBottom: '47px',
});

export const StickySubheader = styled('div', {
    paddingTop: '16px',
    paddingBottom: '8px',
    position: 'fixed',
    width: '100%',
    // nearblack color with 0.9 alpha
    background: 'rgba(9, 7, 20, 0.9)',
    backdropFilter: 'blur(6px)',
    height: '72px',
    boxSizing: 'border-box',
});

export const ViewButton = styled(Button, {
    width: '100% !important',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: '$2',

    '& > svg': {
        width: '32px',
        height: '32px',
        marginTop: '-4px',
        marginBottom: '-4px',
        marginRight: '8px',
    },

    variants: {
        selected: {
            true: {
                color: '$aquamarine',
            },
        },
    },
});

export const BackButton = styled('button', {
    width: '36px',
    height: '36px',
    float: 'left',
    marginLeft: '6px',
    cursor: 'pointer',
});

export const ResetButton = styled(Button, {
    height: '36px',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: '$2',

    width: '48px',
    marginLeft: 'auto !important',

    '& span': {
        display: 'none',
    },

    '& svg': {
        marginRight: '8px',
    },

    '@md': {
        margin: 'auto !important',
        width: '100% !important',

        '& span': {
            display: 'inline',
        },
    },
});

export const HeaderText = styled('h1', {
    marginTop: '0px',
    marginBottom: '30px',
    fontSize: '$6',
    fontWeight: '$3',
});

export const StickyBottomGradient = styled('div', {
    width: '100%',
    height: '64px',
    position: 'fixed',
    bottom: 0,
    background: 'linear-gradient(to bottom, rgba(9, 7, 20, 0), rgba(9, 7, 20, 0.75))',
    pointerEvents: 'none',
});

export const Description = styled('div', {
    fontSize: '$2',
    fontWeight: '$1',
    lineHeight: '24px',
});
