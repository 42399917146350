/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h } from 'preact';
import OSDButton from './OSDButton';
import OSDButtonGroup from './OSDButtonGroup';

// Button<Restart> Button<-30> Button<Play|Pause> Button<+30>
const RecordingControls = ({
    isSeekingForbidden,
    isPauseForbidden,
    isInstantRestartForbidden,
    isDisabled,
    onJumpToStart,
    onSeekBackward,
    onPlay,
    isPlaying,
    onSeekForward,
}) => {
    return (
        <OSDButtonGroup className="osd__controls__icon-group--recording">
            <OSDButton
                isDisabled={isDisabled || isInstantRestartForbidden}
                onAction={onJumpToStart}
                className="icon--restart"
            />
            {!isSeekingForbidden && (
                <OSDButton
                    isDisabled={isDisabled}
                    onAction={onSeekBackward}
                    className="icon--seek-backwards"
                />
            )}
            <OSDButton
                isDisabled={isDisabled || isPauseForbidden}
                onAction={onPlay}
                className={isPlaying ? 'icon--pause' : 'icon--play'}
            />
            {!isSeekingForbidden && (
                <OSDButton
                    isDisabled={isDisabled}
                    onAction={onSeekForward}
                    className="icon--seek-forwards"
                />
            )}
        </OSDButtonGroup>
    );
};

export default RecordingControls;
