import { h, FunctionComponent, ComponentChildren } from 'preact';
import { RecordingDiscoveryMetaInfo } from '@exaring/ui/components-styled/Tile';

export const TimeInfo: FunctionComponent<{ children: ComponentChildren }> = ({ children }) => {
    return (
        <RecordingDiscoveryMetaInfo css={{ color: '$lightBlue' }}>
            {children}
        </RecordingDiscoveryMetaInfo>
    );
};
