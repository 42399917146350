/* eslint-disable no-console */

/**
 * This code is a strip-off version and TS port of https://github.com/exif-js/exif-js
 */

const DEBUG = false;

const SEGMEMT_MARKER = 0xff; // segment marker
const SOI = 0xd8; // start of image marker
const TIFF_MAGIC_NUMBER = 0x002a;
const LITTLE_ENDIAN = 0x4949;
const BIG_ENDIAN = 0x4d4d;
const EXIF_MARKER = 0xffe1;

const ExifTags = {
    0x9286: 'UserComment', // Comments by user
};
const TiffTags = {
    0x8769: 'ExifIFDPointer',
};

const findExifInJpeg = (file: ArrayBufferLike) => {
    const dataView = new DataView(file);
    const length = file.byteLength;
    let offset = 2;
    let marker;

    if (DEBUG) {
        console.log(`Got file of length ${file.byteLength}`);
    }

    if (dataView.getUint8(0) !== SEGMEMT_MARKER || dataView.getUint8(1) !== SOI) {
        if (DEBUG) {
            console.log('Not a valid JPEG');
        }
        return false; // not a valid jpeg
    }

    while (offset < length) {
        if (dataView.getUint8(offset) !== 0xff) {
            if (DEBUG) {
                console.log(
                    `Not a valid marker at offset ${offset}, found: ${dataView.getUint8(offset)}`,
                );
            }
            return false; // not a valid marker, something is wrong
        }

        marker = dataView.getUint8(offset + 1);

        if (DEBUG) {
            console.log(marker);
        }

        // we could implement handling for other markers here,
        // but we're only looking for 0xFFE1 (EXIF data)
        if (marker === 225) {
            if (DEBUG) {
                console.log(`Found ${EXIF_MARKER} marker`);
            }

            return readExifData(dataView, offset + 4);
        }

        offset += 2 + dataView.getUint16(offset + 2);
    }

    return undefined;
};

const readTags = (
    file: DataView,
    tiffStart: number,
    dirStart: number,
    tags: any,
    bigEnd: boolean,
) => {
    const entries = file.getUint16(dirStart, !bigEnd);
    const foundTags: any = {};
    let entryOffset;
    let tag;
    let i;

    for (i = 0; i < entries; i += 1) {
        entryOffset = dirStart + i * 12 + 2;
        tag = tags[file.getUint16(entryOffset, !bigEnd)];

        if (tag) {
            // just add known tags
            foundTags[tag] = readTagValue(file, entryOffset, tiffStart, bigEnd);
        } else if (DEBUG) {
            console.log(`Unknown tag: ${file.getUint16(entryOffset, !bigEnd)}`);
        }
    }

    return foundTags;
};

const readTagValue = (file: DataView, entryOffset: number, tiffStart: number, bigEnd: boolean) => {
    const type = file.getUint16(entryOffset + 2, !bigEnd);
    const numValues = file.getUint32(entryOffset + 4, !bigEnd);
    const valueOffset = file.getUint32(entryOffset + 8, !bigEnd) + tiffStart;
    let offset;
    let vals;
    let val;
    let n;
    let numerator;
    let denominator;

    switch (type) {
        default:
        case 1: // byte, 8-bit unsigned int
        case 7: // undefined, 8-bit byte, value depending on field
            if (numValues === 1) {
                return file.getUint8(entryOffset + 8);
            }
            offset = numValues > 4 ? valueOffset : entryOffset + 8;
            vals = [];
            for (n = 0; n < numValues; n += 1) {
                vals[n] = file.getUint8(offset + n);
            }
            return vals;

        case 2: // ascii, 8-bit byte
            offset = numValues > 4 ? valueOffset : entryOffset + 8;
            return getStringFromBuffer(file, offset, numValues - 1);

        case 3: // short, 16 bit int
            if (numValues === 1) {
                return file.getUint16(entryOffset + 8, !bigEnd);
            }
            offset = numValues > 2 ? valueOffset : entryOffset + 8;
            vals = [];
            for (n = 0; n < numValues; n += 1) {
                vals[n] = file.getUint16(offset + 2 * n, !bigEnd);
            }
            return vals;

        case 4: // long, 32 bit int
            if (numValues === 1) {
                return file.getUint32(entryOffset + 8, !bigEnd);
            }
            vals = [];
            for (n = 0; n < numValues; n += 1) {
                vals[n] = file.getUint32(valueOffset + 4 * n, !bigEnd);
            }
            return vals;

        case 5: // rational = two long values, first is numerator, second is denominator
            if (numValues === 1) {
                numerator = file.getUint32(valueOffset, !bigEnd);
                denominator = file.getUint32(valueOffset + 4, !bigEnd);
                val = numerator / denominator;
                return val;
            }
            vals = [];
            for (n = 0; n < numValues; n += 1) {
                numerator = file.getUint32(valueOffset + 8 * n, !bigEnd);
                denominator = file.getUint32(valueOffset + 4 + 8 * n, !bigEnd);
                vals[n] = numerator / denominator;
            }
            return vals;

        case 9: // slong, 32 bit signed int
            if (numValues === 1) {
                return file.getInt32(entryOffset + 8, !bigEnd);
            }
            vals = [];
            for (n = 0; n < numValues; n += 1) {
                vals[n] = file.getInt32(valueOffset + 4 * n, !bigEnd);
            }
            return vals;

        case 10: // signed rational, two slongs, first is numerator, second is denominator
            if (numValues === 1) {
                return (
                    file.getInt32(valueOffset, !bigEnd) / file.getInt32(valueOffset + 4, !bigEnd)
                );
            }
            vals = [];
            for (n = 0; n < numValues; n += 1) {
                vals[n] =
                    file.getInt32(valueOffset + 8 * n, !bigEnd) /
                    file.getInt32(valueOffset + 4 + 8 * n, !bigEnd);
            }
            return vals;
    }
};

const getStringFromBuffer = (buffer: DataView, start: number, length: number) => {
    let outstr = '';

    for (let n = start; n < start + length; n += 1) {
        outstr += String.fromCharCode(buffer.getUint8(n));
    }

    return outstr;
};

const readExifData = (file: DataView, start: number) => {
    if (getStringFromBuffer(file, start, 4) !== 'Exif') {
        if (DEBUG) {
            console.log(`Not valid EXIF data! ${getStringFromBuffer(file, start, 4)}`);
        }

        return false;
    }

    let bigEnd;
    let tag;
    let exifData;
    const tiffOffset = start + 6;

    // test for endianness
    if (file.getUint16(tiffOffset) === LITTLE_ENDIAN) {
        bigEnd = false;
    } else if (file.getUint16(tiffOffset) === BIG_ENDIAN) {
        bigEnd = true;
    } else {
        if (DEBUG) {
            console.log(`Not valid TIFF data! (no ${LITTLE_ENDIAN} or ${BIG_ENDIAN})`);
        }

        return false;
    }

    // test for TIFF validity
    if (file.getUint16(tiffOffset + 2, !bigEnd) !== TIFF_MAGIC_NUMBER) {
        if (DEBUG) {
            console.log(`Not valid TIFF data! (no ${TIFF_MAGIC_NUMBER})`);
        }

        return false;
    }

    const firstIFDOffset = file.getUint32(tiffOffset + 4, !bigEnd);

    if (firstIFDOffset < 0x00000008) {
        if (DEBUG) {
            console.log(
                'Not valid TIFF data! (First offset less than 8)',
                file.getUint32(tiffOffset + 4, !bigEnd),
            );
        }

        return false;
    }

    const foundTiffTags = readTags(file, tiffOffset, tiffOffset + firstIFDOffset, TiffTags, bigEnd);
    const tags: any = {};
    if (foundTiffTags.ExifIFDPointer) {
        exifData = readTags(
            file,
            tiffOffset,
            tiffOffset + foundTiffTags.ExifIFDPointer,
            ExifTags,
            bigEnd,
        );
        for (tag in exifData) {
            if (tag) {
                tags[tag] = exifData[tag];
            }
        }
    }

    return tags;
};

type ExifData = {
    UserComment: Array<number>;
};

export const readFromBinaryFile = (file: ArrayBufferLike): ExifData => {
    return findExifInJpeg(file);
};
