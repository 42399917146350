import { isObject } from '../object';

export const exists = (val) => !!(val && /^(?!\s*$).+/.test(val));

export const matchesRegex = (regex) => (val) => regex.test(val);

export const handleChange = (setterFn) => (event) => setterFn(event?.target?.value);

/** @type {(v: unknown) => boolean} */
export const isNil = (v) =>
    // eslint-disable-next-line no-restricted-syntax
    v === undefined || v === null;

/** @type {<T>(value: T, defaultValue: T) => T} */
export const orDefault = (value, defaultValue) =>
    !isNil(value) && !Number.isNaN(value) ? value : defaultValue;

export const validateProperties = (obj, validationMap) =>
    isObject(obj) && Object.keys(validationMap).every((key) => validationMap[key]?.(obj[key]));

export const isNumber = (value) => typeof value === 'number' && !Number.isNaN(value);

export const isBoolean = (value) => typeof value === 'boolean';

export const isTrue = (value) => value === true;

export const isFalse = (value) => value === false;
