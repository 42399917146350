import { h, FunctionComponent, RefObject } from 'preact';
import { styled, VariantProps } from '@exaring/ui/components-styled/theme';
import { useScrollViewport } from '@exaring/ui/hooks/useScrollViewport';
import { MutableRef } from 'preact/hooks';
import * as CONSTANTS from '../constants';

type ShadowDirection =
    | ''
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'topleft'
    | 'topright'
    | 'toprightleft'
    | 'topbottomleft'
    | 'toprightbottom'
    | 'bottomleft'
    | 'rightbottom'
    | 'rightbottomleft'
    | 'toprightbottomleft'
    | 'rightleft'
    | 'topbottom';

type ShadowScrollContainerVariants = VariantProps<typeof ShadowScrollContainer>;

const ShadowScrollContainer = styled('div', {
    $$programHeight: '60px',
    $$channelListHeight: `${CONSTANTS.CHANNEL_LIST_WIDTH_IN_PX}px`,
    $$shadowBlur: '20px',
    $$shadowSpread: '75px',
    $$shadowSize: '100px',
    $$shadowColor: 'rgba(9, 7, 20, .75)',

    $$shadowTop: 'inset 0 $$shadowSize $$shadowBlur -$$shadowSpread $$shadowColor',
    $$shadowRight: 'inset -$$shadowSize 0 $$shadowBlur -$$shadowSpread $$shadowColor',
    $$shadowBottom: 'inset 0 -$$shadowSize $$shadowBlur -$$shadowSpread $$shadowColor',
    $$shadowLeft: 'inset $$shadowSize 0 $$shadowBlur -$$shadowSpread $$shadowColor',

    zIndex: 1,
    position: 'absolute',
    pointerEvents: 'none',
    top: '$$programHeight',
    left: '$$channelListHeight',
    width: 'calc(100% - $$channelListHeight)',
    height: 'calc(100% - $$programHeight)',

    variants: {
        shadowDirection: {
            topleft: {
                boxShadow: '$$shadowTop,$$shadowLeft',
            },
            topright: {
                boxShadow: '$$shadowTop,$$shadowRight',
            },
            toprightleft: {
                boxShadow: '$$shadowTop,$$shadowRight,$$shadowLeft',
            },
            topbottomleft: {
                boxShadow: '$$shadowTop,$$shadowBottom,$$shadowLeft',
            },
            toprightbottom: {
                boxShadow: '$$shadowTop,$$shadowRight,$$shadowBottom',
            },
            bottomleft: {
                boxShadow: '$$shadowBottom,$$shadowLeft',
            },
            rightbottom: {
                boxShadow: '$$shadowRight,$$shadowBottom',
            },
            rightbottomleft: {
                boxShadow: '$$shadowRight,$$shadowBottom,$$shadowLeft',
            },
            toprightbottomleft: {
                boxShadow: '$$shadowTop,$$shadowRight,$$shadowBottom,$$shadowLeft',
            },
        },
    },
});

export const ShadowScroll: FunctionComponent<{
    id?: string;
    scrollBoxRef: RefObject<HTMLDivElement>;
}> = ({ id, scrollBoxRef }) => {
    const [, scrollLeft, scrollTop, clientWidth, clientHeight, scrollWidth, scrollHeight] =
        useScrollViewport(scrollBoxRef as MutableRef<HTMLDivElement>, 100);

    const isTop = scrollTop !== 0;
    const isRight = scrollLeft !== scrollWidth - clientWidth;
    const isBottom = scrollTop !== scrollHeight - clientHeight;
    const isLeft = scrollLeft !== 0;

    const shadowDirection: ShadowDirection = `${isTop ? 'top' : ''}${isRight ? 'right' : ''}${
        isBottom ? 'bottom' : ''
    }${isLeft ? 'left' : ''}`;

    return (
        <ShadowScrollContainer
            id={id}
            data-testid={id}
            shadowDirection={shadowDirection as ShadowScrollContainerVariants['shadowDirection']}
        />
    );
};
