/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

/**
 * CRUD function for local storage access. `get` with
 * first param, `set` with name and value and `remove`
 * with `undefined` as value.
 *
 * TODO: Refactor -> Function is old and overly complicated.
 *
 * @param {string} name Storage key
 * @param {any} [value] Value to store
 * @returns {any} The value stored before
 */
export function localStorageItem(name, value) {
    const aLen = arguments.length;
    const s = window.localStorage;

    if (!s) {
        return null; // eslint-disable-line no-restricted-syntax
    }

    if (aLen > 1) {
        if (value !== undefined) {
            return s.setItem(name, JSON.stringify(value));
        }
        return s.removeItem(name);
    }
    if (aLen) {
        let result;
        try {
            result = JSON.parse(s.getItem(name));
        } catch (e) {
            result = s.getItem(name);
        }
        return result;
    }
    return null; // eslint-disable-line no-restricted-syntax
}

export default localStorageItem;
