/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */
import { h, FunctionComponent } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { styled, StyledComponentBaseProps, PulseLoadingIndicatorAnimation } from '../../theme';

const LoadingIndicator = styled('div', {
    variants: {
        isLoading: {
            true: {
                position: 'relative',
                '&:after': {
                    content: '',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,

                    animation: `${PulseLoadingIndicatorAnimation} 1s infinite linear`,
                },
            },
        },
    },
});

const StyledTileImg = styled('img', {
    objectFit: 'cover',
    backgroundSize: 'cover',
    variants: {
        alignBottom: {
            true: {
                objectFit: 'none',
                objectPosition: 'bottom',
            },
        },
    },
});

interface ImageWithFallback {
    src?: string;
    srcSet?: string;
    srcFallback?: string;
    srcSetFallback?: string;
    alt?: string;
}

export const ImageWithFallback: FunctionComponent<StyledComponentBaseProps & ImageWithFallback> = ({
    src,
    srcFallback,
    alt,
    ...baseProps
}) => {
    const ref = useRef<HTMLImageElement>(null);
    const [srcToLoad, setSrcToLoad] = useState<string | undefined>();
    const [brokenImage, setBrokenImage] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const onLoadHandler = () => {
            setIsLoading(false);
        };
        const onErrorHandler = () => {
            setBrokenImage(true);
            setIsLoading(false);
        };

        ref.current?.addEventListener('load', onLoadHandler);
        ref.current?.addEventListener('error', onErrorHandler);

        return () => {
            ref.current?.removeEventListener('load', onLoadHandler);
            ref.current?.removeEventListener('error', onErrorHandler);
        };
    }, [setBrokenImage, setIsLoading]);

    // make sure src is only set when dom is rendered not when react is re-rendering
    useEffect(() => {
        setSrcToLoad((brokenImage || !src) && srcFallback ? srcFallback : src);
    }, [src, srcFallback, brokenImage]);

    return (
        <LoadingIndicator isLoading={isLoading}>
            <StyledTileImg
                ref={ref}
                alignBottom={isLoading}
                src={srcToLoad}
                alt={alt}
                loading="lazy"
                {...baseProps} // eslint-disable-line react/jsx-props-no-spreading
            />
        </LoadingIndicator>
    );
};
